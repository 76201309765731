.hp-main {
    width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up('md') {
        width: calc(100% - 29rem);
    }

    @include media-breakpoint-up('lg') {
        max-width: 1008px;
    }

    @include media-breakpoint-up('xl') {
        max-width: 1141px;
    }
}

.btn-primaire-inverse-h40,
.btn-primaire-h40,
.btn-secondaire-h40,
.btn-secondaire-noir-h40 {
    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:focus {
        @include genericFocus(0.25rem);
    }
}

a.btn-primaire-h40,
a.btn-primaire-h56 {
    &:hover,
    &:focus {
        color: $blanc !important;
    }
}

@include media-breakpoint-up('md') {
    .w-md-auto {
        width: auto !important;
    }
}
