// cards
$size-card-width: 12.5rem; // 200px
$size-card-width-xl: 17.8rem; // 285px
$size-card-height: 10.375rem; // 166px

// breakpoints
$breakpoint-2card-up: 636px;
$breakpoint-2card-down: 601px;
$breakpoint-largeCard-center: 652px;

$class: ".ds-card";

%active-card {
  @extend .shadow-3-6;
  cursor: pointer;

  .ds-card__content-icon {
    color: $bleu_dark !important;
  }

  .ds-card__content-label {
    color: $bleu_dark !important;
  }
}

%selected-card {
  @extend .bleu_turquoise_light_bg;
  border: none;

  .ds-card__content-icon {
    color: $bleu_dark !important;
  }

  .ds-card__content-label {
    color: $bleu_dark !important;
  }
}

%unselected-card {
  @extend .blanc_bg;
}

#{$class}__content-sublabel {
  text-align: center;
}

#{$class}__selected-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $blanc;
  margin: 0;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s;
}

#{$class}__content {
  @extend .border-all-1-solid-gris_light, .radius-4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  transition: box-shadow 0.2s, background-color 0.2s;
}

#{$class}__content-icons {
  display: flex;
  align-items: center;
}

#{$class}__content-icon {
  @extend .gris_dark;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  height: 1.5rem;
  transition: color 0.2s;

  + .ds-card__content-icon {
    padding-left: 0.5rem;
  }
}

#{$class}__numero-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $blanc;
  margin: 0;
  font-size: 175%;
  transition: opacity 0.2s, transform 0.2s;
}

#{$class}__info-icon {
  position: absolute;
  left: 1rem;
  top: 1rem;
  transition: color 0.2s;
  cursor: pointer;
}

#{$class}__content-label {
  @extend .ds-bold, .gris_dark;
  font-size: $font-size-standard;
  line-height: $line-height-standard;
  text-align: center;
  transition: color 0.2s;
}

#{$class}__content-sublabel {
  @extend .gris_dark;
  margin-top: 0.5rem;
  font-size: $font-size-sub;
  line-height: $line-height-sub;
  text-align: center;
  transition: color 0.2s;
}

#{$class}-input,
#{$class}-button {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  display: flex;
  position: relative;
  width: $size-card-width;
  height: $size-card-height;

  &:not(#{$class}--checked) {
    .ds-card__content {
      @extend %unselected-card;
    }
  }

  &#{$class}--checked {
    .ds-card__content {
      @extend %selected-card
    }
    .ds-card__selected-icon {
      display: block;
      opacity: 1;
      transform: scale(1);
    }
  }

  &#{$class}--focused {
    .ds-card__content {
      @extend %active-card
    }
  }

  &:not(#{$class}--disabled) {
    .ds-card__content:hover {
      @extend %active-card;
    }
  }

  &#{$class}--disabled {

  }
}

#{$class}-input {
  input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none !important;

    &[type=radio] ~ .ds-card__content--selected:hover {
      cursor: initial;
      box-shadow: none;
    }
  }
}

#{$class}-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none !important;
  border: none !important;
}

@include media-breakpoint-up('xl') {
  .ds-card-input,
  #{$class}-button {
    width: $size-card-width;
  }
}

.mobile {
  .ds-card-input,
  #{$class}-button {
    width: 100%;
  }
}

// FIXME
strong {
  font-weight: bolder;
}
