.ds-dropdown {
  margin-bottom: 0;
  margin-top: 0;
  .control-label {
    font-size: inherit;
  }

  .dropdown {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    padding: 0px !important;
    font-size: 1rem;
    height: 55px;
    border: none;
    line-height: 1.6;
    box-shadow: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 8px 12px;
    background: $gris_background;

    &.opened {
      background: $gris_light;

      ~ .control-label,
      ~ .dsicon-chevron-haut {
        color: $bleu_texte;
      }
    }

    .options {
      display: none;
      position: absolute;
      background: $blanc;
      border-radius: 5px;
      width: 100%;
      z-index: 99;
      top: 50px;
      padding: 8px 0px 8px 0px;
      max-height: 310px;
      overflow: auto;

      .option {
        position: relative;
        padding: 16px;

        p {
          color: $gris_dark;
        }

        &:hover {
          background-color: $gris_background;
          cursor: pointer;
          p {
            color: $noir
          }
        }
  
        &.active {
          p {
            color: $vert_positif;
          }
  
          .dsicon-check {
            display: inline;
          }
        }
  
        .dsicon-check {
          display: none;
        }
  
        &:not(:last-child) .sub-libelle {
          ~ .line {
            display: inherit;
          }
        }
  
        .line {
          display: none;
          position: absolute;
          bottom: 0;
          left: 15px;
          right: 15px;
          height: 1px;
          background-color: $gris_light;
        }
      }
    }

    ~ .message-aide {
      display: inherit;
      color: $gris_dark;
      font-size: 0.75rem;
      margin-left: 8px;
      margin-top: 4px;
      position: absolute;
      display: inherit;
    }
    ~ .message-erreur {
      position: absolute;
      display: none;
      color: $rouge_negatif;
      font-size: 0.75rem;
      margin-left: 8px;
    }
    &.erreur {
      ~ .bar {
        position: relative;
        border-bottom: 2px solid $rouge_negatif;
        display: block;
      }
      ~ .message-erreur {
        color: $rouge_negatif;
        font-size: 0.75rem;
        margin-left: 8px;
        margin-top: 4px;
        position: absolute;
        display: inherit;
      }
      ~ .message-aide {
        display: none;
      }
    }
    &.valide {
      ~ .bar {
        position: relative;
        border-bottom: 2px solid $vert_positif;
        display: block;
      }
      ~ .message-erreur {
        display: none;
      }
      ~ .message-aide {
        display: none;
      }
    }
    &:focus {
      ~ i.dsicon-chevron-bas {
        display: none;
      }
      ~ i.dsicon-chevron-haut {
        display: inline;
      }
      outline: none;
      ~ .bar::before {
        width: 100%;
        left: 0;
      }
    }
  }
  .dropdown-text {
    width: auto;
    padding: 0;
    font-size: inherit;
    border-bottom: 1px dashed $noir;
    box-shadow: none;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-left: 5px;

    .dsicon-fleche-dropdown-texte {
      margin-left: 4px;
    }

    &.erreur {
      border-bottom: 1px dashed $rouge_negatif;

      label,
      .dsicon-fleche-dropdown-texte {
        color: $rouge_negatif;
      }
    }

    &.disabled {
      border-bottom: 1px dashed $gris_dark;

      label,
      .dsicon-fleche-dropdown-texte {
        color: $gris_dark;
      }
    }

    .options {
      display: none;
      position: absolute;
      background: $blanc;
      border-radius: 5px;
      width: 300px;
      z-index: 99;
      padding: 8px 0px 8px 0px;
      max-height: 310px;
      overflow: auto;

      &.top-left,
      &.top-right {
        bottom: -10px;
      }

      &.top-left {
        right: 0;
      }

      &.top-right {
        left: 0;
      }

      &.bottom-left,
      &.bottom-right {
        top: 0;
      }

      &.bottom-left {
        right: 0;
      }

      &.bottom-right {
        left: 0;
      }

      .option {
        position: relative;
        padding: 16px;

        &:hover {
          background-color: $gris_background;
          cursor: pointer;
          p {
            color: $noir
          }
        }
        
        &.active {
          p {
            color: $vert_positif;
          }
  
          .dsicon-check {
            display: inline;
          }
        }

        p {
          color: $gris_dark;
        }  
  
        .dsicon-check {
          display: none;
        }
  
        .sub-libelle {  
          ~ .line {
            display: inherit;
          }
        }
      }
    }
  }
  .message-erreur {
    color: $rouge_negatif;
    font-size: 0.75rem;
    margin-left: 8px;
  }
  .sub-libelle {
    color: $gris_dark;
  }
  .control-label {
    width: calc(100% - 3.5rem);
    left: 1rem !important;
    padding-left: 0 !important;
    color: $noir;

    &.ds-placeholder {
      color: $gris_dark;
    }
  }
  .control-value {
    position: absolute;
    bottom: 0.625rem;
    right: 2.5rem;
    left: 1rem;

    ~ .control-label {
      font-size: 0.75rem;
      color: $bleu_texte;
      top: 0.5rem;
      left: 0.75rem;
    }
  }
  .disabled {
    pointer-events: none;
    background-color: rgba(246, 246, 246, 0.3) !important;
    opacity: 0.3;
  }

  &__menu {
    min-width: 200px;
    margin-top: 1px;
    max-height: 50vh;
    overflow: auto;
    background-color: $blanc;
  }

  &__item {
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &--selected {
      font-style: normal;
      color: $vert_positif;
    }
  
    &-content {
      padding: 1rem;
    }

    &-separator {
      margin: 0 1rem;
      border-bottom: 1px solid $gris_light;
    }
  }

  .ds-placeholder {
    color: $gris_dark;

    &.valide {
      color: $vert_positif;
    }

    &.opened {
      color: $bleu_texte;
    }
  }

  .ds-input-form__disabled {
    .ds-placeholder,
    .dsicon-fleche-dropdown-texte {
      opacity: 0.3;
    }
  }
}