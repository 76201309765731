//
// Colors
// --------------------------------------------------

//variables

//couleurs primaires
$corail_mh_brand: var(--corail_mh_brand);
$corail_mh_dark: var(--corail_mh_dark);
$corail_mh_light: var(--corail_mh_light);


//couleurs secondaires
$gris_sable_light: var(--gris_sable_light);
$gris_sable_dark: var(--gris_sable_dark);
$jaune_ivoire_light:  var(--jaune_ivoire_light);  
$jaune_ivoire_dark: var(--jaune_ivoire_dark);  
$jaune_light: var(--jaune_light);
$jaune_dark: var(--jaune_dark);
$orange_normal: var(--orange_normal);
$orange_hover: var(--orange_dark);
$orange_dark: var(--orange_dark);      
$vert_light: var(--vert_light);   
$vert_dark: var(--vert_dark);  
$bleu_turquoise_light: var(--bleu_turquoise_light);
$bleu_turquoise_middle: var(--bleu_turquoise_middle);  
$bleu_turquoise_dark: var(--bleu_turquoise_dark);
$bleu_light: var(--bleu_light);
$bleu_dark: var(--bleu_dark);
$bleu_klein_light: var(--bleu_klein_light); 
$bleu_klein_dark: var(--bleu_klein_dark);  
$violet_light: var(--violet_light);
$violet_dark: var(--violet_dark);
$mauve: var(--mauve);  
$rose_light: var(--rose_light);
$rose_dark: var(--rose_dark);
$rose_fushia: var(--rose_fushia);  
$rouge_framboise: var(--rouge_framboise);  
$rose_chair_light: var(--rose_chair_light);
$rose_chair_dark: var(--rose_chair_dark);

//couleurs interfaces
$vert_positif: var(--vert_positif);
$rouge_negatif: var(--rouge_negatif);
$bleu_texte: var(--bleu_texte);
$bleu_texte-rgb: var(--bleu_texte-rgb);
$alerte_vert_positif: var(--alerte_vert_positif);
$alerte_rouge_negatif: var(--alerte_rouge_negatif);
$alerte_bleu_information: var(--alerte_bleu_information);
$alerte_jaune_attention: var(--alerte_jaune_attention);


//couleurs neutres
// Todo écrasement des valeurs hexa pour le lan et le noir erreur popver.scss
$blanc: #ffffff;
$noir: #222222;
$gris_light: var(--gris_light);
$gris_light-rgb: var(--gris_light-rgb);
$gris_dark: var(--gris_dark);
$gris_background: var(--gris_background);

$bleu_dark: var(--bleu_dark);

// couleurs dégradées
$corail_mh_brand_linear: var(--corail_mh_brand_linear);
$corail_mh_light_linear: var(--corail_mh_light_linear);

$gris_sable_light_linear: var(--gris_sable_light_linear);
$gris_sable_dark_linear: var(--gris_sable_dark_linear);

$jaune_ivoire_light_linear: var(--jaune_ivoire_light_linear);
$jaune_ivoire_dark_linear: var(--jaune_ivoire_dark_linear);
$jaune_light_linear: var(--jaune_light_linear);
$jaune_dark_linear: var(--jaune_dark_linear);

$orange_linear: var(--orange_linear);

$vert_light_linear: var(--vert_light_linear);
$vert_dark_linear: var(--vert_dark_linear);

$bleu_turquoise_light_linear: var(--bleu_turquoise_light_linear);
$bleu_turquoise_middle_linear: var(--bleu_turquoise_middle_linear);
$bleu_turquoise_dark_linear: var(--bleu_turquoise_dark_linear);
$bleu_light_linear: var(--bleu_light_linear);
$bleu_klein_light_linear: var(--bleu_klein_light_linear);
$bleu_klein_dark_linear: var(--bleu_klein_dark_linear);

$violet_light_linear: var(--violet_light_linear);
$violet_dark_linear: var(--violet_dark_linear);

$mauve_linear: var(--mauve_linear);

$rose_light_linear: var(--rose_light_linear);
$rose_dark_linear: var(--rose_dark_linear);
$rose_fushia_linear: var(--rose_fushia_linear);
$rose_framboise_linear: var(--rose_framboise_linear);
$rose_chair_light_linear: var(--rose_chair_light_linear);
$rose_chair_dark_linear: var(--rose_chair_dark_linear);

$corail_mh_brand_a: var(--corail_mh_brand_a);
$corail_mh_dark_a: var(--corail_mh_dark_a);
$orange_normal_a: var(--orange_normal_a);
$orange_dark_a: var(--orange_dark_a);
$vert_dark_a: var(--vert_dark_a);
$bleu_turquoise_middle_a: var(--bleu_turquoise_middle_a);
$bleu_turquoise_dark_a: var(--bleu_turquoise_dark_a);
$bleu_light_a: var(--bleu_light_a);
$bleu_klein_light_a: var(--bleu_klein_light_a);
$bleu_klein_dark_a: var(--bleu_klein_dark_a);
$violet_light_a: var(--violet_light_a);
$violet_dark_a: var(--violet_dark_a);
$rose_dark_a: var(--rose_dark_a);
$rose_fushia_a: var(--rose_fushia_a);
$rouge_framboise_a: var(--rouge_framboise_a);
$rose_chair_dark_a: var(--rose_chair_dark_a);
$vert_positif_a: var(--vert_positif_a);
$rouge_negatif_a: var(--rouge_negatif_a);
$bleu_texte_a: var(--bleu_texte_a);
$gris_dark_a: var(--gris_dark_a);

// tableau des couleurs
$couleurs: (
  "corail_mh_brand": $corail_mh_brand,
  "corail_mh_dark": $corail_mh_dark,
  "corail_mh_light": $corail_mh_light,

  "gris_sable_light": $gris_sable_light,
  "gris_sable_dark": $gris_sable_dark,
  "jaune_ivoire_light": $jaune_ivoire_light,  
  "jaune_ivoire_dark": $jaune_ivoire_dark,  
  "jaune_light": $jaune_light,
  "jaune_dark": $jaune_dark,
  "orange_normal": $orange_normal,
  "orange_dark": $orange_dark,  
  "vert_light": $vert_light,  
  "vert_dark": $vert_dark,  
  "bleu_turquoise_light": $bleu_turquoise_light,
  "bleu_turquoise_middle": $bleu_turquoise_middle,  
  "bleu_turquoise_dark": $bleu_turquoise_dark,
  "bleu_light": $bleu_light,
  "bleu_dark": $bleu_dark,
  "bleu_klein_light": $bleu_klein_light,  
  "bleu_klein_dark": $bleu_klein_dark,  
  "violet_light": $violet_light,
  "violet_dark": $violet_dark,
  "mauve": $mauve,  
  "rose_light": $rose_light,
  "rose_dark": $rose_dark,
  "rose_fushia": $rose_fushia,  
  "rouge_framboise": $rouge_framboise,  
  "rose_chair_light": $rose_chair_light,
  "rose_chair_dark": $rose_chair_dark,

  "vert_positif": $vert_positif,
  "rouge_negatif": $rouge_negatif,
  "bleu_texte": $bleu_texte,
  "alerte_vert_positif": $alerte_vert_positif,
  "alerte_rouge_negatif": $alerte_rouge_negatif,
  "alerte_bleu_information": $alerte_bleu_information,
  "alerte_jaune_attention": $alerte_jaune_attention,

  "blanc": $blanc,
  "noir": $noir,
  "gris_light": $gris_light,
  "gris_dark": $gris_dark,
  "gris_background": $gris_background,
);

// tableau des couleurs dégradées
$linears: (
  "corail_mh_brand_linear": $corail_mh_brand_linear,
  "corail_mh_light_linear": $corail_mh_light_linear,

  "gris_sable_light_linear": $gris_sable_light_linear,
  "gris_sable_dark_linear": $gris_sable_dark_linear,

  "jaune_ivoire_light_linear": $jaune_ivoire_light_linear,
  "jaune_ivoire_dark_linear": $jaune_ivoire_dark_linear,
  "jaune_light_linear": $jaune_light_linear,
  "jaune_dark_linear": $jaune_dark_linear,

  "orange_linear": $orange_linear,

  "vert_light_linear": $vert_light_linear,
  "vert_dark_linear": $vert_dark_linear,

  "bleu_turquoise_light_linear": $bleu_turquoise_light_linear,
  "bleu_turquoise_middle_linear": $bleu_turquoise_middle_linear,
  "bleu_turquoise_dark_linear": $bleu_turquoise_dark_linear,
  "bleu_light_linear": $bleu_light_linear,
  "bleu_klein_light_linear": $bleu_klein_light_linear,
  "bleu_klein_dark_linear": $bleu_klein_dark_linear,

  "violet_light_linear": $violet_light_linear,
  "violet_dark_linear": $violet_dark_linear,

  "mauve_linear": $mauve_linear,

  "rose_light_linear": $rose_light_linear,
  "rose_dark_linear": $rose_dark_linear,
  "rose_fushia_linear": $rose_fushia_linear,
  "rose_framboise_linear": $rose_framboise_linear,
  "rose_chair_light_linear": $rose_chair_light_linear,
  "rose_chair_dark_linear": $rose_chair_dark_linear
);
