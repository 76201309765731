@use 'sass:math';
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 32px !default;
$grid-row-columns: 6 !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: math.div(320, 16) * 1rem,
  md: math.div(1024, 16) * 1rem,
  lg: math.div(1280, 16) * 1rem,
  xl: math.div(1440, 16) * 1rem
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: math.div(288, 16) * 1rem,
  md: math.div(992, 16) * 1rem,
  lg: math.div(1248, 16) * 1rem,
  xl: math.div(1440, 16) * 1rem
) !default;

$enable-grid-classes: true !default;

