@each $name, $couleur in $couleurs {
  .#{$name} {
    color: $couleur !important;
  }
  .#{$name}_bg {
    background-color: $couleur;
  }
  .#{$name}_bg_content {
    &:after {
      background-color: $couleur;
    }
    &:before {
      background-color: $couleur;
    }
  }
}

@each $name, $linear in $linears {
  .#{$name} {
    background: $linear;
  }
}
