.ds-dropdown-form {
  height: 56px;
  width: 300px;

  .placeholder {
    color: $gris_dark;
  }

  .text-selected {
    color: $noir;
  }

  .text {
    padding: 2px 0 2px 8px;
  }

  .options {
    display: none;
    position: absolute;
    background: $blanc;
    border-radius: 5px;
    width: 100%;
    z-index: 99;
    box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.3);
    top: 25px;
    padding: 8px 0px 16px 0px;
    max-height: 250px;
    overflow: auto;
    left: 0;
  }

  .option {
    padding: 16px;
    p {
      color: $gris_dark;
    }

    &:hover {
      background-color: $gris_background;
      cursor: pointer;
      p {
        color: $noir;
      }
    }

    .selected {
      position: relative;

      i {
        top: 50%;
        transform: translateY(-50%);
        color: $vert_positif;
      }

      p {
        color: $vert_positif;
      }
    }
  }

  i.dsicon-fleche-dropdown-texte {
    right: 4px;
    top: 4px;
    color: $noir;

    &.rotate {
      transform: rotate(180deg);
    }
  }

  .ds-placeholder {
    color: $gris_dark;
  }

  .ds-input-form__disabled {
    .ds-placeholder,
    .dsicon-fleche-dropdown-texte {
      opacity: 0.3;
    }
  }
}
