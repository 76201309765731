/* Surcharge modal Bootstrap */

.modal {
    &-content {
        border: none;
        box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.2);
        border-radius: 8px;
    }

    &-header {
        padding: 1.5rem 1.5rem 1rem;
        border-bottom: none;
    }

    &-body {
        padding: 0 1.5rem 1rem;
    }
}