.paging-container {
  .debut-paging,
  .precedent,
  .suivant,
  .fin-paging {
    @extend [class^="dsicon-"];
    @include bg-hover($gris_background);
    font-size: 0.875rem;
    border: 1px solid $gris_dark;
    border-radius: 50%;
    background: white;
    opacity: 0.8;
    
  }

  .debut-paging {
    @extend .dsicon-debut-gauche;
    padding: 12px;
    margin-right: 5px;
  }

  .precedent {
    @extend .dsicon-chevron-gauche;
    padding: 12px;
    margin-right: 8px;
  }

  .compte {
    font-size: 0.875rem;
    line-height: 1.36;
    color: $noir;
    margin-right: 8px;

    .compte-total {
      color: $gris_dark;
    }
  }

  .suivant {
    @extend .dsicon-chevron-droit;
    padding: 12px;
    margin-right: 8px;
  }

  .fin-paging {
    @extend .dsicon-fin-droite;
    padding: 12px;
  }

  .disabled,
  [disabled] {
    color: $noir !important;
    border-color: $gris_dark !important;
    pointer-events: none;
    opacity: 0.3;
  }
}
