.intro-return {
    $thumbHeight: 37vw;

    position: relative;
    padding-bottom: $thumbHeight;

    @include media-breakpoint-up('md') {
        padding-bottom: 0;
    }

    .thumbnail {
        position: absolute;
        bottom: 0;
        left: 2rem;
        right: 2rem;
        height: $thumbHeight;
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        border-radius: 4px;

        @include media-breakpoint-up('md') {
            position: static;
            height: auto;
            flex: 0 0 calc(50% - 1.5rem);
            margin-right: 3rem;
            background-size: contain;
            background-position: right center;
        }
    }

    .anchor {
        text-decoration: underline;

        &:hover,
        &:focus {
            color: $corail_mh_brand !important;
        }
    }

    &.no-mobile-thumb {
        padding-bottom: 0;
    }
}

.intro-return-bg {
    background-image: linear-gradient(242deg, #b5fdff, #5ad5d9 100%);
}

.desktop-banner-img {
    margin-right: 8%;
    width: 543px;
    height: 585px;
    @include media-breakpoint-up('xl') {
        width: 24%;
        margin-right: 24%;
        height: 596px;
    }
    img {
      height: 585px;
      width: 543px;
      max-width: none;
      @include media-breakpoint-up('xl') {
        height: 596px;
      }
    }
}

.mobile-banner-img {
    width: 320px;
    height: 292px;
    margin: auto;
    object-fit: contain;
}

a, button {
    cursor: pointer;
}

.new-account {
    text-decoration: underline;
}

.banner-chevron {
    margin: -32px 0 10px;
}
