$vertical-spacing: 3rem;
$vertical-spacing-mobile: 2rem;

.ds-typeform-container {
  display: block;
  position: relative;

  .ds-typeform-question-container {

    display: block;
    width: 100%;
    position: relative;

    .ds-typeform-back {
      display: block;
      margin-bottom: 2rem;

      &.hidden {
        visibility: hidden;
      }
    }

    .ds-typeform-question {

      & > * {
        display: block;
        width: 100%;
      }

      .ds-typeform-question-title {
        font-size: 1.375rem;
        text-align: start;
        flex-flow: column;
        display: flex;
        // vertical spacing
        margin-bottom: $vertical-spacing;

        .ds-typeform-question-subtitle {
          font-size: $font-size-standard;
          line-height: $line-height-standard;
          margin-top: 1rem;
          display: block;
        }
      }

      .ds-typeform-question-field-container {

        & > * ~ * {
          margin-top: $vertical-spacing;
        }

        .popover {
          margin-top: 0;
        }
      }
    }

    .ds-typeform-submit {
      display: block;
      padding: 2rem 0;

      .alternative-submit {
        margin-left: 1.5rem;

        .keyboard-key {
          font-weight: bold;
        }
      }

      &.disabled {
        .alternative-submit {
          opacity: 30%;
        }
      }
    }
  }
}

.mobile {
  .ds-typeform-container {

    .ds-typeform-question-container {

      .ds-typeform-back {
        display: none;
      }

      .ds-typeform-question {

        .ds-typeform-question-title {
          text-align: center;
          // vertical spacing
          margin-bottom: $vertical-spacing-mobile;

          .ds-typeform-question-subtitle {
            font-size: 14px;
          }
        }

        .ds-typeform-question-field-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          & > * ~ * {
            margin-top: $vertical-spacing-mobile;
          }
        }
      }

      .ds-typeform-submit {

        button {
          width: 100%
        }

        .alternative-submit {
          display: none;
        }
      }
    }
  }
}
