
@mixin reset-text() {
  font-family: "poppins-regular";
  // We deliberately do NOT reset font-size or word-wrap.
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}
