$tags: (
        h1: 42,
        h2: 36,
        h3: 28,
        h4: 22,
        h5: 18,
        "body, p, a, form, button": 16,
        sub: 14,
        ".form-focus, .message, caption": 12,
        ".overline": 10,
);

$titles: (
        42: (
                font: $font-size-h1,
                line: $line-height-titre-gros,
        ),
        36: (
                font: $font-size-h2,
                line: $line-height-titre-gros,
        ),
        28: (
                font: $font-size-h3,
                line: $line-height-titre-petit,
        ),
        22: (
                font: $font-size-h4,
                line: $line-height-titre-petit,
        ),
        18: (
                font: $font-size-h5,
                line: $line-height-standard,
        ),
        16: (
                font: $font-size-standard,
                line: $line-height-standard,
        ),
        14: (
                font: $font-size-sub,
                line: $line-height-titre-petit,
        ),
        12: (
                font: $font-size-form,
                line: $line-height-form,
        ),
        10: (
                font: $font-size-overline,
                line: $line-height-overline,
        ),
);

@each $tag, $size in $tags {
  #{$tag} {
    @include style-text(map-get(map-get($titles, $size), "font"), map-get(map-get($titles, $size), "line"));
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  @media screen and (min-width: #{map-get($grid-breakpoints, $breakpoint)}) {
    @each $i in map-keys($titles) {
      .titre#{$infix}-#{$i} {
        @include style-text(map-get(map-get($titles, $i), "font"), map-get(map-get($titles, $i), "line"));
      }
    }
  }
}

body,
p,
a,
form,
button,
table {
  @include style-text($font-size-standard, $line-height-standard);
  color: $noir;
  font-family: "poppins-regular", Arial, Helvetica, sans-serif;
}

.overline {
  text-transform: uppercase;
}

.ds-bold,
b {
  font-family: "poppins-bold", Arial, Helvetica, sans-serif;
}

.sr-only,
.texte-invisible {
  @include sr-only();
}
