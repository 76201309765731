$class: ".ds-date3";

#{$class}-input {
  display: block;

  span {
    display: inline-block;
    margin: 0 8px 0 8px;
  }

  .ds-input__fake-group {
    width: 2.25rem;
    display: inline-block;
    input {
      text-align: center;
      padding: 0 0 4px 0;
    }
  }

  .ds-input__fake-group:last-of-type {
    width: 4rem;
  }

  input:focus::placeholder {
    color: transparent;
  }
}
