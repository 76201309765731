$enable-negative-margins: true;
$bleu_turquoise_light_overide: #5ad5d9;
$bleu_dark_overide: #1A283E;

@import "../../node_modules/ds-bootstrap/src/scss/variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/ds-bootstrap/src/scss/ds-bootstrap";
@import "../../node_modules/ds-bootstrap/src/scss/ds-grid";

html,
body,
#wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

// body {
//   height: 100vh;
//   overflow: initial;
// }

img {
  max-width: 100%;
}

@mixin genericFocus($offset: 0) {
  &:focus {
    outline: 1px dashed $corail_mh_brand;
    outline-offset: $offset;

    &:not(:focus-visible) {
      outline: none;
    }
  }
}

* {
  @include genericFocus();
}

:focus[tabindex="-1"] {
  outline: none;
}

// :focus {
//   outline: 3px dashed blue !important;
// }

@import "utils";
@import "ds-override";

@import "components/env-switch";
@import "components/epargne-slider";
@import "components/espace-action";
@import "components/footer";
@import "components/border-round";
@import "components/intro-return";
@import "components/logo";
@import "components/navbar";
@import "components/retraite-choices";
@import "components/retraite-finder";
@import "components/scroll-hide";
@import "components/title-border";
@import "components/trouver-offre";
@import "components/typeform-block";
