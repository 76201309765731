.bleu_turquoise_gradient_bg {
    background: #B5FDFF;
    background: -webkit-linear-gradient(to bottom, #B5FDFF 0%, #5AD5D9 100%);
    background: -moz-linear-gradient(to bottom, #B5FDFF 0%, #5AD5D9 100%);
    background: linear-gradient(to bottom, #B5FDFF 0%, #5AD5D9 100%);


    @include media-breakpoint-up('md') {
        background: linear-gradient(to right, #5AD5D9 0%, #B5FDFF 100%);
    }
}


.agirc-arrco-container {
    width: 1008px;

    .agirc-arrco-compte-container {
        border-radius: 16px;
        width: 904px;
        height: 353px;
        margin: 0 0 0 -100px;
        z-index: 0 !important;
       // padding-right: 100px;

        // margin: 0 0 0 104px;
        //padding: 70px 90px 70px 313px;
    }


    .agirc-arrco-image-wrapper img {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 270px;
        z-index: 10 !important;
        @include media-breakpoint-up('md') {
            max-width: 380px;
        }
    }
}



.retaitre-smile-container {
    background-image: url("../img/brand-mh-smile.png");
    background-size: cover;
    background-position: center;
    color: $bleu_dark_overide;
    background-repeat: no-repeat;
    @include media-breakpoint-down('md') {
        border-radius: 16px;
    }
}

.hp-your-side {
    width: auto;
    @include media-breakpoint-down('md') {
        margin-left: auto;
        margin-right: auto;
        width: calc(100% - 2rem);
    }
}
