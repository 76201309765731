$font-size-body: 16px;
$font-size-standard: 1rem;
$font-size-h1: 2.625rem;
$font-size-h2: 2.25rem;
$font-size-h3: 1.75rem;
$font-size-h4: 1.375rem;
$font-size-h5: 1.125rem;
$font-size-sub: 0.875rem;
$font-size-cap: 0.75rem;

$font-size-form: 0.75rem;
$font-size-overline: 0.625rem;

$line-height-standard: 1.375rem;
$line-height-h1: 3.5rem;
$line-height-h2: 3rem;
$line-height-h3: 2.375rem;
$line-height-h4: 1.875rem;
$line-height-h5: 1.5625rem;
$line-height-sub: 1.1875rem;
$line-height-cap: 1.0625rem; // 17px

$line-height-form: 0.75rem;
$line-height-overline: 0.625rem;

$line-height-standard: 1.38;
$line-height-titre-gros: 1.33;
$line-height-titre-petit: 1.36;
$line-height-form: 1.42;
$line-height-overline: 1.4;
