footer {
  max-width: map-get($grid-breakpoints, 'xl');

  a {
    text-decoration: none;
  }
}

.ds-footer {
  &_light {
    padding: 25px 0px;
    margin: 0px 32px;
    
    @media (max-width: map-get($grid-breakpoints, 'md')) {
      flex-direction: column;
      margin: 0px 16px;
    }

    a, span {
      color: $gris_dark;
      font-size: $font-size-form;
    }
  
    .menu {
      a {
        text-decoration: none;
        @media (min-width: map-get($grid-breakpoints, 'md')) {
          margin-right: 25px;
        }
        @media (max-width: map-get($grid-breakpoints, 'md')) {
          margin-bottom: 8px;
        }
      }
      @media (max-width: map-get($grid-breakpoints, 'md')) {
        flex-direction: column;
        margin-bottom: 32px;
      }
    }
    
  }
  &_dark {
    padding: 71px 32px;
    background: #636363;
    margin: 0px;

    @media (max-width: map-get($grid-breakpoints, 'md')) {
      flex-direction: column-reverse;
      padding: 32px 16px;
    }

    .menu {
      padding-top: 3px;
      a {
        width: 33%;
        display: inline-block;
        margin-bottom: 16px;
        @media (max-width: map-get($grid-breakpoints, 'md')) {
          width: 100%;
        }
      }
    }

    a, span {
      color: $blanc !important;
      font-size: $font-size-standard;
    }

    .content-side {
      .social-network {
        a {
          color: $blanc;
          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
      .form-group {
        margin-top: 25px;
        @media (max-width: map-get($grid-breakpoints, 'md')) {
          margin-bottom: 25px;
        }
        &:hover {
          button {
            background: $gris_light;
          }
        }
        
        input{
          &:focus ~ button {
            background: $corail_mh_brand !important;
            color: white;
          }
        }

        button {
          position: absolute;
          right: 9px;
          top: 9px;
          width: 40px;
          height: 40px;
          text-align: center;
          padding-top: 6px;
          border-radius: 30px;
          cursor: pointer;
          border: none;
          background: transparent; 
        }  
        .message-aide {
          display: block;
          color: $blanc;
          position: relative;
        }
      }
    }
  }
}
