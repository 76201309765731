@font-face {
  font-family: "ds-icon";
  src: url("/assets/fonts/dsicon/ds-icon.eot");
  src: url("/assets/fonts/dsicon/ds-icon.ttf") format("truetype"),
  url("/assets/fonts/dsicon/ds-icon.woff") format("woff"),
  url("/assets/fonts/dsicon/ds-icon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dsicon-"],
[class*=" dsicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "ds-icon", Arial, Helvetica, sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="dsicon-"][class$="-xl"],
[class*=" dsicon-"][class$="-xl"] {
  font-size: 2rem;
}

@mixin dsicon($name, $icon) {
  .#{$name}:before {
    content: $icon;
    @if ($name == dsicon-facebook or $name == dsicon-linkedin or $name == dsicon-twitter or $name == dsicon-youtube or $name == dsicon-instagram) {
      font-size: 1.5rem;
    }
  }
}

@each $name, $icon in $icons {
  @include dsicon($name, $icon);
  @include dsicon(#{$name}-xl, $icon);
}

