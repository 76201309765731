$box-shadow-card: 0 3px 6px 0 rgba($noir, 0.3);

.ds-card-selected {
  background: $bleu_turquoise_light;
  border: 0px !important;
  color: $bleu_dark !important;

  .check {
    opacity: 1;
    transform: scale(1);
  }

  .info {
    color: $blanc !important;
  }

  .label {
    // TODO : DS Bold Variable
    font-weight: bold;
  }
}

a.ds-card,
.ds-card {
  position: relative;
  height: 134px;
  width: 134px;
  max-width: 100%;
  background: transparent;
  color: $gris_dark;
  transition: box-shadow 0.2s, color 0.2s, background-color 0.2s, border-color 0.2s;
  text-decoration: none;

  &:not(.grid-mode) {
    margin-right: 24px;
    margin-bottom: 24px;
  }

  @include media-breakpoint-up("md") {
    height: 166px;

    &:not(.grid-mode) {
      width: 214px;
      margin-right: 32px !important;
      margin-bottom: 32px !important;
    }
  }

  @include media-breakpoint-up("lg") {
    &:not(.grid-mode) {
      width: 174px;
    }
  }

  @include media-breakpoint-up("xl") {
    &:not(.grid-mode) {
      width: 200px;
    }
  }

  .info {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }

  .icone {
    text-decoration: none !important;
    font-size: 2rem;
  }

  .label {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    width: 100%;
    letter-spacing: normal;
    text-transform: none;
    white-space: normal !important;
    word-wrap: break-word !important;
  }

  &:hover {
    box-shadow: $box-shadow-card !important;
  }
  &:hover,
  &:focus {
    outline: none;
    color: $bleu_dark !important;
  }

  &:active,
  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  .check {
    position: absolute;
    top: 1rem;
    right: 1rem;
    opacity: 0;
    color: $blanc;
    transform: scale(0);
    transition: opacity 0.2s 0.2s, transform 0.2s 0.2s;
  }

  &.grid-mode {
    width: 100%;
    margin-left: 0px !important;
  }

  &:hover {
    text-decoration: none !important;
  }

  &.ds-card--is-selected {
    @extend .ds-card-selected;
  }
}

.ds-link-card {
  &:focus {
    @extend .ds-card-selected;
  }
}
