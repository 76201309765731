@import 'variables.scss';

.ds-message {
  position: relative;
  opacity: 1;
  min-height: 70px;
  padding: 24px;
  margin-bottom: 15px;

  &.message-info {
    background-color: $alerte_bleu_information;
  }

  &.message-info_secondaire {
    background-color: $gris_background;
  }

  &.message-alerte {
    background-color: $alerte_jaune_attention;
  }

  &.message-erreur {
    background-color: $alerte_rouge_negatif;
  }

  &.message-succes {
    background-color: $alerte_vert_positif;
  }

  p,
  i {
    color: $noir;
  }

  .dsicon-close {
    cursor: pointer;
  }
}
