:root {
  --corail_mh_brand:         #e2250c;
  --corail_mh_dark:          #ad1400;
  --corail_mh_light:         #ff4b33;
   
  //couleurs secondaires     
  --gris_sable_light:        #ede8e6;
  --gris_sable_dark:         #e4d8c4;
  --jaune_ivoire_light:      #fcf7e7; 
  --jaune_ivoire_dark:       #f6ebc8; 
  --jaune_light:             #f4ec5b;
  --jaune_dark:              #f9bd00;
  --orange_normal:           #ff8347;
  --orange_dark:             #D14600;
  --orange_hover:            var(--orange_dark);
  --vert_light:              #03dfb2; 
  --vert_dark:               #008275; 
  --bleu_turquoise_light:    #5ad5d9;
  --bleu_turquoise_middle:   #008299; 
  --bleu_turquoise_dark:     #006374;
  --bleu_light:              #4283d1;
  --bleu_dark:               #1a283e;
  --bleu_klein_light:        #5f65e3; 
  --bleu_klein_dark:         #484dad;
  --bleu_klein_dark_a:       #30369E; 
  --violet_light:            #6d4ca7;
  --violet_dark:             #4b218e;
  --mauve:                   #d4c0f0; 
  --rose_light:              #f4b5d4;
  --rose_dark:               #d81e88;
  --rose_fushia:             #b9066c; 
  --rouge_framboise:         #de2b5b; 
  --rose_chair_light:        #f0b7a5;
  --rose_chair_dark:         #cca196;
                             
  //couleurs interfaces      
  --vert_positif:            #1E7000;
  --rouge_negatif:           #CC0000;
  --bleu_texte:              #2964AE;
  --alerte_vert_positif:     #bddab2;
  --alerte_rouge_negatif:    #f9b2b2;
  --alerte_bleu_information: #c1d5ee; 
  --alerte_jaune_attention:  #f9f5ad;


  //couleurs neutres
  --blanc:                   #ffffff;
  --noir:                    #222222;
  --gris_light:              #e1e1e1;
  --gris_dark:               #636363;
  --gris_background:         #f6f6f6;

  --bleu_dark:               #1A283E;

  //couleur accessibilités
  --corail_mh_brand_a:         #ad1400;
  --corail_mh_dark_a:          #8a1f10;

  --orange_normal_a:           #ffb896;
  --orange_dark_a:             #D14600; 
  --vert_dark_a:               #006157; 
  --bleu_turquoise_middle_a:   #005f70; 
  --bleu_turquoise_dark_a:     #004450;
  --bleu_light_a:              #255998;
  --bleu_klein_light_a:        #3840dc; 
  --bleu_klein_dark_a:         #30369E; 
  --violet_light_a:            #67489d;
  --rose_dark_a:               #A61768;
  --rose_fushia_a:             #870950;  
  --rouge_framboise_a:         #ac1b42; 
  --rose_chair_dark_a:         #d2aca2;
  --violet_dark_a:             #381078;
      
  --vert_positif_a:            #1E7000 ;
  --rouge_negatif_a:           #940000;
  --bleu_texte_a:              #1d487c;

  //couleurs neutres
  --gris_dark_a:               #474747;

  --gris_light-rgb:      #{derive-rgb-values-from-hex(#e1e1e1)};
  --bleu_texte-rgb:      #{derive-rgb-values-from-hex(#2964AE)};
  --gris_background-rgb: #{derive-rgb-values-from-hex(#F6F6F6)};

  // couleurs dégradées
  --corail_mh_brand_linear:         linear-gradient(225deg, #FF4B33 0%, #E2250C 100%);
  --corail_mh_light_linear:         linear-gradient(225deg, #FF7643 0%, #FF4B33 100%);

  --gris_sable_light_linear:        linear-gradient(225deg, #FAF5F3 0%, #EDE8E6 100%);
  --gris_sable_dark_linear:         linear-gradient(225deg, #F8F1E6 0%, #E4D8C4 100%);

  --jaune_ivoire_light_linear:      linear-gradient(225deg, #FFFDF6 0%, #FCF7E7 100%);
  --jaune_ivoire_dark_linear:       linear-gradient(225deg, #FFFBED 0%, #F6EBC8 100%);
  --jaune_light_linear:             linear-gradient(225deg, #FFFBB7 0%, #F8F169 100%);
  --jaune_dark_linear:              linear-gradient(225deg, #FFDC6E 0%, #F9BD00 100%);

  --orange_linear:                  linear-gradient(225deg, #FFA51B 0%, #FF8347 100%);

  --vert_light_linear:              linear-gradient(225deg, #AEFFEF 0%, #03DFB2 100%);
  --vert_dark_linear:               linear-gradient(224deg, #005A51 0%, #008275 100%);

  --bleu_turquoise_light_linear:    linear-gradient(225deg, #B5FDFF 0%, #5AD5D9 100%);
  --bleu_turquoise_middle_linear:   linear-gradient(225deg, #007286 0%, #008299 100%);
  --bleu_turquoise_dark_linear:     linear-gradient(225deg, #01414C 0%, #006374 100%);
  --bleu_light_linear:              linear-gradient(225deg, #1F5597 0%, #4283D1 100%);
  --bleu_klein_light_linear:        linear-gradient(226deg, #3D44C9 0%, #5F65E3 100%);
  --bleu_klein_dark_linear:         linear-gradient(225deg, #232876 0%, #484DAD 100%);

  --violet_light_linear:            linear-gradient(225deg, #593894 0%, #6D4CA7 100%);
  --violet_dark_linear:             linear-gradient(225deg, #330F6D 0%, #4B218E 100%);

  --mauve_linear:                   linear-gradient(225deg, #F2E9FF 0%, #D4C0F0 100%);

  --rose_light_linear:              linear-gradient(225deg, #FFE8F3 0%, #F4B5D4 100%);
  --rose_dark_linear:               linear-gradient(225deg, #A30961 0%, #D81E88 100%);
  --rose_fushia_linear:             linear-gradient(225deg, #8D0151 0%, #B9066C 100%);
  --rose_framboise_linear:          linear-gradient(225deg, #B50D3A 0%, #DE2B5B 100%);
  --rose_chair_light_linear:        linear-gradient(225deg, #FFDFD2 0%, #F0B7A5 100%);
  --rose_chair_dark_linear:         linear-gradient(225deg, #EDD5CE 0%, #CCA196 100%);
}

[data-theme="dark"] {
  --corail_mh_brand:         var(--corail_mh_brand_a);
  --corail_mh_dark:          var(--corail_mh_dark_a);

  //couleurs secondaires
  --orange_normal:           var(--orange_normal_a);
  --orange_dark:             var(--orange_dark_a); 
  --vert_dark:               var(--vert_dark_a); 
  --bleu_turquoise_middle:   var(--bleu_turquoise_middle_a); 
  --bleu_turquoise_dark:     var(--bleu_turquoise_dark_a);
  --bleu_light:              var(--bleu_light_a);
  --bleu_klein_light:        var(--bleu_klein_light_a); 
  --bleu_klein_dark:         var(--bleu_klein_dark_a); 
  --violet_light:            var(--violet_light_a);
  --violet_dark:             var(--violet_dark_a);
  --rose_dark:               var(--rose_dark_a);
  --rose_fushia:             var(--rose_fushia_a);  
  --rouge_framboise:         var(--rouge_framboise_a); 
  --rose_chair_dark:         var(--rose_chair_dark_a);

  //couleurs interfaces      
  --vert_positif:            var(--vert_positif_a);
  --rouge_negatif:           var(--rouge_negatif_a);
  --bleu_texte:              var(--bleu_texte_a);

  //couleurs neutres
  --gris_dark:               var(--gris_dark_a);

  --bleu_texte-rgb:     #{derive-rgb-values-from-hex(#1d487c)};
}