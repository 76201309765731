.ds-card__list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -34px;

  .ds-card-input,
  .ds-card-button {
    margin-right: 34px;
    margin-bottom: 34px;
  }

  &.ds-card__list-container--large {
    .ds-card-input,
    .ds-card-button {
      width: $size-card-width-xl;

      .ds-card__content {
        padding-top: 2rem;
        justify-content: initial;
      }

      .ds-card__content-centered {
        justify-content: center;
      }
    }
  }
}

.desktop {
  @media (max-width: $breakpoint-largeCard-center) {
    .ds-card__list-container {
      justify-content: center;
    }
  }
}

.mobile {
  .ds-card__list-container {
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    width: 100%;

    &.ds-card__list-container--large {
      .ds-card-input,
      .ds-card-button {
        width: 100%;
      }
    }

    .ds-card-input,
    .ds-card-button {
      margin-right: 0;
      margin-bottom: 18px;
    }
  }
}
