$class: "ds-side-panel";
$default-position: 300px;
$positions: 'left', 'right', 'bottom', 'top';
$sizes: 200, 240, 280, 320, 360, 400, 520, 600, 704, 800;

@each $size in $sizes {
    @each $position in $positions {
        .#{$class}-#{$position}-#{$size} {
            z-index: 1000;
            background: var(--blanc);
            @if ($position == 'left' or $position == 'right') {
                width: $size * 1px;
                height: 100vh;
                position: fixed;
                top: 0;
                transition: left ease-in-out 0.3s, right ease-in-out 0.3s;
            }
            @else if($position == 'top' or $position == 'bottom') {
                height: $size * 1px;
                width: 100%;
                position: fixed;
                left: 0;
                transition: top ease-in-out 0.3s, bottom ease-in-out 0.3s;
            }
            
            #{$position}: -$size * 1px;

            &.#{$class}-opened {
                #{$position}: 0px;
            }

            & > .dsicon-close {
                position: absolute;
                right: 16px;
                top: 16px;
                cursor: pointer;
            }
        }
    }
}

.nav-container-mask {
    width: 100%;
    z-index: 900;
    opacity: 1;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    position: fixed;
    background: rgba(34, 34, 34, 0.5);
    transition: opacity ease-in-out 0.3s;

    @media (max-width: map-get($grid-breakpoints, "md")) {
        display: none !important;
    }

    &.closed {
        opacity: 0;
        z-index: -1;
    }
}
