.trouver-offre {
    >.content {
        @include media-breakpoint-up('md') {
            padding-top: 125px !important;
            padding-bottom: 125px !important;
        }
    }

    >.thumb {
        height: 280px;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;

        @include media-breakpoint-up('md') {
            flex: 0 0 48.5%;
            height: auto;
        }
    }

    @include media-breakpoint-down('sm') {
        border-radius: 16px;
    }

}

.trouver-offre-pcpme {
    @include media-breakpoint-up('md') {
        flex: 1 1 0px;
    }

    >.thumb {
        height: 280px;
        background-position: center bottom;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.right-side {
    @include media-breakpoint-up('md') {
        width: 30%;
        margin-left: 9%;
        margin-top: 129px;
    }
}

.left-side {
    @include media-breakpoint-up('md') {
        width: 31%;
        margin-left: 15%;
    }
}

.app-banner {
    width: 100% !important;
    height: 406px;
    background-position-x: right;
    background-repeat: no-repeat;
    @include media-breakpoint-up('sm') {
        width: 286px;
        height: 405px;
        margin: 0 0 84px;
    }
}

.phone {
    margin: 19px 76px;
    width: 321px;
    height: 472px;
    object-fit: contain;
    @include media-breakpoint-up('lg') {
        margin: 19px 176px;
    }

}

.phone-mobile {
    width: auto;
    height: 200px;
    margin: -99px 83.4px -100px 29%;
}

.title {
    color: #fff;
    @include media-breakpoint-up('md') {
        width: 435px;
        height: 76px;
        margin: 0 92px 8px 0;
    }
}

.sub-title {
    color: #fff;
    @include media-breakpoint-up('md') {
        width: 527px;
        height: 60px;
        margin: 8px 0 40px;
    }
}

.badge {
   max-width: 90%;
}

.row > div.decouvrez-application {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  flex-shrink: unset;
  margin-top: 0;
}
