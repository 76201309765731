.ds-textarea-form {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  &--field {
    outline: none;
    border: 1px $gris_dark solid;
    font-size: 18px;
    padding: 16px;
    width: 100%;

    &:disabled {
      background-color: $blanc;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px $bleu_texte solid;

      ~ .message-aide {
        display: inherit;
      }

      ~ .message-erreur {
        display: none;
      }
    }
  }

  .message-aide,
  .message-erreur {
    display: none;
    margin-left: 8px;
    font-size: 12px;
    color: $gris_dark;
  }

  &__disabled {
    textarea {
      opacity: 0.3;
    }
  }

  &__valide {
    textarea {
      border: 1px $vert_positif solid !important;

      &:focus,
      &:focus-visible,
      &:focus-within {
        border-bottom: 1px $bleu_texte solid;

        ~ .message-aide {
          display: inherit;
        }

        ~ .message-erreur {
          display: none;
        }
      }
    }
  }

  &__erreur {
    textarea {
      border: 1px $rouge_negatif solid !important;

      &:focus,
      &:focus-visible,
      &:focus-within {
        border: 1px $bleu_texte solid;

        ~ .message-aide {
          display: none;
        }

        ~ .message-erreur {
          display: inherit;
        }
      }
    }

    .message-erreur {
      display: inherit;
    }
  }
}
