$class: ".ds-select";

#{$class}-input {
  display: block;

  button {
    display: block;
    height: 100%;
    width: 100%;
    border: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: left;
    background: transparent;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    //&:focus {
    //  outline: none;
    //}

    &:disabled {
      cursor: initial;
    }
  }

  #{$class}__chevron-bas,
  #{$class}__chevron-haut {
    position: absolute;
    top: 20px;
    right: 11px;
    bottom: 0;
  }

  #{$class}__chevron-bas {
    @extend .dsicon-chevron-bas;
    display: inline;
  }

  #{$class}__chevron-haut {
    @extend .dsicon-chevron-haut;
    display: none;
  }

  &#{$class}--open {
    i#{$class}__chevron-bas {
      display: none;
    }

    i#{$class}__chevron-haut {
      display: inline;
    }
  }

  #{$class}__options {
    display: block;
    position: absolute;
    background: $blanc;
    border-radius: 5px;
    width: 100%;
    z-index: 99;
    box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.3);
    top: 58px;
    padding: 8px 0 16px 0;
    max-height: 250px;
    overflow: auto;

    //&:focus {
    //  outline: none;
    //}

    &#{$class}__options--hidden {
      display: none;
    }

    #{$class}__option {
      padding: 16px;
      min-height: 57px;
      color: $gris_dark;

      &#{$class}__option--active {
        background-color: $gris_background;
        color: $vert_positif;

        #{$class}__option-active-indicator {
          float: right;

          .dsicon-check {
            // inherit from from-group style ...
            display: inline;
            top: auto;
            bottom: auto;
          }
        }
      }

      &:hover {
        background-color: $gris_background;
        cursor: pointer;
      }
    }
  }

  &.ds-input--disabled {
    button {
      color: $gris_light;
      cursor: initial;
    }

    i#{$class}__chevron-bas,
    i#{$class}__chevron-haut {
      display: none;
    }
  }
}


.ds-input-group {
  #{$class}-input {
    button {
      padding: 20px 15px 5px 15px;
    }
  }
}

.ds-input-group-typeform {
  #{$class}-input {
    button {
      height: 32px;
      font-size: 1.125rem;
      line-height: 1.5625rem;
      padding: 0 24px 4px 8px;
    }

    #{$class}__chevron-haut,
    #{$class}__chevron-bas {
      top: 7px;
    }
  }
}


