.scroll-hide {
    overflow: hidden;
    $w: 30px;

    &--bottom {
        >* {
            margin-bottom: $w * -1;
            padding-bottom: $w;
        }
    }
}