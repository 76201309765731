@use 'sass:math';
// Copied from boostrap

// Popovers

$border-width:                1px !default;
$border-radius-lg:            .3rem !default;
$border-color:                $gris_light !default;
$headings-color:              null !default;
$body-color:                $noir !default;

$zindex-popover:                    1060 !default;

$popover-font-size:                 0.875rem !default;
$popover-bg:                        $blanc !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              rgba(#222222, .2) !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                0 .25rem .5rem rgba($noir, .2) !default;

$popover-header-bg:                 darken($popover-bg, 3%) !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          .75rem !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $popover-header-padding-y !default;
$popover-body-padding-x:            $popover-header-padding-x !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  display: block;
  max-width: $popover-max-width;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $popover-font-size;
  // Allow breaking very long words so they don't overflow the popover's bounds
  word-wrap: break-word;
  background-color: $popover-bg;
  background-clip: padding-box;
  border: none;
  border-radius : 8px;
  box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.3);

  .popover-body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    padding: 24px;
  }

  .popover-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: -1px;
    padding: 24px 24px 0px 24px;
    @extend .ds-bold;

    & + .popover-body {
      padding: 4px 24px 24px 24px !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }
}

.bs-popover-top {
  margin-bottom: $popover-arrow-height;

  > .popover-arrow {
    bottom: subtract(-$popover-arrow-height, $popover-border-width);

    &::before {
      bottom: 0;
      border-width: $popover-arrow-height (math.div($popover-arrow-width, 2)) 0;
      border-top-color: $popover-arrow-outer-color;
    }

    &::after {
      bottom: $popover-border-width;
      border-width: $popover-arrow-height (math.div($popover-arrow-width, 2)) 0;
      border-top-color: $popover-arrow-color;
    }
  }
}

.bs-popover-end {
  margin-left: $popover-arrow-height;

  > .popover-arrow {
    left: subtract(-$popover-arrow-height, $popover-border-width);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $popover-border-radius 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      left: 0;
      border-width: (math.div($popover-arrow-width, 2)) $popover-arrow-height (math.div($popover-arrow-width, 2)) 0;
      border-right-color: $popover-arrow-outer-color;
    }

    &::after {
      left: $popover-border-width;
      border-width: (math.div($popover-arrow-width, 2)) $popover-arrow-height (math.div($popover-arrow-width, 2)) 0;
      border-right-color: $popover-arrow-color;
    }
  }
}

.bs-popover-bottom {
  margin-top: $popover-arrow-height;

  > .popover-arrow {
    top: subtract(-$popover-arrow-height, $popover-border-width);

    &::before {
      top: 0;
      border-width: 0 (math.div($popover-arrow-width, 2)) $popover-arrow-height (math.div($popover-arrow-width, 2));
      border-bottom-color: $popover-arrow-outer-color;
    }

    &::after {
      top: $popover-border-width;
      border-width: 0 (math.div($popover-arrow-width, 2)) $popover-arrow-height (math.div($popover-arrow-width, 2));
      border-bottom-color: $popover-arrow-color;
    }
  }
}

.bs-popover-start {
  margin-right: $popover-arrow-height;

  > .popover-arrow {
    right: subtract(-$popover-arrow-height, $popover-border-width);
    width: $popover-arrow-height;
    height: $popover-arrow-width;
    margin: $popover-border-radius 0; // make sure the arrow does not touch the popover's rounded corners

    &::before {
      right: 0;
      border-width: (math.div($popover-arrow-width, 2)) 0 (math.div($popover-arrow-width, 2)) $popover-arrow-height;
      border-left-color: $popover-arrow-outer-color;
    }

    &::after {
      right: $popover-border-width;
      border-width: (math.div($popover-arrow-width, 2)) 0 (math.div($popover-arrow-width, 2)) $popover-arrow-height;
      border-left-color: $popover-arrow-color;
    }
  }
}

.bs-popover-start,
.bs-popover-end,
.bs-popover-start-bottom,
.bs-popover-start-top,
.bs-popover-end-bottom,
.bs-popover-end-top {
  > .popover-arrow {
    top: -7px !important;
  }
}

.bs-popover-auto {
  &[x-placement^="top"] {
    @extend .bs-popover-top;
  }

  &[x-placement^="right"] {
    @extend .bs-popover-end;
  }

  &[x-placement^="bottom"] {
    @extend .bs-popover-bottom;
  }

  &[x-placement^="left"] {
    @extend .bs-popover-start;
  }
}

.popover-body {
  padding: $popover-body-padding-y $popover-body-padding-x;
  color: $popover-body-color;
}

@mixin popover-color($popover-bg-color) {

  &.bs-popover-top {
    > .popover-arrow {
      &::after {
        border-top-color: $popover-bg-color;
      }
    }
  }

  &.bs-popover-end {
    > .popover-arrow {
      &::after {
        border-right-color: $popover-bg-color;
      }
    }
  }

  &.bs-popover-bottom {
    > .popover-arrow {
      &::after {
        border-bottom-color: $popover-bg-color;
      }
    }
  }

  &.bs-popover-start {
    > .popover-arrow {
      &::after {
        border-left-color: $popover-bg-color;
      }
    }
  }

  .popover-body, .popover-header {
    background-color: $popover-bg-color;
  }
}

.popover-error {
  @include popover-color($alerte_rouge_negatif_bg);
}

.popover-info {
  @include popover-color($alerte_bleu_information_bg);
}

.popover-neutral {
  @include popover-color($alerte_blanc_neutre_bg);
}

.popover-valid {
  @include popover-color($alerte_vert_positif_bg);
}

.popover-warn {
  @include popover-color($alerte_jaune_attention_bg);
}

.popover-large {
  max-width: 400px;
  width: 400px;
}

.popover-content {
  display: flex;
}
