@use "sass:map";
@use "sass:math";
@import "../mixins/ds-goutte";

$large: 24px;
$height: 8px;
$side-distance: 10%;
$rotate: rotate(180deg);
$scales: 2, 3, 4;

@function getScaleMovement($scale) {
  @return (math.div($scale - 1, 0.5) * 2);
}

$scalePositions: (
  top: -8,
  topInside: 0,
  right: -8,
  rightInside: 0,
  bottom: -8,
  bottomInside: 0,
  left: -8,
  leftInside: 0,
);

.position-goutte {
  display: block;
  position: absolute;
}

.ds-goutte {
  &-bottom,
  &-bottom-right,
  &-bottom-left {
    @extend .position-goutte;
    @include mask("/assets/components/goutte-bottom.svg");
    bottom: -$height;
    &.ds-goutte-inside {
      bottom: 0px;
    }
  }

  &-top,
  &-top-right,
  &-top-left {
    @extend .position-goutte;
    @include mask("/assets/components/goutte-top.svg");
    top: -$height;
    &.ds-goutte-inside {
      transform: translateX(-50%) $rotate;
      top: 0px;
    }
  }
  &-left,
  &-left-top,
  &-left-bottom {
    @extend .position-goutte;
    @include mask("/assets/components/goutte-left.svg");
    left: -$height;
    &.ds-goutte-inside {
      left: 0px;
    }
  }
  &-right,
  &-right-top,
  &-right-bottom {
    @extend .position-goutte;
    @include mask("/assets/components/goutte-right.svg");
    right: -$height;
    &.ds-goutte-inside {
      right: 0px;
    }
  }

  &-bottom,
  &-top {
    @include size-goutte($large, $height);
    left: 50%;
    transform: translateX(-50%);
    &.ds-goutte-inside {
      transform: translateX(-50%) $rotate;
    }
  }

  &-bottom-right,
  &-top-right {
    @include size-goutte($large, $height);
    right: $side-distance;
    &.ds-goutte-inside {
      transform: $rotate;
    }
  }

  &-bottom-left,
  &-top-left {
    @include size-goutte($large, $height);
    left: $side-distance;
    &.ds-goutte-inside {
      transform: $rotate;
    }
  }

  &-left,
  &-right {
    @include size-goutte($height, $large);
    top: 50%;
    transform: translateY(-50%);
    &.ds-goutte-inside {
      transform: translateY(-50%) $rotate;
    }
  }

  &-left-top,
  &-right-top {
    @include size-goutte($height, $large);
    top: $side-distance;
    &.ds-goutte-inside {
      transform: $rotate;
    }
  }

  &-left-bottom,
  &-right-bottom {
    @include size-goutte($height, $large);
    bottom: $side-distance;
    &.ds-goutte-inside {
      transform: $rotate;
    }
  }
}

@each $scale in $scales {
  .ds-goutte-scale-#{$scale} {
    &.ds-goutte-bottom,
    &.ds-goutte-top {
      transform: translateX(-50%) scale($scale) !important;
      &.ds-goutte-inside {
        transform: translateX(-50%) $rotate scale($scale) !important;
      }
    }
    &.ds-goutte-left,
    &.ds-goutte-right {
      transform: translateY(-50%) scale($scale) !important;
      &.ds-goutte-inside {
        transform: translateY(-50%) $rotate scale($scale) !important;
      }
    }

    &.ds-goutte-bottom-right,
    &.ds-goutte-bottom-left,
    &.ds-goutte-top-right,
    &.ds-goutte-top-left,
    &.ds-goutte-left-top,
    &.ds-goutte-left-bottom,
    &.ds-goutte-right-top,
    &.ds-goutte-right-bottom {
      transform: scale($scale) !important;
      &.ds-goutte-inside {
        transform: $rotate scale($scale) !important;
      }
    }
    
    &.ds-goutte-bottom,
    &.ds-goutte-bottom-right,
    &.ds-goutte-bottom-left {
      bottom: (map.get($scalePositions, "bottom") - getScaleMovement($scale)) * 1px !important;
      &.ds-goutte-inside {
        bottom: (map.get($scalePositions, "bottomInside") + getScaleMovement($scale)) * 1px !important;
      }
    }
    &.ds-goutte-top,
    &.ds-goutte-top-right,
    &.ds-goutte-top-left {
      top: (map.get($scalePositions, "top") - getScaleMovement($scale)) * 1px !important;
      &.ds-goutte-inside {
        top: (map.get($scalePositions, "topInside") + getScaleMovement($scale)) * 1px !important;
      }
    }
    &.ds-goutte-left,
    &.ds-goutte-left-top,
    &.ds-goutte-left-bottom {
      left: (map.get($scalePositions, "left") - getScaleMovement($scale)) * 1px !important;
      &.ds-goutte-inside {
        left: (map.get($scalePositions, "leftInside") + getScaleMovement($scale)) * 1px !important;
      }
    }
    &.ds-goutte-right,
    &.ds-goutte-right-top,
    &.ds-goutte-right-bottom {
      right: (map.get($scalePositions, "right") - getScaleMovement($scale)) * 1px !important;
      &.ds-goutte-inside {
        right: (map.get($scalePositions, "rightInside") + getScaleMovement($scale)) * 1px !important;
      }
    }
  }
}
