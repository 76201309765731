// genere les class border de cette maniere border-(position)-(taille)-(type)-(couleur)
@for $i from 1 through 6 {
  @each $position in $positions {
    @each $name, $couleur in $couleurs {
      @each $type in $types {
        @if ($position == all) {
          .border-#{$position}-#{$i}-#{$type}-#{$name} {
            border: ($i * 1px) $type $couleur;
          }
        } @else {
          .border-#{$position}-#{$i}-#{$type}-#{$name} {
            border-#{$position}: ($i * 1px) $type $couleur;
          }
        }
      }
    }
  }
}
