/*!
 * Malakoff Humanis Bootstrap (https://bitbucket.org/si2m/bpp_scss_ds-bootstrap)
 */

// Core variables and mixins

@import "functions.scss";
@import "root.scss";
@import "variables";
@import "mixins";

// Reset and dependencies
@import "reset";

// Core CSS

// Components
@import "grid";
@import "typographie";
@import "colors";
@import "colors-alert";
@import "alignement";
@import "background-overlay";
@import "border";
@import "button";
@import "input";
@import "logo";
@import "icones";
@import "font";
@import "link";
@import "opacite";
@import "radius";
@import "shadow";
@import "forme-brand";
@import "checkbox";
@import "dropdown";
@import "pagination";
@import "loader";
@import "spacing";
@import "table";

// Components ds-tools

@import "components/alert";
@import "components/header-title";
@import "components/stepper";
@import "components/typeform";
@import "components/modal";
@import "components/popover";
@import "components/card";
@import "components/card-list";
@import "components/input";
@import "components/input-typeform";
@import "components/input-form";
@import "components/textarea-form";
@import "components/select-input";
@import "components/date3-input";
@import "components/quantity-input";
@import "components/file-input";
@import "components/spinner";
@import "components/ds-card";
@import "components/footer";
@import "components/dropdown";
@import "components/dropdown-typeform";
@import "components/ds-goutte";
@import "components/recherche";
@import "components/side-panel";
@import "components/tabs3";
// Utility classes

@import "modal";
@import "tooltip";
@import "message";
