.footer_style {
    background-color: transparent !important;
}

#main-content {
    background-image: linear-gradient(19deg, #fff 44%, #ffe5d9 93%);
}

.subtitle {
    max-width: 480px;

    @include media-breakpoint-down('sm') {
        max-width: 350px;
        margin: auto;
    }
}

.epargne-slider {
    position: relative;

    .tns-ovh {
        padding: 0.25rem 0; // overflow hidden & box-shadow sur les card
    }

    .slides {
    }

    >.controls {
        display: flex;

        >.prev,
        >.next {
            z-index: 2;
            display: flex;
            align-items: center;
            transition: color 0.2s, transform 0.2s ease, opacity 0.2s 0.2s;

            &:hover,
            &:focus {
                color: $corail_mh_brand;
                transform: scale(1.1);
            }
        }

        >.prev {
            left: -3rem;
            justify-content: flex-end;
            margin: 32px 48px 32px auto;
        }

        >.next {
            right: -3rem;
            margin: 32px auto 32px 48px;
        }
    }

    .slide {
        padding: 0 1rem;
    }

    .slider-card {
        overflow: hidden;
        background-color: #fff;

        .thumb {
            margin: 0 auto;
            width: 63%;
            padding-top: 50.85%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }

        &:focus,
        &:hover,
        &:focus-within {
            box-shadow: 0 2px 4px 0 rgba(34, 34, 34, 0.3) !important;
        }
    }
}

.footer-img {
    line-height: 0;
    margin: 0 72px 0 92px;

    @include media-breakpoint-down('sm') {
        margin: 0;
    }
} 


.slide-indicators {
    margin: -47px 0 0 0;
    position: relative;
    bottom: 1px;

    span {
        text-indent: 0;
        width: 8px;
        height: 8px;
        margin-left: 4px;
        margin-right: 4px;
        border-radius: 50%;
        background: $gris_dark;
        opacity: 1;
        transition: $gris_dark 0.4s;

        &.active {
            background: $corail_mh_brand;
            transition: $gris_dark 0.2s;
            width: 30px;
            height: 8px;
            border-radius: 4px;
        }
    }
}

