$positions-spacing: top, bottom, right, left, vertical, horizontal;
$types-spacing: (
        m: margin,
        p: padding
);
$spacing-number: 0, 1, 2, 3, 4, 5, auto;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: (
                                $spacer * 0.25
                        ),
                        2: (
                                $spacer * 0.5
                        ),
                        3: $spacer,
                        4: (
                                $spacer * 1.5
                        ),
                        5: (
                                $spacer * 3
                        )
                ),
                $spacers
);
