#skiplink {
    position: fixed;
    z-index: 999;
    display: inline-block;
    top: -3rem;
    left: 50%;
    padding: 0.5rem 1rem;
    transform: translateX(-50%);
    background: $corail_mh_brand;
    border-radius: 0 0 4px 4px;
    color: $blanc;
    white-space: nowrap;
    transition: top 0.2s;

    &:focus {
        top: 0;
        text-decoration: none;
    }
}

.header {
    @include mobileFullWidth();
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: $blanc;
}

body {
    padding-top: 56px;

    @include media-breakpoint-up('md') {
        padding-top: 87px;
    }
}

.menu-navbar {
    display: flex;
    justify-content: flex-end;
    height: 100%;

    .open-menu-link {
      text-decoration: none;
    }

    &__mobile-toggle {
        display: flex;
        align-items: center;

        &__label {
            $label: &;
            margin-right: 0.5rem;
            font-size: 12px;
            line-height: 17px;
            text-align: right;
            transform: translateY(-100%);
            transition: transform 0.2s ease;

            &__on {
                opacity: 0;
                transition: opacity 0.2s;
            }

            &__off {
                position: absolute;
                width: 100%;
                opacity: 1;
                transition: opacity 0.2s;
            }

            &.is-on {
                transform: translateY(0);

                #{$label}__on {
                    opacity: 1;
                    transition: opacity 0.2s;
                }

                #{$label}__off {
                    opacity: 0;
                    transition: opacity 0.2s;
                }
            }
        }

        &__burger {
            position: relative;

            &,
            &:before,
            &:after {
                height: 2px;
                width: 16px;
                background: $noir;
                border-radius: 2px;
                transition: transform 0.2s ease, background-color 0.2s;
            }

            &:before,
            &:after {
                content: "";
                display: block;
                position: absolute;
            }

            &:before {
                transform: translateY(-5px);
            }

            &:after {
                transform: translateY(5px);
            }

            &.is-on {
                background: transparent;

                &:before {
                    transform: rotate(-45deg);
                }

                &:after {
                    transform: rotate(45deg);
                }
            }
        }
    }

    &__container {
        position: absolute;
        top: 56px;
        left: 0;
        right: 0;
        background: $blanc;
        box-shadow: 0 2px 1px 0 rgba($noir, 0.3);
        opacity: 0;
        transform: scaleY(0);
        transform-origin: center top;
        transition: opacity 0.2s, transform 0.2s ease-out;

        &.is-visible {
            opacity: 1;
            transform: scaleY(1);
        }
    }

    &__top-level {
        $top: &;
        // margin: 0 1rem;
        // border-top: 1px solid $gris_light;

        &__link {
           // display: flex;
            position: relative;
            align-items: center;
            width: 100%;
            padding: 1rem 0;
            transition: color 0.2s;
            text-decoration: none;

            >.dsicon-chevron-bas {
                display: none;
                position: absolute;
                top: 95%;
                left: 50%;
                opacity: 0;
                font-size: 0.8rem;
                transform: translateX(-50%);
                transition: opacity 0.2s;

                @include media-breakpoint-up('md') {
                    display: block;
                }
            }

            &:hover {
                text-decoration: none;
            }

            &--corail {
                color: $corail_mh_brand;
            }

            &.is-active {
                color: $corail_mh_brand;

                >.dsicon-chevron-bas {
                    opacity: 1;
                }
            }
        }

        &__icon {
            width: 1.5rem;
            text-align: left;
        }

        &__chevron {
            margin-left: auto;
            transition: transform 0.2s ease;

            &.is-rotated {
                transform: rotate(180deg);
            }
        }

        &.is-corail {
            #{$top}__link {
                color: $corail_mh_brand;
            }
        }
    }

    @include media-breakpoint-up('md') {
        &__mobile-toggle {
            display: none;
        }

        &__container {
            position: static;
            display: flex;
            width: 100%;
            box-shadow: none;
            background: transparent;
            opacity: 1;
            transform: none !important; // évite bug visuel après clic sur un lien
            transition: none;
        }

        &__top-level {
            $top: &;
            margin: 0 1.5rem 0 0;
            border-top: none;

            &__link {
                position: relative;
                padding: 0.5rem 0;

                &:hover,
                &:focus {
                    color: $corail_mh_brand;
                }
            }

            &__chevron {
                display: none;
            }

            &.is-corail {
                margin-left: auto;

                #{$top}__link {
                    &:hover,
                    &:focus {
                        color: $corail_mh_dark;
                    }
                }

                & ~ & {
                    // https://stackoverflow.com/questions/2717480/css-selector-for-first-element-with-class#answer-8539107
                    margin-left: 0;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.popover__wrapper {
    position: relative;
    display: inline-block;
}

.popover__title {
    font-size: 16px;
    line-height: 36px;
    text-decoration: none;
    color: rgb(228, 68, 68);
    text-align: center;
}

.popover__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: -56px;
    transform: translate(0, 10px);
    background-color: #ffffff;
    padding: 1.5rem;
    box-shadow: 0 0px 17px 0 rgba(0, 0, 0, 0.26);
    border-radius: 8px;
    width: auto;
}

.popover__content:before {
    position: absolute;
    z-index: -1;
    content: "";
    left: calc(78% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    transition-duration: 0.3s;
    transition-property: transform;

  }
  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    transform: translate(0, -6px);
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .menu a{
    font-size: 14px;
  }

  .mobile__menu {
      display: none;
  }

.list__menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    width: 100%;
  }
  .list__menu > li {
    display: flex;
    left: -56px;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: #000;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
  }

  .list__menu > li > a {
    padding: 7px;
  }

  .list__menu > li:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: 1px solid #e1e1e1;
  }

  .list__menu > li:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom: none;
    box-shadow: 0 3px 2px -2px #000;
  }

  .chevron-mobile {
    margin-left: 111px;
    font-size: 12px;
    margin-top: 11px;
    margin-left: auto;
    margin-right: 16px;
  }
