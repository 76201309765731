.typeform-block {
    @include mobileFullWidth();
    position: fixed;
    visibility: hidden;
    display: grid;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    grid-template-columns: 91px auto auto;
    grid-template-rows: 56px 54px 1fr;
    padding: 0 1rem;
    max-height: 100vh;
    overflow: hidden auto;
    background: $blanc;
    opacity: 0;
    pointer-events: none;
    transform-origin: center top;
    transform: translateY(-100%);
    transition: opacity 0.2s, transform 0s 0.2s;

    @include media-breakpoint-up('md') {
        bottom: auto;
        padding: 0 3rem 2rem;
        grid-template-columns: auto auto auto;
        grid-template-rows: 5rem 0 auto;
        overflow: hidden;
        box-shadow: 0 0 0 120vh rgba($noir, 0.2) ,0 3px 6px 0 rgba($noir, 0.3);
    }

    @include media-breakpoint-up('xl') {
        padding-left: calc((100vw - 1408px) / 2);
        padding-right: calc((100vw - 1408px) / 2);
    }

    >.logo {
        grid-area: 1 / 1;

        @include media-breakpoint-up('md') {
            display: none;
        }

        >a {
            display: block;
            width: 100%;
            background-position: center center;
        }
    }

    >.hide {
        position: relative;
        z-index: 3;
        grid-area: 1 / 3;
        justify-self: end;

        &:hover,
        &:focus {
            color: $corail_mh_brand;
        }
    }

    >.previous {
        position: relative;
        z-index: 3;
        grid-area: 2 / 1 / 3 / 4;
        display: flex;
        align-items: center;
        border-top: 1px solid $gris_light;
        border-bottom: 1px solid $gris_light;

        @include media-breakpoint-up('md') {
            grid-area: 1 / 1;
            border: none;
        }

        >button {
            &:hover,
            &:focus {
                color: $corail_mh_brand;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }

    >.question {
        $hideTranslate: 140%;

        visibility: hidden;
        position: relative;
        z-index: 1;
        grid-area: 3 / 1 / 4 / 4;
        padding-top: 1rem;
        opacity: 0;
        pointer-events: none;
        transform: translateX(0);
        transition-property: transform;
        transition-duration: 0s;
        transition-timing-function: ease-out;

        @include media-breakpoint-up('md') {
            display: flex;
            transform: translateY(0);
        }

        >.help {
            position: relative;
            display: block;


            @include media-breakpoint-up('md') {
                flex: 0 0 calc(100% - 672px);
            }

            @include media-breakpoint-up('lg') {
                flex-basis: 434px;
            }

            &,
            & > div,
            & > a {
                position: relative;
                display: block;

                >i {
                    position: absolute;
                    top: 0.5rem;
                    right: 0.5rem;
                }
            }
        }

        &.is-current {
            visibility: inherit;
            opacity: 1;
            pointer-events: all;
            transform: translateX(0);

            @include media-breakpoint-up('md') {
                transform: translateY(0)
            }
        }

        &.is-above {
            transform: translateX(-$hideTranslate);

            @include media-breakpoint-up('md') {
                transform: translateY(-$hideTranslate)
            }
        }

        &.is-below {
            transform: translateX($hideTranslate);

            @include media-breakpoint-up('md') {
                transform: translateY($hideTranslate)
            }
        }

        &.is-moving {
            opacity: 1;
            transition-duration: 0.2s;
        }
    }

    &.is-visible {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
        transition: opacity 0.2s, transform 0.4s ease-out;
    }

    >.mask {
        display: none;
        position: relative;
        z-index: 2;

        @include media-breakpoint-up('md') {
            display: block;
            background: linear-gradient(180deg, $blanc 50%, rgba($blanc, 0) 100%);
            grid-area: 1 / 1 / 2 / 4;
        }
    }
}

.typeform-form {
    @include media-breakpoint-up('md') {
        flex: 1 1 auto;
    }

    >.choices {
        >.choice {
            border: 1px solid $gris_light;
            color: $gris_dark;
            text-decoration: none;
            text-align: center;
            transition: box-shadow 0.2s, color 0.2s, background-color 0.2s;

            @include media-breakpoint-up('md') {
                flex: 0 0 192px;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 166px;
            }

            @include media-breakpoint-up('lg') {
                flex-basis: 214px;
            }

            & + .choice {
                margin-top: 1rem;

                @include media-breakpoint-up('md') {
                    margin: 0 0 0 2rem;
                }
            }

            &:hover,
            &:focus {
                box-shadow: 0 3px 6px 0 rgba($noir, 0.3);
                outline: none;
                color: $noir;
            }

            &:active,
            &:focus:not(:focus-visible) {
                background: $bleu_turquoise_light_overide;
                border-color: $bleu_turquoise_light_overide;
                color: $bleu_dark_overide !important;
                box-shadow: none;
            }
        }
    }

    .choices-ppa-toutm {
      cursor: pointer;
      .choice {
        width: 288px;
        // height: 253px;
        flex-basis: auto;
        position: relative;

        .choice-hover {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(255,255,255,0.7);
        }

        img {
          width: 288px;
          // height: 199px;
        }

        @include media-breakpoint-up('md') {
          width: 255px;
          // height: 230px;

          img {
            width: 255px;
            // height: 176px;
          }

          &.highlighted-choice {
            width: 319px;
            // height: 274px;

            img {
              width: 319px;
              // height: 212px;
            }
          }
        }
      }
    }
}

#typeform-siren-pcpme,
#typeform-numero-adherent {
    position: relative;
    overflow: hidden;

    .ds-loader {
        position: absolute;
        z-index: 90;
        top: 50%;
        left: 50%;
        box-shadow: 0 0 0 50vh rgba($blanc, 0.5);
        opacity: 0;
        transition: opacity 0.2s;

        &.is-visible {
            opacity: 1;
        }
    }
}

.typeform-tp-container {
    display: grid;
    align-items: center;
    justify-content: center;

    > * {
        grid-area: 1 / 1;
        max-width: 288px;
        width: 100%;
        opacity: 0;
        transition: opacity 0.2s, transform 0.2s;

        @include media-breakpoint-up('md') {
            max-width: 400px;
        }

        &:first-child {
            transform: translateX(-20%) scale(0.5);
        }

        &:last-child {
            transform: translateX(20%) scale(0.5);
        }

        &.is-visible {
            opacity: 1;
            transform: translateX(0);
            transition: opacity 0.2s 0.2s, transform 0.2s 0.2s;
        }
    }
}

.faq-smile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 120px;
    max-height: 140px;
    text-align: center;
    background-image: url("../img/faq-smile.png");
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    color: $bleu_dark_overide;
    background-repeat: no-repeat;

    &,
    & > div,
    & > a {
        display: flex !important;
    }
}

