// $class: ".ds-header-title";

// #{$class} {
//   width: 100%;
//   height: 12rem;
//   overflow: hidden;
//   position: relative;
//   display: block;

//   #{$class}__background {
//     position: absolute;
//     margin-left: -80px;
//     margin-right: -80px;
//     height: 12rem;
//     width: calc(100% + 160px);
//     border-radius: 0 0 50% 50%/0 0 3.5rem 3.5rem;
//     background-color: $gris_sable_dark;
//     z-index: -1;
//   }

//   #{$class}__content {
//     text-align: center;
//     height: 100%;
//     display: flex;
//     align-items: center;

//     > * {
//       margin-left: auto;
//       margin-right: auto;
//       @extend .ds-bold;
//     }
//   }
// }

.header-title {
  padding: 32px 32px 71px;
  border-radius: 0 0 50% 50% / 0 0 30px 30px;
}
 