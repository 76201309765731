
.ds-spinner--fullscreen.ds-spinner--shown {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: rgba(white, 0.6);

  .ds-spinner-container {
    box-sizing: content-box;
    width: fit-content;
    height: fit-content;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .ds-loader {
    font-size: 4rem;
  }
}

.ds-spinner--fit-content.ds-spinner--shown {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.8);

  .ds-spinner-container {
    box-sizing: content-box;
    width: fit-content;
    height: fit-content;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .ds-loader {
    font-size: 3.5rem;
  }
}


