@mixin square($w) {
    width: $w;
    height: $w;
}

.unstyled-button {
    padding: 0;
    border: none;
    background: none;

    @include genericFocus();
}

.link-button {
    @extend .unstyled-button;

    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

@include media-breakpoint-up('md') {
    .w-md-75 {
        width: 75%;
    }
}



@mixin mobileFullWidth($padding: 0px) {
    @include media-breakpoint-down('sm') {
        & {
            width: calc(100vw - #{$padding})
        }
    }
}

// Todo à ajouter dans DSB
@import "./node_modules/ds-bootstrap/src/scss/variables";

.ds-message {
  position: relative;
  opacity: 1;
  min-height: 70px;
  padding: 24px;
  box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.3);
  margin-bottom: 15px;
  &.message-info {
    background-color: $alerte_bleu_information;
  }
  &.message-info_secondaire {
    background-color: $gris_background;
  }
  &.message-alerte {
    background-color: $alerte_jaune_attention;
  }
  &.message-erreur {
    background-color: $alerte_rouge_negatif;
  }
  &.message-succes {
    background-color: $alerte_vert_positif;
  }

  .dsicon-cercle-check {
    font-size: 1.5rem;
  }
  p,
  i {
    color: $noir;
  }
  .dsicon-close {
    margin: 0;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    margin-left: 50px;
  }
  .dsicon-cercle-information,
  .dsicon-interrogation,
  .dsicon-cercle-check,
  .dsicon-warning {
    margin-right: 24px;
  }
}

.steps {
  position: relative;

  ul {
    padding: 0px;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .step-item {
        outline: none;
        position: relative;
        width: 100%;
        display: flex;

      &::before {
        content: "";
        width: 4px;
        top: 50%;
        left: 18px;
        display: block;
        position: absolute;
        background-color: $gris_light;
        height: 100%;
      }




      .step-subTitle {
        font-family: "poppins-bold", Arial, Helvetica, sans-serif;
      }

      .step-content {
        width: 40px;
        height: 40px;
        font-size: 1.125rem;
        border-radius: 50%;
        background-color: $bleu_turquoise_light_overide;
        box-shadow: 0 0 2px 2px $blanc;
        color: $bleu_dark_overide;
        text-align: center;
        font-weight: bolder;
        object-fit: contain;
        position: relative;
        z-index: 2;





        .step-image {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }


        &.current {

          font-family: "poppins-bold", Arial, Helvetica, sans-serif;

          .step-content {
            background-color: $bleu_turquoise_light_overide;
            background-color: darken($bleu_turquoise_light_overide, 20%);
            color: darken($bleu_dark_overide, 40%);
            font-family: "poppins-bold", Arial, Helvetica, sans-serif;
          }

        }

        &.disabled {
          background-color: $bleu_turquoise_light_overide;
          background-color: lighten($bleu_turquoise_light_overide, 20%);
          color: lighten($bleu_dark_overide, 40%);

          .step-image {
            opacity: 0.6;
          }

        }
      }

     .step-number {
      font-size: 20px;
      font-weight: bolder;
      line-height: 2;
      text-align: center;
      }


      .subTitle {
        position: absolute;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }

    }
  }
}
