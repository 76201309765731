.tabs3 {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 1rem;
  
    @include media-breakpoint-up("md") {
      flex-wrap: wrap;
    }

    .tab3 {
        margin: 0rem 0.25rem;
      
        @include media-breakpoint-up("md") {
          margin: 0rem 0.5rem;
        }
      
        button {
          text-align: center;
          border-radius: 2rem;
          height: 2rem;
          font-size: 0.875rem;
          border: none;
          padding: 0.375rem 1rem;
          display: inline-block;
          white-space: nowrap; // permet de prendre la largeur du texte
          color: $gris_dark;
      
          @include media-breakpoint-up("md") {
            padding: 0.375rem 1.5rem;
            font-size: 1rem;
          }
      
          &:not(.selected) {
            box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.3);
            background-color: #ffffff;
      
            &:not(:disabled):not(.disabled):hover {
              color: $corail_mh_dark;
              transition: all 0.3s ease;
              outline: none !important;
            }
          }
      
          &.selected {
            background: $corail_mh_brand;
            color: $blanc;
          }
      
          &.disabled {
            opacity: 0.3;
            cursor: default;
          }
      
          &:focus {
            outline: none;
          }
      
          & > i {
            margin-right: 0.5rem;
            vertical-align: middle;
          }
        }
      
        svg {
          fill: $corail_mh_brand;
          margin-top: -1rem;
          height: 0.5rem;
          width: 1.5rem;
        }
      
        .bottom-wave {
          height: 0.5rem;
        }
    }
}