.ds-recherche {
    margin-bottom: 0;
    margin-top: 0;
  
    input {
      padding: 5px 49px 5px 16px;
      &:hover,
      &:focus {
        cursor: pointer;
      }
  
      &:focus {
        ~ .bar {
          position: relative;
          border-bottom: 1px solid $corail_mh_brand;
          display: block;
  
          &:before {
            background: $corail_mh_brand;
            transition: left 0.28s ease, width 0.28s ease;
          }
        }
  
        ~ i.dsicon-search {
          color: $blanc;
          background-color: $corail_mh_brand;
  
          &:hover {
            background-color: $corail_mh_dark;
          }
        }
      }
    }
  
    i.dsicon-search {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 40px;
      width: 40px;
      font-size: 1rem;
      right: 4px;
      color: $noir;
      background-color: $gris_background;
      border-radius: 50%;
      padding-top: 12px;
      padding-left: 12px;
      cursor: pointer;
  
      &:hover {
        background-color: $gris_light;
      }
    }
  
    .control-label {
      font-size: inherit;
    }
  
    .options {
      display: none;
      position: absolute;
      background: $blanc;
      border-radius: 5px;
      width: 100%;
      z-index: 99;
      top: 57px;
      padding: 8px 0px 8px 0px;
      max-height: 310px;
      overflow: auto;
  
      .option {
        position: relative;
        padding: 16px;
  
        p {
          color: $gris_dark;
        }
  
        &:hover {
          background-color: $gris_background;
          cursor: pointer;
          p {
            color: $noir
          }
        }
  
        &.active {
          p {
            color: $vert_positif;
          }
  
          .dsicon-check {
            display: inline;
          }
        }
  
        .dsicon-check {
          display: none;
        }
  
        &:not(:last-child) .sub-libelle {
          ~ .line {
            display: inherit;
          }
        }
  
        .line {
          display: none;
          position: absolute;
          bottom: 0;
          left: 15px;
          right: 15px;
          height: 1px;
          background-color: $gris_light;
        }
      }
    }
  
    ~ .message-aide {
      display: inherit;
      color: $gris_dark;
      font-size: 0.75rem;
      margin-left: 8px;
      margin-top: 4px;
      position: absolute;
      display: inherit;
    }
    ~ .message-erreur {
      position: absolute;
      display: none;
      color: $rouge_negatif;
      font-size: 0.75rem;
      margin-left: 8px;
    }
    .message-erreur {
      color: $rouge_negatif;
      font-size: 0.75rem;
      margin-left: 8px;
    }
    .sub-libelle {
      color: $gris_dark;
    }
    .control-label {
      width: calc(100% - 3.5rem);
      left: 1rem !important;
      padding-left: 0 !important;
      color: $noir;
  
      &.placeholder {
        color: $gris_dark;
      }
    }
    .disabled {
      pointer-events: none;
      background-color: rgba(246, 246, 246, 0.3) !important;
      opacity: 0.3;
    }
  }
  