.env-switch {
    &__wrapper {
        position: relative;
    }

    &__button {
        border-bottom: 1px dashed $noir;

        &:hover,
        &:focus {
            border-color: $corail_mh_brand;
            color: $corail_mh_brand;
        }
    }

    &__list {
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        
        @include media-breakpoint-up('md') {
            left: auto;
            right: 0;
        }
    }
}