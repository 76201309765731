@mixin mask($url) {
    &:after {
      content: ' ';
      display: block;
      -webkit-mask: url($url) no-repeat 50% 50%;
      mask: url($url) no-repeat 50% 50%;
      -webkit-mask-size: cover;
      mask-size: cover;
    }
  }
  
  @mixin size-goutte($width, $height) {
    width: $width;
    height: $height;
    &:after {
      width: $width;
      height: $height;
    }
  }