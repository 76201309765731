:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .375rem;
  --bs-border-radius-sm: .25rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6c757d;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--bs-border-color);
  background-color: #fff;
  border-radius: .375rem;
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: .875em;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 20rem) {
  .container-sm, .container {
    max-width: 18rem;
  }
}

@media (width >= 64rem) {
  .container-md, .container-sm, .container {
    max-width: 62rem;
  }
}

@media (width >= 80rem) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 78rem;
  }
}

@media (width >= 90rem) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 90rem;
  }
}

.row {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem;
}

@media (width >= 20rem) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 64rem) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 80rem) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (width >= 90rem) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: #0000000d;
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: #0000001a;
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: #00000013;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 19.98rem) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 63.98rem) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 79.98rem) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 89.98rem) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6c757d;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.form-control::file-selector-button {
  margin: -.375rem -.75rem;
  color: #212529;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #e9ecef;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .75rem;
  padding: .375rem .75rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  color: #212529;
  border: solid #0000;
  background-color: #0000;
  border-width: 1px 0;
  width: 100%;
  margin-bottom: 0;
  padding: .375rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  border-radius: .25rem;
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  border-radius: .5rem;
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .5rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  -moz-padding-start: calc(.75rem - 3px);
  color: #212529;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .75rem;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #212529;
}

.form-select-sm {
  border-radius: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  vertical-align: top;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  width: 1em;
  height: 1em;
  margin-top: .25em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .3;
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
  padding: 1rem .75rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .75rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 3;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .375rem;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .25rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback), .input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.valid-feedback {
  color: #198754;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #198754e6;
  border-radius: .375rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #198754;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated .input-group .form-select:valid, .input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  color: #dc3545;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .375rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .1875rem) top calc(.375em + .1875rem);
  padding-right: calc(1.5em + .75rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, right 2.25rem center;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem);
  padding-right: 4.125rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.5em + 3.75rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated .input-group .form-select:invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  --bs-btn-padding-x: .75rem;
  --bs-btn-padding-y: .375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .375rem;
  --bs-btn-box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px #00000013;
  --bs-btn-disabled-opacity: .3;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check:focus + .btn, .btn:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}

.btn-link:focus {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: .5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .25rem;
  --bs-btn-padding-x: .5rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .25rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .5rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: .375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: .5rem;
  --bs-dropdown-box-shadow: 0 .5rem 1rem #00000026;
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: .25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: 1000;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 20rem) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 64rem) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 80rem) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 90rem) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  text-decoration: none;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: #ffffff26;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: .375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .5rem;
  --bs-navbar-color: #0000008c;
  --bs-navbar-hover-color: #000000b3;
  --bs-navbar-disabled-color: #0000004d;
  --bs-navbar-active-color: #000000e6;
  --bs-navbar-brand-padding-y: .3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: #000000e6;
  --bs-navbar-brand-hover-color: #000000e6;
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .375rem;
  --bs-navbar-toggler-focus-width: .25rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
  text-decoration: none;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 20rem) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 64rem) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 80rem) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 90rem) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: #ffffff8c;
  --bs-navbar-hover-color: #ffffffbf;
  --bs-navbar-disabled-color: #ffffff40;
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: #ffffff1a;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: .5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: .375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(.375rem - 1px);
  --bs-card-cap-padding-y: .5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: #00000008;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 16px;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 20rem) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: #000;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .375rem;
  --bs-accordion-inner-border-radius: calc(.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(var(--bs-accordion-border-width) * -1) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: #6c757d;
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: #6c757d;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: #fff;
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: #dee2e6;
  --bs-pagination-border-radius: .375rem;
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: #e9ecef;
  --bs-pagination-hover-border-color: #dee2e6;
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #e9ecef;
  --bs-pagination-focus-box-shadow: 0 0 0 .25rem #0d6efd40;
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: #6c757d;
  --bs-pagination-disabled-bg: #fff;
  --bs-pagination-disabled-border-color: #dee2e6;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: .5rem;
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .25rem;
}

.badge {
  --bs-badge-padding-x: .65em;
  --bs-badge-padding-y: .35em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: .375rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .375rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.25rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #084298;
  --bs-alert-bg: #cfe2ff;
  --bs-alert-border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  --bs-alert-color: #0f5132;
  --bs-alert-bg: #d1e7dd;
  --bs-alert-border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  --bs-alert-color: #055160;
  --bs-alert-bg: #cff4fc;
  --bs-alert-border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  --bs-alert-color: #664d03;
  --bs-alert-bg: #fff3cd;
  --bs-alert-border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  --bs-alert-color: #636464;
  --bs-alert-bg: #fefefe;
  --bs-alert-border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  --bs-alert-color: #141619;
  --bs-alert-bg: #d3d3d4;
  --bs-alert-border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: .375rem;
  --bs-progress-box-shadow: inset 0 1px 2px #00000013;
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: #00000020;
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: .5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  text-decoration: none;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 20rem) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 64rem) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 80rem) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 90rem) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  color: #000;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: #000;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 32px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: ;
  --bs-toast-bg: #ffffffd9;
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: .375rem;
  --bs-toast-box-shadow: 0 .5rem 1rem #00000026;
  --bs-toast-header-color: #6c757d;
  --bs-toast-header-bg: #ffffffd9;
  --bs-toast-header-border-color: #0000000d;
  width: var(--bs-toast-max-width);
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  z-index: 1090;
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(var(--bs-toast-padding-x) * -.5);
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .5rem;
  --bs-modal-box-shadow: 0 .125rem .25rem #00000013;
  --bs-modal-inner-border-radius: calc(.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: .5;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(var(--bs-modal-header-padding-y) * -.5) calc(var(--bs-modal-header-padding-x) * -.5) calc(var(--bs-modal-header-padding-y) * -.5) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 20rem) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 .5rem 1rem #00000026;
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 80rem) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 90rem) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 19.98rem) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 63.98rem) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 79.98rem) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 89.98rem) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .5rem;
  --bs-tooltip-padding-y: .25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: .875rem;
  --bs-tooltip-color: #fff;
  --bs-tooltip-bg: #000;
  --bs-tooltip-border-radius: .375rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: #fff;
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: .5rem;
  --bs-popover-inner-border-radius: calc(.5rem - 1px);
  --bs-popover-box-shadow: 0 .5rem 1rem #00000026;
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: .5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--bs-heading-color);
  --bs-popover-header-bg: #f0f0f0;
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: #212529;
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[x-placement^="top"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[x-placement^="top"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[x-placement^="top"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[x-placement^="top"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[x-placement^="right"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[x-placement^="right"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[x-placement^="right"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[x-placement^="right"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(var(--bs-popover-arrow-width) * -.5);
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(var(--bs-popover-arrow-height) * -1 - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[x-placement^="left"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[x-placement^="left"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[x-placement^="left"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[x-placement^="left"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 .125rem .25rem #00000013;
}

@media (width <= 19.98rem) {
  .offcanvas-sm {
    z-index: 1045;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 19.98rem) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 19.98rem) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 20rem) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 63.98rem) {
  .offcanvas-md {
    z-index: 1045;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 63.98rem) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 63.98rem) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 64rem) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 79.98rem) {
  .offcanvas-lg {
    z-index: 1045;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 79.98rem) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 79.98rem) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 80rem) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 89.98rem) {
  .offcanvas-xl {
    z-index: 1045;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 89.98rem) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 89.98rem) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 90rem) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: 1045;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(var(--bs-offcanvas-padding-y) * -.5);
  margin-right: calc(var(--bs-offcanvas-padding-x) * -.5);
  margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #0d6efd !important;
}

.link-primary:hover, .link-primary:focus {
  color: #0a58ca !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64 !important;
}

.link-success {
  color: #198754 !important;
}

.link-success:hover, .link-success:focus {
  color: #146c43 !important;
}

.link-info {
  color: #0dcaf0 !important;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3 !important;
}

.link-warning {
  color: #ffc107 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffcd39 !important;
}

.link-danger {
  color: #dc3545 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #b02a37 !important;
}

.link-light {
  color: #f8f9fa !important;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb !important;
}

.link-dark {
  color: #212529 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #1a1e21 !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 20rem) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 64rem) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 80rem) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 90rem) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .25rem !important;
  margin-right: .25rem !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .25rem !important;
}

.me-2 {
  margin-right: .5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .25rem !important;
}

.ms-2 {
  margin-left: .5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -.25rem !important;
  margin-right: -.25rem !important;
}

.mx-n2 {
  margin-left: -.5rem !important;
  margin-right: -.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -.25rem !important;
  margin-bottom: -.25rem !important;
}

.my-n2 {
  margin-top: -.5rem !important;
  margin-bottom: -.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.mt-n2 {
  margin-top: -.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -.25rem !important;
}

.me-n2 {
  margin-right: -.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -.25rem !important;
}

.mb-n2 {
  margin-bottom: -.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -.25rem !important;
}

.ms-n2 {
  margin-left: -.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-2 {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .25rem !important;
}

.pe-2 {
  padding-right: .5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .25rem !important;
}

.ps-2 {
  padding-left: .5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .25rem !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + .9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + .6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (width >= 20rem) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-sm-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .25rem !important;
  }

  .mt-sm-2 {
    margin-top: .5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .25rem !important;
  }

  .me-sm-2 {
    margin-right: .5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .25rem !important;
  }

  .ms-sm-2 {
    margin-left: .5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-sm-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-sm-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-sm-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-sm-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .25rem !important;
  }

  .pt-sm-2 {
    padding-top: .5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .25rem !important;
  }

  .pe-sm-2 {
    padding-right: .5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .25rem !important;
  }

  .ps-sm-2 {
    padding-left: .5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .25rem !important;
  }

  .gap-sm-2 {
    gap: .5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 64rem) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-md-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .25rem !important;
  }

  .mt-md-2 {
    margin-top: .5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .25rem !important;
  }

  .me-md-2 {
    margin-right: .5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .25rem !important;
  }

  .mb-md-2 {
    margin-bottom: .5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .25rem !important;
  }

  .ms-md-2 {
    margin-left: .5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-md-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-md-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-md-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-md-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -.25rem !important;
  }

  .me-md-n2 {
    margin-right: -.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-md-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-md-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-md-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .25rem !important;
  }

  .pt-md-2 {
    padding-top: .5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .25rem !important;
  }

  .pe-md-2 {
    padding-right: .5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .25rem !important;
  }

  .pb-md-2 {
    padding-bottom: .5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .25rem !important;
  }

  .ps-md-2 {
    padding-left: .5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .25rem !important;
  }

  .gap-md-2 {
    gap: .5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 80rem) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-lg-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .25rem !important;
  }

  .mt-lg-2 {
    margin-top: .5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .25rem !important;
  }

  .me-lg-2 {
    margin-right: .5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .25rem !important;
  }

  .ms-lg-2 {
    margin-left: .5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-lg-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-lg-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-lg-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .25rem !important;
  }

  .pt-lg-2 {
    padding-top: .5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .25rem !important;
  }

  .pe-lg-2 {
    padding-right: .5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .25rem !important;
  }

  .ps-lg-2 {
    padding-left: .5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .25rem !important;
  }

  .gap-lg-2 {
    gap: .5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 90rem) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .25rem !important;
    margin-right: .25rem !important;
  }

  .mx-xl-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important;
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .25rem !important;
  }

  .mt-xl-2 {
    margin-top: .5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .25rem !important;
  }

  .me-xl-2 {
    margin-right: .5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .25rem !important;
  }

  .ms-xl-2 {
    margin-left: .5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.25rem !important;
    margin-right: -.25rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.5rem !important;
    margin-right: -.5rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .mx-xl-n4 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -.25rem !important;
    margin-bottom: -.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -.5rem !important;
    margin-bottom: -.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .25rem !important;
    padding-right: .25rem !important;
  }

  .px-xl-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .px-xl-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .px-xl-4 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-5 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important;
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .25rem !important;
  }

  .pt-xl-2 {
    padding-top: .5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .25rem !important;
  }

  .pe-xl-2 {
    padding-right: .5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .25rem !important;
  }

  .ps-xl-2 {
    padding-left: .5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .25rem !important;
  }

  .gap-xl-2 {
    gap: .5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root {
  --corail_mh_brand: #e2250c;
  --corail_mh_dark: #ad1400;
  --corail_mh_light: #ff4b33;
  --gris_sable_light: #ede8e6;
  --gris_sable_dark: #e4d8c4;
  --jaune_ivoire_light: #fcf7e7;
  --jaune_ivoire_dark: #f6ebc8;
  --jaune_light: #f4ec5b;
  --jaune_dark: #f9bd00;
  --orange_normal: #ff8347;
  --orange_dark: #d14600;
  --orange_hover: var(--orange_dark);
  --vert_light: #03dfb2;
  --vert_dark: #008275;
  --bleu_turquoise_light: #5ad5d9;
  --bleu_turquoise_middle: #008299;
  --bleu_turquoise_dark: #006374;
  --bleu_light: #4283d1;
  --bleu_dark: #1a283e;
  --bleu_klein_light: #5f65e3;
  --bleu_klein_dark: #484dad;
  --bleu_klein_dark_a: #30369e;
  --violet_light: #6d4ca7;
  --violet_dark: #4b218e;
  --mauve: #d4c0f0;
  --rose_light: #f4b5d4;
  --rose_dark: #d81e88;
  --rose_fushia: #b9066c;
  --rouge_framboise: #de2b5b;
  --rose_chair_light: #f0b7a5;
  --rose_chair_dark: #cca196;
  --vert_positif: #1e7000;
  --rouge_negatif: #c00;
  --bleu_texte: #2964ae;
  --alerte_vert_positif: #bddab2;
  --alerte_rouge_negatif: #f9b2b2;
  --alerte_bleu_information: #c1d5ee;
  --alerte_jaune_attention: #f9f5ad;
  --blanc: #fff;
  --noir: #222;
  --gris_light: #e1e1e1;
  --gris_dark: #636363;
  --gris_background: #f6f6f6;
  --bleu_dark: #1a283e;
  --corail_mh_brand_a: #ad1400;
  --corail_mh_dark_a: #8a1f10;
  --orange_normal_a: #ffb896;
  --orange_dark_a: #d14600;
  --vert_dark_a: #006157;
  --bleu_turquoise_middle_a: #005f70;
  --bleu_turquoise_dark_a: #004450;
  --bleu_light_a: #255998;
  --bleu_klein_light_a: #3840dc;
  --bleu_klein_dark_a: #30369e;
  --violet_light_a: #67489d;
  --rose_dark_a: #a61768;
  --rose_fushia_a: #870950;
  --rouge_framboise_a: #ac1b42;
  --rose_chair_dark_a: #d2aca2;
  --violet_dark_a: #381078;
  --vert_positif_a: #1e7000;
  --rouge_negatif_a: #940000;
  --bleu_texte_a: #1d487c;
  --gris_dark_a: #474747;
  --gris_light-rgb: 225, 225, 225;
  --bleu_texte-rgb: 41, 100, 174;
  --gris_background-rgb: 246, 246, 246;
  --corail_mh_brand_linear: linear-gradient(225deg, #ff4b33 0%, #e2250c 100%);
  --corail_mh_light_linear: linear-gradient(225deg, #ff7643 0%, #ff4b33 100%);
  --gris_sable_light_linear: linear-gradient(225deg, #faf5f3 0%, #ede8e6 100%);
  --gris_sable_dark_linear: linear-gradient(225deg, #f8f1e6 0%, #e4d8c4 100%);
  --jaune_ivoire_light_linear: linear-gradient(225deg, #fffdf6 0%, #fcf7e7 100%);
  --jaune_ivoire_dark_linear: linear-gradient(225deg, #fffbed 0%, #f6ebc8 100%);
  --jaune_light_linear: linear-gradient(225deg, #fffbb7 0%, #f8f169 100%);
  --jaune_dark_linear: linear-gradient(225deg, #ffdc6e 0%, #f9bd00 100%);
  --orange_linear: linear-gradient(225deg, #ffa51b 0%, #ff8347 100%);
  --vert_light_linear: linear-gradient(225deg, #aeffef 0%, #03dfb2 100%);
  --vert_dark_linear: linear-gradient(224deg, #005a51 0%, #008275 100%);
  --bleu_turquoise_light_linear: linear-gradient(225deg, #b5fdff 0%, #5ad5d9 100%);
  --bleu_turquoise_middle_linear: linear-gradient(225deg, #007286 0%, #008299 100%);
  --bleu_turquoise_dark_linear: linear-gradient(225deg, #01414c 0%, #006374 100%);
  --bleu_light_linear: linear-gradient(225deg, #1f5597 0%, #4283d1 100%);
  --bleu_klein_light_linear: linear-gradient(226deg, #3d44c9 0%, #5f65e3 100%);
  --bleu_klein_dark_linear: linear-gradient(225deg, #232876 0%, #484dad 100%);
  --violet_light_linear: linear-gradient(225deg, #593894 0%, #6d4ca7 100%);
  --violet_dark_linear: linear-gradient(225deg, #330f6d 0%, #4b218e 100%);
  --mauve_linear: linear-gradient(225deg, #f2e9ff 0%, #d4c0f0 100%);
  --rose_light_linear: linear-gradient(225deg, #ffe8f3 0%, #f4b5d4 100%);
  --rose_dark_linear: linear-gradient(225deg, #a30961 0%, #d81e88 100%);
  --rose_fushia_linear: linear-gradient(225deg, #8d0151 0%, #b9066c 100%);
  --rose_framboise_linear: linear-gradient(225deg, #b50d3a 0%, #de2b5b 100%);
  --rose_chair_light_linear: linear-gradient(225deg, #ffdfd2 0%, #f0b7a5 100%);
  --rose_chair_dark_linear: linear-gradient(225deg, #edd5ce 0%, #cca196 100%);
}

[data-theme="dark"] {
  --corail_mh_brand: var(--corail_mh_brand_a);
  --corail_mh_dark: var(--corail_mh_dark_a);
  --orange_normal: var(--orange_normal_a);
  --orange_dark: var(--orange_dark_a);
  --vert_dark: var(--vert_dark_a);
  --bleu_turquoise_middle: var(--bleu_turquoise_middle_a);
  --bleu_turquoise_dark: var(--bleu_turquoise_dark_a);
  --bleu_light: var(--bleu_light_a);
  --bleu_klein_light: var(--bleu_klein_light_a);
  --bleu_klein_dark: var(--bleu_klein_dark_a);
  --violet_light: var(--violet_light_a);
  --violet_dark: var(--violet_dark_a);
  --rose_dark: var(--rose_dark_a);
  --rose_fushia: var(--rose_fushia_a);
  --rouge_framboise: var(--rouge_framboise_a);
  --rose_chair_dark: var(--rose_chair_dark_a);
  --vert_positif: var(--vert_positif_a);
  --rouge_negatif: var(--rouge_negatif_a);
  --bleu_texte: var(--bleu_texte_a);
  --gris_dark: var(--gris_dark_a);
  --bleu_texte-rgb: 29, 72, 124;
}

html, body, div, span, applet, object, iframe, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, .small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, .mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1, .h1 {
  letter-spacing: normal;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.33;
}

h2, .h2 {
  letter-spacing: normal;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.33;
}

h3, .h3 {
  letter-spacing: normal;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.36;
}

h4, .h4 {
  letter-spacing: normal;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.36;
}

h5, .h5 {
  letter-spacing: normal;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.38;
}

body, p, a, form, button {
  letter-spacing: normal;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.38;
}

sub {
  letter-spacing: normal;
  font-size: .875rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.36;
}

.form-focus, .message, caption {
  letter-spacing: normal;
  font-size: .75rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.42;
}

.overline {
  letter-spacing: normal;
  font-size: .625rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.4;
}

@media screen and (width >= 0) {
  .titre-42 {
    letter-spacing: normal;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-36 {
    letter-spacing: normal;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-28 {
    letter-spacing: normal;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-22 {
    letter-spacing: normal;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-18 {
    letter-spacing: normal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-16 {
    letter-spacing: normal;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-14 {
    letter-spacing: normal;
    font-size: .875rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-12 {
    letter-spacing: normal;
    font-size: .75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.42;
  }

  .titre-10 {
    letter-spacing: normal;
    font-size: .625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
  }
}

@media screen and (width >= 20rem) {
  .titre-sm-42 {
    letter-spacing: normal;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-sm-36 {
    letter-spacing: normal;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-sm-28 {
    letter-spacing: normal;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-sm-22 {
    letter-spacing: normal;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-sm-18 {
    letter-spacing: normal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-sm-16 {
    letter-spacing: normal;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-sm-14 {
    letter-spacing: normal;
    font-size: .875rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-sm-12 {
    letter-spacing: normal;
    font-size: .75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.42;
  }

  .titre-sm-10 {
    letter-spacing: normal;
    font-size: .625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
  }
}

@media screen and (width >= 64rem) {
  .titre-md-42 {
    letter-spacing: normal;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-md-36 {
    letter-spacing: normal;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-md-28 {
    letter-spacing: normal;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-md-22 {
    letter-spacing: normal;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-md-18 {
    letter-spacing: normal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-md-16 {
    letter-spacing: normal;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-md-14 {
    letter-spacing: normal;
    font-size: .875rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-md-12 {
    letter-spacing: normal;
    font-size: .75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.42;
  }

  .titre-md-10 {
    letter-spacing: normal;
    font-size: .625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
  }
}

@media screen and (width >= 80rem) {
  .titre-lg-42 {
    letter-spacing: normal;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-lg-36 {
    letter-spacing: normal;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-lg-28 {
    letter-spacing: normal;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-lg-22 {
    letter-spacing: normal;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-lg-18 {
    letter-spacing: normal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-lg-16 {
    letter-spacing: normal;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-lg-14 {
    letter-spacing: normal;
    font-size: .875rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-lg-12 {
    letter-spacing: normal;
    font-size: .75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.42;
  }

  .titre-lg-10 {
    letter-spacing: normal;
    font-size: .625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
  }
}

@media screen and (width >= 90rem) {
  .titre-xl-42 {
    letter-spacing: normal;
    font-size: 2.625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-xl-36 {
    letter-spacing: normal;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.33;
  }

  .titre-xl-28 {
    letter-spacing: normal;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-xl-22 {
    letter-spacing: normal;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-xl-18 {
    letter-spacing: normal;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-xl-16 {
    letter-spacing: normal;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
  }

  .titre-xl-14 {
    letter-spacing: normal;
    font-size: .875rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.36;
  }

  .titre-xl-12 {
    letter-spacing: normal;
    font-size: .75rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.42;
  }

  .titre-xl-10 {
    letter-spacing: normal;
    font-size: .625rem;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.4;
  }
}

body, p, a, form, button, table {
  letter-spacing: normal;
  color: #222;
  font-family: poppins-regular, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.38;
}

.overline {
  text-transform: uppercase;
}

.ds-bold, .ds-card__content-label, .popover .popover-header, b {
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
}

.sr-only, .texte-invisible {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.corail_mh_brand {
  color: var(--corail_mh_brand) !important;
}

.corail_mh_brand_bg, .corail_mh_brand_bg_content:after, .corail_mh_brand_bg_content:before {
  background-color: var(--corail_mh_brand);
}

.corail_mh_dark {
  color: var(--corail_mh_dark) !important;
}

.corail_mh_dark_bg, .corail_mh_dark_bg_content:after, .corail_mh_dark_bg_content:before {
  background-color: var(--corail_mh_dark);
}

.corail_mh_light {
  color: var(--corail_mh_light) !important;
}

.corail_mh_light_bg, .corail_mh_light_bg_content:after, .corail_mh_light_bg_content:before {
  background-color: var(--corail_mh_light);
}

.gris_sable_light {
  color: var(--gris_sable_light) !important;
}

.gris_sable_light_bg, .gris_sable_light_bg_content:after, .gris_sable_light_bg_content:before {
  background-color: var(--gris_sable_light);
}

.gris_sable_dark {
  color: var(--gris_sable_dark) !important;
}

.gris_sable_dark_bg, .gris_sable_dark_bg_content:after, .gris_sable_dark_bg_content:before {
  background-color: var(--gris_sable_dark);
}

.jaune_ivoire_light {
  color: var(--jaune_ivoire_light) !important;
}

.jaune_ivoire_light_bg, .jaune_ivoire_light_bg_content:after, .jaune_ivoire_light_bg_content:before {
  background-color: var(--jaune_ivoire_light);
}

.jaune_ivoire_dark {
  color: var(--jaune_ivoire_dark) !important;
}

.jaune_ivoire_dark_bg, .jaune_ivoire_dark_bg_content:after, .jaune_ivoire_dark_bg_content:before {
  background-color: var(--jaune_ivoire_dark);
}

.jaune_light {
  color: var(--jaune_light) !important;
}

.jaune_light_bg, .jaune_light_bg_content:after, .jaune_light_bg_content:before {
  background-color: var(--jaune_light);
}

.jaune_dark {
  color: var(--jaune_dark) !important;
}

.jaune_dark_bg, .jaune_dark_bg_content:after, .jaune_dark_bg_content:before {
  background-color: var(--jaune_dark);
}

.orange_normal {
  color: var(--orange_normal) !important;
}

.orange_normal_bg, .orange_normal_bg_content:after, .orange_normal_bg_content:before {
  background-color: var(--orange_normal);
}

.orange_dark {
  color: var(--orange_dark) !important;
}

.orange_dark_bg, .orange_dark_bg_content:after, .orange_dark_bg_content:before {
  background-color: var(--orange_dark);
}

.vert_light {
  color: var(--vert_light) !important;
}

.vert_light_bg, .vert_light_bg_content:after, .vert_light_bg_content:before {
  background-color: var(--vert_light);
}

.vert_dark {
  color: var(--vert_dark) !important;
}

.vert_dark_bg, .vert_dark_bg_content:after, .vert_dark_bg_content:before {
  background-color: var(--vert_dark);
}

.bleu_turquoise_light {
  color: var(--bleu_turquoise_light) !important;
}

.bleu_turquoise_light_bg, .ds-card-input.ds-card--checked .ds-card__content, .ds-card-button.ds-card--checked .ds-card__content, .bleu_turquoise_light_bg_content:after, .bleu_turquoise_light_bg_content:before {
  background-color: var(--bleu_turquoise_light);
}

.bleu_turquoise_middle {
  color: var(--bleu_turquoise_middle) !important;
}

.bleu_turquoise_middle_bg, .bleu_turquoise_middle_bg_content:after, .bleu_turquoise_middle_bg_content:before {
  background-color: var(--bleu_turquoise_middle);
}

.bleu_turquoise_dark {
  color: var(--bleu_turquoise_dark) !important;
}

.bleu_turquoise_dark_bg, .bleu_turquoise_dark_bg_content:after, .bleu_turquoise_dark_bg_content:before {
  background-color: var(--bleu_turquoise_dark);
}

.bleu_light {
  color: var(--bleu_light) !important;
}

.bleu_light_bg, .bleu_light_bg_content:after, .bleu_light_bg_content:before {
  background-color: var(--bleu_light);
}

.bleu_dark {
  color: var(--bleu_dark) !important;
}

.bleu_dark_bg, .bleu_dark_bg_content:after, .bleu_dark_bg_content:before {
  background-color: var(--bleu_dark);
}

.bleu_klein_light {
  color: var(--bleu_klein_light) !important;
}

.bleu_klein_light_bg, .bleu_klein_light_bg_content:after, .bleu_klein_light_bg_content:before {
  background-color: var(--bleu_klein_light);
}

.bleu_klein_dark {
  color: var(--bleu_klein_dark) !important;
}

.bleu_klein_dark_bg, .bleu_klein_dark_bg_content:after, .bleu_klein_dark_bg_content:before {
  background-color: var(--bleu_klein_dark);
}

.violet_light {
  color: var(--violet_light) !important;
}

.violet_light_bg, .violet_light_bg_content:after, .violet_light_bg_content:before {
  background-color: var(--violet_light);
}

.violet_dark {
  color: var(--violet_dark) !important;
}

.violet_dark_bg, .violet_dark_bg_content:after, .violet_dark_bg_content:before {
  background-color: var(--violet_dark);
}

.mauve {
  color: var(--mauve) !important;
}

.mauve_bg, .mauve_bg_content:after, .mauve_bg_content:before {
  background-color: var(--mauve);
}

.rose_light {
  color: var(--rose_light) !important;
}

.rose_light_bg, .rose_light_bg_content:after, .rose_light_bg_content:before {
  background-color: var(--rose_light);
}

.rose_dark {
  color: var(--rose_dark) !important;
}

.rose_dark_bg, .rose_dark_bg_content:after, .rose_dark_bg_content:before {
  background-color: var(--rose_dark);
}

.rose_fushia {
  color: var(--rose_fushia) !important;
}

.rose_fushia_bg, .rose_fushia_bg_content:after, .rose_fushia_bg_content:before {
  background-color: var(--rose_fushia);
}

.rouge_framboise {
  color: var(--rouge_framboise) !important;
}

.rouge_framboise_bg, .rouge_framboise_bg_content:after, .rouge_framboise_bg_content:before {
  background-color: var(--rouge_framboise);
}

.rose_chair_light {
  color: var(--rose_chair_light) !important;
}

.rose_chair_light_bg, .rose_chair_light_bg_content:after, .rose_chair_light_bg_content:before {
  background-color: var(--rose_chair_light);
}

.rose_chair_dark {
  color: var(--rose_chair_dark) !important;
}

.rose_chair_dark_bg, .rose_chair_dark_bg_content:after, .rose_chair_dark_bg_content:before {
  background-color: var(--rose_chair_dark);
}

.vert_positif {
  color: var(--vert_positif) !important;
}

.vert_positif_bg, .vert_positif_bg_content:after, .vert_positif_bg_content:before {
  background-color: var(--vert_positif);
}

.rouge_negatif {
  color: var(--rouge_negatif) !important;
}

.rouge_negatif_bg, .rouge_negatif_bg_content:after, .rouge_negatif_bg_content:before {
  background-color: var(--rouge_negatif);
}

.bleu_texte {
  color: var(--bleu_texte) !important;
}

.bleu_texte_bg, .bleu_texte_bg_content:after, .bleu_texte_bg_content:before {
  background-color: var(--bleu_texte);
}

.alerte_vert_positif {
  color: var(--alerte_vert_positif) !important;
}

.alerte_vert_positif_bg, .ds-alert.ds-alert--valid, .alerte_vert_positif_bg_content:after, .alerte_vert_positif_bg_content:before {
  background-color: var(--alerte_vert_positif);
}

.alerte_rouge_negatif {
  color: var(--alerte_rouge_negatif) !important;
}

.alerte_rouge_negatif_bg, .ds-alert.ds-alert--error, .alerte_rouge_negatif_bg_content:after, .alerte_rouge_negatif_bg_content:before {
  background-color: var(--alerte_rouge_negatif);
}

.alerte_bleu_information {
  color: var(--alerte_bleu_information) !important;
}

.alerte_bleu_information_bg, .ds-alert.ds-alert--info, .alerte_bleu_information_bg_content:after, .alerte_bleu_information_bg_content:before {
  background-color: var(--alerte_bleu_information);
}

.alerte_jaune_attention {
  color: var(--alerte_jaune_attention) !important;
}

.alerte_jaune_attention_bg, .ds-alert.ds-alert--warn, .alerte_jaune_attention_bg_content:after, .alerte_jaune_attention_bg_content:before {
  background-color: var(--alerte_jaune_attention);
}

.blanc {
  color: #fff !important;
}

.blanc_bg, .ds-card-input:not(.ds-card--checked) .ds-card__content, .ds-card-button:not(.ds-card--checked) .ds-card__content, .blanc_bg_content:after, .blanc_bg_content:before {
  background-color: #fff;
}

.noir {
  color: #222 !important;
}

.noir_bg, .noir_bg_content:after, .noir_bg_content:before {
  background-color: #222;
}

.gris_light {
  color: var(--gris_light) !important;
}

.gris_light_bg, .gris_light_bg_content:after, .gris_light_bg_content:before {
  background-color: var(--gris_light);
}

.gris_dark, .ds-card__content-sublabel, .ds-card__content-label, .ds-card__content-icon {
  color: var(--gris_dark) !important;
}

.gris_dark_bg, .gris_dark_bg_content:after, .gris_dark_bg_content:before {
  background-color: var(--gris_dark);
}

.gris_background {
  color: var(--gris_background) !important;
}

.gris_background_bg, .gris_background_bg_content:after, .gris_background_bg_content:before {
  background-color: var(--gris_background);
}

.corail_mh_brand_linear {
  background: var(--corail_mh_brand_linear);
}

.corail_mh_light_linear {
  background: var(--corail_mh_light_linear);
}

.gris_sable_light_linear {
  background: var(--gris_sable_light_linear);
}

.gris_sable_dark_linear {
  background: var(--gris_sable_dark_linear);
}

.jaune_ivoire_light_linear {
  background: var(--jaune_ivoire_light_linear);
}

.jaune_ivoire_dark_linear {
  background: var(--jaune_ivoire_dark_linear);
}

.jaune_light_linear {
  background: var(--jaune_light_linear);
}

.jaune_dark_linear {
  background: var(--jaune_dark_linear);
}

.orange_linear {
  background: var(--orange_linear);
}

.vert_light_linear {
  background: var(--vert_light_linear);
}

.vert_dark_linear {
  background: var(--vert_dark_linear);
}

.bleu_turquoise_light_linear {
  background: var(--bleu_turquoise_light_linear);
}

.bleu_turquoise_middle_linear {
  background: var(--bleu_turquoise_middle_linear);
}

.bleu_turquoise_dark_linear {
  background: var(--bleu_turquoise_dark_linear);
}

.bleu_light_linear {
  background: var(--bleu_light_linear);
}

.bleu_klein_light_linear {
  background: var(--bleu_klein_light_linear);
}

.bleu_klein_dark_linear {
  background: var(--bleu_klein_dark_linear);
}

.violet_light_linear {
  background: var(--violet_light_linear);
}

.violet_dark_linear {
  background: var(--violet_dark_linear);
}

.mauve_linear {
  background: var(--mauve_linear);
}

.rose_light_linear {
  background: var(--rose_light_linear);
}

.rose_dark_linear {
  background: var(--rose_dark_linear);
}

.rose_fushia_linear {
  background: var(--rose_fushia_linear);
}

.rose_framboise_linear {
  background: var(--rose_framboise_linear);
}

.rose_chair_light_linear {
  background: var(--rose_chair_light_linear);
}

.rose_chair_dark_linear {
  background: var(--rose_chair_dark_linear);
}

.alerte_blanc_neutre_bg {
  background-color: #fff;
}

.alerte_gris_neutre_bg, .ds-alert.ds-alert--neutral {
  background-color: #f6f6f6;
}

.alerte_bleu_information_bg, .ds-alert.ds-alert--info {
  background-color: #c1d5ee;
}

.alerte_vert_positif_bg, .ds-alert.ds-alert--valid {
  background-color: #bddab2;
}

.alerte_jaune_attention_bg, .ds-alert.ds-alert--warn {
  background-color: #f9f5ad;
}

.alerte_rouge_negatif_bg, .ds-alert.ds-alert--error {
  background-color: #f9b2b2;
}

.align-gauche {
  text-align: left;
}

.align-centre {
  text-align: center;
}

.align-droite {
  text-align: right;
}

.bg-overlay-0 {
  z-index: 0;
  position: relative;
}

.bg-overlay-1 {
  z-index: 1;
  position: relative;
}

.bg-overlay-2 {
  z-index: 2;
  position: relative;
}

.bg-overlay-3 {
  z-index: 3;
  position: relative;
}

.bg-overlay-4 {
  z-index: 4;
  position: relative;
}

.bg-overlay-5 {
  z-index: 5;
  position: relative;
}

.bg-overlay-6 {
  z-index: 6;
  position: relative;
}

.bg-overlay-7 {
  z-index: 7;
  position: relative;
}

.bg-overlay-8 {
  z-index: 8;
  position: relative;
}

.bg-overlay-9 {
  z-index: 9;
  position: relative;
}

.bg-overlay-10 {
  z-index: 10;
  position: relative;
}

.border-top-1-solid-corail_mh_brand {
  border-top: 1px solid var(--corail_mh_brand);
}

.border-top-1-dashed-corail_mh_brand {
  border-top: 1px dashed var(--corail_mh_brand);
}

.border-top-1-dotted-corail_mh_brand {
  border-top: 1px dotted var(--corail_mh_brand);
}

.border-top-1-double-corail_mh_brand {
  border-top: 1px double var(--corail_mh_brand);
}

.border-top-1-groove-corail_mh_brand {
  border-top: 1px groove var(--corail_mh_brand);
}

.border-top-1-ridge-corail_mh_brand {
  border-top: 1px ridge var(--corail_mh_brand);
}

.border-top-1-inset-corail_mh_brand {
  border-top: 1px inset var(--corail_mh_brand);
}

.border-top-1-outset-corail_mh_brand {
  border-top: 1px outset var(--corail_mh_brand);
}

.border-top-1-none-corail_mh_brand {
  border-top: 1px none var(--corail_mh_brand);
}

.border-top-1-hidden-corail_mh_brand {
  border-top: 1px hidden var(--corail_mh_brand);
}

.border-top-1-solid-corail_mh_dark {
  border-top: 1px solid var(--corail_mh_dark);
}

.border-top-1-dashed-corail_mh_dark {
  border-top: 1px dashed var(--corail_mh_dark);
}

.border-top-1-dotted-corail_mh_dark {
  border-top: 1px dotted var(--corail_mh_dark);
}

.border-top-1-double-corail_mh_dark {
  border-top: 1px double var(--corail_mh_dark);
}

.border-top-1-groove-corail_mh_dark {
  border-top: 1px groove var(--corail_mh_dark);
}

.border-top-1-ridge-corail_mh_dark {
  border-top: 1px ridge var(--corail_mh_dark);
}

.border-top-1-inset-corail_mh_dark {
  border-top: 1px inset var(--corail_mh_dark);
}

.border-top-1-outset-corail_mh_dark {
  border-top: 1px outset var(--corail_mh_dark);
}

.border-top-1-none-corail_mh_dark {
  border-top: 1px none var(--corail_mh_dark);
}

.border-top-1-hidden-corail_mh_dark {
  border-top: 1px hidden var(--corail_mh_dark);
}

.border-top-1-solid-corail_mh_light {
  border-top: 1px solid var(--corail_mh_light);
}

.border-top-1-dashed-corail_mh_light {
  border-top: 1px dashed var(--corail_mh_light);
}

.border-top-1-dotted-corail_mh_light {
  border-top: 1px dotted var(--corail_mh_light);
}

.border-top-1-double-corail_mh_light {
  border-top: 1px double var(--corail_mh_light);
}

.border-top-1-groove-corail_mh_light {
  border-top: 1px groove var(--corail_mh_light);
}

.border-top-1-ridge-corail_mh_light {
  border-top: 1px ridge var(--corail_mh_light);
}

.border-top-1-inset-corail_mh_light {
  border-top: 1px inset var(--corail_mh_light);
}

.border-top-1-outset-corail_mh_light {
  border-top: 1px outset var(--corail_mh_light);
}

.border-top-1-none-corail_mh_light {
  border-top: 1px none var(--corail_mh_light);
}

.border-top-1-hidden-corail_mh_light {
  border-top: 1px hidden var(--corail_mh_light);
}

.border-top-1-solid-gris_sable_light {
  border-top: 1px solid var(--gris_sable_light);
}

.border-top-1-dashed-gris_sable_light {
  border-top: 1px dashed var(--gris_sable_light);
}

.border-top-1-dotted-gris_sable_light {
  border-top: 1px dotted var(--gris_sable_light);
}

.border-top-1-double-gris_sable_light {
  border-top: 1px double var(--gris_sable_light);
}

.border-top-1-groove-gris_sable_light {
  border-top: 1px groove var(--gris_sable_light);
}

.border-top-1-ridge-gris_sable_light {
  border-top: 1px ridge var(--gris_sable_light);
}

.border-top-1-inset-gris_sable_light {
  border-top: 1px inset var(--gris_sable_light);
}

.border-top-1-outset-gris_sable_light {
  border-top: 1px outset var(--gris_sable_light);
}

.border-top-1-none-gris_sable_light {
  border-top: 1px none var(--gris_sable_light);
}

.border-top-1-hidden-gris_sable_light {
  border-top: 1px hidden var(--gris_sable_light);
}

.border-top-1-solid-gris_sable_dark {
  border-top: 1px solid var(--gris_sable_dark);
}

.border-top-1-dashed-gris_sable_dark {
  border-top: 1px dashed var(--gris_sable_dark);
}

.border-top-1-dotted-gris_sable_dark {
  border-top: 1px dotted var(--gris_sable_dark);
}

.border-top-1-double-gris_sable_dark {
  border-top: 1px double var(--gris_sable_dark);
}

.border-top-1-groove-gris_sable_dark {
  border-top: 1px groove var(--gris_sable_dark);
}

.border-top-1-ridge-gris_sable_dark {
  border-top: 1px ridge var(--gris_sable_dark);
}

.border-top-1-inset-gris_sable_dark {
  border-top: 1px inset var(--gris_sable_dark);
}

.border-top-1-outset-gris_sable_dark {
  border-top: 1px outset var(--gris_sable_dark);
}

.border-top-1-none-gris_sable_dark {
  border-top: 1px none var(--gris_sable_dark);
}

.border-top-1-hidden-gris_sable_dark {
  border-top: 1px hidden var(--gris_sable_dark);
}

.border-top-1-solid-jaune_ivoire_light {
  border-top: 1px solid var(--jaune_ivoire_light);
}

.border-top-1-dashed-jaune_ivoire_light {
  border-top: 1px dashed var(--jaune_ivoire_light);
}

.border-top-1-dotted-jaune_ivoire_light {
  border-top: 1px dotted var(--jaune_ivoire_light);
}

.border-top-1-double-jaune_ivoire_light {
  border-top: 1px double var(--jaune_ivoire_light);
}

.border-top-1-groove-jaune_ivoire_light {
  border-top: 1px groove var(--jaune_ivoire_light);
}

.border-top-1-ridge-jaune_ivoire_light {
  border-top: 1px ridge var(--jaune_ivoire_light);
}

.border-top-1-inset-jaune_ivoire_light {
  border-top: 1px inset var(--jaune_ivoire_light);
}

.border-top-1-outset-jaune_ivoire_light {
  border-top: 1px outset var(--jaune_ivoire_light);
}

.border-top-1-none-jaune_ivoire_light {
  border-top: 1px none var(--jaune_ivoire_light);
}

.border-top-1-hidden-jaune_ivoire_light {
  border-top: 1px hidden var(--jaune_ivoire_light);
}

.border-top-1-solid-jaune_ivoire_dark {
  border-top: 1px solid var(--jaune_ivoire_dark);
}

.border-top-1-dashed-jaune_ivoire_dark {
  border-top: 1px dashed var(--jaune_ivoire_dark);
}

.border-top-1-dotted-jaune_ivoire_dark {
  border-top: 1px dotted var(--jaune_ivoire_dark);
}

.border-top-1-double-jaune_ivoire_dark {
  border-top: 1px double var(--jaune_ivoire_dark);
}

.border-top-1-groove-jaune_ivoire_dark {
  border-top: 1px groove var(--jaune_ivoire_dark);
}

.border-top-1-ridge-jaune_ivoire_dark {
  border-top: 1px ridge var(--jaune_ivoire_dark);
}

.border-top-1-inset-jaune_ivoire_dark {
  border-top: 1px inset var(--jaune_ivoire_dark);
}

.border-top-1-outset-jaune_ivoire_dark {
  border-top: 1px outset var(--jaune_ivoire_dark);
}

.border-top-1-none-jaune_ivoire_dark {
  border-top: 1px none var(--jaune_ivoire_dark);
}

.border-top-1-hidden-jaune_ivoire_dark {
  border-top: 1px hidden var(--jaune_ivoire_dark);
}

.border-top-1-solid-jaune_light {
  border-top: 1px solid var(--jaune_light);
}

.border-top-1-dashed-jaune_light {
  border-top: 1px dashed var(--jaune_light);
}

.border-top-1-dotted-jaune_light {
  border-top: 1px dotted var(--jaune_light);
}

.border-top-1-double-jaune_light {
  border-top: 1px double var(--jaune_light);
}

.border-top-1-groove-jaune_light {
  border-top: 1px groove var(--jaune_light);
}

.border-top-1-ridge-jaune_light {
  border-top: 1px ridge var(--jaune_light);
}

.border-top-1-inset-jaune_light {
  border-top: 1px inset var(--jaune_light);
}

.border-top-1-outset-jaune_light {
  border-top: 1px outset var(--jaune_light);
}

.border-top-1-none-jaune_light {
  border-top: 1px none var(--jaune_light);
}

.border-top-1-hidden-jaune_light {
  border-top: 1px hidden var(--jaune_light);
}

.border-top-1-solid-jaune_dark {
  border-top: 1px solid var(--jaune_dark);
}

.border-top-1-dashed-jaune_dark {
  border-top: 1px dashed var(--jaune_dark);
}

.border-top-1-dotted-jaune_dark {
  border-top: 1px dotted var(--jaune_dark);
}

.border-top-1-double-jaune_dark {
  border-top: 1px double var(--jaune_dark);
}

.border-top-1-groove-jaune_dark {
  border-top: 1px groove var(--jaune_dark);
}

.border-top-1-ridge-jaune_dark {
  border-top: 1px ridge var(--jaune_dark);
}

.border-top-1-inset-jaune_dark {
  border-top: 1px inset var(--jaune_dark);
}

.border-top-1-outset-jaune_dark {
  border-top: 1px outset var(--jaune_dark);
}

.border-top-1-none-jaune_dark {
  border-top: 1px none var(--jaune_dark);
}

.border-top-1-hidden-jaune_dark {
  border-top: 1px hidden var(--jaune_dark);
}

.border-top-1-solid-orange_normal {
  border-top: 1px solid var(--orange_normal);
}

.border-top-1-dashed-orange_normal {
  border-top: 1px dashed var(--orange_normal);
}

.border-top-1-dotted-orange_normal {
  border-top: 1px dotted var(--orange_normal);
}

.border-top-1-double-orange_normal {
  border-top: 1px double var(--orange_normal);
}

.border-top-1-groove-orange_normal {
  border-top: 1px groove var(--orange_normal);
}

.border-top-1-ridge-orange_normal {
  border-top: 1px ridge var(--orange_normal);
}

.border-top-1-inset-orange_normal {
  border-top: 1px inset var(--orange_normal);
}

.border-top-1-outset-orange_normal {
  border-top: 1px outset var(--orange_normal);
}

.border-top-1-none-orange_normal {
  border-top: 1px none var(--orange_normal);
}

.border-top-1-hidden-orange_normal {
  border-top: 1px hidden var(--orange_normal);
}

.border-top-1-solid-orange_dark {
  border-top: 1px solid var(--orange_dark);
}

.border-top-1-dashed-orange_dark {
  border-top: 1px dashed var(--orange_dark);
}

.border-top-1-dotted-orange_dark {
  border-top: 1px dotted var(--orange_dark);
}

.border-top-1-double-orange_dark {
  border-top: 1px double var(--orange_dark);
}

.border-top-1-groove-orange_dark {
  border-top: 1px groove var(--orange_dark);
}

.border-top-1-ridge-orange_dark {
  border-top: 1px ridge var(--orange_dark);
}

.border-top-1-inset-orange_dark {
  border-top: 1px inset var(--orange_dark);
}

.border-top-1-outset-orange_dark {
  border-top: 1px outset var(--orange_dark);
}

.border-top-1-none-orange_dark {
  border-top: 1px none var(--orange_dark);
}

.border-top-1-hidden-orange_dark {
  border-top: 1px hidden var(--orange_dark);
}

.border-top-1-solid-vert_light {
  border-top: 1px solid var(--vert_light);
}

.border-top-1-dashed-vert_light {
  border-top: 1px dashed var(--vert_light);
}

.border-top-1-dotted-vert_light {
  border-top: 1px dotted var(--vert_light);
}

.border-top-1-double-vert_light {
  border-top: 1px double var(--vert_light);
}

.border-top-1-groove-vert_light {
  border-top: 1px groove var(--vert_light);
}

.border-top-1-ridge-vert_light {
  border-top: 1px ridge var(--vert_light);
}

.border-top-1-inset-vert_light {
  border-top: 1px inset var(--vert_light);
}

.border-top-1-outset-vert_light {
  border-top: 1px outset var(--vert_light);
}

.border-top-1-none-vert_light {
  border-top: 1px none var(--vert_light);
}

.border-top-1-hidden-vert_light {
  border-top: 1px hidden var(--vert_light);
}

.border-top-1-solid-vert_dark {
  border-top: 1px solid var(--vert_dark);
}

.border-top-1-dashed-vert_dark {
  border-top: 1px dashed var(--vert_dark);
}

.border-top-1-dotted-vert_dark {
  border-top: 1px dotted var(--vert_dark);
}

.border-top-1-double-vert_dark {
  border-top: 1px double var(--vert_dark);
}

.border-top-1-groove-vert_dark {
  border-top: 1px groove var(--vert_dark);
}

.border-top-1-ridge-vert_dark {
  border-top: 1px ridge var(--vert_dark);
}

.border-top-1-inset-vert_dark {
  border-top: 1px inset var(--vert_dark);
}

.border-top-1-outset-vert_dark {
  border-top: 1px outset var(--vert_dark);
}

.border-top-1-none-vert_dark {
  border-top: 1px none var(--vert_dark);
}

.border-top-1-hidden-vert_dark {
  border-top: 1px hidden var(--vert_dark);
}

.border-top-1-solid-bleu_turquoise_light {
  border-top: 1px solid var(--bleu_turquoise_light);
}

.border-top-1-dashed-bleu_turquoise_light {
  border-top: 1px dashed var(--bleu_turquoise_light);
}

.border-top-1-dotted-bleu_turquoise_light {
  border-top: 1px dotted var(--bleu_turquoise_light);
}

.border-top-1-double-bleu_turquoise_light {
  border-top: 1px double var(--bleu_turquoise_light);
}

.border-top-1-groove-bleu_turquoise_light {
  border-top: 1px groove var(--bleu_turquoise_light);
}

.border-top-1-ridge-bleu_turquoise_light {
  border-top: 1px ridge var(--bleu_turquoise_light);
}

.border-top-1-inset-bleu_turquoise_light {
  border-top: 1px inset var(--bleu_turquoise_light);
}

.border-top-1-outset-bleu_turquoise_light {
  border-top: 1px outset var(--bleu_turquoise_light);
}

.border-top-1-none-bleu_turquoise_light {
  border-top: 1px none var(--bleu_turquoise_light);
}

.border-top-1-hidden-bleu_turquoise_light {
  border-top: 1px hidden var(--bleu_turquoise_light);
}

.border-top-1-solid-bleu_turquoise_middle {
  border-top: 1px solid var(--bleu_turquoise_middle);
}

.border-top-1-dashed-bleu_turquoise_middle {
  border-top: 1px dashed var(--bleu_turquoise_middle);
}

.border-top-1-dotted-bleu_turquoise_middle {
  border-top: 1px dotted var(--bleu_turquoise_middle);
}

.border-top-1-double-bleu_turquoise_middle {
  border-top: 1px double var(--bleu_turquoise_middle);
}

.border-top-1-groove-bleu_turquoise_middle {
  border-top: 1px groove var(--bleu_turquoise_middle);
}

.border-top-1-ridge-bleu_turquoise_middle {
  border-top: 1px ridge var(--bleu_turquoise_middle);
}

.border-top-1-inset-bleu_turquoise_middle {
  border-top: 1px inset var(--bleu_turquoise_middle);
}

.border-top-1-outset-bleu_turquoise_middle {
  border-top: 1px outset var(--bleu_turquoise_middle);
}

.border-top-1-none-bleu_turquoise_middle {
  border-top: 1px none var(--bleu_turquoise_middle);
}

.border-top-1-hidden-bleu_turquoise_middle {
  border-top: 1px hidden var(--bleu_turquoise_middle);
}

.border-top-1-solid-bleu_turquoise_dark {
  border-top: 1px solid var(--bleu_turquoise_dark);
}

.border-top-1-dashed-bleu_turquoise_dark {
  border-top: 1px dashed var(--bleu_turquoise_dark);
}

.border-top-1-dotted-bleu_turquoise_dark {
  border-top: 1px dotted var(--bleu_turquoise_dark);
}

.border-top-1-double-bleu_turquoise_dark {
  border-top: 1px double var(--bleu_turquoise_dark);
}

.border-top-1-groove-bleu_turquoise_dark {
  border-top: 1px groove var(--bleu_turquoise_dark);
}

.border-top-1-ridge-bleu_turquoise_dark {
  border-top: 1px ridge var(--bleu_turquoise_dark);
}

.border-top-1-inset-bleu_turquoise_dark {
  border-top: 1px inset var(--bleu_turquoise_dark);
}

.border-top-1-outset-bleu_turquoise_dark {
  border-top: 1px outset var(--bleu_turquoise_dark);
}

.border-top-1-none-bleu_turquoise_dark {
  border-top: 1px none var(--bleu_turquoise_dark);
}

.border-top-1-hidden-bleu_turquoise_dark {
  border-top: 1px hidden var(--bleu_turquoise_dark);
}

.border-top-1-solid-bleu_light {
  border-top: 1px solid var(--bleu_light);
}

.border-top-1-dashed-bleu_light {
  border-top: 1px dashed var(--bleu_light);
}

.border-top-1-dotted-bleu_light {
  border-top: 1px dotted var(--bleu_light);
}

.border-top-1-double-bleu_light {
  border-top: 1px double var(--bleu_light);
}

.border-top-1-groove-bleu_light {
  border-top: 1px groove var(--bleu_light);
}

.border-top-1-ridge-bleu_light {
  border-top: 1px ridge var(--bleu_light);
}

.border-top-1-inset-bleu_light {
  border-top: 1px inset var(--bleu_light);
}

.border-top-1-outset-bleu_light {
  border-top: 1px outset var(--bleu_light);
}

.border-top-1-none-bleu_light {
  border-top: 1px none var(--bleu_light);
}

.border-top-1-hidden-bleu_light {
  border-top: 1px hidden var(--bleu_light);
}

.border-top-1-solid-bleu_dark {
  border-top: 1px solid var(--bleu_dark);
}

.border-top-1-dashed-bleu_dark {
  border-top: 1px dashed var(--bleu_dark);
}

.border-top-1-dotted-bleu_dark {
  border-top: 1px dotted var(--bleu_dark);
}

.border-top-1-double-bleu_dark {
  border-top: 1px double var(--bleu_dark);
}

.border-top-1-groove-bleu_dark {
  border-top: 1px groove var(--bleu_dark);
}

.border-top-1-ridge-bleu_dark {
  border-top: 1px ridge var(--bleu_dark);
}

.border-top-1-inset-bleu_dark {
  border-top: 1px inset var(--bleu_dark);
}

.border-top-1-outset-bleu_dark {
  border-top: 1px outset var(--bleu_dark);
}

.border-top-1-none-bleu_dark {
  border-top: 1px none var(--bleu_dark);
}

.border-top-1-hidden-bleu_dark {
  border-top: 1px hidden var(--bleu_dark);
}

.border-top-1-solid-bleu_klein_light {
  border-top: 1px solid var(--bleu_klein_light);
}

.border-top-1-dashed-bleu_klein_light {
  border-top: 1px dashed var(--bleu_klein_light);
}

.border-top-1-dotted-bleu_klein_light {
  border-top: 1px dotted var(--bleu_klein_light);
}

.border-top-1-double-bleu_klein_light {
  border-top: 1px double var(--bleu_klein_light);
}

.border-top-1-groove-bleu_klein_light {
  border-top: 1px groove var(--bleu_klein_light);
}

.border-top-1-ridge-bleu_klein_light {
  border-top: 1px ridge var(--bleu_klein_light);
}

.border-top-1-inset-bleu_klein_light {
  border-top: 1px inset var(--bleu_klein_light);
}

.border-top-1-outset-bleu_klein_light {
  border-top: 1px outset var(--bleu_klein_light);
}

.border-top-1-none-bleu_klein_light {
  border-top: 1px none var(--bleu_klein_light);
}

.border-top-1-hidden-bleu_klein_light {
  border-top: 1px hidden var(--bleu_klein_light);
}

.border-top-1-solid-bleu_klein_dark {
  border-top: 1px solid var(--bleu_klein_dark);
}

.border-top-1-dashed-bleu_klein_dark {
  border-top: 1px dashed var(--bleu_klein_dark);
}

.border-top-1-dotted-bleu_klein_dark {
  border-top: 1px dotted var(--bleu_klein_dark);
}

.border-top-1-double-bleu_klein_dark {
  border-top: 1px double var(--bleu_klein_dark);
}

.border-top-1-groove-bleu_klein_dark {
  border-top: 1px groove var(--bleu_klein_dark);
}

.border-top-1-ridge-bleu_klein_dark {
  border-top: 1px ridge var(--bleu_klein_dark);
}

.border-top-1-inset-bleu_klein_dark {
  border-top: 1px inset var(--bleu_klein_dark);
}

.border-top-1-outset-bleu_klein_dark {
  border-top: 1px outset var(--bleu_klein_dark);
}

.border-top-1-none-bleu_klein_dark {
  border-top: 1px none var(--bleu_klein_dark);
}

.border-top-1-hidden-bleu_klein_dark {
  border-top: 1px hidden var(--bleu_klein_dark);
}

.border-top-1-solid-violet_light {
  border-top: 1px solid var(--violet_light);
}

.border-top-1-dashed-violet_light {
  border-top: 1px dashed var(--violet_light);
}

.border-top-1-dotted-violet_light {
  border-top: 1px dotted var(--violet_light);
}

.border-top-1-double-violet_light {
  border-top: 1px double var(--violet_light);
}

.border-top-1-groove-violet_light {
  border-top: 1px groove var(--violet_light);
}

.border-top-1-ridge-violet_light {
  border-top: 1px ridge var(--violet_light);
}

.border-top-1-inset-violet_light {
  border-top: 1px inset var(--violet_light);
}

.border-top-1-outset-violet_light {
  border-top: 1px outset var(--violet_light);
}

.border-top-1-none-violet_light {
  border-top: 1px none var(--violet_light);
}

.border-top-1-hidden-violet_light {
  border-top: 1px hidden var(--violet_light);
}

.border-top-1-solid-violet_dark {
  border-top: 1px solid var(--violet_dark);
}

.border-top-1-dashed-violet_dark {
  border-top: 1px dashed var(--violet_dark);
}

.border-top-1-dotted-violet_dark {
  border-top: 1px dotted var(--violet_dark);
}

.border-top-1-double-violet_dark {
  border-top: 1px double var(--violet_dark);
}

.border-top-1-groove-violet_dark {
  border-top: 1px groove var(--violet_dark);
}

.border-top-1-ridge-violet_dark {
  border-top: 1px ridge var(--violet_dark);
}

.border-top-1-inset-violet_dark {
  border-top: 1px inset var(--violet_dark);
}

.border-top-1-outset-violet_dark {
  border-top: 1px outset var(--violet_dark);
}

.border-top-1-none-violet_dark {
  border-top: 1px none var(--violet_dark);
}

.border-top-1-hidden-violet_dark {
  border-top: 1px hidden var(--violet_dark);
}

.border-top-1-solid-mauve {
  border-top: 1px solid var(--mauve);
}

.border-top-1-dashed-mauve {
  border-top: 1px dashed var(--mauve);
}

.border-top-1-dotted-mauve {
  border-top: 1px dotted var(--mauve);
}

.border-top-1-double-mauve {
  border-top: 1px double var(--mauve);
}

.border-top-1-groove-mauve {
  border-top: 1px groove var(--mauve);
}

.border-top-1-ridge-mauve {
  border-top: 1px ridge var(--mauve);
}

.border-top-1-inset-mauve {
  border-top: 1px inset var(--mauve);
}

.border-top-1-outset-mauve {
  border-top: 1px outset var(--mauve);
}

.border-top-1-none-mauve {
  border-top: 1px none var(--mauve);
}

.border-top-1-hidden-mauve {
  border-top: 1px hidden var(--mauve);
}

.border-top-1-solid-rose_light {
  border-top: 1px solid var(--rose_light);
}

.border-top-1-dashed-rose_light {
  border-top: 1px dashed var(--rose_light);
}

.border-top-1-dotted-rose_light {
  border-top: 1px dotted var(--rose_light);
}

.border-top-1-double-rose_light {
  border-top: 1px double var(--rose_light);
}

.border-top-1-groove-rose_light {
  border-top: 1px groove var(--rose_light);
}

.border-top-1-ridge-rose_light {
  border-top: 1px ridge var(--rose_light);
}

.border-top-1-inset-rose_light {
  border-top: 1px inset var(--rose_light);
}

.border-top-1-outset-rose_light {
  border-top: 1px outset var(--rose_light);
}

.border-top-1-none-rose_light {
  border-top: 1px none var(--rose_light);
}

.border-top-1-hidden-rose_light {
  border-top: 1px hidden var(--rose_light);
}

.border-top-1-solid-rose_dark {
  border-top: 1px solid var(--rose_dark);
}

.border-top-1-dashed-rose_dark {
  border-top: 1px dashed var(--rose_dark);
}

.border-top-1-dotted-rose_dark {
  border-top: 1px dotted var(--rose_dark);
}

.border-top-1-double-rose_dark {
  border-top: 1px double var(--rose_dark);
}

.border-top-1-groove-rose_dark {
  border-top: 1px groove var(--rose_dark);
}

.border-top-1-ridge-rose_dark {
  border-top: 1px ridge var(--rose_dark);
}

.border-top-1-inset-rose_dark {
  border-top: 1px inset var(--rose_dark);
}

.border-top-1-outset-rose_dark {
  border-top: 1px outset var(--rose_dark);
}

.border-top-1-none-rose_dark {
  border-top: 1px none var(--rose_dark);
}

.border-top-1-hidden-rose_dark {
  border-top: 1px hidden var(--rose_dark);
}

.border-top-1-solid-rose_fushia {
  border-top: 1px solid var(--rose_fushia);
}

.border-top-1-dashed-rose_fushia {
  border-top: 1px dashed var(--rose_fushia);
}

.border-top-1-dotted-rose_fushia {
  border-top: 1px dotted var(--rose_fushia);
}

.border-top-1-double-rose_fushia {
  border-top: 1px double var(--rose_fushia);
}

.border-top-1-groove-rose_fushia {
  border-top: 1px groove var(--rose_fushia);
}

.border-top-1-ridge-rose_fushia {
  border-top: 1px ridge var(--rose_fushia);
}

.border-top-1-inset-rose_fushia {
  border-top: 1px inset var(--rose_fushia);
}

.border-top-1-outset-rose_fushia {
  border-top: 1px outset var(--rose_fushia);
}

.border-top-1-none-rose_fushia {
  border-top: 1px none var(--rose_fushia);
}

.border-top-1-hidden-rose_fushia {
  border-top: 1px hidden var(--rose_fushia);
}

.border-top-1-solid-rouge_framboise {
  border-top: 1px solid var(--rouge_framboise);
}

.border-top-1-dashed-rouge_framboise {
  border-top: 1px dashed var(--rouge_framboise);
}

.border-top-1-dotted-rouge_framboise {
  border-top: 1px dotted var(--rouge_framboise);
}

.border-top-1-double-rouge_framboise {
  border-top: 1px double var(--rouge_framboise);
}

.border-top-1-groove-rouge_framboise {
  border-top: 1px groove var(--rouge_framboise);
}

.border-top-1-ridge-rouge_framboise {
  border-top: 1px ridge var(--rouge_framboise);
}

.border-top-1-inset-rouge_framboise {
  border-top: 1px inset var(--rouge_framboise);
}

.border-top-1-outset-rouge_framboise {
  border-top: 1px outset var(--rouge_framboise);
}

.border-top-1-none-rouge_framboise {
  border-top: 1px none var(--rouge_framboise);
}

.border-top-1-hidden-rouge_framboise {
  border-top: 1px hidden var(--rouge_framboise);
}

.border-top-1-solid-rose_chair_light {
  border-top: 1px solid var(--rose_chair_light);
}

.border-top-1-dashed-rose_chair_light {
  border-top: 1px dashed var(--rose_chair_light);
}

.border-top-1-dotted-rose_chair_light {
  border-top: 1px dotted var(--rose_chair_light);
}

.border-top-1-double-rose_chair_light {
  border-top: 1px double var(--rose_chair_light);
}

.border-top-1-groove-rose_chair_light {
  border-top: 1px groove var(--rose_chair_light);
}

.border-top-1-ridge-rose_chair_light {
  border-top: 1px ridge var(--rose_chair_light);
}

.border-top-1-inset-rose_chair_light {
  border-top: 1px inset var(--rose_chair_light);
}

.border-top-1-outset-rose_chair_light {
  border-top: 1px outset var(--rose_chair_light);
}

.border-top-1-none-rose_chair_light {
  border-top: 1px none var(--rose_chair_light);
}

.border-top-1-hidden-rose_chair_light {
  border-top: 1px hidden var(--rose_chair_light);
}

.border-top-1-solid-rose_chair_dark {
  border-top: 1px solid var(--rose_chair_dark);
}

.border-top-1-dashed-rose_chair_dark {
  border-top: 1px dashed var(--rose_chair_dark);
}

.border-top-1-dotted-rose_chair_dark {
  border-top: 1px dotted var(--rose_chair_dark);
}

.border-top-1-double-rose_chair_dark {
  border-top: 1px double var(--rose_chair_dark);
}

.border-top-1-groove-rose_chair_dark {
  border-top: 1px groove var(--rose_chair_dark);
}

.border-top-1-ridge-rose_chair_dark {
  border-top: 1px ridge var(--rose_chair_dark);
}

.border-top-1-inset-rose_chair_dark {
  border-top: 1px inset var(--rose_chair_dark);
}

.border-top-1-outset-rose_chair_dark {
  border-top: 1px outset var(--rose_chair_dark);
}

.border-top-1-none-rose_chair_dark {
  border-top: 1px none var(--rose_chair_dark);
}

.border-top-1-hidden-rose_chair_dark {
  border-top: 1px hidden var(--rose_chair_dark);
}

.border-top-1-solid-vert_positif {
  border-top: 1px solid var(--vert_positif);
}

.border-top-1-dashed-vert_positif {
  border-top: 1px dashed var(--vert_positif);
}

.border-top-1-dotted-vert_positif {
  border-top: 1px dotted var(--vert_positif);
}

.border-top-1-double-vert_positif {
  border-top: 1px double var(--vert_positif);
}

.border-top-1-groove-vert_positif {
  border-top: 1px groove var(--vert_positif);
}

.border-top-1-ridge-vert_positif {
  border-top: 1px ridge var(--vert_positif);
}

.border-top-1-inset-vert_positif {
  border-top: 1px inset var(--vert_positif);
}

.border-top-1-outset-vert_positif {
  border-top: 1px outset var(--vert_positif);
}

.border-top-1-none-vert_positif {
  border-top: 1px none var(--vert_positif);
}

.border-top-1-hidden-vert_positif {
  border-top: 1px hidden var(--vert_positif);
}

.border-top-1-solid-rouge_negatif {
  border-top: 1px solid var(--rouge_negatif);
}

.border-top-1-dashed-rouge_negatif {
  border-top: 1px dashed var(--rouge_negatif);
}

.border-top-1-dotted-rouge_negatif {
  border-top: 1px dotted var(--rouge_negatif);
}

.border-top-1-double-rouge_negatif {
  border-top: 1px double var(--rouge_negatif);
}

.border-top-1-groove-rouge_negatif {
  border-top: 1px groove var(--rouge_negatif);
}

.border-top-1-ridge-rouge_negatif {
  border-top: 1px ridge var(--rouge_negatif);
}

.border-top-1-inset-rouge_negatif {
  border-top: 1px inset var(--rouge_negatif);
}

.border-top-1-outset-rouge_negatif {
  border-top: 1px outset var(--rouge_negatif);
}

.border-top-1-none-rouge_negatif {
  border-top: 1px none var(--rouge_negatif);
}

.border-top-1-hidden-rouge_negatif {
  border-top: 1px hidden var(--rouge_negatif);
}

.border-top-1-solid-bleu_texte {
  border-top: 1px solid var(--bleu_texte);
}

.border-top-1-dashed-bleu_texte {
  border-top: 1px dashed var(--bleu_texte);
}

.border-top-1-dotted-bleu_texte {
  border-top: 1px dotted var(--bleu_texte);
}

.border-top-1-double-bleu_texte {
  border-top: 1px double var(--bleu_texte);
}

.border-top-1-groove-bleu_texte {
  border-top: 1px groove var(--bleu_texte);
}

.border-top-1-ridge-bleu_texte {
  border-top: 1px ridge var(--bleu_texte);
}

.border-top-1-inset-bleu_texte {
  border-top: 1px inset var(--bleu_texte);
}

.border-top-1-outset-bleu_texte {
  border-top: 1px outset var(--bleu_texte);
}

.border-top-1-none-bleu_texte {
  border-top: 1px none var(--bleu_texte);
}

.border-top-1-hidden-bleu_texte {
  border-top: 1px hidden var(--bleu_texte);
}

.border-top-1-solid-alerte_vert_positif {
  border-top: 1px solid var(--alerte_vert_positif);
}

.border-top-1-dashed-alerte_vert_positif {
  border-top: 1px dashed var(--alerte_vert_positif);
}

.border-top-1-dotted-alerte_vert_positif {
  border-top: 1px dotted var(--alerte_vert_positif);
}

.border-top-1-double-alerte_vert_positif {
  border-top: 1px double var(--alerte_vert_positif);
}

.border-top-1-groove-alerte_vert_positif {
  border-top: 1px groove var(--alerte_vert_positif);
}

.border-top-1-ridge-alerte_vert_positif {
  border-top: 1px ridge var(--alerte_vert_positif);
}

.border-top-1-inset-alerte_vert_positif {
  border-top: 1px inset var(--alerte_vert_positif);
}

.border-top-1-outset-alerte_vert_positif {
  border-top: 1px outset var(--alerte_vert_positif);
}

.border-top-1-none-alerte_vert_positif {
  border-top: 1px none var(--alerte_vert_positif);
}

.border-top-1-hidden-alerte_vert_positif {
  border-top: 1px hidden var(--alerte_vert_positif);
}

.border-top-1-solid-alerte_rouge_negatif {
  border-top: 1px solid var(--alerte_rouge_negatif);
}

.border-top-1-dashed-alerte_rouge_negatif {
  border-top: 1px dashed var(--alerte_rouge_negatif);
}

.border-top-1-dotted-alerte_rouge_negatif {
  border-top: 1px dotted var(--alerte_rouge_negatif);
}

.border-top-1-double-alerte_rouge_negatif {
  border-top: 1px double var(--alerte_rouge_negatif);
}

.border-top-1-groove-alerte_rouge_negatif {
  border-top: 1px groove var(--alerte_rouge_negatif);
}

.border-top-1-ridge-alerte_rouge_negatif {
  border-top: 1px ridge var(--alerte_rouge_negatif);
}

.border-top-1-inset-alerte_rouge_negatif {
  border-top: 1px inset var(--alerte_rouge_negatif);
}

.border-top-1-outset-alerte_rouge_negatif {
  border-top: 1px outset var(--alerte_rouge_negatif);
}

.border-top-1-none-alerte_rouge_negatif {
  border-top: 1px none var(--alerte_rouge_negatif);
}

.border-top-1-hidden-alerte_rouge_negatif {
  border-top: 1px hidden var(--alerte_rouge_negatif);
}

.border-top-1-solid-alerte_bleu_information {
  border-top: 1px solid var(--alerte_bleu_information);
}

.border-top-1-dashed-alerte_bleu_information {
  border-top: 1px dashed var(--alerte_bleu_information);
}

.border-top-1-dotted-alerte_bleu_information {
  border-top: 1px dotted var(--alerte_bleu_information);
}

.border-top-1-double-alerte_bleu_information {
  border-top: 1px double var(--alerte_bleu_information);
}

.border-top-1-groove-alerte_bleu_information {
  border-top: 1px groove var(--alerte_bleu_information);
}

.border-top-1-ridge-alerte_bleu_information {
  border-top: 1px ridge var(--alerte_bleu_information);
}

.border-top-1-inset-alerte_bleu_information {
  border-top: 1px inset var(--alerte_bleu_information);
}

.border-top-1-outset-alerte_bleu_information {
  border-top: 1px outset var(--alerte_bleu_information);
}

.border-top-1-none-alerte_bleu_information {
  border-top: 1px none var(--alerte_bleu_information);
}

.border-top-1-hidden-alerte_bleu_information {
  border-top: 1px hidden var(--alerte_bleu_information);
}

.border-top-1-solid-alerte_jaune_attention {
  border-top: 1px solid var(--alerte_jaune_attention);
}

.border-top-1-dashed-alerte_jaune_attention {
  border-top: 1px dashed var(--alerte_jaune_attention);
}

.border-top-1-dotted-alerte_jaune_attention {
  border-top: 1px dotted var(--alerte_jaune_attention);
}

.border-top-1-double-alerte_jaune_attention {
  border-top: 1px double var(--alerte_jaune_attention);
}

.border-top-1-groove-alerte_jaune_attention {
  border-top: 1px groove var(--alerte_jaune_attention);
}

.border-top-1-ridge-alerte_jaune_attention {
  border-top: 1px ridge var(--alerte_jaune_attention);
}

.border-top-1-inset-alerte_jaune_attention {
  border-top: 1px inset var(--alerte_jaune_attention);
}

.border-top-1-outset-alerte_jaune_attention {
  border-top: 1px outset var(--alerte_jaune_attention);
}

.border-top-1-none-alerte_jaune_attention {
  border-top: 1px none var(--alerte_jaune_attention);
}

.border-top-1-hidden-alerte_jaune_attention {
  border-top: 1px hidden var(--alerte_jaune_attention);
}

.border-top-1-solid-blanc {
  border-top: 1px solid #fff;
}

.border-top-1-dashed-blanc {
  border-top: 1px dashed #fff;
}

.border-top-1-dotted-blanc {
  border-top: 1px dotted #fff;
}

.border-top-1-double-blanc {
  border-top: 1px double #fff;
}

.border-top-1-groove-blanc {
  border-top: 1px groove #fff;
}

.border-top-1-ridge-blanc {
  border-top: 1px ridge #fff;
}

.border-top-1-inset-blanc {
  border-top: 1px inset #fff;
}

.border-top-1-outset-blanc {
  border-top: 1px outset #fff;
}

.border-top-1-none-blanc {
  border-top: 1px #fff;
}

.border-top-1-hidden-blanc {
  border-top: 1px hidden #fff;
}

.border-top-1-solid-noir {
  border-top: 1px solid #222;
}

.border-top-1-dashed-noir {
  border-top: 1px dashed #222;
}

.border-top-1-dotted-noir {
  border-top: 1px dotted #222;
}

.border-top-1-double-noir {
  border-top: 1px double #222;
}

.border-top-1-groove-noir {
  border-top: 1px groove #222;
}

.border-top-1-ridge-noir {
  border-top: 1px ridge #222;
}

.border-top-1-inset-noir {
  border-top: 1px inset #222;
}

.border-top-1-outset-noir {
  border-top: 1px outset #222;
}

.border-top-1-none-noir {
  border-top: 1px #222;
}

.border-top-1-hidden-noir {
  border-top: 1px hidden #222;
}

.border-top-1-solid-gris_light {
  border-top: 1px solid var(--gris_light);
}

.border-top-1-dashed-gris_light {
  border-top: 1px dashed var(--gris_light);
}

.border-top-1-dotted-gris_light {
  border-top: 1px dotted var(--gris_light);
}

.border-top-1-double-gris_light {
  border-top: 1px double var(--gris_light);
}

.border-top-1-groove-gris_light {
  border-top: 1px groove var(--gris_light);
}

.border-top-1-ridge-gris_light {
  border-top: 1px ridge var(--gris_light);
}

.border-top-1-inset-gris_light {
  border-top: 1px inset var(--gris_light);
}

.border-top-1-outset-gris_light {
  border-top: 1px outset var(--gris_light);
}

.border-top-1-none-gris_light {
  border-top: 1px none var(--gris_light);
}

.border-top-1-hidden-gris_light {
  border-top: 1px hidden var(--gris_light);
}

.border-top-1-solid-gris_dark {
  border-top: 1px solid var(--gris_dark);
}

.border-top-1-dashed-gris_dark {
  border-top: 1px dashed var(--gris_dark);
}

.border-top-1-dotted-gris_dark {
  border-top: 1px dotted var(--gris_dark);
}

.border-top-1-double-gris_dark {
  border-top: 1px double var(--gris_dark);
}

.border-top-1-groove-gris_dark {
  border-top: 1px groove var(--gris_dark);
}

.border-top-1-ridge-gris_dark {
  border-top: 1px ridge var(--gris_dark);
}

.border-top-1-inset-gris_dark {
  border-top: 1px inset var(--gris_dark);
}

.border-top-1-outset-gris_dark {
  border-top: 1px outset var(--gris_dark);
}

.border-top-1-none-gris_dark {
  border-top: 1px none var(--gris_dark);
}

.border-top-1-hidden-gris_dark {
  border-top: 1px hidden var(--gris_dark);
}

.border-top-1-solid-gris_background {
  border-top: 1px solid var(--gris_background);
}

.border-top-1-dashed-gris_background {
  border-top: 1px dashed var(--gris_background);
}

.border-top-1-dotted-gris_background {
  border-top: 1px dotted var(--gris_background);
}

.border-top-1-double-gris_background {
  border-top: 1px double var(--gris_background);
}

.border-top-1-groove-gris_background {
  border-top: 1px groove var(--gris_background);
}

.border-top-1-ridge-gris_background {
  border-top: 1px ridge var(--gris_background);
}

.border-top-1-inset-gris_background {
  border-top: 1px inset var(--gris_background);
}

.border-top-1-outset-gris_background {
  border-top: 1px outset var(--gris_background);
}

.border-top-1-none-gris_background {
  border-top: 1px none var(--gris_background);
}

.border-top-1-hidden-gris_background {
  border-top: 1px hidden var(--gris_background);
}

.border-bottom-1-solid-corail_mh_brand {
  border-bottom: 1px solid var(--corail_mh_brand);
}

.border-bottom-1-dashed-corail_mh_brand {
  border-bottom: 1px dashed var(--corail_mh_brand);
}

.border-bottom-1-dotted-corail_mh_brand {
  border-bottom: 1px dotted var(--corail_mh_brand);
}

.border-bottom-1-double-corail_mh_brand {
  border-bottom: 1px double var(--corail_mh_brand);
}

.border-bottom-1-groove-corail_mh_brand {
  border-bottom: 1px groove var(--corail_mh_brand);
}

.border-bottom-1-ridge-corail_mh_brand {
  border-bottom: 1px ridge var(--corail_mh_brand);
}

.border-bottom-1-inset-corail_mh_brand {
  border-bottom: 1px inset var(--corail_mh_brand);
}

.border-bottom-1-outset-corail_mh_brand {
  border-bottom: 1px outset var(--corail_mh_brand);
}

.border-bottom-1-none-corail_mh_brand {
  border-bottom: 1px none var(--corail_mh_brand);
}

.border-bottom-1-hidden-corail_mh_brand {
  border-bottom: 1px hidden var(--corail_mh_brand);
}

.border-bottom-1-solid-corail_mh_dark {
  border-bottom: 1px solid var(--corail_mh_dark);
}

.border-bottom-1-dashed-corail_mh_dark {
  border-bottom: 1px dashed var(--corail_mh_dark);
}

.border-bottom-1-dotted-corail_mh_dark {
  border-bottom: 1px dotted var(--corail_mh_dark);
}

.border-bottom-1-double-corail_mh_dark {
  border-bottom: 1px double var(--corail_mh_dark);
}

.border-bottom-1-groove-corail_mh_dark {
  border-bottom: 1px groove var(--corail_mh_dark);
}

.border-bottom-1-ridge-corail_mh_dark {
  border-bottom: 1px ridge var(--corail_mh_dark);
}

.border-bottom-1-inset-corail_mh_dark {
  border-bottom: 1px inset var(--corail_mh_dark);
}

.border-bottom-1-outset-corail_mh_dark {
  border-bottom: 1px outset var(--corail_mh_dark);
}

.border-bottom-1-none-corail_mh_dark {
  border-bottom: 1px none var(--corail_mh_dark);
}

.border-bottom-1-hidden-corail_mh_dark {
  border-bottom: 1px hidden var(--corail_mh_dark);
}

.border-bottom-1-solid-corail_mh_light {
  border-bottom: 1px solid var(--corail_mh_light);
}

.border-bottom-1-dashed-corail_mh_light {
  border-bottom: 1px dashed var(--corail_mh_light);
}

.border-bottom-1-dotted-corail_mh_light {
  border-bottom: 1px dotted var(--corail_mh_light);
}

.border-bottom-1-double-corail_mh_light {
  border-bottom: 1px double var(--corail_mh_light);
}

.border-bottom-1-groove-corail_mh_light {
  border-bottom: 1px groove var(--corail_mh_light);
}

.border-bottom-1-ridge-corail_mh_light {
  border-bottom: 1px ridge var(--corail_mh_light);
}

.border-bottom-1-inset-corail_mh_light {
  border-bottom: 1px inset var(--corail_mh_light);
}

.border-bottom-1-outset-corail_mh_light {
  border-bottom: 1px outset var(--corail_mh_light);
}

.border-bottom-1-none-corail_mh_light {
  border-bottom: 1px none var(--corail_mh_light);
}

.border-bottom-1-hidden-corail_mh_light {
  border-bottom: 1px hidden var(--corail_mh_light);
}

.border-bottom-1-solid-gris_sable_light {
  border-bottom: 1px solid var(--gris_sable_light);
}

.border-bottom-1-dashed-gris_sable_light {
  border-bottom: 1px dashed var(--gris_sable_light);
}

.border-bottom-1-dotted-gris_sable_light {
  border-bottom: 1px dotted var(--gris_sable_light);
}

.border-bottom-1-double-gris_sable_light {
  border-bottom: 1px double var(--gris_sable_light);
}

.border-bottom-1-groove-gris_sable_light {
  border-bottom: 1px groove var(--gris_sable_light);
}

.border-bottom-1-ridge-gris_sable_light {
  border-bottom: 1px ridge var(--gris_sable_light);
}

.border-bottom-1-inset-gris_sable_light {
  border-bottom: 1px inset var(--gris_sable_light);
}

.border-bottom-1-outset-gris_sable_light {
  border-bottom: 1px outset var(--gris_sable_light);
}

.border-bottom-1-none-gris_sable_light {
  border-bottom: 1px none var(--gris_sable_light);
}

.border-bottom-1-hidden-gris_sable_light {
  border-bottom: 1px hidden var(--gris_sable_light);
}

.border-bottom-1-solid-gris_sable_dark {
  border-bottom: 1px solid var(--gris_sable_dark);
}

.border-bottom-1-dashed-gris_sable_dark {
  border-bottom: 1px dashed var(--gris_sable_dark);
}

.border-bottom-1-dotted-gris_sable_dark {
  border-bottom: 1px dotted var(--gris_sable_dark);
}

.border-bottom-1-double-gris_sable_dark {
  border-bottom: 1px double var(--gris_sable_dark);
}

.border-bottom-1-groove-gris_sable_dark {
  border-bottom: 1px groove var(--gris_sable_dark);
}

.border-bottom-1-ridge-gris_sable_dark {
  border-bottom: 1px ridge var(--gris_sable_dark);
}

.border-bottom-1-inset-gris_sable_dark {
  border-bottom: 1px inset var(--gris_sable_dark);
}

.border-bottom-1-outset-gris_sable_dark {
  border-bottom: 1px outset var(--gris_sable_dark);
}

.border-bottom-1-none-gris_sable_dark {
  border-bottom: 1px none var(--gris_sable_dark);
}

.border-bottom-1-hidden-gris_sable_dark {
  border-bottom: 1px hidden var(--gris_sable_dark);
}

.border-bottom-1-solid-jaune_ivoire_light {
  border-bottom: 1px solid var(--jaune_ivoire_light);
}

.border-bottom-1-dashed-jaune_ivoire_light {
  border-bottom: 1px dashed var(--jaune_ivoire_light);
}

.border-bottom-1-dotted-jaune_ivoire_light {
  border-bottom: 1px dotted var(--jaune_ivoire_light);
}

.border-bottom-1-double-jaune_ivoire_light {
  border-bottom: 1px double var(--jaune_ivoire_light);
}

.border-bottom-1-groove-jaune_ivoire_light {
  border-bottom: 1px groove var(--jaune_ivoire_light);
}

.border-bottom-1-ridge-jaune_ivoire_light {
  border-bottom: 1px ridge var(--jaune_ivoire_light);
}

.border-bottom-1-inset-jaune_ivoire_light {
  border-bottom: 1px inset var(--jaune_ivoire_light);
}

.border-bottom-1-outset-jaune_ivoire_light {
  border-bottom: 1px outset var(--jaune_ivoire_light);
}

.border-bottom-1-none-jaune_ivoire_light {
  border-bottom: 1px none var(--jaune_ivoire_light);
}

.border-bottom-1-hidden-jaune_ivoire_light {
  border-bottom: 1px hidden var(--jaune_ivoire_light);
}

.border-bottom-1-solid-jaune_ivoire_dark {
  border-bottom: 1px solid var(--jaune_ivoire_dark);
}

.border-bottom-1-dashed-jaune_ivoire_dark {
  border-bottom: 1px dashed var(--jaune_ivoire_dark);
}

.border-bottom-1-dotted-jaune_ivoire_dark {
  border-bottom: 1px dotted var(--jaune_ivoire_dark);
}

.border-bottom-1-double-jaune_ivoire_dark {
  border-bottom: 1px double var(--jaune_ivoire_dark);
}

.border-bottom-1-groove-jaune_ivoire_dark {
  border-bottom: 1px groove var(--jaune_ivoire_dark);
}

.border-bottom-1-ridge-jaune_ivoire_dark {
  border-bottom: 1px ridge var(--jaune_ivoire_dark);
}

.border-bottom-1-inset-jaune_ivoire_dark {
  border-bottom: 1px inset var(--jaune_ivoire_dark);
}

.border-bottom-1-outset-jaune_ivoire_dark {
  border-bottom: 1px outset var(--jaune_ivoire_dark);
}

.border-bottom-1-none-jaune_ivoire_dark {
  border-bottom: 1px none var(--jaune_ivoire_dark);
}

.border-bottom-1-hidden-jaune_ivoire_dark {
  border-bottom: 1px hidden var(--jaune_ivoire_dark);
}

.border-bottom-1-solid-jaune_light {
  border-bottom: 1px solid var(--jaune_light);
}

.border-bottom-1-dashed-jaune_light {
  border-bottom: 1px dashed var(--jaune_light);
}

.border-bottom-1-dotted-jaune_light {
  border-bottom: 1px dotted var(--jaune_light);
}

.border-bottom-1-double-jaune_light {
  border-bottom: 1px double var(--jaune_light);
}

.border-bottom-1-groove-jaune_light {
  border-bottom: 1px groove var(--jaune_light);
}

.border-bottom-1-ridge-jaune_light {
  border-bottom: 1px ridge var(--jaune_light);
}

.border-bottom-1-inset-jaune_light {
  border-bottom: 1px inset var(--jaune_light);
}

.border-bottom-1-outset-jaune_light {
  border-bottom: 1px outset var(--jaune_light);
}

.border-bottom-1-none-jaune_light {
  border-bottom: 1px none var(--jaune_light);
}

.border-bottom-1-hidden-jaune_light {
  border-bottom: 1px hidden var(--jaune_light);
}

.border-bottom-1-solid-jaune_dark {
  border-bottom: 1px solid var(--jaune_dark);
}

.border-bottom-1-dashed-jaune_dark {
  border-bottom: 1px dashed var(--jaune_dark);
}

.border-bottom-1-dotted-jaune_dark {
  border-bottom: 1px dotted var(--jaune_dark);
}

.border-bottom-1-double-jaune_dark {
  border-bottom: 1px double var(--jaune_dark);
}

.border-bottom-1-groove-jaune_dark {
  border-bottom: 1px groove var(--jaune_dark);
}

.border-bottom-1-ridge-jaune_dark {
  border-bottom: 1px ridge var(--jaune_dark);
}

.border-bottom-1-inset-jaune_dark {
  border-bottom: 1px inset var(--jaune_dark);
}

.border-bottom-1-outset-jaune_dark {
  border-bottom: 1px outset var(--jaune_dark);
}

.border-bottom-1-none-jaune_dark {
  border-bottom: 1px none var(--jaune_dark);
}

.border-bottom-1-hidden-jaune_dark {
  border-bottom: 1px hidden var(--jaune_dark);
}

.border-bottom-1-solid-orange_normal {
  border-bottom: 1px solid var(--orange_normal);
}

.border-bottom-1-dashed-orange_normal {
  border-bottom: 1px dashed var(--orange_normal);
}

.border-bottom-1-dotted-orange_normal {
  border-bottom: 1px dotted var(--orange_normal);
}

.border-bottom-1-double-orange_normal {
  border-bottom: 1px double var(--orange_normal);
}

.border-bottom-1-groove-orange_normal {
  border-bottom: 1px groove var(--orange_normal);
}

.border-bottom-1-ridge-orange_normal {
  border-bottom: 1px ridge var(--orange_normal);
}

.border-bottom-1-inset-orange_normal {
  border-bottom: 1px inset var(--orange_normal);
}

.border-bottom-1-outset-orange_normal {
  border-bottom: 1px outset var(--orange_normal);
}

.border-bottom-1-none-orange_normal {
  border-bottom: 1px none var(--orange_normal);
}

.border-bottom-1-hidden-orange_normal {
  border-bottom: 1px hidden var(--orange_normal);
}

.border-bottom-1-solid-orange_dark {
  border-bottom: 1px solid var(--orange_dark);
}

.border-bottom-1-dashed-orange_dark {
  border-bottom: 1px dashed var(--orange_dark);
}

.border-bottom-1-dotted-orange_dark {
  border-bottom: 1px dotted var(--orange_dark);
}

.border-bottom-1-double-orange_dark {
  border-bottom: 1px double var(--orange_dark);
}

.border-bottom-1-groove-orange_dark {
  border-bottom: 1px groove var(--orange_dark);
}

.border-bottom-1-ridge-orange_dark {
  border-bottom: 1px ridge var(--orange_dark);
}

.border-bottom-1-inset-orange_dark {
  border-bottom: 1px inset var(--orange_dark);
}

.border-bottom-1-outset-orange_dark {
  border-bottom: 1px outset var(--orange_dark);
}

.border-bottom-1-none-orange_dark {
  border-bottom: 1px none var(--orange_dark);
}

.border-bottom-1-hidden-orange_dark {
  border-bottom: 1px hidden var(--orange_dark);
}

.border-bottom-1-solid-vert_light {
  border-bottom: 1px solid var(--vert_light);
}

.border-bottom-1-dashed-vert_light {
  border-bottom: 1px dashed var(--vert_light);
}

.border-bottom-1-dotted-vert_light {
  border-bottom: 1px dotted var(--vert_light);
}

.border-bottom-1-double-vert_light {
  border-bottom: 1px double var(--vert_light);
}

.border-bottom-1-groove-vert_light {
  border-bottom: 1px groove var(--vert_light);
}

.border-bottom-1-ridge-vert_light {
  border-bottom: 1px ridge var(--vert_light);
}

.border-bottom-1-inset-vert_light {
  border-bottom: 1px inset var(--vert_light);
}

.border-bottom-1-outset-vert_light {
  border-bottom: 1px outset var(--vert_light);
}

.border-bottom-1-none-vert_light {
  border-bottom: 1px none var(--vert_light);
}

.border-bottom-1-hidden-vert_light {
  border-bottom: 1px hidden var(--vert_light);
}

.border-bottom-1-solid-vert_dark {
  border-bottom: 1px solid var(--vert_dark);
}

.border-bottom-1-dashed-vert_dark {
  border-bottom: 1px dashed var(--vert_dark);
}

.border-bottom-1-dotted-vert_dark {
  border-bottom: 1px dotted var(--vert_dark);
}

.border-bottom-1-double-vert_dark {
  border-bottom: 1px double var(--vert_dark);
}

.border-bottom-1-groove-vert_dark {
  border-bottom: 1px groove var(--vert_dark);
}

.border-bottom-1-ridge-vert_dark {
  border-bottom: 1px ridge var(--vert_dark);
}

.border-bottom-1-inset-vert_dark {
  border-bottom: 1px inset var(--vert_dark);
}

.border-bottom-1-outset-vert_dark {
  border-bottom: 1px outset var(--vert_dark);
}

.border-bottom-1-none-vert_dark {
  border-bottom: 1px none var(--vert_dark);
}

.border-bottom-1-hidden-vert_dark {
  border-bottom: 1px hidden var(--vert_dark);
}

.border-bottom-1-solid-bleu_turquoise_light {
  border-bottom: 1px solid var(--bleu_turquoise_light);
}

.border-bottom-1-dashed-bleu_turquoise_light {
  border-bottom: 1px dashed var(--bleu_turquoise_light);
}

.border-bottom-1-dotted-bleu_turquoise_light {
  border-bottom: 1px dotted var(--bleu_turquoise_light);
}

.border-bottom-1-double-bleu_turquoise_light {
  border-bottom: 1px double var(--bleu_turquoise_light);
}

.border-bottom-1-groove-bleu_turquoise_light {
  border-bottom: 1px groove var(--bleu_turquoise_light);
}

.border-bottom-1-ridge-bleu_turquoise_light {
  border-bottom: 1px ridge var(--bleu_turquoise_light);
}

.border-bottom-1-inset-bleu_turquoise_light {
  border-bottom: 1px inset var(--bleu_turquoise_light);
}

.border-bottom-1-outset-bleu_turquoise_light {
  border-bottom: 1px outset var(--bleu_turquoise_light);
}

.border-bottom-1-none-bleu_turquoise_light {
  border-bottom: 1px none var(--bleu_turquoise_light);
}

.border-bottom-1-hidden-bleu_turquoise_light {
  border-bottom: 1px hidden var(--bleu_turquoise_light);
}

.border-bottom-1-solid-bleu_turquoise_middle {
  border-bottom: 1px solid var(--bleu_turquoise_middle);
}

.border-bottom-1-dashed-bleu_turquoise_middle {
  border-bottom: 1px dashed var(--bleu_turquoise_middle);
}

.border-bottom-1-dotted-bleu_turquoise_middle {
  border-bottom: 1px dotted var(--bleu_turquoise_middle);
}

.border-bottom-1-double-bleu_turquoise_middle {
  border-bottom: 1px double var(--bleu_turquoise_middle);
}

.border-bottom-1-groove-bleu_turquoise_middle {
  border-bottom: 1px groove var(--bleu_turquoise_middle);
}

.border-bottom-1-ridge-bleu_turquoise_middle {
  border-bottom: 1px ridge var(--bleu_turquoise_middle);
}

.border-bottom-1-inset-bleu_turquoise_middle {
  border-bottom: 1px inset var(--bleu_turquoise_middle);
}

.border-bottom-1-outset-bleu_turquoise_middle {
  border-bottom: 1px outset var(--bleu_turquoise_middle);
}

.border-bottom-1-none-bleu_turquoise_middle {
  border-bottom: 1px none var(--bleu_turquoise_middle);
}

.border-bottom-1-hidden-bleu_turquoise_middle {
  border-bottom: 1px hidden var(--bleu_turquoise_middle);
}

.border-bottom-1-solid-bleu_turquoise_dark {
  border-bottom: 1px solid var(--bleu_turquoise_dark);
}

.border-bottom-1-dashed-bleu_turquoise_dark {
  border-bottom: 1px dashed var(--bleu_turquoise_dark);
}

.border-bottom-1-dotted-bleu_turquoise_dark {
  border-bottom: 1px dotted var(--bleu_turquoise_dark);
}

.border-bottom-1-double-bleu_turquoise_dark {
  border-bottom: 1px double var(--bleu_turquoise_dark);
}

.border-bottom-1-groove-bleu_turquoise_dark {
  border-bottom: 1px groove var(--bleu_turquoise_dark);
}

.border-bottom-1-ridge-bleu_turquoise_dark {
  border-bottom: 1px ridge var(--bleu_turquoise_dark);
}

.border-bottom-1-inset-bleu_turquoise_dark {
  border-bottom: 1px inset var(--bleu_turquoise_dark);
}

.border-bottom-1-outset-bleu_turquoise_dark {
  border-bottom: 1px outset var(--bleu_turquoise_dark);
}

.border-bottom-1-none-bleu_turquoise_dark {
  border-bottom: 1px none var(--bleu_turquoise_dark);
}

.border-bottom-1-hidden-bleu_turquoise_dark {
  border-bottom: 1px hidden var(--bleu_turquoise_dark);
}

.border-bottom-1-solid-bleu_light {
  border-bottom: 1px solid var(--bleu_light);
}

.border-bottom-1-dashed-bleu_light {
  border-bottom: 1px dashed var(--bleu_light);
}

.border-bottom-1-dotted-bleu_light {
  border-bottom: 1px dotted var(--bleu_light);
}

.border-bottom-1-double-bleu_light {
  border-bottom: 1px double var(--bleu_light);
}

.border-bottom-1-groove-bleu_light {
  border-bottom: 1px groove var(--bleu_light);
}

.border-bottom-1-ridge-bleu_light {
  border-bottom: 1px ridge var(--bleu_light);
}

.border-bottom-1-inset-bleu_light {
  border-bottom: 1px inset var(--bleu_light);
}

.border-bottom-1-outset-bleu_light {
  border-bottom: 1px outset var(--bleu_light);
}

.border-bottom-1-none-bleu_light {
  border-bottom: 1px none var(--bleu_light);
}

.border-bottom-1-hidden-bleu_light {
  border-bottom: 1px hidden var(--bleu_light);
}

.border-bottom-1-solid-bleu_dark {
  border-bottom: 1px solid var(--bleu_dark);
}

.border-bottom-1-dashed-bleu_dark {
  border-bottom: 1px dashed var(--bleu_dark);
}

.border-bottom-1-dotted-bleu_dark {
  border-bottom: 1px dotted var(--bleu_dark);
}

.border-bottom-1-double-bleu_dark {
  border-bottom: 1px double var(--bleu_dark);
}

.border-bottom-1-groove-bleu_dark {
  border-bottom: 1px groove var(--bleu_dark);
}

.border-bottom-1-ridge-bleu_dark {
  border-bottom: 1px ridge var(--bleu_dark);
}

.border-bottom-1-inset-bleu_dark {
  border-bottom: 1px inset var(--bleu_dark);
}

.border-bottom-1-outset-bleu_dark {
  border-bottom: 1px outset var(--bleu_dark);
}

.border-bottom-1-none-bleu_dark {
  border-bottom: 1px none var(--bleu_dark);
}

.border-bottom-1-hidden-bleu_dark {
  border-bottom: 1px hidden var(--bleu_dark);
}

.border-bottom-1-solid-bleu_klein_light {
  border-bottom: 1px solid var(--bleu_klein_light);
}

.border-bottom-1-dashed-bleu_klein_light {
  border-bottom: 1px dashed var(--bleu_klein_light);
}

.border-bottom-1-dotted-bleu_klein_light {
  border-bottom: 1px dotted var(--bleu_klein_light);
}

.border-bottom-1-double-bleu_klein_light {
  border-bottom: 1px double var(--bleu_klein_light);
}

.border-bottom-1-groove-bleu_klein_light {
  border-bottom: 1px groove var(--bleu_klein_light);
}

.border-bottom-1-ridge-bleu_klein_light {
  border-bottom: 1px ridge var(--bleu_klein_light);
}

.border-bottom-1-inset-bleu_klein_light {
  border-bottom: 1px inset var(--bleu_klein_light);
}

.border-bottom-1-outset-bleu_klein_light {
  border-bottom: 1px outset var(--bleu_klein_light);
}

.border-bottom-1-none-bleu_klein_light {
  border-bottom: 1px none var(--bleu_klein_light);
}

.border-bottom-1-hidden-bleu_klein_light {
  border-bottom: 1px hidden var(--bleu_klein_light);
}

.border-bottom-1-solid-bleu_klein_dark {
  border-bottom: 1px solid var(--bleu_klein_dark);
}

.border-bottom-1-dashed-bleu_klein_dark {
  border-bottom: 1px dashed var(--bleu_klein_dark);
}

.border-bottom-1-dotted-bleu_klein_dark {
  border-bottom: 1px dotted var(--bleu_klein_dark);
}

.border-bottom-1-double-bleu_klein_dark {
  border-bottom: 1px double var(--bleu_klein_dark);
}

.border-bottom-1-groove-bleu_klein_dark {
  border-bottom: 1px groove var(--bleu_klein_dark);
}

.border-bottom-1-ridge-bleu_klein_dark {
  border-bottom: 1px ridge var(--bleu_klein_dark);
}

.border-bottom-1-inset-bleu_klein_dark {
  border-bottom: 1px inset var(--bleu_klein_dark);
}

.border-bottom-1-outset-bleu_klein_dark {
  border-bottom: 1px outset var(--bleu_klein_dark);
}

.border-bottom-1-none-bleu_klein_dark {
  border-bottom: 1px none var(--bleu_klein_dark);
}

.border-bottom-1-hidden-bleu_klein_dark {
  border-bottom: 1px hidden var(--bleu_klein_dark);
}

.border-bottom-1-solid-violet_light {
  border-bottom: 1px solid var(--violet_light);
}

.border-bottom-1-dashed-violet_light {
  border-bottom: 1px dashed var(--violet_light);
}

.border-bottom-1-dotted-violet_light {
  border-bottom: 1px dotted var(--violet_light);
}

.border-bottom-1-double-violet_light {
  border-bottom: 1px double var(--violet_light);
}

.border-bottom-1-groove-violet_light {
  border-bottom: 1px groove var(--violet_light);
}

.border-bottom-1-ridge-violet_light {
  border-bottom: 1px ridge var(--violet_light);
}

.border-bottom-1-inset-violet_light {
  border-bottom: 1px inset var(--violet_light);
}

.border-bottom-1-outset-violet_light {
  border-bottom: 1px outset var(--violet_light);
}

.border-bottom-1-none-violet_light {
  border-bottom: 1px none var(--violet_light);
}

.border-bottom-1-hidden-violet_light {
  border-bottom: 1px hidden var(--violet_light);
}

.border-bottom-1-solid-violet_dark {
  border-bottom: 1px solid var(--violet_dark);
}

.border-bottom-1-dashed-violet_dark {
  border-bottom: 1px dashed var(--violet_dark);
}

.border-bottom-1-dotted-violet_dark {
  border-bottom: 1px dotted var(--violet_dark);
}

.border-bottom-1-double-violet_dark {
  border-bottom: 1px double var(--violet_dark);
}

.border-bottom-1-groove-violet_dark {
  border-bottom: 1px groove var(--violet_dark);
}

.border-bottom-1-ridge-violet_dark {
  border-bottom: 1px ridge var(--violet_dark);
}

.border-bottom-1-inset-violet_dark {
  border-bottom: 1px inset var(--violet_dark);
}

.border-bottom-1-outset-violet_dark {
  border-bottom: 1px outset var(--violet_dark);
}

.border-bottom-1-none-violet_dark {
  border-bottom: 1px none var(--violet_dark);
}

.border-bottom-1-hidden-violet_dark {
  border-bottom: 1px hidden var(--violet_dark);
}

.border-bottom-1-solid-mauve {
  border-bottom: 1px solid var(--mauve);
}

.border-bottom-1-dashed-mauve {
  border-bottom: 1px dashed var(--mauve);
}

.border-bottom-1-dotted-mauve {
  border-bottom: 1px dotted var(--mauve);
}

.border-bottom-1-double-mauve {
  border-bottom: 1px double var(--mauve);
}

.border-bottom-1-groove-mauve {
  border-bottom: 1px groove var(--mauve);
}

.border-bottom-1-ridge-mauve {
  border-bottom: 1px ridge var(--mauve);
}

.border-bottom-1-inset-mauve {
  border-bottom: 1px inset var(--mauve);
}

.border-bottom-1-outset-mauve {
  border-bottom: 1px outset var(--mauve);
}

.border-bottom-1-none-mauve {
  border-bottom: 1px none var(--mauve);
}

.border-bottom-1-hidden-mauve {
  border-bottom: 1px hidden var(--mauve);
}

.border-bottom-1-solid-rose_light {
  border-bottom: 1px solid var(--rose_light);
}

.border-bottom-1-dashed-rose_light {
  border-bottom: 1px dashed var(--rose_light);
}

.border-bottom-1-dotted-rose_light {
  border-bottom: 1px dotted var(--rose_light);
}

.border-bottom-1-double-rose_light {
  border-bottom: 1px double var(--rose_light);
}

.border-bottom-1-groove-rose_light {
  border-bottom: 1px groove var(--rose_light);
}

.border-bottom-1-ridge-rose_light {
  border-bottom: 1px ridge var(--rose_light);
}

.border-bottom-1-inset-rose_light {
  border-bottom: 1px inset var(--rose_light);
}

.border-bottom-1-outset-rose_light {
  border-bottom: 1px outset var(--rose_light);
}

.border-bottom-1-none-rose_light {
  border-bottom: 1px none var(--rose_light);
}

.border-bottom-1-hidden-rose_light {
  border-bottom: 1px hidden var(--rose_light);
}

.border-bottom-1-solid-rose_dark {
  border-bottom: 1px solid var(--rose_dark);
}

.border-bottom-1-dashed-rose_dark {
  border-bottom: 1px dashed var(--rose_dark);
}

.border-bottom-1-dotted-rose_dark {
  border-bottom: 1px dotted var(--rose_dark);
}

.border-bottom-1-double-rose_dark {
  border-bottom: 1px double var(--rose_dark);
}

.border-bottom-1-groove-rose_dark {
  border-bottom: 1px groove var(--rose_dark);
}

.border-bottom-1-ridge-rose_dark {
  border-bottom: 1px ridge var(--rose_dark);
}

.border-bottom-1-inset-rose_dark {
  border-bottom: 1px inset var(--rose_dark);
}

.border-bottom-1-outset-rose_dark {
  border-bottom: 1px outset var(--rose_dark);
}

.border-bottom-1-none-rose_dark {
  border-bottom: 1px none var(--rose_dark);
}

.border-bottom-1-hidden-rose_dark {
  border-bottom: 1px hidden var(--rose_dark);
}

.border-bottom-1-solid-rose_fushia {
  border-bottom: 1px solid var(--rose_fushia);
}

.border-bottom-1-dashed-rose_fushia {
  border-bottom: 1px dashed var(--rose_fushia);
}

.border-bottom-1-dotted-rose_fushia {
  border-bottom: 1px dotted var(--rose_fushia);
}

.border-bottom-1-double-rose_fushia {
  border-bottom: 1px double var(--rose_fushia);
}

.border-bottom-1-groove-rose_fushia {
  border-bottom: 1px groove var(--rose_fushia);
}

.border-bottom-1-ridge-rose_fushia {
  border-bottom: 1px ridge var(--rose_fushia);
}

.border-bottom-1-inset-rose_fushia {
  border-bottom: 1px inset var(--rose_fushia);
}

.border-bottom-1-outset-rose_fushia {
  border-bottom: 1px outset var(--rose_fushia);
}

.border-bottom-1-none-rose_fushia {
  border-bottom: 1px none var(--rose_fushia);
}

.border-bottom-1-hidden-rose_fushia {
  border-bottom: 1px hidden var(--rose_fushia);
}

.border-bottom-1-solid-rouge_framboise {
  border-bottom: 1px solid var(--rouge_framboise);
}

.border-bottom-1-dashed-rouge_framboise {
  border-bottom: 1px dashed var(--rouge_framboise);
}

.border-bottom-1-dotted-rouge_framboise {
  border-bottom: 1px dotted var(--rouge_framboise);
}

.border-bottom-1-double-rouge_framboise {
  border-bottom: 1px double var(--rouge_framboise);
}

.border-bottom-1-groove-rouge_framboise {
  border-bottom: 1px groove var(--rouge_framboise);
}

.border-bottom-1-ridge-rouge_framboise {
  border-bottom: 1px ridge var(--rouge_framboise);
}

.border-bottom-1-inset-rouge_framboise {
  border-bottom: 1px inset var(--rouge_framboise);
}

.border-bottom-1-outset-rouge_framboise {
  border-bottom: 1px outset var(--rouge_framboise);
}

.border-bottom-1-none-rouge_framboise {
  border-bottom: 1px none var(--rouge_framboise);
}

.border-bottom-1-hidden-rouge_framboise {
  border-bottom: 1px hidden var(--rouge_framboise);
}

.border-bottom-1-solid-rose_chair_light {
  border-bottom: 1px solid var(--rose_chair_light);
}

.border-bottom-1-dashed-rose_chair_light {
  border-bottom: 1px dashed var(--rose_chair_light);
}

.border-bottom-1-dotted-rose_chair_light {
  border-bottom: 1px dotted var(--rose_chair_light);
}

.border-bottom-1-double-rose_chair_light {
  border-bottom: 1px double var(--rose_chair_light);
}

.border-bottom-1-groove-rose_chair_light {
  border-bottom: 1px groove var(--rose_chair_light);
}

.border-bottom-1-ridge-rose_chair_light {
  border-bottom: 1px ridge var(--rose_chair_light);
}

.border-bottom-1-inset-rose_chair_light {
  border-bottom: 1px inset var(--rose_chair_light);
}

.border-bottom-1-outset-rose_chair_light {
  border-bottom: 1px outset var(--rose_chair_light);
}

.border-bottom-1-none-rose_chair_light {
  border-bottom: 1px none var(--rose_chair_light);
}

.border-bottom-1-hidden-rose_chair_light {
  border-bottom: 1px hidden var(--rose_chair_light);
}

.border-bottom-1-solid-rose_chair_dark {
  border-bottom: 1px solid var(--rose_chair_dark);
}

.border-bottom-1-dashed-rose_chair_dark {
  border-bottom: 1px dashed var(--rose_chair_dark);
}

.border-bottom-1-dotted-rose_chair_dark {
  border-bottom: 1px dotted var(--rose_chair_dark);
}

.border-bottom-1-double-rose_chair_dark {
  border-bottom: 1px double var(--rose_chair_dark);
}

.border-bottom-1-groove-rose_chair_dark {
  border-bottom: 1px groove var(--rose_chair_dark);
}

.border-bottom-1-ridge-rose_chair_dark {
  border-bottom: 1px ridge var(--rose_chair_dark);
}

.border-bottom-1-inset-rose_chair_dark {
  border-bottom: 1px inset var(--rose_chair_dark);
}

.border-bottom-1-outset-rose_chair_dark {
  border-bottom: 1px outset var(--rose_chair_dark);
}

.border-bottom-1-none-rose_chair_dark {
  border-bottom: 1px none var(--rose_chair_dark);
}

.border-bottom-1-hidden-rose_chair_dark {
  border-bottom: 1px hidden var(--rose_chair_dark);
}

.border-bottom-1-solid-vert_positif {
  border-bottom: 1px solid var(--vert_positif);
}

.border-bottom-1-dashed-vert_positif {
  border-bottom: 1px dashed var(--vert_positif);
}

.border-bottom-1-dotted-vert_positif {
  border-bottom: 1px dotted var(--vert_positif);
}

.border-bottom-1-double-vert_positif {
  border-bottom: 1px double var(--vert_positif);
}

.border-bottom-1-groove-vert_positif {
  border-bottom: 1px groove var(--vert_positif);
}

.border-bottom-1-ridge-vert_positif {
  border-bottom: 1px ridge var(--vert_positif);
}

.border-bottom-1-inset-vert_positif {
  border-bottom: 1px inset var(--vert_positif);
}

.border-bottom-1-outset-vert_positif {
  border-bottom: 1px outset var(--vert_positif);
}

.border-bottom-1-none-vert_positif {
  border-bottom: 1px none var(--vert_positif);
}

.border-bottom-1-hidden-vert_positif {
  border-bottom: 1px hidden var(--vert_positif);
}

.border-bottom-1-solid-rouge_negatif {
  border-bottom: 1px solid var(--rouge_negatif);
}

.border-bottom-1-dashed-rouge_negatif {
  border-bottom: 1px dashed var(--rouge_negatif);
}

.border-bottom-1-dotted-rouge_negatif {
  border-bottom: 1px dotted var(--rouge_negatif);
}

.border-bottom-1-double-rouge_negatif {
  border-bottom: 1px double var(--rouge_negatif);
}

.border-bottom-1-groove-rouge_negatif {
  border-bottom: 1px groove var(--rouge_negatif);
}

.border-bottom-1-ridge-rouge_negatif {
  border-bottom: 1px ridge var(--rouge_negatif);
}

.border-bottom-1-inset-rouge_negatif {
  border-bottom: 1px inset var(--rouge_negatif);
}

.border-bottom-1-outset-rouge_negatif {
  border-bottom: 1px outset var(--rouge_negatif);
}

.border-bottom-1-none-rouge_negatif {
  border-bottom: 1px none var(--rouge_negatif);
}

.border-bottom-1-hidden-rouge_negatif {
  border-bottom: 1px hidden var(--rouge_negatif);
}

.border-bottom-1-solid-bleu_texte {
  border-bottom: 1px solid var(--bleu_texte);
}

.border-bottom-1-dashed-bleu_texte {
  border-bottom: 1px dashed var(--bleu_texte);
}

.border-bottom-1-dotted-bleu_texte {
  border-bottom: 1px dotted var(--bleu_texte);
}

.border-bottom-1-double-bleu_texte {
  border-bottom: 1px double var(--bleu_texte);
}

.border-bottom-1-groove-bleu_texte {
  border-bottom: 1px groove var(--bleu_texte);
}

.border-bottom-1-ridge-bleu_texte {
  border-bottom: 1px ridge var(--bleu_texte);
}

.border-bottom-1-inset-bleu_texte {
  border-bottom: 1px inset var(--bleu_texte);
}

.border-bottom-1-outset-bleu_texte {
  border-bottom: 1px outset var(--bleu_texte);
}

.border-bottom-1-none-bleu_texte {
  border-bottom: 1px none var(--bleu_texte);
}

.border-bottom-1-hidden-bleu_texte {
  border-bottom: 1px hidden var(--bleu_texte);
}

.border-bottom-1-solid-alerte_vert_positif {
  border-bottom: 1px solid var(--alerte_vert_positif);
}

.border-bottom-1-dashed-alerte_vert_positif {
  border-bottom: 1px dashed var(--alerte_vert_positif);
}

.border-bottom-1-dotted-alerte_vert_positif {
  border-bottom: 1px dotted var(--alerte_vert_positif);
}

.border-bottom-1-double-alerte_vert_positif {
  border-bottom: 1px double var(--alerte_vert_positif);
}

.border-bottom-1-groove-alerte_vert_positif {
  border-bottom: 1px groove var(--alerte_vert_positif);
}

.border-bottom-1-ridge-alerte_vert_positif {
  border-bottom: 1px ridge var(--alerte_vert_positif);
}

.border-bottom-1-inset-alerte_vert_positif {
  border-bottom: 1px inset var(--alerte_vert_positif);
}

.border-bottom-1-outset-alerte_vert_positif {
  border-bottom: 1px outset var(--alerte_vert_positif);
}

.border-bottom-1-none-alerte_vert_positif {
  border-bottom: 1px none var(--alerte_vert_positif);
}

.border-bottom-1-hidden-alerte_vert_positif {
  border-bottom: 1px hidden var(--alerte_vert_positif);
}

.border-bottom-1-solid-alerte_rouge_negatif {
  border-bottom: 1px solid var(--alerte_rouge_negatif);
}

.border-bottom-1-dashed-alerte_rouge_negatif {
  border-bottom: 1px dashed var(--alerte_rouge_negatif);
}

.border-bottom-1-dotted-alerte_rouge_negatif {
  border-bottom: 1px dotted var(--alerte_rouge_negatif);
}

.border-bottom-1-double-alerte_rouge_negatif {
  border-bottom: 1px double var(--alerte_rouge_negatif);
}

.border-bottom-1-groove-alerte_rouge_negatif {
  border-bottom: 1px groove var(--alerte_rouge_negatif);
}

.border-bottom-1-ridge-alerte_rouge_negatif {
  border-bottom: 1px ridge var(--alerte_rouge_negatif);
}

.border-bottom-1-inset-alerte_rouge_negatif {
  border-bottom: 1px inset var(--alerte_rouge_negatif);
}

.border-bottom-1-outset-alerte_rouge_negatif {
  border-bottom: 1px outset var(--alerte_rouge_negatif);
}

.border-bottom-1-none-alerte_rouge_negatif {
  border-bottom: 1px none var(--alerte_rouge_negatif);
}

.border-bottom-1-hidden-alerte_rouge_negatif {
  border-bottom: 1px hidden var(--alerte_rouge_negatif);
}

.border-bottom-1-solid-alerte_bleu_information {
  border-bottom: 1px solid var(--alerte_bleu_information);
}

.border-bottom-1-dashed-alerte_bleu_information {
  border-bottom: 1px dashed var(--alerte_bleu_information);
}

.border-bottom-1-dotted-alerte_bleu_information {
  border-bottom: 1px dotted var(--alerte_bleu_information);
}

.border-bottom-1-double-alerte_bleu_information {
  border-bottom: 1px double var(--alerte_bleu_information);
}

.border-bottom-1-groove-alerte_bleu_information {
  border-bottom: 1px groove var(--alerte_bleu_information);
}

.border-bottom-1-ridge-alerte_bleu_information {
  border-bottom: 1px ridge var(--alerte_bleu_information);
}

.border-bottom-1-inset-alerte_bleu_information {
  border-bottom: 1px inset var(--alerte_bleu_information);
}

.border-bottom-1-outset-alerte_bleu_information {
  border-bottom: 1px outset var(--alerte_bleu_information);
}

.border-bottom-1-none-alerte_bleu_information {
  border-bottom: 1px none var(--alerte_bleu_information);
}

.border-bottom-1-hidden-alerte_bleu_information {
  border-bottom: 1px hidden var(--alerte_bleu_information);
}

.border-bottom-1-solid-alerte_jaune_attention {
  border-bottom: 1px solid var(--alerte_jaune_attention);
}

.border-bottom-1-dashed-alerte_jaune_attention {
  border-bottom: 1px dashed var(--alerte_jaune_attention);
}

.border-bottom-1-dotted-alerte_jaune_attention {
  border-bottom: 1px dotted var(--alerte_jaune_attention);
}

.border-bottom-1-double-alerte_jaune_attention {
  border-bottom: 1px double var(--alerte_jaune_attention);
}

.border-bottom-1-groove-alerte_jaune_attention {
  border-bottom: 1px groove var(--alerte_jaune_attention);
}

.border-bottom-1-ridge-alerte_jaune_attention {
  border-bottom: 1px ridge var(--alerte_jaune_attention);
}

.border-bottom-1-inset-alerte_jaune_attention {
  border-bottom: 1px inset var(--alerte_jaune_attention);
}

.border-bottom-1-outset-alerte_jaune_attention {
  border-bottom: 1px outset var(--alerte_jaune_attention);
}

.border-bottom-1-none-alerte_jaune_attention {
  border-bottom: 1px none var(--alerte_jaune_attention);
}

.border-bottom-1-hidden-alerte_jaune_attention {
  border-bottom: 1px hidden var(--alerte_jaune_attention);
}

.border-bottom-1-solid-blanc {
  border-bottom: 1px solid #fff;
}

.border-bottom-1-dashed-blanc {
  border-bottom: 1px dashed #fff;
}

.border-bottom-1-dotted-blanc {
  border-bottom: 1px dotted #fff;
}

.border-bottom-1-double-blanc {
  border-bottom: 1px double #fff;
}

.border-bottom-1-groove-blanc {
  border-bottom: 1px groove #fff;
}

.border-bottom-1-ridge-blanc {
  border-bottom: 1px ridge #fff;
}

.border-bottom-1-inset-blanc {
  border-bottom: 1px inset #fff;
}

.border-bottom-1-outset-blanc {
  border-bottom: 1px outset #fff;
}

.border-bottom-1-none-blanc {
  border-bottom: 1px #fff;
}

.border-bottom-1-hidden-blanc {
  border-bottom: 1px hidden #fff;
}

.border-bottom-1-solid-noir {
  border-bottom: 1px solid #222;
}

.border-bottom-1-dashed-noir {
  border-bottom: 1px dashed #222;
}

.border-bottom-1-dotted-noir {
  border-bottom: 1px dotted #222;
}

.border-bottom-1-double-noir {
  border-bottom: 1px double #222;
}

.border-bottom-1-groove-noir {
  border-bottom: 1px groove #222;
}

.border-bottom-1-ridge-noir {
  border-bottom: 1px ridge #222;
}

.border-bottom-1-inset-noir {
  border-bottom: 1px inset #222;
}

.border-bottom-1-outset-noir {
  border-bottom: 1px outset #222;
}

.border-bottom-1-none-noir {
  border-bottom: 1px #222;
}

.border-bottom-1-hidden-noir {
  border-bottom: 1px hidden #222;
}

.border-bottom-1-solid-gris_light {
  border-bottom: 1px solid var(--gris_light);
}

.border-bottom-1-dashed-gris_light {
  border-bottom: 1px dashed var(--gris_light);
}

.border-bottom-1-dotted-gris_light {
  border-bottom: 1px dotted var(--gris_light);
}

.border-bottom-1-double-gris_light {
  border-bottom: 1px double var(--gris_light);
}

.border-bottom-1-groove-gris_light {
  border-bottom: 1px groove var(--gris_light);
}

.border-bottom-1-ridge-gris_light {
  border-bottom: 1px ridge var(--gris_light);
}

.border-bottom-1-inset-gris_light {
  border-bottom: 1px inset var(--gris_light);
}

.border-bottom-1-outset-gris_light {
  border-bottom: 1px outset var(--gris_light);
}

.border-bottom-1-none-gris_light {
  border-bottom: 1px none var(--gris_light);
}

.border-bottom-1-hidden-gris_light {
  border-bottom: 1px hidden var(--gris_light);
}

.border-bottom-1-solid-gris_dark {
  border-bottom: 1px solid var(--gris_dark);
}

.border-bottom-1-dashed-gris_dark {
  border-bottom: 1px dashed var(--gris_dark);
}

.border-bottom-1-dotted-gris_dark {
  border-bottom: 1px dotted var(--gris_dark);
}

.border-bottom-1-double-gris_dark {
  border-bottom: 1px double var(--gris_dark);
}

.border-bottom-1-groove-gris_dark {
  border-bottom: 1px groove var(--gris_dark);
}

.border-bottom-1-ridge-gris_dark {
  border-bottom: 1px ridge var(--gris_dark);
}

.border-bottom-1-inset-gris_dark {
  border-bottom: 1px inset var(--gris_dark);
}

.border-bottom-1-outset-gris_dark {
  border-bottom: 1px outset var(--gris_dark);
}

.border-bottom-1-none-gris_dark {
  border-bottom: 1px none var(--gris_dark);
}

.border-bottom-1-hidden-gris_dark {
  border-bottom: 1px hidden var(--gris_dark);
}

.border-bottom-1-solid-gris_background {
  border-bottom: 1px solid var(--gris_background);
}

.border-bottom-1-dashed-gris_background {
  border-bottom: 1px dashed var(--gris_background);
}

.border-bottom-1-dotted-gris_background {
  border-bottom: 1px dotted var(--gris_background);
}

.border-bottom-1-double-gris_background {
  border-bottom: 1px double var(--gris_background);
}

.border-bottom-1-groove-gris_background {
  border-bottom: 1px groove var(--gris_background);
}

.border-bottom-1-ridge-gris_background {
  border-bottom: 1px ridge var(--gris_background);
}

.border-bottom-1-inset-gris_background {
  border-bottom: 1px inset var(--gris_background);
}

.border-bottom-1-outset-gris_background {
  border-bottom: 1px outset var(--gris_background);
}

.border-bottom-1-none-gris_background {
  border-bottom: 1px none var(--gris_background);
}

.border-bottom-1-hidden-gris_background {
  border-bottom: 1px hidden var(--gris_background);
}

.border-right-1-solid-corail_mh_brand {
  border-right: 1px solid var(--corail_mh_brand);
}

.border-right-1-dashed-corail_mh_brand {
  border-right: 1px dashed var(--corail_mh_brand);
}

.border-right-1-dotted-corail_mh_brand {
  border-right: 1px dotted var(--corail_mh_brand);
}

.border-right-1-double-corail_mh_brand {
  border-right: 1px double var(--corail_mh_brand);
}

.border-right-1-groove-corail_mh_brand {
  border-right: 1px groove var(--corail_mh_brand);
}

.border-right-1-ridge-corail_mh_brand {
  border-right: 1px ridge var(--corail_mh_brand);
}

.border-right-1-inset-corail_mh_brand {
  border-right: 1px inset var(--corail_mh_brand);
}

.border-right-1-outset-corail_mh_brand {
  border-right: 1px outset var(--corail_mh_brand);
}

.border-right-1-none-corail_mh_brand {
  border-right: 1px none var(--corail_mh_brand);
}

.border-right-1-hidden-corail_mh_brand {
  border-right: 1px hidden var(--corail_mh_brand);
}

.border-right-1-solid-corail_mh_dark {
  border-right: 1px solid var(--corail_mh_dark);
}

.border-right-1-dashed-corail_mh_dark {
  border-right: 1px dashed var(--corail_mh_dark);
}

.border-right-1-dotted-corail_mh_dark {
  border-right: 1px dotted var(--corail_mh_dark);
}

.border-right-1-double-corail_mh_dark {
  border-right: 1px double var(--corail_mh_dark);
}

.border-right-1-groove-corail_mh_dark {
  border-right: 1px groove var(--corail_mh_dark);
}

.border-right-1-ridge-corail_mh_dark {
  border-right: 1px ridge var(--corail_mh_dark);
}

.border-right-1-inset-corail_mh_dark {
  border-right: 1px inset var(--corail_mh_dark);
}

.border-right-1-outset-corail_mh_dark {
  border-right: 1px outset var(--corail_mh_dark);
}

.border-right-1-none-corail_mh_dark {
  border-right: 1px none var(--corail_mh_dark);
}

.border-right-1-hidden-corail_mh_dark {
  border-right: 1px hidden var(--corail_mh_dark);
}

.border-right-1-solid-corail_mh_light {
  border-right: 1px solid var(--corail_mh_light);
}

.border-right-1-dashed-corail_mh_light {
  border-right: 1px dashed var(--corail_mh_light);
}

.border-right-1-dotted-corail_mh_light {
  border-right: 1px dotted var(--corail_mh_light);
}

.border-right-1-double-corail_mh_light {
  border-right: 1px double var(--corail_mh_light);
}

.border-right-1-groove-corail_mh_light {
  border-right: 1px groove var(--corail_mh_light);
}

.border-right-1-ridge-corail_mh_light {
  border-right: 1px ridge var(--corail_mh_light);
}

.border-right-1-inset-corail_mh_light {
  border-right: 1px inset var(--corail_mh_light);
}

.border-right-1-outset-corail_mh_light {
  border-right: 1px outset var(--corail_mh_light);
}

.border-right-1-none-corail_mh_light {
  border-right: 1px none var(--corail_mh_light);
}

.border-right-1-hidden-corail_mh_light {
  border-right: 1px hidden var(--corail_mh_light);
}

.border-right-1-solid-gris_sable_light {
  border-right: 1px solid var(--gris_sable_light);
}

.border-right-1-dashed-gris_sable_light {
  border-right: 1px dashed var(--gris_sable_light);
}

.border-right-1-dotted-gris_sable_light {
  border-right: 1px dotted var(--gris_sable_light);
}

.border-right-1-double-gris_sable_light {
  border-right: 1px double var(--gris_sable_light);
}

.border-right-1-groove-gris_sable_light {
  border-right: 1px groove var(--gris_sable_light);
}

.border-right-1-ridge-gris_sable_light {
  border-right: 1px ridge var(--gris_sable_light);
}

.border-right-1-inset-gris_sable_light {
  border-right: 1px inset var(--gris_sable_light);
}

.border-right-1-outset-gris_sable_light {
  border-right: 1px outset var(--gris_sable_light);
}

.border-right-1-none-gris_sable_light {
  border-right: 1px none var(--gris_sable_light);
}

.border-right-1-hidden-gris_sable_light {
  border-right: 1px hidden var(--gris_sable_light);
}

.border-right-1-solid-gris_sable_dark {
  border-right: 1px solid var(--gris_sable_dark);
}

.border-right-1-dashed-gris_sable_dark {
  border-right: 1px dashed var(--gris_sable_dark);
}

.border-right-1-dotted-gris_sable_dark {
  border-right: 1px dotted var(--gris_sable_dark);
}

.border-right-1-double-gris_sable_dark {
  border-right: 1px double var(--gris_sable_dark);
}

.border-right-1-groove-gris_sable_dark {
  border-right: 1px groove var(--gris_sable_dark);
}

.border-right-1-ridge-gris_sable_dark {
  border-right: 1px ridge var(--gris_sable_dark);
}

.border-right-1-inset-gris_sable_dark {
  border-right: 1px inset var(--gris_sable_dark);
}

.border-right-1-outset-gris_sable_dark {
  border-right: 1px outset var(--gris_sable_dark);
}

.border-right-1-none-gris_sable_dark {
  border-right: 1px none var(--gris_sable_dark);
}

.border-right-1-hidden-gris_sable_dark {
  border-right: 1px hidden var(--gris_sable_dark);
}

.border-right-1-solid-jaune_ivoire_light {
  border-right: 1px solid var(--jaune_ivoire_light);
}

.border-right-1-dashed-jaune_ivoire_light {
  border-right: 1px dashed var(--jaune_ivoire_light);
}

.border-right-1-dotted-jaune_ivoire_light {
  border-right: 1px dotted var(--jaune_ivoire_light);
}

.border-right-1-double-jaune_ivoire_light {
  border-right: 1px double var(--jaune_ivoire_light);
}

.border-right-1-groove-jaune_ivoire_light {
  border-right: 1px groove var(--jaune_ivoire_light);
}

.border-right-1-ridge-jaune_ivoire_light {
  border-right: 1px ridge var(--jaune_ivoire_light);
}

.border-right-1-inset-jaune_ivoire_light {
  border-right: 1px inset var(--jaune_ivoire_light);
}

.border-right-1-outset-jaune_ivoire_light {
  border-right: 1px outset var(--jaune_ivoire_light);
}

.border-right-1-none-jaune_ivoire_light {
  border-right: 1px none var(--jaune_ivoire_light);
}

.border-right-1-hidden-jaune_ivoire_light {
  border-right: 1px hidden var(--jaune_ivoire_light);
}

.border-right-1-solid-jaune_ivoire_dark {
  border-right: 1px solid var(--jaune_ivoire_dark);
}

.border-right-1-dashed-jaune_ivoire_dark {
  border-right: 1px dashed var(--jaune_ivoire_dark);
}

.border-right-1-dotted-jaune_ivoire_dark {
  border-right: 1px dotted var(--jaune_ivoire_dark);
}

.border-right-1-double-jaune_ivoire_dark {
  border-right: 1px double var(--jaune_ivoire_dark);
}

.border-right-1-groove-jaune_ivoire_dark {
  border-right: 1px groove var(--jaune_ivoire_dark);
}

.border-right-1-ridge-jaune_ivoire_dark {
  border-right: 1px ridge var(--jaune_ivoire_dark);
}

.border-right-1-inset-jaune_ivoire_dark {
  border-right: 1px inset var(--jaune_ivoire_dark);
}

.border-right-1-outset-jaune_ivoire_dark {
  border-right: 1px outset var(--jaune_ivoire_dark);
}

.border-right-1-none-jaune_ivoire_dark {
  border-right: 1px none var(--jaune_ivoire_dark);
}

.border-right-1-hidden-jaune_ivoire_dark {
  border-right: 1px hidden var(--jaune_ivoire_dark);
}

.border-right-1-solid-jaune_light {
  border-right: 1px solid var(--jaune_light);
}

.border-right-1-dashed-jaune_light {
  border-right: 1px dashed var(--jaune_light);
}

.border-right-1-dotted-jaune_light {
  border-right: 1px dotted var(--jaune_light);
}

.border-right-1-double-jaune_light {
  border-right: 1px double var(--jaune_light);
}

.border-right-1-groove-jaune_light {
  border-right: 1px groove var(--jaune_light);
}

.border-right-1-ridge-jaune_light {
  border-right: 1px ridge var(--jaune_light);
}

.border-right-1-inset-jaune_light {
  border-right: 1px inset var(--jaune_light);
}

.border-right-1-outset-jaune_light {
  border-right: 1px outset var(--jaune_light);
}

.border-right-1-none-jaune_light {
  border-right: 1px none var(--jaune_light);
}

.border-right-1-hidden-jaune_light {
  border-right: 1px hidden var(--jaune_light);
}

.border-right-1-solid-jaune_dark {
  border-right: 1px solid var(--jaune_dark);
}

.border-right-1-dashed-jaune_dark {
  border-right: 1px dashed var(--jaune_dark);
}

.border-right-1-dotted-jaune_dark {
  border-right: 1px dotted var(--jaune_dark);
}

.border-right-1-double-jaune_dark {
  border-right: 1px double var(--jaune_dark);
}

.border-right-1-groove-jaune_dark {
  border-right: 1px groove var(--jaune_dark);
}

.border-right-1-ridge-jaune_dark {
  border-right: 1px ridge var(--jaune_dark);
}

.border-right-1-inset-jaune_dark {
  border-right: 1px inset var(--jaune_dark);
}

.border-right-1-outset-jaune_dark {
  border-right: 1px outset var(--jaune_dark);
}

.border-right-1-none-jaune_dark {
  border-right: 1px none var(--jaune_dark);
}

.border-right-1-hidden-jaune_dark {
  border-right: 1px hidden var(--jaune_dark);
}

.border-right-1-solid-orange_normal {
  border-right: 1px solid var(--orange_normal);
}

.border-right-1-dashed-orange_normal {
  border-right: 1px dashed var(--orange_normal);
}

.border-right-1-dotted-orange_normal {
  border-right: 1px dotted var(--orange_normal);
}

.border-right-1-double-orange_normal {
  border-right: 1px double var(--orange_normal);
}

.border-right-1-groove-orange_normal {
  border-right: 1px groove var(--orange_normal);
}

.border-right-1-ridge-orange_normal {
  border-right: 1px ridge var(--orange_normal);
}

.border-right-1-inset-orange_normal {
  border-right: 1px inset var(--orange_normal);
}

.border-right-1-outset-orange_normal {
  border-right: 1px outset var(--orange_normal);
}

.border-right-1-none-orange_normal {
  border-right: 1px none var(--orange_normal);
}

.border-right-1-hidden-orange_normal {
  border-right: 1px hidden var(--orange_normal);
}

.border-right-1-solid-orange_dark {
  border-right: 1px solid var(--orange_dark);
}

.border-right-1-dashed-orange_dark {
  border-right: 1px dashed var(--orange_dark);
}

.border-right-1-dotted-orange_dark {
  border-right: 1px dotted var(--orange_dark);
}

.border-right-1-double-orange_dark {
  border-right: 1px double var(--orange_dark);
}

.border-right-1-groove-orange_dark {
  border-right: 1px groove var(--orange_dark);
}

.border-right-1-ridge-orange_dark {
  border-right: 1px ridge var(--orange_dark);
}

.border-right-1-inset-orange_dark {
  border-right: 1px inset var(--orange_dark);
}

.border-right-1-outset-orange_dark {
  border-right: 1px outset var(--orange_dark);
}

.border-right-1-none-orange_dark {
  border-right: 1px none var(--orange_dark);
}

.border-right-1-hidden-orange_dark {
  border-right: 1px hidden var(--orange_dark);
}

.border-right-1-solid-vert_light {
  border-right: 1px solid var(--vert_light);
}

.border-right-1-dashed-vert_light {
  border-right: 1px dashed var(--vert_light);
}

.border-right-1-dotted-vert_light {
  border-right: 1px dotted var(--vert_light);
}

.border-right-1-double-vert_light {
  border-right: 1px double var(--vert_light);
}

.border-right-1-groove-vert_light {
  border-right: 1px groove var(--vert_light);
}

.border-right-1-ridge-vert_light {
  border-right: 1px ridge var(--vert_light);
}

.border-right-1-inset-vert_light {
  border-right: 1px inset var(--vert_light);
}

.border-right-1-outset-vert_light {
  border-right: 1px outset var(--vert_light);
}

.border-right-1-none-vert_light {
  border-right: 1px none var(--vert_light);
}

.border-right-1-hidden-vert_light {
  border-right: 1px hidden var(--vert_light);
}

.border-right-1-solid-vert_dark {
  border-right: 1px solid var(--vert_dark);
}

.border-right-1-dashed-vert_dark {
  border-right: 1px dashed var(--vert_dark);
}

.border-right-1-dotted-vert_dark {
  border-right: 1px dotted var(--vert_dark);
}

.border-right-1-double-vert_dark {
  border-right: 1px double var(--vert_dark);
}

.border-right-1-groove-vert_dark {
  border-right: 1px groove var(--vert_dark);
}

.border-right-1-ridge-vert_dark {
  border-right: 1px ridge var(--vert_dark);
}

.border-right-1-inset-vert_dark {
  border-right: 1px inset var(--vert_dark);
}

.border-right-1-outset-vert_dark {
  border-right: 1px outset var(--vert_dark);
}

.border-right-1-none-vert_dark {
  border-right: 1px none var(--vert_dark);
}

.border-right-1-hidden-vert_dark {
  border-right: 1px hidden var(--vert_dark);
}

.border-right-1-solid-bleu_turquoise_light {
  border-right: 1px solid var(--bleu_turquoise_light);
}

.border-right-1-dashed-bleu_turquoise_light {
  border-right: 1px dashed var(--bleu_turquoise_light);
}

.border-right-1-dotted-bleu_turquoise_light {
  border-right: 1px dotted var(--bleu_turquoise_light);
}

.border-right-1-double-bleu_turquoise_light {
  border-right: 1px double var(--bleu_turquoise_light);
}

.border-right-1-groove-bleu_turquoise_light {
  border-right: 1px groove var(--bleu_turquoise_light);
}

.border-right-1-ridge-bleu_turquoise_light {
  border-right: 1px ridge var(--bleu_turquoise_light);
}

.border-right-1-inset-bleu_turquoise_light {
  border-right: 1px inset var(--bleu_turquoise_light);
}

.border-right-1-outset-bleu_turquoise_light {
  border-right: 1px outset var(--bleu_turquoise_light);
}

.border-right-1-none-bleu_turquoise_light {
  border-right: 1px none var(--bleu_turquoise_light);
}

.border-right-1-hidden-bleu_turquoise_light {
  border-right: 1px hidden var(--bleu_turquoise_light);
}

.border-right-1-solid-bleu_turquoise_middle {
  border-right: 1px solid var(--bleu_turquoise_middle);
}

.border-right-1-dashed-bleu_turquoise_middle {
  border-right: 1px dashed var(--bleu_turquoise_middle);
}

.border-right-1-dotted-bleu_turquoise_middle {
  border-right: 1px dotted var(--bleu_turquoise_middle);
}

.border-right-1-double-bleu_turquoise_middle {
  border-right: 1px double var(--bleu_turquoise_middle);
}

.border-right-1-groove-bleu_turquoise_middle {
  border-right: 1px groove var(--bleu_turquoise_middle);
}

.border-right-1-ridge-bleu_turquoise_middle {
  border-right: 1px ridge var(--bleu_turquoise_middle);
}

.border-right-1-inset-bleu_turquoise_middle {
  border-right: 1px inset var(--bleu_turquoise_middle);
}

.border-right-1-outset-bleu_turquoise_middle {
  border-right: 1px outset var(--bleu_turquoise_middle);
}

.border-right-1-none-bleu_turquoise_middle {
  border-right: 1px none var(--bleu_turquoise_middle);
}

.border-right-1-hidden-bleu_turquoise_middle {
  border-right: 1px hidden var(--bleu_turquoise_middle);
}

.border-right-1-solid-bleu_turquoise_dark {
  border-right: 1px solid var(--bleu_turquoise_dark);
}

.border-right-1-dashed-bleu_turquoise_dark {
  border-right: 1px dashed var(--bleu_turquoise_dark);
}

.border-right-1-dotted-bleu_turquoise_dark {
  border-right: 1px dotted var(--bleu_turquoise_dark);
}

.border-right-1-double-bleu_turquoise_dark {
  border-right: 1px double var(--bleu_turquoise_dark);
}

.border-right-1-groove-bleu_turquoise_dark {
  border-right: 1px groove var(--bleu_turquoise_dark);
}

.border-right-1-ridge-bleu_turquoise_dark {
  border-right: 1px ridge var(--bleu_turquoise_dark);
}

.border-right-1-inset-bleu_turquoise_dark {
  border-right: 1px inset var(--bleu_turquoise_dark);
}

.border-right-1-outset-bleu_turquoise_dark {
  border-right: 1px outset var(--bleu_turquoise_dark);
}

.border-right-1-none-bleu_turquoise_dark {
  border-right: 1px none var(--bleu_turquoise_dark);
}

.border-right-1-hidden-bleu_turquoise_dark {
  border-right: 1px hidden var(--bleu_turquoise_dark);
}

.border-right-1-solid-bleu_light {
  border-right: 1px solid var(--bleu_light);
}

.border-right-1-dashed-bleu_light {
  border-right: 1px dashed var(--bleu_light);
}

.border-right-1-dotted-bleu_light {
  border-right: 1px dotted var(--bleu_light);
}

.border-right-1-double-bleu_light {
  border-right: 1px double var(--bleu_light);
}

.border-right-1-groove-bleu_light {
  border-right: 1px groove var(--bleu_light);
}

.border-right-1-ridge-bleu_light {
  border-right: 1px ridge var(--bleu_light);
}

.border-right-1-inset-bleu_light {
  border-right: 1px inset var(--bleu_light);
}

.border-right-1-outset-bleu_light {
  border-right: 1px outset var(--bleu_light);
}

.border-right-1-none-bleu_light {
  border-right: 1px none var(--bleu_light);
}

.border-right-1-hidden-bleu_light {
  border-right: 1px hidden var(--bleu_light);
}

.border-right-1-solid-bleu_dark {
  border-right: 1px solid var(--bleu_dark);
}

.border-right-1-dashed-bleu_dark {
  border-right: 1px dashed var(--bleu_dark);
}

.border-right-1-dotted-bleu_dark {
  border-right: 1px dotted var(--bleu_dark);
}

.border-right-1-double-bleu_dark {
  border-right: 1px double var(--bleu_dark);
}

.border-right-1-groove-bleu_dark {
  border-right: 1px groove var(--bleu_dark);
}

.border-right-1-ridge-bleu_dark {
  border-right: 1px ridge var(--bleu_dark);
}

.border-right-1-inset-bleu_dark {
  border-right: 1px inset var(--bleu_dark);
}

.border-right-1-outset-bleu_dark {
  border-right: 1px outset var(--bleu_dark);
}

.border-right-1-none-bleu_dark {
  border-right: 1px none var(--bleu_dark);
}

.border-right-1-hidden-bleu_dark {
  border-right: 1px hidden var(--bleu_dark);
}

.border-right-1-solid-bleu_klein_light {
  border-right: 1px solid var(--bleu_klein_light);
}

.border-right-1-dashed-bleu_klein_light {
  border-right: 1px dashed var(--bleu_klein_light);
}

.border-right-1-dotted-bleu_klein_light {
  border-right: 1px dotted var(--bleu_klein_light);
}

.border-right-1-double-bleu_klein_light {
  border-right: 1px double var(--bleu_klein_light);
}

.border-right-1-groove-bleu_klein_light {
  border-right: 1px groove var(--bleu_klein_light);
}

.border-right-1-ridge-bleu_klein_light {
  border-right: 1px ridge var(--bleu_klein_light);
}

.border-right-1-inset-bleu_klein_light {
  border-right: 1px inset var(--bleu_klein_light);
}

.border-right-1-outset-bleu_klein_light {
  border-right: 1px outset var(--bleu_klein_light);
}

.border-right-1-none-bleu_klein_light {
  border-right: 1px none var(--bleu_klein_light);
}

.border-right-1-hidden-bleu_klein_light {
  border-right: 1px hidden var(--bleu_klein_light);
}

.border-right-1-solid-bleu_klein_dark {
  border-right: 1px solid var(--bleu_klein_dark);
}

.border-right-1-dashed-bleu_klein_dark {
  border-right: 1px dashed var(--bleu_klein_dark);
}

.border-right-1-dotted-bleu_klein_dark {
  border-right: 1px dotted var(--bleu_klein_dark);
}

.border-right-1-double-bleu_klein_dark {
  border-right: 1px double var(--bleu_klein_dark);
}

.border-right-1-groove-bleu_klein_dark {
  border-right: 1px groove var(--bleu_klein_dark);
}

.border-right-1-ridge-bleu_klein_dark {
  border-right: 1px ridge var(--bleu_klein_dark);
}

.border-right-1-inset-bleu_klein_dark {
  border-right: 1px inset var(--bleu_klein_dark);
}

.border-right-1-outset-bleu_klein_dark {
  border-right: 1px outset var(--bleu_klein_dark);
}

.border-right-1-none-bleu_klein_dark {
  border-right: 1px none var(--bleu_klein_dark);
}

.border-right-1-hidden-bleu_klein_dark {
  border-right: 1px hidden var(--bleu_klein_dark);
}

.border-right-1-solid-violet_light {
  border-right: 1px solid var(--violet_light);
}

.border-right-1-dashed-violet_light {
  border-right: 1px dashed var(--violet_light);
}

.border-right-1-dotted-violet_light {
  border-right: 1px dotted var(--violet_light);
}

.border-right-1-double-violet_light {
  border-right: 1px double var(--violet_light);
}

.border-right-1-groove-violet_light {
  border-right: 1px groove var(--violet_light);
}

.border-right-1-ridge-violet_light {
  border-right: 1px ridge var(--violet_light);
}

.border-right-1-inset-violet_light {
  border-right: 1px inset var(--violet_light);
}

.border-right-1-outset-violet_light {
  border-right: 1px outset var(--violet_light);
}

.border-right-1-none-violet_light {
  border-right: 1px none var(--violet_light);
}

.border-right-1-hidden-violet_light {
  border-right: 1px hidden var(--violet_light);
}

.border-right-1-solid-violet_dark {
  border-right: 1px solid var(--violet_dark);
}

.border-right-1-dashed-violet_dark {
  border-right: 1px dashed var(--violet_dark);
}

.border-right-1-dotted-violet_dark {
  border-right: 1px dotted var(--violet_dark);
}

.border-right-1-double-violet_dark {
  border-right: 1px double var(--violet_dark);
}

.border-right-1-groove-violet_dark {
  border-right: 1px groove var(--violet_dark);
}

.border-right-1-ridge-violet_dark {
  border-right: 1px ridge var(--violet_dark);
}

.border-right-1-inset-violet_dark {
  border-right: 1px inset var(--violet_dark);
}

.border-right-1-outset-violet_dark {
  border-right: 1px outset var(--violet_dark);
}

.border-right-1-none-violet_dark {
  border-right: 1px none var(--violet_dark);
}

.border-right-1-hidden-violet_dark {
  border-right: 1px hidden var(--violet_dark);
}

.border-right-1-solid-mauve {
  border-right: 1px solid var(--mauve);
}

.border-right-1-dashed-mauve {
  border-right: 1px dashed var(--mauve);
}

.border-right-1-dotted-mauve {
  border-right: 1px dotted var(--mauve);
}

.border-right-1-double-mauve {
  border-right: 1px double var(--mauve);
}

.border-right-1-groove-mauve {
  border-right: 1px groove var(--mauve);
}

.border-right-1-ridge-mauve {
  border-right: 1px ridge var(--mauve);
}

.border-right-1-inset-mauve {
  border-right: 1px inset var(--mauve);
}

.border-right-1-outset-mauve {
  border-right: 1px outset var(--mauve);
}

.border-right-1-none-mauve {
  border-right: 1px none var(--mauve);
}

.border-right-1-hidden-mauve {
  border-right: 1px hidden var(--mauve);
}

.border-right-1-solid-rose_light {
  border-right: 1px solid var(--rose_light);
}

.border-right-1-dashed-rose_light {
  border-right: 1px dashed var(--rose_light);
}

.border-right-1-dotted-rose_light {
  border-right: 1px dotted var(--rose_light);
}

.border-right-1-double-rose_light {
  border-right: 1px double var(--rose_light);
}

.border-right-1-groove-rose_light {
  border-right: 1px groove var(--rose_light);
}

.border-right-1-ridge-rose_light {
  border-right: 1px ridge var(--rose_light);
}

.border-right-1-inset-rose_light {
  border-right: 1px inset var(--rose_light);
}

.border-right-1-outset-rose_light {
  border-right: 1px outset var(--rose_light);
}

.border-right-1-none-rose_light {
  border-right: 1px none var(--rose_light);
}

.border-right-1-hidden-rose_light {
  border-right: 1px hidden var(--rose_light);
}

.border-right-1-solid-rose_dark {
  border-right: 1px solid var(--rose_dark);
}

.border-right-1-dashed-rose_dark {
  border-right: 1px dashed var(--rose_dark);
}

.border-right-1-dotted-rose_dark {
  border-right: 1px dotted var(--rose_dark);
}

.border-right-1-double-rose_dark {
  border-right: 1px double var(--rose_dark);
}

.border-right-1-groove-rose_dark {
  border-right: 1px groove var(--rose_dark);
}

.border-right-1-ridge-rose_dark {
  border-right: 1px ridge var(--rose_dark);
}

.border-right-1-inset-rose_dark {
  border-right: 1px inset var(--rose_dark);
}

.border-right-1-outset-rose_dark {
  border-right: 1px outset var(--rose_dark);
}

.border-right-1-none-rose_dark {
  border-right: 1px none var(--rose_dark);
}

.border-right-1-hidden-rose_dark {
  border-right: 1px hidden var(--rose_dark);
}

.border-right-1-solid-rose_fushia {
  border-right: 1px solid var(--rose_fushia);
}

.border-right-1-dashed-rose_fushia {
  border-right: 1px dashed var(--rose_fushia);
}

.border-right-1-dotted-rose_fushia {
  border-right: 1px dotted var(--rose_fushia);
}

.border-right-1-double-rose_fushia {
  border-right: 1px double var(--rose_fushia);
}

.border-right-1-groove-rose_fushia {
  border-right: 1px groove var(--rose_fushia);
}

.border-right-1-ridge-rose_fushia {
  border-right: 1px ridge var(--rose_fushia);
}

.border-right-1-inset-rose_fushia {
  border-right: 1px inset var(--rose_fushia);
}

.border-right-1-outset-rose_fushia {
  border-right: 1px outset var(--rose_fushia);
}

.border-right-1-none-rose_fushia {
  border-right: 1px none var(--rose_fushia);
}

.border-right-1-hidden-rose_fushia {
  border-right: 1px hidden var(--rose_fushia);
}

.border-right-1-solid-rouge_framboise {
  border-right: 1px solid var(--rouge_framboise);
}

.border-right-1-dashed-rouge_framboise {
  border-right: 1px dashed var(--rouge_framboise);
}

.border-right-1-dotted-rouge_framboise {
  border-right: 1px dotted var(--rouge_framboise);
}

.border-right-1-double-rouge_framboise {
  border-right: 1px double var(--rouge_framboise);
}

.border-right-1-groove-rouge_framboise {
  border-right: 1px groove var(--rouge_framboise);
}

.border-right-1-ridge-rouge_framboise {
  border-right: 1px ridge var(--rouge_framboise);
}

.border-right-1-inset-rouge_framboise {
  border-right: 1px inset var(--rouge_framboise);
}

.border-right-1-outset-rouge_framboise {
  border-right: 1px outset var(--rouge_framboise);
}

.border-right-1-none-rouge_framboise {
  border-right: 1px none var(--rouge_framboise);
}

.border-right-1-hidden-rouge_framboise {
  border-right: 1px hidden var(--rouge_framboise);
}

.border-right-1-solid-rose_chair_light {
  border-right: 1px solid var(--rose_chair_light);
}

.border-right-1-dashed-rose_chair_light {
  border-right: 1px dashed var(--rose_chair_light);
}

.border-right-1-dotted-rose_chair_light {
  border-right: 1px dotted var(--rose_chair_light);
}

.border-right-1-double-rose_chair_light {
  border-right: 1px double var(--rose_chair_light);
}

.border-right-1-groove-rose_chair_light {
  border-right: 1px groove var(--rose_chair_light);
}

.border-right-1-ridge-rose_chair_light {
  border-right: 1px ridge var(--rose_chair_light);
}

.border-right-1-inset-rose_chair_light {
  border-right: 1px inset var(--rose_chair_light);
}

.border-right-1-outset-rose_chair_light {
  border-right: 1px outset var(--rose_chair_light);
}

.border-right-1-none-rose_chair_light {
  border-right: 1px none var(--rose_chair_light);
}

.border-right-1-hidden-rose_chair_light {
  border-right: 1px hidden var(--rose_chair_light);
}

.border-right-1-solid-rose_chair_dark {
  border-right: 1px solid var(--rose_chair_dark);
}

.border-right-1-dashed-rose_chair_dark {
  border-right: 1px dashed var(--rose_chair_dark);
}

.border-right-1-dotted-rose_chair_dark {
  border-right: 1px dotted var(--rose_chair_dark);
}

.border-right-1-double-rose_chair_dark {
  border-right: 1px double var(--rose_chair_dark);
}

.border-right-1-groove-rose_chair_dark {
  border-right: 1px groove var(--rose_chair_dark);
}

.border-right-1-ridge-rose_chair_dark {
  border-right: 1px ridge var(--rose_chair_dark);
}

.border-right-1-inset-rose_chair_dark {
  border-right: 1px inset var(--rose_chair_dark);
}

.border-right-1-outset-rose_chair_dark {
  border-right: 1px outset var(--rose_chair_dark);
}

.border-right-1-none-rose_chair_dark {
  border-right: 1px none var(--rose_chair_dark);
}

.border-right-1-hidden-rose_chair_dark {
  border-right: 1px hidden var(--rose_chair_dark);
}

.border-right-1-solid-vert_positif {
  border-right: 1px solid var(--vert_positif);
}

.border-right-1-dashed-vert_positif {
  border-right: 1px dashed var(--vert_positif);
}

.border-right-1-dotted-vert_positif {
  border-right: 1px dotted var(--vert_positif);
}

.border-right-1-double-vert_positif {
  border-right: 1px double var(--vert_positif);
}

.border-right-1-groove-vert_positif {
  border-right: 1px groove var(--vert_positif);
}

.border-right-1-ridge-vert_positif {
  border-right: 1px ridge var(--vert_positif);
}

.border-right-1-inset-vert_positif {
  border-right: 1px inset var(--vert_positif);
}

.border-right-1-outset-vert_positif {
  border-right: 1px outset var(--vert_positif);
}

.border-right-1-none-vert_positif {
  border-right: 1px none var(--vert_positif);
}

.border-right-1-hidden-vert_positif {
  border-right: 1px hidden var(--vert_positif);
}

.border-right-1-solid-rouge_negatif {
  border-right: 1px solid var(--rouge_negatif);
}

.border-right-1-dashed-rouge_negatif {
  border-right: 1px dashed var(--rouge_negatif);
}

.border-right-1-dotted-rouge_negatif {
  border-right: 1px dotted var(--rouge_negatif);
}

.border-right-1-double-rouge_negatif {
  border-right: 1px double var(--rouge_negatif);
}

.border-right-1-groove-rouge_negatif {
  border-right: 1px groove var(--rouge_negatif);
}

.border-right-1-ridge-rouge_negatif {
  border-right: 1px ridge var(--rouge_negatif);
}

.border-right-1-inset-rouge_negatif {
  border-right: 1px inset var(--rouge_negatif);
}

.border-right-1-outset-rouge_negatif {
  border-right: 1px outset var(--rouge_negatif);
}

.border-right-1-none-rouge_negatif {
  border-right: 1px none var(--rouge_negatif);
}

.border-right-1-hidden-rouge_negatif {
  border-right: 1px hidden var(--rouge_negatif);
}

.border-right-1-solid-bleu_texte {
  border-right: 1px solid var(--bleu_texte);
}

.border-right-1-dashed-bleu_texte {
  border-right: 1px dashed var(--bleu_texte);
}

.border-right-1-dotted-bleu_texte {
  border-right: 1px dotted var(--bleu_texte);
}

.border-right-1-double-bleu_texte {
  border-right: 1px double var(--bleu_texte);
}

.border-right-1-groove-bleu_texte {
  border-right: 1px groove var(--bleu_texte);
}

.border-right-1-ridge-bleu_texte {
  border-right: 1px ridge var(--bleu_texte);
}

.border-right-1-inset-bleu_texte {
  border-right: 1px inset var(--bleu_texte);
}

.border-right-1-outset-bleu_texte {
  border-right: 1px outset var(--bleu_texte);
}

.border-right-1-none-bleu_texte {
  border-right: 1px none var(--bleu_texte);
}

.border-right-1-hidden-bleu_texte {
  border-right: 1px hidden var(--bleu_texte);
}

.border-right-1-solid-alerte_vert_positif {
  border-right: 1px solid var(--alerte_vert_positif);
}

.border-right-1-dashed-alerte_vert_positif {
  border-right: 1px dashed var(--alerte_vert_positif);
}

.border-right-1-dotted-alerte_vert_positif {
  border-right: 1px dotted var(--alerte_vert_positif);
}

.border-right-1-double-alerte_vert_positif {
  border-right: 1px double var(--alerte_vert_positif);
}

.border-right-1-groove-alerte_vert_positif {
  border-right: 1px groove var(--alerte_vert_positif);
}

.border-right-1-ridge-alerte_vert_positif {
  border-right: 1px ridge var(--alerte_vert_positif);
}

.border-right-1-inset-alerte_vert_positif {
  border-right: 1px inset var(--alerte_vert_positif);
}

.border-right-1-outset-alerte_vert_positif {
  border-right: 1px outset var(--alerte_vert_positif);
}

.border-right-1-none-alerte_vert_positif {
  border-right: 1px none var(--alerte_vert_positif);
}

.border-right-1-hidden-alerte_vert_positif {
  border-right: 1px hidden var(--alerte_vert_positif);
}

.border-right-1-solid-alerte_rouge_negatif {
  border-right: 1px solid var(--alerte_rouge_negatif);
}

.border-right-1-dashed-alerte_rouge_negatif {
  border-right: 1px dashed var(--alerte_rouge_negatif);
}

.border-right-1-dotted-alerte_rouge_negatif {
  border-right: 1px dotted var(--alerte_rouge_negatif);
}

.border-right-1-double-alerte_rouge_negatif {
  border-right: 1px double var(--alerte_rouge_negatif);
}

.border-right-1-groove-alerte_rouge_negatif {
  border-right: 1px groove var(--alerte_rouge_negatif);
}

.border-right-1-ridge-alerte_rouge_negatif {
  border-right: 1px ridge var(--alerte_rouge_negatif);
}

.border-right-1-inset-alerte_rouge_negatif {
  border-right: 1px inset var(--alerte_rouge_negatif);
}

.border-right-1-outset-alerte_rouge_negatif {
  border-right: 1px outset var(--alerte_rouge_negatif);
}

.border-right-1-none-alerte_rouge_negatif {
  border-right: 1px none var(--alerte_rouge_negatif);
}

.border-right-1-hidden-alerte_rouge_negatif {
  border-right: 1px hidden var(--alerte_rouge_negatif);
}

.border-right-1-solid-alerte_bleu_information {
  border-right: 1px solid var(--alerte_bleu_information);
}

.border-right-1-dashed-alerte_bleu_information {
  border-right: 1px dashed var(--alerte_bleu_information);
}

.border-right-1-dotted-alerte_bleu_information {
  border-right: 1px dotted var(--alerte_bleu_information);
}

.border-right-1-double-alerte_bleu_information {
  border-right: 1px double var(--alerte_bleu_information);
}

.border-right-1-groove-alerte_bleu_information {
  border-right: 1px groove var(--alerte_bleu_information);
}

.border-right-1-ridge-alerte_bleu_information {
  border-right: 1px ridge var(--alerte_bleu_information);
}

.border-right-1-inset-alerte_bleu_information {
  border-right: 1px inset var(--alerte_bleu_information);
}

.border-right-1-outset-alerte_bleu_information {
  border-right: 1px outset var(--alerte_bleu_information);
}

.border-right-1-none-alerte_bleu_information {
  border-right: 1px none var(--alerte_bleu_information);
}

.border-right-1-hidden-alerte_bleu_information {
  border-right: 1px hidden var(--alerte_bleu_information);
}

.border-right-1-solid-alerte_jaune_attention {
  border-right: 1px solid var(--alerte_jaune_attention);
}

.border-right-1-dashed-alerte_jaune_attention {
  border-right: 1px dashed var(--alerte_jaune_attention);
}

.border-right-1-dotted-alerte_jaune_attention {
  border-right: 1px dotted var(--alerte_jaune_attention);
}

.border-right-1-double-alerte_jaune_attention {
  border-right: 1px double var(--alerte_jaune_attention);
}

.border-right-1-groove-alerte_jaune_attention {
  border-right: 1px groove var(--alerte_jaune_attention);
}

.border-right-1-ridge-alerte_jaune_attention {
  border-right: 1px ridge var(--alerte_jaune_attention);
}

.border-right-1-inset-alerte_jaune_attention {
  border-right: 1px inset var(--alerte_jaune_attention);
}

.border-right-1-outset-alerte_jaune_attention {
  border-right: 1px outset var(--alerte_jaune_attention);
}

.border-right-1-none-alerte_jaune_attention {
  border-right: 1px none var(--alerte_jaune_attention);
}

.border-right-1-hidden-alerte_jaune_attention {
  border-right: 1px hidden var(--alerte_jaune_attention);
}

.border-right-1-solid-blanc {
  border-right: 1px solid #fff;
}

.border-right-1-dashed-blanc {
  border-right: 1px dashed #fff;
}

.border-right-1-dotted-blanc {
  border-right: 1px dotted #fff;
}

.border-right-1-double-blanc {
  border-right: 1px double #fff;
}

.border-right-1-groove-blanc {
  border-right: 1px groove #fff;
}

.border-right-1-ridge-blanc {
  border-right: 1px ridge #fff;
}

.border-right-1-inset-blanc {
  border-right: 1px inset #fff;
}

.border-right-1-outset-blanc {
  border-right: 1px outset #fff;
}

.border-right-1-none-blanc {
  border-right: 1px #fff;
}

.border-right-1-hidden-blanc {
  border-right: 1px hidden #fff;
}

.border-right-1-solid-noir {
  border-right: 1px solid #222;
}

.border-right-1-dashed-noir {
  border-right: 1px dashed #222;
}

.border-right-1-dotted-noir {
  border-right: 1px dotted #222;
}

.border-right-1-double-noir {
  border-right: 1px double #222;
}

.border-right-1-groove-noir {
  border-right: 1px groove #222;
}

.border-right-1-ridge-noir {
  border-right: 1px ridge #222;
}

.border-right-1-inset-noir {
  border-right: 1px inset #222;
}

.border-right-1-outset-noir {
  border-right: 1px outset #222;
}

.border-right-1-none-noir {
  border-right: 1px #222;
}

.border-right-1-hidden-noir {
  border-right: 1px hidden #222;
}

.border-right-1-solid-gris_light {
  border-right: 1px solid var(--gris_light);
}

.border-right-1-dashed-gris_light {
  border-right: 1px dashed var(--gris_light);
}

.border-right-1-dotted-gris_light {
  border-right: 1px dotted var(--gris_light);
}

.border-right-1-double-gris_light {
  border-right: 1px double var(--gris_light);
}

.border-right-1-groove-gris_light {
  border-right: 1px groove var(--gris_light);
}

.border-right-1-ridge-gris_light {
  border-right: 1px ridge var(--gris_light);
}

.border-right-1-inset-gris_light {
  border-right: 1px inset var(--gris_light);
}

.border-right-1-outset-gris_light {
  border-right: 1px outset var(--gris_light);
}

.border-right-1-none-gris_light {
  border-right: 1px none var(--gris_light);
}

.border-right-1-hidden-gris_light {
  border-right: 1px hidden var(--gris_light);
}

.border-right-1-solid-gris_dark {
  border-right: 1px solid var(--gris_dark);
}

.border-right-1-dashed-gris_dark {
  border-right: 1px dashed var(--gris_dark);
}

.border-right-1-dotted-gris_dark {
  border-right: 1px dotted var(--gris_dark);
}

.border-right-1-double-gris_dark {
  border-right: 1px double var(--gris_dark);
}

.border-right-1-groove-gris_dark {
  border-right: 1px groove var(--gris_dark);
}

.border-right-1-ridge-gris_dark {
  border-right: 1px ridge var(--gris_dark);
}

.border-right-1-inset-gris_dark {
  border-right: 1px inset var(--gris_dark);
}

.border-right-1-outset-gris_dark {
  border-right: 1px outset var(--gris_dark);
}

.border-right-1-none-gris_dark {
  border-right: 1px none var(--gris_dark);
}

.border-right-1-hidden-gris_dark {
  border-right: 1px hidden var(--gris_dark);
}

.border-right-1-solid-gris_background {
  border-right: 1px solid var(--gris_background);
}

.border-right-1-dashed-gris_background {
  border-right: 1px dashed var(--gris_background);
}

.border-right-1-dotted-gris_background {
  border-right: 1px dotted var(--gris_background);
}

.border-right-1-double-gris_background {
  border-right: 1px double var(--gris_background);
}

.border-right-1-groove-gris_background {
  border-right: 1px groove var(--gris_background);
}

.border-right-1-ridge-gris_background {
  border-right: 1px ridge var(--gris_background);
}

.border-right-1-inset-gris_background {
  border-right: 1px inset var(--gris_background);
}

.border-right-1-outset-gris_background {
  border-right: 1px outset var(--gris_background);
}

.border-right-1-none-gris_background {
  border-right: 1px none var(--gris_background);
}

.border-right-1-hidden-gris_background {
  border-right: 1px hidden var(--gris_background);
}

.border-left-1-solid-corail_mh_brand {
  border-left: 1px solid var(--corail_mh_brand);
}

.border-left-1-dashed-corail_mh_brand {
  border-left: 1px dashed var(--corail_mh_brand);
}

.border-left-1-dotted-corail_mh_brand {
  border-left: 1px dotted var(--corail_mh_brand);
}

.border-left-1-double-corail_mh_brand {
  border-left: 1px double var(--corail_mh_brand);
}

.border-left-1-groove-corail_mh_brand {
  border-left: 1px groove var(--corail_mh_brand);
}

.border-left-1-ridge-corail_mh_brand {
  border-left: 1px ridge var(--corail_mh_brand);
}

.border-left-1-inset-corail_mh_brand {
  border-left: 1px inset var(--corail_mh_brand);
}

.border-left-1-outset-corail_mh_brand {
  border-left: 1px outset var(--corail_mh_brand);
}

.border-left-1-none-corail_mh_brand {
  border-left: 1px none var(--corail_mh_brand);
}

.border-left-1-hidden-corail_mh_brand {
  border-left: 1px hidden var(--corail_mh_brand);
}

.border-left-1-solid-corail_mh_dark {
  border-left: 1px solid var(--corail_mh_dark);
}

.border-left-1-dashed-corail_mh_dark {
  border-left: 1px dashed var(--corail_mh_dark);
}

.border-left-1-dotted-corail_mh_dark {
  border-left: 1px dotted var(--corail_mh_dark);
}

.border-left-1-double-corail_mh_dark {
  border-left: 1px double var(--corail_mh_dark);
}

.border-left-1-groove-corail_mh_dark {
  border-left: 1px groove var(--corail_mh_dark);
}

.border-left-1-ridge-corail_mh_dark {
  border-left: 1px ridge var(--corail_mh_dark);
}

.border-left-1-inset-corail_mh_dark {
  border-left: 1px inset var(--corail_mh_dark);
}

.border-left-1-outset-corail_mh_dark {
  border-left: 1px outset var(--corail_mh_dark);
}

.border-left-1-none-corail_mh_dark {
  border-left: 1px none var(--corail_mh_dark);
}

.border-left-1-hidden-corail_mh_dark {
  border-left: 1px hidden var(--corail_mh_dark);
}

.border-left-1-solid-corail_mh_light {
  border-left: 1px solid var(--corail_mh_light);
}

.border-left-1-dashed-corail_mh_light {
  border-left: 1px dashed var(--corail_mh_light);
}

.border-left-1-dotted-corail_mh_light {
  border-left: 1px dotted var(--corail_mh_light);
}

.border-left-1-double-corail_mh_light {
  border-left: 1px double var(--corail_mh_light);
}

.border-left-1-groove-corail_mh_light {
  border-left: 1px groove var(--corail_mh_light);
}

.border-left-1-ridge-corail_mh_light {
  border-left: 1px ridge var(--corail_mh_light);
}

.border-left-1-inset-corail_mh_light {
  border-left: 1px inset var(--corail_mh_light);
}

.border-left-1-outset-corail_mh_light {
  border-left: 1px outset var(--corail_mh_light);
}

.border-left-1-none-corail_mh_light {
  border-left: 1px none var(--corail_mh_light);
}

.border-left-1-hidden-corail_mh_light {
  border-left: 1px hidden var(--corail_mh_light);
}

.border-left-1-solid-gris_sable_light {
  border-left: 1px solid var(--gris_sable_light);
}

.border-left-1-dashed-gris_sable_light {
  border-left: 1px dashed var(--gris_sable_light);
}

.border-left-1-dotted-gris_sable_light {
  border-left: 1px dotted var(--gris_sable_light);
}

.border-left-1-double-gris_sable_light {
  border-left: 1px double var(--gris_sable_light);
}

.border-left-1-groove-gris_sable_light {
  border-left: 1px groove var(--gris_sable_light);
}

.border-left-1-ridge-gris_sable_light {
  border-left: 1px ridge var(--gris_sable_light);
}

.border-left-1-inset-gris_sable_light {
  border-left: 1px inset var(--gris_sable_light);
}

.border-left-1-outset-gris_sable_light {
  border-left: 1px outset var(--gris_sable_light);
}

.border-left-1-none-gris_sable_light {
  border-left: 1px none var(--gris_sable_light);
}

.border-left-1-hidden-gris_sable_light {
  border-left: 1px hidden var(--gris_sable_light);
}

.border-left-1-solid-gris_sable_dark {
  border-left: 1px solid var(--gris_sable_dark);
}

.border-left-1-dashed-gris_sable_dark {
  border-left: 1px dashed var(--gris_sable_dark);
}

.border-left-1-dotted-gris_sable_dark {
  border-left: 1px dotted var(--gris_sable_dark);
}

.border-left-1-double-gris_sable_dark {
  border-left: 1px double var(--gris_sable_dark);
}

.border-left-1-groove-gris_sable_dark {
  border-left: 1px groove var(--gris_sable_dark);
}

.border-left-1-ridge-gris_sable_dark {
  border-left: 1px ridge var(--gris_sable_dark);
}

.border-left-1-inset-gris_sable_dark {
  border-left: 1px inset var(--gris_sable_dark);
}

.border-left-1-outset-gris_sable_dark {
  border-left: 1px outset var(--gris_sable_dark);
}

.border-left-1-none-gris_sable_dark {
  border-left: 1px none var(--gris_sable_dark);
}

.border-left-1-hidden-gris_sable_dark {
  border-left: 1px hidden var(--gris_sable_dark);
}

.border-left-1-solid-jaune_ivoire_light {
  border-left: 1px solid var(--jaune_ivoire_light);
}

.border-left-1-dashed-jaune_ivoire_light {
  border-left: 1px dashed var(--jaune_ivoire_light);
}

.border-left-1-dotted-jaune_ivoire_light {
  border-left: 1px dotted var(--jaune_ivoire_light);
}

.border-left-1-double-jaune_ivoire_light {
  border-left: 1px double var(--jaune_ivoire_light);
}

.border-left-1-groove-jaune_ivoire_light {
  border-left: 1px groove var(--jaune_ivoire_light);
}

.border-left-1-ridge-jaune_ivoire_light {
  border-left: 1px ridge var(--jaune_ivoire_light);
}

.border-left-1-inset-jaune_ivoire_light {
  border-left: 1px inset var(--jaune_ivoire_light);
}

.border-left-1-outset-jaune_ivoire_light {
  border-left: 1px outset var(--jaune_ivoire_light);
}

.border-left-1-none-jaune_ivoire_light {
  border-left: 1px none var(--jaune_ivoire_light);
}

.border-left-1-hidden-jaune_ivoire_light {
  border-left: 1px hidden var(--jaune_ivoire_light);
}

.border-left-1-solid-jaune_ivoire_dark {
  border-left: 1px solid var(--jaune_ivoire_dark);
}

.border-left-1-dashed-jaune_ivoire_dark {
  border-left: 1px dashed var(--jaune_ivoire_dark);
}

.border-left-1-dotted-jaune_ivoire_dark {
  border-left: 1px dotted var(--jaune_ivoire_dark);
}

.border-left-1-double-jaune_ivoire_dark {
  border-left: 1px double var(--jaune_ivoire_dark);
}

.border-left-1-groove-jaune_ivoire_dark {
  border-left: 1px groove var(--jaune_ivoire_dark);
}

.border-left-1-ridge-jaune_ivoire_dark {
  border-left: 1px ridge var(--jaune_ivoire_dark);
}

.border-left-1-inset-jaune_ivoire_dark {
  border-left: 1px inset var(--jaune_ivoire_dark);
}

.border-left-1-outset-jaune_ivoire_dark {
  border-left: 1px outset var(--jaune_ivoire_dark);
}

.border-left-1-none-jaune_ivoire_dark {
  border-left: 1px none var(--jaune_ivoire_dark);
}

.border-left-1-hidden-jaune_ivoire_dark {
  border-left: 1px hidden var(--jaune_ivoire_dark);
}

.border-left-1-solid-jaune_light {
  border-left: 1px solid var(--jaune_light);
}

.border-left-1-dashed-jaune_light {
  border-left: 1px dashed var(--jaune_light);
}

.border-left-1-dotted-jaune_light {
  border-left: 1px dotted var(--jaune_light);
}

.border-left-1-double-jaune_light {
  border-left: 1px double var(--jaune_light);
}

.border-left-1-groove-jaune_light {
  border-left: 1px groove var(--jaune_light);
}

.border-left-1-ridge-jaune_light {
  border-left: 1px ridge var(--jaune_light);
}

.border-left-1-inset-jaune_light {
  border-left: 1px inset var(--jaune_light);
}

.border-left-1-outset-jaune_light {
  border-left: 1px outset var(--jaune_light);
}

.border-left-1-none-jaune_light {
  border-left: 1px none var(--jaune_light);
}

.border-left-1-hidden-jaune_light {
  border-left: 1px hidden var(--jaune_light);
}

.border-left-1-solid-jaune_dark {
  border-left: 1px solid var(--jaune_dark);
}

.border-left-1-dashed-jaune_dark {
  border-left: 1px dashed var(--jaune_dark);
}

.border-left-1-dotted-jaune_dark {
  border-left: 1px dotted var(--jaune_dark);
}

.border-left-1-double-jaune_dark {
  border-left: 1px double var(--jaune_dark);
}

.border-left-1-groove-jaune_dark {
  border-left: 1px groove var(--jaune_dark);
}

.border-left-1-ridge-jaune_dark {
  border-left: 1px ridge var(--jaune_dark);
}

.border-left-1-inset-jaune_dark {
  border-left: 1px inset var(--jaune_dark);
}

.border-left-1-outset-jaune_dark {
  border-left: 1px outset var(--jaune_dark);
}

.border-left-1-none-jaune_dark {
  border-left: 1px none var(--jaune_dark);
}

.border-left-1-hidden-jaune_dark {
  border-left: 1px hidden var(--jaune_dark);
}

.border-left-1-solid-orange_normal {
  border-left: 1px solid var(--orange_normal);
}

.border-left-1-dashed-orange_normal {
  border-left: 1px dashed var(--orange_normal);
}

.border-left-1-dotted-orange_normal {
  border-left: 1px dotted var(--orange_normal);
}

.border-left-1-double-orange_normal {
  border-left: 1px double var(--orange_normal);
}

.border-left-1-groove-orange_normal {
  border-left: 1px groove var(--orange_normal);
}

.border-left-1-ridge-orange_normal {
  border-left: 1px ridge var(--orange_normal);
}

.border-left-1-inset-orange_normal {
  border-left: 1px inset var(--orange_normal);
}

.border-left-1-outset-orange_normal {
  border-left: 1px outset var(--orange_normal);
}

.border-left-1-none-orange_normal {
  border-left: 1px none var(--orange_normal);
}

.border-left-1-hidden-orange_normal {
  border-left: 1px hidden var(--orange_normal);
}

.border-left-1-solid-orange_dark {
  border-left: 1px solid var(--orange_dark);
}

.border-left-1-dashed-orange_dark {
  border-left: 1px dashed var(--orange_dark);
}

.border-left-1-dotted-orange_dark {
  border-left: 1px dotted var(--orange_dark);
}

.border-left-1-double-orange_dark {
  border-left: 1px double var(--orange_dark);
}

.border-left-1-groove-orange_dark {
  border-left: 1px groove var(--orange_dark);
}

.border-left-1-ridge-orange_dark {
  border-left: 1px ridge var(--orange_dark);
}

.border-left-1-inset-orange_dark {
  border-left: 1px inset var(--orange_dark);
}

.border-left-1-outset-orange_dark {
  border-left: 1px outset var(--orange_dark);
}

.border-left-1-none-orange_dark {
  border-left: 1px none var(--orange_dark);
}

.border-left-1-hidden-orange_dark {
  border-left: 1px hidden var(--orange_dark);
}

.border-left-1-solid-vert_light {
  border-left: 1px solid var(--vert_light);
}

.border-left-1-dashed-vert_light {
  border-left: 1px dashed var(--vert_light);
}

.border-left-1-dotted-vert_light {
  border-left: 1px dotted var(--vert_light);
}

.border-left-1-double-vert_light {
  border-left: 1px double var(--vert_light);
}

.border-left-1-groove-vert_light {
  border-left: 1px groove var(--vert_light);
}

.border-left-1-ridge-vert_light {
  border-left: 1px ridge var(--vert_light);
}

.border-left-1-inset-vert_light {
  border-left: 1px inset var(--vert_light);
}

.border-left-1-outset-vert_light {
  border-left: 1px outset var(--vert_light);
}

.border-left-1-none-vert_light {
  border-left: 1px none var(--vert_light);
}

.border-left-1-hidden-vert_light {
  border-left: 1px hidden var(--vert_light);
}

.border-left-1-solid-vert_dark {
  border-left: 1px solid var(--vert_dark);
}

.border-left-1-dashed-vert_dark {
  border-left: 1px dashed var(--vert_dark);
}

.border-left-1-dotted-vert_dark {
  border-left: 1px dotted var(--vert_dark);
}

.border-left-1-double-vert_dark {
  border-left: 1px double var(--vert_dark);
}

.border-left-1-groove-vert_dark {
  border-left: 1px groove var(--vert_dark);
}

.border-left-1-ridge-vert_dark {
  border-left: 1px ridge var(--vert_dark);
}

.border-left-1-inset-vert_dark {
  border-left: 1px inset var(--vert_dark);
}

.border-left-1-outset-vert_dark {
  border-left: 1px outset var(--vert_dark);
}

.border-left-1-none-vert_dark {
  border-left: 1px none var(--vert_dark);
}

.border-left-1-hidden-vert_dark {
  border-left: 1px hidden var(--vert_dark);
}

.border-left-1-solid-bleu_turquoise_light {
  border-left: 1px solid var(--bleu_turquoise_light);
}

.border-left-1-dashed-bleu_turquoise_light {
  border-left: 1px dashed var(--bleu_turquoise_light);
}

.border-left-1-dotted-bleu_turquoise_light {
  border-left: 1px dotted var(--bleu_turquoise_light);
}

.border-left-1-double-bleu_turquoise_light {
  border-left: 1px double var(--bleu_turquoise_light);
}

.border-left-1-groove-bleu_turquoise_light {
  border-left: 1px groove var(--bleu_turquoise_light);
}

.border-left-1-ridge-bleu_turquoise_light {
  border-left: 1px ridge var(--bleu_turquoise_light);
}

.border-left-1-inset-bleu_turquoise_light {
  border-left: 1px inset var(--bleu_turquoise_light);
}

.border-left-1-outset-bleu_turquoise_light {
  border-left: 1px outset var(--bleu_turquoise_light);
}

.border-left-1-none-bleu_turquoise_light {
  border-left: 1px none var(--bleu_turquoise_light);
}

.border-left-1-hidden-bleu_turquoise_light {
  border-left: 1px hidden var(--bleu_turquoise_light);
}

.border-left-1-solid-bleu_turquoise_middle {
  border-left: 1px solid var(--bleu_turquoise_middle);
}

.border-left-1-dashed-bleu_turquoise_middle {
  border-left: 1px dashed var(--bleu_turquoise_middle);
}

.border-left-1-dotted-bleu_turquoise_middle {
  border-left: 1px dotted var(--bleu_turquoise_middle);
}

.border-left-1-double-bleu_turquoise_middle {
  border-left: 1px double var(--bleu_turquoise_middle);
}

.border-left-1-groove-bleu_turquoise_middle {
  border-left: 1px groove var(--bleu_turquoise_middle);
}

.border-left-1-ridge-bleu_turquoise_middle {
  border-left: 1px ridge var(--bleu_turquoise_middle);
}

.border-left-1-inset-bleu_turquoise_middle {
  border-left: 1px inset var(--bleu_turquoise_middle);
}

.border-left-1-outset-bleu_turquoise_middle {
  border-left: 1px outset var(--bleu_turquoise_middle);
}

.border-left-1-none-bleu_turquoise_middle {
  border-left: 1px none var(--bleu_turquoise_middle);
}

.border-left-1-hidden-bleu_turquoise_middle {
  border-left: 1px hidden var(--bleu_turquoise_middle);
}

.border-left-1-solid-bleu_turquoise_dark {
  border-left: 1px solid var(--bleu_turquoise_dark);
}

.border-left-1-dashed-bleu_turquoise_dark {
  border-left: 1px dashed var(--bleu_turquoise_dark);
}

.border-left-1-dotted-bleu_turquoise_dark {
  border-left: 1px dotted var(--bleu_turquoise_dark);
}

.border-left-1-double-bleu_turquoise_dark {
  border-left: 1px double var(--bleu_turquoise_dark);
}

.border-left-1-groove-bleu_turquoise_dark {
  border-left: 1px groove var(--bleu_turquoise_dark);
}

.border-left-1-ridge-bleu_turquoise_dark {
  border-left: 1px ridge var(--bleu_turquoise_dark);
}

.border-left-1-inset-bleu_turquoise_dark {
  border-left: 1px inset var(--bleu_turquoise_dark);
}

.border-left-1-outset-bleu_turquoise_dark {
  border-left: 1px outset var(--bleu_turquoise_dark);
}

.border-left-1-none-bleu_turquoise_dark {
  border-left: 1px none var(--bleu_turquoise_dark);
}

.border-left-1-hidden-bleu_turquoise_dark {
  border-left: 1px hidden var(--bleu_turquoise_dark);
}

.border-left-1-solid-bleu_light {
  border-left: 1px solid var(--bleu_light);
}

.border-left-1-dashed-bleu_light {
  border-left: 1px dashed var(--bleu_light);
}

.border-left-1-dotted-bleu_light {
  border-left: 1px dotted var(--bleu_light);
}

.border-left-1-double-bleu_light {
  border-left: 1px double var(--bleu_light);
}

.border-left-1-groove-bleu_light {
  border-left: 1px groove var(--bleu_light);
}

.border-left-1-ridge-bleu_light {
  border-left: 1px ridge var(--bleu_light);
}

.border-left-1-inset-bleu_light {
  border-left: 1px inset var(--bleu_light);
}

.border-left-1-outset-bleu_light {
  border-left: 1px outset var(--bleu_light);
}

.border-left-1-none-bleu_light {
  border-left: 1px none var(--bleu_light);
}

.border-left-1-hidden-bleu_light {
  border-left: 1px hidden var(--bleu_light);
}

.border-left-1-solid-bleu_dark {
  border-left: 1px solid var(--bleu_dark);
}

.border-left-1-dashed-bleu_dark {
  border-left: 1px dashed var(--bleu_dark);
}

.border-left-1-dotted-bleu_dark {
  border-left: 1px dotted var(--bleu_dark);
}

.border-left-1-double-bleu_dark {
  border-left: 1px double var(--bleu_dark);
}

.border-left-1-groove-bleu_dark {
  border-left: 1px groove var(--bleu_dark);
}

.border-left-1-ridge-bleu_dark {
  border-left: 1px ridge var(--bleu_dark);
}

.border-left-1-inset-bleu_dark {
  border-left: 1px inset var(--bleu_dark);
}

.border-left-1-outset-bleu_dark {
  border-left: 1px outset var(--bleu_dark);
}

.border-left-1-none-bleu_dark {
  border-left: 1px none var(--bleu_dark);
}

.border-left-1-hidden-bleu_dark {
  border-left: 1px hidden var(--bleu_dark);
}

.border-left-1-solid-bleu_klein_light {
  border-left: 1px solid var(--bleu_klein_light);
}

.border-left-1-dashed-bleu_klein_light {
  border-left: 1px dashed var(--bleu_klein_light);
}

.border-left-1-dotted-bleu_klein_light {
  border-left: 1px dotted var(--bleu_klein_light);
}

.border-left-1-double-bleu_klein_light {
  border-left: 1px double var(--bleu_klein_light);
}

.border-left-1-groove-bleu_klein_light {
  border-left: 1px groove var(--bleu_klein_light);
}

.border-left-1-ridge-bleu_klein_light {
  border-left: 1px ridge var(--bleu_klein_light);
}

.border-left-1-inset-bleu_klein_light {
  border-left: 1px inset var(--bleu_klein_light);
}

.border-left-1-outset-bleu_klein_light {
  border-left: 1px outset var(--bleu_klein_light);
}

.border-left-1-none-bleu_klein_light {
  border-left: 1px none var(--bleu_klein_light);
}

.border-left-1-hidden-bleu_klein_light {
  border-left: 1px hidden var(--bleu_klein_light);
}

.border-left-1-solid-bleu_klein_dark {
  border-left: 1px solid var(--bleu_klein_dark);
}

.border-left-1-dashed-bleu_klein_dark {
  border-left: 1px dashed var(--bleu_klein_dark);
}

.border-left-1-dotted-bleu_klein_dark {
  border-left: 1px dotted var(--bleu_klein_dark);
}

.border-left-1-double-bleu_klein_dark {
  border-left: 1px double var(--bleu_klein_dark);
}

.border-left-1-groove-bleu_klein_dark {
  border-left: 1px groove var(--bleu_klein_dark);
}

.border-left-1-ridge-bleu_klein_dark {
  border-left: 1px ridge var(--bleu_klein_dark);
}

.border-left-1-inset-bleu_klein_dark {
  border-left: 1px inset var(--bleu_klein_dark);
}

.border-left-1-outset-bleu_klein_dark {
  border-left: 1px outset var(--bleu_klein_dark);
}

.border-left-1-none-bleu_klein_dark {
  border-left: 1px none var(--bleu_klein_dark);
}

.border-left-1-hidden-bleu_klein_dark {
  border-left: 1px hidden var(--bleu_klein_dark);
}

.border-left-1-solid-violet_light {
  border-left: 1px solid var(--violet_light);
}

.border-left-1-dashed-violet_light {
  border-left: 1px dashed var(--violet_light);
}

.border-left-1-dotted-violet_light {
  border-left: 1px dotted var(--violet_light);
}

.border-left-1-double-violet_light {
  border-left: 1px double var(--violet_light);
}

.border-left-1-groove-violet_light {
  border-left: 1px groove var(--violet_light);
}

.border-left-1-ridge-violet_light {
  border-left: 1px ridge var(--violet_light);
}

.border-left-1-inset-violet_light {
  border-left: 1px inset var(--violet_light);
}

.border-left-1-outset-violet_light {
  border-left: 1px outset var(--violet_light);
}

.border-left-1-none-violet_light {
  border-left: 1px none var(--violet_light);
}

.border-left-1-hidden-violet_light {
  border-left: 1px hidden var(--violet_light);
}

.border-left-1-solid-violet_dark {
  border-left: 1px solid var(--violet_dark);
}

.border-left-1-dashed-violet_dark {
  border-left: 1px dashed var(--violet_dark);
}

.border-left-1-dotted-violet_dark {
  border-left: 1px dotted var(--violet_dark);
}

.border-left-1-double-violet_dark {
  border-left: 1px double var(--violet_dark);
}

.border-left-1-groove-violet_dark {
  border-left: 1px groove var(--violet_dark);
}

.border-left-1-ridge-violet_dark {
  border-left: 1px ridge var(--violet_dark);
}

.border-left-1-inset-violet_dark {
  border-left: 1px inset var(--violet_dark);
}

.border-left-1-outset-violet_dark {
  border-left: 1px outset var(--violet_dark);
}

.border-left-1-none-violet_dark {
  border-left: 1px none var(--violet_dark);
}

.border-left-1-hidden-violet_dark {
  border-left: 1px hidden var(--violet_dark);
}

.border-left-1-solid-mauve {
  border-left: 1px solid var(--mauve);
}

.border-left-1-dashed-mauve {
  border-left: 1px dashed var(--mauve);
}

.border-left-1-dotted-mauve {
  border-left: 1px dotted var(--mauve);
}

.border-left-1-double-mauve {
  border-left: 1px double var(--mauve);
}

.border-left-1-groove-mauve {
  border-left: 1px groove var(--mauve);
}

.border-left-1-ridge-mauve {
  border-left: 1px ridge var(--mauve);
}

.border-left-1-inset-mauve {
  border-left: 1px inset var(--mauve);
}

.border-left-1-outset-mauve {
  border-left: 1px outset var(--mauve);
}

.border-left-1-none-mauve {
  border-left: 1px none var(--mauve);
}

.border-left-1-hidden-mauve {
  border-left: 1px hidden var(--mauve);
}

.border-left-1-solid-rose_light {
  border-left: 1px solid var(--rose_light);
}

.border-left-1-dashed-rose_light {
  border-left: 1px dashed var(--rose_light);
}

.border-left-1-dotted-rose_light {
  border-left: 1px dotted var(--rose_light);
}

.border-left-1-double-rose_light {
  border-left: 1px double var(--rose_light);
}

.border-left-1-groove-rose_light {
  border-left: 1px groove var(--rose_light);
}

.border-left-1-ridge-rose_light {
  border-left: 1px ridge var(--rose_light);
}

.border-left-1-inset-rose_light {
  border-left: 1px inset var(--rose_light);
}

.border-left-1-outset-rose_light {
  border-left: 1px outset var(--rose_light);
}

.border-left-1-none-rose_light {
  border-left: 1px none var(--rose_light);
}

.border-left-1-hidden-rose_light {
  border-left: 1px hidden var(--rose_light);
}

.border-left-1-solid-rose_dark {
  border-left: 1px solid var(--rose_dark);
}

.border-left-1-dashed-rose_dark {
  border-left: 1px dashed var(--rose_dark);
}

.border-left-1-dotted-rose_dark {
  border-left: 1px dotted var(--rose_dark);
}

.border-left-1-double-rose_dark {
  border-left: 1px double var(--rose_dark);
}

.border-left-1-groove-rose_dark {
  border-left: 1px groove var(--rose_dark);
}

.border-left-1-ridge-rose_dark {
  border-left: 1px ridge var(--rose_dark);
}

.border-left-1-inset-rose_dark {
  border-left: 1px inset var(--rose_dark);
}

.border-left-1-outset-rose_dark {
  border-left: 1px outset var(--rose_dark);
}

.border-left-1-none-rose_dark {
  border-left: 1px none var(--rose_dark);
}

.border-left-1-hidden-rose_dark {
  border-left: 1px hidden var(--rose_dark);
}

.border-left-1-solid-rose_fushia {
  border-left: 1px solid var(--rose_fushia);
}

.border-left-1-dashed-rose_fushia {
  border-left: 1px dashed var(--rose_fushia);
}

.border-left-1-dotted-rose_fushia {
  border-left: 1px dotted var(--rose_fushia);
}

.border-left-1-double-rose_fushia {
  border-left: 1px double var(--rose_fushia);
}

.border-left-1-groove-rose_fushia {
  border-left: 1px groove var(--rose_fushia);
}

.border-left-1-ridge-rose_fushia {
  border-left: 1px ridge var(--rose_fushia);
}

.border-left-1-inset-rose_fushia {
  border-left: 1px inset var(--rose_fushia);
}

.border-left-1-outset-rose_fushia {
  border-left: 1px outset var(--rose_fushia);
}

.border-left-1-none-rose_fushia {
  border-left: 1px none var(--rose_fushia);
}

.border-left-1-hidden-rose_fushia {
  border-left: 1px hidden var(--rose_fushia);
}

.border-left-1-solid-rouge_framboise {
  border-left: 1px solid var(--rouge_framboise);
}

.border-left-1-dashed-rouge_framboise {
  border-left: 1px dashed var(--rouge_framboise);
}

.border-left-1-dotted-rouge_framboise {
  border-left: 1px dotted var(--rouge_framboise);
}

.border-left-1-double-rouge_framboise {
  border-left: 1px double var(--rouge_framboise);
}

.border-left-1-groove-rouge_framboise {
  border-left: 1px groove var(--rouge_framboise);
}

.border-left-1-ridge-rouge_framboise {
  border-left: 1px ridge var(--rouge_framboise);
}

.border-left-1-inset-rouge_framboise {
  border-left: 1px inset var(--rouge_framboise);
}

.border-left-1-outset-rouge_framboise {
  border-left: 1px outset var(--rouge_framboise);
}

.border-left-1-none-rouge_framboise {
  border-left: 1px none var(--rouge_framboise);
}

.border-left-1-hidden-rouge_framboise {
  border-left: 1px hidden var(--rouge_framboise);
}

.border-left-1-solid-rose_chair_light {
  border-left: 1px solid var(--rose_chair_light);
}

.border-left-1-dashed-rose_chair_light {
  border-left: 1px dashed var(--rose_chair_light);
}

.border-left-1-dotted-rose_chair_light {
  border-left: 1px dotted var(--rose_chair_light);
}

.border-left-1-double-rose_chair_light {
  border-left: 1px double var(--rose_chair_light);
}

.border-left-1-groove-rose_chair_light {
  border-left: 1px groove var(--rose_chair_light);
}

.border-left-1-ridge-rose_chair_light {
  border-left: 1px ridge var(--rose_chair_light);
}

.border-left-1-inset-rose_chair_light {
  border-left: 1px inset var(--rose_chair_light);
}

.border-left-1-outset-rose_chair_light {
  border-left: 1px outset var(--rose_chair_light);
}

.border-left-1-none-rose_chair_light {
  border-left: 1px none var(--rose_chair_light);
}

.border-left-1-hidden-rose_chair_light {
  border-left: 1px hidden var(--rose_chair_light);
}

.border-left-1-solid-rose_chair_dark {
  border-left: 1px solid var(--rose_chair_dark);
}

.border-left-1-dashed-rose_chair_dark {
  border-left: 1px dashed var(--rose_chair_dark);
}

.border-left-1-dotted-rose_chair_dark {
  border-left: 1px dotted var(--rose_chair_dark);
}

.border-left-1-double-rose_chair_dark {
  border-left: 1px double var(--rose_chair_dark);
}

.border-left-1-groove-rose_chair_dark {
  border-left: 1px groove var(--rose_chair_dark);
}

.border-left-1-ridge-rose_chair_dark {
  border-left: 1px ridge var(--rose_chair_dark);
}

.border-left-1-inset-rose_chair_dark {
  border-left: 1px inset var(--rose_chair_dark);
}

.border-left-1-outset-rose_chair_dark {
  border-left: 1px outset var(--rose_chair_dark);
}

.border-left-1-none-rose_chair_dark {
  border-left: 1px none var(--rose_chair_dark);
}

.border-left-1-hidden-rose_chair_dark {
  border-left: 1px hidden var(--rose_chair_dark);
}

.border-left-1-solid-vert_positif {
  border-left: 1px solid var(--vert_positif);
}

.border-left-1-dashed-vert_positif {
  border-left: 1px dashed var(--vert_positif);
}

.border-left-1-dotted-vert_positif {
  border-left: 1px dotted var(--vert_positif);
}

.border-left-1-double-vert_positif {
  border-left: 1px double var(--vert_positif);
}

.border-left-1-groove-vert_positif {
  border-left: 1px groove var(--vert_positif);
}

.border-left-1-ridge-vert_positif {
  border-left: 1px ridge var(--vert_positif);
}

.border-left-1-inset-vert_positif {
  border-left: 1px inset var(--vert_positif);
}

.border-left-1-outset-vert_positif {
  border-left: 1px outset var(--vert_positif);
}

.border-left-1-none-vert_positif {
  border-left: 1px none var(--vert_positif);
}

.border-left-1-hidden-vert_positif {
  border-left: 1px hidden var(--vert_positif);
}

.border-left-1-solid-rouge_negatif {
  border-left: 1px solid var(--rouge_negatif);
}

.border-left-1-dashed-rouge_negatif {
  border-left: 1px dashed var(--rouge_negatif);
}

.border-left-1-dotted-rouge_negatif {
  border-left: 1px dotted var(--rouge_negatif);
}

.border-left-1-double-rouge_negatif {
  border-left: 1px double var(--rouge_negatif);
}

.border-left-1-groove-rouge_negatif {
  border-left: 1px groove var(--rouge_negatif);
}

.border-left-1-ridge-rouge_negatif {
  border-left: 1px ridge var(--rouge_negatif);
}

.border-left-1-inset-rouge_negatif {
  border-left: 1px inset var(--rouge_negatif);
}

.border-left-1-outset-rouge_negatif {
  border-left: 1px outset var(--rouge_negatif);
}

.border-left-1-none-rouge_negatif {
  border-left: 1px none var(--rouge_negatif);
}

.border-left-1-hidden-rouge_negatif {
  border-left: 1px hidden var(--rouge_negatif);
}

.border-left-1-solid-bleu_texte {
  border-left: 1px solid var(--bleu_texte);
}

.border-left-1-dashed-bleu_texte {
  border-left: 1px dashed var(--bleu_texte);
}

.border-left-1-dotted-bleu_texte {
  border-left: 1px dotted var(--bleu_texte);
}

.border-left-1-double-bleu_texte {
  border-left: 1px double var(--bleu_texte);
}

.border-left-1-groove-bleu_texte {
  border-left: 1px groove var(--bleu_texte);
}

.border-left-1-ridge-bleu_texte {
  border-left: 1px ridge var(--bleu_texte);
}

.border-left-1-inset-bleu_texte {
  border-left: 1px inset var(--bleu_texte);
}

.border-left-1-outset-bleu_texte {
  border-left: 1px outset var(--bleu_texte);
}

.border-left-1-none-bleu_texte {
  border-left: 1px none var(--bleu_texte);
}

.border-left-1-hidden-bleu_texte {
  border-left: 1px hidden var(--bleu_texte);
}

.border-left-1-solid-alerte_vert_positif {
  border-left: 1px solid var(--alerte_vert_positif);
}

.border-left-1-dashed-alerte_vert_positif {
  border-left: 1px dashed var(--alerte_vert_positif);
}

.border-left-1-dotted-alerte_vert_positif {
  border-left: 1px dotted var(--alerte_vert_positif);
}

.border-left-1-double-alerte_vert_positif {
  border-left: 1px double var(--alerte_vert_positif);
}

.border-left-1-groove-alerte_vert_positif {
  border-left: 1px groove var(--alerte_vert_positif);
}

.border-left-1-ridge-alerte_vert_positif {
  border-left: 1px ridge var(--alerte_vert_positif);
}

.border-left-1-inset-alerte_vert_positif {
  border-left: 1px inset var(--alerte_vert_positif);
}

.border-left-1-outset-alerte_vert_positif {
  border-left: 1px outset var(--alerte_vert_positif);
}

.border-left-1-none-alerte_vert_positif {
  border-left: 1px none var(--alerte_vert_positif);
}

.border-left-1-hidden-alerte_vert_positif {
  border-left: 1px hidden var(--alerte_vert_positif);
}

.border-left-1-solid-alerte_rouge_negatif {
  border-left: 1px solid var(--alerte_rouge_negatif);
}

.border-left-1-dashed-alerte_rouge_negatif {
  border-left: 1px dashed var(--alerte_rouge_negatif);
}

.border-left-1-dotted-alerte_rouge_negatif {
  border-left: 1px dotted var(--alerte_rouge_negatif);
}

.border-left-1-double-alerte_rouge_negatif {
  border-left: 1px double var(--alerte_rouge_negatif);
}

.border-left-1-groove-alerte_rouge_negatif {
  border-left: 1px groove var(--alerte_rouge_negatif);
}

.border-left-1-ridge-alerte_rouge_negatif {
  border-left: 1px ridge var(--alerte_rouge_negatif);
}

.border-left-1-inset-alerte_rouge_negatif {
  border-left: 1px inset var(--alerte_rouge_negatif);
}

.border-left-1-outset-alerte_rouge_negatif {
  border-left: 1px outset var(--alerte_rouge_negatif);
}

.border-left-1-none-alerte_rouge_negatif {
  border-left: 1px none var(--alerte_rouge_negatif);
}

.border-left-1-hidden-alerte_rouge_negatif {
  border-left: 1px hidden var(--alerte_rouge_negatif);
}

.border-left-1-solid-alerte_bleu_information {
  border-left: 1px solid var(--alerte_bleu_information);
}

.border-left-1-dashed-alerte_bleu_information {
  border-left: 1px dashed var(--alerte_bleu_information);
}

.border-left-1-dotted-alerte_bleu_information {
  border-left: 1px dotted var(--alerte_bleu_information);
}

.border-left-1-double-alerte_bleu_information {
  border-left: 1px double var(--alerte_bleu_information);
}

.border-left-1-groove-alerte_bleu_information {
  border-left: 1px groove var(--alerte_bleu_information);
}

.border-left-1-ridge-alerte_bleu_information {
  border-left: 1px ridge var(--alerte_bleu_information);
}

.border-left-1-inset-alerte_bleu_information {
  border-left: 1px inset var(--alerte_bleu_information);
}

.border-left-1-outset-alerte_bleu_information {
  border-left: 1px outset var(--alerte_bleu_information);
}

.border-left-1-none-alerte_bleu_information {
  border-left: 1px none var(--alerte_bleu_information);
}

.border-left-1-hidden-alerte_bleu_information {
  border-left: 1px hidden var(--alerte_bleu_information);
}

.border-left-1-solid-alerte_jaune_attention {
  border-left: 1px solid var(--alerte_jaune_attention);
}

.border-left-1-dashed-alerte_jaune_attention {
  border-left: 1px dashed var(--alerte_jaune_attention);
}

.border-left-1-dotted-alerte_jaune_attention {
  border-left: 1px dotted var(--alerte_jaune_attention);
}

.border-left-1-double-alerte_jaune_attention {
  border-left: 1px double var(--alerte_jaune_attention);
}

.border-left-1-groove-alerte_jaune_attention {
  border-left: 1px groove var(--alerte_jaune_attention);
}

.border-left-1-ridge-alerte_jaune_attention {
  border-left: 1px ridge var(--alerte_jaune_attention);
}

.border-left-1-inset-alerte_jaune_attention {
  border-left: 1px inset var(--alerte_jaune_attention);
}

.border-left-1-outset-alerte_jaune_attention {
  border-left: 1px outset var(--alerte_jaune_attention);
}

.border-left-1-none-alerte_jaune_attention {
  border-left: 1px none var(--alerte_jaune_attention);
}

.border-left-1-hidden-alerte_jaune_attention {
  border-left: 1px hidden var(--alerte_jaune_attention);
}

.border-left-1-solid-blanc {
  border-left: 1px solid #fff;
}

.border-left-1-dashed-blanc {
  border-left: 1px dashed #fff;
}

.border-left-1-dotted-blanc {
  border-left: 1px dotted #fff;
}

.border-left-1-double-blanc {
  border-left: 1px double #fff;
}

.border-left-1-groove-blanc {
  border-left: 1px groove #fff;
}

.border-left-1-ridge-blanc {
  border-left: 1px ridge #fff;
}

.border-left-1-inset-blanc {
  border-left: 1px inset #fff;
}

.border-left-1-outset-blanc {
  border-left: 1px outset #fff;
}

.border-left-1-none-blanc {
  border-left: 1px #fff;
}

.border-left-1-hidden-blanc {
  border-left: 1px hidden #fff;
}

.border-left-1-solid-noir {
  border-left: 1px solid #222;
}

.border-left-1-dashed-noir {
  border-left: 1px dashed #222;
}

.border-left-1-dotted-noir {
  border-left: 1px dotted #222;
}

.border-left-1-double-noir {
  border-left: 1px double #222;
}

.border-left-1-groove-noir {
  border-left: 1px groove #222;
}

.border-left-1-ridge-noir {
  border-left: 1px ridge #222;
}

.border-left-1-inset-noir {
  border-left: 1px inset #222;
}

.border-left-1-outset-noir {
  border-left: 1px outset #222;
}

.border-left-1-none-noir {
  border-left: 1px #222;
}

.border-left-1-hidden-noir {
  border-left: 1px hidden #222;
}

.border-left-1-solid-gris_light {
  border-left: 1px solid var(--gris_light);
}

.border-left-1-dashed-gris_light {
  border-left: 1px dashed var(--gris_light);
}

.border-left-1-dotted-gris_light {
  border-left: 1px dotted var(--gris_light);
}

.border-left-1-double-gris_light {
  border-left: 1px double var(--gris_light);
}

.border-left-1-groove-gris_light {
  border-left: 1px groove var(--gris_light);
}

.border-left-1-ridge-gris_light {
  border-left: 1px ridge var(--gris_light);
}

.border-left-1-inset-gris_light {
  border-left: 1px inset var(--gris_light);
}

.border-left-1-outset-gris_light {
  border-left: 1px outset var(--gris_light);
}

.border-left-1-none-gris_light {
  border-left: 1px none var(--gris_light);
}

.border-left-1-hidden-gris_light {
  border-left: 1px hidden var(--gris_light);
}

.border-left-1-solid-gris_dark {
  border-left: 1px solid var(--gris_dark);
}

.border-left-1-dashed-gris_dark {
  border-left: 1px dashed var(--gris_dark);
}

.border-left-1-dotted-gris_dark {
  border-left: 1px dotted var(--gris_dark);
}

.border-left-1-double-gris_dark {
  border-left: 1px double var(--gris_dark);
}

.border-left-1-groove-gris_dark {
  border-left: 1px groove var(--gris_dark);
}

.border-left-1-ridge-gris_dark {
  border-left: 1px ridge var(--gris_dark);
}

.border-left-1-inset-gris_dark {
  border-left: 1px inset var(--gris_dark);
}

.border-left-1-outset-gris_dark {
  border-left: 1px outset var(--gris_dark);
}

.border-left-1-none-gris_dark {
  border-left: 1px none var(--gris_dark);
}

.border-left-1-hidden-gris_dark {
  border-left: 1px hidden var(--gris_dark);
}

.border-left-1-solid-gris_background {
  border-left: 1px solid var(--gris_background);
}

.border-left-1-dashed-gris_background {
  border-left: 1px dashed var(--gris_background);
}

.border-left-1-dotted-gris_background {
  border-left: 1px dotted var(--gris_background);
}

.border-left-1-double-gris_background {
  border-left: 1px double var(--gris_background);
}

.border-left-1-groove-gris_background {
  border-left: 1px groove var(--gris_background);
}

.border-left-1-ridge-gris_background {
  border-left: 1px ridge var(--gris_background);
}

.border-left-1-inset-gris_background {
  border-left: 1px inset var(--gris_background);
}

.border-left-1-outset-gris_background {
  border-left: 1px outset var(--gris_background);
}

.border-left-1-none-gris_background {
  border-left: 1px none var(--gris_background);
}

.border-left-1-hidden-gris_background {
  border-left: 1px hidden var(--gris_background);
}

.border-all-1-solid-corail_mh_brand {
  border: 1px solid var(--corail_mh_brand);
}

.border-all-1-dashed-corail_mh_brand {
  border: 1px dashed var(--corail_mh_brand);
}

.border-all-1-dotted-corail_mh_brand {
  border: 1px dotted var(--corail_mh_brand);
}

.border-all-1-double-corail_mh_brand {
  border: 1px double var(--corail_mh_brand);
}

.border-all-1-groove-corail_mh_brand {
  border: 1px groove var(--corail_mh_brand);
}

.border-all-1-ridge-corail_mh_brand {
  border: 1px ridge var(--corail_mh_brand);
}

.border-all-1-inset-corail_mh_brand {
  border: 1px inset var(--corail_mh_brand);
}

.border-all-1-outset-corail_mh_brand {
  border: 1px outset var(--corail_mh_brand);
}

.border-all-1-none-corail_mh_brand {
  border: 1px none var(--corail_mh_brand);
}

.border-all-1-hidden-corail_mh_brand {
  border: 1px hidden var(--corail_mh_brand);
}

.border-all-1-solid-corail_mh_dark {
  border: 1px solid var(--corail_mh_dark);
}

.border-all-1-dashed-corail_mh_dark {
  border: 1px dashed var(--corail_mh_dark);
}

.border-all-1-dotted-corail_mh_dark {
  border: 1px dotted var(--corail_mh_dark);
}

.border-all-1-double-corail_mh_dark {
  border: 1px double var(--corail_mh_dark);
}

.border-all-1-groove-corail_mh_dark {
  border: 1px groove var(--corail_mh_dark);
}

.border-all-1-ridge-corail_mh_dark {
  border: 1px ridge var(--corail_mh_dark);
}

.border-all-1-inset-corail_mh_dark {
  border: 1px inset var(--corail_mh_dark);
}

.border-all-1-outset-corail_mh_dark {
  border: 1px outset var(--corail_mh_dark);
}

.border-all-1-none-corail_mh_dark {
  border: 1px none var(--corail_mh_dark);
}

.border-all-1-hidden-corail_mh_dark {
  border: 1px hidden var(--corail_mh_dark);
}

.border-all-1-solid-corail_mh_light {
  border: 1px solid var(--corail_mh_light);
}

.border-all-1-dashed-corail_mh_light {
  border: 1px dashed var(--corail_mh_light);
}

.border-all-1-dotted-corail_mh_light {
  border: 1px dotted var(--corail_mh_light);
}

.border-all-1-double-corail_mh_light {
  border: 1px double var(--corail_mh_light);
}

.border-all-1-groove-corail_mh_light {
  border: 1px groove var(--corail_mh_light);
}

.border-all-1-ridge-corail_mh_light {
  border: 1px ridge var(--corail_mh_light);
}

.border-all-1-inset-corail_mh_light {
  border: 1px inset var(--corail_mh_light);
}

.border-all-1-outset-corail_mh_light {
  border: 1px outset var(--corail_mh_light);
}

.border-all-1-none-corail_mh_light {
  border: 1px none var(--corail_mh_light);
}

.border-all-1-hidden-corail_mh_light {
  border: 1px hidden var(--corail_mh_light);
}

.border-all-1-solid-gris_sable_light {
  border: 1px solid var(--gris_sable_light);
}

.border-all-1-dashed-gris_sable_light {
  border: 1px dashed var(--gris_sable_light);
}

.border-all-1-dotted-gris_sable_light {
  border: 1px dotted var(--gris_sable_light);
}

.border-all-1-double-gris_sable_light {
  border: 1px double var(--gris_sable_light);
}

.border-all-1-groove-gris_sable_light {
  border: 1px groove var(--gris_sable_light);
}

.border-all-1-ridge-gris_sable_light {
  border: 1px ridge var(--gris_sable_light);
}

.border-all-1-inset-gris_sable_light {
  border: 1px inset var(--gris_sable_light);
}

.border-all-1-outset-gris_sable_light {
  border: 1px outset var(--gris_sable_light);
}

.border-all-1-none-gris_sable_light {
  border: 1px none var(--gris_sable_light);
}

.border-all-1-hidden-gris_sable_light {
  border: 1px hidden var(--gris_sable_light);
}

.border-all-1-solid-gris_sable_dark {
  border: 1px solid var(--gris_sable_dark);
}

.border-all-1-dashed-gris_sable_dark {
  border: 1px dashed var(--gris_sable_dark);
}

.border-all-1-dotted-gris_sable_dark {
  border: 1px dotted var(--gris_sable_dark);
}

.border-all-1-double-gris_sable_dark {
  border: 1px double var(--gris_sable_dark);
}

.border-all-1-groove-gris_sable_dark {
  border: 1px groove var(--gris_sable_dark);
}

.border-all-1-ridge-gris_sable_dark {
  border: 1px ridge var(--gris_sable_dark);
}

.border-all-1-inset-gris_sable_dark {
  border: 1px inset var(--gris_sable_dark);
}

.border-all-1-outset-gris_sable_dark {
  border: 1px outset var(--gris_sable_dark);
}

.border-all-1-none-gris_sable_dark {
  border: 1px none var(--gris_sable_dark);
}

.border-all-1-hidden-gris_sable_dark {
  border: 1px hidden var(--gris_sable_dark);
}

.border-all-1-solid-jaune_ivoire_light {
  border: 1px solid var(--jaune_ivoire_light);
}

.border-all-1-dashed-jaune_ivoire_light {
  border: 1px dashed var(--jaune_ivoire_light);
}

.border-all-1-dotted-jaune_ivoire_light {
  border: 1px dotted var(--jaune_ivoire_light);
}

.border-all-1-double-jaune_ivoire_light {
  border: 1px double var(--jaune_ivoire_light);
}

.border-all-1-groove-jaune_ivoire_light {
  border: 1px groove var(--jaune_ivoire_light);
}

.border-all-1-ridge-jaune_ivoire_light {
  border: 1px ridge var(--jaune_ivoire_light);
}

.border-all-1-inset-jaune_ivoire_light {
  border: 1px inset var(--jaune_ivoire_light);
}

.border-all-1-outset-jaune_ivoire_light {
  border: 1px outset var(--jaune_ivoire_light);
}

.border-all-1-none-jaune_ivoire_light {
  border: 1px none var(--jaune_ivoire_light);
}

.border-all-1-hidden-jaune_ivoire_light {
  border: 1px hidden var(--jaune_ivoire_light);
}

.border-all-1-solid-jaune_ivoire_dark {
  border: 1px solid var(--jaune_ivoire_dark);
}

.border-all-1-dashed-jaune_ivoire_dark {
  border: 1px dashed var(--jaune_ivoire_dark);
}

.border-all-1-dotted-jaune_ivoire_dark {
  border: 1px dotted var(--jaune_ivoire_dark);
}

.border-all-1-double-jaune_ivoire_dark {
  border: 1px double var(--jaune_ivoire_dark);
}

.border-all-1-groove-jaune_ivoire_dark {
  border: 1px groove var(--jaune_ivoire_dark);
}

.border-all-1-ridge-jaune_ivoire_dark {
  border: 1px ridge var(--jaune_ivoire_dark);
}

.border-all-1-inset-jaune_ivoire_dark {
  border: 1px inset var(--jaune_ivoire_dark);
}

.border-all-1-outset-jaune_ivoire_dark {
  border: 1px outset var(--jaune_ivoire_dark);
}

.border-all-1-none-jaune_ivoire_dark {
  border: 1px none var(--jaune_ivoire_dark);
}

.border-all-1-hidden-jaune_ivoire_dark {
  border: 1px hidden var(--jaune_ivoire_dark);
}

.border-all-1-solid-jaune_light {
  border: 1px solid var(--jaune_light);
}

.border-all-1-dashed-jaune_light {
  border: 1px dashed var(--jaune_light);
}

.border-all-1-dotted-jaune_light {
  border: 1px dotted var(--jaune_light);
}

.border-all-1-double-jaune_light {
  border: 1px double var(--jaune_light);
}

.border-all-1-groove-jaune_light {
  border: 1px groove var(--jaune_light);
}

.border-all-1-ridge-jaune_light {
  border: 1px ridge var(--jaune_light);
}

.border-all-1-inset-jaune_light {
  border: 1px inset var(--jaune_light);
}

.border-all-1-outset-jaune_light {
  border: 1px outset var(--jaune_light);
}

.border-all-1-none-jaune_light {
  border: 1px none var(--jaune_light);
}

.border-all-1-hidden-jaune_light {
  border: 1px hidden var(--jaune_light);
}

.border-all-1-solid-jaune_dark {
  border: 1px solid var(--jaune_dark);
}

.border-all-1-dashed-jaune_dark {
  border: 1px dashed var(--jaune_dark);
}

.border-all-1-dotted-jaune_dark {
  border: 1px dotted var(--jaune_dark);
}

.border-all-1-double-jaune_dark {
  border: 1px double var(--jaune_dark);
}

.border-all-1-groove-jaune_dark {
  border: 1px groove var(--jaune_dark);
}

.border-all-1-ridge-jaune_dark {
  border: 1px ridge var(--jaune_dark);
}

.border-all-1-inset-jaune_dark {
  border: 1px inset var(--jaune_dark);
}

.border-all-1-outset-jaune_dark {
  border: 1px outset var(--jaune_dark);
}

.border-all-1-none-jaune_dark {
  border: 1px none var(--jaune_dark);
}

.border-all-1-hidden-jaune_dark {
  border: 1px hidden var(--jaune_dark);
}

.border-all-1-solid-orange_normal {
  border: 1px solid var(--orange_normal);
}

.border-all-1-dashed-orange_normal {
  border: 1px dashed var(--orange_normal);
}

.border-all-1-dotted-orange_normal {
  border: 1px dotted var(--orange_normal);
}

.border-all-1-double-orange_normal {
  border: 1px double var(--orange_normal);
}

.border-all-1-groove-orange_normal {
  border: 1px groove var(--orange_normal);
}

.border-all-1-ridge-orange_normal {
  border: 1px ridge var(--orange_normal);
}

.border-all-1-inset-orange_normal {
  border: 1px inset var(--orange_normal);
}

.border-all-1-outset-orange_normal {
  border: 1px outset var(--orange_normal);
}

.border-all-1-none-orange_normal {
  border: 1px none var(--orange_normal);
}

.border-all-1-hidden-orange_normal {
  border: 1px hidden var(--orange_normal);
}

.border-all-1-solid-orange_dark {
  border: 1px solid var(--orange_dark);
}

.border-all-1-dashed-orange_dark {
  border: 1px dashed var(--orange_dark);
}

.border-all-1-dotted-orange_dark {
  border: 1px dotted var(--orange_dark);
}

.border-all-1-double-orange_dark {
  border: 1px double var(--orange_dark);
}

.border-all-1-groove-orange_dark {
  border: 1px groove var(--orange_dark);
}

.border-all-1-ridge-orange_dark {
  border: 1px ridge var(--orange_dark);
}

.border-all-1-inset-orange_dark {
  border: 1px inset var(--orange_dark);
}

.border-all-1-outset-orange_dark {
  border: 1px outset var(--orange_dark);
}

.border-all-1-none-orange_dark {
  border: 1px none var(--orange_dark);
}

.border-all-1-hidden-orange_dark {
  border: 1px hidden var(--orange_dark);
}

.border-all-1-solid-vert_light {
  border: 1px solid var(--vert_light);
}

.border-all-1-dashed-vert_light {
  border: 1px dashed var(--vert_light);
}

.border-all-1-dotted-vert_light {
  border: 1px dotted var(--vert_light);
}

.border-all-1-double-vert_light {
  border: 1px double var(--vert_light);
}

.border-all-1-groove-vert_light {
  border: 1px groove var(--vert_light);
}

.border-all-1-ridge-vert_light {
  border: 1px ridge var(--vert_light);
}

.border-all-1-inset-vert_light {
  border: 1px inset var(--vert_light);
}

.border-all-1-outset-vert_light {
  border: 1px outset var(--vert_light);
}

.border-all-1-none-vert_light {
  border: 1px none var(--vert_light);
}

.border-all-1-hidden-vert_light {
  border: 1px hidden var(--vert_light);
}

.border-all-1-solid-vert_dark {
  border: 1px solid var(--vert_dark);
}

.border-all-1-dashed-vert_dark {
  border: 1px dashed var(--vert_dark);
}

.border-all-1-dotted-vert_dark {
  border: 1px dotted var(--vert_dark);
}

.border-all-1-double-vert_dark {
  border: 1px double var(--vert_dark);
}

.border-all-1-groove-vert_dark {
  border: 1px groove var(--vert_dark);
}

.border-all-1-ridge-vert_dark {
  border: 1px ridge var(--vert_dark);
}

.border-all-1-inset-vert_dark {
  border: 1px inset var(--vert_dark);
}

.border-all-1-outset-vert_dark {
  border: 1px outset var(--vert_dark);
}

.border-all-1-none-vert_dark {
  border: 1px none var(--vert_dark);
}

.border-all-1-hidden-vert_dark {
  border: 1px hidden var(--vert_dark);
}

.border-all-1-solid-bleu_turquoise_light {
  border: 1px solid var(--bleu_turquoise_light);
}

.border-all-1-dashed-bleu_turquoise_light {
  border: 1px dashed var(--bleu_turquoise_light);
}

.border-all-1-dotted-bleu_turquoise_light {
  border: 1px dotted var(--bleu_turquoise_light);
}

.border-all-1-double-bleu_turquoise_light {
  border: 1px double var(--bleu_turquoise_light);
}

.border-all-1-groove-bleu_turquoise_light {
  border: 1px groove var(--bleu_turquoise_light);
}

.border-all-1-ridge-bleu_turquoise_light {
  border: 1px ridge var(--bleu_turquoise_light);
}

.border-all-1-inset-bleu_turquoise_light {
  border: 1px inset var(--bleu_turquoise_light);
}

.border-all-1-outset-bleu_turquoise_light {
  border: 1px outset var(--bleu_turquoise_light);
}

.border-all-1-none-bleu_turquoise_light {
  border: 1px none var(--bleu_turquoise_light);
}

.border-all-1-hidden-bleu_turquoise_light {
  border: 1px hidden var(--bleu_turquoise_light);
}

.border-all-1-solid-bleu_turquoise_middle {
  border: 1px solid var(--bleu_turquoise_middle);
}

.border-all-1-dashed-bleu_turquoise_middle {
  border: 1px dashed var(--bleu_turquoise_middle);
}

.border-all-1-dotted-bleu_turquoise_middle {
  border: 1px dotted var(--bleu_turquoise_middle);
}

.border-all-1-double-bleu_turquoise_middle {
  border: 1px double var(--bleu_turquoise_middle);
}

.border-all-1-groove-bleu_turquoise_middle {
  border: 1px groove var(--bleu_turquoise_middle);
}

.border-all-1-ridge-bleu_turquoise_middle {
  border: 1px ridge var(--bleu_turquoise_middle);
}

.border-all-1-inset-bleu_turquoise_middle {
  border: 1px inset var(--bleu_turquoise_middle);
}

.border-all-1-outset-bleu_turquoise_middle {
  border: 1px outset var(--bleu_turquoise_middle);
}

.border-all-1-none-bleu_turquoise_middle {
  border: 1px none var(--bleu_turquoise_middle);
}

.border-all-1-hidden-bleu_turquoise_middle {
  border: 1px hidden var(--bleu_turquoise_middle);
}

.border-all-1-solid-bleu_turquoise_dark {
  border: 1px solid var(--bleu_turquoise_dark);
}

.border-all-1-dashed-bleu_turquoise_dark {
  border: 1px dashed var(--bleu_turquoise_dark);
}

.border-all-1-dotted-bleu_turquoise_dark {
  border: 1px dotted var(--bleu_turquoise_dark);
}

.border-all-1-double-bleu_turquoise_dark {
  border: 1px double var(--bleu_turquoise_dark);
}

.border-all-1-groove-bleu_turquoise_dark {
  border: 1px groove var(--bleu_turquoise_dark);
}

.border-all-1-ridge-bleu_turquoise_dark {
  border: 1px ridge var(--bleu_turquoise_dark);
}

.border-all-1-inset-bleu_turquoise_dark {
  border: 1px inset var(--bleu_turquoise_dark);
}

.border-all-1-outset-bleu_turquoise_dark {
  border: 1px outset var(--bleu_turquoise_dark);
}

.border-all-1-none-bleu_turquoise_dark {
  border: 1px none var(--bleu_turquoise_dark);
}

.border-all-1-hidden-bleu_turquoise_dark {
  border: 1px hidden var(--bleu_turquoise_dark);
}

.border-all-1-solid-bleu_light {
  border: 1px solid var(--bleu_light);
}

.border-all-1-dashed-bleu_light {
  border: 1px dashed var(--bleu_light);
}

.border-all-1-dotted-bleu_light {
  border: 1px dotted var(--bleu_light);
}

.border-all-1-double-bleu_light {
  border: 1px double var(--bleu_light);
}

.border-all-1-groove-bleu_light {
  border: 1px groove var(--bleu_light);
}

.border-all-1-ridge-bleu_light {
  border: 1px ridge var(--bleu_light);
}

.border-all-1-inset-bleu_light {
  border: 1px inset var(--bleu_light);
}

.border-all-1-outset-bleu_light {
  border: 1px outset var(--bleu_light);
}

.border-all-1-none-bleu_light {
  border: 1px none var(--bleu_light);
}

.border-all-1-hidden-bleu_light {
  border: 1px hidden var(--bleu_light);
}

.border-all-1-solid-bleu_dark {
  border: 1px solid var(--bleu_dark);
}

.border-all-1-dashed-bleu_dark {
  border: 1px dashed var(--bleu_dark);
}

.border-all-1-dotted-bleu_dark {
  border: 1px dotted var(--bleu_dark);
}

.border-all-1-double-bleu_dark {
  border: 1px double var(--bleu_dark);
}

.border-all-1-groove-bleu_dark {
  border: 1px groove var(--bleu_dark);
}

.border-all-1-ridge-bleu_dark {
  border: 1px ridge var(--bleu_dark);
}

.border-all-1-inset-bleu_dark {
  border: 1px inset var(--bleu_dark);
}

.border-all-1-outset-bleu_dark {
  border: 1px outset var(--bleu_dark);
}

.border-all-1-none-bleu_dark {
  border: 1px none var(--bleu_dark);
}

.border-all-1-hidden-bleu_dark {
  border: 1px hidden var(--bleu_dark);
}

.border-all-1-solid-bleu_klein_light {
  border: 1px solid var(--bleu_klein_light);
}

.border-all-1-dashed-bleu_klein_light {
  border: 1px dashed var(--bleu_klein_light);
}

.border-all-1-dotted-bleu_klein_light {
  border: 1px dotted var(--bleu_klein_light);
}

.border-all-1-double-bleu_klein_light {
  border: 1px double var(--bleu_klein_light);
}

.border-all-1-groove-bleu_klein_light {
  border: 1px groove var(--bleu_klein_light);
}

.border-all-1-ridge-bleu_klein_light {
  border: 1px ridge var(--bleu_klein_light);
}

.border-all-1-inset-bleu_klein_light {
  border: 1px inset var(--bleu_klein_light);
}

.border-all-1-outset-bleu_klein_light {
  border: 1px outset var(--bleu_klein_light);
}

.border-all-1-none-bleu_klein_light {
  border: 1px none var(--bleu_klein_light);
}

.border-all-1-hidden-bleu_klein_light {
  border: 1px hidden var(--bleu_klein_light);
}

.border-all-1-solid-bleu_klein_dark {
  border: 1px solid var(--bleu_klein_dark);
}

.border-all-1-dashed-bleu_klein_dark {
  border: 1px dashed var(--bleu_klein_dark);
}

.border-all-1-dotted-bleu_klein_dark {
  border: 1px dotted var(--bleu_klein_dark);
}

.border-all-1-double-bleu_klein_dark {
  border: 1px double var(--bleu_klein_dark);
}

.border-all-1-groove-bleu_klein_dark {
  border: 1px groove var(--bleu_klein_dark);
}

.border-all-1-ridge-bleu_klein_dark {
  border: 1px ridge var(--bleu_klein_dark);
}

.border-all-1-inset-bleu_klein_dark {
  border: 1px inset var(--bleu_klein_dark);
}

.border-all-1-outset-bleu_klein_dark {
  border: 1px outset var(--bleu_klein_dark);
}

.border-all-1-none-bleu_klein_dark {
  border: 1px none var(--bleu_klein_dark);
}

.border-all-1-hidden-bleu_klein_dark {
  border: 1px hidden var(--bleu_klein_dark);
}

.border-all-1-solid-violet_light {
  border: 1px solid var(--violet_light);
}

.border-all-1-dashed-violet_light {
  border: 1px dashed var(--violet_light);
}

.border-all-1-dotted-violet_light {
  border: 1px dotted var(--violet_light);
}

.border-all-1-double-violet_light {
  border: 1px double var(--violet_light);
}

.border-all-1-groove-violet_light {
  border: 1px groove var(--violet_light);
}

.border-all-1-ridge-violet_light {
  border: 1px ridge var(--violet_light);
}

.border-all-1-inset-violet_light {
  border: 1px inset var(--violet_light);
}

.border-all-1-outset-violet_light {
  border: 1px outset var(--violet_light);
}

.border-all-1-none-violet_light {
  border: 1px none var(--violet_light);
}

.border-all-1-hidden-violet_light {
  border: 1px hidden var(--violet_light);
}

.border-all-1-solid-violet_dark {
  border: 1px solid var(--violet_dark);
}

.border-all-1-dashed-violet_dark {
  border: 1px dashed var(--violet_dark);
}

.border-all-1-dotted-violet_dark {
  border: 1px dotted var(--violet_dark);
}

.border-all-1-double-violet_dark {
  border: 1px double var(--violet_dark);
}

.border-all-1-groove-violet_dark {
  border: 1px groove var(--violet_dark);
}

.border-all-1-ridge-violet_dark {
  border: 1px ridge var(--violet_dark);
}

.border-all-1-inset-violet_dark {
  border: 1px inset var(--violet_dark);
}

.border-all-1-outset-violet_dark {
  border: 1px outset var(--violet_dark);
}

.border-all-1-none-violet_dark {
  border: 1px none var(--violet_dark);
}

.border-all-1-hidden-violet_dark {
  border: 1px hidden var(--violet_dark);
}

.border-all-1-solid-mauve {
  border: 1px solid var(--mauve);
}

.border-all-1-dashed-mauve {
  border: 1px dashed var(--mauve);
}

.border-all-1-dotted-mauve {
  border: 1px dotted var(--mauve);
}

.border-all-1-double-mauve {
  border: 1px double var(--mauve);
}

.border-all-1-groove-mauve {
  border: 1px groove var(--mauve);
}

.border-all-1-ridge-mauve {
  border: 1px ridge var(--mauve);
}

.border-all-1-inset-mauve {
  border: 1px inset var(--mauve);
}

.border-all-1-outset-mauve {
  border: 1px outset var(--mauve);
}

.border-all-1-none-mauve {
  border: 1px none var(--mauve);
}

.border-all-1-hidden-mauve {
  border: 1px hidden var(--mauve);
}

.border-all-1-solid-rose_light {
  border: 1px solid var(--rose_light);
}

.border-all-1-dashed-rose_light {
  border: 1px dashed var(--rose_light);
}

.border-all-1-dotted-rose_light {
  border: 1px dotted var(--rose_light);
}

.border-all-1-double-rose_light {
  border: 1px double var(--rose_light);
}

.border-all-1-groove-rose_light {
  border: 1px groove var(--rose_light);
}

.border-all-1-ridge-rose_light {
  border: 1px ridge var(--rose_light);
}

.border-all-1-inset-rose_light {
  border: 1px inset var(--rose_light);
}

.border-all-1-outset-rose_light {
  border: 1px outset var(--rose_light);
}

.border-all-1-none-rose_light {
  border: 1px none var(--rose_light);
}

.border-all-1-hidden-rose_light {
  border: 1px hidden var(--rose_light);
}

.border-all-1-solid-rose_dark {
  border: 1px solid var(--rose_dark);
}

.border-all-1-dashed-rose_dark {
  border: 1px dashed var(--rose_dark);
}

.border-all-1-dotted-rose_dark {
  border: 1px dotted var(--rose_dark);
}

.border-all-1-double-rose_dark {
  border: 1px double var(--rose_dark);
}

.border-all-1-groove-rose_dark {
  border: 1px groove var(--rose_dark);
}

.border-all-1-ridge-rose_dark {
  border: 1px ridge var(--rose_dark);
}

.border-all-1-inset-rose_dark {
  border: 1px inset var(--rose_dark);
}

.border-all-1-outset-rose_dark {
  border: 1px outset var(--rose_dark);
}

.border-all-1-none-rose_dark {
  border: 1px none var(--rose_dark);
}

.border-all-1-hidden-rose_dark {
  border: 1px hidden var(--rose_dark);
}

.border-all-1-solid-rose_fushia {
  border: 1px solid var(--rose_fushia);
}

.border-all-1-dashed-rose_fushia {
  border: 1px dashed var(--rose_fushia);
}

.border-all-1-dotted-rose_fushia {
  border: 1px dotted var(--rose_fushia);
}

.border-all-1-double-rose_fushia {
  border: 1px double var(--rose_fushia);
}

.border-all-1-groove-rose_fushia {
  border: 1px groove var(--rose_fushia);
}

.border-all-1-ridge-rose_fushia {
  border: 1px ridge var(--rose_fushia);
}

.border-all-1-inset-rose_fushia {
  border: 1px inset var(--rose_fushia);
}

.border-all-1-outset-rose_fushia {
  border: 1px outset var(--rose_fushia);
}

.border-all-1-none-rose_fushia {
  border: 1px none var(--rose_fushia);
}

.border-all-1-hidden-rose_fushia {
  border: 1px hidden var(--rose_fushia);
}

.border-all-1-solid-rouge_framboise {
  border: 1px solid var(--rouge_framboise);
}

.border-all-1-dashed-rouge_framboise {
  border: 1px dashed var(--rouge_framboise);
}

.border-all-1-dotted-rouge_framboise {
  border: 1px dotted var(--rouge_framboise);
}

.border-all-1-double-rouge_framboise {
  border: 1px double var(--rouge_framboise);
}

.border-all-1-groove-rouge_framboise {
  border: 1px groove var(--rouge_framboise);
}

.border-all-1-ridge-rouge_framboise {
  border: 1px ridge var(--rouge_framboise);
}

.border-all-1-inset-rouge_framboise {
  border: 1px inset var(--rouge_framboise);
}

.border-all-1-outset-rouge_framboise {
  border: 1px outset var(--rouge_framboise);
}

.border-all-1-none-rouge_framboise {
  border: 1px none var(--rouge_framboise);
}

.border-all-1-hidden-rouge_framboise {
  border: 1px hidden var(--rouge_framboise);
}

.border-all-1-solid-rose_chair_light {
  border: 1px solid var(--rose_chair_light);
}

.border-all-1-dashed-rose_chair_light {
  border: 1px dashed var(--rose_chair_light);
}

.border-all-1-dotted-rose_chair_light {
  border: 1px dotted var(--rose_chair_light);
}

.border-all-1-double-rose_chair_light {
  border: 1px double var(--rose_chair_light);
}

.border-all-1-groove-rose_chair_light {
  border: 1px groove var(--rose_chair_light);
}

.border-all-1-ridge-rose_chair_light {
  border: 1px ridge var(--rose_chair_light);
}

.border-all-1-inset-rose_chair_light {
  border: 1px inset var(--rose_chair_light);
}

.border-all-1-outset-rose_chair_light {
  border: 1px outset var(--rose_chair_light);
}

.border-all-1-none-rose_chair_light {
  border: 1px none var(--rose_chair_light);
}

.border-all-1-hidden-rose_chair_light {
  border: 1px hidden var(--rose_chair_light);
}

.border-all-1-solid-rose_chair_dark {
  border: 1px solid var(--rose_chair_dark);
}

.border-all-1-dashed-rose_chair_dark {
  border: 1px dashed var(--rose_chair_dark);
}

.border-all-1-dotted-rose_chair_dark {
  border: 1px dotted var(--rose_chair_dark);
}

.border-all-1-double-rose_chair_dark {
  border: 1px double var(--rose_chair_dark);
}

.border-all-1-groove-rose_chair_dark {
  border: 1px groove var(--rose_chair_dark);
}

.border-all-1-ridge-rose_chair_dark {
  border: 1px ridge var(--rose_chair_dark);
}

.border-all-1-inset-rose_chair_dark {
  border: 1px inset var(--rose_chair_dark);
}

.border-all-1-outset-rose_chair_dark {
  border: 1px outset var(--rose_chair_dark);
}

.border-all-1-none-rose_chair_dark {
  border: 1px none var(--rose_chair_dark);
}

.border-all-1-hidden-rose_chair_dark {
  border: 1px hidden var(--rose_chair_dark);
}

.border-all-1-solid-vert_positif {
  border: 1px solid var(--vert_positif);
}

.border-all-1-dashed-vert_positif {
  border: 1px dashed var(--vert_positif);
}

.border-all-1-dotted-vert_positif {
  border: 1px dotted var(--vert_positif);
}

.border-all-1-double-vert_positif {
  border: 1px double var(--vert_positif);
}

.border-all-1-groove-vert_positif {
  border: 1px groove var(--vert_positif);
}

.border-all-1-ridge-vert_positif {
  border: 1px ridge var(--vert_positif);
}

.border-all-1-inset-vert_positif {
  border: 1px inset var(--vert_positif);
}

.border-all-1-outset-vert_positif {
  border: 1px outset var(--vert_positif);
}

.border-all-1-none-vert_positif {
  border: 1px none var(--vert_positif);
}

.border-all-1-hidden-vert_positif {
  border: 1px hidden var(--vert_positif);
}

.border-all-1-solid-rouge_negatif {
  border: 1px solid var(--rouge_negatif);
}

.border-all-1-dashed-rouge_negatif {
  border: 1px dashed var(--rouge_negatif);
}

.border-all-1-dotted-rouge_negatif {
  border: 1px dotted var(--rouge_negatif);
}

.border-all-1-double-rouge_negatif {
  border: 1px double var(--rouge_negatif);
}

.border-all-1-groove-rouge_negatif {
  border: 1px groove var(--rouge_negatif);
}

.border-all-1-ridge-rouge_negatif {
  border: 1px ridge var(--rouge_negatif);
}

.border-all-1-inset-rouge_negatif {
  border: 1px inset var(--rouge_negatif);
}

.border-all-1-outset-rouge_negatif {
  border: 1px outset var(--rouge_negatif);
}

.border-all-1-none-rouge_negatif {
  border: 1px none var(--rouge_negatif);
}

.border-all-1-hidden-rouge_negatif {
  border: 1px hidden var(--rouge_negatif);
}

.border-all-1-solid-bleu_texte {
  border: 1px solid var(--bleu_texte);
}

.border-all-1-dashed-bleu_texte {
  border: 1px dashed var(--bleu_texte);
}

.border-all-1-dotted-bleu_texte {
  border: 1px dotted var(--bleu_texte);
}

.border-all-1-double-bleu_texte {
  border: 1px double var(--bleu_texte);
}

.border-all-1-groove-bleu_texte {
  border: 1px groove var(--bleu_texte);
}

.border-all-1-ridge-bleu_texte {
  border: 1px ridge var(--bleu_texte);
}

.border-all-1-inset-bleu_texte {
  border: 1px inset var(--bleu_texte);
}

.border-all-1-outset-bleu_texte {
  border: 1px outset var(--bleu_texte);
}

.border-all-1-none-bleu_texte {
  border: 1px none var(--bleu_texte);
}

.border-all-1-hidden-bleu_texte {
  border: 1px hidden var(--bleu_texte);
}

.border-all-1-solid-alerte_vert_positif {
  border: 1px solid var(--alerte_vert_positif);
}

.border-all-1-dashed-alerte_vert_positif {
  border: 1px dashed var(--alerte_vert_positif);
}

.border-all-1-dotted-alerte_vert_positif {
  border: 1px dotted var(--alerte_vert_positif);
}

.border-all-1-double-alerte_vert_positif {
  border: 1px double var(--alerte_vert_positif);
}

.border-all-1-groove-alerte_vert_positif {
  border: 1px groove var(--alerte_vert_positif);
}

.border-all-1-ridge-alerte_vert_positif {
  border: 1px ridge var(--alerte_vert_positif);
}

.border-all-1-inset-alerte_vert_positif {
  border: 1px inset var(--alerte_vert_positif);
}

.border-all-1-outset-alerte_vert_positif {
  border: 1px outset var(--alerte_vert_positif);
}

.border-all-1-none-alerte_vert_positif {
  border: 1px none var(--alerte_vert_positif);
}

.border-all-1-hidden-alerte_vert_positif {
  border: 1px hidden var(--alerte_vert_positif);
}

.border-all-1-solid-alerte_rouge_negatif {
  border: 1px solid var(--alerte_rouge_negatif);
}

.border-all-1-dashed-alerte_rouge_negatif {
  border: 1px dashed var(--alerte_rouge_negatif);
}

.border-all-1-dotted-alerte_rouge_negatif {
  border: 1px dotted var(--alerte_rouge_negatif);
}

.border-all-1-double-alerte_rouge_negatif {
  border: 1px double var(--alerte_rouge_negatif);
}

.border-all-1-groove-alerte_rouge_negatif {
  border: 1px groove var(--alerte_rouge_negatif);
}

.border-all-1-ridge-alerte_rouge_negatif {
  border: 1px ridge var(--alerte_rouge_negatif);
}

.border-all-1-inset-alerte_rouge_negatif {
  border: 1px inset var(--alerte_rouge_negatif);
}

.border-all-1-outset-alerte_rouge_negatif {
  border: 1px outset var(--alerte_rouge_negatif);
}

.border-all-1-none-alerte_rouge_negatif {
  border: 1px none var(--alerte_rouge_negatif);
}

.border-all-1-hidden-alerte_rouge_negatif {
  border: 1px hidden var(--alerte_rouge_negatif);
}

.border-all-1-solid-alerte_bleu_information {
  border: 1px solid var(--alerte_bleu_information);
}

.border-all-1-dashed-alerte_bleu_information {
  border: 1px dashed var(--alerte_bleu_information);
}

.border-all-1-dotted-alerte_bleu_information {
  border: 1px dotted var(--alerte_bleu_information);
}

.border-all-1-double-alerte_bleu_information {
  border: 1px double var(--alerte_bleu_information);
}

.border-all-1-groove-alerte_bleu_information {
  border: 1px groove var(--alerte_bleu_information);
}

.border-all-1-ridge-alerte_bleu_information {
  border: 1px ridge var(--alerte_bleu_information);
}

.border-all-1-inset-alerte_bleu_information {
  border: 1px inset var(--alerte_bleu_information);
}

.border-all-1-outset-alerte_bleu_information {
  border: 1px outset var(--alerte_bleu_information);
}

.border-all-1-none-alerte_bleu_information {
  border: 1px none var(--alerte_bleu_information);
}

.border-all-1-hidden-alerte_bleu_information {
  border: 1px hidden var(--alerte_bleu_information);
}

.border-all-1-solid-alerte_jaune_attention {
  border: 1px solid var(--alerte_jaune_attention);
}

.border-all-1-dashed-alerte_jaune_attention {
  border: 1px dashed var(--alerte_jaune_attention);
}

.border-all-1-dotted-alerte_jaune_attention {
  border: 1px dotted var(--alerte_jaune_attention);
}

.border-all-1-double-alerte_jaune_attention {
  border: 1px double var(--alerte_jaune_attention);
}

.border-all-1-groove-alerte_jaune_attention {
  border: 1px groove var(--alerte_jaune_attention);
}

.border-all-1-ridge-alerte_jaune_attention {
  border: 1px ridge var(--alerte_jaune_attention);
}

.border-all-1-inset-alerte_jaune_attention {
  border: 1px inset var(--alerte_jaune_attention);
}

.border-all-1-outset-alerte_jaune_attention {
  border: 1px outset var(--alerte_jaune_attention);
}

.border-all-1-none-alerte_jaune_attention {
  border: 1px none var(--alerte_jaune_attention);
}

.border-all-1-hidden-alerte_jaune_attention {
  border: 1px hidden var(--alerte_jaune_attention);
}

.border-all-1-solid-blanc {
  border: 1px solid #fff;
}

.border-all-1-dashed-blanc {
  border: 1px dashed #fff;
}

.border-all-1-dotted-blanc {
  border: 1px dotted #fff;
}

.border-all-1-double-blanc {
  border: 1px double #fff;
}

.border-all-1-groove-blanc {
  border: 1px groove #fff;
}

.border-all-1-ridge-blanc {
  border: 1px ridge #fff;
}

.border-all-1-inset-blanc {
  border: 1px inset #fff;
}

.border-all-1-outset-blanc {
  border: 1px outset #fff;
}

.border-all-1-none-blanc {
  border: 1px #fff;
}

.border-all-1-hidden-blanc {
  border: 1px hidden #fff;
}

.border-all-1-solid-noir {
  border: 1px solid #222;
}

.border-all-1-dashed-noir {
  border: 1px dashed #222;
}

.border-all-1-dotted-noir {
  border: 1px dotted #222;
}

.border-all-1-double-noir {
  border: 1px double #222;
}

.border-all-1-groove-noir {
  border: 1px groove #222;
}

.border-all-1-ridge-noir {
  border: 1px ridge #222;
}

.border-all-1-inset-noir {
  border: 1px inset #222;
}

.border-all-1-outset-noir {
  border: 1px outset #222;
}

.border-all-1-none-noir {
  border: 1px #222;
}

.border-all-1-hidden-noir {
  border: 1px hidden #222;
}

.border-all-1-solid-gris_light, .ds-card__content {
  border: 1px solid var(--gris_light);
}

.border-all-1-dashed-gris_light {
  border: 1px dashed var(--gris_light);
}

.border-all-1-dotted-gris_light {
  border: 1px dotted var(--gris_light);
}

.border-all-1-double-gris_light {
  border: 1px double var(--gris_light);
}

.border-all-1-groove-gris_light {
  border: 1px groove var(--gris_light);
}

.border-all-1-ridge-gris_light {
  border: 1px ridge var(--gris_light);
}

.border-all-1-inset-gris_light {
  border: 1px inset var(--gris_light);
}

.border-all-1-outset-gris_light {
  border: 1px outset var(--gris_light);
}

.border-all-1-none-gris_light {
  border: 1px none var(--gris_light);
}

.border-all-1-hidden-gris_light {
  border: 1px hidden var(--gris_light);
}

.border-all-1-solid-gris_dark {
  border: 1px solid var(--gris_dark);
}

.border-all-1-dashed-gris_dark {
  border: 1px dashed var(--gris_dark);
}

.border-all-1-dotted-gris_dark {
  border: 1px dotted var(--gris_dark);
}

.border-all-1-double-gris_dark {
  border: 1px double var(--gris_dark);
}

.border-all-1-groove-gris_dark {
  border: 1px groove var(--gris_dark);
}

.border-all-1-ridge-gris_dark {
  border: 1px ridge var(--gris_dark);
}

.border-all-1-inset-gris_dark {
  border: 1px inset var(--gris_dark);
}

.border-all-1-outset-gris_dark {
  border: 1px outset var(--gris_dark);
}

.border-all-1-none-gris_dark {
  border: 1px none var(--gris_dark);
}

.border-all-1-hidden-gris_dark {
  border: 1px hidden var(--gris_dark);
}

.border-all-1-solid-gris_background {
  border: 1px solid var(--gris_background);
}

.border-all-1-dashed-gris_background {
  border: 1px dashed var(--gris_background);
}

.border-all-1-dotted-gris_background {
  border: 1px dotted var(--gris_background);
}

.border-all-1-double-gris_background {
  border: 1px double var(--gris_background);
}

.border-all-1-groove-gris_background {
  border: 1px groove var(--gris_background);
}

.border-all-1-ridge-gris_background {
  border: 1px ridge var(--gris_background);
}

.border-all-1-inset-gris_background {
  border: 1px inset var(--gris_background);
}

.border-all-1-outset-gris_background {
  border: 1px outset var(--gris_background);
}

.border-all-1-none-gris_background {
  border: 1px none var(--gris_background);
}

.border-all-1-hidden-gris_background {
  border: 1px hidden var(--gris_background);
}

.border-top-2-solid-corail_mh_brand {
  border-top: 2px solid var(--corail_mh_brand);
}

.border-top-2-dashed-corail_mh_brand {
  border-top: 2px dashed var(--corail_mh_brand);
}

.border-top-2-dotted-corail_mh_brand {
  border-top: 2px dotted var(--corail_mh_brand);
}

.border-top-2-double-corail_mh_brand {
  border-top: 2px double var(--corail_mh_brand);
}

.border-top-2-groove-corail_mh_brand {
  border-top: 2px groove var(--corail_mh_brand);
}

.border-top-2-ridge-corail_mh_brand {
  border-top: 2px ridge var(--corail_mh_brand);
}

.border-top-2-inset-corail_mh_brand {
  border-top: 2px inset var(--corail_mh_brand);
}

.border-top-2-outset-corail_mh_brand {
  border-top: 2px outset var(--corail_mh_brand);
}

.border-top-2-none-corail_mh_brand {
  border-top: 2px none var(--corail_mh_brand);
}

.border-top-2-hidden-corail_mh_brand {
  border-top: 2px hidden var(--corail_mh_brand);
}

.border-top-2-solid-corail_mh_dark {
  border-top: 2px solid var(--corail_mh_dark);
}

.border-top-2-dashed-corail_mh_dark {
  border-top: 2px dashed var(--corail_mh_dark);
}

.border-top-2-dotted-corail_mh_dark {
  border-top: 2px dotted var(--corail_mh_dark);
}

.border-top-2-double-corail_mh_dark {
  border-top: 2px double var(--corail_mh_dark);
}

.border-top-2-groove-corail_mh_dark {
  border-top: 2px groove var(--corail_mh_dark);
}

.border-top-2-ridge-corail_mh_dark {
  border-top: 2px ridge var(--corail_mh_dark);
}

.border-top-2-inset-corail_mh_dark {
  border-top: 2px inset var(--corail_mh_dark);
}

.border-top-2-outset-corail_mh_dark {
  border-top: 2px outset var(--corail_mh_dark);
}

.border-top-2-none-corail_mh_dark {
  border-top: 2px none var(--corail_mh_dark);
}

.border-top-2-hidden-corail_mh_dark {
  border-top: 2px hidden var(--corail_mh_dark);
}

.border-top-2-solid-corail_mh_light {
  border-top: 2px solid var(--corail_mh_light);
}

.border-top-2-dashed-corail_mh_light {
  border-top: 2px dashed var(--corail_mh_light);
}

.border-top-2-dotted-corail_mh_light {
  border-top: 2px dotted var(--corail_mh_light);
}

.border-top-2-double-corail_mh_light {
  border-top: 2px double var(--corail_mh_light);
}

.border-top-2-groove-corail_mh_light {
  border-top: 2px groove var(--corail_mh_light);
}

.border-top-2-ridge-corail_mh_light {
  border-top: 2px ridge var(--corail_mh_light);
}

.border-top-2-inset-corail_mh_light {
  border-top: 2px inset var(--corail_mh_light);
}

.border-top-2-outset-corail_mh_light {
  border-top: 2px outset var(--corail_mh_light);
}

.border-top-2-none-corail_mh_light {
  border-top: 2px none var(--corail_mh_light);
}

.border-top-2-hidden-corail_mh_light {
  border-top: 2px hidden var(--corail_mh_light);
}

.border-top-2-solid-gris_sable_light {
  border-top: 2px solid var(--gris_sable_light);
}

.border-top-2-dashed-gris_sable_light {
  border-top: 2px dashed var(--gris_sable_light);
}

.border-top-2-dotted-gris_sable_light {
  border-top: 2px dotted var(--gris_sable_light);
}

.border-top-2-double-gris_sable_light {
  border-top: 2px double var(--gris_sable_light);
}

.border-top-2-groove-gris_sable_light {
  border-top: 2px groove var(--gris_sable_light);
}

.border-top-2-ridge-gris_sable_light {
  border-top: 2px ridge var(--gris_sable_light);
}

.border-top-2-inset-gris_sable_light {
  border-top: 2px inset var(--gris_sable_light);
}

.border-top-2-outset-gris_sable_light {
  border-top: 2px outset var(--gris_sable_light);
}

.border-top-2-none-gris_sable_light {
  border-top: 2px none var(--gris_sable_light);
}

.border-top-2-hidden-gris_sable_light {
  border-top: 2px hidden var(--gris_sable_light);
}

.border-top-2-solid-gris_sable_dark {
  border-top: 2px solid var(--gris_sable_dark);
}

.border-top-2-dashed-gris_sable_dark {
  border-top: 2px dashed var(--gris_sable_dark);
}

.border-top-2-dotted-gris_sable_dark {
  border-top: 2px dotted var(--gris_sable_dark);
}

.border-top-2-double-gris_sable_dark {
  border-top: 2px double var(--gris_sable_dark);
}

.border-top-2-groove-gris_sable_dark {
  border-top: 2px groove var(--gris_sable_dark);
}

.border-top-2-ridge-gris_sable_dark {
  border-top: 2px ridge var(--gris_sable_dark);
}

.border-top-2-inset-gris_sable_dark {
  border-top: 2px inset var(--gris_sable_dark);
}

.border-top-2-outset-gris_sable_dark {
  border-top: 2px outset var(--gris_sable_dark);
}

.border-top-2-none-gris_sable_dark {
  border-top: 2px none var(--gris_sable_dark);
}

.border-top-2-hidden-gris_sable_dark {
  border-top: 2px hidden var(--gris_sable_dark);
}

.border-top-2-solid-jaune_ivoire_light {
  border-top: 2px solid var(--jaune_ivoire_light);
}

.border-top-2-dashed-jaune_ivoire_light {
  border-top: 2px dashed var(--jaune_ivoire_light);
}

.border-top-2-dotted-jaune_ivoire_light {
  border-top: 2px dotted var(--jaune_ivoire_light);
}

.border-top-2-double-jaune_ivoire_light {
  border-top: 2px double var(--jaune_ivoire_light);
}

.border-top-2-groove-jaune_ivoire_light {
  border-top: 2px groove var(--jaune_ivoire_light);
}

.border-top-2-ridge-jaune_ivoire_light {
  border-top: 2px ridge var(--jaune_ivoire_light);
}

.border-top-2-inset-jaune_ivoire_light {
  border-top: 2px inset var(--jaune_ivoire_light);
}

.border-top-2-outset-jaune_ivoire_light {
  border-top: 2px outset var(--jaune_ivoire_light);
}

.border-top-2-none-jaune_ivoire_light {
  border-top: 2px none var(--jaune_ivoire_light);
}

.border-top-2-hidden-jaune_ivoire_light {
  border-top: 2px hidden var(--jaune_ivoire_light);
}

.border-top-2-solid-jaune_ivoire_dark {
  border-top: 2px solid var(--jaune_ivoire_dark);
}

.border-top-2-dashed-jaune_ivoire_dark {
  border-top: 2px dashed var(--jaune_ivoire_dark);
}

.border-top-2-dotted-jaune_ivoire_dark {
  border-top: 2px dotted var(--jaune_ivoire_dark);
}

.border-top-2-double-jaune_ivoire_dark {
  border-top: 2px double var(--jaune_ivoire_dark);
}

.border-top-2-groove-jaune_ivoire_dark {
  border-top: 2px groove var(--jaune_ivoire_dark);
}

.border-top-2-ridge-jaune_ivoire_dark {
  border-top: 2px ridge var(--jaune_ivoire_dark);
}

.border-top-2-inset-jaune_ivoire_dark {
  border-top: 2px inset var(--jaune_ivoire_dark);
}

.border-top-2-outset-jaune_ivoire_dark {
  border-top: 2px outset var(--jaune_ivoire_dark);
}

.border-top-2-none-jaune_ivoire_dark {
  border-top: 2px none var(--jaune_ivoire_dark);
}

.border-top-2-hidden-jaune_ivoire_dark {
  border-top: 2px hidden var(--jaune_ivoire_dark);
}

.border-top-2-solid-jaune_light {
  border-top: 2px solid var(--jaune_light);
}

.border-top-2-dashed-jaune_light {
  border-top: 2px dashed var(--jaune_light);
}

.border-top-2-dotted-jaune_light {
  border-top: 2px dotted var(--jaune_light);
}

.border-top-2-double-jaune_light {
  border-top: 2px double var(--jaune_light);
}

.border-top-2-groove-jaune_light {
  border-top: 2px groove var(--jaune_light);
}

.border-top-2-ridge-jaune_light {
  border-top: 2px ridge var(--jaune_light);
}

.border-top-2-inset-jaune_light {
  border-top: 2px inset var(--jaune_light);
}

.border-top-2-outset-jaune_light {
  border-top: 2px outset var(--jaune_light);
}

.border-top-2-none-jaune_light {
  border-top: 2px none var(--jaune_light);
}

.border-top-2-hidden-jaune_light {
  border-top: 2px hidden var(--jaune_light);
}

.border-top-2-solid-jaune_dark {
  border-top: 2px solid var(--jaune_dark);
}

.border-top-2-dashed-jaune_dark {
  border-top: 2px dashed var(--jaune_dark);
}

.border-top-2-dotted-jaune_dark {
  border-top: 2px dotted var(--jaune_dark);
}

.border-top-2-double-jaune_dark {
  border-top: 2px double var(--jaune_dark);
}

.border-top-2-groove-jaune_dark {
  border-top: 2px groove var(--jaune_dark);
}

.border-top-2-ridge-jaune_dark {
  border-top: 2px ridge var(--jaune_dark);
}

.border-top-2-inset-jaune_dark {
  border-top: 2px inset var(--jaune_dark);
}

.border-top-2-outset-jaune_dark {
  border-top: 2px outset var(--jaune_dark);
}

.border-top-2-none-jaune_dark {
  border-top: 2px none var(--jaune_dark);
}

.border-top-2-hidden-jaune_dark {
  border-top: 2px hidden var(--jaune_dark);
}

.border-top-2-solid-orange_normal {
  border-top: 2px solid var(--orange_normal);
}

.border-top-2-dashed-orange_normal {
  border-top: 2px dashed var(--orange_normal);
}

.border-top-2-dotted-orange_normal {
  border-top: 2px dotted var(--orange_normal);
}

.border-top-2-double-orange_normal {
  border-top: 2px double var(--orange_normal);
}

.border-top-2-groove-orange_normal {
  border-top: 2px groove var(--orange_normal);
}

.border-top-2-ridge-orange_normal {
  border-top: 2px ridge var(--orange_normal);
}

.border-top-2-inset-orange_normal {
  border-top: 2px inset var(--orange_normal);
}

.border-top-2-outset-orange_normal {
  border-top: 2px outset var(--orange_normal);
}

.border-top-2-none-orange_normal {
  border-top: 2px none var(--orange_normal);
}

.border-top-2-hidden-orange_normal {
  border-top: 2px hidden var(--orange_normal);
}

.border-top-2-solid-orange_dark {
  border-top: 2px solid var(--orange_dark);
}

.border-top-2-dashed-orange_dark {
  border-top: 2px dashed var(--orange_dark);
}

.border-top-2-dotted-orange_dark {
  border-top: 2px dotted var(--orange_dark);
}

.border-top-2-double-orange_dark {
  border-top: 2px double var(--orange_dark);
}

.border-top-2-groove-orange_dark {
  border-top: 2px groove var(--orange_dark);
}

.border-top-2-ridge-orange_dark {
  border-top: 2px ridge var(--orange_dark);
}

.border-top-2-inset-orange_dark {
  border-top: 2px inset var(--orange_dark);
}

.border-top-2-outset-orange_dark {
  border-top: 2px outset var(--orange_dark);
}

.border-top-2-none-orange_dark {
  border-top: 2px none var(--orange_dark);
}

.border-top-2-hidden-orange_dark {
  border-top: 2px hidden var(--orange_dark);
}

.border-top-2-solid-vert_light {
  border-top: 2px solid var(--vert_light);
}

.border-top-2-dashed-vert_light {
  border-top: 2px dashed var(--vert_light);
}

.border-top-2-dotted-vert_light {
  border-top: 2px dotted var(--vert_light);
}

.border-top-2-double-vert_light {
  border-top: 2px double var(--vert_light);
}

.border-top-2-groove-vert_light {
  border-top: 2px groove var(--vert_light);
}

.border-top-2-ridge-vert_light {
  border-top: 2px ridge var(--vert_light);
}

.border-top-2-inset-vert_light {
  border-top: 2px inset var(--vert_light);
}

.border-top-2-outset-vert_light {
  border-top: 2px outset var(--vert_light);
}

.border-top-2-none-vert_light {
  border-top: 2px none var(--vert_light);
}

.border-top-2-hidden-vert_light {
  border-top: 2px hidden var(--vert_light);
}

.border-top-2-solid-vert_dark {
  border-top: 2px solid var(--vert_dark);
}

.border-top-2-dashed-vert_dark {
  border-top: 2px dashed var(--vert_dark);
}

.border-top-2-dotted-vert_dark {
  border-top: 2px dotted var(--vert_dark);
}

.border-top-2-double-vert_dark {
  border-top: 2px double var(--vert_dark);
}

.border-top-2-groove-vert_dark {
  border-top: 2px groove var(--vert_dark);
}

.border-top-2-ridge-vert_dark {
  border-top: 2px ridge var(--vert_dark);
}

.border-top-2-inset-vert_dark {
  border-top: 2px inset var(--vert_dark);
}

.border-top-2-outset-vert_dark {
  border-top: 2px outset var(--vert_dark);
}

.border-top-2-none-vert_dark {
  border-top: 2px none var(--vert_dark);
}

.border-top-2-hidden-vert_dark {
  border-top: 2px hidden var(--vert_dark);
}

.border-top-2-solid-bleu_turquoise_light {
  border-top: 2px solid var(--bleu_turquoise_light);
}

.border-top-2-dashed-bleu_turquoise_light {
  border-top: 2px dashed var(--bleu_turquoise_light);
}

.border-top-2-dotted-bleu_turquoise_light {
  border-top: 2px dotted var(--bleu_turquoise_light);
}

.border-top-2-double-bleu_turquoise_light {
  border-top: 2px double var(--bleu_turquoise_light);
}

.border-top-2-groove-bleu_turquoise_light {
  border-top: 2px groove var(--bleu_turquoise_light);
}

.border-top-2-ridge-bleu_turquoise_light {
  border-top: 2px ridge var(--bleu_turquoise_light);
}

.border-top-2-inset-bleu_turquoise_light {
  border-top: 2px inset var(--bleu_turquoise_light);
}

.border-top-2-outset-bleu_turquoise_light {
  border-top: 2px outset var(--bleu_turquoise_light);
}

.border-top-2-none-bleu_turquoise_light {
  border-top: 2px none var(--bleu_turquoise_light);
}

.border-top-2-hidden-bleu_turquoise_light {
  border-top: 2px hidden var(--bleu_turquoise_light);
}

.border-top-2-solid-bleu_turquoise_middle {
  border-top: 2px solid var(--bleu_turquoise_middle);
}

.border-top-2-dashed-bleu_turquoise_middle {
  border-top: 2px dashed var(--bleu_turquoise_middle);
}

.border-top-2-dotted-bleu_turquoise_middle {
  border-top: 2px dotted var(--bleu_turquoise_middle);
}

.border-top-2-double-bleu_turquoise_middle {
  border-top: 2px double var(--bleu_turquoise_middle);
}

.border-top-2-groove-bleu_turquoise_middle {
  border-top: 2px groove var(--bleu_turquoise_middle);
}

.border-top-2-ridge-bleu_turquoise_middle {
  border-top: 2px ridge var(--bleu_turquoise_middle);
}

.border-top-2-inset-bleu_turquoise_middle {
  border-top: 2px inset var(--bleu_turquoise_middle);
}

.border-top-2-outset-bleu_turquoise_middle {
  border-top: 2px outset var(--bleu_turquoise_middle);
}

.border-top-2-none-bleu_turquoise_middle {
  border-top: 2px none var(--bleu_turquoise_middle);
}

.border-top-2-hidden-bleu_turquoise_middle {
  border-top: 2px hidden var(--bleu_turquoise_middle);
}

.border-top-2-solid-bleu_turquoise_dark {
  border-top: 2px solid var(--bleu_turquoise_dark);
}

.border-top-2-dashed-bleu_turquoise_dark {
  border-top: 2px dashed var(--bleu_turquoise_dark);
}

.border-top-2-dotted-bleu_turquoise_dark {
  border-top: 2px dotted var(--bleu_turquoise_dark);
}

.border-top-2-double-bleu_turquoise_dark {
  border-top: 2px double var(--bleu_turquoise_dark);
}

.border-top-2-groove-bleu_turquoise_dark {
  border-top: 2px groove var(--bleu_turquoise_dark);
}

.border-top-2-ridge-bleu_turquoise_dark {
  border-top: 2px ridge var(--bleu_turquoise_dark);
}

.border-top-2-inset-bleu_turquoise_dark {
  border-top: 2px inset var(--bleu_turquoise_dark);
}

.border-top-2-outset-bleu_turquoise_dark {
  border-top: 2px outset var(--bleu_turquoise_dark);
}

.border-top-2-none-bleu_turquoise_dark {
  border-top: 2px none var(--bleu_turquoise_dark);
}

.border-top-2-hidden-bleu_turquoise_dark {
  border-top: 2px hidden var(--bleu_turquoise_dark);
}

.border-top-2-solid-bleu_light {
  border-top: 2px solid var(--bleu_light);
}

.border-top-2-dashed-bleu_light {
  border-top: 2px dashed var(--bleu_light);
}

.border-top-2-dotted-bleu_light {
  border-top: 2px dotted var(--bleu_light);
}

.border-top-2-double-bleu_light {
  border-top: 2px double var(--bleu_light);
}

.border-top-2-groove-bleu_light {
  border-top: 2px groove var(--bleu_light);
}

.border-top-2-ridge-bleu_light {
  border-top: 2px ridge var(--bleu_light);
}

.border-top-2-inset-bleu_light {
  border-top: 2px inset var(--bleu_light);
}

.border-top-2-outset-bleu_light {
  border-top: 2px outset var(--bleu_light);
}

.border-top-2-none-bleu_light {
  border-top: 2px none var(--bleu_light);
}

.border-top-2-hidden-bleu_light {
  border-top: 2px hidden var(--bleu_light);
}

.border-top-2-solid-bleu_dark {
  border-top: 2px solid var(--bleu_dark);
}

.border-top-2-dashed-bleu_dark {
  border-top: 2px dashed var(--bleu_dark);
}

.border-top-2-dotted-bleu_dark {
  border-top: 2px dotted var(--bleu_dark);
}

.border-top-2-double-bleu_dark {
  border-top: 2px double var(--bleu_dark);
}

.border-top-2-groove-bleu_dark {
  border-top: 2px groove var(--bleu_dark);
}

.border-top-2-ridge-bleu_dark {
  border-top: 2px ridge var(--bleu_dark);
}

.border-top-2-inset-bleu_dark {
  border-top: 2px inset var(--bleu_dark);
}

.border-top-2-outset-bleu_dark {
  border-top: 2px outset var(--bleu_dark);
}

.border-top-2-none-bleu_dark {
  border-top: 2px none var(--bleu_dark);
}

.border-top-2-hidden-bleu_dark {
  border-top: 2px hidden var(--bleu_dark);
}

.border-top-2-solid-bleu_klein_light {
  border-top: 2px solid var(--bleu_klein_light);
}

.border-top-2-dashed-bleu_klein_light {
  border-top: 2px dashed var(--bleu_klein_light);
}

.border-top-2-dotted-bleu_klein_light {
  border-top: 2px dotted var(--bleu_klein_light);
}

.border-top-2-double-bleu_klein_light {
  border-top: 2px double var(--bleu_klein_light);
}

.border-top-2-groove-bleu_klein_light {
  border-top: 2px groove var(--bleu_klein_light);
}

.border-top-2-ridge-bleu_klein_light {
  border-top: 2px ridge var(--bleu_klein_light);
}

.border-top-2-inset-bleu_klein_light {
  border-top: 2px inset var(--bleu_klein_light);
}

.border-top-2-outset-bleu_klein_light {
  border-top: 2px outset var(--bleu_klein_light);
}

.border-top-2-none-bleu_klein_light {
  border-top: 2px none var(--bleu_klein_light);
}

.border-top-2-hidden-bleu_klein_light {
  border-top: 2px hidden var(--bleu_klein_light);
}

.border-top-2-solid-bleu_klein_dark {
  border-top: 2px solid var(--bleu_klein_dark);
}

.border-top-2-dashed-bleu_klein_dark {
  border-top: 2px dashed var(--bleu_klein_dark);
}

.border-top-2-dotted-bleu_klein_dark {
  border-top: 2px dotted var(--bleu_klein_dark);
}

.border-top-2-double-bleu_klein_dark {
  border-top: 2px double var(--bleu_klein_dark);
}

.border-top-2-groove-bleu_klein_dark {
  border-top: 2px groove var(--bleu_klein_dark);
}

.border-top-2-ridge-bleu_klein_dark {
  border-top: 2px ridge var(--bleu_klein_dark);
}

.border-top-2-inset-bleu_klein_dark {
  border-top: 2px inset var(--bleu_klein_dark);
}

.border-top-2-outset-bleu_klein_dark {
  border-top: 2px outset var(--bleu_klein_dark);
}

.border-top-2-none-bleu_klein_dark {
  border-top: 2px none var(--bleu_klein_dark);
}

.border-top-2-hidden-bleu_klein_dark {
  border-top: 2px hidden var(--bleu_klein_dark);
}

.border-top-2-solid-violet_light {
  border-top: 2px solid var(--violet_light);
}

.border-top-2-dashed-violet_light {
  border-top: 2px dashed var(--violet_light);
}

.border-top-2-dotted-violet_light {
  border-top: 2px dotted var(--violet_light);
}

.border-top-2-double-violet_light {
  border-top: 2px double var(--violet_light);
}

.border-top-2-groove-violet_light {
  border-top: 2px groove var(--violet_light);
}

.border-top-2-ridge-violet_light {
  border-top: 2px ridge var(--violet_light);
}

.border-top-2-inset-violet_light {
  border-top: 2px inset var(--violet_light);
}

.border-top-2-outset-violet_light {
  border-top: 2px outset var(--violet_light);
}

.border-top-2-none-violet_light {
  border-top: 2px none var(--violet_light);
}

.border-top-2-hidden-violet_light {
  border-top: 2px hidden var(--violet_light);
}

.border-top-2-solid-violet_dark {
  border-top: 2px solid var(--violet_dark);
}

.border-top-2-dashed-violet_dark {
  border-top: 2px dashed var(--violet_dark);
}

.border-top-2-dotted-violet_dark {
  border-top: 2px dotted var(--violet_dark);
}

.border-top-2-double-violet_dark {
  border-top: 2px double var(--violet_dark);
}

.border-top-2-groove-violet_dark {
  border-top: 2px groove var(--violet_dark);
}

.border-top-2-ridge-violet_dark {
  border-top: 2px ridge var(--violet_dark);
}

.border-top-2-inset-violet_dark {
  border-top: 2px inset var(--violet_dark);
}

.border-top-2-outset-violet_dark {
  border-top: 2px outset var(--violet_dark);
}

.border-top-2-none-violet_dark {
  border-top: 2px none var(--violet_dark);
}

.border-top-2-hidden-violet_dark {
  border-top: 2px hidden var(--violet_dark);
}

.border-top-2-solid-mauve {
  border-top: 2px solid var(--mauve);
}

.border-top-2-dashed-mauve {
  border-top: 2px dashed var(--mauve);
}

.border-top-2-dotted-mauve {
  border-top: 2px dotted var(--mauve);
}

.border-top-2-double-mauve {
  border-top: 2px double var(--mauve);
}

.border-top-2-groove-mauve {
  border-top: 2px groove var(--mauve);
}

.border-top-2-ridge-mauve {
  border-top: 2px ridge var(--mauve);
}

.border-top-2-inset-mauve {
  border-top: 2px inset var(--mauve);
}

.border-top-2-outset-mauve {
  border-top: 2px outset var(--mauve);
}

.border-top-2-none-mauve {
  border-top: 2px none var(--mauve);
}

.border-top-2-hidden-mauve {
  border-top: 2px hidden var(--mauve);
}

.border-top-2-solid-rose_light {
  border-top: 2px solid var(--rose_light);
}

.border-top-2-dashed-rose_light {
  border-top: 2px dashed var(--rose_light);
}

.border-top-2-dotted-rose_light {
  border-top: 2px dotted var(--rose_light);
}

.border-top-2-double-rose_light {
  border-top: 2px double var(--rose_light);
}

.border-top-2-groove-rose_light {
  border-top: 2px groove var(--rose_light);
}

.border-top-2-ridge-rose_light {
  border-top: 2px ridge var(--rose_light);
}

.border-top-2-inset-rose_light {
  border-top: 2px inset var(--rose_light);
}

.border-top-2-outset-rose_light {
  border-top: 2px outset var(--rose_light);
}

.border-top-2-none-rose_light {
  border-top: 2px none var(--rose_light);
}

.border-top-2-hidden-rose_light {
  border-top: 2px hidden var(--rose_light);
}

.border-top-2-solid-rose_dark {
  border-top: 2px solid var(--rose_dark);
}

.border-top-2-dashed-rose_dark {
  border-top: 2px dashed var(--rose_dark);
}

.border-top-2-dotted-rose_dark {
  border-top: 2px dotted var(--rose_dark);
}

.border-top-2-double-rose_dark {
  border-top: 2px double var(--rose_dark);
}

.border-top-2-groove-rose_dark {
  border-top: 2px groove var(--rose_dark);
}

.border-top-2-ridge-rose_dark {
  border-top: 2px ridge var(--rose_dark);
}

.border-top-2-inset-rose_dark {
  border-top: 2px inset var(--rose_dark);
}

.border-top-2-outset-rose_dark {
  border-top: 2px outset var(--rose_dark);
}

.border-top-2-none-rose_dark {
  border-top: 2px none var(--rose_dark);
}

.border-top-2-hidden-rose_dark {
  border-top: 2px hidden var(--rose_dark);
}

.border-top-2-solid-rose_fushia {
  border-top: 2px solid var(--rose_fushia);
}

.border-top-2-dashed-rose_fushia {
  border-top: 2px dashed var(--rose_fushia);
}

.border-top-2-dotted-rose_fushia {
  border-top: 2px dotted var(--rose_fushia);
}

.border-top-2-double-rose_fushia {
  border-top: 2px double var(--rose_fushia);
}

.border-top-2-groove-rose_fushia {
  border-top: 2px groove var(--rose_fushia);
}

.border-top-2-ridge-rose_fushia {
  border-top: 2px ridge var(--rose_fushia);
}

.border-top-2-inset-rose_fushia {
  border-top: 2px inset var(--rose_fushia);
}

.border-top-2-outset-rose_fushia {
  border-top: 2px outset var(--rose_fushia);
}

.border-top-2-none-rose_fushia {
  border-top: 2px none var(--rose_fushia);
}

.border-top-2-hidden-rose_fushia {
  border-top: 2px hidden var(--rose_fushia);
}

.border-top-2-solid-rouge_framboise {
  border-top: 2px solid var(--rouge_framboise);
}

.border-top-2-dashed-rouge_framboise {
  border-top: 2px dashed var(--rouge_framboise);
}

.border-top-2-dotted-rouge_framboise {
  border-top: 2px dotted var(--rouge_framboise);
}

.border-top-2-double-rouge_framboise {
  border-top: 2px double var(--rouge_framboise);
}

.border-top-2-groove-rouge_framboise {
  border-top: 2px groove var(--rouge_framboise);
}

.border-top-2-ridge-rouge_framboise {
  border-top: 2px ridge var(--rouge_framboise);
}

.border-top-2-inset-rouge_framboise {
  border-top: 2px inset var(--rouge_framboise);
}

.border-top-2-outset-rouge_framboise {
  border-top: 2px outset var(--rouge_framboise);
}

.border-top-2-none-rouge_framboise {
  border-top: 2px none var(--rouge_framboise);
}

.border-top-2-hidden-rouge_framboise {
  border-top: 2px hidden var(--rouge_framboise);
}

.border-top-2-solid-rose_chair_light {
  border-top: 2px solid var(--rose_chair_light);
}

.border-top-2-dashed-rose_chair_light {
  border-top: 2px dashed var(--rose_chair_light);
}

.border-top-2-dotted-rose_chair_light {
  border-top: 2px dotted var(--rose_chair_light);
}

.border-top-2-double-rose_chair_light {
  border-top: 2px double var(--rose_chair_light);
}

.border-top-2-groove-rose_chair_light {
  border-top: 2px groove var(--rose_chair_light);
}

.border-top-2-ridge-rose_chair_light {
  border-top: 2px ridge var(--rose_chair_light);
}

.border-top-2-inset-rose_chair_light {
  border-top: 2px inset var(--rose_chair_light);
}

.border-top-2-outset-rose_chair_light {
  border-top: 2px outset var(--rose_chair_light);
}

.border-top-2-none-rose_chair_light {
  border-top: 2px none var(--rose_chair_light);
}

.border-top-2-hidden-rose_chair_light {
  border-top: 2px hidden var(--rose_chair_light);
}

.border-top-2-solid-rose_chair_dark {
  border-top: 2px solid var(--rose_chair_dark);
}

.border-top-2-dashed-rose_chair_dark {
  border-top: 2px dashed var(--rose_chair_dark);
}

.border-top-2-dotted-rose_chair_dark {
  border-top: 2px dotted var(--rose_chair_dark);
}

.border-top-2-double-rose_chair_dark {
  border-top: 2px double var(--rose_chair_dark);
}

.border-top-2-groove-rose_chair_dark {
  border-top: 2px groove var(--rose_chair_dark);
}

.border-top-2-ridge-rose_chair_dark {
  border-top: 2px ridge var(--rose_chair_dark);
}

.border-top-2-inset-rose_chair_dark {
  border-top: 2px inset var(--rose_chair_dark);
}

.border-top-2-outset-rose_chair_dark {
  border-top: 2px outset var(--rose_chair_dark);
}

.border-top-2-none-rose_chair_dark {
  border-top: 2px none var(--rose_chair_dark);
}

.border-top-2-hidden-rose_chair_dark {
  border-top: 2px hidden var(--rose_chair_dark);
}

.border-top-2-solid-vert_positif {
  border-top: 2px solid var(--vert_positif);
}

.border-top-2-dashed-vert_positif {
  border-top: 2px dashed var(--vert_positif);
}

.border-top-2-dotted-vert_positif {
  border-top: 2px dotted var(--vert_positif);
}

.border-top-2-double-vert_positif {
  border-top: 2px double var(--vert_positif);
}

.border-top-2-groove-vert_positif {
  border-top: 2px groove var(--vert_positif);
}

.border-top-2-ridge-vert_positif {
  border-top: 2px ridge var(--vert_positif);
}

.border-top-2-inset-vert_positif {
  border-top: 2px inset var(--vert_positif);
}

.border-top-2-outset-vert_positif {
  border-top: 2px outset var(--vert_positif);
}

.border-top-2-none-vert_positif {
  border-top: 2px none var(--vert_positif);
}

.border-top-2-hidden-vert_positif {
  border-top: 2px hidden var(--vert_positif);
}

.border-top-2-solid-rouge_negatif {
  border-top: 2px solid var(--rouge_negatif);
}

.border-top-2-dashed-rouge_negatif {
  border-top: 2px dashed var(--rouge_negatif);
}

.border-top-2-dotted-rouge_negatif {
  border-top: 2px dotted var(--rouge_negatif);
}

.border-top-2-double-rouge_negatif {
  border-top: 2px double var(--rouge_negatif);
}

.border-top-2-groove-rouge_negatif {
  border-top: 2px groove var(--rouge_negatif);
}

.border-top-2-ridge-rouge_negatif {
  border-top: 2px ridge var(--rouge_negatif);
}

.border-top-2-inset-rouge_negatif {
  border-top: 2px inset var(--rouge_negatif);
}

.border-top-2-outset-rouge_negatif {
  border-top: 2px outset var(--rouge_negatif);
}

.border-top-2-none-rouge_negatif {
  border-top: 2px none var(--rouge_negatif);
}

.border-top-2-hidden-rouge_negatif {
  border-top: 2px hidden var(--rouge_negatif);
}

.border-top-2-solid-bleu_texte {
  border-top: 2px solid var(--bleu_texte);
}

.border-top-2-dashed-bleu_texte {
  border-top: 2px dashed var(--bleu_texte);
}

.border-top-2-dotted-bleu_texte {
  border-top: 2px dotted var(--bleu_texte);
}

.border-top-2-double-bleu_texte {
  border-top: 2px double var(--bleu_texte);
}

.border-top-2-groove-bleu_texte {
  border-top: 2px groove var(--bleu_texte);
}

.border-top-2-ridge-bleu_texte {
  border-top: 2px ridge var(--bleu_texte);
}

.border-top-2-inset-bleu_texte {
  border-top: 2px inset var(--bleu_texte);
}

.border-top-2-outset-bleu_texte {
  border-top: 2px outset var(--bleu_texte);
}

.border-top-2-none-bleu_texte {
  border-top: 2px none var(--bleu_texte);
}

.border-top-2-hidden-bleu_texte {
  border-top: 2px hidden var(--bleu_texte);
}

.border-top-2-solid-alerte_vert_positif {
  border-top: 2px solid var(--alerte_vert_positif);
}

.border-top-2-dashed-alerte_vert_positif {
  border-top: 2px dashed var(--alerte_vert_positif);
}

.border-top-2-dotted-alerte_vert_positif {
  border-top: 2px dotted var(--alerte_vert_positif);
}

.border-top-2-double-alerte_vert_positif {
  border-top: 2px double var(--alerte_vert_positif);
}

.border-top-2-groove-alerte_vert_positif {
  border-top: 2px groove var(--alerte_vert_positif);
}

.border-top-2-ridge-alerte_vert_positif {
  border-top: 2px ridge var(--alerte_vert_positif);
}

.border-top-2-inset-alerte_vert_positif {
  border-top: 2px inset var(--alerte_vert_positif);
}

.border-top-2-outset-alerte_vert_positif {
  border-top: 2px outset var(--alerte_vert_positif);
}

.border-top-2-none-alerte_vert_positif {
  border-top: 2px none var(--alerte_vert_positif);
}

.border-top-2-hidden-alerte_vert_positif {
  border-top: 2px hidden var(--alerte_vert_positif);
}

.border-top-2-solid-alerte_rouge_negatif {
  border-top: 2px solid var(--alerte_rouge_negatif);
}

.border-top-2-dashed-alerte_rouge_negatif {
  border-top: 2px dashed var(--alerte_rouge_negatif);
}

.border-top-2-dotted-alerte_rouge_negatif {
  border-top: 2px dotted var(--alerte_rouge_negatif);
}

.border-top-2-double-alerte_rouge_negatif {
  border-top: 2px double var(--alerte_rouge_negatif);
}

.border-top-2-groove-alerte_rouge_negatif {
  border-top: 2px groove var(--alerte_rouge_negatif);
}

.border-top-2-ridge-alerte_rouge_negatif {
  border-top: 2px ridge var(--alerte_rouge_negatif);
}

.border-top-2-inset-alerte_rouge_negatif {
  border-top: 2px inset var(--alerte_rouge_negatif);
}

.border-top-2-outset-alerte_rouge_negatif {
  border-top: 2px outset var(--alerte_rouge_negatif);
}

.border-top-2-none-alerte_rouge_negatif {
  border-top: 2px none var(--alerte_rouge_negatif);
}

.border-top-2-hidden-alerte_rouge_negatif {
  border-top: 2px hidden var(--alerte_rouge_negatif);
}

.border-top-2-solid-alerte_bleu_information {
  border-top: 2px solid var(--alerte_bleu_information);
}

.border-top-2-dashed-alerte_bleu_information {
  border-top: 2px dashed var(--alerte_bleu_information);
}

.border-top-2-dotted-alerte_bleu_information {
  border-top: 2px dotted var(--alerte_bleu_information);
}

.border-top-2-double-alerte_bleu_information {
  border-top: 2px double var(--alerte_bleu_information);
}

.border-top-2-groove-alerte_bleu_information {
  border-top: 2px groove var(--alerte_bleu_information);
}

.border-top-2-ridge-alerte_bleu_information {
  border-top: 2px ridge var(--alerte_bleu_information);
}

.border-top-2-inset-alerte_bleu_information {
  border-top: 2px inset var(--alerte_bleu_information);
}

.border-top-2-outset-alerte_bleu_information {
  border-top: 2px outset var(--alerte_bleu_information);
}

.border-top-2-none-alerte_bleu_information {
  border-top: 2px none var(--alerte_bleu_information);
}

.border-top-2-hidden-alerte_bleu_information {
  border-top: 2px hidden var(--alerte_bleu_information);
}

.border-top-2-solid-alerte_jaune_attention {
  border-top: 2px solid var(--alerte_jaune_attention);
}

.border-top-2-dashed-alerte_jaune_attention {
  border-top: 2px dashed var(--alerte_jaune_attention);
}

.border-top-2-dotted-alerte_jaune_attention {
  border-top: 2px dotted var(--alerte_jaune_attention);
}

.border-top-2-double-alerte_jaune_attention {
  border-top: 2px double var(--alerte_jaune_attention);
}

.border-top-2-groove-alerte_jaune_attention {
  border-top: 2px groove var(--alerte_jaune_attention);
}

.border-top-2-ridge-alerte_jaune_attention {
  border-top: 2px ridge var(--alerte_jaune_attention);
}

.border-top-2-inset-alerte_jaune_attention {
  border-top: 2px inset var(--alerte_jaune_attention);
}

.border-top-2-outset-alerte_jaune_attention {
  border-top: 2px outset var(--alerte_jaune_attention);
}

.border-top-2-none-alerte_jaune_attention {
  border-top: 2px none var(--alerte_jaune_attention);
}

.border-top-2-hidden-alerte_jaune_attention {
  border-top: 2px hidden var(--alerte_jaune_attention);
}

.border-top-2-solid-blanc {
  border-top: 2px solid #fff;
}

.border-top-2-dashed-blanc {
  border-top: 2px dashed #fff;
}

.border-top-2-dotted-blanc {
  border-top: 2px dotted #fff;
}

.border-top-2-double-blanc {
  border-top: 2px double #fff;
}

.border-top-2-groove-blanc {
  border-top: 2px groove #fff;
}

.border-top-2-ridge-blanc {
  border-top: 2px ridge #fff;
}

.border-top-2-inset-blanc {
  border-top: 2px inset #fff;
}

.border-top-2-outset-blanc {
  border-top: 2px outset #fff;
}

.border-top-2-none-blanc {
  border-top: 2px #fff;
}

.border-top-2-hidden-blanc {
  border-top: 2px hidden #fff;
}

.border-top-2-solid-noir {
  border-top: 2px solid #222;
}

.border-top-2-dashed-noir {
  border-top: 2px dashed #222;
}

.border-top-2-dotted-noir {
  border-top: 2px dotted #222;
}

.border-top-2-double-noir {
  border-top: 2px double #222;
}

.border-top-2-groove-noir {
  border-top: 2px groove #222;
}

.border-top-2-ridge-noir {
  border-top: 2px ridge #222;
}

.border-top-2-inset-noir {
  border-top: 2px inset #222;
}

.border-top-2-outset-noir {
  border-top: 2px outset #222;
}

.border-top-2-none-noir {
  border-top: 2px #222;
}

.border-top-2-hidden-noir {
  border-top: 2px hidden #222;
}

.border-top-2-solid-gris_light {
  border-top: 2px solid var(--gris_light);
}

.border-top-2-dashed-gris_light {
  border-top: 2px dashed var(--gris_light);
}

.border-top-2-dotted-gris_light {
  border-top: 2px dotted var(--gris_light);
}

.border-top-2-double-gris_light {
  border-top: 2px double var(--gris_light);
}

.border-top-2-groove-gris_light {
  border-top: 2px groove var(--gris_light);
}

.border-top-2-ridge-gris_light {
  border-top: 2px ridge var(--gris_light);
}

.border-top-2-inset-gris_light {
  border-top: 2px inset var(--gris_light);
}

.border-top-2-outset-gris_light {
  border-top: 2px outset var(--gris_light);
}

.border-top-2-none-gris_light {
  border-top: 2px none var(--gris_light);
}

.border-top-2-hidden-gris_light {
  border-top: 2px hidden var(--gris_light);
}

.border-top-2-solid-gris_dark {
  border-top: 2px solid var(--gris_dark);
}

.border-top-2-dashed-gris_dark {
  border-top: 2px dashed var(--gris_dark);
}

.border-top-2-dotted-gris_dark {
  border-top: 2px dotted var(--gris_dark);
}

.border-top-2-double-gris_dark {
  border-top: 2px double var(--gris_dark);
}

.border-top-2-groove-gris_dark {
  border-top: 2px groove var(--gris_dark);
}

.border-top-2-ridge-gris_dark {
  border-top: 2px ridge var(--gris_dark);
}

.border-top-2-inset-gris_dark {
  border-top: 2px inset var(--gris_dark);
}

.border-top-2-outset-gris_dark {
  border-top: 2px outset var(--gris_dark);
}

.border-top-2-none-gris_dark {
  border-top: 2px none var(--gris_dark);
}

.border-top-2-hidden-gris_dark {
  border-top: 2px hidden var(--gris_dark);
}

.border-top-2-solid-gris_background {
  border-top: 2px solid var(--gris_background);
}

.border-top-2-dashed-gris_background {
  border-top: 2px dashed var(--gris_background);
}

.border-top-2-dotted-gris_background {
  border-top: 2px dotted var(--gris_background);
}

.border-top-2-double-gris_background {
  border-top: 2px double var(--gris_background);
}

.border-top-2-groove-gris_background {
  border-top: 2px groove var(--gris_background);
}

.border-top-2-ridge-gris_background {
  border-top: 2px ridge var(--gris_background);
}

.border-top-2-inset-gris_background {
  border-top: 2px inset var(--gris_background);
}

.border-top-2-outset-gris_background {
  border-top: 2px outset var(--gris_background);
}

.border-top-2-none-gris_background {
  border-top: 2px none var(--gris_background);
}

.border-top-2-hidden-gris_background {
  border-top: 2px hidden var(--gris_background);
}

.border-bottom-2-solid-corail_mh_brand {
  border-bottom: 2px solid var(--corail_mh_brand);
}

.border-bottom-2-dashed-corail_mh_brand {
  border-bottom: 2px dashed var(--corail_mh_brand);
}

.border-bottom-2-dotted-corail_mh_brand {
  border-bottom: 2px dotted var(--corail_mh_brand);
}

.border-bottom-2-double-corail_mh_brand {
  border-bottom: 2px double var(--corail_mh_brand);
}

.border-bottom-2-groove-corail_mh_brand {
  border-bottom: 2px groove var(--corail_mh_brand);
}

.border-bottom-2-ridge-corail_mh_brand {
  border-bottom: 2px ridge var(--corail_mh_brand);
}

.border-bottom-2-inset-corail_mh_brand {
  border-bottom: 2px inset var(--corail_mh_brand);
}

.border-bottom-2-outset-corail_mh_brand {
  border-bottom: 2px outset var(--corail_mh_brand);
}

.border-bottom-2-none-corail_mh_brand {
  border-bottom: 2px none var(--corail_mh_brand);
}

.border-bottom-2-hidden-corail_mh_brand {
  border-bottom: 2px hidden var(--corail_mh_brand);
}

.border-bottom-2-solid-corail_mh_dark {
  border-bottom: 2px solid var(--corail_mh_dark);
}

.border-bottom-2-dashed-corail_mh_dark {
  border-bottom: 2px dashed var(--corail_mh_dark);
}

.border-bottom-2-dotted-corail_mh_dark {
  border-bottom: 2px dotted var(--corail_mh_dark);
}

.border-bottom-2-double-corail_mh_dark {
  border-bottom: 2px double var(--corail_mh_dark);
}

.border-bottom-2-groove-corail_mh_dark {
  border-bottom: 2px groove var(--corail_mh_dark);
}

.border-bottom-2-ridge-corail_mh_dark {
  border-bottom: 2px ridge var(--corail_mh_dark);
}

.border-bottom-2-inset-corail_mh_dark {
  border-bottom: 2px inset var(--corail_mh_dark);
}

.border-bottom-2-outset-corail_mh_dark {
  border-bottom: 2px outset var(--corail_mh_dark);
}

.border-bottom-2-none-corail_mh_dark {
  border-bottom: 2px none var(--corail_mh_dark);
}

.border-bottom-2-hidden-corail_mh_dark {
  border-bottom: 2px hidden var(--corail_mh_dark);
}

.border-bottom-2-solid-corail_mh_light {
  border-bottom: 2px solid var(--corail_mh_light);
}

.border-bottom-2-dashed-corail_mh_light {
  border-bottom: 2px dashed var(--corail_mh_light);
}

.border-bottom-2-dotted-corail_mh_light {
  border-bottom: 2px dotted var(--corail_mh_light);
}

.border-bottom-2-double-corail_mh_light {
  border-bottom: 2px double var(--corail_mh_light);
}

.border-bottom-2-groove-corail_mh_light {
  border-bottom: 2px groove var(--corail_mh_light);
}

.border-bottom-2-ridge-corail_mh_light {
  border-bottom: 2px ridge var(--corail_mh_light);
}

.border-bottom-2-inset-corail_mh_light {
  border-bottom: 2px inset var(--corail_mh_light);
}

.border-bottom-2-outset-corail_mh_light {
  border-bottom: 2px outset var(--corail_mh_light);
}

.border-bottom-2-none-corail_mh_light {
  border-bottom: 2px none var(--corail_mh_light);
}

.border-bottom-2-hidden-corail_mh_light {
  border-bottom: 2px hidden var(--corail_mh_light);
}

.border-bottom-2-solid-gris_sable_light {
  border-bottom: 2px solid var(--gris_sable_light);
}

.border-bottom-2-dashed-gris_sable_light {
  border-bottom: 2px dashed var(--gris_sable_light);
}

.border-bottom-2-dotted-gris_sable_light {
  border-bottom: 2px dotted var(--gris_sable_light);
}

.border-bottom-2-double-gris_sable_light {
  border-bottom: 2px double var(--gris_sable_light);
}

.border-bottom-2-groove-gris_sable_light {
  border-bottom: 2px groove var(--gris_sable_light);
}

.border-bottom-2-ridge-gris_sable_light {
  border-bottom: 2px ridge var(--gris_sable_light);
}

.border-bottom-2-inset-gris_sable_light {
  border-bottom: 2px inset var(--gris_sable_light);
}

.border-bottom-2-outset-gris_sable_light {
  border-bottom: 2px outset var(--gris_sable_light);
}

.border-bottom-2-none-gris_sable_light {
  border-bottom: 2px none var(--gris_sable_light);
}

.border-bottom-2-hidden-gris_sable_light {
  border-bottom: 2px hidden var(--gris_sable_light);
}

.border-bottom-2-solid-gris_sable_dark {
  border-bottom: 2px solid var(--gris_sable_dark);
}

.border-bottom-2-dashed-gris_sable_dark {
  border-bottom: 2px dashed var(--gris_sable_dark);
}

.border-bottom-2-dotted-gris_sable_dark {
  border-bottom: 2px dotted var(--gris_sable_dark);
}

.border-bottom-2-double-gris_sable_dark {
  border-bottom: 2px double var(--gris_sable_dark);
}

.border-bottom-2-groove-gris_sable_dark {
  border-bottom: 2px groove var(--gris_sable_dark);
}

.border-bottom-2-ridge-gris_sable_dark {
  border-bottom: 2px ridge var(--gris_sable_dark);
}

.border-bottom-2-inset-gris_sable_dark {
  border-bottom: 2px inset var(--gris_sable_dark);
}

.border-bottom-2-outset-gris_sable_dark {
  border-bottom: 2px outset var(--gris_sable_dark);
}

.border-bottom-2-none-gris_sable_dark {
  border-bottom: 2px none var(--gris_sable_dark);
}

.border-bottom-2-hidden-gris_sable_dark {
  border-bottom: 2px hidden var(--gris_sable_dark);
}

.border-bottom-2-solid-jaune_ivoire_light {
  border-bottom: 2px solid var(--jaune_ivoire_light);
}

.border-bottom-2-dashed-jaune_ivoire_light {
  border-bottom: 2px dashed var(--jaune_ivoire_light);
}

.border-bottom-2-dotted-jaune_ivoire_light {
  border-bottom: 2px dotted var(--jaune_ivoire_light);
}

.border-bottom-2-double-jaune_ivoire_light {
  border-bottom: 2px double var(--jaune_ivoire_light);
}

.border-bottom-2-groove-jaune_ivoire_light {
  border-bottom: 2px groove var(--jaune_ivoire_light);
}

.border-bottom-2-ridge-jaune_ivoire_light {
  border-bottom: 2px ridge var(--jaune_ivoire_light);
}

.border-bottom-2-inset-jaune_ivoire_light {
  border-bottom: 2px inset var(--jaune_ivoire_light);
}

.border-bottom-2-outset-jaune_ivoire_light {
  border-bottom: 2px outset var(--jaune_ivoire_light);
}

.border-bottom-2-none-jaune_ivoire_light {
  border-bottom: 2px none var(--jaune_ivoire_light);
}

.border-bottom-2-hidden-jaune_ivoire_light {
  border-bottom: 2px hidden var(--jaune_ivoire_light);
}

.border-bottom-2-solid-jaune_ivoire_dark {
  border-bottom: 2px solid var(--jaune_ivoire_dark);
}

.border-bottom-2-dashed-jaune_ivoire_dark {
  border-bottom: 2px dashed var(--jaune_ivoire_dark);
}

.border-bottom-2-dotted-jaune_ivoire_dark {
  border-bottom: 2px dotted var(--jaune_ivoire_dark);
}

.border-bottom-2-double-jaune_ivoire_dark {
  border-bottom: 2px double var(--jaune_ivoire_dark);
}

.border-bottom-2-groove-jaune_ivoire_dark {
  border-bottom: 2px groove var(--jaune_ivoire_dark);
}

.border-bottom-2-ridge-jaune_ivoire_dark {
  border-bottom: 2px ridge var(--jaune_ivoire_dark);
}

.border-bottom-2-inset-jaune_ivoire_dark {
  border-bottom: 2px inset var(--jaune_ivoire_dark);
}

.border-bottom-2-outset-jaune_ivoire_dark {
  border-bottom: 2px outset var(--jaune_ivoire_dark);
}

.border-bottom-2-none-jaune_ivoire_dark {
  border-bottom: 2px none var(--jaune_ivoire_dark);
}

.border-bottom-2-hidden-jaune_ivoire_dark {
  border-bottom: 2px hidden var(--jaune_ivoire_dark);
}

.border-bottom-2-solid-jaune_light {
  border-bottom: 2px solid var(--jaune_light);
}

.border-bottom-2-dashed-jaune_light {
  border-bottom: 2px dashed var(--jaune_light);
}

.border-bottom-2-dotted-jaune_light {
  border-bottom: 2px dotted var(--jaune_light);
}

.border-bottom-2-double-jaune_light {
  border-bottom: 2px double var(--jaune_light);
}

.border-bottom-2-groove-jaune_light {
  border-bottom: 2px groove var(--jaune_light);
}

.border-bottom-2-ridge-jaune_light {
  border-bottom: 2px ridge var(--jaune_light);
}

.border-bottom-2-inset-jaune_light {
  border-bottom: 2px inset var(--jaune_light);
}

.border-bottom-2-outset-jaune_light {
  border-bottom: 2px outset var(--jaune_light);
}

.border-bottom-2-none-jaune_light {
  border-bottom: 2px none var(--jaune_light);
}

.border-bottom-2-hidden-jaune_light {
  border-bottom: 2px hidden var(--jaune_light);
}

.border-bottom-2-solid-jaune_dark {
  border-bottom: 2px solid var(--jaune_dark);
}

.border-bottom-2-dashed-jaune_dark {
  border-bottom: 2px dashed var(--jaune_dark);
}

.border-bottom-2-dotted-jaune_dark {
  border-bottom: 2px dotted var(--jaune_dark);
}

.border-bottom-2-double-jaune_dark {
  border-bottom: 2px double var(--jaune_dark);
}

.border-bottom-2-groove-jaune_dark {
  border-bottom: 2px groove var(--jaune_dark);
}

.border-bottom-2-ridge-jaune_dark {
  border-bottom: 2px ridge var(--jaune_dark);
}

.border-bottom-2-inset-jaune_dark {
  border-bottom: 2px inset var(--jaune_dark);
}

.border-bottom-2-outset-jaune_dark {
  border-bottom: 2px outset var(--jaune_dark);
}

.border-bottom-2-none-jaune_dark {
  border-bottom: 2px none var(--jaune_dark);
}

.border-bottom-2-hidden-jaune_dark {
  border-bottom: 2px hidden var(--jaune_dark);
}

.border-bottom-2-solid-orange_normal {
  border-bottom: 2px solid var(--orange_normal);
}

.border-bottom-2-dashed-orange_normal {
  border-bottom: 2px dashed var(--orange_normal);
}

.border-bottom-2-dotted-orange_normal {
  border-bottom: 2px dotted var(--orange_normal);
}

.border-bottom-2-double-orange_normal {
  border-bottom: 2px double var(--orange_normal);
}

.border-bottom-2-groove-orange_normal {
  border-bottom: 2px groove var(--orange_normal);
}

.border-bottom-2-ridge-orange_normal {
  border-bottom: 2px ridge var(--orange_normal);
}

.border-bottom-2-inset-orange_normal {
  border-bottom: 2px inset var(--orange_normal);
}

.border-bottom-2-outset-orange_normal {
  border-bottom: 2px outset var(--orange_normal);
}

.border-bottom-2-none-orange_normal {
  border-bottom: 2px none var(--orange_normal);
}

.border-bottom-2-hidden-orange_normal {
  border-bottom: 2px hidden var(--orange_normal);
}

.border-bottom-2-solid-orange_dark {
  border-bottom: 2px solid var(--orange_dark);
}

.border-bottom-2-dashed-orange_dark {
  border-bottom: 2px dashed var(--orange_dark);
}

.border-bottom-2-dotted-orange_dark {
  border-bottom: 2px dotted var(--orange_dark);
}

.border-bottom-2-double-orange_dark {
  border-bottom: 2px double var(--orange_dark);
}

.border-bottom-2-groove-orange_dark {
  border-bottom: 2px groove var(--orange_dark);
}

.border-bottom-2-ridge-orange_dark {
  border-bottom: 2px ridge var(--orange_dark);
}

.border-bottom-2-inset-orange_dark {
  border-bottom: 2px inset var(--orange_dark);
}

.border-bottom-2-outset-orange_dark {
  border-bottom: 2px outset var(--orange_dark);
}

.border-bottom-2-none-orange_dark {
  border-bottom: 2px none var(--orange_dark);
}

.border-bottom-2-hidden-orange_dark {
  border-bottom: 2px hidden var(--orange_dark);
}

.border-bottom-2-solid-vert_light {
  border-bottom: 2px solid var(--vert_light);
}

.border-bottom-2-dashed-vert_light {
  border-bottom: 2px dashed var(--vert_light);
}

.border-bottom-2-dotted-vert_light {
  border-bottom: 2px dotted var(--vert_light);
}

.border-bottom-2-double-vert_light {
  border-bottom: 2px double var(--vert_light);
}

.border-bottom-2-groove-vert_light {
  border-bottom: 2px groove var(--vert_light);
}

.border-bottom-2-ridge-vert_light {
  border-bottom: 2px ridge var(--vert_light);
}

.border-bottom-2-inset-vert_light {
  border-bottom: 2px inset var(--vert_light);
}

.border-bottom-2-outset-vert_light {
  border-bottom: 2px outset var(--vert_light);
}

.border-bottom-2-none-vert_light {
  border-bottom: 2px none var(--vert_light);
}

.border-bottom-2-hidden-vert_light {
  border-bottom: 2px hidden var(--vert_light);
}

.border-bottom-2-solid-vert_dark {
  border-bottom: 2px solid var(--vert_dark);
}

.border-bottom-2-dashed-vert_dark {
  border-bottom: 2px dashed var(--vert_dark);
}

.border-bottom-2-dotted-vert_dark {
  border-bottom: 2px dotted var(--vert_dark);
}

.border-bottom-2-double-vert_dark {
  border-bottom: 2px double var(--vert_dark);
}

.border-bottom-2-groove-vert_dark {
  border-bottom: 2px groove var(--vert_dark);
}

.border-bottom-2-ridge-vert_dark {
  border-bottom: 2px ridge var(--vert_dark);
}

.border-bottom-2-inset-vert_dark {
  border-bottom: 2px inset var(--vert_dark);
}

.border-bottom-2-outset-vert_dark {
  border-bottom: 2px outset var(--vert_dark);
}

.border-bottom-2-none-vert_dark {
  border-bottom: 2px none var(--vert_dark);
}

.border-bottom-2-hidden-vert_dark {
  border-bottom: 2px hidden var(--vert_dark);
}

.border-bottom-2-solid-bleu_turquoise_light {
  border-bottom: 2px solid var(--bleu_turquoise_light);
}

.border-bottom-2-dashed-bleu_turquoise_light {
  border-bottom: 2px dashed var(--bleu_turquoise_light);
}

.border-bottom-2-dotted-bleu_turquoise_light {
  border-bottom: 2px dotted var(--bleu_turquoise_light);
}

.border-bottom-2-double-bleu_turquoise_light {
  border-bottom: 2px double var(--bleu_turquoise_light);
}

.border-bottom-2-groove-bleu_turquoise_light {
  border-bottom: 2px groove var(--bleu_turquoise_light);
}

.border-bottom-2-ridge-bleu_turquoise_light {
  border-bottom: 2px ridge var(--bleu_turquoise_light);
}

.border-bottom-2-inset-bleu_turquoise_light {
  border-bottom: 2px inset var(--bleu_turquoise_light);
}

.border-bottom-2-outset-bleu_turquoise_light {
  border-bottom: 2px outset var(--bleu_turquoise_light);
}

.border-bottom-2-none-bleu_turquoise_light {
  border-bottom: 2px none var(--bleu_turquoise_light);
}

.border-bottom-2-hidden-bleu_turquoise_light {
  border-bottom: 2px hidden var(--bleu_turquoise_light);
}

.border-bottom-2-solid-bleu_turquoise_middle {
  border-bottom: 2px solid var(--bleu_turquoise_middle);
}

.border-bottom-2-dashed-bleu_turquoise_middle {
  border-bottom: 2px dashed var(--bleu_turquoise_middle);
}

.border-bottom-2-dotted-bleu_turquoise_middle {
  border-bottom: 2px dotted var(--bleu_turquoise_middle);
}

.border-bottom-2-double-bleu_turquoise_middle {
  border-bottom: 2px double var(--bleu_turquoise_middle);
}

.border-bottom-2-groove-bleu_turquoise_middle {
  border-bottom: 2px groove var(--bleu_turquoise_middle);
}

.border-bottom-2-ridge-bleu_turquoise_middle {
  border-bottom: 2px ridge var(--bleu_turquoise_middle);
}

.border-bottom-2-inset-bleu_turquoise_middle {
  border-bottom: 2px inset var(--bleu_turquoise_middle);
}

.border-bottom-2-outset-bleu_turquoise_middle {
  border-bottom: 2px outset var(--bleu_turquoise_middle);
}

.border-bottom-2-none-bleu_turquoise_middle {
  border-bottom: 2px none var(--bleu_turquoise_middle);
}

.border-bottom-2-hidden-bleu_turquoise_middle {
  border-bottom: 2px hidden var(--bleu_turquoise_middle);
}

.border-bottom-2-solid-bleu_turquoise_dark {
  border-bottom: 2px solid var(--bleu_turquoise_dark);
}

.border-bottom-2-dashed-bleu_turquoise_dark {
  border-bottom: 2px dashed var(--bleu_turquoise_dark);
}

.border-bottom-2-dotted-bleu_turquoise_dark {
  border-bottom: 2px dotted var(--bleu_turquoise_dark);
}

.border-bottom-2-double-bleu_turquoise_dark {
  border-bottom: 2px double var(--bleu_turquoise_dark);
}

.border-bottom-2-groove-bleu_turquoise_dark {
  border-bottom: 2px groove var(--bleu_turquoise_dark);
}

.border-bottom-2-ridge-bleu_turquoise_dark {
  border-bottom: 2px ridge var(--bleu_turquoise_dark);
}

.border-bottom-2-inset-bleu_turquoise_dark {
  border-bottom: 2px inset var(--bleu_turquoise_dark);
}

.border-bottom-2-outset-bleu_turquoise_dark {
  border-bottom: 2px outset var(--bleu_turquoise_dark);
}

.border-bottom-2-none-bleu_turquoise_dark {
  border-bottom: 2px none var(--bleu_turquoise_dark);
}

.border-bottom-2-hidden-bleu_turquoise_dark {
  border-bottom: 2px hidden var(--bleu_turquoise_dark);
}

.border-bottom-2-solid-bleu_light {
  border-bottom: 2px solid var(--bleu_light);
}

.border-bottom-2-dashed-bleu_light {
  border-bottom: 2px dashed var(--bleu_light);
}

.border-bottom-2-dotted-bleu_light {
  border-bottom: 2px dotted var(--bleu_light);
}

.border-bottom-2-double-bleu_light {
  border-bottom: 2px double var(--bleu_light);
}

.border-bottom-2-groove-bleu_light {
  border-bottom: 2px groove var(--bleu_light);
}

.border-bottom-2-ridge-bleu_light {
  border-bottom: 2px ridge var(--bleu_light);
}

.border-bottom-2-inset-bleu_light {
  border-bottom: 2px inset var(--bleu_light);
}

.border-bottom-2-outset-bleu_light {
  border-bottom: 2px outset var(--bleu_light);
}

.border-bottom-2-none-bleu_light {
  border-bottom: 2px none var(--bleu_light);
}

.border-bottom-2-hidden-bleu_light {
  border-bottom: 2px hidden var(--bleu_light);
}

.border-bottom-2-solid-bleu_dark {
  border-bottom: 2px solid var(--bleu_dark);
}

.border-bottom-2-dashed-bleu_dark {
  border-bottom: 2px dashed var(--bleu_dark);
}

.border-bottom-2-dotted-bleu_dark {
  border-bottom: 2px dotted var(--bleu_dark);
}

.border-bottom-2-double-bleu_dark {
  border-bottom: 2px double var(--bleu_dark);
}

.border-bottom-2-groove-bleu_dark {
  border-bottom: 2px groove var(--bleu_dark);
}

.border-bottom-2-ridge-bleu_dark {
  border-bottom: 2px ridge var(--bleu_dark);
}

.border-bottom-2-inset-bleu_dark {
  border-bottom: 2px inset var(--bleu_dark);
}

.border-bottom-2-outset-bleu_dark {
  border-bottom: 2px outset var(--bleu_dark);
}

.border-bottom-2-none-bleu_dark {
  border-bottom: 2px none var(--bleu_dark);
}

.border-bottom-2-hidden-bleu_dark {
  border-bottom: 2px hidden var(--bleu_dark);
}

.border-bottom-2-solid-bleu_klein_light {
  border-bottom: 2px solid var(--bleu_klein_light);
}

.border-bottom-2-dashed-bleu_klein_light {
  border-bottom: 2px dashed var(--bleu_klein_light);
}

.border-bottom-2-dotted-bleu_klein_light {
  border-bottom: 2px dotted var(--bleu_klein_light);
}

.border-bottom-2-double-bleu_klein_light {
  border-bottom: 2px double var(--bleu_klein_light);
}

.border-bottom-2-groove-bleu_klein_light {
  border-bottom: 2px groove var(--bleu_klein_light);
}

.border-bottom-2-ridge-bleu_klein_light {
  border-bottom: 2px ridge var(--bleu_klein_light);
}

.border-bottom-2-inset-bleu_klein_light {
  border-bottom: 2px inset var(--bleu_klein_light);
}

.border-bottom-2-outset-bleu_klein_light {
  border-bottom: 2px outset var(--bleu_klein_light);
}

.border-bottom-2-none-bleu_klein_light {
  border-bottom: 2px none var(--bleu_klein_light);
}

.border-bottom-2-hidden-bleu_klein_light {
  border-bottom: 2px hidden var(--bleu_klein_light);
}

.border-bottom-2-solid-bleu_klein_dark {
  border-bottom: 2px solid var(--bleu_klein_dark);
}

.border-bottom-2-dashed-bleu_klein_dark {
  border-bottom: 2px dashed var(--bleu_klein_dark);
}

.border-bottom-2-dotted-bleu_klein_dark {
  border-bottom: 2px dotted var(--bleu_klein_dark);
}

.border-bottom-2-double-bleu_klein_dark {
  border-bottom: 2px double var(--bleu_klein_dark);
}

.border-bottom-2-groove-bleu_klein_dark {
  border-bottom: 2px groove var(--bleu_klein_dark);
}

.border-bottom-2-ridge-bleu_klein_dark {
  border-bottom: 2px ridge var(--bleu_klein_dark);
}

.border-bottom-2-inset-bleu_klein_dark {
  border-bottom: 2px inset var(--bleu_klein_dark);
}

.border-bottom-2-outset-bleu_klein_dark {
  border-bottom: 2px outset var(--bleu_klein_dark);
}

.border-bottom-2-none-bleu_klein_dark {
  border-bottom: 2px none var(--bleu_klein_dark);
}

.border-bottom-2-hidden-bleu_klein_dark {
  border-bottom: 2px hidden var(--bleu_klein_dark);
}

.border-bottom-2-solid-violet_light {
  border-bottom: 2px solid var(--violet_light);
}

.border-bottom-2-dashed-violet_light {
  border-bottom: 2px dashed var(--violet_light);
}

.border-bottom-2-dotted-violet_light {
  border-bottom: 2px dotted var(--violet_light);
}

.border-bottom-2-double-violet_light {
  border-bottom: 2px double var(--violet_light);
}

.border-bottom-2-groove-violet_light {
  border-bottom: 2px groove var(--violet_light);
}

.border-bottom-2-ridge-violet_light {
  border-bottom: 2px ridge var(--violet_light);
}

.border-bottom-2-inset-violet_light {
  border-bottom: 2px inset var(--violet_light);
}

.border-bottom-2-outset-violet_light {
  border-bottom: 2px outset var(--violet_light);
}

.border-bottom-2-none-violet_light {
  border-bottom: 2px none var(--violet_light);
}

.border-bottom-2-hidden-violet_light {
  border-bottom: 2px hidden var(--violet_light);
}

.border-bottom-2-solid-violet_dark {
  border-bottom: 2px solid var(--violet_dark);
}

.border-bottom-2-dashed-violet_dark {
  border-bottom: 2px dashed var(--violet_dark);
}

.border-bottom-2-dotted-violet_dark {
  border-bottom: 2px dotted var(--violet_dark);
}

.border-bottom-2-double-violet_dark {
  border-bottom: 2px double var(--violet_dark);
}

.border-bottom-2-groove-violet_dark {
  border-bottom: 2px groove var(--violet_dark);
}

.border-bottom-2-ridge-violet_dark {
  border-bottom: 2px ridge var(--violet_dark);
}

.border-bottom-2-inset-violet_dark {
  border-bottom: 2px inset var(--violet_dark);
}

.border-bottom-2-outset-violet_dark {
  border-bottom: 2px outset var(--violet_dark);
}

.border-bottom-2-none-violet_dark {
  border-bottom: 2px none var(--violet_dark);
}

.border-bottom-2-hidden-violet_dark {
  border-bottom: 2px hidden var(--violet_dark);
}

.border-bottom-2-solid-mauve {
  border-bottom: 2px solid var(--mauve);
}

.border-bottom-2-dashed-mauve {
  border-bottom: 2px dashed var(--mauve);
}

.border-bottom-2-dotted-mauve {
  border-bottom: 2px dotted var(--mauve);
}

.border-bottom-2-double-mauve {
  border-bottom: 2px double var(--mauve);
}

.border-bottom-2-groove-mauve {
  border-bottom: 2px groove var(--mauve);
}

.border-bottom-2-ridge-mauve {
  border-bottom: 2px ridge var(--mauve);
}

.border-bottom-2-inset-mauve {
  border-bottom: 2px inset var(--mauve);
}

.border-bottom-2-outset-mauve {
  border-bottom: 2px outset var(--mauve);
}

.border-bottom-2-none-mauve {
  border-bottom: 2px none var(--mauve);
}

.border-bottom-2-hidden-mauve {
  border-bottom: 2px hidden var(--mauve);
}

.border-bottom-2-solid-rose_light {
  border-bottom: 2px solid var(--rose_light);
}

.border-bottom-2-dashed-rose_light {
  border-bottom: 2px dashed var(--rose_light);
}

.border-bottom-2-dotted-rose_light {
  border-bottom: 2px dotted var(--rose_light);
}

.border-bottom-2-double-rose_light {
  border-bottom: 2px double var(--rose_light);
}

.border-bottom-2-groove-rose_light {
  border-bottom: 2px groove var(--rose_light);
}

.border-bottom-2-ridge-rose_light {
  border-bottom: 2px ridge var(--rose_light);
}

.border-bottom-2-inset-rose_light {
  border-bottom: 2px inset var(--rose_light);
}

.border-bottom-2-outset-rose_light {
  border-bottom: 2px outset var(--rose_light);
}

.border-bottom-2-none-rose_light {
  border-bottom: 2px none var(--rose_light);
}

.border-bottom-2-hidden-rose_light {
  border-bottom: 2px hidden var(--rose_light);
}

.border-bottom-2-solid-rose_dark {
  border-bottom: 2px solid var(--rose_dark);
}

.border-bottom-2-dashed-rose_dark {
  border-bottom: 2px dashed var(--rose_dark);
}

.border-bottom-2-dotted-rose_dark {
  border-bottom: 2px dotted var(--rose_dark);
}

.border-bottom-2-double-rose_dark {
  border-bottom: 2px double var(--rose_dark);
}

.border-bottom-2-groove-rose_dark {
  border-bottom: 2px groove var(--rose_dark);
}

.border-bottom-2-ridge-rose_dark {
  border-bottom: 2px ridge var(--rose_dark);
}

.border-bottom-2-inset-rose_dark {
  border-bottom: 2px inset var(--rose_dark);
}

.border-bottom-2-outset-rose_dark {
  border-bottom: 2px outset var(--rose_dark);
}

.border-bottom-2-none-rose_dark {
  border-bottom: 2px none var(--rose_dark);
}

.border-bottom-2-hidden-rose_dark {
  border-bottom: 2px hidden var(--rose_dark);
}

.border-bottom-2-solid-rose_fushia {
  border-bottom: 2px solid var(--rose_fushia);
}

.border-bottom-2-dashed-rose_fushia {
  border-bottom: 2px dashed var(--rose_fushia);
}

.border-bottom-2-dotted-rose_fushia {
  border-bottom: 2px dotted var(--rose_fushia);
}

.border-bottom-2-double-rose_fushia {
  border-bottom: 2px double var(--rose_fushia);
}

.border-bottom-2-groove-rose_fushia {
  border-bottom: 2px groove var(--rose_fushia);
}

.border-bottom-2-ridge-rose_fushia {
  border-bottom: 2px ridge var(--rose_fushia);
}

.border-bottom-2-inset-rose_fushia {
  border-bottom: 2px inset var(--rose_fushia);
}

.border-bottom-2-outset-rose_fushia {
  border-bottom: 2px outset var(--rose_fushia);
}

.border-bottom-2-none-rose_fushia {
  border-bottom: 2px none var(--rose_fushia);
}

.border-bottom-2-hidden-rose_fushia {
  border-bottom: 2px hidden var(--rose_fushia);
}

.border-bottom-2-solid-rouge_framboise {
  border-bottom: 2px solid var(--rouge_framboise);
}

.border-bottom-2-dashed-rouge_framboise {
  border-bottom: 2px dashed var(--rouge_framboise);
}

.border-bottom-2-dotted-rouge_framboise {
  border-bottom: 2px dotted var(--rouge_framboise);
}

.border-bottom-2-double-rouge_framboise {
  border-bottom: 2px double var(--rouge_framboise);
}

.border-bottom-2-groove-rouge_framboise {
  border-bottom: 2px groove var(--rouge_framboise);
}

.border-bottom-2-ridge-rouge_framboise {
  border-bottom: 2px ridge var(--rouge_framboise);
}

.border-bottom-2-inset-rouge_framboise {
  border-bottom: 2px inset var(--rouge_framboise);
}

.border-bottom-2-outset-rouge_framboise {
  border-bottom: 2px outset var(--rouge_framboise);
}

.border-bottom-2-none-rouge_framboise {
  border-bottom: 2px none var(--rouge_framboise);
}

.border-bottom-2-hidden-rouge_framboise {
  border-bottom: 2px hidden var(--rouge_framboise);
}

.border-bottom-2-solid-rose_chair_light {
  border-bottom: 2px solid var(--rose_chair_light);
}

.border-bottom-2-dashed-rose_chair_light {
  border-bottom: 2px dashed var(--rose_chair_light);
}

.border-bottom-2-dotted-rose_chair_light {
  border-bottom: 2px dotted var(--rose_chair_light);
}

.border-bottom-2-double-rose_chair_light {
  border-bottom: 2px double var(--rose_chair_light);
}

.border-bottom-2-groove-rose_chair_light {
  border-bottom: 2px groove var(--rose_chair_light);
}

.border-bottom-2-ridge-rose_chair_light {
  border-bottom: 2px ridge var(--rose_chair_light);
}

.border-bottom-2-inset-rose_chair_light {
  border-bottom: 2px inset var(--rose_chair_light);
}

.border-bottom-2-outset-rose_chair_light {
  border-bottom: 2px outset var(--rose_chair_light);
}

.border-bottom-2-none-rose_chair_light {
  border-bottom: 2px none var(--rose_chair_light);
}

.border-bottom-2-hidden-rose_chair_light {
  border-bottom: 2px hidden var(--rose_chair_light);
}

.border-bottom-2-solid-rose_chair_dark {
  border-bottom: 2px solid var(--rose_chair_dark);
}

.border-bottom-2-dashed-rose_chair_dark {
  border-bottom: 2px dashed var(--rose_chair_dark);
}

.border-bottom-2-dotted-rose_chair_dark {
  border-bottom: 2px dotted var(--rose_chair_dark);
}

.border-bottom-2-double-rose_chair_dark {
  border-bottom: 2px double var(--rose_chair_dark);
}

.border-bottom-2-groove-rose_chair_dark {
  border-bottom: 2px groove var(--rose_chair_dark);
}

.border-bottom-2-ridge-rose_chair_dark {
  border-bottom: 2px ridge var(--rose_chair_dark);
}

.border-bottom-2-inset-rose_chair_dark {
  border-bottom: 2px inset var(--rose_chair_dark);
}

.border-bottom-2-outset-rose_chair_dark {
  border-bottom: 2px outset var(--rose_chair_dark);
}

.border-bottom-2-none-rose_chair_dark {
  border-bottom: 2px none var(--rose_chair_dark);
}

.border-bottom-2-hidden-rose_chair_dark {
  border-bottom: 2px hidden var(--rose_chair_dark);
}

.border-bottom-2-solid-vert_positif {
  border-bottom: 2px solid var(--vert_positif);
}

.border-bottom-2-dashed-vert_positif {
  border-bottom: 2px dashed var(--vert_positif);
}

.border-bottom-2-dotted-vert_positif {
  border-bottom: 2px dotted var(--vert_positif);
}

.border-bottom-2-double-vert_positif {
  border-bottom: 2px double var(--vert_positif);
}

.border-bottom-2-groove-vert_positif {
  border-bottom: 2px groove var(--vert_positif);
}

.border-bottom-2-ridge-vert_positif {
  border-bottom: 2px ridge var(--vert_positif);
}

.border-bottom-2-inset-vert_positif {
  border-bottom: 2px inset var(--vert_positif);
}

.border-bottom-2-outset-vert_positif {
  border-bottom: 2px outset var(--vert_positif);
}

.border-bottom-2-none-vert_positif {
  border-bottom: 2px none var(--vert_positif);
}

.border-bottom-2-hidden-vert_positif {
  border-bottom: 2px hidden var(--vert_positif);
}

.border-bottom-2-solid-rouge_negatif {
  border-bottom: 2px solid var(--rouge_negatif);
}

.border-bottom-2-dashed-rouge_negatif {
  border-bottom: 2px dashed var(--rouge_negatif);
}

.border-bottom-2-dotted-rouge_negatif {
  border-bottom: 2px dotted var(--rouge_negatif);
}

.border-bottom-2-double-rouge_negatif {
  border-bottom: 2px double var(--rouge_negatif);
}

.border-bottom-2-groove-rouge_negatif {
  border-bottom: 2px groove var(--rouge_negatif);
}

.border-bottom-2-ridge-rouge_negatif {
  border-bottom: 2px ridge var(--rouge_negatif);
}

.border-bottom-2-inset-rouge_negatif {
  border-bottom: 2px inset var(--rouge_negatif);
}

.border-bottom-2-outset-rouge_negatif {
  border-bottom: 2px outset var(--rouge_negatif);
}

.border-bottom-2-none-rouge_negatif {
  border-bottom: 2px none var(--rouge_negatif);
}

.border-bottom-2-hidden-rouge_negatif {
  border-bottom: 2px hidden var(--rouge_negatif);
}

.border-bottom-2-solid-bleu_texte {
  border-bottom: 2px solid var(--bleu_texte);
}

.border-bottom-2-dashed-bleu_texte {
  border-bottom: 2px dashed var(--bleu_texte);
}

.border-bottom-2-dotted-bleu_texte {
  border-bottom: 2px dotted var(--bleu_texte);
}

.border-bottom-2-double-bleu_texte {
  border-bottom: 2px double var(--bleu_texte);
}

.border-bottom-2-groove-bleu_texte {
  border-bottom: 2px groove var(--bleu_texte);
}

.border-bottom-2-ridge-bleu_texte {
  border-bottom: 2px ridge var(--bleu_texte);
}

.border-bottom-2-inset-bleu_texte {
  border-bottom: 2px inset var(--bleu_texte);
}

.border-bottom-2-outset-bleu_texte {
  border-bottom: 2px outset var(--bleu_texte);
}

.border-bottom-2-none-bleu_texte {
  border-bottom: 2px none var(--bleu_texte);
}

.border-bottom-2-hidden-bleu_texte {
  border-bottom: 2px hidden var(--bleu_texte);
}

.border-bottom-2-solid-alerte_vert_positif {
  border-bottom: 2px solid var(--alerte_vert_positif);
}

.border-bottom-2-dashed-alerte_vert_positif {
  border-bottom: 2px dashed var(--alerte_vert_positif);
}

.border-bottom-2-dotted-alerte_vert_positif {
  border-bottom: 2px dotted var(--alerte_vert_positif);
}

.border-bottom-2-double-alerte_vert_positif {
  border-bottom: 2px double var(--alerte_vert_positif);
}

.border-bottom-2-groove-alerte_vert_positif {
  border-bottom: 2px groove var(--alerte_vert_positif);
}

.border-bottom-2-ridge-alerte_vert_positif {
  border-bottom: 2px ridge var(--alerte_vert_positif);
}

.border-bottom-2-inset-alerte_vert_positif {
  border-bottom: 2px inset var(--alerte_vert_positif);
}

.border-bottom-2-outset-alerte_vert_positif {
  border-bottom: 2px outset var(--alerte_vert_positif);
}

.border-bottom-2-none-alerte_vert_positif {
  border-bottom: 2px none var(--alerte_vert_positif);
}

.border-bottom-2-hidden-alerte_vert_positif {
  border-bottom: 2px hidden var(--alerte_vert_positif);
}

.border-bottom-2-solid-alerte_rouge_negatif {
  border-bottom: 2px solid var(--alerte_rouge_negatif);
}

.border-bottom-2-dashed-alerte_rouge_negatif {
  border-bottom: 2px dashed var(--alerte_rouge_negatif);
}

.border-bottom-2-dotted-alerte_rouge_negatif {
  border-bottom: 2px dotted var(--alerte_rouge_negatif);
}

.border-bottom-2-double-alerte_rouge_negatif {
  border-bottom: 2px double var(--alerte_rouge_negatif);
}

.border-bottom-2-groove-alerte_rouge_negatif {
  border-bottom: 2px groove var(--alerte_rouge_negatif);
}

.border-bottom-2-ridge-alerte_rouge_negatif {
  border-bottom: 2px ridge var(--alerte_rouge_negatif);
}

.border-bottom-2-inset-alerte_rouge_negatif {
  border-bottom: 2px inset var(--alerte_rouge_negatif);
}

.border-bottom-2-outset-alerte_rouge_negatif {
  border-bottom: 2px outset var(--alerte_rouge_negatif);
}

.border-bottom-2-none-alerte_rouge_negatif {
  border-bottom: 2px none var(--alerte_rouge_negatif);
}

.border-bottom-2-hidden-alerte_rouge_negatif {
  border-bottom: 2px hidden var(--alerte_rouge_negatif);
}

.border-bottom-2-solid-alerte_bleu_information {
  border-bottom: 2px solid var(--alerte_bleu_information);
}

.border-bottom-2-dashed-alerte_bleu_information {
  border-bottom: 2px dashed var(--alerte_bleu_information);
}

.border-bottom-2-dotted-alerte_bleu_information {
  border-bottom: 2px dotted var(--alerte_bleu_information);
}

.border-bottom-2-double-alerte_bleu_information {
  border-bottom: 2px double var(--alerte_bleu_information);
}

.border-bottom-2-groove-alerte_bleu_information {
  border-bottom: 2px groove var(--alerte_bleu_information);
}

.border-bottom-2-ridge-alerte_bleu_information {
  border-bottom: 2px ridge var(--alerte_bleu_information);
}

.border-bottom-2-inset-alerte_bleu_information {
  border-bottom: 2px inset var(--alerte_bleu_information);
}

.border-bottom-2-outset-alerte_bleu_information {
  border-bottom: 2px outset var(--alerte_bleu_information);
}

.border-bottom-2-none-alerte_bleu_information {
  border-bottom: 2px none var(--alerte_bleu_information);
}

.border-bottom-2-hidden-alerte_bleu_information {
  border-bottom: 2px hidden var(--alerte_bleu_information);
}

.border-bottom-2-solid-alerte_jaune_attention {
  border-bottom: 2px solid var(--alerte_jaune_attention);
}

.border-bottom-2-dashed-alerte_jaune_attention {
  border-bottom: 2px dashed var(--alerte_jaune_attention);
}

.border-bottom-2-dotted-alerte_jaune_attention {
  border-bottom: 2px dotted var(--alerte_jaune_attention);
}

.border-bottom-2-double-alerte_jaune_attention {
  border-bottom: 2px double var(--alerte_jaune_attention);
}

.border-bottom-2-groove-alerte_jaune_attention {
  border-bottom: 2px groove var(--alerte_jaune_attention);
}

.border-bottom-2-ridge-alerte_jaune_attention {
  border-bottom: 2px ridge var(--alerte_jaune_attention);
}

.border-bottom-2-inset-alerte_jaune_attention {
  border-bottom: 2px inset var(--alerte_jaune_attention);
}

.border-bottom-2-outset-alerte_jaune_attention {
  border-bottom: 2px outset var(--alerte_jaune_attention);
}

.border-bottom-2-none-alerte_jaune_attention {
  border-bottom: 2px none var(--alerte_jaune_attention);
}

.border-bottom-2-hidden-alerte_jaune_attention {
  border-bottom: 2px hidden var(--alerte_jaune_attention);
}

.border-bottom-2-solid-blanc {
  border-bottom: 2px solid #fff;
}

.border-bottom-2-dashed-blanc {
  border-bottom: 2px dashed #fff;
}

.border-bottom-2-dotted-blanc {
  border-bottom: 2px dotted #fff;
}

.border-bottom-2-double-blanc {
  border-bottom: 2px double #fff;
}

.border-bottom-2-groove-blanc {
  border-bottom: 2px groove #fff;
}

.border-bottom-2-ridge-blanc {
  border-bottom: 2px ridge #fff;
}

.border-bottom-2-inset-blanc {
  border-bottom: 2px inset #fff;
}

.border-bottom-2-outset-blanc {
  border-bottom: 2px outset #fff;
}

.border-bottom-2-none-blanc {
  border-bottom: 2px #fff;
}

.border-bottom-2-hidden-blanc {
  border-bottom: 2px hidden #fff;
}

.border-bottom-2-solid-noir {
  border-bottom: 2px solid #222;
}

.border-bottom-2-dashed-noir {
  border-bottom: 2px dashed #222;
}

.border-bottom-2-dotted-noir {
  border-bottom: 2px dotted #222;
}

.border-bottom-2-double-noir {
  border-bottom: 2px double #222;
}

.border-bottom-2-groove-noir {
  border-bottom: 2px groove #222;
}

.border-bottom-2-ridge-noir {
  border-bottom: 2px ridge #222;
}

.border-bottom-2-inset-noir {
  border-bottom: 2px inset #222;
}

.border-bottom-2-outset-noir {
  border-bottom: 2px outset #222;
}

.border-bottom-2-none-noir {
  border-bottom: 2px #222;
}

.border-bottom-2-hidden-noir {
  border-bottom: 2px hidden #222;
}

.border-bottom-2-solid-gris_light {
  border-bottom: 2px solid var(--gris_light);
}

.border-bottom-2-dashed-gris_light {
  border-bottom: 2px dashed var(--gris_light);
}

.border-bottom-2-dotted-gris_light {
  border-bottom: 2px dotted var(--gris_light);
}

.border-bottom-2-double-gris_light {
  border-bottom: 2px double var(--gris_light);
}

.border-bottom-2-groove-gris_light {
  border-bottom: 2px groove var(--gris_light);
}

.border-bottom-2-ridge-gris_light {
  border-bottom: 2px ridge var(--gris_light);
}

.border-bottom-2-inset-gris_light {
  border-bottom: 2px inset var(--gris_light);
}

.border-bottom-2-outset-gris_light {
  border-bottom: 2px outset var(--gris_light);
}

.border-bottom-2-none-gris_light {
  border-bottom: 2px none var(--gris_light);
}

.border-bottom-2-hidden-gris_light {
  border-bottom: 2px hidden var(--gris_light);
}

.border-bottom-2-solid-gris_dark {
  border-bottom: 2px solid var(--gris_dark);
}

.border-bottom-2-dashed-gris_dark {
  border-bottom: 2px dashed var(--gris_dark);
}

.border-bottom-2-dotted-gris_dark {
  border-bottom: 2px dotted var(--gris_dark);
}

.border-bottom-2-double-gris_dark {
  border-bottom: 2px double var(--gris_dark);
}

.border-bottom-2-groove-gris_dark {
  border-bottom: 2px groove var(--gris_dark);
}

.border-bottom-2-ridge-gris_dark {
  border-bottom: 2px ridge var(--gris_dark);
}

.border-bottom-2-inset-gris_dark {
  border-bottom: 2px inset var(--gris_dark);
}

.border-bottom-2-outset-gris_dark {
  border-bottom: 2px outset var(--gris_dark);
}

.border-bottom-2-none-gris_dark {
  border-bottom: 2px none var(--gris_dark);
}

.border-bottom-2-hidden-gris_dark {
  border-bottom: 2px hidden var(--gris_dark);
}

.border-bottom-2-solid-gris_background {
  border-bottom: 2px solid var(--gris_background);
}

.border-bottom-2-dashed-gris_background {
  border-bottom: 2px dashed var(--gris_background);
}

.border-bottom-2-dotted-gris_background {
  border-bottom: 2px dotted var(--gris_background);
}

.border-bottom-2-double-gris_background {
  border-bottom: 2px double var(--gris_background);
}

.border-bottom-2-groove-gris_background {
  border-bottom: 2px groove var(--gris_background);
}

.border-bottom-2-ridge-gris_background {
  border-bottom: 2px ridge var(--gris_background);
}

.border-bottom-2-inset-gris_background {
  border-bottom: 2px inset var(--gris_background);
}

.border-bottom-2-outset-gris_background {
  border-bottom: 2px outset var(--gris_background);
}

.border-bottom-2-none-gris_background {
  border-bottom: 2px none var(--gris_background);
}

.border-bottom-2-hidden-gris_background {
  border-bottom: 2px hidden var(--gris_background);
}

.border-right-2-solid-corail_mh_brand {
  border-right: 2px solid var(--corail_mh_brand);
}

.border-right-2-dashed-corail_mh_brand {
  border-right: 2px dashed var(--corail_mh_brand);
}

.border-right-2-dotted-corail_mh_brand {
  border-right: 2px dotted var(--corail_mh_brand);
}

.border-right-2-double-corail_mh_brand {
  border-right: 2px double var(--corail_mh_brand);
}

.border-right-2-groove-corail_mh_brand {
  border-right: 2px groove var(--corail_mh_brand);
}

.border-right-2-ridge-corail_mh_brand {
  border-right: 2px ridge var(--corail_mh_brand);
}

.border-right-2-inset-corail_mh_brand {
  border-right: 2px inset var(--corail_mh_brand);
}

.border-right-2-outset-corail_mh_brand {
  border-right: 2px outset var(--corail_mh_brand);
}

.border-right-2-none-corail_mh_brand {
  border-right: 2px none var(--corail_mh_brand);
}

.border-right-2-hidden-corail_mh_brand {
  border-right: 2px hidden var(--corail_mh_brand);
}

.border-right-2-solid-corail_mh_dark {
  border-right: 2px solid var(--corail_mh_dark);
}

.border-right-2-dashed-corail_mh_dark {
  border-right: 2px dashed var(--corail_mh_dark);
}

.border-right-2-dotted-corail_mh_dark {
  border-right: 2px dotted var(--corail_mh_dark);
}

.border-right-2-double-corail_mh_dark {
  border-right: 2px double var(--corail_mh_dark);
}

.border-right-2-groove-corail_mh_dark {
  border-right: 2px groove var(--corail_mh_dark);
}

.border-right-2-ridge-corail_mh_dark {
  border-right: 2px ridge var(--corail_mh_dark);
}

.border-right-2-inset-corail_mh_dark {
  border-right: 2px inset var(--corail_mh_dark);
}

.border-right-2-outset-corail_mh_dark {
  border-right: 2px outset var(--corail_mh_dark);
}

.border-right-2-none-corail_mh_dark {
  border-right: 2px none var(--corail_mh_dark);
}

.border-right-2-hidden-corail_mh_dark {
  border-right: 2px hidden var(--corail_mh_dark);
}

.border-right-2-solid-corail_mh_light {
  border-right: 2px solid var(--corail_mh_light);
}

.border-right-2-dashed-corail_mh_light {
  border-right: 2px dashed var(--corail_mh_light);
}

.border-right-2-dotted-corail_mh_light {
  border-right: 2px dotted var(--corail_mh_light);
}

.border-right-2-double-corail_mh_light {
  border-right: 2px double var(--corail_mh_light);
}

.border-right-2-groove-corail_mh_light {
  border-right: 2px groove var(--corail_mh_light);
}

.border-right-2-ridge-corail_mh_light {
  border-right: 2px ridge var(--corail_mh_light);
}

.border-right-2-inset-corail_mh_light {
  border-right: 2px inset var(--corail_mh_light);
}

.border-right-2-outset-corail_mh_light {
  border-right: 2px outset var(--corail_mh_light);
}

.border-right-2-none-corail_mh_light {
  border-right: 2px none var(--corail_mh_light);
}

.border-right-2-hidden-corail_mh_light {
  border-right: 2px hidden var(--corail_mh_light);
}

.border-right-2-solid-gris_sable_light {
  border-right: 2px solid var(--gris_sable_light);
}

.border-right-2-dashed-gris_sable_light {
  border-right: 2px dashed var(--gris_sable_light);
}

.border-right-2-dotted-gris_sable_light {
  border-right: 2px dotted var(--gris_sable_light);
}

.border-right-2-double-gris_sable_light {
  border-right: 2px double var(--gris_sable_light);
}

.border-right-2-groove-gris_sable_light {
  border-right: 2px groove var(--gris_sable_light);
}

.border-right-2-ridge-gris_sable_light {
  border-right: 2px ridge var(--gris_sable_light);
}

.border-right-2-inset-gris_sable_light {
  border-right: 2px inset var(--gris_sable_light);
}

.border-right-2-outset-gris_sable_light {
  border-right: 2px outset var(--gris_sable_light);
}

.border-right-2-none-gris_sable_light {
  border-right: 2px none var(--gris_sable_light);
}

.border-right-2-hidden-gris_sable_light {
  border-right: 2px hidden var(--gris_sable_light);
}

.border-right-2-solid-gris_sable_dark {
  border-right: 2px solid var(--gris_sable_dark);
}

.border-right-2-dashed-gris_sable_dark {
  border-right: 2px dashed var(--gris_sable_dark);
}

.border-right-2-dotted-gris_sable_dark {
  border-right: 2px dotted var(--gris_sable_dark);
}

.border-right-2-double-gris_sable_dark {
  border-right: 2px double var(--gris_sable_dark);
}

.border-right-2-groove-gris_sable_dark {
  border-right: 2px groove var(--gris_sable_dark);
}

.border-right-2-ridge-gris_sable_dark {
  border-right: 2px ridge var(--gris_sable_dark);
}

.border-right-2-inset-gris_sable_dark {
  border-right: 2px inset var(--gris_sable_dark);
}

.border-right-2-outset-gris_sable_dark {
  border-right: 2px outset var(--gris_sable_dark);
}

.border-right-2-none-gris_sable_dark {
  border-right: 2px none var(--gris_sable_dark);
}

.border-right-2-hidden-gris_sable_dark {
  border-right: 2px hidden var(--gris_sable_dark);
}

.border-right-2-solid-jaune_ivoire_light {
  border-right: 2px solid var(--jaune_ivoire_light);
}

.border-right-2-dashed-jaune_ivoire_light {
  border-right: 2px dashed var(--jaune_ivoire_light);
}

.border-right-2-dotted-jaune_ivoire_light {
  border-right: 2px dotted var(--jaune_ivoire_light);
}

.border-right-2-double-jaune_ivoire_light {
  border-right: 2px double var(--jaune_ivoire_light);
}

.border-right-2-groove-jaune_ivoire_light {
  border-right: 2px groove var(--jaune_ivoire_light);
}

.border-right-2-ridge-jaune_ivoire_light {
  border-right: 2px ridge var(--jaune_ivoire_light);
}

.border-right-2-inset-jaune_ivoire_light {
  border-right: 2px inset var(--jaune_ivoire_light);
}

.border-right-2-outset-jaune_ivoire_light {
  border-right: 2px outset var(--jaune_ivoire_light);
}

.border-right-2-none-jaune_ivoire_light {
  border-right: 2px none var(--jaune_ivoire_light);
}

.border-right-2-hidden-jaune_ivoire_light {
  border-right: 2px hidden var(--jaune_ivoire_light);
}

.border-right-2-solid-jaune_ivoire_dark {
  border-right: 2px solid var(--jaune_ivoire_dark);
}

.border-right-2-dashed-jaune_ivoire_dark {
  border-right: 2px dashed var(--jaune_ivoire_dark);
}

.border-right-2-dotted-jaune_ivoire_dark {
  border-right: 2px dotted var(--jaune_ivoire_dark);
}

.border-right-2-double-jaune_ivoire_dark {
  border-right: 2px double var(--jaune_ivoire_dark);
}

.border-right-2-groove-jaune_ivoire_dark {
  border-right: 2px groove var(--jaune_ivoire_dark);
}

.border-right-2-ridge-jaune_ivoire_dark {
  border-right: 2px ridge var(--jaune_ivoire_dark);
}

.border-right-2-inset-jaune_ivoire_dark {
  border-right: 2px inset var(--jaune_ivoire_dark);
}

.border-right-2-outset-jaune_ivoire_dark {
  border-right: 2px outset var(--jaune_ivoire_dark);
}

.border-right-2-none-jaune_ivoire_dark {
  border-right: 2px none var(--jaune_ivoire_dark);
}

.border-right-2-hidden-jaune_ivoire_dark {
  border-right: 2px hidden var(--jaune_ivoire_dark);
}

.border-right-2-solid-jaune_light {
  border-right: 2px solid var(--jaune_light);
}

.border-right-2-dashed-jaune_light {
  border-right: 2px dashed var(--jaune_light);
}

.border-right-2-dotted-jaune_light {
  border-right: 2px dotted var(--jaune_light);
}

.border-right-2-double-jaune_light {
  border-right: 2px double var(--jaune_light);
}

.border-right-2-groove-jaune_light {
  border-right: 2px groove var(--jaune_light);
}

.border-right-2-ridge-jaune_light {
  border-right: 2px ridge var(--jaune_light);
}

.border-right-2-inset-jaune_light {
  border-right: 2px inset var(--jaune_light);
}

.border-right-2-outset-jaune_light {
  border-right: 2px outset var(--jaune_light);
}

.border-right-2-none-jaune_light {
  border-right: 2px none var(--jaune_light);
}

.border-right-2-hidden-jaune_light {
  border-right: 2px hidden var(--jaune_light);
}

.border-right-2-solid-jaune_dark {
  border-right: 2px solid var(--jaune_dark);
}

.border-right-2-dashed-jaune_dark {
  border-right: 2px dashed var(--jaune_dark);
}

.border-right-2-dotted-jaune_dark {
  border-right: 2px dotted var(--jaune_dark);
}

.border-right-2-double-jaune_dark {
  border-right: 2px double var(--jaune_dark);
}

.border-right-2-groove-jaune_dark {
  border-right: 2px groove var(--jaune_dark);
}

.border-right-2-ridge-jaune_dark {
  border-right: 2px ridge var(--jaune_dark);
}

.border-right-2-inset-jaune_dark {
  border-right: 2px inset var(--jaune_dark);
}

.border-right-2-outset-jaune_dark {
  border-right: 2px outset var(--jaune_dark);
}

.border-right-2-none-jaune_dark {
  border-right: 2px none var(--jaune_dark);
}

.border-right-2-hidden-jaune_dark {
  border-right: 2px hidden var(--jaune_dark);
}

.border-right-2-solid-orange_normal {
  border-right: 2px solid var(--orange_normal);
}

.border-right-2-dashed-orange_normal {
  border-right: 2px dashed var(--orange_normal);
}

.border-right-2-dotted-orange_normal {
  border-right: 2px dotted var(--orange_normal);
}

.border-right-2-double-orange_normal {
  border-right: 2px double var(--orange_normal);
}

.border-right-2-groove-orange_normal {
  border-right: 2px groove var(--orange_normal);
}

.border-right-2-ridge-orange_normal {
  border-right: 2px ridge var(--orange_normal);
}

.border-right-2-inset-orange_normal {
  border-right: 2px inset var(--orange_normal);
}

.border-right-2-outset-orange_normal {
  border-right: 2px outset var(--orange_normal);
}

.border-right-2-none-orange_normal {
  border-right: 2px none var(--orange_normal);
}

.border-right-2-hidden-orange_normal {
  border-right: 2px hidden var(--orange_normal);
}

.border-right-2-solid-orange_dark {
  border-right: 2px solid var(--orange_dark);
}

.border-right-2-dashed-orange_dark {
  border-right: 2px dashed var(--orange_dark);
}

.border-right-2-dotted-orange_dark {
  border-right: 2px dotted var(--orange_dark);
}

.border-right-2-double-orange_dark {
  border-right: 2px double var(--orange_dark);
}

.border-right-2-groove-orange_dark {
  border-right: 2px groove var(--orange_dark);
}

.border-right-2-ridge-orange_dark {
  border-right: 2px ridge var(--orange_dark);
}

.border-right-2-inset-orange_dark {
  border-right: 2px inset var(--orange_dark);
}

.border-right-2-outset-orange_dark {
  border-right: 2px outset var(--orange_dark);
}

.border-right-2-none-orange_dark {
  border-right: 2px none var(--orange_dark);
}

.border-right-2-hidden-orange_dark {
  border-right: 2px hidden var(--orange_dark);
}

.border-right-2-solid-vert_light {
  border-right: 2px solid var(--vert_light);
}

.border-right-2-dashed-vert_light {
  border-right: 2px dashed var(--vert_light);
}

.border-right-2-dotted-vert_light {
  border-right: 2px dotted var(--vert_light);
}

.border-right-2-double-vert_light {
  border-right: 2px double var(--vert_light);
}

.border-right-2-groove-vert_light {
  border-right: 2px groove var(--vert_light);
}

.border-right-2-ridge-vert_light {
  border-right: 2px ridge var(--vert_light);
}

.border-right-2-inset-vert_light {
  border-right: 2px inset var(--vert_light);
}

.border-right-2-outset-vert_light {
  border-right: 2px outset var(--vert_light);
}

.border-right-2-none-vert_light {
  border-right: 2px none var(--vert_light);
}

.border-right-2-hidden-vert_light {
  border-right: 2px hidden var(--vert_light);
}

.border-right-2-solid-vert_dark {
  border-right: 2px solid var(--vert_dark);
}

.border-right-2-dashed-vert_dark {
  border-right: 2px dashed var(--vert_dark);
}

.border-right-2-dotted-vert_dark {
  border-right: 2px dotted var(--vert_dark);
}

.border-right-2-double-vert_dark {
  border-right: 2px double var(--vert_dark);
}

.border-right-2-groove-vert_dark {
  border-right: 2px groove var(--vert_dark);
}

.border-right-2-ridge-vert_dark {
  border-right: 2px ridge var(--vert_dark);
}

.border-right-2-inset-vert_dark {
  border-right: 2px inset var(--vert_dark);
}

.border-right-2-outset-vert_dark {
  border-right: 2px outset var(--vert_dark);
}

.border-right-2-none-vert_dark {
  border-right: 2px none var(--vert_dark);
}

.border-right-2-hidden-vert_dark {
  border-right: 2px hidden var(--vert_dark);
}

.border-right-2-solid-bleu_turquoise_light {
  border-right: 2px solid var(--bleu_turquoise_light);
}

.border-right-2-dashed-bleu_turquoise_light {
  border-right: 2px dashed var(--bleu_turquoise_light);
}

.border-right-2-dotted-bleu_turquoise_light {
  border-right: 2px dotted var(--bleu_turquoise_light);
}

.border-right-2-double-bleu_turquoise_light {
  border-right: 2px double var(--bleu_turquoise_light);
}

.border-right-2-groove-bleu_turquoise_light {
  border-right: 2px groove var(--bleu_turquoise_light);
}

.border-right-2-ridge-bleu_turquoise_light {
  border-right: 2px ridge var(--bleu_turquoise_light);
}

.border-right-2-inset-bleu_turquoise_light {
  border-right: 2px inset var(--bleu_turquoise_light);
}

.border-right-2-outset-bleu_turquoise_light {
  border-right: 2px outset var(--bleu_turquoise_light);
}

.border-right-2-none-bleu_turquoise_light {
  border-right: 2px none var(--bleu_turquoise_light);
}

.border-right-2-hidden-bleu_turquoise_light {
  border-right: 2px hidden var(--bleu_turquoise_light);
}

.border-right-2-solid-bleu_turquoise_middle {
  border-right: 2px solid var(--bleu_turquoise_middle);
}

.border-right-2-dashed-bleu_turquoise_middle {
  border-right: 2px dashed var(--bleu_turquoise_middle);
}

.border-right-2-dotted-bleu_turquoise_middle {
  border-right: 2px dotted var(--bleu_turquoise_middle);
}

.border-right-2-double-bleu_turquoise_middle {
  border-right: 2px double var(--bleu_turquoise_middle);
}

.border-right-2-groove-bleu_turquoise_middle {
  border-right: 2px groove var(--bleu_turquoise_middle);
}

.border-right-2-ridge-bleu_turquoise_middle {
  border-right: 2px ridge var(--bleu_turquoise_middle);
}

.border-right-2-inset-bleu_turquoise_middle {
  border-right: 2px inset var(--bleu_turquoise_middle);
}

.border-right-2-outset-bleu_turquoise_middle {
  border-right: 2px outset var(--bleu_turquoise_middle);
}

.border-right-2-none-bleu_turquoise_middle {
  border-right: 2px none var(--bleu_turquoise_middle);
}

.border-right-2-hidden-bleu_turquoise_middle {
  border-right: 2px hidden var(--bleu_turquoise_middle);
}

.border-right-2-solid-bleu_turquoise_dark {
  border-right: 2px solid var(--bleu_turquoise_dark);
}

.border-right-2-dashed-bleu_turquoise_dark {
  border-right: 2px dashed var(--bleu_turquoise_dark);
}

.border-right-2-dotted-bleu_turquoise_dark {
  border-right: 2px dotted var(--bleu_turquoise_dark);
}

.border-right-2-double-bleu_turquoise_dark {
  border-right: 2px double var(--bleu_turquoise_dark);
}

.border-right-2-groove-bleu_turquoise_dark {
  border-right: 2px groove var(--bleu_turquoise_dark);
}

.border-right-2-ridge-bleu_turquoise_dark {
  border-right: 2px ridge var(--bleu_turquoise_dark);
}

.border-right-2-inset-bleu_turquoise_dark {
  border-right: 2px inset var(--bleu_turquoise_dark);
}

.border-right-2-outset-bleu_turquoise_dark {
  border-right: 2px outset var(--bleu_turquoise_dark);
}

.border-right-2-none-bleu_turquoise_dark {
  border-right: 2px none var(--bleu_turquoise_dark);
}

.border-right-2-hidden-bleu_turquoise_dark {
  border-right: 2px hidden var(--bleu_turquoise_dark);
}

.border-right-2-solid-bleu_light {
  border-right: 2px solid var(--bleu_light);
}

.border-right-2-dashed-bleu_light {
  border-right: 2px dashed var(--bleu_light);
}

.border-right-2-dotted-bleu_light {
  border-right: 2px dotted var(--bleu_light);
}

.border-right-2-double-bleu_light {
  border-right: 2px double var(--bleu_light);
}

.border-right-2-groove-bleu_light {
  border-right: 2px groove var(--bleu_light);
}

.border-right-2-ridge-bleu_light {
  border-right: 2px ridge var(--bleu_light);
}

.border-right-2-inset-bleu_light {
  border-right: 2px inset var(--bleu_light);
}

.border-right-2-outset-bleu_light {
  border-right: 2px outset var(--bleu_light);
}

.border-right-2-none-bleu_light {
  border-right: 2px none var(--bleu_light);
}

.border-right-2-hidden-bleu_light {
  border-right: 2px hidden var(--bleu_light);
}

.border-right-2-solid-bleu_dark {
  border-right: 2px solid var(--bleu_dark);
}

.border-right-2-dashed-bleu_dark {
  border-right: 2px dashed var(--bleu_dark);
}

.border-right-2-dotted-bleu_dark {
  border-right: 2px dotted var(--bleu_dark);
}

.border-right-2-double-bleu_dark {
  border-right: 2px double var(--bleu_dark);
}

.border-right-2-groove-bleu_dark {
  border-right: 2px groove var(--bleu_dark);
}

.border-right-2-ridge-bleu_dark {
  border-right: 2px ridge var(--bleu_dark);
}

.border-right-2-inset-bleu_dark {
  border-right: 2px inset var(--bleu_dark);
}

.border-right-2-outset-bleu_dark {
  border-right: 2px outset var(--bleu_dark);
}

.border-right-2-none-bleu_dark {
  border-right: 2px none var(--bleu_dark);
}

.border-right-2-hidden-bleu_dark {
  border-right: 2px hidden var(--bleu_dark);
}

.border-right-2-solid-bleu_klein_light {
  border-right: 2px solid var(--bleu_klein_light);
}

.border-right-2-dashed-bleu_klein_light {
  border-right: 2px dashed var(--bleu_klein_light);
}

.border-right-2-dotted-bleu_klein_light {
  border-right: 2px dotted var(--bleu_klein_light);
}

.border-right-2-double-bleu_klein_light {
  border-right: 2px double var(--bleu_klein_light);
}

.border-right-2-groove-bleu_klein_light {
  border-right: 2px groove var(--bleu_klein_light);
}

.border-right-2-ridge-bleu_klein_light {
  border-right: 2px ridge var(--bleu_klein_light);
}

.border-right-2-inset-bleu_klein_light {
  border-right: 2px inset var(--bleu_klein_light);
}

.border-right-2-outset-bleu_klein_light {
  border-right: 2px outset var(--bleu_klein_light);
}

.border-right-2-none-bleu_klein_light {
  border-right: 2px none var(--bleu_klein_light);
}

.border-right-2-hidden-bleu_klein_light {
  border-right: 2px hidden var(--bleu_klein_light);
}

.border-right-2-solid-bleu_klein_dark {
  border-right: 2px solid var(--bleu_klein_dark);
}

.border-right-2-dashed-bleu_klein_dark {
  border-right: 2px dashed var(--bleu_klein_dark);
}

.border-right-2-dotted-bleu_klein_dark {
  border-right: 2px dotted var(--bleu_klein_dark);
}

.border-right-2-double-bleu_klein_dark {
  border-right: 2px double var(--bleu_klein_dark);
}

.border-right-2-groove-bleu_klein_dark {
  border-right: 2px groove var(--bleu_klein_dark);
}

.border-right-2-ridge-bleu_klein_dark {
  border-right: 2px ridge var(--bleu_klein_dark);
}

.border-right-2-inset-bleu_klein_dark {
  border-right: 2px inset var(--bleu_klein_dark);
}

.border-right-2-outset-bleu_klein_dark {
  border-right: 2px outset var(--bleu_klein_dark);
}

.border-right-2-none-bleu_klein_dark {
  border-right: 2px none var(--bleu_klein_dark);
}

.border-right-2-hidden-bleu_klein_dark {
  border-right: 2px hidden var(--bleu_klein_dark);
}

.border-right-2-solid-violet_light {
  border-right: 2px solid var(--violet_light);
}

.border-right-2-dashed-violet_light {
  border-right: 2px dashed var(--violet_light);
}

.border-right-2-dotted-violet_light {
  border-right: 2px dotted var(--violet_light);
}

.border-right-2-double-violet_light {
  border-right: 2px double var(--violet_light);
}

.border-right-2-groove-violet_light {
  border-right: 2px groove var(--violet_light);
}

.border-right-2-ridge-violet_light {
  border-right: 2px ridge var(--violet_light);
}

.border-right-2-inset-violet_light {
  border-right: 2px inset var(--violet_light);
}

.border-right-2-outset-violet_light {
  border-right: 2px outset var(--violet_light);
}

.border-right-2-none-violet_light {
  border-right: 2px none var(--violet_light);
}

.border-right-2-hidden-violet_light {
  border-right: 2px hidden var(--violet_light);
}

.border-right-2-solid-violet_dark {
  border-right: 2px solid var(--violet_dark);
}

.border-right-2-dashed-violet_dark {
  border-right: 2px dashed var(--violet_dark);
}

.border-right-2-dotted-violet_dark {
  border-right: 2px dotted var(--violet_dark);
}

.border-right-2-double-violet_dark {
  border-right: 2px double var(--violet_dark);
}

.border-right-2-groove-violet_dark {
  border-right: 2px groove var(--violet_dark);
}

.border-right-2-ridge-violet_dark {
  border-right: 2px ridge var(--violet_dark);
}

.border-right-2-inset-violet_dark {
  border-right: 2px inset var(--violet_dark);
}

.border-right-2-outset-violet_dark {
  border-right: 2px outset var(--violet_dark);
}

.border-right-2-none-violet_dark {
  border-right: 2px none var(--violet_dark);
}

.border-right-2-hidden-violet_dark {
  border-right: 2px hidden var(--violet_dark);
}

.border-right-2-solid-mauve {
  border-right: 2px solid var(--mauve);
}

.border-right-2-dashed-mauve {
  border-right: 2px dashed var(--mauve);
}

.border-right-2-dotted-mauve {
  border-right: 2px dotted var(--mauve);
}

.border-right-2-double-mauve {
  border-right: 2px double var(--mauve);
}

.border-right-2-groove-mauve {
  border-right: 2px groove var(--mauve);
}

.border-right-2-ridge-mauve {
  border-right: 2px ridge var(--mauve);
}

.border-right-2-inset-mauve {
  border-right: 2px inset var(--mauve);
}

.border-right-2-outset-mauve {
  border-right: 2px outset var(--mauve);
}

.border-right-2-none-mauve {
  border-right: 2px none var(--mauve);
}

.border-right-2-hidden-mauve {
  border-right: 2px hidden var(--mauve);
}

.border-right-2-solid-rose_light {
  border-right: 2px solid var(--rose_light);
}

.border-right-2-dashed-rose_light {
  border-right: 2px dashed var(--rose_light);
}

.border-right-2-dotted-rose_light {
  border-right: 2px dotted var(--rose_light);
}

.border-right-2-double-rose_light {
  border-right: 2px double var(--rose_light);
}

.border-right-2-groove-rose_light {
  border-right: 2px groove var(--rose_light);
}

.border-right-2-ridge-rose_light {
  border-right: 2px ridge var(--rose_light);
}

.border-right-2-inset-rose_light {
  border-right: 2px inset var(--rose_light);
}

.border-right-2-outset-rose_light {
  border-right: 2px outset var(--rose_light);
}

.border-right-2-none-rose_light {
  border-right: 2px none var(--rose_light);
}

.border-right-2-hidden-rose_light {
  border-right: 2px hidden var(--rose_light);
}

.border-right-2-solid-rose_dark {
  border-right: 2px solid var(--rose_dark);
}

.border-right-2-dashed-rose_dark {
  border-right: 2px dashed var(--rose_dark);
}

.border-right-2-dotted-rose_dark {
  border-right: 2px dotted var(--rose_dark);
}

.border-right-2-double-rose_dark {
  border-right: 2px double var(--rose_dark);
}

.border-right-2-groove-rose_dark {
  border-right: 2px groove var(--rose_dark);
}

.border-right-2-ridge-rose_dark {
  border-right: 2px ridge var(--rose_dark);
}

.border-right-2-inset-rose_dark {
  border-right: 2px inset var(--rose_dark);
}

.border-right-2-outset-rose_dark {
  border-right: 2px outset var(--rose_dark);
}

.border-right-2-none-rose_dark {
  border-right: 2px none var(--rose_dark);
}

.border-right-2-hidden-rose_dark {
  border-right: 2px hidden var(--rose_dark);
}

.border-right-2-solid-rose_fushia {
  border-right: 2px solid var(--rose_fushia);
}

.border-right-2-dashed-rose_fushia {
  border-right: 2px dashed var(--rose_fushia);
}

.border-right-2-dotted-rose_fushia {
  border-right: 2px dotted var(--rose_fushia);
}

.border-right-2-double-rose_fushia {
  border-right: 2px double var(--rose_fushia);
}

.border-right-2-groove-rose_fushia {
  border-right: 2px groove var(--rose_fushia);
}

.border-right-2-ridge-rose_fushia {
  border-right: 2px ridge var(--rose_fushia);
}

.border-right-2-inset-rose_fushia {
  border-right: 2px inset var(--rose_fushia);
}

.border-right-2-outset-rose_fushia {
  border-right: 2px outset var(--rose_fushia);
}

.border-right-2-none-rose_fushia {
  border-right: 2px none var(--rose_fushia);
}

.border-right-2-hidden-rose_fushia {
  border-right: 2px hidden var(--rose_fushia);
}

.border-right-2-solid-rouge_framboise {
  border-right: 2px solid var(--rouge_framboise);
}

.border-right-2-dashed-rouge_framboise {
  border-right: 2px dashed var(--rouge_framboise);
}

.border-right-2-dotted-rouge_framboise {
  border-right: 2px dotted var(--rouge_framboise);
}

.border-right-2-double-rouge_framboise {
  border-right: 2px double var(--rouge_framboise);
}

.border-right-2-groove-rouge_framboise {
  border-right: 2px groove var(--rouge_framboise);
}

.border-right-2-ridge-rouge_framboise {
  border-right: 2px ridge var(--rouge_framboise);
}

.border-right-2-inset-rouge_framboise {
  border-right: 2px inset var(--rouge_framboise);
}

.border-right-2-outset-rouge_framboise {
  border-right: 2px outset var(--rouge_framboise);
}

.border-right-2-none-rouge_framboise {
  border-right: 2px none var(--rouge_framboise);
}

.border-right-2-hidden-rouge_framboise {
  border-right: 2px hidden var(--rouge_framboise);
}

.border-right-2-solid-rose_chair_light {
  border-right: 2px solid var(--rose_chair_light);
}

.border-right-2-dashed-rose_chair_light {
  border-right: 2px dashed var(--rose_chair_light);
}

.border-right-2-dotted-rose_chair_light {
  border-right: 2px dotted var(--rose_chair_light);
}

.border-right-2-double-rose_chair_light {
  border-right: 2px double var(--rose_chair_light);
}

.border-right-2-groove-rose_chair_light {
  border-right: 2px groove var(--rose_chair_light);
}

.border-right-2-ridge-rose_chair_light {
  border-right: 2px ridge var(--rose_chair_light);
}

.border-right-2-inset-rose_chair_light {
  border-right: 2px inset var(--rose_chair_light);
}

.border-right-2-outset-rose_chair_light {
  border-right: 2px outset var(--rose_chair_light);
}

.border-right-2-none-rose_chair_light {
  border-right: 2px none var(--rose_chair_light);
}

.border-right-2-hidden-rose_chair_light {
  border-right: 2px hidden var(--rose_chair_light);
}

.border-right-2-solid-rose_chair_dark {
  border-right: 2px solid var(--rose_chair_dark);
}

.border-right-2-dashed-rose_chair_dark {
  border-right: 2px dashed var(--rose_chair_dark);
}

.border-right-2-dotted-rose_chair_dark {
  border-right: 2px dotted var(--rose_chair_dark);
}

.border-right-2-double-rose_chair_dark {
  border-right: 2px double var(--rose_chair_dark);
}

.border-right-2-groove-rose_chair_dark {
  border-right: 2px groove var(--rose_chair_dark);
}

.border-right-2-ridge-rose_chair_dark {
  border-right: 2px ridge var(--rose_chair_dark);
}

.border-right-2-inset-rose_chair_dark {
  border-right: 2px inset var(--rose_chair_dark);
}

.border-right-2-outset-rose_chair_dark {
  border-right: 2px outset var(--rose_chair_dark);
}

.border-right-2-none-rose_chair_dark {
  border-right: 2px none var(--rose_chair_dark);
}

.border-right-2-hidden-rose_chair_dark {
  border-right: 2px hidden var(--rose_chair_dark);
}

.border-right-2-solid-vert_positif {
  border-right: 2px solid var(--vert_positif);
}

.border-right-2-dashed-vert_positif {
  border-right: 2px dashed var(--vert_positif);
}

.border-right-2-dotted-vert_positif {
  border-right: 2px dotted var(--vert_positif);
}

.border-right-2-double-vert_positif {
  border-right: 2px double var(--vert_positif);
}

.border-right-2-groove-vert_positif {
  border-right: 2px groove var(--vert_positif);
}

.border-right-2-ridge-vert_positif {
  border-right: 2px ridge var(--vert_positif);
}

.border-right-2-inset-vert_positif {
  border-right: 2px inset var(--vert_positif);
}

.border-right-2-outset-vert_positif {
  border-right: 2px outset var(--vert_positif);
}

.border-right-2-none-vert_positif {
  border-right: 2px none var(--vert_positif);
}

.border-right-2-hidden-vert_positif {
  border-right: 2px hidden var(--vert_positif);
}

.border-right-2-solid-rouge_negatif {
  border-right: 2px solid var(--rouge_negatif);
}

.border-right-2-dashed-rouge_negatif {
  border-right: 2px dashed var(--rouge_negatif);
}

.border-right-2-dotted-rouge_negatif {
  border-right: 2px dotted var(--rouge_negatif);
}

.border-right-2-double-rouge_negatif {
  border-right: 2px double var(--rouge_negatif);
}

.border-right-2-groove-rouge_negatif {
  border-right: 2px groove var(--rouge_negatif);
}

.border-right-2-ridge-rouge_negatif {
  border-right: 2px ridge var(--rouge_negatif);
}

.border-right-2-inset-rouge_negatif {
  border-right: 2px inset var(--rouge_negatif);
}

.border-right-2-outset-rouge_negatif {
  border-right: 2px outset var(--rouge_negatif);
}

.border-right-2-none-rouge_negatif {
  border-right: 2px none var(--rouge_negatif);
}

.border-right-2-hidden-rouge_negatif {
  border-right: 2px hidden var(--rouge_negatif);
}

.border-right-2-solid-bleu_texte {
  border-right: 2px solid var(--bleu_texte);
}

.border-right-2-dashed-bleu_texte {
  border-right: 2px dashed var(--bleu_texte);
}

.border-right-2-dotted-bleu_texte {
  border-right: 2px dotted var(--bleu_texte);
}

.border-right-2-double-bleu_texte {
  border-right: 2px double var(--bleu_texte);
}

.border-right-2-groove-bleu_texte {
  border-right: 2px groove var(--bleu_texte);
}

.border-right-2-ridge-bleu_texte {
  border-right: 2px ridge var(--bleu_texte);
}

.border-right-2-inset-bleu_texte {
  border-right: 2px inset var(--bleu_texte);
}

.border-right-2-outset-bleu_texte {
  border-right: 2px outset var(--bleu_texte);
}

.border-right-2-none-bleu_texte {
  border-right: 2px none var(--bleu_texte);
}

.border-right-2-hidden-bleu_texte {
  border-right: 2px hidden var(--bleu_texte);
}

.border-right-2-solid-alerte_vert_positif {
  border-right: 2px solid var(--alerte_vert_positif);
}

.border-right-2-dashed-alerte_vert_positif {
  border-right: 2px dashed var(--alerte_vert_positif);
}

.border-right-2-dotted-alerte_vert_positif {
  border-right: 2px dotted var(--alerte_vert_positif);
}

.border-right-2-double-alerte_vert_positif {
  border-right: 2px double var(--alerte_vert_positif);
}

.border-right-2-groove-alerte_vert_positif {
  border-right: 2px groove var(--alerte_vert_positif);
}

.border-right-2-ridge-alerte_vert_positif {
  border-right: 2px ridge var(--alerte_vert_positif);
}

.border-right-2-inset-alerte_vert_positif {
  border-right: 2px inset var(--alerte_vert_positif);
}

.border-right-2-outset-alerte_vert_positif {
  border-right: 2px outset var(--alerte_vert_positif);
}

.border-right-2-none-alerte_vert_positif {
  border-right: 2px none var(--alerte_vert_positif);
}

.border-right-2-hidden-alerte_vert_positif {
  border-right: 2px hidden var(--alerte_vert_positif);
}

.border-right-2-solid-alerte_rouge_negatif {
  border-right: 2px solid var(--alerte_rouge_negatif);
}

.border-right-2-dashed-alerte_rouge_negatif {
  border-right: 2px dashed var(--alerte_rouge_negatif);
}

.border-right-2-dotted-alerte_rouge_negatif {
  border-right: 2px dotted var(--alerte_rouge_negatif);
}

.border-right-2-double-alerte_rouge_negatif {
  border-right: 2px double var(--alerte_rouge_negatif);
}

.border-right-2-groove-alerte_rouge_negatif {
  border-right: 2px groove var(--alerte_rouge_negatif);
}

.border-right-2-ridge-alerte_rouge_negatif {
  border-right: 2px ridge var(--alerte_rouge_negatif);
}

.border-right-2-inset-alerte_rouge_negatif {
  border-right: 2px inset var(--alerte_rouge_negatif);
}

.border-right-2-outset-alerte_rouge_negatif {
  border-right: 2px outset var(--alerte_rouge_negatif);
}

.border-right-2-none-alerte_rouge_negatif {
  border-right: 2px none var(--alerte_rouge_negatif);
}

.border-right-2-hidden-alerte_rouge_negatif {
  border-right: 2px hidden var(--alerte_rouge_negatif);
}

.border-right-2-solid-alerte_bleu_information {
  border-right: 2px solid var(--alerte_bleu_information);
}

.border-right-2-dashed-alerte_bleu_information {
  border-right: 2px dashed var(--alerte_bleu_information);
}

.border-right-2-dotted-alerte_bleu_information {
  border-right: 2px dotted var(--alerte_bleu_information);
}

.border-right-2-double-alerte_bleu_information {
  border-right: 2px double var(--alerte_bleu_information);
}

.border-right-2-groove-alerte_bleu_information {
  border-right: 2px groove var(--alerte_bleu_information);
}

.border-right-2-ridge-alerte_bleu_information {
  border-right: 2px ridge var(--alerte_bleu_information);
}

.border-right-2-inset-alerte_bleu_information {
  border-right: 2px inset var(--alerte_bleu_information);
}

.border-right-2-outset-alerte_bleu_information {
  border-right: 2px outset var(--alerte_bleu_information);
}

.border-right-2-none-alerte_bleu_information {
  border-right: 2px none var(--alerte_bleu_information);
}

.border-right-2-hidden-alerte_bleu_information {
  border-right: 2px hidden var(--alerte_bleu_information);
}

.border-right-2-solid-alerte_jaune_attention {
  border-right: 2px solid var(--alerte_jaune_attention);
}

.border-right-2-dashed-alerte_jaune_attention {
  border-right: 2px dashed var(--alerte_jaune_attention);
}

.border-right-2-dotted-alerte_jaune_attention {
  border-right: 2px dotted var(--alerte_jaune_attention);
}

.border-right-2-double-alerte_jaune_attention {
  border-right: 2px double var(--alerte_jaune_attention);
}

.border-right-2-groove-alerte_jaune_attention {
  border-right: 2px groove var(--alerte_jaune_attention);
}

.border-right-2-ridge-alerte_jaune_attention {
  border-right: 2px ridge var(--alerte_jaune_attention);
}

.border-right-2-inset-alerte_jaune_attention {
  border-right: 2px inset var(--alerte_jaune_attention);
}

.border-right-2-outset-alerte_jaune_attention {
  border-right: 2px outset var(--alerte_jaune_attention);
}

.border-right-2-none-alerte_jaune_attention {
  border-right: 2px none var(--alerte_jaune_attention);
}

.border-right-2-hidden-alerte_jaune_attention {
  border-right: 2px hidden var(--alerte_jaune_attention);
}

.border-right-2-solid-blanc {
  border-right: 2px solid #fff;
}

.border-right-2-dashed-blanc {
  border-right: 2px dashed #fff;
}

.border-right-2-dotted-blanc {
  border-right: 2px dotted #fff;
}

.border-right-2-double-blanc {
  border-right: 2px double #fff;
}

.border-right-2-groove-blanc {
  border-right: 2px groove #fff;
}

.border-right-2-ridge-blanc {
  border-right: 2px ridge #fff;
}

.border-right-2-inset-blanc {
  border-right: 2px inset #fff;
}

.border-right-2-outset-blanc {
  border-right: 2px outset #fff;
}

.border-right-2-none-blanc {
  border-right: 2px #fff;
}

.border-right-2-hidden-blanc {
  border-right: 2px hidden #fff;
}

.border-right-2-solid-noir {
  border-right: 2px solid #222;
}

.border-right-2-dashed-noir {
  border-right: 2px dashed #222;
}

.border-right-2-dotted-noir {
  border-right: 2px dotted #222;
}

.border-right-2-double-noir {
  border-right: 2px double #222;
}

.border-right-2-groove-noir {
  border-right: 2px groove #222;
}

.border-right-2-ridge-noir {
  border-right: 2px ridge #222;
}

.border-right-2-inset-noir {
  border-right: 2px inset #222;
}

.border-right-2-outset-noir {
  border-right: 2px outset #222;
}

.border-right-2-none-noir {
  border-right: 2px #222;
}

.border-right-2-hidden-noir {
  border-right: 2px hidden #222;
}

.border-right-2-solid-gris_light {
  border-right: 2px solid var(--gris_light);
}

.border-right-2-dashed-gris_light {
  border-right: 2px dashed var(--gris_light);
}

.border-right-2-dotted-gris_light {
  border-right: 2px dotted var(--gris_light);
}

.border-right-2-double-gris_light {
  border-right: 2px double var(--gris_light);
}

.border-right-2-groove-gris_light {
  border-right: 2px groove var(--gris_light);
}

.border-right-2-ridge-gris_light {
  border-right: 2px ridge var(--gris_light);
}

.border-right-2-inset-gris_light {
  border-right: 2px inset var(--gris_light);
}

.border-right-2-outset-gris_light {
  border-right: 2px outset var(--gris_light);
}

.border-right-2-none-gris_light {
  border-right: 2px none var(--gris_light);
}

.border-right-2-hidden-gris_light {
  border-right: 2px hidden var(--gris_light);
}

.border-right-2-solid-gris_dark {
  border-right: 2px solid var(--gris_dark);
}

.border-right-2-dashed-gris_dark {
  border-right: 2px dashed var(--gris_dark);
}

.border-right-2-dotted-gris_dark {
  border-right: 2px dotted var(--gris_dark);
}

.border-right-2-double-gris_dark {
  border-right: 2px double var(--gris_dark);
}

.border-right-2-groove-gris_dark {
  border-right: 2px groove var(--gris_dark);
}

.border-right-2-ridge-gris_dark {
  border-right: 2px ridge var(--gris_dark);
}

.border-right-2-inset-gris_dark {
  border-right: 2px inset var(--gris_dark);
}

.border-right-2-outset-gris_dark {
  border-right: 2px outset var(--gris_dark);
}

.border-right-2-none-gris_dark {
  border-right: 2px none var(--gris_dark);
}

.border-right-2-hidden-gris_dark {
  border-right: 2px hidden var(--gris_dark);
}

.border-right-2-solid-gris_background {
  border-right: 2px solid var(--gris_background);
}

.border-right-2-dashed-gris_background {
  border-right: 2px dashed var(--gris_background);
}

.border-right-2-dotted-gris_background {
  border-right: 2px dotted var(--gris_background);
}

.border-right-2-double-gris_background {
  border-right: 2px double var(--gris_background);
}

.border-right-2-groove-gris_background {
  border-right: 2px groove var(--gris_background);
}

.border-right-2-ridge-gris_background {
  border-right: 2px ridge var(--gris_background);
}

.border-right-2-inset-gris_background {
  border-right: 2px inset var(--gris_background);
}

.border-right-2-outset-gris_background {
  border-right: 2px outset var(--gris_background);
}

.border-right-2-none-gris_background {
  border-right: 2px none var(--gris_background);
}

.border-right-2-hidden-gris_background {
  border-right: 2px hidden var(--gris_background);
}

.border-left-2-solid-corail_mh_brand {
  border-left: 2px solid var(--corail_mh_brand);
}

.border-left-2-dashed-corail_mh_brand {
  border-left: 2px dashed var(--corail_mh_brand);
}

.border-left-2-dotted-corail_mh_brand {
  border-left: 2px dotted var(--corail_mh_brand);
}

.border-left-2-double-corail_mh_brand {
  border-left: 2px double var(--corail_mh_brand);
}

.border-left-2-groove-corail_mh_brand {
  border-left: 2px groove var(--corail_mh_brand);
}

.border-left-2-ridge-corail_mh_brand {
  border-left: 2px ridge var(--corail_mh_brand);
}

.border-left-2-inset-corail_mh_brand {
  border-left: 2px inset var(--corail_mh_brand);
}

.border-left-2-outset-corail_mh_brand {
  border-left: 2px outset var(--corail_mh_brand);
}

.border-left-2-none-corail_mh_brand {
  border-left: 2px none var(--corail_mh_brand);
}

.border-left-2-hidden-corail_mh_brand {
  border-left: 2px hidden var(--corail_mh_brand);
}

.border-left-2-solid-corail_mh_dark {
  border-left: 2px solid var(--corail_mh_dark);
}

.border-left-2-dashed-corail_mh_dark {
  border-left: 2px dashed var(--corail_mh_dark);
}

.border-left-2-dotted-corail_mh_dark {
  border-left: 2px dotted var(--corail_mh_dark);
}

.border-left-2-double-corail_mh_dark {
  border-left: 2px double var(--corail_mh_dark);
}

.border-left-2-groove-corail_mh_dark {
  border-left: 2px groove var(--corail_mh_dark);
}

.border-left-2-ridge-corail_mh_dark {
  border-left: 2px ridge var(--corail_mh_dark);
}

.border-left-2-inset-corail_mh_dark {
  border-left: 2px inset var(--corail_mh_dark);
}

.border-left-2-outset-corail_mh_dark {
  border-left: 2px outset var(--corail_mh_dark);
}

.border-left-2-none-corail_mh_dark {
  border-left: 2px none var(--corail_mh_dark);
}

.border-left-2-hidden-corail_mh_dark {
  border-left: 2px hidden var(--corail_mh_dark);
}

.border-left-2-solid-corail_mh_light {
  border-left: 2px solid var(--corail_mh_light);
}

.border-left-2-dashed-corail_mh_light {
  border-left: 2px dashed var(--corail_mh_light);
}

.border-left-2-dotted-corail_mh_light {
  border-left: 2px dotted var(--corail_mh_light);
}

.border-left-2-double-corail_mh_light {
  border-left: 2px double var(--corail_mh_light);
}

.border-left-2-groove-corail_mh_light {
  border-left: 2px groove var(--corail_mh_light);
}

.border-left-2-ridge-corail_mh_light {
  border-left: 2px ridge var(--corail_mh_light);
}

.border-left-2-inset-corail_mh_light {
  border-left: 2px inset var(--corail_mh_light);
}

.border-left-2-outset-corail_mh_light {
  border-left: 2px outset var(--corail_mh_light);
}

.border-left-2-none-corail_mh_light {
  border-left: 2px none var(--corail_mh_light);
}

.border-left-2-hidden-corail_mh_light {
  border-left: 2px hidden var(--corail_mh_light);
}

.border-left-2-solid-gris_sable_light {
  border-left: 2px solid var(--gris_sable_light);
}

.border-left-2-dashed-gris_sable_light {
  border-left: 2px dashed var(--gris_sable_light);
}

.border-left-2-dotted-gris_sable_light {
  border-left: 2px dotted var(--gris_sable_light);
}

.border-left-2-double-gris_sable_light {
  border-left: 2px double var(--gris_sable_light);
}

.border-left-2-groove-gris_sable_light {
  border-left: 2px groove var(--gris_sable_light);
}

.border-left-2-ridge-gris_sable_light {
  border-left: 2px ridge var(--gris_sable_light);
}

.border-left-2-inset-gris_sable_light {
  border-left: 2px inset var(--gris_sable_light);
}

.border-left-2-outset-gris_sable_light {
  border-left: 2px outset var(--gris_sable_light);
}

.border-left-2-none-gris_sable_light {
  border-left: 2px none var(--gris_sable_light);
}

.border-left-2-hidden-gris_sable_light {
  border-left: 2px hidden var(--gris_sable_light);
}

.border-left-2-solid-gris_sable_dark {
  border-left: 2px solid var(--gris_sable_dark);
}

.border-left-2-dashed-gris_sable_dark {
  border-left: 2px dashed var(--gris_sable_dark);
}

.border-left-2-dotted-gris_sable_dark {
  border-left: 2px dotted var(--gris_sable_dark);
}

.border-left-2-double-gris_sable_dark {
  border-left: 2px double var(--gris_sable_dark);
}

.border-left-2-groove-gris_sable_dark {
  border-left: 2px groove var(--gris_sable_dark);
}

.border-left-2-ridge-gris_sable_dark {
  border-left: 2px ridge var(--gris_sable_dark);
}

.border-left-2-inset-gris_sable_dark {
  border-left: 2px inset var(--gris_sable_dark);
}

.border-left-2-outset-gris_sable_dark {
  border-left: 2px outset var(--gris_sable_dark);
}

.border-left-2-none-gris_sable_dark {
  border-left: 2px none var(--gris_sable_dark);
}

.border-left-2-hidden-gris_sable_dark {
  border-left: 2px hidden var(--gris_sable_dark);
}

.border-left-2-solid-jaune_ivoire_light {
  border-left: 2px solid var(--jaune_ivoire_light);
}

.border-left-2-dashed-jaune_ivoire_light {
  border-left: 2px dashed var(--jaune_ivoire_light);
}

.border-left-2-dotted-jaune_ivoire_light {
  border-left: 2px dotted var(--jaune_ivoire_light);
}

.border-left-2-double-jaune_ivoire_light {
  border-left: 2px double var(--jaune_ivoire_light);
}

.border-left-2-groove-jaune_ivoire_light {
  border-left: 2px groove var(--jaune_ivoire_light);
}

.border-left-2-ridge-jaune_ivoire_light {
  border-left: 2px ridge var(--jaune_ivoire_light);
}

.border-left-2-inset-jaune_ivoire_light {
  border-left: 2px inset var(--jaune_ivoire_light);
}

.border-left-2-outset-jaune_ivoire_light {
  border-left: 2px outset var(--jaune_ivoire_light);
}

.border-left-2-none-jaune_ivoire_light {
  border-left: 2px none var(--jaune_ivoire_light);
}

.border-left-2-hidden-jaune_ivoire_light {
  border-left: 2px hidden var(--jaune_ivoire_light);
}

.border-left-2-solid-jaune_ivoire_dark {
  border-left: 2px solid var(--jaune_ivoire_dark);
}

.border-left-2-dashed-jaune_ivoire_dark {
  border-left: 2px dashed var(--jaune_ivoire_dark);
}

.border-left-2-dotted-jaune_ivoire_dark {
  border-left: 2px dotted var(--jaune_ivoire_dark);
}

.border-left-2-double-jaune_ivoire_dark {
  border-left: 2px double var(--jaune_ivoire_dark);
}

.border-left-2-groove-jaune_ivoire_dark {
  border-left: 2px groove var(--jaune_ivoire_dark);
}

.border-left-2-ridge-jaune_ivoire_dark {
  border-left: 2px ridge var(--jaune_ivoire_dark);
}

.border-left-2-inset-jaune_ivoire_dark {
  border-left: 2px inset var(--jaune_ivoire_dark);
}

.border-left-2-outset-jaune_ivoire_dark {
  border-left: 2px outset var(--jaune_ivoire_dark);
}

.border-left-2-none-jaune_ivoire_dark {
  border-left: 2px none var(--jaune_ivoire_dark);
}

.border-left-2-hidden-jaune_ivoire_dark {
  border-left: 2px hidden var(--jaune_ivoire_dark);
}

.border-left-2-solid-jaune_light {
  border-left: 2px solid var(--jaune_light);
}

.border-left-2-dashed-jaune_light {
  border-left: 2px dashed var(--jaune_light);
}

.border-left-2-dotted-jaune_light {
  border-left: 2px dotted var(--jaune_light);
}

.border-left-2-double-jaune_light {
  border-left: 2px double var(--jaune_light);
}

.border-left-2-groove-jaune_light {
  border-left: 2px groove var(--jaune_light);
}

.border-left-2-ridge-jaune_light {
  border-left: 2px ridge var(--jaune_light);
}

.border-left-2-inset-jaune_light {
  border-left: 2px inset var(--jaune_light);
}

.border-left-2-outset-jaune_light {
  border-left: 2px outset var(--jaune_light);
}

.border-left-2-none-jaune_light {
  border-left: 2px none var(--jaune_light);
}

.border-left-2-hidden-jaune_light {
  border-left: 2px hidden var(--jaune_light);
}

.border-left-2-solid-jaune_dark {
  border-left: 2px solid var(--jaune_dark);
}

.border-left-2-dashed-jaune_dark {
  border-left: 2px dashed var(--jaune_dark);
}

.border-left-2-dotted-jaune_dark {
  border-left: 2px dotted var(--jaune_dark);
}

.border-left-2-double-jaune_dark {
  border-left: 2px double var(--jaune_dark);
}

.border-left-2-groove-jaune_dark {
  border-left: 2px groove var(--jaune_dark);
}

.border-left-2-ridge-jaune_dark {
  border-left: 2px ridge var(--jaune_dark);
}

.border-left-2-inset-jaune_dark {
  border-left: 2px inset var(--jaune_dark);
}

.border-left-2-outset-jaune_dark {
  border-left: 2px outset var(--jaune_dark);
}

.border-left-2-none-jaune_dark {
  border-left: 2px none var(--jaune_dark);
}

.border-left-2-hidden-jaune_dark {
  border-left: 2px hidden var(--jaune_dark);
}

.border-left-2-solid-orange_normal {
  border-left: 2px solid var(--orange_normal);
}

.border-left-2-dashed-orange_normal {
  border-left: 2px dashed var(--orange_normal);
}

.border-left-2-dotted-orange_normal {
  border-left: 2px dotted var(--orange_normal);
}

.border-left-2-double-orange_normal {
  border-left: 2px double var(--orange_normal);
}

.border-left-2-groove-orange_normal {
  border-left: 2px groove var(--orange_normal);
}

.border-left-2-ridge-orange_normal {
  border-left: 2px ridge var(--orange_normal);
}

.border-left-2-inset-orange_normal {
  border-left: 2px inset var(--orange_normal);
}

.border-left-2-outset-orange_normal {
  border-left: 2px outset var(--orange_normal);
}

.border-left-2-none-orange_normal {
  border-left: 2px none var(--orange_normal);
}

.border-left-2-hidden-orange_normal {
  border-left: 2px hidden var(--orange_normal);
}

.border-left-2-solid-orange_dark {
  border-left: 2px solid var(--orange_dark);
}

.border-left-2-dashed-orange_dark {
  border-left: 2px dashed var(--orange_dark);
}

.border-left-2-dotted-orange_dark {
  border-left: 2px dotted var(--orange_dark);
}

.border-left-2-double-orange_dark {
  border-left: 2px double var(--orange_dark);
}

.border-left-2-groove-orange_dark {
  border-left: 2px groove var(--orange_dark);
}

.border-left-2-ridge-orange_dark {
  border-left: 2px ridge var(--orange_dark);
}

.border-left-2-inset-orange_dark {
  border-left: 2px inset var(--orange_dark);
}

.border-left-2-outset-orange_dark {
  border-left: 2px outset var(--orange_dark);
}

.border-left-2-none-orange_dark {
  border-left: 2px none var(--orange_dark);
}

.border-left-2-hidden-orange_dark {
  border-left: 2px hidden var(--orange_dark);
}

.border-left-2-solid-vert_light {
  border-left: 2px solid var(--vert_light);
}

.border-left-2-dashed-vert_light {
  border-left: 2px dashed var(--vert_light);
}

.border-left-2-dotted-vert_light {
  border-left: 2px dotted var(--vert_light);
}

.border-left-2-double-vert_light {
  border-left: 2px double var(--vert_light);
}

.border-left-2-groove-vert_light {
  border-left: 2px groove var(--vert_light);
}

.border-left-2-ridge-vert_light {
  border-left: 2px ridge var(--vert_light);
}

.border-left-2-inset-vert_light {
  border-left: 2px inset var(--vert_light);
}

.border-left-2-outset-vert_light {
  border-left: 2px outset var(--vert_light);
}

.border-left-2-none-vert_light {
  border-left: 2px none var(--vert_light);
}

.border-left-2-hidden-vert_light {
  border-left: 2px hidden var(--vert_light);
}

.border-left-2-solid-vert_dark {
  border-left: 2px solid var(--vert_dark);
}

.border-left-2-dashed-vert_dark {
  border-left: 2px dashed var(--vert_dark);
}

.border-left-2-dotted-vert_dark {
  border-left: 2px dotted var(--vert_dark);
}

.border-left-2-double-vert_dark {
  border-left: 2px double var(--vert_dark);
}

.border-left-2-groove-vert_dark {
  border-left: 2px groove var(--vert_dark);
}

.border-left-2-ridge-vert_dark {
  border-left: 2px ridge var(--vert_dark);
}

.border-left-2-inset-vert_dark {
  border-left: 2px inset var(--vert_dark);
}

.border-left-2-outset-vert_dark {
  border-left: 2px outset var(--vert_dark);
}

.border-left-2-none-vert_dark {
  border-left: 2px none var(--vert_dark);
}

.border-left-2-hidden-vert_dark {
  border-left: 2px hidden var(--vert_dark);
}

.border-left-2-solid-bleu_turquoise_light {
  border-left: 2px solid var(--bleu_turquoise_light);
}

.border-left-2-dashed-bleu_turquoise_light {
  border-left: 2px dashed var(--bleu_turquoise_light);
}

.border-left-2-dotted-bleu_turquoise_light {
  border-left: 2px dotted var(--bleu_turquoise_light);
}

.border-left-2-double-bleu_turquoise_light {
  border-left: 2px double var(--bleu_turquoise_light);
}

.border-left-2-groove-bleu_turquoise_light {
  border-left: 2px groove var(--bleu_turquoise_light);
}

.border-left-2-ridge-bleu_turquoise_light {
  border-left: 2px ridge var(--bleu_turquoise_light);
}

.border-left-2-inset-bleu_turquoise_light {
  border-left: 2px inset var(--bleu_turquoise_light);
}

.border-left-2-outset-bleu_turquoise_light {
  border-left: 2px outset var(--bleu_turquoise_light);
}

.border-left-2-none-bleu_turquoise_light {
  border-left: 2px none var(--bleu_turquoise_light);
}

.border-left-2-hidden-bleu_turquoise_light {
  border-left: 2px hidden var(--bleu_turquoise_light);
}

.border-left-2-solid-bleu_turquoise_middle {
  border-left: 2px solid var(--bleu_turquoise_middle);
}

.border-left-2-dashed-bleu_turquoise_middle {
  border-left: 2px dashed var(--bleu_turquoise_middle);
}

.border-left-2-dotted-bleu_turquoise_middle {
  border-left: 2px dotted var(--bleu_turquoise_middle);
}

.border-left-2-double-bleu_turquoise_middle {
  border-left: 2px double var(--bleu_turquoise_middle);
}

.border-left-2-groove-bleu_turquoise_middle {
  border-left: 2px groove var(--bleu_turquoise_middle);
}

.border-left-2-ridge-bleu_turquoise_middle {
  border-left: 2px ridge var(--bleu_turquoise_middle);
}

.border-left-2-inset-bleu_turquoise_middle {
  border-left: 2px inset var(--bleu_turquoise_middle);
}

.border-left-2-outset-bleu_turquoise_middle {
  border-left: 2px outset var(--bleu_turquoise_middle);
}

.border-left-2-none-bleu_turquoise_middle {
  border-left: 2px none var(--bleu_turquoise_middle);
}

.border-left-2-hidden-bleu_turquoise_middle {
  border-left: 2px hidden var(--bleu_turquoise_middle);
}

.border-left-2-solid-bleu_turquoise_dark {
  border-left: 2px solid var(--bleu_turquoise_dark);
}

.border-left-2-dashed-bleu_turquoise_dark {
  border-left: 2px dashed var(--bleu_turquoise_dark);
}

.border-left-2-dotted-bleu_turquoise_dark {
  border-left: 2px dotted var(--bleu_turquoise_dark);
}

.border-left-2-double-bleu_turquoise_dark {
  border-left: 2px double var(--bleu_turquoise_dark);
}

.border-left-2-groove-bleu_turquoise_dark {
  border-left: 2px groove var(--bleu_turquoise_dark);
}

.border-left-2-ridge-bleu_turquoise_dark {
  border-left: 2px ridge var(--bleu_turquoise_dark);
}

.border-left-2-inset-bleu_turquoise_dark {
  border-left: 2px inset var(--bleu_turquoise_dark);
}

.border-left-2-outset-bleu_turquoise_dark {
  border-left: 2px outset var(--bleu_turquoise_dark);
}

.border-left-2-none-bleu_turquoise_dark {
  border-left: 2px none var(--bleu_turquoise_dark);
}

.border-left-2-hidden-bleu_turquoise_dark {
  border-left: 2px hidden var(--bleu_turquoise_dark);
}

.border-left-2-solid-bleu_light {
  border-left: 2px solid var(--bleu_light);
}

.border-left-2-dashed-bleu_light {
  border-left: 2px dashed var(--bleu_light);
}

.border-left-2-dotted-bleu_light {
  border-left: 2px dotted var(--bleu_light);
}

.border-left-2-double-bleu_light {
  border-left: 2px double var(--bleu_light);
}

.border-left-2-groove-bleu_light {
  border-left: 2px groove var(--bleu_light);
}

.border-left-2-ridge-bleu_light {
  border-left: 2px ridge var(--bleu_light);
}

.border-left-2-inset-bleu_light {
  border-left: 2px inset var(--bleu_light);
}

.border-left-2-outset-bleu_light {
  border-left: 2px outset var(--bleu_light);
}

.border-left-2-none-bleu_light {
  border-left: 2px none var(--bleu_light);
}

.border-left-2-hidden-bleu_light {
  border-left: 2px hidden var(--bleu_light);
}

.border-left-2-solid-bleu_dark {
  border-left: 2px solid var(--bleu_dark);
}

.border-left-2-dashed-bleu_dark {
  border-left: 2px dashed var(--bleu_dark);
}

.border-left-2-dotted-bleu_dark {
  border-left: 2px dotted var(--bleu_dark);
}

.border-left-2-double-bleu_dark {
  border-left: 2px double var(--bleu_dark);
}

.border-left-2-groove-bleu_dark {
  border-left: 2px groove var(--bleu_dark);
}

.border-left-2-ridge-bleu_dark {
  border-left: 2px ridge var(--bleu_dark);
}

.border-left-2-inset-bleu_dark {
  border-left: 2px inset var(--bleu_dark);
}

.border-left-2-outset-bleu_dark {
  border-left: 2px outset var(--bleu_dark);
}

.border-left-2-none-bleu_dark {
  border-left: 2px none var(--bleu_dark);
}

.border-left-2-hidden-bleu_dark {
  border-left: 2px hidden var(--bleu_dark);
}

.border-left-2-solid-bleu_klein_light {
  border-left: 2px solid var(--bleu_klein_light);
}

.border-left-2-dashed-bleu_klein_light {
  border-left: 2px dashed var(--bleu_klein_light);
}

.border-left-2-dotted-bleu_klein_light {
  border-left: 2px dotted var(--bleu_klein_light);
}

.border-left-2-double-bleu_klein_light {
  border-left: 2px double var(--bleu_klein_light);
}

.border-left-2-groove-bleu_klein_light {
  border-left: 2px groove var(--bleu_klein_light);
}

.border-left-2-ridge-bleu_klein_light {
  border-left: 2px ridge var(--bleu_klein_light);
}

.border-left-2-inset-bleu_klein_light {
  border-left: 2px inset var(--bleu_klein_light);
}

.border-left-2-outset-bleu_klein_light {
  border-left: 2px outset var(--bleu_klein_light);
}

.border-left-2-none-bleu_klein_light {
  border-left: 2px none var(--bleu_klein_light);
}

.border-left-2-hidden-bleu_klein_light {
  border-left: 2px hidden var(--bleu_klein_light);
}

.border-left-2-solid-bleu_klein_dark {
  border-left: 2px solid var(--bleu_klein_dark);
}

.border-left-2-dashed-bleu_klein_dark {
  border-left: 2px dashed var(--bleu_klein_dark);
}

.border-left-2-dotted-bleu_klein_dark {
  border-left: 2px dotted var(--bleu_klein_dark);
}

.border-left-2-double-bleu_klein_dark {
  border-left: 2px double var(--bleu_klein_dark);
}

.border-left-2-groove-bleu_klein_dark {
  border-left: 2px groove var(--bleu_klein_dark);
}

.border-left-2-ridge-bleu_klein_dark {
  border-left: 2px ridge var(--bleu_klein_dark);
}

.border-left-2-inset-bleu_klein_dark {
  border-left: 2px inset var(--bleu_klein_dark);
}

.border-left-2-outset-bleu_klein_dark {
  border-left: 2px outset var(--bleu_klein_dark);
}

.border-left-2-none-bleu_klein_dark {
  border-left: 2px none var(--bleu_klein_dark);
}

.border-left-2-hidden-bleu_klein_dark {
  border-left: 2px hidden var(--bleu_klein_dark);
}

.border-left-2-solid-violet_light {
  border-left: 2px solid var(--violet_light);
}

.border-left-2-dashed-violet_light {
  border-left: 2px dashed var(--violet_light);
}

.border-left-2-dotted-violet_light {
  border-left: 2px dotted var(--violet_light);
}

.border-left-2-double-violet_light {
  border-left: 2px double var(--violet_light);
}

.border-left-2-groove-violet_light {
  border-left: 2px groove var(--violet_light);
}

.border-left-2-ridge-violet_light {
  border-left: 2px ridge var(--violet_light);
}

.border-left-2-inset-violet_light {
  border-left: 2px inset var(--violet_light);
}

.border-left-2-outset-violet_light {
  border-left: 2px outset var(--violet_light);
}

.border-left-2-none-violet_light {
  border-left: 2px none var(--violet_light);
}

.border-left-2-hidden-violet_light {
  border-left: 2px hidden var(--violet_light);
}

.border-left-2-solid-violet_dark {
  border-left: 2px solid var(--violet_dark);
}

.border-left-2-dashed-violet_dark {
  border-left: 2px dashed var(--violet_dark);
}

.border-left-2-dotted-violet_dark {
  border-left: 2px dotted var(--violet_dark);
}

.border-left-2-double-violet_dark {
  border-left: 2px double var(--violet_dark);
}

.border-left-2-groove-violet_dark {
  border-left: 2px groove var(--violet_dark);
}

.border-left-2-ridge-violet_dark {
  border-left: 2px ridge var(--violet_dark);
}

.border-left-2-inset-violet_dark {
  border-left: 2px inset var(--violet_dark);
}

.border-left-2-outset-violet_dark {
  border-left: 2px outset var(--violet_dark);
}

.border-left-2-none-violet_dark {
  border-left: 2px none var(--violet_dark);
}

.border-left-2-hidden-violet_dark {
  border-left: 2px hidden var(--violet_dark);
}

.border-left-2-solid-mauve {
  border-left: 2px solid var(--mauve);
}

.border-left-2-dashed-mauve {
  border-left: 2px dashed var(--mauve);
}

.border-left-2-dotted-mauve {
  border-left: 2px dotted var(--mauve);
}

.border-left-2-double-mauve {
  border-left: 2px double var(--mauve);
}

.border-left-2-groove-mauve {
  border-left: 2px groove var(--mauve);
}

.border-left-2-ridge-mauve {
  border-left: 2px ridge var(--mauve);
}

.border-left-2-inset-mauve {
  border-left: 2px inset var(--mauve);
}

.border-left-2-outset-mauve {
  border-left: 2px outset var(--mauve);
}

.border-left-2-none-mauve {
  border-left: 2px none var(--mauve);
}

.border-left-2-hidden-mauve {
  border-left: 2px hidden var(--mauve);
}

.border-left-2-solid-rose_light {
  border-left: 2px solid var(--rose_light);
}

.border-left-2-dashed-rose_light {
  border-left: 2px dashed var(--rose_light);
}

.border-left-2-dotted-rose_light {
  border-left: 2px dotted var(--rose_light);
}

.border-left-2-double-rose_light {
  border-left: 2px double var(--rose_light);
}

.border-left-2-groove-rose_light {
  border-left: 2px groove var(--rose_light);
}

.border-left-2-ridge-rose_light {
  border-left: 2px ridge var(--rose_light);
}

.border-left-2-inset-rose_light {
  border-left: 2px inset var(--rose_light);
}

.border-left-2-outset-rose_light {
  border-left: 2px outset var(--rose_light);
}

.border-left-2-none-rose_light {
  border-left: 2px none var(--rose_light);
}

.border-left-2-hidden-rose_light {
  border-left: 2px hidden var(--rose_light);
}

.border-left-2-solid-rose_dark {
  border-left: 2px solid var(--rose_dark);
}

.border-left-2-dashed-rose_dark {
  border-left: 2px dashed var(--rose_dark);
}

.border-left-2-dotted-rose_dark {
  border-left: 2px dotted var(--rose_dark);
}

.border-left-2-double-rose_dark {
  border-left: 2px double var(--rose_dark);
}

.border-left-2-groove-rose_dark {
  border-left: 2px groove var(--rose_dark);
}

.border-left-2-ridge-rose_dark {
  border-left: 2px ridge var(--rose_dark);
}

.border-left-2-inset-rose_dark {
  border-left: 2px inset var(--rose_dark);
}

.border-left-2-outset-rose_dark {
  border-left: 2px outset var(--rose_dark);
}

.border-left-2-none-rose_dark {
  border-left: 2px none var(--rose_dark);
}

.border-left-2-hidden-rose_dark {
  border-left: 2px hidden var(--rose_dark);
}

.border-left-2-solid-rose_fushia {
  border-left: 2px solid var(--rose_fushia);
}

.border-left-2-dashed-rose_fushia {
  border-left: 2px dashed var(--rose_fushia);
}

.border-left-2-dotted-rose_fushia {
  border-left: 2px dotted var(--rose_fushia);
}

.border-left-2-double-rose_fushia {
  border-left: 2px double var(--rose_fushia);
}

.border-left-2-groove-rose_fushia {
  border-left: 2px groove var(--rose_fushia);
}

.border-left-2-ridge-rose_fushia {
  border-left: 2px ridge var(--rose_fushia);
}

.border-left-2-inset-rose_fushia {
  border-left: 2px inset var(--rose_fushia);
}

.border-left-2-outset-rose_fushia {
  border-left: 2px outset var(--rose_fushia);
}

.border-left-2-none-rose_fushia {
  border-left: 2px none var(--rose_fushia);
}

.border-left-2-hidden-rose_fushia {
  border-left: 2px hidden var(--rose_fushia);
}

.border-left-2-solid-rouge_framboise {
  border-left: 2px solid var(--rouge_framboise);
}

.border-left-2-dashed-rouge_framboise {
  border-left: 2px dashed var(--rouge_framboise);
}

.border-left-2-dotted-rouge_framboise {
  border-left: 2px dotted var(--rouge_framboise);
}

.border-left-2-double-rouge_framboise {
  border-left: 2px double var(--rouge_framboise);
}

.border-left-2-groove-rouge_framboise {
  border-left: 2px groove var(--rouge_framboise);
}

.border-left-2-ridge-rouge_framboise {
  border-left: 2px ridge var(--rouge_framboise);
}

.border-left-2-inset-rouge_framboise {
  border-left: 2px inset var(--rouge_framboise);
}

.border-left-2-outset-rouge_framboise {
  border-left: 2px outset var(--rouge_framboise);
}

.border-left-2-none-rouge_framboise {
  border-left: 2px none var(--rouge_framboise);
}

.border-left-2-hidden-rouge_framboise {
  border-left: 2px hidden var(--rouge_framboise);
}

.border-left-2-solid-rose_chair_light {
  border-left: 2px solid var(--rose_chair_light);
}

.border-left-2-dashed-rose_chair_light {
  border-left: 2px dashed var(--rose_chair_light);
}

.border-left-2-dotted-rose_chair_light {
  border-left: 2px dotted var(--rose_chair_light);
}

.border-left-2-double-rose_chair_light {
  border-left: 2px double var(--rose_chair_light);
}

.border-left-2-groove-rose_chair_light {
  border-left: 2px groove var(--rose_chair_light);
}

.border-left-2-ridge-rose_chair_light {
  border-left: 2px ridge var(--rose_chair_light);
}

.border-left-2-inset-rose_chair_light {
  border-left: 2px inset var(--rose_chair_light);
}

.border-left-2-outset-rose_chair_light {
  border-left: 2px outset var(--rose_chair_light);
}

.border-left-2-none-rose_chair_light {
  border-left: 2px none var(--rose_chair_light);
}

.border-left-2-hidden-rose_chair_light {
  border-left: 2px hidden var(--rose_chair_light);
}

.border-left-2-solid-rose_chair_dark {
  border-left: 2px solid var(--rose_chair_dark);
}

.border-left-2-dashed-rose_chair_dark {
  border-left: 2px dashed var(--rose_chair_dark);
}

.border-left-2-dotted-rose_chair_dark {
  border-left: 2px dotted var(--rose_chair_dark);
}

.border-left-2-double-rose_chair_dark {
  border-left: 2px double var(--rose_chair_dark);
}

.border-left-2-groove-rose_chair_dark {
  border-left: 2px groove var(--rose_chair_dark);
}

.border-left-2-ridge-rose_chair_dark {
  border-left: 2px ridge var(--rose_chair_dark);
}

.border-left-2-inset-rose_chair_dark {
  border-left: 2px inset var(--rose_chair_dark);
}

.border-left-2-outset-rose_chair_dark {
  border-left: 2px outset var(--rose_chair_dark);
}

.border-left-2-none-rose_chair_dark {
  border-left: 2px none var(--rose_chair_dark);
}

.border-left-2-hidden-rose_chair_dark {
  border-left: 2px hidden var(--rose_chair_dark);
}

.border-left-2-solid-vert_positif {
  border-left: 2px solid var(--vert_positif);
}

.border-left-2-dashed-vert_positif {
  border-left: 2px dashed var(--vert_positif);
}

.border-left-2-dotted-vert_positif {
  border-left: 2px dotted var(--vert_positif);
}

.border-left-2-double-vert_positif {
  border-left: 2px double var(--vert_positif);
}

.border-left-2-groove-vert_positif {
  border-left: 2px groove var(--vert_positif);
}

.border-left-2-ridge-vert_positif {
  border-left: 2px ridge var(--vert_positif);
}

.border-left-2-inset-vert_positif {
  border-left: 2px inset var(--vert_positif);
}

.border-left-2-outset-vert_positif {
  border-left: 2px outset var(--vert_positif);
}

.border-left-2-none-vert_positif {
  border-left: 2px none var(--vert_positif);
}

.border-left-2-hidden-vert_positif {
  border-left: 2px hidden var(--vert_positif);
}

.border-left-2-solid-rouge_negatif {
  border-left: 2px solid var(--rouge_negatif);
}

.border-left-2-dashed-rouge_negatif {
  border-left: 2px dashed var(--rouge_negatif);
}

.border-left-2-dotted-rouge_negatif {
  border-left: 2px dotted var(--rouge_negatif);
}

.border-left-2-double-rouge_negatif {
  border-left: 2px double var(--rouge_negatif);
}

.border-left-2-groove-rouge_negatif {
  border-left: 2px groove var(--rouge_negatif);
}

.border-left-2-ridge-rouge_negatif {
  border-left: 2px ridge var(--rouge_negatif);
}

.border-left-2-inset-rouge_negatif {
  border-left: 2px inset var(--rouge_negatif);
}

.border-left-2-outset-rouge_negatif {
  border-left: 2px outset var(--rouge_negatif);
}

.border-left-2-none-rouge_negatif {
  border-left: 2px none var(--rouge_negatif);
}

.border-left-2-hidden-rouge_negatif {
  border-left: 2px hidden var(--rouge_negatif);
}

.border-left-2-solid-bleu_texte {
  border-left: 2px solid var(--bleu_texte);
}

.border-left-2-dashed-bleu_texte {
  border-left: 2px dashed var(--bleu_texte);
}

.border-left-2-dotted-bleu_texte {
  border-left: 2px dotted var(--bleu_texte);
}

.border-left-2-double-bleu_texte {
  border-left: 2px double var(--bleu_texte);
}

.border-left-2-groove-bleu_texte {
  border-left: 2px groove var(--bleu_texte);
}

.border-left-2-ridge-bleu_texte {
  border-left: 2px ridge var(--bleu_texte);
}

.border-left-2-inset-bleu_texte {
  border-left: 2px inset var(--bleu_texte);
}

.border-left-2-outset-bleu_texte {
  border-left: 2px outset var(--bleu_texte);
}

.border-left-2-none-bleu_texte {
  border-left: 2px none var(--bleu_texte);
}

.border-left-2-hidden-bleu_texte {
  border-left: 2px hidden var(--bleu_texte);
}

.border-left-2-solid-alerte_vert_positif {
  border-left: 2px solid var(--alerte_vert_positif);
}

.border-left-2-dashed-alerte_vert_positif {
  border-left: 2px dashed var(--alerte_vert_positif);
}

.border-left-2-dotted-alerte_vert_positif {
  border-left: 2px dotted var(--alerte_vert_positif);
}

.border-left-2-double-alerte_vert_positif {
  border-left: 2px double var(--alerte_vert_positif);
}

.border-left-2-groove-alerte_vert_positif {
  border-left: 2px groove var(--alerte_vert_positif);
}

.border-left-2-ridge-alerte_vert_positif {
  border-left: 2px ridge var(--alerte_vert_positif);
}

.border-left-2-inset-alerte_vert_positif {
  border-left: 2px inset var(--alerte_vert_positif);
}

.border-left-2-outset-alerte_vert_positif {
  border-left: 2px outset var(--alerte_vert_positif);
}

.border-left-2-none-alerte_vert_positif {
  border-left: 2px none var(--alerte_vert_positif);
}

.border-left-2-hidden-alerte_vert_positif {
  border-left: 2px hidden var(--alerte_vert_positif);
}

.border-left-2-solid-alerte_rouge_negatif {
  border-left: 2px solid var(--alerte_rouge_negatif);
}

.border-left-2-dashed-alerte_rouge_negatif {
  border-left: 2px dashed var(--alerte_rouge_negatif);
}

.border-left-2-dotted-alerte_rouge_negatif {
  border-left: 2px dotted var(--alerte_rouge_negatif);
}

.border-left-2-double-alerte_rouge_negatif {
  border-left: 2px double var(--alerte_rouge_negatif);
}

.border-left-2-groove-alerte_rouge_negatif {
  border-left: 2px groove var(--alerte_rouge_negatif);
}

.border-left-2-ridge-alerte_rouge_negatif {
  border-left: 2px ridge var(--alerte_rouge_negatif);
}

.border-left-2-inset-alerte_rouge_negatif {
  border-left: 2px inset var(--alerte_rouge_negatif);
}

.border-left-2-outset-alerte_rouge_negatif {
  border-left: 2px outset var(--alerte_rouge_negatif);
}

.border-left-2-none-alerte_rouge_negatif {
  border-left: 2px none var(--alerte_rouge_negatif);
}

.border-left-2-hidden-alerte_rouge_negatif {
  border-left: 2px hidden var(--alerte_rouge_negatif);
}

.border-left-2-solid-alerte_bleu_information {
  border-left: 2px solid var(--alerte_bleu_information);
}

.border-left-2-dashed-alerte_bleu_information {
  border-left: 2px dashed var(--alerte_bleu_information);
}

.border-left-2-dotted-alerte_bleu_information {
  border-left: 2px dotted var(--alerte_bleu_information);
}

.border-left-2-double-alerte_bleu_information {
  border-left: 2px double var(--alerte_bleu_information);
}

.border-left-2-groove-alerte_bleu_information {
  border-left: 2px groove var(--alerte_bleu_information);
}

.border-left-2-ridge-alerte_bleu_information {
  border-left: 2px ridge var(--alerte_bleu_information);
}

.border-left-2-inset-alerte_bleu_information {
  border-left: 2px inset var(--alerte_bleu_information);
}

.border-left-2-outset-alerte_bleu_information {
  border-left: 2px outset var(--alerte_bleu_information);
}

.border-left-2-none-alerte_bleu_information {
  border-left: 2px none var(--alerte_bleu_information);
}

.border-left-2-hidden-alerte_bleu_information {
  border-left: 2px hidden var(--alerte_bleu_information);
}

.border-left-2-solid-alerte_jaune_attention {
  border-left: 2px solid var(--alerte_jaune_attention);
}

.border-left-2-dashed-alerte_jaune_attention {
  border-left: 2px dashed var(--alerte_jaune_attention);
}

.border-left-2-dotted-alerte_jaune_attention {
  border-left: 2px dotted var(--alerte_jaune_attention);
}

.border-left-2-double-alerte_jaune_attention {
  border-left: 2px double var(--alerte_jaune_attention);
}

.border-left-2-groove-alerte_jaune_attention {
  border-left: 2px groove var(--alerte_jaune_attention);
}

.border-left-2-ridge-alerte_jaune_attention {
  border-left: 2px ridge var(--alerte_jaune_attention);
}

.border-left-2-inset-alerte_jaune_attention {
  border-left: 2px inset var(--alerte_jaune_attention);
}

.border-left-2-outset-alerte_jaune_attention {
  border-left: 2px outset var(--alerte_jaune_attention);
}

.border-left-2-none-alerte_jaune_attention {
  border-left: 2px none var(--alerte_jaune_attention);
}

.border-left-2-hidden-alerte_jaune_attention {
  border-left: 2px hidden var(--alerte_jaune_attention);
}

.border-left-2-solid-blanc {
  border-left: 2px solid #fff;
}

.border-left-2-dashed-blanc {
  border-left: 2px dashed #fff;
}

.border-left-2-dotted-blanc {
  border-left: 2px dotted #fff;
}

.border-left-2-double-blanc {
  border-left: 2px double #fff;
}

.border-left-2-groove-blanc {
  border-left: 2px groove #fff;
}

.border-left-2-ridge-blanc {
  border-left: 2px ridge #fff;
}

.border-left-2-inset-blanc {
  border-left: 2px inset #fff;
}

.border-left-2-outset-blanc {
  border-left: 2px outset #fff;
}

.border-left-2-none-blanc {
  border-left: 2px #fff;
}

.border-left-2-hidden-blanc {
  border-left: 2px hidden #fff;
}

.border-left-2-solid-noir {
  border-left: 2px solid #222;
}

.border-left-2-dashed-noir {
  border-left: 2px dashed #222;
}

.border-left-2-dotted-noir {
  border-left: 2px dotted #222;
}

.border-left-2-double-noir {
  border-left: 2px double #222;
}

.border-left-2-groove-noir {
  border-left: 2px groove #222;
}

.border-left-2-ridge-noir {
  border-left: 2px ridge #222;
}

.border-left-2-inset-noir {
  border-left: 2px inset #222;
}

.border-left-2-outset-noir {
  border-left: 2px outset #222;
}

.border-left-2-none-noir {
  border-left: 2px #222;
}

.border-left-2-hidden-noir {
  border-left: 2px hidden #222;
}

.border-left-2-solid-gris_light {
  border-left: 2px solid var(--gris_light);
}

.border-left-2-dashed-gris_light {
  border-left: 2px dashed var(--gris_light);
}

.border-left-2-dotted-gris_light {
  border-left: 2px dotted var(--gris_light);
}

.border-left-2-double-gris_light {
  border-left: 2px double var(--gris_light);
}

.border-left-2-groove-gris_light {
  border-left: 2px groove var(--gris_light);
}

.border-left-2-ridge-gris_light {
  border-left: 2px ridge var(--gris_light);
}

.border-left-2-inset-gris_light {
  border-left: 2px inset var(--gris_light);
}

.border-left-2-outset-gris_light {
  border-left: 2px outset var(--gris_light);
}

.border-left-2-none-gris_light {
  border-left: 2px none var(--gris_light);
}

.border-left-2-hidden-gris_light {
  border-left: 2px hidden var(--gris_light);
}

.border-left-2-solid-gris_dark {
  border-left: 2px solid var(--gris_dark);
}

.border-left-2-dashed-gris_dark {
  border-left: 2px dashed var(--gris_dark);
}

.border-left-2-dotted-gris_dark {
  border-left: 2px dotted var(--gris_dark);
}

.border-left-2-double-gris_dark {
  border-left: 2px double var(--gris_dark);
}

.border-left-2-groove-gris_dark {
  border-left: 2px groove var(--gris_dark);
}

.border-left-2-ridge-gris_dark {
  border-left: 2px ridge var(--gris_dark);
}

.border-left-2-inset-gris_dark {
  border-left: 2px inset var(--gris_dark);
}

.border-left-2-outset-gris_dark {
  border-left: 2px outset var(--gris_dark);
}

.border-left-2-none-gris_dark {
  border-left: 2px none var(--gris_dark);
}

.border-left-2-hidden-gris_dark {
  border-left: 2px hidden var(--gris_dark);
}

.border-left-2-solid-gris_background {
  border-left: 2px solid var(--gris_background);
}

.border-left-2-dashed-gris_background {
  border-left: 2px dashed var(--gris_background);
}

.border-left-2-dotted-gris_background {
  border-left: 2px dotted var(--gris_background);
}

.border-left-2-double-gris_background {
  border-left: 2px double var(--gris_background);
}

.border-left-2-groove-gris_background {
  border-left: 2px groove var(--gris_background);
}

.border-left-2-ridge-gris_background {
  border-left: 2px ridge var(--gris_background);
}

.border-left-2-inset-gris_background {
  border-left: 2px inset var(--gris_background);
}

.border-left-2-outset-gris_background {
  border-left: 2px outset var(--gris_background);
}

.border-left-2-none-gris_background {
  border-left: 2px none var(--gris_background);
}

.border-left-2-hidden-gris_background {
  border-left: 2px hidden var(--gris_background);
}

.border-all-2-solid-corail_mh_brand {
  border: 2px solid var(--corail_mh_brand);
}

.border-all-2-dashed-corail_mh_brand {
  border: 2px dashed var(--corail_mh_brand);
}

.border-all-2-dotted-corail_mh_brand {
  border: 2px dotted var(--corail_mh_brand);
}

.border-all-2-double-corail_mh_brand {
  border: 2px double var(--corail_mh_brand);
}

.border-all-2-groove-corail_mh_brand {
  border: 2px groove var(--corail_mh_brand);
}

.border-all-2-ridge-corail_mh_brand {
  border: 2px ridge var(--corail_mh_brand);
}

.border-all-2-inset-corail_mh_brand {
  border: 2px inset var(--corail_mh_brand);
}

.border-all-2-outset-corail_mh_brand {
  border: 2px outset var(--corail_mh_brand);
}

.border-all-2-none-corail_mh_brand {
  border: 2px none var(--corail_mh_brand);
}

.border-all-2-hidden-corail_mh_brand {
  border: 2px hidden var(--corail_mh_brand);
}

.border-all-2-solid-corail_mh_dark {
  border: 2px solid var(--corail_mh_dark);
}

.border-all-2-dashed-corail_mh_dark {
  border: 2px dashed var(--corail_mh_dark);
}

.border-all-2-dotted-corail_mh_dark {
  border: 2px dotted var(--corail_mh_dark);
}

.border-all-2-double-corail_mh_dark {
  border: 2px double var(--corail_mh_dark);
}

.border-all-2-groove-corail_mh_dark {
  border: 2px groove var(--corail_mh_dark);
}

.border-all-2-ridge-corail_mh_dark {
  border: 2px ridge var(--corail_mh_dark);
}

.border-all-2-inset-corail_mh_dark {
  border: 2px inset var(--corail_mh_dark);
}

.border-all-2-outset-corail_mh_dark {
  border: 2px outset var(--corail_mh_dark);
}

.border-all-2-none-corail_mh_dark {
  border: 2px none var(--corail_mh_dark);
}

.border-all-2-hidden-corail_mh_dark {
  border: 2px hidden var(--corail_mh_dark);
}

.border-all-2-solid-corail_mh_light {
  border: 2px solid var(--corail_mh_light);
}

.border-all-2-dashed-corail_mh_light {
  border: 2px dashed var(--corail_mh_light);
}

.border-all-2-dotted-corail_mh_light {
  border: 2px dotted var(--corail_mh_light);
}

.border-all-2-double-corail_mh_light {
  border: 2px double var(--corail_mh_light);
}

.border-all-2-groove-corail_mh_light {
  border: 2px groove var(--corail_mh_light);
}

.border-all-2-ridge-corail_mh_light {
  border: 2px ridge var(--corail_mh_light);
}

.border-all-2-inset-corail_mh_light {
  border: 2px inset var(--corail_mh_light);
}

.border-all-2-outset-corail_mh_light {
  border: 2px outset var(--corail_mh_light);
}

.border-all-2-none-corail_mh_light {
  border: 2px none var(--corail_mh_light);
}

.border-all-2-hidden-corail_mh_light {
  border: 2px hidden var(--corail_mh_light);
}

.border-all-2-solid-gris_sable_light {
  border: 2px solid var(--gris_sable_light);
}

.border-all-2-dashed-gris_sable_light {
  border: 2px dashed var(--gris_sable_light);
}

.border-all-2-dotted-gris_sable_light {
  border: 2px dotted var(--gris_sable_light);
}

.border-all-2-double-gris_sable_light {
  border: 2px double var(--gris_sable_light);
}

.border-all-2-groove-gris_sable_light {
  border: 2px groove var(--gris_sable_light);
}

.border-all-2-ridge-gris_sable_light {
  border: 2px ridge var(--gris_sable_light);
}

.border-all-2-inset-gris_sable_light {
  border: 2px inset var(--gris_sable_light);
}

.border-all-2-outset-gris_sable_light {
  border: 2px outset var(--gris_sable_light);
}

.border-all-2-none-gris_sable_light {
  border: 2px none var(--gris_sable_light);
}

.border-all-2-hidden-gris_sable_light {
  border: 2px hidden var(--gris_sable_light);
}

.border-all-2-solid-gris_sable_dark {
  border: 2px solid var(--gris_sable_dark);
}

.border-all-2-dashed-gris_sable_dark {
  border: 2px dashed var(--gris_sable_dark);
}

.border-all-2-dotted-gris_sable_dark {
  border: 2px dotted var(--gris_sable_dark);
}

.border-all-2-double-gris_sable_dark {
  border: 2px double var(--gris_sable_dark);
}

.border-all-2-groove-gris_sable_dark {
  border: 2px groove var(--gris_sable_dark);
}

.border-all-2-ridge-gris_sable_dark {
  border: 2px ridge var(--gris_sable_dark);
}

.border-all-2-inset-gris_sable_dark {
  border: 2px inset var(--gris_sable_dark);
}

.border-all-2-outset-gris_sable_dark {
  border: 2px outset var(--gris_sable_dark);
}

.border-all-2-none-gris_sable_dark {
  border: 2px none var(--gris_sable_dark);
}

.border-all-2-hidden-gris_sable_dark {
  border: 2px hidden var(--gris_sable_dark);
}

.border-all-2-solid-jaune_ivoire_light {
  border: 2px solid var(--jaune_ivoire_light);
}

.border-all-2-dashed-jaune_ivoire_light {
  border: 2px dashed var(--jaune_ivoire_light);
}

.border-all-2-dotted-jaune_ivoire_light {
  border: 2px dotted var(--jaune_ivoire_light);
}

.border-all-2-double-jaune_ivoire_light {
  border: 2px double var(--jaune_ivoire_light);
}

.border-all-2-groove-jaune_ivoire_light {
  border: 2px groove var(--jaune_ivoire_light);
}

.border-all-2-ridge-jaune_ivoire_light {
  border: 2px ridge var(--jaune_ivoire_light);
}

.border-all-2-inset-jaune_ivoire_light {
  border: 2px inset var(--jaune_ivoire_light);
}

.border-all-2-outset-jaune_ivoire_light {
  border: 2px outset var(--jaune_ivoire_light);
}

.border-all-2-none-jaune_ivoire_light {
  border: 2px none var(--jaune_ivoire_light);
}

.border-all-2-hidden-jaune_ivoire_light {
  border: 2px hidden var(--jaune_ivoire_light);
}

.border-all-2-solid-jaune_ivoire_dark {
  border: 2px solid var(--jaune_ivoire_dark);
}

.border-all-2-dashed-jaune_ivoire_dark {
  border: 2px dashed var(--jaune_ivoire_dark);
}

.border-all-2-dotted-jaune_ivoire_dark {
  border: 2px dotted var(--jaune_ivoire_dark);
}

.border-all-2-double-jaune_ivoire_dark {
  border: 2px double var(--jaune_ivoire_dark);
}

.border-all-2-groove-jaune_ivoire_dark {
  border: 2px groove var(--jaune_ivoire_dark);
}

.border-all-2-ridge-jaune_ivoire_dark {
  border: 2px ridge var(--jaune_ivoire_dark);
}

.border-all-2-inset-jaune_ivoire_dark {
  border: 2px inset var(--jaune_ivoire_dark);
}

.border-all-2-outset-jaune_ivoire_dark {
  border: 2px outset var(--jaune_ivoire_dark);
}

.border-all-2-none-jaune_ivoire_dark {
  border: 2px none var(--jaune_ivoire_dark);
}

.border-all-2-hidden-jaune_ivoire_dark {
  border: 2px hidden var(--jaune_ivoire_dark);
}

.border-all-2-solid-jaune_light {
  border: 2px solid var(--jaune_light);
}

.border-all-2-dashed-jaune_light {
  border: 2px dashed var(--jaune_light);
}

.border-all-2-dotted-jaune_light {
  border: 2px dotted var(--jaune_light);
}

.border-all-2-double-jaune_light {
  border: 2px double var(--jaune_light);
}

.border-all-2-groove-jaune_light {
  border: 2px groove var(--jaune_light);
}

.border-all-2-ridge-jaune_light {
  border: 2px ridge var(--jaune_light);
}

.border-all-2-inset-jaune_light {
  border: 2px inset var(--jaune_light);
}

.border-all-2-outset-jaune_light {
  border: 2px outset var(--jaune_light);
}

.border-all-2-none-jaune_light {
  border: 2px none var(--jaune_light);
}

.border-all-2-hidden-jaune_light {
  border: 2px hidden var(--jaune_light);
}

.border-all-2-solid-jaune_dark {
  border: 2px solid var(--jaune_dark);
}

.border-all-2-dashed-jaune_dark {
  border: 2px dashed var(--jaune_dark);
}

.border-all-2-dotted-jaune_dark {
  border: 2px dotted var(--jaune_dark);
}

.border-all-2-double-jaune_dark {
  border: 2px double var(--jaune_dark);
}

.border-all-2-groove-jaune_dark {
  border: 2px groove var(--jaune_dark);
}

.border-all-2-ridge-jaune_dark {
  border: 2px ridge var(--jaune_dark);
}

.border-all-2-inset-jaune_dark {
  border: 2px inset var(--jaune_dark);
}

.border-all-2-outset-jaune_dark {
  border: 2px outset var(--jaune_dark);
}

.border-all-2-none-jaune_dark {
  border: 2px none var(--jaune_dark);
}

.border-all-2-hidden-jaune_dark {
  border: 2px hidden var(--jaune_dark);
}

.border-all-2-solid-orange_normal {
  border: 2px solid var(--orange_normal);
}

.border-all-2-dashed-orange_normal {
  border: 2px dashed var(--orange_normal);
}

.border-all-2-dotted-orange_normal {
  border: 2px dotted var(--orange_normal);
}

.border-all-2-double-orange_normal {
  border: 2px double var(--orange_normal);
}

.border-all-2-groove-orange_normal {
  border: 2px groove var(--orange_normal);
}

.border-all-2-ridge-orange_normal {
  border: 2px ridge var(--orange_normal);
}

.border-all-2-inset-orange_normal {
  border: 2px inset var(--orange_normal);
}

.border-all-2-outset-orange_normal {
  border: 2px outset var(--orange_normal);
}

.border-all-2-none-orange_normal {
  border: 2px none var(--orange_normal);
}

.border-all-2-hidden-orange_normal {
  border: 2px hidden var(--orange_normal);
}

.border-all-2-solid-orange_dark {
  border: 2px solid var(--orange_dark);
}

.border-all-2-dashed-orange_dark {
  border: 2px dashed var(--orange_dark);
}

.border-all-2-dotted-orange_dark {
  border: 2px dotted var(--orange_dark);
}

.border-all-2-double-orange_dark {
  border: 2px double var(--orange_dark);
}

.border-all-2-groove-orange_dark {
  border: 2px groove var(--orange_dark);
}

.border-all-2-ridge-orange_dark {
  border: 2px ridge var(--orange_dark);
}

.border-all-2-inset-orange_dark {
  border: 2px inset var(--orange_dark);
}

.border-all-2-outset-orange_dark {
  border: 2px outset var(--orange_dark);
}

.border-all-2-none-orange_dark {
  border: 2px none var(--orange_dark);
}

.border-all-2-hidden-orange_dark {
  border: 2px hidden var(--orange_dark);
}

.border-all-2-solid-vert_light {
  border: 2px solid var(--vert_light);
}

.border-all-2-dashed-vert_light {
  border: 2px dashed var(--vert_light);
}

.border-all-2-dotted-vert_light {
  border: 2px dotted var(--vert_light);
}

.border-all-2-double-vert_light {
  border: 2px double var(--vert_light);
}

.border-all-2-groove-vert_light {
  border: 2px groove var(--vert_light);
}

.border-all-2-ridge-vert_light {
  border: 2px ridge var(--vert_light);
}

.border-all-2-inset-vert_light {
  border: 2px inset var(--vert_light);
}

.border-all-2-outset-vert_light {
  border: 2px outset var(--vert_light);
}

.border-all-2-none-vert_light {
  border: 2px none var(--vert_light);
}

.border-all-2-hidden-vert_light {
  border: 2px hidden var(--vert_light);
}

.border-all-2-solid-vert_dark {
  border: 2px solid var(--vert_dark);
}

.border-all-2-dashed-vert_dark {
  border: 2px dashed var(--vert_dark);
}

.border-all-2-dotted-vert_dark {
  border: 2px dotted var(--vert_dark);
}

.border-all-2-double-vert_dark {
  border: 2px double var(--vert_dark);
}

.border-all-2-groove-vert_dark {
  border: 2px groove var(--vert_dark);
}

.border-all-2-ridge-vert_dark {
  border: 2px ridge var(--vert_dark);
}

.border-all-2-inset-vert_dark {
  border: 2px inset var(--vert_dark);
}

.border-all-2-outset-vert_dark {
  border: 2px outset var(--vert_dark);
}

.border-all-2-none-vert_dark {
  border: 2px none var(--vert_dark);
}

.border-all-2-hidden-vert_dark {
  border: 2px hidden var(--vert_dark);
}

.border-all-2-solid-bleu_turquoise_light {
  border: 2px solid var(--bleu_turquoise_light);
}

.border-all-2-dashed-bleu_turquoise_light {
  border: 2px dashed var(--bleu_turquoise_light);
}

.border-all-2-dotted-bleu_turquoise_light {
  border: 2px dotted var(--bleu_turquoise_light);
}

.border-all-2-double-bleu_turquoise_light {
  border: 2px double var(--bleu_turquoise_light);
}

.border-all-2-groove-bleu_turquoise_light {
  border: 2px groove var(--bleu_turquoise_light);
}

.border-all-2-ridge-bleu_turquoise_light {
  border: 2px ridge var(--bleu_turquoise_light);
}

.border-all-2-inset-bleu_turquoise_light {
  border: 2px inset var(--bleu_turquoise_light);
}

.border-all-2-outset-bleu_turquoise_light {
  border: 2px outset var(--bleu_turquoise_light);
}

.border-all-2-none-bleu_turquoise_light {
  border: 2px none var(--bleu_turquoise_light);
}

.border-all-2-hidden-bleu_turquoise_light {
  border: 2px hidden var(--bleu_turquoise_light);
}

.border-all-2-solid-bleu_turquoise_middle {
  border: 2px solid var(--bleu_turquoise_middle);
}

.border-all-2-dashed-bleu_turquoise_middle {
  border: 2px dashed var(--bleu_turquoise_middle);
}

.border-all-2-dotted-bleu_turquoise_middle {
  border: 2px dotted var(--bleu_turquoise_middle);
}

.border-all-2-double-bleu_turquoise_middle {
  border: 2px double var(--bleu_turquoise_middle);
}

.border-all-2-groove-bleu_turquoise_middle {
  border: 2px groove var(--bleu_turquoise_middle);
}

.border-all-2-ridge-bleu_turquoise_middle {
  border: 2px ridge var(--bleu_turquoise_middle);
}

.border-all-2-inset-bleu_turquoise_middle {
  border: 2px inset var(--bleu_turquoise_middle);
}

.border-all-2-outset-bleu_turquoise_middle {
  border: 2px outset var(--bleu_turquoise_middle);
}

.border-all-2-none-bleu_turquoise_middle {
  border: 2px none var(--bleu_turquoise_middle);
}

.border-all-2-hidden-bleu_turquoise_middle {
  border: 2px hidden var(--bleu_turquoise_middle);
}

.border-all-2-solid-bleu_turquoise_dark {
  border: 2px solid var(--bleu_turquoise_dark);
}

.border-all-2-dashed-bleu_turquoise_dark {
  border: 2px dashed var(--bleu_turquoise_dark);
}

.border-all-2-dotted-bleu_turquoise_dark {
  border: 2px dotted var(--bleu_turquoise_dark);
}

.border-all-2-double-bleu_turquoise_dark {
  border: 2px double var(--bleu_turquoise_dark);
}

.border-all-2-groove-bleu_turquoise_dark {
  border: 2px groove var(--bleu_turquoise_dark);
}

.border-all-2-ridge-bleu_turquoise_dark {
  border: 2px ridge var(--bleu_turquoise_dark);
}

.border-all-2-inset-bleu_turquoise_dark {
  border: 2px inset var(--bleu_turquoise_dark);
}

.border-all-2-outset-bleu_turquoise_dark {
  border: 2px outset var(--bleu_turquoise_dark);
}

.border-all-2-none-bleu_turquoise_dark {
  border: 2px none var(--bleu_turquoise_dark);
}

.border-all-2-hidden-bleu_turquoise_dark {
  border: 2px hidden var(--bleu_turquoise_dark);
}

.border-all-2-solid-bleu_light {
  border: 2px solid var(--bleu_light);
}

.border-all-2-dashed-bleu_light {
  border: 2px dashed var(--bleu_light);
}

.border-all-2-dotted-bleu_light {
  border: 2px dotted var(--bleu_light);
}

.border-all-2-double-bleu_light {
  border: 2px double var(--bleu_light);
}

.border-all-2-groove-bleu_light {
  border: 2px groove var(--bleu_light);
}

.border-all-2-ridge-bleu_light {
  border: 2px ridge var(--bleu_light);
}

.border-all-2-inset-bleu_light {
  border: 2px inset var(--bleu_light);
}

.border-all-2-outset-bleu_light {
  border: 2px outset var(--bleu_light);
}

.border-all-2-none-bleu_light {
  border: 2px none var(--bleu_light);
}

.border-all-2-hidden-bleu_light {
  border: 2px hidden var(--bleu_light);
}

.border-all-2-solid-bleu_dark {
  border: 2px solid var(--bleu_dark);
}

.border-all-2-dashed-bleu_dark {
  border: 2px dashed var(--bleu_dark);
}

.border-all-2-dotted-bleu_dark {
  border: 2px dotted var(--bleu_dark);
}

.border-all-2-double-bleu_dark {
  border: 2px double var(--bleu_dark);
}

.border-all-2-groove-bleu_dark {
  border: 2px groove var(--bleu_dark);
}

.border-all-2-ridge-bleu_dark {
  border: 2px ridge var(--bleu_dark);
}

.border-all-2-inset-bleu_dark {
  border: 2px inset var(--bleu_dark);
}

.border-all-2-outset-bleu_dark {
  border: 2px outset var(--bleu_dark);
}

.border-all-2-none-bleu_dark {
  border: 2px none var(--bleu_dark);
}

.border-all-2-hidden-bleu_dark {
  border: 2px hidden var(--bleu_dark);
}

.border-all-2-solid-bleu_klein_light {
  border: 2px solid var(--bleu_klein_light);
}

.border-all-2-dashed-bleu_klein_light {
  border: 2px dashed var(--bleu_klein_light);
}

.border-all-2-dotted-bleu_klein_light {
  border: 2px dotted var(--bleu_klein_light);
}

.border-all-2-double-bleu_klein_light {
  border: 2px double var(--bleu_klein_light);
}

.border-all-2-groove-bleu_klein_light {
  border: 2px groove var(--bleu_klein_light);
}

.border-all-2-ridge-bleu_klein_light {
  border: 2px ridge var(--bleu_klein_light);
}

.border-all-2-inset-bleu_klein_light {
  border: 2px inset var(--bleu_klein_light);
}

.border-all-2-outset-bleu_klein_light {
  border: 2px outset var(--bleu_klein_light);
}

.border-all-2-none-bleu_klein_light {
  border: 2px none var(--bleu_klein_light);
}

.border-all-2-hidden-bleu_klein_light {
  border: 2px hidden var(--bleu_klein_light);
}

.border-all-2-solid-bleu_klein_dark {
  border: 2px solid var(--bleu_klein_dark);
}

.border-all-2-dashed-bleu_klein_dark {
  border: 2px dashed var(--bleu_klein_dark);
}

.border-all-2-dotted-bleu_klein_dark {
  border: 2px dotted var(--bleu_klein_dark);
}

.border-all-2-double-bleu_klein_dark {
  border: 2px double var(--bleu_klein_dark);
}

.border-all-2-groove-bleu_klein_dark {
  border: 2px groove var(--bleu_klein_dark);
}

.border-all-2-ridge-bleu_klein_dark {
  border: 2px ridge var(--bleu_klein_dark);
}

.border-all-2-inset-bleu_klein_dark {
  border: 2px inset var(--bleu_klein_dark);
}

.border-all-2-outset-bleu_klein_dark {
  border: 2px outset var(--bleu_klein_dark);
}

.border-all-2-none-bleu_klein_dark {
  border: 2px none var(--bleu_klein_dark);
}

.border-all-2-hidden-bleu_klein_dark {
  border: 2px hidden var(--bleu_klein_dark);
}

.border-all-2-solid-violet_light {
  border: 2px solid var(--violet_light);
}

.border-all-2-dashed-violet_light {
  border: 2px dashed var(--violet_light);
}

.border-all-2-dotted-violet_light {
  border: 2px dotted var(--violet_light);
}

.border-all-2-double-violet_light {
  border: 2px double var(--violet_light);
}

.border-all-2-groove-violet_light {
  border: 2px groove var(--violet_light);
}

.border-all-2-ridge-violet_light {
  border: 2px ridge var(--violet_light);
}

.border-all-2-inset-violet_light {
  border: 2px inset var(--violet_light);
}

.border-all-2-outset-violet_light {
  border: 2px outset var(--violet_light);
}

.border-all-2-none-violet_light {
  border: 2px none var(--violet_light);
}

.border-all-2-hidden-violet_light {
  border: 2px hidden var(--violet_light);
}

.border-all-2-solid-violet_dark {
  border: 2px solid var(--violet_dark);
}

.border-all-2-dashed-violet_dark {
  border: 2px dashed var(--violet_dark);
}

.border-all-2-dotted-violet_dark {
  border: 2px dotted var(--violet_dark);
}

.border-all-2-double-violet_dark {
  border: 2px double var(--violet_dark);
}

.border-all-2-groove-violet_dark {
  border: 2px groove var(--violet_dark);
}

.border-all-2-ridge-violet_dark {
  border: 2px ridge var(--violet_dark);
}

.border-all-2-inset-violet_dark {
  border: 2px inset var(--violet_dark);
}

.border-all-2-outset-violet_dark {
  border: 2px outset var(--violet_dark);
}

.border-all-2-none-violet_dark {
  border: 2px none var(--violet_dark);
}

.border-all-2-hidden-violet_dark {
  border: 2px hidden var(--violet_dark);
}

.border-all-2-solid-mauve {
  border: 2px solid var(--mauve);
}

.border-all-2-dashed-mauve {
  border: 2px dashed var(--mauve);
}

.border-all-2-dotted-mauve {
  border: 2px dotted var(--mauve);
}

.border-all-2-double-mauve {
  border: 2px double var(--mauve);
}

.border-all-2-groove-mauve {
  border: 2px groove var(--mauve);
}

.border-all-2-ridge-mauve {
  border: 2px ridge var(--mauve);
}

.border-all-2-inset-mauve {
  border: 2px inset var(--mauve);
}

.border-all-2-outset-mauve {
  border: 2px outset var(--mauve);
}

.border-all-2-none-mauve {
  border: 2px none var(--mauve);
}

.border-all-2-hidden-mauve {
  border: 2px hidden var(--mauve);
}

.border-all-2-solid-rose_light {
  border: 2px solid var(--rose_light);
}

.border-all-2-dashed-rose_light {
  border: 2px dashed var(--rose_light);
}

.border-all-2-dotted-rose_light {
  border: 2px dotted var(--rose_light);
}

.border-all-2-double-rose_light {
  border: 2px double var(--rose_light);
}

.border-all-2-groove-rose_light {
  border: 2px groove var(--rose_light);
}

.border-all-2-ridge-rose_light {
  border: 2px ridge var(--rose_light);
}

.border-all-2-inset-rose_light {
  border: 2px inset var(--rose_light);
}

.border-all-2-outset-rose_light {
  border: 2px outset var(--rose_light);
}

.border-all-2-none-rose_light {
  border: 2px none var(--rose_light);
}

.border-all-2-hidden-rose_light {
  border: 2px hidden var(--rose_light);
}

.border-all-2-solid-rose_dark {
  border: 2px solid var(--rose_dark);
}

.border-all-2-dashed-rose_dark {
  border: 2px dashed var(--rose_dark);
}

.border-all-2-dotted-rose_dark {
  border: 2px dotted var(--rose_dark);
}

.border-all-2-double-rose_dark {
  border: 2px double var(--rose_dark);
}

.border-all-2-groove-rose_dark {
  border: 2px groove var(--rose_dark);
}

.border-all-2-ridge-rose_dark {
  border: 2px ridge var(--rose_dark);
}

.border-all-2-inset-rose_dark {
  border: 2px inset var(--rose_dark);
}

.border-all-2-outset-rose_dark {
  border: 2px outset var(--rose_dark);
}

.border-all-2-none-rose_dark {
  border: 2px none var(--rose_dark);
}

.border-all-2-hidden-rose_dark {
  border: 2px hidden var(--rose_dark);
}

.border-all-2-solid-rose_fushia {
  border: 2px solid var(--rose_fushia);
}

.border-all-2-dashed-rose_fushia {
  border: 2px dashed var(--rose_fushia);
}

.border-all-2-dotted-rose_fushia {
  border: 2px dotted var(--rose_fushia);
}

.border-all-2-double-rose_fushia {
  border: 2px double var(--rose_fushia);
}

.border-all-2-groove-rose_fushia {
  border: 2px groove var(--rose_fushia);
}

.border-all-2-ridge-rose_fushia {
  border: 2px ridge var(--rose_fushia);
}

.border-all-2-inset-rose_fushia {
  border: 2px inset var(--rose_fushia);
}

.border-all-2-outset-rose_fushia {
  border: 2px outset var(--rose_fushia);
}

.border-all-2-none-rose_fushia {
  border: 2px none var(--rose_fushia);
}

.border-all-2-hidden-rose_fushia {
  border: 2px hidden var(--rose_fushia);
}

.border-all-2-solid-rouge_framboise {
  border: 2px solid var(--rouge_framboise);
}

.border-all-2-dashed-rouge_framboise {
  border: 2px dashed var(--rouge_framboise);
}

.border-all-2-dotted-rouge_framboise {
  border: 2px dotted var(--rouge_framboise);
}

.border-all-2-double-rouge_framboise {
  border: 2px double var(--rouge_framboise);
}

.border-all-2-groove-rouge_framboise {
  border: 2px groove var(--rouge_framboise);
}

.border-all-2-ridge-rouge_framboise {
  border: 2px ridge var(--rouge_framboise);
}

.border-all-2-inset-rouge_framboise {
  border: 2px inset var(--rouge_framboise);
}

.border-all-2-outset-rouge_framboise {
  border: 2px outset var(--rouge_framboise);
}

.border-all-2-none-rouge_framboise {
  border: 2px none var(--rouge_framboise);
}

.border-all-2-hidden-rouge_framboise {
  border: 2px hidden var(--rouge_framboise);
}

.border-all-2-solid-rose_chair_light {
  border: 2px solid var(--rose_chair_light);
}

.border-all-2-dashed-rose_chair_light {
  border: 2px dashed var(--rose_chair_light);
}

.border-all-2-dotted-rose_chair_light {
  border: 2px dotted var(--rose_chair_light);
}

.border-all-2-double-rose_chair_light {
  border: 2px double var(--rose_chair_light);
}

.border-all-2-groove-rose_chair_light {
  border: 2px groove var(--rose_chair_light);
}

.border-all-2-ridge-rose_chair_light {
  border: 2px ridge var(--rose_chair_light);
}

.border-all-2-inset-rose_chair_light {
  border: 2px inset var(--rose_chair_light);
}

.border-all-2-outset-rose_chair_light {
  border: 2px outset var(--rose_chair_light);
}

.border-all-2-none-rose_chair_light {
  border: 2px none var(--rose_chair_light);
}

.border-all-2-hidden-rose_chair_light {
  border: 2px hidden var(--rose_chair_light);
}

.border-all-2-solid-rose_chair_dark {
  border: 2px solid var(--rose_chair_dark);
}

.border-all-2-dashed-rose_chair_dark {
  border: 2px dashed var(--rose_chair_dark);
}

.border-all-2-dotted-rose_chair_dark {
  border: 2px dotted var(--rose_chair_dark);
}

.border-all-2-double-rose_chair_dark {
  border: 2px double var(--rose_chair_dark);
}

.border-all-2-groove-rose_chair_dark {
  border: 2px groove var(--rose_chair_dark);
}

.border-all-2-ridge-rose_chair_dark {
  border: 2px ridge var(--rose_chair_dark);
}

.border-all-2-inset-rose_chair_dark {
  border: 2px inset var(--rose_chair_dark);
}

.border-all-2-outset-rose_chair_dark {
  border: 2px outset var(--rose_chair_dark);
}

.border-all-2-none-rose_chair_dark {
  border: 2px none var(--rose_chair_dark);
}

.border-all-2-hidden-rose_chair_dark {
  border: 2px hidden var(--rose_chair_dark);
}

.border-all-2-solid-vert_positif {
  border: 2px solid var(--vert_positif);
}

.border-all-2-dashed-vert_positif {
  border: 2px dashed var(--vert_positif);
}

.border-all-2-dotted-vert_positif {
  border: 2px dotted var(--vert_positif);
}

.border-all-2-double-vert_positif {
  border: 2px double var(--vert_positif);
}

.border-all-2-groove-vert_positif {
  border: 2px groove var(--vert_positif);
}

.border-all-2-ridge-vert_positif {
  border: 2px ridge var(--vert_positif);
}

.border-all-2-inset-vert_positif {
  border: 2px inset var(--vert_positif);
}

.border-all-2-outset-vert_positif {
  border: 2px outset var(--vert_positif);
}

.border-all-2-none-vert_positif {
  border: 2px none var(--vert_positif);
}

.border-all-2-hidden-vert_positif {
  border: 2px hidden var(--vert_positif);
}

.border-all-2-solid-rouge_negatif {
  border: 2px solid var(--rouge_negatif);
}

.border-all-2-dashed-rouge_negatif {
  border: 2px dashed var(--rouge_negatif);
}

.border-all-2-dotted-rouge_negatif {
  border: 2px dotted var(--rouge_negatif);
}

.border-all-2-double-rouge_negatif {
  border: 2px double var(--rouge_negatif);
}

.border-all-2-groove-rouge_negatif {
  border: 2px groove var(--rouge_negatif);
}

.border-all-2-ridge-rouge_negatif {
  border: 2px ridge var(--rouge_negatif);
}

.border-all-2-inset-rouge_negatif {
  border: 2px inset var(--rouge_negatif);
}

.border-all-2-outset-rouge_negatif {
  border: 2px outset var(--rouge_negatif);
}

.border-all-2-none-rouge_negatif {
  border: 2px none var(--rouge_negatif);
}

.border-all-2-hidden-rouge_negatif {
  border: 2px hidden var(--rouge_negatif);
}

.border-all-2-solid-bleu_texte {
  border: 2px solid var(--bleu_texte);
}

.border-all-2-dashed-bleu_texte {
  border: 2px dashed var(--bleu_texte);
}

.border-all-2-dotted-bleu_texte {
  border: 2px dotted var(--bleu_texte);
}

.border-all-2-double-bleu_texte {
  border: 2px double var(--bleu_texte);
}

.border-all-2-groove-bleu_texte {
  border: 2px groove var(--bleu_texte);
}

.border-all-2-ridge-bleu_texte {
  border: 2px ridge var(--bleu_texte);
}

.border-all-2-inset-bleu_texte {
  border: 2px inset var(--bleu_texte);
}

.border-all-2-outset-bleu_texte {
  border: 2px outset var(--bleu_texte);
}

.border-all-2-none-bleu_texte {
  border: 2px none var(--bleu_texte);
}

.border-all-2-hidden-bleu_texte {
  border: 2px hidden var(--bleu_texte);
}

.border-all-2-solid-alerte_vert_positif {
  border: 2px solid var(--alerte_vert_positif);
}

.border-all-2-dashed-alerte_vert_positif {
  border: 2px dashed var(--alerte_vert_positif);
}

.border-all-2-dotted-alerte_vert_positif {
  border: 2px dotted var(--alerte_vert_positif);
}

.border-all-2-double-alerte_vert_positif {
  border: 2px double var(--alerte_vert_positif);
}

.border-all-2-groove-alerte_vert_positif {
  border: 2px groove var(--alerte_vert_positif);
}

.border-all-2-ridge-alerte_vert_positif {
  border: 2px ridge var(--alerte_vert_positif);
}

.border-all-2-inset-alerte_vert_positif {
  border: 2px inset var(--alerte_vert_positif);
}

.border-all-2-outset-alerte_vert_positif {
  border: 2px outset var(--alerte_vert_positif);
}

.border-all-2-none-alerte_vert_positif {
  border: 2px none var(--alerte_vert_positif);
}

.border-all-2-hidden-alerte_vert_positif {
  border: 2px hidden var(--alerte_vert_positif);
}

.border-all-2-solid-alerte_rouge_negatif {
  border: 2px solid var(--alerte_rouge_negatif);
}

.border-all-2-dashed-alerte_rouge_negatif {
  border: 2px dashed var(--alerte_rouge_negatif);
}

.border-all-2-dotted-alerte_rouge_negatif {
  border: 2px dotted var(--alerte_rouge_negatif);
}

.border-all-2-double-alerte_rouge_negatif {
  border: 2px double var(--alerte_rouge_negatif);
}

.border-all-2-groove-alerte_rouge_negatif {
  border: 2px groove var(--alerte_rouge_negatif);
}

.border-all-2-ridge-alerte_rouge_negatif {
  border: 2px ridge var(--alerte_rouge_negatif);
}

.border-all-2-inset-alerte_rouge_negatif {
  border: 2px inset var(--alerte_rouge_negatif);
}

.border-all-2-outset-alerte_rouge_negatif {
  border: 2px outset var(--alerte_rouge_negatif);
}

.border-all-2-none-alerte_rouge_negatif {
  border: 2px none var(--alerte_rouge_negatif);
}

.border-all-2-hidden-alerte_rouge_negatif {
  border: 2px hidden var(--alerte_rouge_negatif);
}

.border-all-2-solid-alerte_bleu_information {
  border: 2px solid var(--alerte_bleu_information);
}

.border-all-2-dashed-alerte_bleu_information {
  border: 2px dashed var(--alerte_bleu_information);
}

.border-all-2-dotted-alerte_bleu_information {
  border: 2px dotted var(--alerte_bleu_information);
}

.border-all-2-double-alerte_bleu_information {
  border: 2px double var(--alerte_bleu_information);
}

.border-all-2-groove-alerte_bleu_information {
  border: 2px groove var(--alerte_bleu_information);
}

.border-all-2-ridge-alerte_bleu_information {
  border: 2px ridge var(--alerte_bleu_information);
}

.border-all-2-inset-alerte_bleu_information {
  border: 2px inset var(--alerte_bleu_information);
}

.border-all-2-outset-alerte_bleu_information {
  border: 2px outset var(--alerte_bleu_information);
}

.border-all-2-none-alerte_bleu_information {
  border: 2px none var(--alerte_bleu_information);
}

.border-all-2-hidden-alerte_bleu_information {
  border: 2px hidden var(--alerte_bleu_information);
}

.border-all-2-solid-alerte_jaune_attention {
  border: 2px solid var(--alerte_jaune_attention);
}

.border-all-2-dashed-alerte_jaune_attention {
  border: 2px dashed var(--alerte_jaune_attention);
}

.border-all-2-dotted-alerte_jaune_attention {
  border: 2px dotted var(--alerte_jaune_attention);
}

.border-all-2-double-alerte_jaune_attention {
  border: 2px double var(--alerte_jaune_attention);
}

.border-all-2-groove-alerte_jaune_attention {
  border: 2px groove var(--alerte_jaune_attention);
}

.border-all-2-ridge-alerte_jaune_attention {
  border: 2px ridge var(--alerte_jaune_attention);
}

.border-all-2-inset-alerte_jaune_attention {
  border: 2px inset var(--alerte_jaune_attention);
}

.border-all-2-outset-alerte_jaune_attention {
  border: 2px outset var(--alerte_jaune_attention);
}

.border-all-2-none-alerte_jaune_attention {
  border: 2px none var(--alerte_jaune_attention);
}

.border-all-2-hidden-alerte_jaune_attention {
  border: 2px hidden var(--alerte_jaune_attention);
}

.border-all-2-solid-blanc {
  border: 2px solid #fff;
}

.border-all-2-dashed-blanc {
  border: 2px dashed #fff;
}

.border-all-2-dotted-blanc {
  border: 2px dotted #fff;
}

.border-all-2-double-blanc {
  border: 2px double #fff;
}

.border-all-2-groove-blanc {
  border: 2px groove #fff;
}

.border-all-2-ridge-blanc {
  border: 2px ridge #fff;
}

.border-all-2-inset-blanc {
  border: 2px inset #fff;
}

.border-all-2-outset-blanc {
  border: 2px outset #fff;
}

.border-all-2-none-blanc {
  border: 2px #fff;
}

.border-all-2-hidden-blanc {
  border: 2px hidden #fff;
}

.border-all-2-solid-noir {
  border: 2px solid #222;
}

.border-all-2-dashed-noir {
  border: 2px dashed #222;
}

.border-all-2-dotted-noir {
  border: 2px dotted #222;
}

.border-all-2-double-noir {
  border: 2px double #222;
}

.border-all-2-groove-noir {
  border: 2px groove #222;
}

.border-all-2-ridge-noir {
  border: 2px ridge #222;
}

.border-all-2-inset-noir {
  border: 2px inset #222;
}

.border-all-2-outset-noir {
  border: 2px outset #222;
}

.border-all-2-none-noir {
  border: 2px #222;
}

.border-all-2-hidden-noir {
  border: 2px hidden #222;
}

.border-all-2-solid-gris_light {
  border: 2px solid var(--gris_light);
}

.border-all-2-dashed-gris_light {
  border: 2px dashed var(--gris_light);
}

.border-all-2-dotted-gris_light {
  border: 2px dotted var(--gris_light);
}

.border-all-2-double-gris_light {
  border: 2px double var(--gris_light);
}

.border-all-2-groove-gris_light {
  border: 2px groove var(--gris_light);
}

.border-all-2-ridge-gris_light {
  border: 2px ridge var(--gris_light);
}

.border-all-2-inset-gris_light {
  border: 2px inset var(--gris_light);
}

.border-all-2-outset-gris_light {
  border: 2px outset var(--gris_light);
}

.border-all-2-none-gris_light {
  border: 2px none var(--gris_light);
}

.border-all-2-hidden-gris_light {
  border: 2px hidden var(--gris_light);
}

.border-all-2-solid-gris_dark {
  border: 2px solid var(--gris_dark);
}

.border-all-2-dashed-gris_dark {
  border: 2px dashed var(--gris_dark);
}

.border-all-2-dotted-gris_dark {
  border: 2px dotted var(--gris_dark);
}

.border-all-2-double-gris_dark {
  border: 2px double var(--gris_dark);
}

.border-all-2-groove-gris_dark {
  border: 2px groove var(--gris_dark);
}

.border-all-2-ridge-gris_dark {
  border: 2px ridge var(--gris_dark);
}

.border-all-2-inset-gris_dark {
  border: 2px inset var(--gris_dark);
}

.border-all-2-outset-gris_dark {
  border: 2px outset var(--gris_dark);
}

.border-all-2-none-gris_dark {
  border: 2px none var(--gris_dark);
}

.border-all-2-hidden-gris_dark {
  border: 2px hidden var(--gris_dark);
}

.border-all-2-solid-gris_background {
  border: 2px solid var(--gris_background);
}

.border-all-2-dashed-gris_background {
  border: 2px dashed var(--gris_background);
}

.border-all-2-dotted-gris_background {
  border: 2px dotted var(--gris_background);
}

.border-all-2-double-gris_background {
  border: 2px double var(--gris_background);
}

.border-all-2-groove-gris_background {
  border: 2px groove var(--gris_background);
}

.border-all-2-ridge-gris_background {
  border: 2px ridge var(--gris_background);
}

.border-all-2-inset-gris_background {
  border: 2px inset var(--gris_background);
}

.border-all-2-outset-gris_background {
  border: 2px outset var(--gris_background);
}

.border-all-2-none-gris_background {
  border: 2px none var(--gris_background);
}

.border-all-2-hidden-gris_background {
  border: 2px hidden var(--gris_background);
}

.border-top-3-solid-corail_mh_brand {
  border-top: 3px solid var(--corail_mh_brand);
}

.border-top-3-dashed-corail_mh_brand {
  border-top: 3px dashed var(--corail_mh_brand);
}

.border-top-3-dotted-corail_mh_brand {
  border-top: 3px dotted var(--corail_mh_brand);
}

.border-top-3-double-corail_mh_brand {
  border-top: 3px double var(--corail_mh_brand);
}

.border-top-3-groove-corail_mh_brand {
  border-top: 3px groove var(--corail_mh_brand);
}

.border-top-3-ridge-corail_mh_brand {
  border-top: 3px ridge var(--corail_mh_brand);
}

.border-top-3-inset-corail_mh_brand {
  border-top: 3px inset var(--corail_mh_brand);
}

.border-top-3-outset-corail_mh_brand {
  border-top: 3px outset var(--corail_mh_brand);
}

.border-top-3-none-corail_mh_brand {
  border-top: 3px none var(--corail_mh_brand);
}

.border-top-3-hidden-corail_mh_brand {
  border-top: 3px hidden var(--corail_mh_brand);
}

.border-top-3-solid-corail_mh_dark {
  border-top: 3px solid var(--corail_mh_dark);
}

.border-top-3-dashed-corail_mh_dark {
  border-top: 3px dashed var(--corail_mh_dark);
}

.border-top-3-dotted-corail_mh_dark {
  border-top: 3px dotted var(--corail_mh_dark);
}

.border-top-3-double-corail_mh_dark {
  border-top: 3px double var(--corail_mh_dark);
}

.border-top-3-groove-corail_mh_dark {
  border-top: 3px groove var(--corail_mh_dark);
}

.border-top-3-ridge-corail_mh_dark {
  border-top: 3px ridge var(--corail_mh_dark);
}

.border-top-3-inset-corail_mh_dark {
  border-top: 3px inset var(--corail_mh_dark);
}

.border-top-3-outset-corail_mh_dark {
  border-top: 3px outset var(--corail_mh_dark);
}

.border-top-3-none-corail_mh_dark {
  border-top: 3px none var(--corail_mh_dark);
}

.border-top-3-hidden-corail_mh_dark {
  border-top: 3px hidden var(--corail_mh_dark);
}

.border-top-3-solid-corail_mh_light {
  border-top: 3px solid var(--corail_mh_light);
}

.border-top-3-dashed-corail_mh_light {
  border-top: 3px dashed var(--corail_mh_light);
}

.border-top-3-dotted-corail_mh_light {
  border-top: 3px dotted var(--corail_mh_light);
}

.border-top-3-double-corail_mh_light {
  border-top: 3px double var(--corail_mh_light);
}

.border-top-3-groove-corail_mh_light {
  border-top: 3px groove var(--corail_mh_light);
}

.border-top-3-ridge-corail_mh_light {
  border-top: 3px ridge var(--corail_mh_light);
}

.border-top-3-inset-corail_mh_light {
  border-top: 3px inset var(--corail_mh_light);
}

.border-top-3-outset-corail_mh_light {
  border-top: 3px outset var(--corail_mh_light);
}

.border-top-3-none-corail_mh_light {
  border-top: 3px none var(--corail_mh_light);
}

.border-top-3-hidden-corail_mh_light {
  border-top: 3px hidden var(--corail_mh_light);
}

.border-top-3-solid-gris_sable_light {
  border-top: 3px solid var(--gris_sable_light);
}

.border-top-3-dashed-gris_sable_light {
  border-top: 3px dashed var(--gris_sable_light);
}

.border-top-3-dotted-gris_sable_light {
  border-top: 3px dotted var(--gris_sable_light);
}

.border-top-3-double-gris_sable_light {
  border-top: 3px double var(--gris_sable_light);
}

.border-top-3-groove-gris_sable_light {
  border-top: 3px groove var(--gris_sable_light);
}

.border-top-3-ridge-gris_sable_light {
  border-top: 3px ridge var(--gris_sable_light);
}

.border-top-3-inset-gris_sable_light {
  border-top: 3px inset var(--gris_sable_light);
}

.border-top-3-outset-gris_sable_light {
  border-top: 3px outset var(--gris_sable_light);
}

.border-top-3-none-gris_sable_light {
  border-top: 3px none var(--gris_sable_light);
}

.border-top-3-hidden-gris_sable_light {
  border-top: 3px hidden var(--gris_sable_light);
}

.border-top-3-solid-gris_sable_dark {
  border-top: 3px solid var(--gris_sable_dark);
}

.border-top-3-dashed-gris_sable_dark {
  border-top: 3px dashed var(--gris_sable_dark);
}

.border-top-3-dotted-gris_sable_dark {
  border-top: 3px dotted var(--gris_sable_dark);
}

.border-top-3-double-gris_sable_dark {
  border-top: 3px double var(--gris_sable_dark);
}

.border-top-3-groove-gris_sable_dark {
  border-top: 3px groove var(--gris_sable_dark);
}

.border-top-3-ridge-gris_sable_dark {
  border-top: 3px ridge var(--gris_sable_dark);
}

.border-top-3-inset-gris_sable_dark {
  border-top: 3px inset var(--gris_sable_dark);
}

.border-top-3-outset-gris_sable_dark {
  border-top: 3px outset var(--gris_sable_dark);
}

.border-top-3-none-gris_sable_dark {
  border-top: 3px none var(--gris_sable_dark);
}

.border-top-3-hidden-gris_sable_dark {
  border-top: 3px hidden var(--gris_sable_dark);
}

.border-top-3-solid-jaune_ivoire_light {
  border-top: 3px solid var(--jaune_ivoire_light);
}

.border-top-3-dashed-jaune_ivoire_light {
  border-top: 3px dashed var(--jaune_ivoire_light);
}

.border-top-3-dotted-jaune_ivoire_light {
  border-top: 3px dotted var(--jaune_ivoire_light);
}

.border-top-3-double-jaune_ivoire_light {
  border-top: 3px double var(--jaune_ivoire_light);
}

.border-top-3-groove-jaune_ivoire_light {
  border-top: 3px groove var(--jaune_ivoire_light);
}

.border-top-3-ridge-jaune_ivoire_light {
  border-top: 3px ridge var(--jaune_ivoire_light);
}

.border-top-3-inset-jaune_ivoire_light {
  border-top: 3px inset var(--jaune_ivoire_light);
}

.border-top-3-outset-jaune_ivoire_light {
  border-top: 3px outset var(--jaune_ivoire_light);
}

.border-top-3-none-jaune_ivoire_light {
  border-top: 3px none var(--jaune_ivoire_light);
}

.border-top-3-hidden-jaune_ivoire_light {
  border-top: 3px hidden var(--jaune_ivoire_light);
}

.border-top-3-solid-jaune_ivoire_dark {
  border-top: 3px solid var(--jaune_ivoire_dark);
}

.border-top-3-dashed-jaune_ivoire_dark {
  border-top: 3px dashed var(--jaune_ivoire_dark);
}

.border-top-3-dotted-jaune_ivoire_dark {
  border-top: 3px dotted var(--jaune_ivoire_dark);
}

.border-top-3-double-jaune_ivoire_dark {
  border-top: 3px double var(--jaune_ivoire_dark);
}

.border-top-3-groove-jaune_ivoire_dark {
  border-top: 3px groove var(--jaune_ivoire_dark);
}

.border-top-3-ridge-jaune_ivoire_dark {
  border-top: 3px ridge var(--jaune_ivoire_dark);
}

.border-top-3-inset-jaune_ivoire_dark {
  border-top: 3px inset var(--jaune_ivoire_dark);
}

.border-top-3-outset-jaune_ivoire_dark {
  border-top: 3px outset var(--jaune_ivoire_dark);
}

.border-top-3-none-jaune_ivoire_dark {
  border-top: 3px none var(--jaune_ivoire_dark);
}

.border-top-3-hidden-jaune_ivoire_dark {
  border-top: 3px hidden var(--jaune_ivoire_dark);
}

.border-top-3-solid-jaune_light {
  border-top: 3px solid var(--jaune_light);
}

.border-top-3-dashed-jaune_light {
  border-top: 3px dashed var(--jaune_light);
}

.border-top-3-dotted-jaune_light {
  border-top: 3px dotted var(--jaune_light);
}

.border-top-3-double-jaune_light {
  border-top: 3px double var(--jaune_light);
}

.border-top-3-groove-jaune_light {
  border-top: 3px groove var(--jaune_light);
}

.border-top-3-ridge-jaune_light {
  border-top: 3px ridge var(--jaune_light);
}

.border-top-3-inset-jaune_light {
  border-top: 3px inset var(--jaune_light);
}

.border-top-3-outset-jaune_light {
  border-top: 3px outset var(--jaune_light);
}

.border-top-3-none-jaune_light {
  border-top: 3px none var(--jaune_light);
}

.border-top-3-hidden-jaune_light {
  border-top: 3px hidden var(--jaune_light);
}

.border-top-3-solid-jaune_dark {
  border-top: 3px solid var(--jaune_dark);
}

.border-top-3-dashed-jaune_dark {
  border-top: 3px dashed var(--jaune_dark);
}

.border-top-3-dotted-jaune_dark {
  border-top: 3px dotted var(--jaune_dark);
}

.border-top-3-double-jaune_dark {
  border-top: 3px double var(--jaune_dark);
}

.border-top-3-groove-jaune_dark {
  border-top: 3px groove var(--jaune_dark);
}

.border-top-3-ridge-jaune_dark {
  border-top: 3px ridge var(--jaune_dark);
}

.border-top-3-inset-jaune_dark {
  border-top: 3px inset var(--jaune_dark);
}

.border-top-3-outset-jaune_dark {
  border-top: 3px outset var(--jaune_dark);
}

.border-top-3-none-jaune_dark {
  border-top: 3px none var(--jaune_dark);
}

.border-top-3-hidden-jaune_dark {
  border-top: 3px hidden var(--jaune_dark);
}

.border-top-3-solid-orange_normal {
  border-top: 3px solid var(--orange_normal);
}

.border-top-3-dashed-orange_normal {
  border-top: 3px dashed var(--orange_normal);
}

.border-top-3-dotted-orange_normal {
  border-top: 3px dotted var(--orange_normal);
}

.border-top-3-double-orange_normal {
  border-top: 3px double var(--orange_normal);
}

.border-top-3-groove-orange_normal {
  border-top: 3px groove var(--orange_normal);
}

.border-top-3-ridge-orange_normal {
  border-top: 3px ridge var(--orange_normal);
}

.border-top-3-inset-orange_normal {
  border-top: 3px inset var(--orange_normal);
}

.border-top-3-outset-orange_normal {
  border-top: 3px outset var(--orange_normal);
}

.border-top-3-none-orange_normal {
  border-top: 3px none var(--orange_normal);
}

.border-top-3-hidden-orange_normal {
  border-top: 3px hidden var(--orange_normal);
}

.border-top-3-solid-orange_dark {
  border-top: 3px solid var(--orange_dark);
}

.border-top-3-dashed-orange_dark {
  border-top: 3px dashed var(--orange_dark);
}

.border-top-3-dotted-orange_dark {
  border-top: 3px dotted var(--orange_dark);
}

.border-top-3-double-orange_dark {
  border-top: 3px double var(--orange_dark);
}

.border-top-3-groove-orange_dark {
  border-top: 3px groove var(--orange_dark);
}

.border-top-3-ridge-orange_dark {
  border-top: 3px ridge var(--orange_dark);
}

.border-top-3-inset-orange_dark {
  border-top: 3px inset var(--orange_dark);
}

.border-top-3-outset-orange_dark {
  border-top: 3px outset var(--orange_dark);
}

.border-top-3-none-orange_dark {
  border-top: 3px none var(--orange_dark);
}

.border-top-3-hidden-orange_dark {
  border-top: 3px hidden var(--orange_dark);
}

.border-top-3-solid-vert_light {
  border-top: 3px solid var(--vert_light);
}

.border-top-3-dashed-vert_light {
  border-top: 3px dashed var(--vert_light);
}

.border-top-3-dotted-vert_light {
  border-top: 3px dotted var(--vert_light);
}

.border-top-3-double-vert_light {
  border-top: 3px double var(--vert_light);
}

.border-top-3-groove-vert_light {
  border-top: 3px groove var(--vert_light);
}

.border-top-3-ridge-vert_light {
  border-top: 3px ridge var(--vert_light);
}

.border-top-3-inset-vert_light {
  border-top: 3px inset var(--vert_light);
}

.border-top-3-outset-vert_light {
  border-top: 3px outset var(--vert_light);
}

.border-top-3-none-vert_light {
  border-top: 3px none var(--vert_light);
}

.border-top-3-hidden-vert_light {
  border-top: 3px hidden var(--vert_light);
}

.border-top-3-solid-vert_dark {
  border-top: 3px solid var(--vert_dark);
}

.border-top-3-dashed-vert_dark {
  border-top: 3px dashed var(--vert_dark);
}

.border-top-3-dotted-vert_dark {
  border-top: 3px dotted var(--vert_dark);
}

.border-top-3-double-vert_dark {
  border-top: 3px double var(--vert_dark);
}

.border-top-3-groove-vert_dark {
  border-top: 3px groove var(--vert_dark);
}

.border-top-3-ridge-vert_dark {
  border-top: 3px ridge var(--vert_dark);
}

.border-top-3-inset-vert_dark {
  border-top: 3px inset var(--vert_dark);
}

.border-top-3-outset-vert_dark {
  border-top: 3px outset var(--vert_dark);
}

.border-top-3-none-vert_dark {
  border-top: 3px none var(--vert_dark);
}

.border-top-3-hidden-vert_dark {
  border-top: 3px hidden var(--vert_dark);
}

.border-top-3-solid-bleu_turquoise_light {
  border-top: 3px solid var(--bleu_turquoise_light);
}

.border-top-3-dashed-bleu_turquoise_light {
  border-top: 3px dashed var(--bleu_turquoise_light);
}

.border-top-3-dotted-bleu_turquoise_light {
  border-top: 3px dotted var(--bleu_turquoise_light);
}

.border-top-3-double-bleu_turquoise_light {
  border-top: 3px double var(--bleu_turquoise_light);
}

.border-top-3-groove-bleu_turquoise_light {
  border-top: 3px groove var(--bleu_turquoise_light);
}

.border-top-3-ridge-bleu_turquoise_light {
  border-top: 3px ridge var(--bleu_turquoise_light);
}

.border-top-3-inset-bleu_turquoise_light {
  border-top: 3px inset var(--bleu_turquoise_light);
}

.border-top-3-outset-bleu_turquoise_light {
  border-top: 3px outset var(--bleu_turquoise_light);
}

.border-top-3-none-bleu_turquoise_light {
  border-top: 3px none var(--bleu_turquoise_light);
}

.border-top-3-hidden-bleu_turquoise_light {
  border-top: 3px hidden var(--bleu_turquoise_light);
}

.border-top-3-solid-bleu_turquoise_middle {
  border-top: 3px solid var(--bleu_turquoise_middle);
}

.border-top-3-dashed-bleu_turquoise_middle {
  border-top: 3px dashed var(--bleu_turquoise_middle);
}

.border-top-3-dotted-bleu_turquoise_middle {
  border-top: 3px dotted var(--bleu_turquoise_middle);
}

.border-top-3-double-bleu_turquoise_middle {
  border-top: 3px double var(--bleu_turquoise_middle);
}

.border-top-3-groove-bleu_turquoise_middle {
  border-top: 3px groove var(--bleu_turquoise_middle);
}

.border-top-3-ridge-bleu_turquoise_middle {
  border-top: 3px ridge var(--bleu_turquoise_middle);
}

.border-top-3-inset-bleu_turquoise_middle {
  border-top: 3px inset var(--bleu_turquoise_middle);
}

.border-top-3-outset-bleu_turquoise_middle {
  border-top: 3px outset var(--bleu_turquoise_middle);
}

.border-top-3-none-bleu_turquoise_middle {
  border-top: 3px none var(--bleu_turquoise_middle);
}

.border-top-3-hidden-bleu_turquoise_middle {
  border-top: 3px hidden var(--bleu_turquoise_middle);
}

.border-top-3-solid-bleu_turquoise_dark {
  border-top: 3px solid var(--bleu_turquoise_dark);
}

.border-top-3-dashed-bleu_turquoise_dark {
  border-top: 3px dashed var(--bleu_turquoise_dark);
}

.border-top-3-dotted-bleu_turquoise_dark {
  border-top: 3px dotted var(--bleu_turquoise_dark);
}

.border-top-3-double-bleu_turquoise_dark {
  border-top: 3px double var(--bleu_turquoise_dark);
}

.border-top-3-groove-bleu_turquoise_dark {
  border-top: 3px groove var(--bleu_turquoise_dark);
}

.border-top-3-ridge-bleu_turquoise_dark {
  border-top: 3px ridge var(--bleu_turquoise_dark);
}

.border-top-3-inset-bleu_turquoise_dark {
  border-top: 3px inset var(--bleu_turquoise_dark);
}

.border-top-3-outset-bleu_turquoise_dark {
  border-top: 3px outset var(--bleu_turquoise_dark);
}

.border-top-3-none-bleu_turquoise_dark {
  border-top: 3px none var(--bleu_turquoise_dark);
}

.border-top-3-hidden-bleu_turquoise_dark {
  border-top: 3px hidden var(--bleu_turquoise_dark);
}

.border-top-3-solid-bleu_light {
  border-top: 3px solid var(--bleu_light);
}

.border-top-3-dashed-bleu_light {
  border-top: 3px dashed var(--bleu_light);
}

.border-top-3-dotted-bleu_light {
  border-top: 3px dotted var(--bleu_light);
}

.border-top-3-double-bleu_light {
  border-top: 3px double var(--bleu_light);
}

.border-top-3-groove-bleu_light {
  border-top: 3px groove var(--bleu_light);
}

.border-top-3-ridge-bleu_light {
  border-top: 3px ridge var(--bleu_light);
}

.border-top-3-inset-bleu_light {
  border-top: 3px inset var(--bleu_light);
}

.border-top-3-outset-bleu_light {
  border-top: 3px outset var(--bleu_light);
}

.border-top-3-none-bleu_light {
  border-top: 3px none var(--bleu_light);
}

.border-top-3-hidden-bleu_light {
  border-top: 3px hidden var(--bleu_light);
}

.border-top-3-solid-bleu_dark {
  border-top: 3px solid var(--bleu_dark);
}

.border-top-3-dashed-bleu_dark {
  border-top: 3px dashed var(--bleu_dark);
}

.border-top-3-dotted-bleu_dark {
  border-top: 3px dotted var(--bleu_dark);
}

.border-top-3-double-bleu_dark {
  border-top: 3px double var(--bleu_dark);
}

.border-top-3-groove-bleu_dark {
  border-top: 3px groove var(--bleu_dark);
}

.border-top-3-ridge-bleu_dark {
  border-top: 3px ridge var(--bleu_dark);
}

.border-top-3-inset-bleu_dark {
  border-top: 3px inset var(--bleu_dark);
}

.border-top-3-outset-bleu_dark {
  border-top: 3px outset var(--bleu_dark);
}

.border-top-3-none-bleu_dark {
  border-top: 3px none var(--bleu_dark);
}

.border-top-3-hidden-bleu_dark {
  border-top: 3px hidden var(--bleu_dark);
}

.border-top-3-solid-bleu_klein_light {
  border-top: 3px solid var(--bleu_klein_light);
}

.border-top-3-dashed-bleu_klein_light {
  border-top: 3px dashed var(--bleu_klein_light);
}

.border-top-3-dotted-bleu_klein_light {
  border-top: 3px dotted var(--bleu_klein_light);
}

.border-top-3-double-bleu_klein_light {
  border-top: 3px double var(--bleu_klein_light);
}

.border-top-3-groove-bleu_klein_light {
  border-top: 3px groove var(--bleu_klein_light);
}

.border-top-3-ridge-bleu_klein_light {
  border-top: 3px ridge var(--bleu_klein_light);
}

.border-top-3-inset-bleu_klein_light {
  border-top: 3px inset var(--bleu_klein_light);
}

.border-top-3-outset-bleu_klein_light {
  border-top: 3px outset var(--bleu_klein_light);
}

.border-top-3-none-bleu_klein_light {
  border-top: 3px none var(--bleu_klein_light);
}

.border-top-3-hidden-bleu_klein_light {
  border-top: 3px hidden var(--bleu_klein_light);
}

.border-top-3-solid-bleu_klein_dark {
  border-top: 3px solid var(--bleu_klein_dark);
}

.border-top-3-dashed-bleu_klein_dark {
  border-top: 3px dashed var(--bleu_klein_dark);
}

.border-top-3-dotted-bleu_klein_dark {
  border-top: 3px dotted var(--bleu_klein_dark);
}

.border-top-3-double-bleu_klein_dark {
  border-top: 3px double var(--bleu_klein_dark);
}

.border-top-3-groove-bleu_klein_dark {
  border-top: 3px groove var(--bleu_klein_dark);
}

.border-top-3-ridge-bleu_klein_dark {
  border-top: 3px ridge var(--bleu_klein_dark);
}

.border-top-3-inset-bleu_klein_dark {
  border-top: 3px inset var(--bleu_klein_dark);
}

.border-top-3-outset-bleu_klein_dark {
  border-top: 3px outset var(--bleu_klein_dark);
}

.border-top-3-none-bleu_klein_dark {
  border-top: 3px none var(--bleu_klein_dark);
}

.border-top-3-hidden-bleu_klein_dark {
  border-top: 3px hidden var(--bleu_klein_dark);
}

.border-top-3-solid-violet_light {
  border-top: 3px solid var(--violet_light);
}

.border-top-3-dashed-violet_light {
  border-top: 3px dashed var(--violet_light);
}

.border-top-3-dotted-violet_light {
  border-top: 3px dotted var(--violet_light);
}

.border-top-3-double-violet_light {
  border-top: 3px double var(--violet_light);
}

.border-top-3-groove-violet_light {
  border-top: 3px groove var(--violet_light);
}

.border-top-3-ridge-violet_light {
  border-top: 3px ridge var(--violet_light);
}

.border-top-3-inset-violet_light {
  border-top: 3px inset var(--violet_light);
}

.border-top-3-outset-violet_light {
  border-top: 3px outset var(--violet_light);
}

.border-top-3-none-violet_light {
  border-top: 3px none var(--violet_light);
}

.border-top-3-hidden-violet_light {
  border-top: 3px hidden var(--violet_light);
}

.border-top-3-solid-violet_dark {
  border-top: 3px solid var(--violet_dark);
}

.border-top-3-dashed-violet_dark {
  border-top: 3px dashed var(--violet_dark);
}

.border-top-3-dotted-violet_dark {
  border-top: 3px dotted var(--violet_dark);
}

.border-top-3-double-violet_dark {
  border-top: 3px double var(--violet_dark);
}

.border-top-3-groove-violet_dark {
  border-top: 3px groove var(--violet_dark);
}

.border-top-3-ridge-violet_dark {
  border-top: 3px ridge var(--violet_dark);
}

.border-top-3-inset-violet_dark {
  border-top: 3px inset var(--violet_dark);
}

.border-top-3-outset-violet_dark {
  border-top: 3px outset var(--violet_dark);
}

.border-top-3-none-violet_dark {
  border-top: 3px none var(--violet_dark);
}

.border-top-3-hidden-violet_dark {
  border-top: 3px hidden var(--violet_dark);
}

.border-top-3-solid-mauve {
  border-top: 3px solid var(--mauve);
}

.border-top-3-dashed-mauve {
  border-top: 3px dashed var(--mauve);
}

.border-top-3-dotted-mauve {
  border-top: 3px dotted var(--mauve);
}

.border-top-3-double-mauve {
  border-top: 3px double var(--mauve);
}

.border-top-3-groove-mauve {
  border-top: 3px groove var(--mauve);
}

.border-top-3-ridge-mauve {
  border-top: 3px ridge var(--mauve);
}

.border-top-3-inset-mauve {
  border-top: 3px inset var(--mauve);
}

.border-top-3-outset-mauve {
  border-top: 3px outset var(--mauve);
}

.border-top-3-none-mauve {
  border-top: 3px none var(--mauve);
}

.border-top-3-hidden-mauve {
  border-top: 3px hidden var(--mauve);
}

.border-top-3-solid-rose_light {
  border-top: 3px solid var(--rose_light);
}

.border-top-3-dashed-rose_light {
  border-top: 3px dashed var(--rose_light);
}

.border-top-3-dotted-rose_light {
  border-top: 3px dotted var(--rose_light);
}

.border-top-3-double-rose_light {
  border-top: 3px double var(--rose_light);
}

.border-top-3-groove-rose_light {
  border-top: 3px groove var(--rose_light);
}

.border-top-3-ridge-rose_light {
  border-top: 3px ridge var(--rose_light);
}

.border-top-3-inset-rose_light {
  border-top: 3px inset var(--rose_light);
}

.border-top-3-outset-rose_light {
  border-top: 3px outset var(--rose_light);
}

.border-top-3-none-rose_light {
  border-top: 3px none var(--rose_light);
}

.border-top-3-hidden-rose_light {
  border-top: 3px hidden var(--rose_light);
}

.border-top-3-solid-rose_dark {
  border-top: 3px solid var(--rose_dark);
}

.border-top-3-dashed-rose_dark {
  border-top: 3px dashed var(--rose_dark);
}

.border-top-3-dotted-rose_dark {
  border-top: 3px dotted var(--rose_dark);
}

.border-top-3-double-rose_dark {
  border-top: 3px double var(--rose_dark);
}

.border-top-3-groove-rose_dark {
  border-top: 3px groove var(--rose_dark);
}

.border-top-3-ridge-rose_dark {
  border-top: 3px ridge var(--rose_dark);
}

.border-top-3-inset-rose_dark {
  border-top: 3px inset var(--rose_dark);
}

.border-top-3-outset-rose_dark {
  border-top: 3px outset var(--rose_dark);
}

.border-top-3-none-rose_dark {
  border-top: 3px none var(--rose_dark);
}

.border-top-3-hidden-rose_dark {
  border-top: 3px hidden var(--rose_dark);
}

.border-top-3-solid-rose_fushia {
  border-top: 3px solid var(--rose_fushia);
}

.border-top-3-dashed-rose_fushia {
  border-top: 3px dashed var(--rose_fushia);
}

.border-top-3-dotted-rose_fushia {
  border-top: 3px dotted var(--rose_fushia);
}

.border-top-3-double-rose_fushia {
  border-top: 3px double var(--rose_fushia);
}

.border-top-3-groove-rose_fushia {
  border-top: 3px groove var(--rose_fushia);
}

.border-top-3-ridge-rose_fushia {
  border-top: 3px ridge var(--rose_fushia);
}

.border-top-3-inset-rose_fushia {
  border-top: 3px inset var(--rose_fushia);
}

.border-top-3-outset-rose_fushia {
  border-top: 3px outset var(--rose_fushia);
}

.border-top-3-none-rose_fushia {
  border-top: 3px none var(--rose_fushia);
}

.border-top-3-hidden-rose_fushia {
  border-top: 3px hidden var(--rose_fushia);
}

.border-top-3-solid-rouge_framboise {
  border-top: 3px solid var(--rouge_framboise);
}

.border-top-3-dashed-rouge_framboise {
  border-top: 3px dashed var(--rouge_framboise);
}

.border-top-3-dotted-rouge_framboise {
  border-top: 3px dotted var(--rouge_framboise);
}

.border-top-3-double-rouge_framboise {
  border-top: 3px double var(--rouge_framboise);
}

.border-top-3-groove-rouge_framboise {
  border-top: 3px groove var(--rouge_framboise);
}

.border-top-3-ridge-rouge_framboise {
  border-top: 3px ridge var(--rouge_framboise);
}

.border-top-3-inset-rouge_framboise {
  border-top: 3px inset var(--rouge_framboise);
}

.border-top-3-outset-rouge_framboise {
  border-top: 3px outset var(--rouge_framboise);
}

.border-top-3-none-rouge_framboise {
  border-top: 3px none var(--rouge_framboise);
}

.border-top-3-hidden-rouge_framboise {
  border-top: 3px hidden var(--rouge_framboise);
}

.border-top-3-solid-rose_chair_light {
  border-top: 3px solid var(--rose_chair_light);
}

.border-top-3-dashed-rose_chair_light {
  border-top: 3px dashed var(--rose_chair_light);
}

.border-top-3-dotted-rose_chair_light {
  border-top: 3px dotted var(--rose_chair_light);
}

.border-top-3-double-rose_chair_light {
  border-top: 3px double var(--rose_chair_light);
}

.border-top-3-groove-rose_chair_light {
  border-top: 3px groove var(--rose_chair_light);
}

.border-top-3-ridge-rose_chair_light {
  border-top: 3px ridge var(--rose_chair_light);
}

.border-top-3-inset-rose_chair_light {
  border-top: 3px inset var(--rose_chair_light);
}

.border-top-3-outset-rose_chair_light {
  border-top: 3px outset var(--rose_chair_light);
}

.border-top-3-none-rose_chair_light {
  border-top: 3px none var(--rose_chair_light);
}

.border-top-3-hidden-rose_chair_light {
  border-top: 3px hidden var(--rose_chair_light);
}

.border-top-3-solid-rose_chair_dark {
  border-top: 3px solid var(--rose_chair_dark);
}

.border-top-3-dashed-rose_chair_dark {
  border-top: 3px dashed var(--rose_chair_dark);
}

.border-top-3-dotted-rose_chair_dark {
  border-top: 3px dotted var(--rose_chair_dark);
}

.border-top-3-double-rose_chair_dark {
  border-top: 3px double var(--rose_chair_dark);
}

.border-top-3-groove-rose_chair_dark {
  border-top: 3px groove var(--rose_chair_dark);
}

.border-top-3-ridge-rose_chair_dark {
  border-top: 3px ridge var(--rose_chair_dark);
}

.border-top-3-inset-rose_chair_dark {
  border-top: 3px inset var(--rose_chair_dark);
}

.border-top-3-outset-rose_chair_dark {
  border-top: 3px outset var(--rose_chair_dark);
}

.border-top-3-none-rose_chair_dark {
  border-top: 3px none var(--rose_chair_dark);
}

.border-top-3-hidden-rose_chair_dark {
  border-top: 3px hidden var(--rose_chair_dark);
}

.border-top-3-solid-vert_positif {
  border-top: 3px solid var(--vert_positif);
}

.border-top-3-dashed-vert_positif {
  border-top: 3px dashed var(--vert_positif);
}

.border-top-3-dotted-vert_positif {
  border-top: 3px dotted var(--vert_positif);
}

.border-top-3-double-vert_positif {
  border-top: 3px double var(--vert_positif);
}

.border-top-3-groove-vert_positif {
  border-top: 3px groove var(--vert_positif);
}

.border-top-3-ridge-vert_positif {
  border-top: 3px ridge var(--vert_positif);
}

.border-top-3-inset-vert_positif {
  border-top: 3px inset var(--vert_positif);
}

.border-top-3-outset-vert_positif {
  border-top: 3px outset var(--vert_positif);
}

.border-top-3-none-vert_positif {
  border-top: 3px none var(--vert_positif);
}

.border-top-3-hidden-vert_positif {
  border-top: 3px hidden var(--vert_positif);
}

.border-top-3-solid-rouge_negatif {
  border-top: 3px solid var(--rouge_negatif);
}

.border-top-3-dashed-rouge_negatif {
  border-top: 3px dashed var(--rouge_negatif);
}

.border-top-3-dotted-rouge_negatif {
  border-top: 3px dotted var(--rouge_negatif);
}

.border-top-3-double-rouge_negatif {
  border-top: 3px double var(--rouge_negatif);
}

.border-top-3-groove-rouge_negatif {
  border-top: 3px groove var(--rouge_negatif);
}

.border-top-3-ridge-rouge_negatif {
  border-top: 3px ridge var(--rouge_negatif);
}

.border-top-3-inset-rouge_negatif {
  border-top: 3px inset var(--rouge_negatif);
}

.border-top-3-outset-rouge_negatif {
  border-top: 3px outset var(--rouge_negatif);
}

.border-top-3-none-rouge_negatif {
  border-top: 3px none var(--rouge_negatif);
}

.border-top-3-hidden-rouge_negatif {
  border-top: 3px hidden var(--rouge_negatif);
}

.border-top-3-solid-bleu_texte {
  border-top: 3px solid var(--bleu_texte);
}

.border-top-3-dashed-bleu_texte {
  border-top: 3px dashed var(--bleu_texte);
}

.border-top-3-dotted-bleu_texte {
  border-top: 3px dotted var(--bleu_texte);
}

.border-top-3-double-bleu_texte {
  border-top: 3px double var(--bleu_texte);
}

.border-top-3-groove-bleu_texte {
  border-top: 3px groove var(--bleu_texte);
}

.border-top-3-ridge-bleu_texte {
  border-top: 3px ridge var(--bleu_texte);
}

.border-top-3-inset-bleu_texte {
  border-top: 3px inset var(--bleu_texte);
}

.border-top-3-outset-bleu_texte {
  border-top: 3px outset var(--bleu_texte);
}

.border-top-3-none-bleu_texte {
  border-top: 3px none var(--bleu_texte);
}

.border-top-3-hidden-bleu_texte {
  border-top: 3px hidden var(--bleu_texte);
}

.border-top-3-solid-alerte_vert_positif {
  border-top: 3px solid var(--alerte_vert_positif);
}

.border-top-3-dashed-alerte_vert_positif {
  border-top: 3px dashed var(--alerte_vert_positif);
}

.border-top-3-dotted-alerte_vert_positif {
  border-top: 3px dotted var(--alerte_vert_positif);
}

.border-top-3-double-alerte_vert_positif {
  border-top: 3px double var(--alerte_vert_positif);
}

.border-top-3-groove-alerte_vert_positif {
  border-top: 3px groove var(--alerte_vert_positif);
}

.border-top-3-ridge-alerte_vert_positif {
  border-top: 3px ridge var(--alerte_vert_positif);
}

.border-top-3-inset-alerte_vert_positif {
  border-top: 3px inset var(--alerte_vert_positif);
}

.border-top-3-outset-alerte_vert_positif {
  border-top: 3px outset var(--alerte_vert_positif);
}

.border-top-3-none-alerte_vert_positif {
  border-top: 3px none var(--alerte_vert_positif);
}

.border-top-3-hidden-alerte_vert_positif {
  border-top: 3px hidden var(--alerte_vert_positif);
}

.border-top-3-solid-alerte_rouge_negatif {
  border-top: 3px solid var(--alerte_rouge_negatif);
}

.border-top-3-dashed-alerte_rouge_negatif {
  border-top: 3px dashed var(--alerte_rouge_negatif);
}

.border-top-3-dotted-alerte_rouge_negatif {
  border-top: 3px dotted var(--alerte_rouge_negatif);
}

.border-top-3-double-alerte_rouge_negatif {
  border-top: 3px double var(--alerte_rouge_negatif);
}

.border-top-3-groove-alerte_rouge_negatif {
  border-top: 3px groove var(--alerte_rouge_negatif);
}

.border-top-3-ridge-alerte_rouge_negatif {
  border-top: 3px ridge var(--alerte_rouge_negatif);
}

.border-top-3-inset-alerte_rouge_negatif {
  border-top: 3px inset var(--alerte_rouge_negatif);
}

.border-top-3-outset-alerte_rouge_negatif {
  border-top: 3px outset var(--alerte_rouge_negatif);
}

.border-top-3-none-alerte_rouge_negatif {
  border-top: 3px none var(--alerte_rouge_negatif);
}

.border-top-3-hidden-alerte_rouge_negatif {
  border-top: 3px hidden var(--alerte_rouge_negatif);
}

.border-top-3-solid-alerte_bleu_information {
  border-top: 3px solid var(--alerte_bleu_information);
}

.border-top-3-dashed-alerte_bleu_information {
  border-top: 3px dashed var(--alerte_bleu_information);
}

.border-top-3-dotted-alerte_bleu_information {
  border-top: 3px dotted var(--alerte_bleu_information);
}

.border-top-3-double-alerte_bleu_information {
  border-top: 3px double var(--alerte_bleu_information);
}

.border-top-3-groove-alerte_bleu_information {
  border-top: 3px groove var(--alerte_bleu_information);
}

.border-top-3-ridge-alerte_bleu_information {
  border-top: 3px ridge var(--alerte_bleu_information);
}

.border-top-3-inset-alerte_bleu_information {
  border-top: 3px inset var(--alerte_bleu_information);
}

.border-top-3-outset-alerte_bleu_information {
  border-top: 3px outset var(--alerte_bleu_information);
}

.border-top-3-none-alerte_bleu_information {
  border-top: 3px none var(--alerte_bleu_information);
}

.border-top-3-hidden-alerte_bleu_information {
  border-top: 3px hidden var(--alerte_bleu_information);
}

.border-top-3-solid-alerte_jaune_attention {
  border-top: 3px solid var(--alerte_jaune_attention);
}

.border-top-3-dashed-alerte_jaune_attention {
  border-top: 3px dashed var(--alerte_jaune_attention);
}

.border-top-3-dotted-alerte_jaune_attention {
  border-top: 3px dotted var(--alerte_jaune_attention);
}

.border-top-3-double-alerte_jaune_attention {
  border-top: 3px double var(--alerte_jaune_attention);
}

.border-top-3-groove-alerte_jaune_attention {
  border-top: 3px groove var(--alerte_jaune_attention);
}

.border-top-3-ridge-alerte_jaune_attention {
  border-top: 3px ridge var(--alerte_jaune_attention);
}

.border-top-3-inset-alerte_jaune_attention {
  border-top: 3px inset var(--alerte_jaune_attention);
}

.border-top-3-outset-alerte_jaune_attention {
  border-top: 3px outset var(--alerte_jaune_attention);
}

.border-top-3-none-alerte_jaune_attention {
  border-top: 3px none var(--alerte_jaune_attention);
}

.border-top-3-hidden-alerte_jaune_attention {
  border-top: 3px hidden var(--alerte_jaune_attention);
}

.border-top-3-solid-blanc {
  border-top: 3px solid #fff;
}

.border-top-3-dashed-blanc {
  border-top: 3px dashed #fff;
}

.border-top-3-dotted-blanc {
  border-top: 3px dotted #fff;
}

.border-top-3-double-blanc {
  border-top: 3px double #fff;
}

.border-top-3-groove-blanc {
  border-top: 3px groove #fff;
}

.border-top-3-ridge-blanc {
  border-top: 3px ridge #fff;
}

.border-top-3-inset-blanc {
  border-top: 3px inset #fff;
}

.border-top-3-outset-blanc {
  border-top: 3px outset #fff;
}

.border-top-3-none-blanc {
  border-top: 3px #fff;
}

.border-top-3-hidden-blanc {
  border-top: 3px hidden #fff;
}

.border-top-3-solid-noir {
  border-top: 3px solid #222;
}

.border-top-3-dashed-noir {
  border-top: 3px dashed #222;
}

.border-top-3-dotted-noir {
  border-top: 3px dotted #222;
}

.border-top-3-double-noir {
  border-top: 3px double #222;
}

.border-top-3-groove-noir {
  border-top: 3px groove #222;
}

.border-top-3-ridge-noir {
  border-top: 3px ridge #222;
}

.border-top-3-inset-noir {
  border-top: 3px inset #222;
}

.border-top-3-outset-noir {
  border-top: 3px outset #222;
}

.border-top-3-none-noir {
  border-top: 3px #222;
}

.border-top-3-hidden-noir {
  border-top: 3px hidden #222;
}

.border-top-3-solid-gris_light {
  border-top: 3px solid var(--gris_light);
}

.border-top-3-dashed-gris_light {
  border-top: 3px dashed var(--gris_light);
}

.border-top-3-dotted-gris_light {
  border-top: 3px dotted var(--gris_light);
}

.border-top-3-double-gris_light {
  border-top: 3px double var(--gris_light);
}

.border-top-3-groove-gris_light {
  border-top: 3px groove var(--gris_light);
}

.border-top-3-ridge-gris_light {
  border-top: 3px ridge var(--gris_light);
}

.border-top-3-inset-gris_light {
  border-top: 3px inset var(--gris_light);
}

.border-top-3-outset-gris_light {
  border-top: 3px outset var(--gris_light);
}

.border-top-3-none-gris_light {
  border-top: 3px none var(--gris_light);
}

.border-top-3-hidden-gris_light {
  border-top: 3px hidden var(--gris_light);
}

.border-top-3-solid-gris_dark {
  border-top: 3px solid var(--gris_dark);
}

.border-top-3-dashed-gris_dark {
  border-top: 3px dashed var(--gris_dark);
}

.border-top-3-dotted-gris_dark {
  border-top: 3px dotted var(--gris_dark);
}

.border-top-3-double-gris_dark {
  border-top: 3px double var(--gris_dark);
}

.border-top-3-groove-gris_dark {
  border-top: 3px groove var(--gris_dark);
}

.border-top-3-ridge-gris_dark {
  border-top: 3px ridge var(--gris_dark);
}

.border-top-3-inset-gris_dark {
  border-top: 3px inset var(--gris_dark);
}

.border-top-3-outset-gris_dark {
  border-top: 3px outset var(--gris_dark);
}

.border-top-3-none-gris_dark {
  border-top: 3px none var(--gris_dark);
}

.border-top-3-hidden-gris_dark {
  border-top: 3px hidden var(--gris_dark);
}

.border-top-3-solid-gris_background {
  border-top: 3px solid var(--gris_background);
}

.border-top-3-dashed-gris_background {
  border-top: 3px dashed var(--gris_background);
}

.border-top-3-dotted-gris_background {
  border-top: 3px dotted var(--gris_background);
}

.border-top-3-double-gris_background {
  border-top: 3px double var(--gris_background);
}

.border-top-3-groove-gris_background {
  border-top: 3px groove var(--gris_background);
}

.border-top-3-ridge-gris_background {
  border-top: 3px ridge var(--gris_background);
}

.border-top-3-inset-gris_background {
  border-top: 3px inset var(--gris_background);
}

.border-top-3-outset-gris_background {
  border-top: 3px outset var(--gris_background);
}

.border-top-3-none-gris_background {
  border-top: 3px none var(--gris_background);
}

.border-top-3-hidden-gris_background {
  border-top: 3px hidden var(--gris_background);
}

.border-bottom-3-solid-corail_mh_brand {
  border-bottom: 3px solid var(--corail_mh_brand);
}

.border-bottom-3-dashed-corail_mh_brand {
  border-bottom: 3px dashed var(--corail_mh_brand);
}

.border-bottom-3-dotted-corail_mh_brand {
  border-bottom: 3px dotted var(--corail_mh_brand);
}

.border-bottom-3-double-corail_mh_brand {
  border-bottom: 3px double var(--corail_mh_brand);
}

.border-bottom-3-groove-corail_mh_brand {
  border-bottom: 3px groove var(--corail_mh_brand);
}

.border-bottom-3-ridge-corail_mh_brand {
  border-bottom: 3px ridge var(--corail_mh_brand);
}

.border-bottom-3-inset-corail_mh_brand {
  border-bottom: 3px inset var(--corail_mh_brand);
}

.border-bottom-3-outset-corail_mh_brand {
  border-bottom: 3px outset var(--corail_mh_brand);
}

.border-bottom-3-none-corail_mh_brand {
  border-bottom: 3px none var(--corail_mh_brand);
}

.border-bottom-3-hidden-corail_mh_brand {
  border-bottom: 3px hidden var(--corail_mh_brand);
}

.border-bottom-3-solid-corail_mh_dark {
  border-bottom: 3px solid var(--corail_mh_dark);
}

.border-bottom-3-dashed-corail_mh_dark {
  border-bottom: 3px dashed var(--corail_mh_dark);
}

.border-bottom-3-dotted-corail_mh_dark {
  border-bottom: 3px dotted var(--corail_mh_dark);
}

.border-bottom-3-double-corail_mh_dark {
  border-bottom: 3px double var(--corail_mh_dark);
}

.border-bottom-3-groove-corail_mh_dark {
  border-bottom: 3px groove var(--corail_mh_dark);
}

.border-bottom-3-ridge-corail_mh_dark {
  border-bottom: 3px ridge var(--corail_mh_dark);
}

.border-bottom-3-inset-corail_mh_dark {
  border-bottom: 3px inset var(--corail_mh_dark);
}

.border-bottom-3-outset-corail_mh_dark {
  border-bottom: 3px outset var(--corail_mh_dark);
}

.border-bottom-3-none-corail_mh_dark {
  border-bottom: 3px none var(--corail_mh_dark);
}

.border-bottom-3-hidden-corail_mh_dark {
  border-bottom: 3px hidden var(--corail_mh_dark);
}

.border-bottom-3-solid-corail_mh_light {
  border-bottom: 3px solid var(--corail_mh_light);
}

.border-bottom-3-dashed-corail_mh_light {
  border-bottom: 3px dashed var(--corail_mh_light);
}

.border-bottom-3-dotted-corail_mh_light {
  border-bottom: 3px dotted var(--corail_mh_light);
}

.border-bottom-3-double-corail_mh_light {
  border-bottom: 3px double var(--corail_mh_light);
}

.border-bottom-3-groove-corail_mh_light {
  border-bottom: 3px groove var(--corail_mh_light);
}

.border-bottom-3-ridge-corail_mh_light {
  border-bottom: 3px ridge var(--corail_mh_light);
}

.border-bottom-3-inset-corail_mh_light {
  border-bottom: 3px inset var(--corail_mh_light);
}

.border-bottom-3-outset-corail_mh_light {
  border-bottom: 3px outset var(--corail_mh_light);
}

.border-bottom-3-none-corail_mh_light {
  border-bottom: 3px none var(--corail_mh_light);
}

.border-bottom-3-hidden-corail_mh_light {
  border-bottom: 3px hidden var(--corail_mh_light);
}

.border-bottom-3-solid-gris_sable_light {
  border-bottom: 3px solid var(--gris_sable_light);
}

.border-bottom-3-dashed-gris_sable_light {
  border-bottom: 3px dashed var(--gris_sable_light);
}

.border-bottom-3-dotted-gris_sable_light {
  border-bottom: 3px dotted var(--gris_sable_light);
}

.border-bottom-3-double-gris_sable_light {
  border-bottom: 3px double var(--gris_sable_light);
}

.border-bottom-3-groove-gris_sable_light {
  border-bottom: 3px groove var(--gris_sable_light);
}

.border-bottom-3-ridge-gris_sable_light {
  border-bottom: 3px ridge var(--gris_sable_light);
}

.border-bottom-3-inset-gris_sable_light {
  border-bottom: 3px inset var(--gris_sable_light);
}

.border-bottom-3-outset-gris_sable_light {
  border-bottom: 3px outset var(--gris_sable_light);
}

.border-bottom-3-none-gris_sable_light {
  border-bottom: 3px none var(--gris_sable_light);
}

.border-bottom-3-hidden-gris_sable_light {
  border-bottom: 3px hidden var(--gris_sable_light);
}

.border-bottom-3-solid-gris_sable_dark {
  border-bottom: 3px solid var(--gris_sable_dark);
}

.border-bottom-3-dashed-gris_sable_dark {
  border-bottom: 3px dashed var(--gris_sable_dark);
}

.border-bottom-3-dotted-gris_sable_dark {
  border-bottom: 3px dotted var(--gris_sable_dark);
}

.border-bottom-3-double-gris_sable_dark {
  border-bottom: 3px double var(--gris_sable_dark);
}

.border-bottom-3-groove-gris_sable_dark {
  border-bottom: 3px groove var(--gris_sable_dark);
}

.border-bottom-3-ridge-gris_sable_dark {
  border-bottom: 3px ridge var(--gris_sable_dark);
}

.border-bottom-3-inset-gris_sable_dark {
  border-bottom: 3px inset var(--gris_sable_dark);
}

.border-bottom-3-outset-gris_sable_dark {
  border-bottom: 3px outset var(--gris_sable_dark);
}

.border-bottom-3-none-gris_sable_dark {
  border-bottom: 3px none var(--gris_sable_dark);
}

.border-bottom-3-hidden-gris_sable_dark {
  border-bottom: 3px hidden var(--gris_sable_dark);
}

.border-bottom-3-solid-jaune_ivoire_light {
  border-bottom: 3px solid var(--jaune_ivoire_light);
}

.border-bottom-3-dashed-jaune_ivoire_light {
  border-bottom: 3px dashed var(--jaune_ivoire_light);
}

.border-bottom-3-dotted-jaune_ivoire_light {
  border-bottom: 3px dotted var(--jaune_ivoire_light);
}

.border-bottom-3-double-jaune_ivoire_light {
  border-bottom: 3px double var(--jaune_ivoire_light);
}

.border-bottom-3-groove-jaune_ivoire_light {
  border-bottom: 3px groove var(--jaune_ivoire_light);
}

.border-bottom-3-ridge-jaune_ivoire_light {
  border-bottom: 3px ridge var(--jaune_ivoire_light);
}

.border-bottom-3-inset-jaune_ivoire_light {
  border-bottom: 3px inset var(--jaune_ivoire_light);
}

.border-bottom-3-outset-jaune_ivoire_light {
  border-bottom: 3px outset var(--jaune_ivoire_light);
}

.border-bottom-3-none-jaune_ivoire_light {
  border-bottom: 3px none var(--jaune_ivoire_light);
}

.border-bottom-3-hidden-jaune_ivoire_light {
  border-bottom: 3px hidden var(--jaune_ivoire_light);
}

.border-bottom-3-solid-jaune_ivoire_dark {
  border-bottom: 3px solid var(--jaune_ivoire_dark);
}

.border-bottom-3-dashed-jaune_ivoire_dark {
  border-bottom: 3px dashed var(--jaune_ivoire_dark);
}

.border-bottom-3-dotted-jaune_ivoire_dark {
  border-bottom: 3px dotted var(--jaune_ivoire_dark);
}

.border-bottom-3-double-jaune_ivoire_dark {
  border-bottom: 3px double var(--jaune_ivoire_dark);
}

.border-bottom-3-groove-jaune_ivoire_dark {
  border-bottom: 3px groove var(--jaune_ivoire_dark);
}

.border-bottom-3-ridge-jaune_ivoire_dark {
  border-bottom: 3px ridge var(--jaune_ivoire_dark);
}

.border-bottom-3-inset-jaune_ivoire_dark {
  border-bottom: 3px inset var(--jaune_ivoire_dark);
}

.border-bottom-3-outset-jaune_ivoire_dark {
  border-bottom: 3px outset var(--jaune_ivoire_dark);
}

.border-bottom-3-none-jaune_ivoire_dark {
  border-bottom: 3px none var(--jaune_ivoire_dark);
}

.border-bottom-3-hidden-jaune_ivoire_dark {
  border-bottom: 3px hidden var(--jaune_ivoire_dark);
}

.border-bottom-3-solid-jaune_light {
  border-bottom: 3px solid var(--jaune_light);
}

.border-bottom-3-dashed-jaune_light {
  border-bottom: 3px dashed var(--jaune_light);
}

.border-bottom-3-dotted-jaune_light {
  border-bottom: 3px dotted var(--jaune_light);
}

.border-bottom-3-double-jaune_light {
  border-bottom: 3px double var(--jaune_light);
}

.border-bottom-3-groove-jaune_light {
  border-bottom: 3px groove var(--jaune_light);
}

.border-bottom-3-ridge-jaune_light {
  border-bottom: 3px ridge var(--jaune_light);
}

.border-bottom-3-inset-jaune_light {
  border-bottom: 3px inset var(--jaune_light);
}

.border-bottom-3-outset-jaune_light {
  border-bottom: 3px outset var(--jaune_light);
}

.border-bottom-3-none-jaune_light {
  border-bottom: 3px none var(--jaune_light);
}

.border-bottom-3-hidden-jaune_light {
  border-bottom: 3px hidden var(--jaune_light);
}

.border-bottom-3-solid-jaune_dark {
  border-bottom: 3px solid var(--jaune_dark);
}

.border-bottom-3-dashed-jaune_dark {
  border-bottom: 3px dashed var(--jaune_dark);
}

.border-bottom-3-dotted-jaune_dark {
  border-bottom: 3px dotted var(--jaune_dark);
}

.border-bottom-3-double-jaune_dark {
  border-bottom: 3px double var(--jaune_dark);
}

.border-bottom-3-groove-jaune_dark {
  border-bottom: 3px groove var(--jaune_dark);
}

.border-bottom-3-ridge-jaune_dark {
  border-bottom: 3px ridge var(--jaune_dark);
}

.border-bottom-3-inset-jaune_dark {
  border-bottom: 3px inset var(--jaune_dark);
}

.border-bottom-3-outset-jaune_dark {
  border-bottom: 3px outset var(--jaune_dark);
}

.border-bottom-3-none-jaune_dark {
  border-bottom: 3px none var(--jaune_dark);
}

.border-bottom-3-hidden-jaune_dark {
  border-bottom: 3px hidden var(--jaune_dark);
}

.border-bottom-3-solid-orange_normal {
  border-bottom: 3px solid var(--orange_normal);
}

.border-bottom-3-dashed-orange_normal {
  border-bottom: 3px dashed var(--orange_normal);
}

.border-bottom-3-dotted-orange_normal {
  border-bottom: 3px dotted var(--orange_normal);
}

.border-bottom-3-double-orange_normal {
  border-bottom: 3px double var(--orange_normal);
}

.border-bottom-3-groove-orange_normal {
  border-bottom: 3px groove var(--orange_normal);
}

.border-bottom-3-ridge-orange_normal {
  border-bottom: 3px ridge var(--orange_normal);
}

.border-bottom-3-inset-orange_normal {
  border-bottom: 3px inset var(--orange_normal);
}

.border-bottom-3-outset-orange_normal {
  border-bottom: 3px outset var(--orange_normal);
}

.border-bottom-3-none-orange_normal {
  border-bottom: 3px none var(--orange_normal);
}

.border-bottom-3-hidden-orange_normal {
  border-bottom: 3px hidden var(--orange_normal);
}

.border-bottom-3-solid-orange_dark {
  border-bottom: 3px solid var(--orange_dark);
}

.border-bottom-3-dashed-orange_dark {
  border-bottom: 3px dashed var(--orange_dark);
}

.border-bottom-3-dotted-orange_dark {
  border-bottom: 3px dotted var(--orange_dark);
}

.border-bottom-3-double-orange_dark {
  border-bottom: 3px double var(--orange_dark);
}

.border-bottom-3-groove-orange_dark {
  border-bottom: 3px groove var(--orange_dark);
}

.border-bottom-3-ridge-orange_dark {
  border-bottom: 3px ridge var(--orange_dark);
}

.border-bottom-3-inset-orange_dark {
  border-bottom: 3px inset var(--orange_dark);
}

.border-bottom-3-outset-orange_dark {
  border-bottom: 3px outset var(--orange_dark);
}

.border-bottom-3-none-orange_dark {
  border-bottom: 3px none var(--orange_dark);
}

.border-bottom-3-hidden-orange_dark {
  border-bottom: 3px hidden var(--orange_dark);
}

.border-bottom-3-solid-vert_light {
  border-bottom: 3px solid var(--vert_light);
}

.border-bottom-3-dashed-vert_light {
  border-bottom: 3px dashed var(--vert_light);
}

.border-bottom-3-dotted-vert_light {
  border-bottom: 3px dotted var(--vert_light);
}

.border-bottom-3-double-vert_light {
  border-bottom: 3px double var(--vert_light);
}

.border-bottom-3-groove-vert_light {
  border-bottom: 3px groove var(--vert_light);
}

.border-bottom-3-ridge-vert_light {
  border-bottom: 3px ridge var(--vert_light);
}

.border-bottom-3-inset-vert_light {
  border-bottom: 3px inset var(--vert_light);
}

.border-bottom-3-outset-vert_light {
  border-bottom: 3px outset var(--vert_light);
}

.border-bottom-3-none-vert_light {
  border-bottom: 3px none var(--vert_light);
}

.border-bottom-3-hidden-vert_light {
  border-bottom: 3px hidden var(--vert_light);
}

.border-bottom-3-solid-vert_dark {
  border-bottom: 3px solid var(--vert_dark);
}

.border-bottom-3-dashed-vert_dark {
  border-bottom: 3px dashed var(--vert_dark);
}

.border-bottom-3-dotted-vert_dark {
  border-bottom: 3px dotted var(--vert_dark);
}

.border-bottom-3-double-vert_dark {
  border-bottom: 3px double var(--vert_dark);
}

.border-bottom-3-groove-vert_dark {
  border-bottom: 3px groove var(--vert_dark);
}

.border-bottom-3-ridge-vert_dark {
  border-bottom: 3px ridge var(--vert_dark);
}

.border-bottom-3-inset-vert_dark {
  border-bottom: 3px inset var(--vert_dark);
}

.border-bottom-3-outset-vert_dark {
  border-bottom: 3px outset var(--vert_dark);
}

.border-bottom-3-none-vert_dark {
  border-bottom: 3px none var(--vert_dark);
}

.border-bottom-3-hidden-vert_dark {
  border-bottom: 3px hidden var(--vert_dark);
}

.border-bottom-3-solid-bleu_turquoise_light {
  border-bottom: 3px solid var(--bleu_turquoise_light);
}

.border-bottom-3-dashed-bleu_turquoise_light {
  border-bottom: 3px dashed var(--bleu_turquoise_light);
}

.border-bottom-3-dotted-bleu_turquoise_light {
  border-bottom: 3px dotted var(--bleu_turquoise_light);
}

.border-bottom-3-double-bleu_turquoise_light {
  border-bottom: 3px double var(--bleu_turquoise_light);
}

.border-bottom-3-groove-bleu_turquoise_light {
  border-bottom: 3px groove var(--bleu_turquoise_light);
}

.border-bottom-3-ridge-bleu_turquoise_light {
  border-bottom: 3px ridge var(--bleu_turquoise_light);
}

.border-bottom-3-inset-bleu_turquoise_light {
  border-bottom: 3px inset var(--bleu_turquoise_light);
}

.border-bottom-3-outset-bleu_turquoise_light {
  border-bottom: 3px outset var(--bleu_turquoise_light);
}

.border-bottom-3-none-bleu_turquoise_light {
  border-bottom: 3px none var(--bleu_turquoise_light);
}

.border-bottom-3-hidden-bleu_turquoise_light {
  border-bottom: 3px hidden var(--bleu_turquoise_light);
}

.border-bottom-3-solid-bleu_turquoise_middle {
  border-bottom: 3px solid var(--bleu_turquoise_middle);
}

.border-bottom-3-dashed-bleu_turquoise_middle {
  border-bottom: 3px dashed var(--bleu_turquoise_middle);
}

.border-bottom-3-dotted-bleu_turquoise_middle {
  border-bottom: 3px dotted var(--bleu_turquoise_middle);
}

.border-bottom-3-double-bleu_turquoise_middle {
  border-bottom: 3px double var(--bleu_turquoise_middle);
}

.border-bottom-3-groove-bleu_turquoise_middle {
  border-bottom: 3px groove var(--bleu_turquoise_middle);
}

.border-bottom-3-ridge-bleu_turquoise_middle {
  border-bottom: 3px ridge var(--bleu_turquoise_middle);
}

.border-bottom-3-inset-bleu_turquoise_middle {
  border-bottom: 3px inset var(--bleu_turquoise_middle);
}

.border-bottom-3-outset-bleu_turquoise_middle {
  border-bottom: 3px outset var(--bleu_turquoise_middle);
}

.border-bottom-3-none-bleu_turquoise_middle {
  border-bottom: 3px none var(--bleu_turquoise_middle);
}

.border-bottom-3-hidden-bleu_turquoise_middle {
  border-bottom: 3px hidden var(--bleu_turquoise_middle);
}

.border-bottom-3-solid-bleu_turquoise_dark {
  border-bottom: 3px solid var(--bleu_turquoise_dark);
}

.border-bottom-3-dashed-bleu_turquoise_dark {
  border-bottom: 3px dashed var(--bleu_turquoise_dark);
}

.border-bottom-3-dotted-bleu_turquoise_dark {
  border-bottom: 3px dotted var(--bleu_turquoise_dark);
}

.border-bottom-3-double-bleu_turquoise_dark {
  border-bottom: 3px double var(--bleu_turquoise_dark);
}

.border-bottom-3-groove-bleu_turquoise_dark {
  border-bottom: 3px groove var(--bleu_turquoise_dark);
}

.border-bottom-3-ridge-bleu_turquoise_dark {
  border-bottom: 3px ridge var(--bleu_turquoise_dark);
}

.border-bottom-3-inset-bleu_turquoise_dark {
  border-bottom: 3px inset var(--bleu_turquoise_dark);
}

.border-bottom-3-outset-bleu_turquoise_dark {
  border-bottom: 3px outset var(--bleu_turquoise_dark);
}

.border-bottom-3-none-bleu_turquoise_dark {
  border-bottom: 3px none var(--bleu_turquoise_dark);
}

.border-bottom-3-hidden-bleu_turquoise_dark {
  border-bottom: 3px hidden var(--bleu_turquoise_dark);
}

.border-bottom-3-solid-bleu_light {
  border-bottom: 3px solid var(--bleu_light);
}

.border-bottom-3-dashed-bleu_light {
  border-bottom: 3px dashed var(--bleu_light);
}

.border-bottom-3-dotted-bleu_light {
  border-bottom: 3px dotted var(--bleu_light);
}

.border-bottom-3-double-bleu_light {
  border-bottom: 3px double var(--bleu_light);
}

.border-bottom-3-groove-bleu_light {
  border-bottom: 3px groove var(--bleu_light);
}

.border-bottom-3-ridge-bleu_light {
  border-bottom: 3px ridge var(--bleu_light);
}

.border-bottom-3-inset-bleu_light {
  border-bottom: 3px inset var(--bleu_light);
}

.border-bottom-3-outset-bleu_light {
  border-bottom: 3px outset var(--bleu_light);
}

.border-bottom-3-none-bleu_light {
  border-bottom: 3px none var(--bleu_light);
}

.border-bottom-3-hidden-bleu_light {
  border-bottom: 3px hidden var(--bleu_light);
}

.border-bottom-3-solid-bleu_dark {
  border-bottom: 3px solid var(--bleu_dark);
}

.border-bottom-3-dashed-bleu_dark {
  border-bottom: 3px dashed var(--bleu_dark);
}

.border-bottom-3-dotted-bleu_dark {
  border-bottom: 3px dotted var(--bleu_dark);
}

.border-bottom-3-double-bleu_dark {
  border-bottom: 3px double var(--bleu_dark);
}

.border-bottom-3-groove-bleu_dark {
  border-bottom: 3px groove var(--bleu_dark);
}

.border-bottom-3-ridge-bleu_dark {
  border-bottom: 3px ridge var(--bleu_dark);
}

.border-bottom-3-inset-bleu_dark {
  border-bottom: 3px inset var(--bleu_dark);
}

.border-bottom-3-outset-bleu_dark {
  border-bottom: 3px outset var(--bleu_dark);
}

.border-bottom-3-none-bleu_dark {
  border-bottom: 3px none var(--bleu_dark);
}

.border-bottom-3-hidden-bleu_dark {
  border-bottom: 3px hidden var(--bleu_dark);
}

.border-bottom-3-solid-bleu_klein_light {
  border-bottom: 3px solid var(--bleu_klein_light);
}

.border-bottom-3-dashed-bleu_klein_light {
  border-bottom: 3px dashed var(--bleu_klein_light);
}

.border-bottom-3-dotted-bleu_klein_light {
  border-bottom: 3px dotted var(--bleu_klein_light);
}

.border-bottom-3-double-bleu_klein_light {
  border-bottom: 3px double var(--bleu_klein_light);
}

.border-bottom-3-groove-bleu_klein_light {
  border-bottom: 3px groove var(--bleu_klein_light);
}

.border-bottom-3-ridge-bleu_klein_light {
  border-bottom: 3px ridge var(--bleu_klein_light);
}

.border-bottom-3-inset-bleu_klein_light {
  border-bottom: 3px inset var(--bleu_klein_light);
}

.border-bottom-3-outset-bleu_klein_light {
  border-bottom: 3px outset var(--bleu_klein_light);
}

.border-bottom-3-none-bleu_klein_light {
  border-bottom: 3px none var(--bleu_klein_light);
}

.border-bottom-3-hidden-bleu_klein_light {
  border-bottom: 3px hidden var(--bleu_klein_light);
}

.border-bottom-3-solid-bleu_klein_dark {
  border-bottom: 3px solid var(--bleu_klein_dark);
}

.border-bottom-3-dashed-bleu_klein_dark {
  border-bottom: 3px dashed var(--bleu_klein_dark);
}

.border-bottom-3-dotted-bleu_klein_dark {
  border-bottom: 3px dotted var(--bleu_klein_dark);
}

.border-bottom-3-double-bleu_klein_dark {
  border-bottom: 3px double var(--bleu_klein_dark);
}

.border-bottom-3-groove-bleu_klein_dark {
  border-bottom: 3px groove var(--bleu_klein_dark);
}

.border-bottom-3-ridge-bleu_klein_dark {
  border-bottom: 3px ridge var(--bleu_klein_dark);
}

.border-bottom-3-inset-bleu_klein_dark {
  border-bottom: 3px inset var(--bleu_klein_dark);
}

.border-bottom-3-outset-bleu_klein_dark {
  border-bottom: 3px outset var(--bleu_klein_dark);
}

.border-bottom-3-none-bleu_klein_dark {
  border-bottom: 3px none var(--bleu_klein_dark);
}

.border-bottom-3-hidden-bleu_klein_dark {
  border-bottom: 3px hidden var(--bleu_klein_dark);
}

.border-bottom-3-solid-violet_light {
  border-bottom: 3px solid var(--violet_light);
}

.border-bottom-3-dashed-violet_light {
  border-bottom: 3px dashed var(--violet_light);
}

.border-bottom-3-dotted-violet_light {
  border-bottom: 3px dotted var(--violet_light);
}

.border-bottom-3-double-violet_light {
  border-bottom: 3px double var(--violet_light);
}

.border-bottom-3-groove-violet_light {
  border-bottom: 3px groove var(--violet_light);
}

.border-bottom-3-ridge-violet_light {
  border-bottom: 3px ridge var(--violet_light);
}

.border-bottom-3-inset-violet_light {
  border-bottom: 3px inset var(--violet_light);
}

.border-bottom-3-outset-violet_light {
  border-bottom: 3px outset var(--violet_light);
}

.border-bottom-3-none-violet_light {
  border-bottom: 3px none var(--violet_light);
}

.border-bottom-3-hidden-violet_light {
  border-bottom: 3px hidden var(--violet_light);
}

.border-bottom-3-solid-violet_dark {
  border-bottom: 3px solid var(--violet_dark);
}

.border-bottom-3-dashed-violet_dark {
  border-bottom: 3px dashed var(--violet_dark);
}

.border-bottom-3-dotted-violet_dark {
  border-bottom: 3px dotted var(--violet_dark);
}

.border-bottom-3-double-violet_dark {
  border-bottom: 3px double var(--violet_dark);
}

.border-bottom-3-groove-violet_dark {
  border-bottom: 3px groove var(--violet_dark);
}

.border-bottom-3-ridge-violet_dark {
  border-bottom: 3px ridge var(--violet_dark);
}

.border-bottom-3-inset-violet_dark {
  border-bottom: 3px inset var(--violet_dark);
}

.border-bottom-3-outset-violet_dark {
  border-bottom: 3px outset var(--violet_dark);
}

.border-bottom-3-none-violet_dark {
  border-bottom: 3px none var(--violet_dark);
}

.border-bottom-3-hidden-violet_dark {
  border-bottom: 3px hidden var(--violet_dark);
}

.border-bottom-3-solid-mauve {
  border-bottom: 3px solid var(--mauve);
}

.border-bottom-3-dashed-mauve {
  border-bottom: 3px dashed var(--mauve);
}

.border-bottom-3-dotted-mauve {
  border-bottom: 3px dotted var(--mauve);
}

.border-bottom-3-double-mauve {
  border-bottom: 3px double var(--mauve);
}

.border-bottom-3-groove-mauve {
  border-bottom: 3px groove var(--mauve);
}

.border-bottom-3-ridge-mauve {
  border-bottom: 3px ridge var(--mauve);
}

.border-bottom-3-inset-mauve {
  border-bottom: 3px inset var(--mauve);
}

.border-bottom-3-outset-mauve {
  border-bottom: 3px outset var(--mauve);
}

.border-bottom-3-none-mauve {
  border-bottom: 3px none var(--mauve);
}

.border-bottom-3-hidden-mauve {
  border-bottom: 3px hidden var(--mauve);
}

.border-bottom-3-solid-rose_light {
  border-bottom: 3px solid var(--rose_light);
}

.border-bottom-3-dashed-rose_light {
  border-bottom: 3px dashed var(--rose_light);
}

.border-bottom-3-dotted-rose_light {
  border-bottom: 3px dotted var(--rose_light);
}

.border-bottom-3-double-rose_light {
  border-bottom: 3px double var(--rose_light);
}

.border-bottom-3-groove-rose_light {
  border-bottom: 3px groove var(--rose_light);
}

.border-bottom-3-ridge-rose_light {
  border-bottom: 3px ridge var(--rose_light);
}

.border-bottom-3-inset-rose_light {
  border-bottom: 3px inset var(--rose_light);
}

.border-bottom-3-outset-rose_light {
  border-bottom: 3px outset var(--rose_light);
}

.border-bottom-3-none-rose_light {
  border-bottom: 3px none var(--rose_light);
}

.border-bottom-3-hidden-rose_light {
  border-bottom: 3px hidden var(--rose_light);
}

.border-bottom-3-solid-rose_dark {
  border-bottom: 3px solid var(--rose_dark);
}

.border-bottom-3-dashed-rose_dark {
  border-bottom: 3px dashed var(--rose_dark);
}

.border-bottom-3-dotted-rose_dark {
  border-bottom: 3px dotted var(--rose_dark);
}

.border-bottom-3-double-rose_dark {
  border-bottom: 3px double var(--rose_dark);
}

.border-bottom-3-groove-rose_dark {
  border-bottom: 3px groove var(--rose_dark);
}

.border-bottom-3-ridge-rose_dark {
  border-bottom: 3px ridge var(--rose_dark);
}

.border-bottom-3-inset-rose_dark {
  border-bottom: 3px inset var(--rose_dark);
}

.border-bottom-3-outset-rose_dark {
  border-bottom: 3px outset var(--rose_dark);
}

.border-bottom-3-none-rose_dark {
  border-bottom: 3px none var(--rose_dark);
}

.border-bottom-3-hidden-rose_dark {
  border-bottom: 3px hidden var(--rose_dark);
}

.border-bottom-3-solid-rose_fushia {
  border-bottom: 3px solid var(--rose_fushia);
}

.border-bottom-3-dashed-rose_fushia {
  border-bottom: 3px dashed var(--rose_fushia);
}

.border-bottom-3-dotted-rose_fushia {
  border-bottom: 3px dotted var(--rose_fushia);
}

.border-bottom-3-double-rose_fushia {
  border-bottom: 3px double var(--rose_fushia);
}

.border-bottom-3-groove-rose_fushia {
  border-bottom: 3px groove var(--rose_fushia);
}

.border-bottom-3-ridge-rose_fushia {
  border-bottom: 3px ridge var(--rose_fushia);
}

.border-bottom-3-inset-rose_fushia {
  border-bottom: 3px inset var(--rose_fushia);
}

.border-bottom-3-outset-rose_fushia {
  border-bottom: 3px outset var(--rose_fushia);
}

.border-bottom-3-none-rose_fushia {
  border-bottom: 3px none var(--rose_fushia);
}

.border-bottom-3-hidden-rose_fushia {
  border-bottom: 3px hidden var(--rose_fushia);
}

.border-bottom-3-solid-rouge_framboise {
  border-bottom: 3px solid var(--rouge_framboise);
}

.border-bottom-3-dashed-rouge_framboise {
  border-bottom: 3px dashed var(--rouge_framboise);
}

.border-bottom-3-dotted-rouge_framboise {
  border-bottom: 3px dotted var(--rouge_framboise);
}

.border-bottom-3-double-rouge_framboise {
  border-bottom: 3px double var(--rouge_framboise);
}

.border-bottom-3-groove-rouge_framboise {
  border-bottom: 3px groove var(--rouge_framboise);
}

.border-bottom-3-ridge-rouge_framboise {
  border-bottom: 3px ridge var(--rouge_framboise);
}

.border-bottom-3-inset-rouge_framboise {
  border-bottom: 3px inset var(--rouge_framboise);
}

.border-bottom-3-outset-rouge_framboise {
  border-bottom: 3px outset var(--rouge_framboise);
}

.border-bottom-3-none-rouge_framboise {
  border-bottom: 3px none var(--rouge_framboise);
}

.border-bottom-3-hidden-rouge_framboise {
  border-bottom: 3px hidden var(--rouge_framboise);
}

.border-bottom-3-solid-rose_chair_light {
  border-bottom: 3px solid var(--rose_chair_light);
}

.border-bottom-3-dashed-rose_chair_light {
  border-bottom: 3px dashed var(--rose_chair_light);
}

.border-bottom-3-dotted-rose_chair_light {
  border-bottom: 3px dotted var(--rose_chair_light);
}

.border-bottom-3-double-rose_chair_light {
  border-bottom: 3px double var(--rose_chair_light);
}

.border-bottom-3-groove-rose_chair_light {
  border-bottom: 3px groove var(--rose_chair_light);
}

.border-bottom-3-ridge-rose_chair_light {
  border-bottom: 3px ridge var(--rose_chair_light);
}

.border-bottom-3-inset-rose_chair_light {
  border-bottom: 3px inset var(--rose_chair_light);
}

.border-bottom-3-outset-rose_chair_light {
  border-bottom: 3px outset var(--rose_chair_light);
}

.border-bottom-3-none-rose_chair_light {
  border-bottom: 3px none var(--rose_chair_light);
}

.border-bottom-3-hidden-rose_chair_light {
  border-bottom: 3px hidden var(--rose_chair_light);
}

.border-bottom-3-solid-rose_chair_dark {
  border-bottom: 3px solid var(--rose_chair_dark);
}

.border-bottom-3-dashed-rose_chair_dark {
  border-bottom: 3px dashed var(--rose_chair_dark);
}

.border-bottom-3-dotted-rose_chair_dark {
  border-bottom: 3px dotted var(--rose_chair_dark);
}

.border-bottom-3-double-rose_chair_dark {
  border-bottom: 3px double var(--rose_chair_dark);
}

.border-bottom-3-groove-rose_chair_dark {
  border-bottom: 3px groove var(--rose_chair_dark);
}

.border-bottom-3-ridge-rose_chair_dark {
  border-bottom: 3px ridge var(--rose_chair_dark);
}

.border-bottom-3-inset-rose_chair_dark {
  border-bottom: 3px inset var(--rose_chair_dark);
}

.border-bottom-3-outset-rose_chair_dark {
  border-bottom: 3px outset var(--rose_chair_dark);
}

.border-bottom-3-none-rose_chair_dark {
  border-bottom: 3px none var(--rose_chair_dark);
}

.border-bottom-3-hidden-rose_chair_dark {
  border-bottom: 3px hidden var(--rose_chair_dark);
}

.border-bottom-3-solid-vert_positif {
  border-bottom: 3px solid var(--vert_positif);
}

.border-bottom-3-dashed-vert_positif {
  border-bottom: 3px dashed var(--vert_positif);
}

.border-bottom-3-dotted-vert_positif {
  border-bottom: 3px dotted var(--vert_positif);
}

.border-bottom-3-double-vert_positif {
  border-bottom: 3px double var(--vert_positif);
}

.border-bottom-3-groove-vert_positif {
  border-bottom: 3px groove var(--vert_positif);
}

.border-bottom-3-ridge-vert_positif {
  border-bottom: 3px ridge var(--vert_positif);
}

.border-bottom-3-inset-vert_positif {
  border-bottom: 3px inset var(--vert_positif);
}

.border-bottom-3-outset-vert_positif {
  border-bottom: 3px outset var(--vert_positif);
}

.border-bottom-3-none-vert_positif {
  border-bottom: 3px none var(--vert_positif);
}

.border-bottom-3-hidden-vert_positif {
  border-bottom: 3px hidden var(--vert_positif);
}

.border-bottom-3-solid-rouge_negatif {
  border-bottom: 3px solid var(--rouge_negatif);
}

.border-bottom-3-dashed-rouge_negatif {
  border-bottom: 3px dashed var(--rouge_negatif);
}

.border-bottom-3-dotted-rouge_negatif {
  border-bottom: 3px dotted var(--rouge_negatif);
}

.border-bottom-3-double-rouge_negatif {
  border-bottom: 3px double var(--rouge_negatif);
}

.border-bottom-3-groove-rouge_negatif {
  border-bottom: 3px groove var(--rouge_negatif);
}

.border-bottom-3-ridge-rouge_negatif {
  border-bottom: 3px ridge var(--rouge_negatif);
}

.border-bottom-3-inset-rouge_negatif {
  border-bottom: 3px inset var(--rouge_negatif);
}

.border-bottom-3-outset-rouge_negatif {
  border-bottom: 3px outset var(--rouge_negatif);
}

.border-bottom-3-none-rouge_negatif {
  border-bottom: 3px none var(--rouge_negatif);
}

.border-bottom-3-hidden-rouge_negatif {
  border-bottom: 3px hidden var(--rouge_negatif);
}

.border-bottom-3-solid-bleu_texte {
  border-bottom: 3px solid var(--bleu_texte);
}

.border-bottom-3-dashed-bleu_texte {
  border-bottom: 3px dashed var(--bleu_texte);
}

.border-bottom-3-dotted-bleu_texte {
  border-bottom: 3px dotted var(--bleu_texte);
}

.border-bottom-3-double-bleu_texte {
  border-bottom: 3px double var(--bleu_texte);
}

.border-bottom-3-groove-bleu_texte {
  border-bottom: 3px groove var(--bleu_texte);
}

.border-bottom-3-ridge-bleu_texte {
  border-bottom: 3px ridge var(--bleu_texte);
}

.border-bottom-3-inset-bleu_texte {
  border-bottom: 3px inset var(--bleu_texte);
}

.border-bottom-3-outset-bleu_texte {
  border-bottom: 3px outset var(--bleu_texte);
}

.border-bottom-3-none-bleu_texte {
  border-bottom: 3px none var(--bleu_texte);
}

.border-bottom-3-hidden-bleu_texte {
  border-bottom: 3px hidden var(--bleu_texte);
}

.border-bottom-3-solid-alerte_vert_positif {
  border-bottom: 3px solid var(--alerte_vert_positif);
}

.border-bottom-3-dashed-alerte_vert_positif {
  border-bottom: 3px dashed var(--alerte_vert_positif);
}

.border-bottom-3-dotted-alerte_vert_positif {
  border-bottom: 3px dotted var(--alerte_vert_positif);
}

.border-bottom-3-double-alerte_vert_positif {
  border-bottom: 3px double var(--alerte_vert_positif);
}

.border-bottom-3-groove-alerte_vert_positif {
  border-bottom: 3px groove var(--alerte_vert_positif);
}

.border-bottom-3-ridge-alerte_vert_positif {
  border-bottom: 3px ridge var(--alerte_vert_positif);
}

.border-bottom-3-inset-alerte_vert_positif {
  border-bottom: 3px inset var(--alerte_vert_positif);
}

.border-bottom-3-outset-alerte_vert_positif {
  border-bottom: 3px outset var(--alerte_vert_positif);
}

.border-bottom-3-none-alerte_vert_positif {
  border-bottom: 3px none var(--alerte_vert_positif);
}

.border-bottom-3-hidden-alerte_vert_positif {
  border-bottom: 3px hidden var(--alerte_vert_positif);
}

.border-bottom-3-solid-alerte_rouge_negatif {
  border-bottom: 3px solid var(--alerte_rouge_negatif);
}

.border-bottom-3-dashed-alerte_rouge_negatif {
  border-bottom: 3px dashed var(--alerte_rouge_negatif);
}

.border-bottom-3-dotted-alerte_rouge_negatif {
  border-bottom: 3px dotted var(--alerte_rouge_negatif);
}

.border-bottom-3-double-alerte_rouge_negatif {
  border-bottom: 3px double var(--alerte_rouge_negatif);
}

.border-bottom-3-groove-alerte_rouge_negatif {
  border-bottom: 3px groove var(--alerte_rouge_negatif);
}

.border-bottom-3-ridge-alerte_rouge_negatif {
  border-bottom: 3px ridge var(--alerte_rouge_negatif);
}

.border-bottom-3-inset-alerte_rouge_negatif {
  border-bottom: 3px inset var(--alerte_rouge_negatif);
}

.border-bottom-3-outset-alerte_rouge_negatif {
  border-bottom: 3px outset var(--alerte_rouge_negatif);
}

.border-bottom-3-none-alerte_rouge_negatif {
  border-bottom: 3px none var(--alerte_rouge_negatif);
}

.border-bottom-3-hidden-alerte_rouge_negatif {
  border-bottom: 3px hidden var(--alerte_rouge_negatif);
}

.border-bottom-3-solid-alerte_bleu_information {
  border-bottom: 3px solid var(--alerte_bleu_information);
}

.border-bottom-3-dashed-alerte_bleu_information {
  border-bottom: 3px dashed var(--alerte_bleu_information);
}

.border-bottom-3-dotted-alerte_bleu_information {
  border-bottom: 3px dotted var(--alerte_bleu_information);
}

.border-bottom-3-double-alerte_bleu_information {
  border-bottom: 3px double var(--alerte_bleu_information);
}

.border-bottom-3-groove-alerte_bleu_information {
  border-bottom: 3px groove var(--alerte_bleu_information);
}

.border-bottom-3-ridge-alerte_bleu_information {
  border-bottom: 3px ridge var(--alerte_bleu_information);
}

.border-bottom-3-inset-alerte_bleu_information {
  border-bottom: 3px inset var(--alerte_bleu_information);
}

.border-bottom-3-outset-alerte_bleu_information {
  border-bottom: 3px outset var(--alerte_bleu_information);
}

.border-bottom-3-none-alerte_bleu_information {
  border-bottom: 3px none var(--alerte_bleu_information);
}

.border-bottom-3-hidden-alerte_bleu_information {
  border-bottom: 3px hidden var(--alerte_bleu_information);
}

.border-bottom-3-solid-alerte_jaune_attention {
  border-bottom: 3px solid var(--alerte_jaune_attention);
}

.border-bottom-3-dashed-alerte_jaune_attention {
  border-bottom: 3px dashed var(--alerte_jaune_attention);
}

.border-bottom-3-dotted-alerte_jaune_attention {
  border-bottom: 3px dotted var(--alerte_jaune_attention);
}

.border-bottom-3-double-alerte_jaune_attention {
  border-bottom: 3px double var(--alerte_jaune_attention);
}

.border-bottom-3-groove-alerte_jaune_attention {
  border-bottom: 3px groove var(--alerte_jaune_attention);
}

.border-bottom-3-ridge-alerte_jaune_attention {
  border-bottom: 3px ridge var(--alerte_jaune_attention);
}

.border-bottom-3-inset-alerte_jaune_attention {
  border-bottom: 3px inset var(--alerte_jaune_attention);
}

.border-bottom-3-outset-alerte_jaune_attention {
  border-bottom: 3px outset var(--alerte_jaune_attention);
}

.border-bottom-3-none-alerte_jaune_attention {
  border-bottom: 3px none var(--alerte_jaune_attention);
}

.border-bottom-3-hidden-alerte_jaune_attention {
  border-bottom: 3px hidden var(--alerte_jaune_attention);
}

.border-bottom-3-solid-blanc {
  border-bottom: 3px solid #fff;
}

.border-bottom-3-dashed-blanc {
  border-bottom: 3px dashed #fff;
}

.border-bottom-3-dotted-blanc {
  border-bottom: 3px dotted #fff;
}

.border-bottom-3-double-blanc {
  border-bottom: 3px double #fff;
}

.border-bottom-3-groove-blanc {
  border-bottom: 3px groove #fff;
}

.border-bottom-3-ridge-blanc {
  border-bottom: 3px ridge #fff;
}

.border-bottom-3-inset-blanc {
  border-bottom: 3px inset #fff;
}

.border-bottom-3-outset-blanc {
  border-bottom: 3px outset #fff;
}

.border-bottom-3-none-blanc {
  border-bottom: 3px #fff;
}

.border-bottom-3-hidden-blanc {
  border-bottom: 3px hidden #fff;
}

.border-bottom-3-solid-noir {
  border-bottom: 3px solid #222;
}

.border-bottom-3-dashed-noir {
  border-bottom: 3px dashed #222;
}

.border-bottom-3-dotted-noir {
  border-bottom: 3px dotted #222;
}

.border-bottom-3-double-noir {
  border-bottom: 3px double #222;
}

.border-bottom-3-groove-noir {
  border-bottom: 3px groove #222;
}

.border-bottom-3-ridge-noir {
  border-bottom: 3px ridge #222;
}

.border-bottom-3-inset-noir {
  border-bottom: 3px inset #222;
}

.border-bottom-3-outset-noir {
  border-bottom: 3px outset #222;
}

.border-bottom-3-none-noir {
  border-bottom: 3px #222;
}

.border-bottom-3-hidden-noir {
  border-bottom: 3px hidden #222;
}

.border-bottom-3-solid-gris_light {
  border-bottom: 3px solid var(--gris_light);
}

.border-bottom-3-dashed-gris_light {
  border-bottom: 3px dashed var(--gris_light);
}

.border-bottom-3-dotted-gris_light {
  border-bottom: 3px dotted var(--gris_light);
}

.border-bottom-3-double-gris_light {
  border-bottom: 3px double var(--gris_light);
}

.border-bottom-3-groove-gris_light {
  border-bottom: 3px groove var(--gris_light);
}

.border-bottom-3-ridge-gris_light {
  border-bottom: 3px ridge var(--gris_light);
}

.border-bottom-3-inset-gris_light {
  border-bottom: 3px inset var(--gris_light);
}

.border-bottom-3-outset-gris_light {
  border-bottom: 3px outset var(--gris_light);
}

.border-bottom-3-none-gris_light {
  border-bottom: 3px none var(--gris_light);
}

.border-bottom-3-hidden-gris_light {
  border-bottom: 3px hidden var(--gris_light);
}

.border-bottom-3-solid-gris_dark {
  border-bottom: 3px solid var(--gris_dark);
}

.border-bottom-3-dashed-gris_dark {
  border-bottom: 3px dashed var(--gris_dark);
}

.border-bottom-3-dotted-gris_dark {
  border-bottom: 3px dotted var(--gris_dark);
}

.border-bottom-3-double-gris_dark {
  border-bottom: 3px double var(--gris_dark);
}

.border-bottom-3-groove-gris_dark {
  border-bottom: 3px groove var(--gris_dark);
}

.border-bottom-3-ridge-gris_dark {
  border-bottom: 3px ridge var(--gris_dark);
}

.border-bottom-3-inset-gris_dark {
  border-bottom: 3px inset var(--gris_dark);
}

.border-bottom-3-outset-gris_dark {
  border-bottom: 3px outset var(--gris_dark);
}

.border-bottom-3-none-gris_dark {
  border-bottom: 3px none var(--gris_dark);
}

.border-bottom-3-hidden-gris_dark {
  border-bottom: 3px hidden var(--gris_dark);
}

.border-bottom-3-solid-gris_background {
  border-bottom: 3px solid var(--gris_background);
}

.border-bottom-3-dashed-gris_background {
  border-bottom: 3px dashed var(--gris_background);
}

.border-bottom-3-dotted-gris_background {
  border-bottom: 3px dotted var(--gris_background);
}

.border-bottom-3-double-gris_background {
  border-bottom: 3px double var(--gris_background);
}

.border-bottom-3-groove-gris_background {
  border-bottom: 3px groove var(--gris_background);
}

.border-bottom-3-ridge-gris_background {
  border-bottom: 3px ridge var(--gris_background);
}

.border-bottom-3-inset-gris_background {
  border-bottom: 3px inset var(--gris_background);
}

.border-bottom-3-outset-gris_background {
  border-bottom: 3px outset var(--gris_background);
}

.border-bottom-3-none-gris_background {
  border-bottom: 3px none var(--gris_background);
}

.border-bottom-3-hidden-gris_background {
  border-bottom: 3px hidden var(--gris_background);
}

.border-right-3-solid-corail_mh_brand {
  border-right: 3px solid var(--corail_mh_brand);
}

.border-right-3-dashed-corail_mh_brand {
  border-right: 3px dashed var(--corail_mh_brand);
}

.border-right-3-dotted-corail_mh_brand {
  border-right: 3px dotted var(--corail_mh_brand);
}

.border-right-3-double-corail_mh_brand {
  border-right: 3px double var(--corail_mh_brand);
}

.border-right-3-groove-corail_mh_brand {
  border-right: 3px groove var(--corail_mh_brand);
}

.border-right-3-ridge-corail_mh_brand {
  border-right: 3px ridge var(--corail_mh_brand);
}

.border-right-3-inset-corail_mh_brand {
  border-right: 3px inset var(--corail_mh_brand);
}

.border-right-3-outset-corail_mh_brand {
  border-right: 3px outset var(--corail_mh_brand);
}

.border-right-3-none-corail_mh_brand {
  border-right: 3px none var(--corail_mh_brand);
}

.border-right-3-hidden-corail_mh_brand {
  border-right: 3px hidden var(--corail_mh_brand);
}

.border-right-3-solid-corail_mh_dark {
  border-right: 3px solid var(--corail_mh_dark);
}

.border-right-3-dashed-corail_mh_dark {
  border-right: 3px dashed var(--corail_mh_dark);
}

.border-right-3-dotted-corail_mh_dark {
  border-right: 3px dotted var(--corail_mh_dark);
}

.border-right-3-double-corail_mh_dark {
  border-right: 3px double var(--corail_mh_dark);
}

.border-right-3-groove-corail_mh_dark {
  border-right: 3px groove var(--corail_mh_dark);
}

.border-right-3-ridge-corail_mh_dark {
  border-right: 3px ridge var(--corail_mh_dark);
}

.border-right-3-inset-corail_mh_dark {
  border-right: 3px inset var(--corail_mh_dark);
}

.border-right-3-outset-corail_mh_dark {
  border-right: 3px outset var(--corail_mh_dark);
}

.border-right-3-none-corail_mh_dark {
  border-right: 3px none var(--corail_mh_dark);
}

.border-right-3-hidden-corail_mh_dark {
  border-right: 3px hidden var(--corail_mh_dark);
}

.border-right-3-solid-corail_mh_light {
  border-right: 3px solid var(--corail_mh_light);
}

.border-right-3-dashed-corail_mh_light {
  border-right: 3px dashed var(--corail_mh_light);
}

.border-right-3-dotted-corail_mh_light {
  border-right: 3px dotted var(--corail_mh_light);
}

.border-right-3-double-corail_mh_light {
  border-right: 3px double var(--corail_mh_light);
}

.border-right-3-groove-corail_mh_light {
  border-right: 3px groove var(--corail_mh_light);
}

.border-right-3-ridge-corail_mh_light {
  border-right: 3px ridge var(--corail_mh_light);
}

.border-right-3-inset-corail_mh_light {
  border-right: 3px inset var(--corail_mh_light);
}

.border-right-3-outset-corail_mh_light {
  border-right: 3px outset var(--corail_mh_light);
}

.border-right-3-none-corail_mh_light {
  border-right: 3px none var(--corail_mh_light);
}

.border-right-3-hidden-corail_mh_light {
  border-right: 3px hidden var(--corail_mh_light);
}

.border-right-3-solid-gris_sable_light {
  border-right: 3px solid var(--gris_sable_light);
}

.border-right-3-dashed-gris_sable_light {
  border-right: 3px dashed var(--gris_sable_light);
}

.border-right-3-dotted-gris_sable_light {
  border-right: 3px dotted var(--gris_sable_light);
}

.border-right-3-double-gris_sable_light {
  border-right: 3px double var(--gris_sable_light);
}

.border-right-3-groove-gris_sable_light {
  border-right: 3px groove var(--gris_sable_light);
}

.border-right-3-ridge-gris_sable_light {
  border-right: 3px ridge var(--gris_sable_light);
}

.border-right-3-inset-gris_sable_light {
  border-right: 3px inset var(--gris_sable_light);
}

.border-right-3-outset-gris_sable_light {
  border-right: 3px outset var(--gris_sable_light);
}

.border-right-3-none-gris_sable_light {
  border-right: 3px none var(--gris_sable_light);
}

.border-right-3-hidden-gris_sable_light {
  border-right: 3px hidden var(--gris_sable_light);
}

.border-right-3-solid-gris_sable_dark {
  border-right: 3px solid var(--gris_sable_dark);
}

.border-right-3-dashed-gris_sable_dark {
  border-right: 3px dashed var(--gris_sable_dark);
}

.border-right-3-dotted-gris_sable_dark {
  border-right: 3px dotted var(--gris_sable_dark);
}

.border-right-3-double-gris_sable_dark {
  border-right: 3px double var(--gris_sable_dark);
}

.border-right-3-groove-gris_sable_dark {
  border-right: 3px groove var(--gris_sable_dark);
}

.border-right-3-ridge-gris_sable_dark {
  border-right: 3px ridge var(--gris_sable_dark);
}

.border-right-3-inset-gris_sable_dark {
  border-right: 3px inset var(--gris_sable_dark);
}

.border-right-3-outset-gris_sable_dark {
  border-right: 3px outset var(--gris_sable_dark);
}

.border-right-3-none-gris_sable_dark {
  border-right: 3px none var(--gris_sable_dark);
}

.border-right-3-hidden-gris_sable_dark {
  border-right: 3px hidden var(--gris_sable_dark);
}

.border-right-3-solid-jaune_ivoire_light {
  border-right: 3px solid var(--jaune_ivoire_light);
}

.border-right-3-dashed-jaune_ivoire_light {
  border-right: 3px dashed var(--jaune_ivoire_light);
}

.border-right-3-dotted-jaune_ivoire_light {
  border-right: 3px dotted var(--jaune_ivoire_light);
}

.border-right-3-double-jaune_ivoire_light {
  border-right: 3px double var(--jaune_ivoire_light);
}

.border-right-3-groove-jaune_ivoire_light {
  border-right: 3px groove var(--jaune_ivoire_light);
}

.border-right-3-ridge-jaune_ivoire_light {
  border-right: 3px ridge var(--jaune_ivoire_light);
}

.border-right-3-inset-jaune_ivoire_light {
  border-right: 3px inset var(--jaune_ivoire_light);
}

.border-right-3-outset-jaune_ivoire_light {
  border-right: 3px outset var(--jaune_ivoire_light);
}

.border-right-3-none-jaune_ivoire_light {
  border-right: 3px none var(--jaune_ivoire_light);
}

.border-right-3-hidden-jaune_ivoire_light {
  border-right: 3px hidden var(--jaune_ivoire_light);
}

.border-right-3-solid-jaune_ivoire_dark {
  border-right: 3px solid var(--jaune_ivoire_dark);
}

.border-right-3-dashed-jaune_ivoire_dark {
  border-right: 3px dashed var(--jaune_ivoire_dark);
}

.border-right-3-dotted-jaune_ivoire_dark {
  border-right: 3px dotted var(--jaune_ivoire_dark);
}

.border-right-3-double-jaune_ivoire_dark {
  border-right: 3px double var(--jaune_ivoire_dark);
}

.border-right-3-groove-jaune_ivoire_dark {
  border-right: 3px groove var(--jaune_ivoire_dark);
}

.border-right-3-ridge-jaune_ivoire_dark {
  border-right: 3px ridge var(--jaune_ivoire_dark);
}

.border-right-3-inset-jaune_ivoire_dark {
  border-right: 3px inset var(--jaune_ivoire_dark);
}

.border-right-3-outset-jaune_ivoire_dark {
  border-right: 3px outset var(--jaune_ivoire_dark);
}

.border-right-3-none-jaune_ivoire_dark {
  border-right: 3px none var(--jaune_ivoire_dark);
}

.border-right-3-hidden-jaune_ivoire_dark {
  border-right: 3px hidden var(--jaune_ivoire_dark);
}

.border-right-3-solid-jaune_light {
  border-right: 3px solid var(--jaune_light);
}

.border-right-3-dashed-jaune_light {
  border-right: 3px dashed var(--jaune_light);
}

.border-right-3-dotted-jaune_light {
  border-right: 3px dotted var(--jaune_light);
}

.border-right-3-double-jaune_light {
  border-right: 3px double var(--jaune_light);
}

.border-right-3-groove-jaune_light {
  border-right: 3px groove var(--jaune_light);
}

.border-right-3-ridge-jaune_light {
  border-right: 3px ridge var(--jaune_light);
}

.border-right-3-inset-jaune_light {
  border-right: 3px inset var(--jaune_light);
}

.border-right-3-outset-jaune_light {
  border-right: 3px outset var(--jaune_light);
}

.border-right-3-none-jaune_light {
  border-right: 3px none var(--jaune_light);
}

.border-right-3-hidden-jaune_light {
  border-right: 3px hidden var(--jaune_light);
}

.border-right-3-solid-jaune_dark {
  border-right: 3px solid var(--jaune_dark);
}

.border-right-3-dashed-jaune_dark {
  border-right: 3px dashed var(--jaune_dark);
}

.border-right-3-dotted-jaune_dark {
  border-right: 3px dotted var(--jaune_dark);
}

.border-right-3-double-jaune_dark {
  border-right: 3px double var(--jaune_dark);
}

.border-right-3-groove-jaune_dark {
  border-right: 3px groove var(--jaune_dark);
}

.border-right-3-ridge-jaune_dark {
  border-right: 3px ridge var(--jaune_dark);
}

.border-right-3-inset-jaune_dark {
  border-right: 3px inset var(--jaune_dark);
}

.border-right-3-outset-jaune_dark {
  border-right: 3px outset var(--jaune_dark);
}

.border-right-3-none-jaune_dark {
  border-right: 3px none var(--jaune_dark);
}

.border-right-3-hidden-jaune_dark {
  border-right: 3px hidden var(--jaune_dark);
}

.border-right-3-solid-orange_normal {
  border-right: 3px solid var(--orange_normal);
}

.border-right-3-dashed-orange_normal {
  border-right: 3px dashed var(--orange_normal);
}

.border-right-3-dotted-orange_normal {
  border-right: 3px dotted var(--orange_normal);
}

.border-right-3-double-orange_normal {
  border-right: 3px double var(--orange_normal);
}

.border-right-3-groove-orange_normal {
  border-right: 3px groove var(--orange_normal);
}

.border-right-3-ridge-orange_normal {
  border-right: 3px ridge var(--orange_normal);
}

.border-right-3-inset-orange_normal {
  border-right: 3px inset var(--orange_normal);
}

.border-right-3-outset-orange_normal {
  border-right: 3px outset var(--orange_normal);
}

.border-right-3-none-orange_normal {
  border-right: 3px none var(--orange_normal);
}

.border-right-3-hidden-orange_normal {
  border-right: 3px hidden var(--orange_normal);
}

.border-right-3-solid-orange_dark {
  border-right: 3px solid var(--orange_dark);
}

.border-right-3-dashed-orange_dark {
  border-right: 3px dashed var(--orange_dark);
}

.border-right-3-dotted-orange_dark {
  border-right: 3px dotted var(--orange_dark);
}

.border-right-3-double-orange_dark {
  border-right: 3px double var(--orange_dark);
}

.border-right-3-groove-orange_dark {
  border-right: 3px groove var(--orange_dark);
}

.border-right-3-ridge-orange_dark {
  border-right: 3px ridge var(--orange_dark);
}

.border-right-3-inset-orange_dark {
  border-right: 3px inset var(--orange_dark);
}

.border-right-3-outset-orange_dark {
  border-right: 3px outset var(--orange_dark);
}

.border-right-3-none-orange_dark {
  border-right: 3px none var(--orange_dark);
}

.border-right-3-hidden-orange_dark {
  border-right: 3px hidden var(--orange_dark);
}

.border-right-3-solid-vert_light {
  border-right: 3px solid var(--vert_light);
}

.border-right-3-dashed-vert_light {
  border-right: 3px dashed var(--vert_light);
}

.border-right-3-dotted-vert_light {
  border-right: 3px dotted var(--vert_light);
}

.border-right-3-double-vert_light {
  border-right: 3px double var(--vert_light);
}

.border-right-3-groove-vert_light {
  border-right: 3px groove var(--vert_light);
}

.border-right-3-ridge-vert_light {
  border-right: 3px ridge var(--vert_light);
}

.border-right-3-inset-vert_light {
  border-right: 3px inset var(--vert_light);
}

.border-right-3-outset-vert_light {
  border-right: 3px outset var(--vert_light);
}

.border-right-3-none-vert_light {
  border-right: 3px none var(--vert_light);
}

.border-right-3-hidden-vert_light {
  border-right: 3px hidden var(--vert_light);
}

.border-right-3-solid-vert_dark {
  border-right: 3px solid var(--vert_dark);
}

.border-right-3-dashed-vert_dark {
  border-right: 3px dashed var(--vert_dark);
}

.border-right-3-dotted-vert_dark {
  border-right: 3px dotted var(--vert_dark);
}

.border-right-3-double-vert_dark {
  border-right: 3px double var(--vert_dark);
}

.border-right-3-groove-vert_dark {
  border-right: 3px groove var(--vert_dark);
}

.border-right-3-ridge-vert_dark {
  border-right: 3px ridge var(--vert_dark);
}

.border-right-3-inset-vert_dark {
  border-right: 3px inset var(--vert_dark);
}

.border-right-3-outset-vert_dark {
  border-right: 3px outset var(--vert_dark);
}

.border-right-3-none-vert_dark {
  border-right: 3px none var(--vert_dark);
}

.border-right-3-hidden-vert_dark {
  border-right: 3px hidden var(--vert_dark);
}

.border-right-3-solid-bleu_turquoise_light {
  border-right: 3px solid var(--bleu_turquoise_light);
}

.border-right-3-dashed-bleu_turquoise_light {
  border-right: 3px dashed var(--bleu_turquoise_light);
}

.border-right-3-dotted-bleu_turquoise_light {
  border-right: 3px dotted var(--bleu_turquoise_light);
}

.border-right-3-double-bleu_turquoise_light {
  border-right: 3px double var(--bleu_turquoise_light);
}

.border-right-3-groove-bleu_turquoise_light {
  border-right: 3px groove var(--bleu_turquoise_light);
}

.border-right-3-ridge-bleu_turquoise_light {
  border-right: 3px ridge var(--bleu_turquoise_light);
}

.border-right-3-inset-bleu_turquoise_light {
  border-right: 3px inset var(--bleu_turquoise_light);
}

.border-right-3-outset-bleu_turquoise_light {
  border-right: 3px outset var(--bleu_turquoise_light);
}

.border-right-3-none-bleu_turquoise_light {
  border-right: 3px none var(--bleu_turquoise_light);
}

.border-right-3-hidden-bleu_turquoise_light {
  border-right: 3px hidden var(--bleu_turquoise_light);
}

.border-right-3-solid-bleu_turquoise_middle {
  border-right: 3px solid var(--bleu_turquoise_middle);
}

.border-right-3-dashed-bleu_turquoise_middle {
  border-right: 3px dashed var(--bleu_turquoise_middle);
}

.border-right-3-dotted-bleu_turquoise_middle {
  border-right: 3px dotted var(--bleu_turquoise_middle);
}

.border-right-3-double-bleu_turquoise_middle {
  border-right: 3px double var(--bleu_turquoise_middle);
}

.border-right-3-groove-bleu_turquoise_middle {
  border-right: 3px groove var(--bleu_turquoise_middle);
}

.border-right-3-ridge-bleu_turquoise_middle {
  border-right: 3px ridge var(--bleu_turquoise_middle);
}

.border-right-3-inset-bleu_turquoise_middle {
  border-right: 3px inset var(--bleu_turquoise_middle);
}

.border-right-3-outset-bleu_turquoise_middle {
  border-right: 3px outset var(--bleu_turquoise_middle);
}

.border-right-3-none-bleu_turquoise_middle {
  border-right: 3px none var(--bleu_turquoise_middle);
}

.border-right-3-hidden-bleu_turquoise_middle {
  border-right: 3px hidden var(--bleu_turquoise_middle);
}

.border-right-3-solid-bleu_turquoise_dark {
  border-right: 3px solid var(--bleu_turquoise_dark);
}

.border-right-3-dashed-bleu_turquoise_dark {
  border-right: 3px dashed var(--bleu_turquoise_dark);
}

.border-right-3-dotted-bleu_turquoise_dark {
  border-right: 3px dotted var(--bleu_turquoise_dark);
}

.border-right-3-double-bleu_turquoise_dark {
  border-right: 3px double var(--bleu_turquoise_dark);
}

.border-right-3-groove-bleu_turquoise_dark {
  border-right: 3px groove var(--bleu_turquoise_dark);
}

.border-right-3-ridge-bleu_turquoise_dark {
  border-right: 3px ridge var(--bleu_turquoise_dark);
}

.border-right-3-inset-bleu_turquoise_dark {
  border-right: 3px inset var(--bleu_turquoise_dark);
}

.border-right-3-outset-bleu_turquoise_dark {
  border-right: 3px outset var(--bleu_turquoise_dark);
}

.border-right-3-none-bleu_turquoise_dark {
  border-right: 3px none var(--bleu_turquoise_dark);
}

.border-right-3-hidden-bleu_turquoise_dark {
  border-right: 3px hidden var(--bleu_turquoise_dark);
}

.border-right-3-solid-bleu_light {
  border-right: 3px solid var(--bleu_light);
}

.border-right-3-dashed-bleu_light {
  border-right: 3px dashed var(--bleu_light);
}

.border-right-3-dotted-bleu_light {
  border-right: 3px dotted var(--bleu_light);
}

.border-right-3-double-bleu_light {
  border-right: 3px double var(--bleu_light);
}

.border-right-3-groove-bleu_light {
  border-right: 3px groove var(--bleu_light);
}

.border-right-3-ridge-bleu_light {
  border-right: 3px ridge var(--bleu_light);
}

.border-right-3-inset-bleu_light {
  border-right: 3px inset var(--bleu_light);
}

.border-right-3-outset-bleu_light {
  border-right: 3px outset var(--bleu_light);
}

.border-right-3-none-bleu_light {
  border-right: 3px none var(--bleu_light);
}

.border-right-3-hidden-bleu_light {
  border-right: 3px hidden var(--bleu_light);
}

.border-right-3-solid-bleu_dark {
  border-right: 3px solid var(--bleu_dark);
}

.border-right-3-dashed-bleu_dark {
  border-right: 3px dashed var(--bleu_dark);
}

.border-right-3-dotted-bleu_dark {
  border-right: 3px dotted var(--bleu_dark);
}

.border-right-3-double-bleu_dark {
  border-right: 3px double var(--bleu_dark);
}

.border-right-3-groove-bleu_dark {
  border-right: 3px groove var(--bleu_dark);
}

.border-right-3-ridge-bleu_dark {
  border-right: 3px ridge var(--bleu_dark);
}

.border-right-3-inset-bleu_dark {
  border-right: 3px inset var(--bleu_dark);
}

.border-right-3-outset-bleu_dark {
  border-right: 3px outset var(--bleu_dark);
}

.border-right-3-none-bleu_dark {
  border-right: 3px none var(--bleu_dark);
}

.border-right-3-hidden-bleu_dark {
  border-right: 3px hidden var(--bleu_dark);
}

.border-right-3-solid-bleu_klein_light {
  border-right: 3px solid var(--bleu_klein_light);
}

.border-right-3-dashed-bleu_klein_light {
  border-right: 3px dashed var(--bleu_klein_light);
}

.border-right-3-dotted-bleu_klein_light {
  border-right: 3px dotted var(--bleu_klein_light);
}

.border-right-3-double-bleu_klein_light {
  border-right: 3px double var(--bleu_klein_light);
}

.border-right-3-groove-bleu_klein_light {
  border-right: 3px groove var(--bleu_klein_light);
}

.border-right-3-ridge-bleu_klein_light {
  border-right: 3px ridge var(--bleu_klein_light);
}

.border-right-3-inset-bleu_klein_light {
  border-right: 3px inset var(--bleu_klein_light);
}

.border-right-3-outset-bleu_klein_light {
  border-right: 3px outset var(--bleu_klein_light);
}

.border-right-3-none-bleu_klein_light {
  border-right: 3px none var(--bleu_klein_light);
}

.border-right-3-hidden-bleu_klein_light {
  border-right: 3px hidden var(--bleu_klein_light);
}

.border-right-3-solid-bleu_klein_dark {
  border-right: 3px solid var(--bleu_klein_dark);
}

.border-right-3-dashed-bleu_klein_dark {
  border-right: 3px dashed var(--bleu_klein_dark);
}

.border-right-3-dotted-bleu_klein_dark {
  border-right: 3px dotted var(--bleu_klein_dark);
}

.border-right-3-double-bleu_klein_dark {
  border-right: 3px double var(--bleu_klein_dark);
}

.border-right-3-groove-bleu_klein_dark {
  border-right: 3px groove var(--bleu_klein_dark);
}

.border-right-3-ridge-bleu_klein_dark {
  border-right: 3px ridge var(--bleu_klein_dark);
}

.border-right-3-inset-bleu_klein_dark {
  border-right: 3px inset var(--bleu_klein_dark);
}

.border-right-3-outset-bleu_klein_dark {
  border-right: 3px outset var(--bleu_klein_dark);
}

.border-right-3-none-bleu_klein_dark {
  border-right: 3px none var(--bleu_klein_dark);
}

.border-right-3-hidden-bleu_klein_dark {
  border-right: 3px hidden var(--bleu_klein_dark);
}

.border-right-3-solid-violet_light {
  border-right: 3px solid var(--violet_light);
}

.border-right-3-dashed-violet_light {
  border-right: 3px dashed var(--violet_light);
}

.border-right-3-dotted-violet_light {
  border-right: 3px dotted var(--violet_light);
}

.border-right-3-double-violet_light {
  border-right: 3px double var(--violet_light);
}

.border-right-3-groove-violet_light {
  border-right: 3px groove var(--violet_light);
}

.border-right-3-ridge-violet_light {
  border-right: 3px ridge var(--violet_light);
}

.border-right-3-inset-violet_light {
  border-right: 3px inset var(--violet_light);
}

.border-right-3-outset-violet_light {
  border-right: 3px outset var(--violet_light);
}

.border-right-3-none-violet_light {
  border-right: 3px none var(--violet_light);
}

.border-right-3-hidden-violet_light {
  border-right: 3px hidden var(--violet_light);
}

.border-right-3-solid-violet_dark {
  border-right: 3px solid var(--violet_dark);
}

.border-right-3-dashed-violet_dark {
  border-right: 3px dashed var(--violet_dark);
}

.border-right-3-dotted-violet_dark {
  border-right: 3px dotted var(--violet_dark);
}

.border-right-3-double-violet_dark {
  border-right: 3px double var(--violet_dark);
}

.border-right-3-groove-violet_dark {
  border-right: 3px groove var(--violet_dark);
}

.border-right-3-ridge-violet_dark {
  border-right: 3px ridge var(--violet_dark);
}

.border-right-3-inset-violet_dark {
  border-right: 3px inset var(--violet_dark);
}

.border-right-3-outset-violet_dark {
  border-right: 3px outset var(--violet_dark);
}

.border-right-3-none-violet_dark {
  border-right: 3px none var(--violet_dark);
}

.border-right-3-hidden-violet_dark {
  border-right: 3px hidden var(--violet_dark);
}

.border-right-3-solid-mauve {
  border-right: 3px solid var(--mauve);
}

.border-right-3-dashed-mauve {
  border-right: 3px dashed var(--mauve);
}

.border-right-3-dotted-mauve {
  border-right: 3px dotted var(--mauve);
}

.border-right-3-double-mauve {
  border-right: 3px double var(--mauve);
}

.border-right-3-groove-mauve {
  border-right: 3px groove var(--mauve);
}

.border-right-3-ridge-mauve {
  border-right: 3px ridge var(--mauve);
}

.border-right-3-inset-mauve {
  border-right: 3px inset var(--mauve);
}

.border-right-3-outset-mauve {
  border-right: 3px outset var(--mauve);
}

.border-right-3-none-mauve {
  border-right: 3px none var(--mauve);
}

.border-right-3-hidden-mauve {
  border-right: 3px hidden var(--mauve);
}

.border-right-3-solid-rose_light {
  border-right: 3px solid var(--rose_light);
}

.border-right-3-dashed-rose_light {
  border-right: 3px dashed var(--rose_light);
}

.border-right-3-dotted-rose_light {
  border-right: 3px dotted var(--rose_light);
}

.border-right-3-double-rose_light {
  border-right: 3px double var(--rose_light);
}

.border-right-3-groove-rose_light {
  border-right: 3px groove var(--rose_light);
}

.border-right-3-ridge-rose_light {
  border-right: 3px ridge var(--rose_light);
}

.border-right-3-inset-rose_light {
  border-right: 3px inset var(--rose_light);
}

.border-right-3-outset-rose_light {
  border-right: 3px outset var(--rose_light);
}

.border-right-3-none-rose_light {
  border-right: 3px none var(--rose_light);
}

.border-right-3-hidden-rose_light {
  border-right: 3px hidden var(--rose_light);
}

.border-right-3-solid-rose_dark {
  border-right: 3px solid var(--rose_dark);
}

.border-right-3-dashed-rose_dark {
  border-right: 3px dashed var(--rose_dark);
}

.border-right-3-dotted-rose_dark {
  border-right: 3px dotted var(--rose_dark);
}

.border-right-3-double-rose_dark {
  border-right: 3px double var(--rose_dark);
}

.border-right-3-groove-rose_dark {
  border-right: 3px groove var(--rose_dark);
}

.border-right-3-ridge-rose_dark {
  border-right: 3px ridge var(--rose_dark);
}

.border-right-3-inset-rose_dark {
  border-right: 3px inset var(--rose_dark);
}

.border-right-3-outset-rose_dark {
  border-right: 3px outset var(--rose_dark);
}

.border-right-3-none-rose_dark {
  border-right: 3px none var(--rose_dark);
}

.border-right-3-hidden-rose_dark {
  border-right: 3px hidden var(--rose_dark);
}

.border-right-3-solid-rose_fushia {
  border-right: 3px solid var(--rose_fushia);
}

.border-right-3-dashed-rose_fushia {
  border-right: 3px dashed var(--rose_fushia);
}

.border-right-3-dotted-rose_fushia {
  border-right: 3px dotted var(--rose_fushia);
}

.border-right-3-double-rose_fushia {
  border-right: 3px double var(--rose_fushia);
}

.border-right-3-groove-rose_fushia {
  border-right: 3px groove var(--rose_fushia);
}

.border-right-3-ridge-rose_fushia {
  border-right: 3px ridge var(--rose_fushia);
}

.border-right-3-inset-rose_fushia {
  border-right: 3px inset var(--rose_fushia);
}

.border-right-3-outset-rose_fushia {
  border-right: 3px outset var(--rose_fushia);
}

.border-right-3-none-rose_fushia {
  border-right: 3px none var(--rose_fushia);
}

.border-right-3-hidden-rose_fushia {
  border-right: 3px hidden var(--rose_fushia);
}

.border-right-3-solid-rouge_framboise {
  border-right: 3px solid var(--rouge_framboise);
}

.border-right-3-dashed-rouge_framboise {
  border-right: 3px dashed var(--rouge_framboise);
}

.border-right-3-dotted-rouge_framboise {
  border-right: 3px dotted var(--rouge_framboise);
}

.border-right-3-double-rouge_framboise {
  border-right: 3px double var(--rouge_framboise);
}

.border-right-3-groove-rouge_framboise {
  border-right: 3px groove var(--rouge_framboise);
}

.border-right-3-ridge-rouge_framboise {
  border-right: 3px ridge var(--rouge_framboise);
}

.border-right-3-inset-rouge_framboise {
  border-right: 3px inset var(--rouge_framboise);
}

.border-right-3-outset-rouge_framboise {
  border-right: 3px outset var(--rouge_framboise);
}

.border-right-3-none-rouge_framboise {
  border-right: 3px none var(--rouge_framboise);
}

.border-right-3-hidden-rouge_framboise {
  border-right: 3px hidden var(--rouge_framboise);
}

.border-right-3-solid-rose_chair_light {
  border-right: 3px solid var(--rose_chair_light);
}

.border-right-3-dashed-rose_chair_light {
  border-right: 3px dashed var(--rose_chair_light);
}

.border-right-3-dotted-rose_chair_light {
  border-right: 3px dotted var(--rose_chair_light);
}

.border-right-3-double-rose_chair_light {
  border-right: 3px double var(--rose_chair_light);
}

.border-right-3-groove-rose_chair_light {
  border-right: 3px groove var(--rose_chair_light);
}

.border-right-3-ridge-rose_chair_light {
  border-right: 3px ridge var(--rose_chair_light);
}

.border-right-3-inset-rose_chair_light {
  border-right: 3px inset var(--rose_chair_light);
}

.border-right-3-outset-rose_chair_light {
  border-right: 3px outset var(--rose_chair_light);
}

.border-right-3-none-rose_chair_light {
  border-right: 3px none var(--rose_chair_light);
}

.border-right-3-hidden-rose_chair_light {
  border-right: 3px hidden var(--rose_chair_light);
}

.border-right-3-solid-rose_chair_dark {
  border-right: 3px solid var(--rose_chair_dark);
}

.border-right-3-dashed-rose_chair_dark {
  border-right: 3px dashed var(--rose_chair_dark);
}

.border-right-3-dotted-rose_chair_dark {
  border-right: 3px dotted var(--rose_chair_dark);
}

.border-right-3-double-rose_chair_dark {
  border-right: 3px double var(--rose_chair_dark);
}

.border-right-3-groove-rose_chair_dark {
  border-right: 3px groove var(--rose_chair_dark);
}

.border-right-3-ridge-rose_chair_dark {
  border-right: 3px ridge var(--rose_chair_dark);
}

.border-right-3-inset-rose_chair_dark {
  border-right: 3px inset var(--rose_chair_dark);
}

.border-right-3-outset-rose_chair_dark {
  border-right: 3px outset var(--rose_chair_dark);
}

.border-right-3-none-rose_chair_dark {
  border-right: 3px none var(--rose_chair_dark);
}

.border-right-3-hidden-rose_chair_dark {
  border-right: 3px hidden var(--rose_chair_dark);
}

.border-right-3-solid-vert_positif {
  border-right: 3px solid var(--vert_positif);
}

.border-right-3-dashed-vert_positif {
  border-right: 3px dashed var(--vert_positif);
}

.border-right-3-dotted-vert_positif {
  border-right: 3px dotted var(--vert_positif);
}

.border-right-3-double-vert_positif {
  border-right: 3px double var(--vert_positif);
}

.border-right-3-groove-vert_positif {
  border-right: 3px groove var(--vert_positif);
}

.border-right-3-ridge-vert_positif {
  border-right: 3px ridge var(--vert_positif);
}

.border-right-3-inset-vert_positif {
  border-right: 3px inset var(--vert_positif);
}

.border-right-3-outset-vert_positif {
  border-right: 3px outset var(--vert_positif);
}

.border-right-3-none-vert_positif {
  border-right: 3px none var(--vert_positif);
}

.border-right-3-hidden-vert_positif {
  border-right: 3px hidden var(--vert_positif);
}

.border-right-3-solid-rouge_negatif {
  border-right: 3px solid var(--rouge_negatif);
}

.border-right-3-dashed-rouge_negatif {
  border-right: 3px dashed var(--rouge_negatif);
}

.border-right-3-dotted-rouge_negatif {
  border-right: 3px dotted var(--rouge_negatif);
}

.border-right-3-double-rouge_negatif {
  border-right: 3px double var(--rouge_negatif);
}

.border-right-3-groove-rouge_negatif {
  border-right: 3px groove var(--rouge_negatif);
}

.border-right-3-ridge-rouge_negatif {
  border-right: 3px ridge var(--rouge_negatif);
}

.border-right-3-inset-rouge_negatif {
  border-right: 3px inset var(--rouge_negatif);
}

.border-right-3-outset-rouge_negatif {
  border-right: 3px outset var(--rouge_negatif);
}

.border-right-3-none-rouge_negatif {
  border-right: 3px none var(--rouge_negatif);
}

.border-right-3-hidden-rouge_negatif {
  border-right: 3px hidden var(--rouge_negatif);
}

.border-right-3-solid-bleu_texte {
  border-right: 3px solid var(--bleu_texte);
}

.border-right-3-dashed-bleu_texte {
  border-right: 3px dashed var(--bleu_texte);
}

.border-right-3-dotted-bleu_texte {
  border-right: 3px dotted var(--bleu_texte);
}

.border-right-3-double-bleu_texte {
  border-right: 3px double var(--bleu_texte);
}

.border-right-3-groove-bleu_texte {
  border-right: 3px groove var(--bleu_texte);
}

.border-right-3-ridge-bleu_texte {
  border-right: 3px ridge var(--bleu_texte);
}

.border-right-3-inset-bleu_texte {
  border-right: 3px inset var(--bleu_texte);
}

.border-right-3-outset-bleu_texte {
  border-right: 3px outset var(--bleu_texte);
}

.border-right-3-none-bleu_texte {
  border-right: 3px none var(--bleu_texte);
}

.border-right-3-hidden-bleu_texte {
  border-right: 3px hidden var(--bleu_texte);
}

.border-right-3-solid-alerte_vert_positif {
  border-right: 3px solid var(--alerte_vert_positif);
}

.border-right-3-dashed-alerte_vert_positif {
  border-right: 3px dashed var(--alerte_vert_positif);
}

.border-right-3-dotted-alerte_vert_positif {
  border-right: 3px dotted var(--alerte_vert_positif);
}

.border-right-3-double-alerte_vert_positif {
  border-right: 3px double var(--alerte_vert_positif);
}

.border-right-3-groove-alerte_vert_positif {
  border-right: 3px groove var(--alerte_vert_positif);
}

.border-right-3-ridge-alerte_vert_positif {
  border-right: 3px ridge var(--alerte_vert_positif);
}

.border-right-3-inset-alerte_vert_positif {
  border-right: 3px inset var(--alerte_vert_positif);
}

.border-right-3-outset-alerte_vert_positif {
  border-right: 3px outset var(--alerte_vert_positif);
}

.border-right-3-none-alerte_vert_positif {
  border-right: 3px none var(--alerte_vert_positif);
}

.border-right-3-hidden-alerte_vert_positif {
  border-right: 3px hidden var(--alerte_vert_positif);
}

.border-right-3-solid-alerte_rouge_negatif {
  border-right: 3px solid var(--alerte_rouge_negatif);
}

.border-right-3-dashed-alerte_rouge_negatif {
  border-right: 3px dashed var(--alerte_rouge_negatif);
}

.border-right-3-dotted-alerte_rouge_negatif {
  border-right: 3px dotted var(--alerte_rouge_negatif);
}

.border-right-3-double-alerte_rouge_negatif {
  border-right: 3px double var(--alerte_rouge_negatif);
}

.border-right-3-groove-alerte_rouge_negatif {
  border-right: 3px groove var(--alerte_rouge_negatif);
}

.border-right-3-ridge-alerte_rouge_negatif {
  border-right: 3px ridge var(--alerte_rouge_negatif);
}

.border-right-3-inset-alerte_rouge_negatif {
  border-right: 3px inset var(--alerte_rouge_negatif);
}

.border-right-3-outset-alerte_rouge_negatif {
  border-right: 3px outset var(--alerte_rouge_negatif);
}

.border-right-3-none-alerte_rouge_negatif {
  border-right: 3px none var(--alerte_rouge_negatif);
}

.border-right-3-hidden-alerte_rouge_negatif {
  border-right: 3px hidden var(--alerte_rouge_negatif);
}

.border-right-3-solid-alerte_bleu_information {
  border-right: 3px solid var(--alerte_bleu_information);
}

.border-right-3-dashed-alerte_bleu_information {
  border-right: 3px dashed var(--alerte_bleu_information);
}

.border-right-3-dotted-alerte_bleu_information {
  border-right: 3px dotted var(--alerte_bleu_information);
}

.border-right-3-double-alerte_bleu_information {
  border-right: 3px double var(--alerte_bleu_information);
}

.border-right-3-groove-alerte_bleu_information {
  border-right: 3px groove var(--alerte_bleu_information);
}

.border-right-3-ridge-alerte_bleu_information {
  border-right: 3px ridge var(--alerte_bleu_information);
}

.border-right-3-inset-alerte_bleu_information {
  border-right: 3px inset var(--alerte_bleu_information);
}

.border-right-3-outset-alerte_bleu_information {
  border-right: 3px outset var(--alerte_bleu_information);
}

.border-right-3-none-alerte_bleu_information {
  border-right: 3px none var(--alerte_bleu_information);
}

.border-right-3-hidden-alerte_bleu_information {
  border-right: 3px hidden var(--alerte_bleu_information);
}

.border-right-3-solid-alerte_jaune_attention {
  border-right: 3px solid var(--alerte_jaune_attention);
}

.border-right-3-dashed-alerte_jaune_attention {
  border-right: 3px dashed var(--alerte_jaune_attention);
}

.border-right-3-dotted-alerte_jaune_attention {
  border-right: 3px dotted var(--alerte_jaune_attention);
}

.border-right-3-double-alerte_jaune_attention {
  border-right: 3px double var(--alerte_jaune_attention);
}

.border-right-3-groove-alerte_jaune_attention {
  border-right: 3px groove var(--alerte_jaune_attention);
}

.border-right-3-ridge-alerte_jaune_attention {
  border-right: 3px ridge var(--alerte_jaune_attention);
}

.border-right-3-inset-alerte_jaune_attention {
  border-right: 3px inset var(--alerte_jaune_attention);
}

.border-right-3-outset-alerte_jaune_attention {
  border-right: 3px outset var(--alerte_jaune_attention);
}

.border-right-3-none-alerte_jaune_attention {
  border-right: 3px none var(--alerte_jaune_attention);
}

.border-right-3-hidden-alerte_jaune_attention {
  border-right: 3px hidden var(--alerte_jaune_attention);
}

.border-right-3-solid-blanc {
  border-right: 3px solid #fff;
}

.border-right-3-dashed-blanc {
  border-right: 3px dashed #fff;
}

.border-right-3-dotted-blanc {
  border-right: 3px dotted #fff;
}

.border-right-3-double-blanc {
  border-right: 3px double #fff;
}

.border-right-3-groove-blanc {
  border-right: 3px groove #fff;
}

.border-right-3-ridge-blanc {
  border-right: 3px ridge #fff;
}

.border-right-3-inset-blanc {
  border-right: 3px inset #fff;
}

.border-right-3-outset-blanc {
  border-right: 3px outset #fff;
}

.border-right-3-none-blanc {
  border-right: 3px #fff;
}

.border-right-3-hidden-blanc {
  border-right: 3px hidden #fff;
}

.border-right-3-solid-noir {
  border-right: 3px solid #222;
}

.border-right-3-dashed-noir {
  border-right: 3px dashed #222;
}

.border-right-3-dotted-noir {
  border-right: 3px dotted #222;
}

.border-right-3-double-noir {
  border-right: 3px double #222;
}

.border-right-3-groove-noir {
  border-right: 3px groove #222;
}

.border-right-3-ridge-noir {
  border-right: 3px ridge #222;
}

.border-right-3-inset-noir {
  border-right: 3px inset #222;
}

.border-right-3-outset-noir {
  border-right: 3px outset #222;
}

.border-right-3-none-noir {
  border-right: 3px #222;
}

.border-right-3-hidden-noir {
  border-right: 3px hidden #222;
}

.border-right-3-solid-gris_light {
  border-right: 3px solid var(--gris_light);
}

.border-right-3-dashed-gris_light {
  border-right: 3px dashed var(--gris_light);
}

.border-right-3-dotted-gris_light {
  border-right: 3px dotted var(--gris_light);
}

.border-right-3-double-gris_light {
  border-right: 3px double var(--gris_light);
}

.border-right-3-groove-gris_light {
  border-right: 3px groove var(--gris_light);
}

.border-right-3-ridge-gris_light {
  border-right: 3px ridge var(--gris_light);
}

.border-right-3-inset-gris_light {
  border-right: 3px inset var(--gris_light);
}

.border-right-3-outset-gris_light {
  border-right: 3px outset var(--gris_light);
}

.border-right-3-none-gris_light {
  border-right: 3px none var(--gris_light);
}

.border-right-3-hidden-gris_light {
  border-right: 3px hidden var(--gris_light);
}

.border-right-3-solid-gris_dark {
  border-right: 3px solid var(--gris_dark);
}

.border-right-3-dashed-gris_dark {
  border-right: 3px dashed var(--gris_dark);
}

.border-right-3-dotted-gris_dark {
  border-right: 3px dotted var(--gris_dark);
}

.border-right-3-double-gris_dark {
  border-right: 3px double var(--gris_dark);
}

.border-right-3-groove-gris_dark {
  border-right: 3px groove var(--gris_dark);
}

.border-right-3-ridge-gris_dark {
  border-right: 3px ridge var(--gris_dark);
}

.border-right-3-inset-gris_dark {
  border-right: 3px inset var(--gris_dark);
}

.border-right-3-outset-gris_dark {
  border-right: 3px outset var(--gris_dark);
}

.border-right-3-none-gris_dark {
  border-right: 3px none var(--gris_dark);
}

.border-right-3-hidden-gris_dark {
  border-right: 3px hidden var(--gris_dark);
}

.border-right-3-solid-gris_background {
  border-right: 3px solid var(--gris_background);
}

.border-right-3-dashed-gris_background {
  border-right: 3px dashed var(--gris_background);
}

.border-right-3-dotted-gris_background {
  border-right: 3px dotted var(--gris_background);
}

.border-right-3-double-gris_background {
  border-right: 3px double var(--gris_background);
}

.border-right-3-groove-gris_background {
  border-right: 3px groove var(--gris_background);
}

.border-right-3-ridge-gris_background {
  border-right: 3px ridge var(--gris_background);
}

.border-right-3-inset-gris_background {
  border-right: 3px inset var(--gris_background);
}

.border-right-3-outset-gris_background {
  border-right: 3px outset var(--gris_background);
}

.border-right-3-none-gris_background {
  border-right: 3px none var(--gris_background);
}

.border-right-3-hidden-gris_background {
  border-right: 3px hidden var(--gris_background);
}

.border-left-3-solid-corail_mh_brand {
  border-left: 3px solid var(--corail_mh_brand);
}

.border-left-3-dashed-corail_mh_brand {
  border-left: 3px dashed var(--corail_mh_brand);
}

.border-left-3-dotted-corail_mh_brand {
  border-left: 3px dotted var(--corail_mh_brand);
}

.border-left-3-double-corail_mh_brand {
  border-left: 3px double var(--corail_mh_brand);
}

.border-left-3-groove-corail_mh_brand {
  border-left: 3px groove var(--corail_mh_brand);
}

.border-left-3-ridge-corail_mh_brand {
  border-left: 3px ridge var(--corail_mh_brand);
}

.border-left-3-inset-corail_mh_brand {
  border-left: 3px inset var(--corail_mh_brand);
}

.border-left-3-outset-corail_mh_brand {
  border-left: 3px outset var(--corail_mh_brand);
}

.border-left-3-none-corail_mh_brand {
  border-left: 3px none var(--corail_mh_brand);
}

.border-left-3-hidden-corail_mh_brand {
  border-left: 3px hidden var(--corail_mh_brand);
}

.border-left-3-solid-corail_mh_dark {
  border-left: 3px solid var(--corail_mh_dark);
}

.border-left-3-dashed-corail_mh_dark {
  border-left: 3px dashed var(--corail_mh_dark);
}

.border-left-3-dotted-corail_mh_dark {
  border-left: 3px dotted var(--corail_mh_dark);
}

.border-left-3-double-corail_mh_dark {
  border-left: 3px double var(--corail_mh_dark);
}

.border-left-3-groove-corail_mh_dark {
  border-left: 3px groove var(--corail_mh_dark);
}

.border-left-3-ridge-corail_mh_dark {
  border-left: 3px ridge var(--corail_mh_dark);
}

.border-left-3-inset-corail_mh_dark {
  border-left: 3px inset var(--corail_mh_dark);
}

.border-left-3-outset-corail_mh_dark {
  border-left: 3px outset var(--corail_mh_dark);
}

.border-left-3-none-corail_mh_dark {
  border-left: 3px none var(--corail_mh_dark);
}

.border-left-3-hidden-corail_mh_dark {
  border-left: 3px hidden var(--corail_mh_dark);
}

.border-left-3-solid-corail_mh_light {
  border-left: 3px solid var(--corail_mh_light);
}

.border-left-3-dashed-corail_mh_light {
  border-left: 3px dashed var(--corail_mh_light);
}

.border-left-3-dotted-corail_mh_light {
  border-left: 3px dotted var(--corail_mh_light);
}

.border-left-3-double-corail_mh_light {
  border-left: 3px double var(--corail_mh_light);
}

.border-left-3-groove-corail_mh_light {
  border-left: 3px groove var(--corail_mh_light);
}

.border-left-3-ridge-corail_mh_light {
  border-left: 3px ridge var(--corail_mh_light);
}

.border-left-3-inset-corail_mh_light {
  border-left: 3px inset var(--corail_mh_light);
}

.border-left-3-outset-corail_mh_light {
  border-left: 3px outset var(--corail_mh_light);
}

.border-left-3-none-corail_mh_light {
  border-left: 3px none var(--corail_mh_light);
}

.border-left-3-hidden-corail_mh_light {
  border-left: 3px hidden var(--corail_mh_light);
}

.border-left-3-solid-gris_sable_light {
  border-left: 3px solid var(--gris_sable_light);
}

.border-left-3-dashed-gris_sable_light {
  border-left: 3px dashed var(--gris_sable_light);
}

.border-left-3-dotted-gris_sable_light {
  border-left: 3px dotted var(--gris_sable_light);
}

.border-left-3-double-gris_sable_light {
  border-left: 3px double var(--gris_sable_light);
}

.border-left-3-groove-gris_sable_light {
  border-left: 3px groove var(--gris_sable_light);
}

.border-left-3-ridge-gris_sable_light {
  border-left: 3px ridge var(--gris_sable_light);
}

.border-left-3-inset-gris_sable_light {
  border-left: 3px inset var(--gris_sable_light);
}

.border-left-3-outset-gris_sable_light {
  border-left: 3px outset var(--gris_sable_light);
}

.border-left-3-none-gris_sable_light {
  border-left: 3px none var(--gris_sable_light);
}

.border-left-3-hidden-gris_sable_light {
  border-left: 3px hidden var(--gris_sable_light);
}

.border-left-3-solid-gris_sable_dark {
  border-left: 3px solid var(--gris_sable_dark);
}

.border-left-3-dashed-gris_sable_dark {
  border-left: 3px dashed var(--gris_sable_dark);
}

.border-left-3-dotted-gris_sable_dark {
  border-left: 3px dotted var(--gris_sable_dark);
}

.border-left-3-double-gris_sable_dark {
  border-left: 3px double var(--gris_sable_dark);
}

.border-left-3-groove-gris_sable_dark {
  border-left: 3px groove var(--gris_sable_dark);
}

.border-left-3-ridge-gris_sable_dark {
  border-left: 3px ridge var(--gris_sable_dark);
}

.border-left-3-inset-gris_sable_dark {
  border-left: 3px inset var(--gris_sable_dark);
}

.border-left-3-outset-gris_sable_dark {
  border-left: 3px outset var(--gris_sable_dark);
}

.border-left-3-none-gris_sable_dark {
  border-left: 3px none var(--gris_sable_dark);
}

.border-left-3-hidden-gris_sable_dark {
  border-left: 3px hidden var(--gris_sable_dark);
}

.border-left-3-solid-jaune_ivoire_light {
  border-left: 3px solid var(--jaune_ivoire_light);
}

.border-left-3-dashed-jaune_ivoire_light {
  border-left: 3px dashed var(--jaune_ivoire_light);
}

.border-left-3-dotted-jaune_ivoire_light {
  border-left: 3px dotted var(--jaune_ivoire_light);
}

.border-left-3-double-jaune_ivoire_light {
  border-left: 3px double var(--jaune_ivoire_light);
}

.border-left-3-groove-jaune_ivoire_light {
  border-left: 3px groove var(--jaune_ivoire_light);
}

.border-left-3-ridge-jaune_ivoire_light {
  border-left: 3px ridge var(--jaune_ivoire_light);
}

.border-left-3-inset-jaune_ivoire_light {
  border-left: 3px inset var(--jaune_ivoire_light);
}

.border-left-3-outset-jaune_ivoire_light {
  border-left: 3px outset var(--jaune_ivoire_light);
}

.border-left-3-none-jaune_ivoire_light {
  border-left: 3px none var(--jaune_ivoire_light);
}

.border-left-3-hidden-jaune_ivoire_light {
  border-left: 3px hidden var(--jaune_ivoire_light);
}

.border-left-3-solid-jaune_ivoire_dark {
  border-left: 3px solid var(--jaune_ivoire_dark);
}

.border-left-3-dashed-jaune_ivoire_dark {
  border-left: 3px dashed var(--jaune_ivoire_dark);
}

.border-left-3-dotted-jaune_ivoire_dark {
  border-left: 3px dotted var(--jaune_ivoire_dark);
}

.border-left-3-double-jaune_ivoire_dark {
  border-left: 3px double var(--jaune_ivoire_dark);
}

.border-left-3-groove-jaune_ivoire_dark {
  border-left: 3px groove var(--jaune_ivoire_dark);
}

.border-left-3-ridge-jaune_ivoire_dark {
  border-left: 3px ridge var(--jaune_ivoire_dark);
}

.border-left-3-inset-jaune_ivoire_dark {
  border-left: 3px inset var(--jaune_ivoire_dark);
}

.border-left-3-outset-jaune_ivoire_dark {
  border-left: 3px outset var(--jaune_ivoire_dark);
}

.border-left-3-none-jaune_ivoire_dark {
  border-left: 3px none var(--jaune_ivoire_dark);
}

.border-left-3-hidden-jaune_ivoire_dark {
  border-left: 3px hidden var(--jaune_ivoire_dark);
}

.border-left-3-solid-jaune_light {
  border-left: 3px solid var(--jaune_light);
}

.border-left-3-dashed-jaune_light {
  border-left: 3px dashed var(--jaune_light);
}

.border-left-3-dotted-jaune_light {
  border-left: 3px dotted var(--jaune_light);
}

.border-left-3-double-jaune_light {
  border-left: 3px double var(--jaune_light);
}

.border-left-3-groove-jaune_light {
  border-left: 3px groove var(--jaune_light);
}

.border-left-3-ridge-jaune_light {
  border-left: 3px ridge var(--jaune_light);
}

.border-left-3-inset-jaune_light {
  border-left: 3px inset var(--jaune_light);
}

.border-left-3-outset-jaune_light {
  border-left: 3px outset var(--jaune_light);
}

.border-left-3-none-jaune_light {
  border-left: 3px none var(--jaune_light);
}

.border-left-3-hidden-jaune_light {
  border-left: 3px hidden var(--jaune_light);
}

.border-left-3-solid-jaune_dark {
  border-left: 3px solid var(--jaune_dark);
}

.border-left-3-dashed-jaune_dark {
  border-left: 3px dashed var(--jaune_dark);
}

.border-left-3-dotted-jaune_dark {
  border-left: 3px dotted var(--jaune_dark);
}

.border-left-3-double-jaune_dark {
  border-left: 3px double var(--jaune_dark);
}

.border-left-3-groove-jaune_dark {
  border-left: 3px groove var(--jaune_dark);
}

.border-left-3-ridge-jaune_dark {
  border-left: 3px ridge var(--jaune_dark);
}

.border-left-3-inset-jaune_dark {
  border-left: 3px inset var(--jaune_dark);
}

.border-left-3-outset-jaune_dark {
  border-left: 3px outset var(--jaune_dark);
}

.border-left-3-none-jaune_dark {
  border-left: 3px none var(--jaune_dark);
}

.border-left-3-hidden-jaune_dark {
  border-left: 3px hidden var(--jaune_dark);
}

.border-left-3-solid-orange_normal {
  border-left: 3px solid var(--orange_normal);
}

.border-left-3-dashed-orange_normal {
  border-left: 3px dashed var(--orange_normal);
}

.border-left-3-dotted-orange_normal {
  border-left: 3px dotted var(--orange_normal);
}

.border-left-3-double-orange_normal {
  border-left: 3px double var(--orange_normal);
}

.border-left-3-groove-orange_normal {
  border-left: 3px groove var(--orange_normal);
}

.border-left-3-ridge-orange_normal {
  border-left: 3px ridge var(--orange_normal);
}

.border-left-3-inset-orange_normal {
  border-left: 3px inset var(--orange_normal);
}

.border-left-3-outset-orange_normal {
  border-left: 3px outset var(--orange_normal);
}

.border-left-3-none-orange_normal {
  border-left: 3px none var(--orange_normal);
}

.border-left-3-hidden-orange_normal {
  border-left: 3px hidden var(--orange_normal);
}

.border-left-3-solid-orange_dark {
  border-left: 3px solid var(--orange_dark);
}

.border-left-3-dashed-orange_dark {
  border-left: 3px dashed var(--orange_dark);
}

.border-left-3-dotted-orange_dark {
  border-left: 3px dotted var(--orange_dark);
}

.border-left-3-double-orange_dark {
  border-left: 3px double var(--orange_dark);
}

.border-left-3-groove-orange_dark {
  border-left: 3px groove var(--orange_dark);
}

.border-left-3-ridge-orange_dark {
  border-left: 3px ridge var(--orange_dark);
}

.border-left-3-inset-orange_dark {
  border-left: 3px inset var(--orange_dark);
}

.border-left-3-outset-orange_dark {
  border-left: 3px outset var(--orange_dark);
}

.border-left-3-none-orange_dark {
  border-left: 3px none var(--orange_dark);
}

.border-left-3-hidden-orange_dark {
  border-left: 3px hidden var(--orange_dark);
}

.border-left-3-solid-vert_light {
  border-left: 3px solid var(--vert_light);
}

.border-left-3-dashed-vert_light {
  border-left: 3px dashed var(--vert_light);
}

.border-left-3-dotted-vert_light {
  border-left: 3px dotted var(--vert_light);
}

.border-left-3-double-vert_light {
  border-left: 3px double var(--vert_light);
}

.border-left-3-groove-vert_light {
  border-left: 3px groove var(--vert_light);
}

.border-left-3-ridge-vert_light {
  border-left: 3px ridge var(--vert_light);
}

.border-left-3-inset-vert_light {
  border-left: 3px inset var(--vert_light);
}

.border-left-3-outset-vert_light {
  border-left: 3px outset var(--vert_light);
}

.border-left-3-none-vert_light {
  border-left: 3px none var(--vert_light);
}

.border-left-3-hidden-vert_light {
  border-left: 3px hidden var(--vert_light);
}

.border-left-3-solid-vert_dark {
  border-left: 3px solid var(--vert_dark);
}

.border-left-3-dashed-vert_dark {
  border-left: 3px dashed var(--vert_dark);
}

.border-left-3-dotted-vert_dark {
  border-left: 3px dotted var(--vert_dark);
}

.border-left-3-double-vert_dark {
  border-left: 3px double var(--vert_dark);
}

.border-left-3-groove-vert_dark {
  border-left: 3px groove var(--vert_dark);
}

.border-left-3-ridge-vert_dark {
  border-left: 3px ridge var(--vert_dark);
}

.border-left-3-inset-vert_dark {
  border-left: 3px inset var(--vert_dark);
}

.border-left-3-outset-vert_dark {
  border-left: 3px outset var(--vert_dark);
}

.border-left-3-none-vert_dark {
  border-left: 3px none var(--vert_dark);
}

.border-left-3-hidden-vert_dark {
  border-left: 3px hidden var(--vert_dark);
}

.border-left-3-solid-bleu_turquoise_light {
  border-left: 3px solid var(--bleu_turquoise_light);
}

.border-left-3-dashed-bleu_turquoise_light {
  border-left: 3px dashed var(--bleu_turquoise_light);
}

.border-left-3-dotted-bleu_turquoise_light {
  border-left: 3px dotted var(--bleu_turquoise_light);
}

.border-left-3-double-bleu_turquoise_light {
  border-left: 3px double var(--bleu_turquoise_light);
}

.border-left-3-groove-bleu_turquoise_light {
  border-left: 3px groove var(--bleu_turquoise_light);
}

.border-left-3-ridge-bleu_turquoise_light {
  border-left: 3px ridge var(--bleu_turquoise_light);
}

.border-left-3-inset-bleu_turquoise_light {
  border-left: 3px inset var(--bleu_turquoise_light);
}

.border-left-3-outset-bleu_turquoise_light {
  border-left: 3px outset var(--bleu_turquoise_light);
}

.border-left-3-none-bleu_turquoise_light {
  border-left: 3px none var(--bleu_turquoise_light);
}

.border-left-3-hidden-bleu_turquoise_light {
  border-left: 3px hidden var(--bleu_turquoise_light);
}

.border-left-3-solid-bleu_turquoise_middle {
  border-left: 3px solid var(--bleu_turquoise_middle);
}

.border-left-3-dashed-bleu_turquoise_middle {
  border-left: 3px dashed var(--bleu_turquoise_middle);
}

.border-left-3-dotted-bleu_turquoise_middle {
  border-left: 3px dotted var(--bleu_turquoise_middle);
}

.border-left-3-double-bleu_turquoise_middle {
  border-left: 3px double var(--bleu_turquoise_middle);
}

.border-left-3-groove-bleu_turquoise_middle {
  border-left: 3px groove var(--bleu_turquoise_middle);
}

.border-left-3-ridge-bleu_turquoise_middle {
  border-left: 3px ridge var(--bleu_turquoise_middle);
}

.border-left-3-inset-bleu_turquoise_middle {
  border-left: 3px inset var(--bleu_turquoise_middle);
}

.border-left-3-outset-bleu_turquoise_middle {
  border-left: 3px outset var(--bleu_turquoise_middle);
}

.border-left-3-none-bleu_turquoise_middle {
  border-left: 3px none var(--bleu_turquoise_middle);
}

.border-left-3-hidden-bleu_turquoise_middle {
  border-left: 3px hidden var(--bleu_turquoise_middle);
}

.border-left-3-solid-bleu_turquoise_dark {
  border-left: 3px solid var(--bleu_turquoise_dark);
}

.border-left-3-dashed-bleu_turquoise_dark {
  border-left: 3px dashed var(--bleu_turquoise_dark);
}

.border-left-3-dotted-bleu_turquoise_dark {
  border-left: 3px dotted var(--bleu_turquoise_dark);
}

.border-left-3-double-bleu_turquoise_dark {
  border-left: 3px double var(--bleu_turquoise_dark);
}

.border-left-3-groove-bleu_turquoise_dark {
  border-left: 3px groove var(--bleu_turquoise_dark);
}

.border-left-3-ridge-bleu_turquoise_dark {
  border-left: 3px ridge var(--bleu_turquoise_dark);
}

.border-left-3-inset-bleu_turquoise_dark {
  border-left: 3px inset var(--bleu_turquoise_dark);
}

.border-left-3-outset-bleu_turquoise_dark {
  border-left: 3px outset var(--bleu_turquoise_dark);
}

.border-left-3-none-bleu_turquoise_dark {
  border-left: 3px none var(--bleu_turquoise_dark);
}

.border-left-3-hidden-bleu_turquoise_dark {
  border-left: 3px hidden var(--bleu_turquoise_dark);
}

.border-left-3-solid-bleu_light {
  border-left: 3px solid var(--bleu_light);
}

.border-left-3-dashed-bleu_light {
  border-left: 3px dashed var(--bleu_light);
}

.border-left-3-dotted-bleu_light {
  border-left: 3px dotted var(--bleu_light);
}

.border-left-3-double-bleu_light {
  border-left: 3px double var(--bleu_light);
}

.border-left-3-groove-bleu_light {
  border-left: 3px groove var(--bleu_light);
}

.border-left-3-ridge-bleu_light {
  border-left: 3px ridge var(--bleu_light);
}

.border-left-3-inset-bleu_light {
  border-left: 3px inset var(--bleu_light);
}

.border-left-3-outset-bleu_light {
  border-left: 3px outset var(--bleu_light);
}

.border-left-3-none-bleu_light {
  border-left: 3px none var(--bleu_light);
}

.border-left-3-hidden-bleu_light {
  border-left: 3px hidden var(--bleu_light);
}

.border-left-3-solid-bleu_dark {
  border-left: 3px solid var(--bleu_dark);
}

.border-left-3-dashed-bleu_dark {
  border-left: 3px dashed var(--bleu_dark);
}

.border-left-3-dotted-bleu_dark {
  border-left: 3px dotted var(--bleu_dark);
}

.border-left-3-double-bleu_dark {
  border-left: 3px double var(--bleu_dark);
}

.border-left-3-groove-bleu_dark {
  border-left: 3px groove var(--bleu_dark);
}

.border-left-3-ridge-bleu_dark {
  border-left: 3px ridge var(--bleu_dark);
}

.border-left-3-inset-bleu_dark {
  border-left: 3px inset var(--bleu_dark);
}

.border-left-3-outset-bleu_dark {
  border-left: 3px outset var(--bleu_dark);
}

.border-left-3-none-bleu_dark {
  border-left: 3px none var(--bleu_dark);
}

.border-left-3-hidden-bleu_dark {
  border-left: 3px hidden var(--bleu_dark);
}

.border-left-3-solid-bleu_klein_light {
  border-left: 3px solid var(--bleu_klein_light);
}

.border-left-3-dashed-bleu_klein_light {
  border-left: 3px dashed var(--bleu_klein_light);
}

.border-left-3-dotted-bleu_klein_light {
  border-left: 3px dotted var(--bleu_klein_light);
}

.border-left-3-double-bleu_klein_light {
  border-left: 3px double var(--bleu_klein_light);
}

.border-left-3-groove-bleu_klein_light {
  border-left: 3px groove var(--bleu_klein_light);
}

.border-left-3-ridge-bleu_klein_light {
  border-left: 3px ridge var(--bleu_klein_light);
}

.border-left-3-inset-bleu_klein_light {
  border-left: 3px inset var(--bleu_klein_light);
}

.border-left-3-outset-bleu_klein_light {
  border-left: 3px outset var(--bleu_klein_light);
}

.border-left-3-none-bleu_klein_light {
  border-left: 3px none var(--bleu_klein_light);
}

.border-left-3-hidden-bleu_klein_light {
  border-left: 3px hidden var(--bleu_klein_light);
}

.border-left-3-solid-bleu_klein_dark {
  border-left: 3px solid var(--bleu_klein_dark);
}

.border-left-3-dashed-bleu_klein_dark {
  border-left: 3px dashed var(--bleu_klein_dark);
}

.border-left-3-dotted-bleu_klein_dark {
  border-left: 3px dotted var(--bleu_klein_dark);
}

.border-left-3-double-bleu_klein_dark {
  border-left: 3px double var(--bleu_klein_dark);
}

.border-left-3-groove-bleu_klein_dark {
  border-left: 3px groove var(--bleu_klein_dark);
}

.border-left-3-ridge-bleu_klein_dark {
  border-left: 3px ridge var(--bleu_klein_dark);
}

.border-left-3-inset-bleu_klein_dark {
  border-left: 3px inset var(--bleu_klein_dark);
}

.border-left-3-outset-bleu_klein_dark {
  border-left: 3px outset var(--bleu_klein_dark);
}

.border-left-3-none-bleu_klein_dark {
  border-left: 3px none var(--bleu_klein_dark);
}

.border-left-3-hidden-bleu_klein_dark {
  border-left: 3px hidden var(--bleu_klein_dark);
}

.border-left-3-solid-violet_light {
  border-left: 3px solid var(--violet_light);
}

.border-left-3-dashed-violet_light {
  border-left: 3px dashed var(--violet_light);
}

.border-left-3-dotted-violet_light {
  border-left: 3px dotted var(--violet_light);
}

.border-left-3-double-violet_light {
  border-left: 3px double var(--violet_light);
}

.border-left-3-groove-violet_light {
  border-left: 3px groove var(--violet_light);
}

.border-left-3-ridge-violet_light {
  border-left: 3px ridge var(--violet_light);
}

.border-left-3-inset-violet_light {
  border-left: 3px inset var(--violet_light);
}

.border-left-3-outset-violet_light {
  border-left: 3px outset var(--violet_light);
}

.border-left-3-none-violet_light {
  border-left: 3px none var(--violet_light);
}

.border-left-3-hidden-violet_light {
  border-left: 3px hidden var(--violet_light);
}

.border-left-3-solid-violet_dark {
  border-left: 3px solid var(--violet_dark);
}

.border-left-3-dashed-violet_dark {
  border-left: 3px dashed var(--violet_dark);
}

.border-left-3-dotted-violet_dark {
  border-left: 3px dotted var(--violet_dark);
}

.border-left-3-double-violet_dark {
  border-left: 3px double var(--violet_dark);
}

.border-left-3-groove-violet_dark {
  border-left: 3px groove var(--violet_dark);
}

.border-left-3-ridge-violet_dark {
  border-left: 3px ridge var(--violet_dark);
}

.border-left-3-inset-violet_dark {
  border-left: 3px inset var(--violet_dark);
}

.border-left-3-outset-violet_dark {
  border-left: 3px outset var(--violet_dark);
}

.border-left-3-none-violet_dark {
  border-left: 3px none var(--violet_dark);
}

.border-left-3-hidden-violet_dark {
  border-left: 3px hidden var(--violet_dark);
}

.border-left-3-solid-mauve {
  border-left: 3px solid var(--mauve);
}

.border-left-3-dashed-mauve {
  border-left: 3px dashed var(--mauve);
}

.border-left-3-dotted-mauve {
  border-left: 3px dotted var(--mauve);
}

.border-left-3-double-mauve {
  border-left: 3px double var(--mauve);
}

.border-left-3-groove-mauve {
  border-left: 3px groove var(--mauve);
}

.border-left-3-ridge-mauve {
  border-left: 3px ridge var(--mauve);
}

.border-left-3-inset-mauve {
  border-left: 3px inset var(--mauve);
}

.border-left-3-outset-mauve {
  border-left: 3px outset var(--mauve);
}

.border-left-3-none-mauve {
  border-left: 3px none var(--mauve);
}

.border-left-3-hidden-mauve {
  border-left: 3px hidden var(--mauve);
}

.border-left-3-solid-rose_light {
  border-left: 3px solid var(--rose_light);
}

.border-left-3-dashed-rose_light {
  border-left: 3px dashed var(--rose_light);
}

.border-left-3-dotted-rose_light {
  border-left: 3px dotted var(--rose_light);
}

.border-left-3-double-rose_light {
  border-left: 3px double var(--rose_light);
}

.border-left-3-groove-rose_light {
  border-left: 3px groove var(--rose_light);
}

.border-left-3-ridge-rose_light {
  border-left: 3px ridge var(--rose_light);
}

.border-left-3-inset-rose_light {
  border-left: 3px inset var(--rose_light);
}

.border-left-3-outset-rose_light {
  border-left: 3px outset var(--rose_light);
}

.border-left-3-none-rose_light {
  border-left: 3px none var(--rose_light);
}

.border-left-3-hidden-rose_light {
  border-left: 3px hidden var(--rose_light);
}

.border-left-3-solid-rose_dark {
  border-left: 3px solid var(--rose_dark);
}

.border-left-3-dashed-rose_dark {
  border-left: 3px dashed var(--rose_dark);
}

.border-left-3-dotted-rose_dark {
  border-left: 3px dotted var(--rose_dark);
}

.border-left-3-double-rose_dark {
  border-left: 3px double var(--rose_dark);
}

.border-left-3-groove-rose_dark {
  border-left: 3px groove var(--rose_dark);
}

.border-left-3-ridge-rose_dark {
  border-left: 3px ridge var(--rose_dark);
}

.border-left-3-inset-rose_dark {
  border-left: 3px inset var(--rose_dark);
}

.border-left-3-outset-rose_dark {
  border-left: 3px outset var(--rose_dark);
}

.border-left-3-none-rose_dark {
  border-left: 3px none var(--rose_dark);
}

.border-left-3-hidden-rose_dark {
  border-left: 3px hidden var(--rose_dark);
}

.border-left-3-solid-rose_fushia {
  border-left: 3px solid var(--rose_fushia);
}

.border-left-3-dashed-rose_fushia {
  border-left: 3px dashed var(--rose_fushia);
}

.border-left-3-dotted-rose_fushia {
  border-left: 3px dotted var(--rose_fushia);
}

.border-left-3-double-rose_fushia {
  border-left: 3px double var(--rose_fushia);
}

.border-left-3-groove-rose_fushia {
  border-left: 3px groove var(--rose_fushia);
}

.border-left-3-ridge-rose_fushia {
  border-left: 3px ridge var(--rose_fushia);
}

.border-left-3-inset-rose_fushia {
  border-left: 3px inset var(--rose_fushia);
}

.border-left-3-outset-rose_fushia {
  border-left: 3px outset var(--rose_fushia);
}

.border-left-3-none-rose_fushia {
  border-left: 3px none var(--rose_fushia);
}

.border-left-3-hidden-rose_fushia {
  border-left: 3px hidden var(--rose_fushia);
}

.border-left-3-solid-rouge_framboise {
  border-left: 3px solid var(--rouge_framboise);
}

.border-left-3-dashed-rouge_framboise {
  border-left: 3px dashed var(--rouge_framboise);
}

.border-left-3-dotted-rouge_framboise {
  border-left: 3px dotted var(--rouge_framboise);
}

.border-left-3-double-rouge_framboise {
  border-left: 3px double var(--rouge_framboise);
}

.border-left-3-groove-rouge_framboise {
  border-left: 3px groove var(--rouge_framboise);
}

.border-left-3-ridge-rouge_framboise {
  border-left: 3px ridge var(--rouge_framboise);
}

.border-left-3-inset-rouge_framboise {
  border-left: 3px inset var(--rouge_framboise);
}

.border-left-3-outset-rouge_framboise {
  border-left: 3px outset var(--rouge_framboise);
}

.border-left-3-none-rouge_framboise {
  border-left: 3px none var(--rouge_framboise);
}

.border-left-3-hidden-rouge_framboise {
  border-left: 3px hidden var(--rouge_framboise);
}

.border-left-3-solid-rose_chair_light {
  border-left: 3px solid var(--rose_chair_light);
}

.border-left-3-dashed-rose_chair_light {
  border-left: 3px dashed var(--rose_chair_light);
}

.border-left-3-dotted-rose_chair_light {
  border-left: 3px dotted var(--rose_chair_light);
}

.border-left-3-double-rose_chair_light {
  border-left: 3px double var(--rose_chair_light);
}

.border-left-3-groove-rose_chair_light {
  border-left: 3px groove var(--rose_chair_light);
}

.border-left-3-ridge-rose_chair_light {
  border-left: 3px ridge var(--rose_chair_light);
}

.border-left-3-inset-rose_chair_light {
  border-left: 3px inset var(--rose_chair_light);
}

.border-left-3-outset-rose_chair_light {
  border-left: 3px outset var(--rose_chair_light);
}

.border-left-3-none-rose_chair_light {
  border-left: 3px none var(--rose_chair_light);
}

.border-left-3-hidden-rose_chair_light {
  border-left: 3px hidden var(--rose_chair_light);
}

.border-left-3-solid-rose_chair_dark {
  border-left: 3px solid var(--rose_chair_dark);
}

.border-left-3-dashed-rose_chair_dark {
  border-left: 3px dashed var(--rose_chair_dark);
}

.border-left-3-dotted-rose_chair_dark {
  border-left: 3px dotted var(--rose_chair_dark);
}

.border-left-3-double-rose_chair_dark {
  border-left: 3px double var(--rose_chair_dark);
}

.border-left-3-groove-rose_chair_dark {
  border-left: 3px groove var(--rose_chair_dark);
}

.border-left-3-ridge-rose_chair_dark {
  border-left: 3px ridge var(--rose_chair_dark);
}

.border-left-3-inset-rose_chair_dark {
  border-left: 3px inset var(--rose_chair_dark);
}

.border-left-3-outset-rose_chair_dark {
  border-left: 3px outset var(--rose_chair_dark);
}

.border-left-3-none-rose_chair_dark {
  border-left: 3px none var(--rose_chair_dark);
}

.border-left-3-hidden-rose_chair_dark {
  border-left: 3px hidden var(--rose_chair_dark);
}

.border-left-3-solid-vert_positif {
  border-left: 3px solid var(--vert_positif);
}

.border-left-3-dashed-vert_positif {
  border-left: 3px dashed var(--vert_positif);
}

.border-left-3-dotted-vert_positif {
  border-left: 3px dotted var(--vert_positif);
}

.border-left-3-double-vert_positif {
  border-left: 3px double var(--vert_positif);
}

.border-left-3-groove-vert_positif {
  border-left: 3px groove var(--vert_positif);
}

.border-left-3-ridge-vert_positif {
  border-left: 3px ridge var(--vert_positif);
}

.border-left-3-inset-vert_positif {
  border-left: 3px inset var(--vert_positif);
}

.border-left-3-outset-vert_positif {
  border-left: 3px outset var(--vert_positif);
}

.border-left-3-none-vert_positif {
  border-left: 3px none var(--vert_positif);
}

.border-left-3-hidden-vert_positif {
  border-left: 3px hidden var(--vert_positif);
}

.border-left-3-solid-rouge_negatif {
  border-left: 3px solid var(--rouge_negatif);
}

.border-left-3-dashed-rouge_negatif {
  border-left: 3px dashed var(--rouge_negatif);
}

.border-left-3-dotted-rouge_negatif {
  border-left: 3px dotted var(--rouge_negatif);
}

.border-left-3-double-rouge_negatif {
  border-left: 3px double var(--rouge_negatif);
}

.border-left-3-groove-rouge_negatif {
  border-left: 3px groove var(--rouge_negatif);
}

.border-left-3-ridge-rouge_negatif {
  border-left: 3px ridge var(--rouge_negatif);
}

.border-left-3-inset-rouge_negatif {
  border-left: 3px inset var(--rouge_negatif);
}

.border-left-3-outset-rouge_negatif {
  border-left: 3px outset var(--rouge_negatif);
}

.border-left-3-none-rouge_negatif {
  border-left: 3px none var(--rouge_negatif);
}

.border-left-3-hidden-rouge_negatif {
  border-left: 3px hidden var(--rouge_negatif);
}

.border-left-3-solid-bleu_texte {
  border-left: 3px solid var(--bleu_texte);
}

.border-left-3-dashed-bleu_texte {
  border-left: 3px dashed var(--bleu_texte);
}

.border-left-3-dotted-bleu_texte {
  border-left: 3px dotted var(--bleu_texte);
}

.border-left-3-double-bleu_texte {
  border-left: 3px double var(--bleu_texte);
}

.border-left-3-groove-bleu_texte {
  border-left: 3px groove var(--bleu_texte);
}

.border-left-3-ridge-bleu_texte {
  border-left: 3px ridge var(--bleu_texte);
}

.border-left-3-inset-bleu_texte {
  border-left: 3px inset var(--bleu_texte);
}

.border-left-3-outset-bleu_texte {
  border-left: 3px outset var(--bleu_texte);
}

.border-left-3-none-bleu_texte {
  border-left: 3px none var(--bleu_texte);
}

.border-left-3-hidden-bleu_texte {
  border-left: 3px hidden var(--bleu_texte);
}

.border-left-3-solid-alerte_vert_positif {
  border-left: 3px solid var(--alerte_vert_positif);
}

.border-left-3-dashed-alerte_vert_positif {
  border-left: 3px dashed var(--alerte_vert_positif);
}

.border-left-3-dotted-alerte_vert_positif {
  border-left: 3px dotted var(--alerte_vert_positif);
}

.border-left-3-double-alerte_vert_positif {
  border-left: 3px double var(--alerte_vert_positif);
}

.border-left-3-groove-alerte_vert_positif {
  border-left: 3px groove var(--alerte_vert_positif);
}

.border-left-3-ridge-alerte_vert_positif {
  border-left: 3px ridge var(--alerte_vert_positif);
}

.border-left-3-inset-alerte_vert_positif {
  border-left: 3px inset var(--alerte_vert_positif);
}

.border-left-3-outset-alerte_vert_positif {
  border-left: 3px outset var(--alerte_vert_positif);
}

.border-left-3-none-alerte_vert_positif {
  border-left: 3px none var(--alerte_vert_positif);
}

.border-left-3-hidden-alerte_vert_positif {
  border-left: 3px hidden var(--alerte_vert_positif);
}

.border-left-3-solid-alerte_rouge_negatif {
  border-left: 3px solid var(--alerte_rouge_negatif);
}

.border-left-3-dashed-alerte_rouge_negatif {
  border-left: 3px dashed var(--alerte_rouge_negatif);
}

.border-left-3-dotted-alerte_rouge_negatif {
  border-left: 3px dotted var(--alerte_rouge_negatif);
}

.border-left-3-double-alerte_rouge_negatif {
  border-left: 3px double var(--alerte_rouge_negatif);
}

.border-left-3-groove-alerte_rouge_negatif {
  border-left: 3px groove var(--alerte_rouge_negatif);
}

.border-left-3-ridge-alerte_rouge_negatif {
  border-left: 3px ridge var(--alerte_rouge_negatif);
}

.border-left-3-inset-alerte_rouge_negatif {
  border-left: 3px inset var(--alerte_rouge_negatif);
}

.border-left-3-outset-alerte_rouge_negatif {
  border-left: 3px outset var(--alerte_rouge_negatif);
}

.border-left-3-none-alerte_rouge_negatif {
  border-left: 3px none var(--alerte_rouge_negatif);
}

.border-left-3-hidden-alerte_rouge_negatif {
  border-left: 3px hidden var(--alerte_rouge_negatif);
}

.border-left-3-solid-alerte_bleu_information {
  border-left: 3px solid var(--alerte_bleu_information);
}

.border-left-3-dashed-alerte_bleu_information {
  border-left: 3px dashed var(--alerte_bleu_information);
}

.border-left-3-dotted-alerte_bleu_information {
  border-left: 3px dotted var(--alerte_bleu_information);
}

.border-left-3-double-alerte_bleu_information {
  border-left: 3px double var(--alerte_bleu_information);
}

.border-left-3-groove-alerte_bleu_information {
  border-left: 3px groove var(--alerte_bleu_information);
}

.border-left-3-ridge-alerte_bleu_information {
  border-left: 3px ridge var(--alerte_bleu_information);
}

.border-left-3-inset-alerte_bleu_information {
  border-left: 3px inset var(--alerte_bleu_information);
}

.border-left-3-outset-alerte_bleu_information {
  border-left: 3px outset var(--alerte_bleu_information);
}

.border-left-3-none-alerte_bleu_information {
  border-left: 3px none var(--alerte_bleu_information);
}

.border-left-3-hidden-alerte_bleu_information {
  border-left: 3px hidden var(--alerte_bleu_information);
}

.border-left-3-solid-alerte_jaune_attention {
  border-left: 3px solid var(--alerte_jaune_attention);
}

.border-left-3-dashed-alerte_jaune_attention {
  border-left: 3px dashed var(--alerte_jaune_attention);
}

.border-left-3-dotted-alerte_jaune_attention {
  border-left: 3px dotted var(--alerte_jaune_attention);
}

.border-left-3-double-alerte_jaune_attention {
  border-left: 3px double var(--alerte_jaune_attention);
}

.border-left-3-groove-alerte_jaune_attention {
  border-left: 3px groove var(--alerte_jaune_attention);
}

.border-left-3-ridge-alerte_jaune_attention {
  border-left: 3px ridge var(--alerte_jaune_attention);
}

.border-left-3-inset-alerte_jaune_attention {
  border-left: 3px inset var(--alerte_jaune_attention);
}

.border-left-3-outset-alerte_jaune_attention {
  border-left: 3px outset var(--alerte_jaune_attention);
}

.border-left-3-none-alerte_jaune_attention {
  border-left: 3px none var(--alerte_jaune_attention);
}

.border-left-3-hidden-alerte_jaune_attention {
  border-left: 3px hidden var(--alerte_jaune_attention);
}

.border-left-3-solid-blanc {
  border-left: 3px solid #fff;
}

.border-left-3-dashed-blanc {
  border-left: 3px dashed #fff;
}

.border-left-3-dotted-blanc {
  border-left: 3px dotted #fff;
}

.border-left-3-double-blanc {
  border-left: 3px double #fff;
}

.border-left-3-groove-blanc {
  border-left: 3px groove #fff;
}

.border-left-3-ridge-blanc {
  border-left: 3px ridge #fff;
}

.border-left-3-inset-blanc {
  border-left: 3px inset #fff;
}

.border-left-3-outset-blanc {
  border-left: 3px outset #fff;
}

.border-left-3-none-blanc {
  border-left: 3px #fff;
}

.border-left-3-hidden-blanc {
  border-left: 3px hidden #fff;
}

.border-left-3-solid-noir {
  border-left: 3px solid #222;
}

.border-left-3-dashed-noir {
  border-left: 3px dashed #222;
}

.border-left-3-dotted-noir {
  border-left: 3px dotted #222;
}

.border-left-3-double-noir {
  border-left: 3px double #222;
}

.border-left-3-groove-noir {
  border-left: 3px groove #222;
}

.border-left-3-ridge-noir {
  border-left: 3px ridge #222;
}

.border-left-3-inset-noir {
  border-left: 3px inset #222;
}

.border-left-3-outset-noir {
  border-left: 3px outset #222;
}

.border-left-3-none-noir {
  border-left: 3px #222;
}

.border-left-3-hidden-noir {
  border-left: 3px hidden #222;
}

.border-left-3-solid-gris_light {
  border-left: 3px solid var(--gris_light);
}

.border-left-3-dashed-gris_light {
  border-left: 3px dashed var(--gris_light);
}

.border-left-3-dotted-gris_light {
  border-left: 3px dotted var(--gris_light);
}

.border-left-3-double-gris_light {
  border-left: 3px double var(--gris_light);
}

.border-left-3-groove-gris_light {
  border-left: 3px groove var(--gris_light);
}

.border-left-3-ridge-gris_light {
  border-left: 3px ridge var(--gris_light);
}

.border-left-3-inset-gris_light {
  border-left: 3px inset var(--gris_light);
}

.border-left-3-outset-gris_light {
  border-left: 3px outset var(--gris_light);
}

.border-left-3-none-gris_light {
  border-left: 3px none var(--gris_light);
}

.border-left-3-hidden-gris_light {
  border-left: 3px hidden var(--gris_light);
}

.border-left-3-solid-gris_dark {
  border-left: 3px solid var(--gris_dark);
}

.border-left-3-dashed-gris_dark {
  border-left: 3px dashed var(--gris_dark);
}

.border-left-3-dotted-gris_dark {
  border-left: 3px dotted var(--gris_dark);
}

.border-left-3-double-gris_dark {
  border-left: 3px double var(--gris_dark);
}

.border-left-3-groove-gris_dark {
  border-left: 3px groove var(--gris_dark);
}

.border-left-3-ridge-gris_dark {
  border-left: 3px ridge var(--gris_dark);
}

.border-left-3-inset-gris_dark {
  border-left: 3px inset var(--gris_dark);
}

.border-left-3-outset-gris_dark {
  border-left: 3px outset var(--gris_dark);
}

.border-left-3-none-gris_dark {
  border-left: 3px none var(--gris_dark);
}

.border-left-3-hidden-gris_dark {
  border-left: 3px hidden var(--gris_dark);
}

.border-left-3-solid-gris_background {
  border-left: 3px solid var(--gris_background);
}

.border-left-3-dashed-gris_background {
  border-left: 3px dashed var(--gris_background);
}

.border-left-3-dotted-gris_background {
  border-left: 3px dotted var(--gris_background);
}

.border-left-3-double-gris_background {
  border-left: 3px double var(--gris_background);
}

.border-left-3-groove-gris_background {
  border-left: 3px groove var(--gris_background);
}

.border-left-3-ridge-gris_background {
  border-left: 3px ridge var(--gris_background);
}

.border-left-3-inset-gris_background {
  border-left: 3px inset var(--gris_background);
}

.border-left-3-outset-gris_background {
  border-left: 3px outset var(--gris_background);
}

.border-left-3-none-gris_background {
  border-left: 3px none var(--gris_background);
}

.border-left-3-hidden-gris_background {
  border-left: 3px hidden var(--gris_background);
}

.border-all-3-solid-corail_mh_brand {
  border: 3px solid var(--corail_mh_brand);
}

.border-all-3-dashed-corail_mh_brand {
  border: 3px dashed var(--corail_mh_brand);
}

.border-all-3-dotted-corail_mh_brand {
  border: 3px dotted var(--corail_mh_brand);
}

.border-all-3-double-corail_mh_brand {
  border: 3px double var(--corail_mh_brand);
}

.border-all-3-groove-corail_mh_brand {
  border: 3px groove var(--corail_mh_brand);
}

.border-all-3-ridge-corail_mh_brand {
  border: 3px ridge var(--corail_mh_brand);
}

.border-all-3-inset-corail_mh_brand {
  border: 3px inset var(--corail_mh_brand);
}

.border-all-3-outset-corail_mh_brand {
  border: 3px outset var(--corail_mh_brand);
}

.border-all-3-none-corail_mh_brand {
  border: 3px none var(--corail_mh_brand);
}

.border-all-3-hidden-corail_mh_brand {
  border: 3px hidden var(--corail_mh_brand);
}

.border-all-3-solid-corail_mh_dark {
  border: 3px solid var(--corail_mh_dark);
}

.border-all-3-dashed-corail_mh_dark {
  border: 3px dashed var(--corail_mh_dark);
}

.border-all-3-dotted-corail_mh_dark {
  border: 3px dotted var(--corail_mh_dark);
}

.border-all-3-double-corail_mh_dark {
  border: 3px double var(--corail_mh_dark);
}

.border-all-3-groove-corail_mh_dark {
  border: 3px groove var(--corail_mh_dark);
}

.border-all-3-ridge-corail_mh_dark {
  border: 3px ridge var(--corail_mh_dark);
}

.border-all-3-inset-corail_mh_dark {
  border: 3px inset var(--corail_mh_dark);
}

.border-all-3-outset-corail_mh_dark {
  border: 3px outset var(--corail_mh_dark);
}

.border-all-3-none-corail_mh_dark {
  border: 3px none var(--corail_mh_dark);
}

.border-all-3-hidden-corail_mh_dark {
  border: 3px hidden var(--corail_mh_dark);
}

.border-all-3-solid-corail_mh_light {
  border: 3px solid var(--corail_mh_light);
}

.border-all-3-dashed-corail_mh_light {
  border: 3px dashed var(--corail_mh_light);
}

.border-all-3-dotted-corail_mh_light {
  border: 3px dotted var(--corail_mh_light);
}

.border-all-3-double-corail_mh_light {
  border: 3px double var(--corail_mh_light);
}

.border-all-3-groove-corail_mh_light {
  border: 3px groove var(--corail_mh_light);
}

.border-all-3-ridge-corail_mh_light {
  border: 3px ridge var(--corail_mh_light);
}

.border-all-3-inset-corail_mh_light {
  border: 3px inset var(--corail_mh_light);
}

.border-all-3-outset-corail_mh_light {
  border: 3px outset var(--corail_mh_light);
}

.border-all-3-none-corail_mh_light {
  border: 3px none var(--corail_mh_light);
}

.border-all-3-hidden-corail_mh_light {
  border: 3px hidden var(--corail_mh_light);
}

.border-all-3-solid-gris_sable_light {
  border: 3px solid var(--gris_sable_light);
}

.border-all-3-dashed-gris_sable_light {
  border: 3px dashed var(--gris_sable_light);
}

.border-all-3-dotted-gris_sable_light {
  border: 3px dotted var(--gris_sable_light);
}

.border-all-3-double-gris_sable_light {
  border: 3px double var(--gris_sable_light);
}

.border-all-3-groove-gris_sable_light {
  border: 3px groove var(--gris_sable_light);
}

.border-all-3-ridge-gris_sable_light {
  border: 3px ridge var(--gris_sable_light);
}

.border-all-3-inset-gris_sable_light {
  border: 3px inset var(--gris_sable_light);
}

.border-all-3-outset-gris_sable_light {
  border: 3px outset var(--gris_sable_light);
}

.border-all-3-none-gris_sable_light {
  border: 3px none var(--gris_sable_light);
}

.border-all-3-hidden-gris_sable_light {
  border: 3px hidden var(--gris_sable_light);
}

.border-all-3-solid-gris_sable_dark {
  border: 3px solid var(--gris_sable_dark);
}

.border-all-3-dashed-gris_sable_dark {
  border: 3px dashed var(--gris_sable_dark);
}

.border-all-3-dotted-gris_sable_dark {
  border: 3px dotted var(--gris_sable_dark);
}

.border-all-3-double-gris_sable_dark {
  border: 3px double var(--gris_sable_dark);
}

.border-all-3-groove-gris_sable_dark {
  border: 3px groove var(--gris_sable_dark);
}

.border-all-3-ridge-gris_sable_dark {
  border: 3px ridge var(--gris_sable_dark);
}

.border-all-3-inset-gris_sable_dark {
  border: 3px inset var(--gris_sable_dark);
}

.border-all-3-outset-gris_sable_dark {
  border: 3px outset var(--gris_sable_dark);
}

.border-all-3-none-gris_sable_dark {
  border: 3px none var(--gris_sable_dark);
}

.border-all-3-hidden-gris_sable_dark {
  border: 3px hidden var(--gris_sable_dark);
}

.border-all-3-solid-jaune_ivoire_light {
  border: 3px solid var(--jaune_ivoire_light);
}

.border-all-3-dashed-jaune_ivoire_light {
  border: 3px dashed var(--jaune_ivoire_light);
}

.border-all-3-dotted-jaune_ivoire_light {
  border: 3px dotted var(--jaune_ivoire_light);
}

.border-all-3-double-jaune_ivoire_light {
  border: 3px double var(--jaune_ivoire_light);
}

.border-all-3-groove-jaune_ivoire_light {
  border: 3px groove var(--jaune_ivoire_light);
}

.border-all-3-ridge-jaune_ivoire_light {
  border: 3px ridge var(--jaune_ivoire_light);
}

.border-all-3-inset-jaune_ivoire_light {
  border: 3px inset var(--jaune_ivoire_light);
}

.border-all-3-outset-jaune_ivoire_light {
  border: 3px outset var(--jaune_ivoire_light);
}

.border-all-3-none-jaune_ivoire_light {
  border: 3px none var(--jaune_ivoire_light);
}

.border-all-3-hidden-jaune_ivoire_light {
  border: 3px hidden var(--jaune_ivoire_light);
}

.border-all-3-solid-jaune_ivoire_dark {
  border: 3px solid var(--jaune_ivoire_dark);
}

.border-all-3-dashed-jaune_ivoire_dark {
  border: 3px dashed var(--jaune_ivoire_dark);
}

.border-all-3-dotted-jaune_ivoire_dark {
  border: 3px dotted var(--jaune_ivoire_dark);
}

.border-all-3-double-jaune_ivoire_dark {
  border: 3px double var(--jaune_ivoire_dark);
}

.border-all-3-groove-jaune_ivoire_dark {
  border: 3px groove var(--jaune_ivoire_dark);
}

.border-all-3-ridge-jaune_ivoire_dark {
  border: 3px ridge var(--jaune_ivoire_dark);
}

.border-all-3-inset-jaune_ivoire_dark {
  border: 3px inset var(--jaune_ivoire_dark);
}

.border-all-3-outset-jaune_ivoire_dark {
  border: 3px outset var(--jaune_ivoire_dark);
}

.border-all-3-none-jaune_ivoire_dark {
  border: 3px none var(--jaune_ivoire_dark);
}

.border-all-3-hidden-jaune_ivoire_dark {
  border: 3px hidden var(--jaune_ivoire_dark);
}

.border-all-3-solid-jaune_light {
  border: 3px solid var(--jaune_light);
}

.border-all-3-dashed-jaune_light {
  border: 3px dashed var(--jaune_light);
}

.border-all-3-dotted-jaune_light {
  border: 3px dotted var(--jaune_light);
}

.border-all-3-double-jaune_light {
  border: 3px double var(--jaune_light);
}

.border-all-3-groove-jaune_light {
  border: 3px groove var(--jaune_light);
}

.border-all-3-ridge-jaune_light {
  border: 3px ridge var(--jaune_light);
}

.border-all-3-inset-jaune_light {
  border: 3px inset var(--jaune_light);
}

.border-all-3-outset-jaune_light {
  border: 3px outset var(--jaune_light);
}

.border-all-3-none-jaune_light {
  border: 3px none var(--jaune_light);
}

.border-all-3-hidden-jaune_light {
  border: 3px hidden var(--jaune_light);
}

.border-all-3-solid-jaune_dark {
  border: 3px solid var(--jaune_dark);
}

.border-all-3-dashed-jaune_dark {
  border: 3px dashed var(--jaune_dark);
}

.border-all-3-dotted-jaune_dark {
  border: 3px dotted var(--jaune_dark);
}

.border-all-3-double-jaune_dark {
  border: 3px double var(--jaune_dark);
}

.border-all-3-groove-jaune_dark {
  border: 3px groove var(--jaune_dark);
}

.border-all-3-ridge-jaune_dark {
  border: 3px ridge var(--jaune_dark);
}

.border-all-3-inset-jaune_dark {
  border: 3px inset var(--jaune_dark);
}

.border-all-3-outset-jaune_dark {
  border: 3px outset var(--jaune_dark);
}

.border-all-3-none-jaune_dark {
  border: 3px none var(--jaune_dark);
}

.border-all-3-hidden-jaune_dark {
  border: 3px hidden var(--jaune_dark);
}

.border-all-3-solid-orange_normal {
  border: 3px solid var(--orange_normal);
}

.border-all-3-dashed-orange_normal {
  border: 3px dashed var(--orange_normal);
}

.border-all-3-dotted-orange_normal {
  border: 3px dotted var(--orange_normal);
}

.border-all-3-double-orange_normal {
  border: 3px double var(--orange_normal);
}

.border-all-3-groove-orange_normal {
  border: 3px groove var(--orange_normal);
}

.border-all-3-ridge-orange_normal {
  border: 3px ridge var(--orange_normal);
}

.border-all-3-inset-orange_normal {
  border: 3px inset var(--orange_normal);
}

.border-all-3-outset-orange_normal {
  border: 3px outset var(--orange_normal);
}

.border-all-3-none-orange_normal {
  border: 3px none var(--orange_normal);
}

.border-all-3-hidden-orange_normal {
  border: 3px hidden var(--orange_normal);
}

.border-all-3-solid-orange_dark {
  border: 3px solid var(--orange_dark);
}

.border-all-3-dashed-orange_dark {
  border: 3px dashed var(--orange_dark);
}

.border-all-3-dotted-orange_dark {
  border: 3px dotted var(--orange_dark);
}

.border-all-3-double-orange_dark {
  border: 3px double var(--orange_dark);
}

.border-all-3-groove-orange_dark {
  border: 3px groove var(--orange_dark);
}

.border-all-3-ridge-orange_dark {
  border: 3px ridge var(--orange_dark);
}

.border-all-3-inset-orange_dark {
  border: 3px inset var(--orange_dark);
}

.border-all-3-outset-orange_dark {
  border: 3px outset var(--orange_dark);
}

.border-all-3-none-orange_dark {
  border: 3px none var(--orange_dark);
}

.border-all-3-hidden-orange_dark {
  border: 3px hidden var(--orange_dark);
}

.border-all-3-solid-vert_light {
  border: 3px solid var(--vert_light);
}

.border-all-3-dashed-vert_light {
  border: 3px dashed var(--vert_light);
}

.border-all-3-dotted-vert_light {
  border: 3px dotted var(--vert_light);
}

.border-all-3-double-vert_light {
  border: 3px double var(--vert_light);
}

.border-all-3-groove-vert_light {
  border: 3px groove var(--vert_light);
}

.border-all-3-ridge-vert_light {
  border: 3px ridge var(--vert_light);
}

.border-all-3-inset-vert_light {
  border: 3px inset var(--vert_light);
}

.border-all-3-outset-vert_light {
  border: 3px outset var(--vert_light);
}

.border-all-3-none-vert_light {
  border: 3px none var(--vert_light);
}

.border-all-3-hidden-vert_light {
  border: 3px hidden var(--vert_light);
}

.border-all-3-solid-vert_dark {
  border: 3px solid var(--vert_dark);
}

.border-all-3-dashed-vert_dark {
  border: 3px dashed var(--vert_dark);
}

.border-all-3-dotted-vert_dark {
  border: 3px dotted var(--vert_dark);
}

.border-all-3-double-vert_dark {
  border: 3px double var(--vert_dark);
}

.border-all-3-groove-vert_dark {
  border: 3px groove var(--vert_dark);
}

.border-all-3-ridge-vert_dark {
  border: 3px ridge var(--vert_dark);
}

.border-all-3-inset-vert_dark {
  border: 3px inset var(--vert_dark);
}

.border-all-3-outset-vert_dark {
  border: 3px outset var(--vert_dark);
}

.border-all-3-none-vert_dark {
  border: 3px none var(--vert_dark);
}

.border-all-3-hidden-vert_dark {
  border: 3px hidden var(--vert_dark);
}

.border-all-3-solid-bleu_turquoise_light {
  border: 3px solid var(--bleu_turquoise_light);
}

.border-all-3-dashed-bleu_turquoise_light {
  border: 3px dashed var(--bleu_turquoise_light);
}

.border-all-3-dotted-bleu_turquoise_light {
  border: 3px dotted var(--bleu_turquoise_light);
}

.border-all-3-double-bleu_turquoise_light {
  border: 3px double var(--bleu_turquoise_light);
}

.border-all-3-groove-bleu_turquoise_light {
  border: 3px groove var(--bleu_turquoise_light);
}

.border-all-3-ridge-bleu_turquoise_light {
  border: 3px ridge var(--bleu_turquoise_light);
}

.border-all-3-inset-bleu_turquoise_light {
  border: 3px inset var(--bleu_turquoise_light);
}

.border-all-3-outset-bleu_turquoise_light {
  border: 3px outset var(--bleu_turquoise_light);
}

.border-all-3-none-bleu_turquoise_light {
  border: 3px none var(--bleu_turquoise_light);
}

.border-all-3-hidden-bleu_turquoise_light {
  border: 3px hidden var(--bleu_turquoise_light);
}

.border-all-3-solid-bleu_turquoise_middle {
  border: 3px solid var(--bleu_turquoise_middle);
}

.border-all-3-dashed-bleu_turquoise_middle {
  border: 3px dashed var(--bleu_turquoise_middle);
}

.border-all-3-dotted-bleu_turquoise_middle {
  border: 3px dotted var(--bleu_turquoise_middle);
}

.border-all-3-double-bleu_turquoise_middle {
  border: 3px double var(--bleu_turquoise_middle);
}

.border-all-3-groove-bleu_turquoise_middle {
  border: 3px groove var(--bleu_turquoise_middle);
}

.border-all-3-ridge-bleu_turquoise_middle {
  border: 3px ridge var(--bleu_turquoise_middle);
}

.border-all-3-inset-bleu_turquoise_middle {
  border: 3px inset var(--bleu_turquoise_middle);
}

.border-all-3-outset-bleu_turquoise_middle {
  border: 3px outset var(--bleu_turquoise_middle);
}

.border-all-3-none-bleu_turquoise_middle {
  border: 3px none var(--bleu_turquoise_middle);
}

.border-all-3-hidden-bleu_turquoise_middle {
  border: 3px hidden var(--bleu_turquoise_middle);
}

.border-all-3-solid-bleu_turquoise_dark {
  border: 3px solid var(--bleu_turquoise_dark);
}

.border-all-3-dashed-bleu_turquoise_dark {
  border: 3px dashed var(--bleu_turquoise_dark);
}

.border-all-3-dotted-bleu_turquoise_dark {
  border: 3px dotted var(--bleu_turquoise_dark);
}

.border-all-3-double-bleu_turquoise_dark {
  border: 3px double var(--bleu_turquoise_dark);
}

.border-all-3-groove-bleu_turquoise_dark {
  border: 3px groove var(--bleu_turquoise_dark);
}

.border-all-3-ridge-bleu_turquoise_dark {
  border: 3px ridge var(--bleu_turquoise_dark);
}

.border-all-3-inset-bleu_turquoise_dark {
  border: 3px inset var(--bleu_turquoise_dark);
}

.border-all-3-outset-bleu_turquoise_dark {
  border: 3px outset var(--bleu_turquoise_dark);
}

.border-all-3-none-bleu_turquoise_dark {
  border: 3px none var(--bleu_turquoise_dark);
}

.border-all-3-hidden-bleu_turquoise_dark {
  border: 3px hidden var(--bleu_turquoise_dark);
}

.border-all-3-solid-bleu_light {
  border: 3px solid var(--bleu_light);
}

.border-all-3-dashed-bleu_light {
  border: 3px dashed var(--bleu_light);
}

.border-all-3-dotted-bleu_light {
  border: 3px dotted var(--bleu_light);
}

.border-all-3-double-bleu_light {
  border: 3px double var(--bleu_light);
}

.border-all-3-groove-bleu_light {
  border: 3px groove var(--bleu_light);
}

.border-all-3-ridge-bleu_light {
  border: 3px ridge var(--bleu_light);
}

.border-all-3-inset-bleu_light {
  border: 3px inset var(--bleu_light);
}

.border-all-3-outset-bleu_light {
  border: 3px outset var(--bleu_light);
}

.border-all-3-none-bleu_light {
  border: 3px none var(--bleu_light);
}

.border-all-3-hidden-bleu_light {
  border: 3px hidden var(--bleu_light);
}

.border-all-3-solid-bleu_dark {
  border: 3px solid var(--bleu_dark);
}

.border-all-3-dashed-bleu_dark {
  border: 3px dashed var(--bleu_dark);
}

.border-all-3-dotted-bleu_dark {
  border: 3px dotted var(--bleu_dark);
}

.border-all-3-double-bleu_dark {
  border: 3px double var(--bleu_dark);
}

.border-all-3-groove-bleu_dark {
  border: 3px groove var(--bleu_dark);
}

.border-all-3-ridge-bleu_dark {
  border: 3px ridge var(--bleu_dark);
}

.border-all-3-inset-bleu_dark {
  border: 3px inset var(--bleu_dark);
}

.border-all-3-outset-bleu_dark {
  border: 3px outset var(--bleu_dark);
}

.border-all-3-none-bleu_dark {
  border: 3px none var(--bleu_dark);
}

.border-all-3-hidden-bleu_dark {
  border: 3px hidden var(--bleu_dark);
}

.border-all-3-solid-bleu_klein_light {
  border: 3px solid var(--bleu_klein_light);
}

.border-all-3-dashed-bleu_klein_light {
  border: 3px dashed var(--bleu_klein_light);
}

.border-all-3-dotted-bleu_klein_light {
  border: 3px dotted var(--bleu_klein_light);
}

.border-all-3-double-bleu_klein_light {
  border: 3px double var(--bleu_klein_light);
}

.border-all-3-groove-bleu_klein_light {
  border: 3px groove var(--bleu_klein_light);
}

.border-all-3-ridge-bleu_klein_light {
  border: 3px ridge var(--bleu_klein_light);
}

.border-all-3-inset-bleu_klein_light {
  border: 3px inset var(--bleu_klein_light);
}

.border-all-3-outset-bleu_klein_light {
  border: 3px outset var(--bleu_klein_light);
}

.border-all-3-none-bleu_klein_light {
  border: 3px none var(--bleu_klein_light);
}

.border-all-3-hidden-bleu_klein_light {
  border: 3px hidden var(--bleu_klein_light);
}

.border-all-3-solid-bleu_klein_dark {
  border: 3px solid var(--bleu_klein_dark);
}

.border-all-3-dashed-bleu_klein_dark {
  border: 3px dashed var(--bleu_klein_dark);
}

.border-all-3-dotted-bleu_klein_dark {
  border: 3px dotted var(--bleu_klein_dark);
}

.border-all-3-double-bleu_klein_dark {
  border: 3px double var(--bleu_klein_dark);
}

.border-all-3-groove-bleu_klein_dark {
  border: 3px groove var(--bleu_klein_dark);
}

.border-all-3-ridge-bleu_klein_dark {
  border: 3px ridge var(--bleu_klein_dark);
}

.border-all-3-inset-bleu_klein_dark {
  border: 3px inset var(--bleu_klein_dark);
}

.border-all-3-outset-bleu_klein_dark {
  border: 3px outset var(--bleu_klein_dark);
}

.border-all-3-none-bleu_klein_dark {
  border: 3px none var(--bleu_klein_dark);
}

.border-all-3-hidden-bleu_klein_dark {
  border: 3px hidden var(--bleu_klein_dark);
}

.border-all-3-solid-violet_light {
  border: 3px solid var(--violet_light);
}

.border-all-3-dashed-violet_light {
  border: 3px dashed var(--violet_light);
}

.border-all-3-dotted-violet_light {
  border: 3px dotted var(--violet_light);
}

.border-all-3-double-violet_light {
  border: 3px double var(--violet_light);
}

.border-all-3-groove-violet_light {
  border: 3px groove var(--violet_light);
}

.border-all-3-ridge-violet_light {
  border: 3px ridge var(--violet_light);
}

.border-all-3-inset-violet_light {
  border: 3px inset var(--violet_light);
}

.border-all-3-outset-violet_light {
  border: 3px outset var(--violet_light);
}

.border-all-3-none-violet_light {
  border: 3px none var(--violet_light);
}

.border-all-3-hidden-violet_light {
  border: 3px hidden var(--violet_light);
}

.border-all-3-solid-violet_dark {
  border: 3px solid var(--violet_dark);
}

.border-all-3-dashed-violet_dark {
  border: 3px dashed var(--violet_dark);
}

.border-all-3-dotted-violet_dark {
  border: 3px dotted var(--violet_dark);
}

.border-all-3-double-violet_dark {
  border: 3px double var(--violet_dark);
}

.border-all-3-groove-violet_dark {
  border: 3px groove var(--violet_dark);
}

.border-all-3-ridge-violet_dark {
  border: 3px ridge var(--violet_dark);
}

.border-all-3-inset-violet_dark {
  border: 3px inset var(--violet_dark);
}

.border-all-3-outset-violet_dark {
  border: 3px outset var(--violet_dark);
}

.border-all-3-none-violet_dark {
  border: 3px none var(--violet_dark);
}

.border-all-3-hidden-violet_dark {
  border: 3px hidden var(--violet_dark);
}

.border-all-3-solid-mauve {
  border: 3px solid var(--mauve);
}

.border-all-3-dashed-mauve {
  border: 3px dashed var(--mauve);
}

.border-all-3-dotted-mauve {
  border: 3px dotted var(--mauve);
}

.border-all-3-double-mauve {
  border: 3px double var(--mauve);
}

.border-all-3-groove-mauve {
  border: 3px groove var(--mauve);
}

.border-all-3-ridge-mauve {
  border: 3px ridge var(--mauve);
}

.border-all-3-inset-mauve {
  border: 3px inset var(--mauve);
}

.border-all-3-outset-mauve {
  border: 3px outset var(--mauve);
}

.border-all-3-none-mauve {
  border: 3px none var(--mauve);
}

.border-all-3-hidden-mauve {
  border: 3px hidden var(--mauve);
}

.border-all-3-solid-rose_light {
  border: 3px solid var(--rose_light);
}

.border-all-3-dashed-rose_light {
  border: 3px dashed var(--rose_light);
}

.border-all-3-dotted-rose_light {
  border: 3px dotted var(--rose_light);
}

.border-all-3-double-rose_light {
  border: 3px double var(--rose_light);
}

.border-all-3-groove-rose_light {
  border: 3px groove var(--rose_light);
}

.border-all-3-ridge-rose_light {
  border: 3px ridge var(--rose_light);
}

.border-all-3-inset-rose_light {
  border: 3px inset var(--rose_light);
}

.border-all-3-outset-rose_light {
  border: 3px outset var(--rose_light);
}

.border-all-3-none-rose_light {
  border: 3px none var(--rose_light);
}

.border-all-3-hidden-rose_light {
  border: 3px hidden var(--rose_light);
}

.border-all-3-solid-rose_dark {
  border: 3px solid var(--rose_dark);
}

.border-all-3-dashed-rose_dark {
  border: 3px dashed var(--rose_dark);
}

.border-all-3-dotted-rose_dark {
  border: 3px dotted var(--rose_dark);
}

.border-all-3-double-rose_dark {
  border: 3px double var(--rose_dark);
}

.border-all-3-groove-rose_dark {
  border: 3px groove var(--rose_dark);
}

.border-all-3-ridge-rose_dark {
  border: 3px ridge var(--rose_dark);
}

.border-all-3-inset-rose_dark {
  border: 3px inset var(--rose_dark);
}

.border-all-3-outset-rose_dark {
  border: 3px outset var(--rose_dark);
}

.border-all-3-none-rose_dark {
  border: 3px none var(--rose_dark);
}

.border-all-3-hidden-rose_dark {
  border: 3px hidden var(--rose_dark);
}

.border-all-3-solid-rose_fushia {
  border: 3px solid var(--rose_fushia);
}

.border-all-3-dashed-rose_fushia {
  border: 3px dashed var(--rose_fushia);
}

.border-all-3-dotted-rose_fushia {
  border: 3px dotted var(--rose_fushia);
}

.border-all-3-double-rose_fushia {
  border: 3px double var(--rose_fushia);
}

.border-all-3-groove-rose_fushia {
  border: 3px groove var(--rose_fushia);
}

.border-all-3-ridge-rose_fushia {
  border: 3px ridge var(--rose_fushia);
}

.border-all-3-inset-rose_fushia {
  border: 3px inset var(--rose_fushia);
}

.border-all-3-outset-rose_fushia {
  border: 3px outset var(--rose_fushia);
}

.border-all-3-none-rose_fushia {
  border: 3px none var(--rose_fushia);
}

.border-all-3-hidden-rose_fushia {
  border: 3px hidden var(--rose_fushia);
}

.border-all-3-solid-rouge_framboise {
  border: 3px solid var(--rouge_framboise);
}

.border-all-3-dashed-rouge_framboise {
  border: 3px dashed var(--rouge_framboise);
}

.border-all-3-dotted-rouge_framboise {
  border: 3px dotted var(--rouge_framboise);
}

.border-all-3-double-rouge_framboise {
  border: 3px double var(--rouge_framboise);
}

.border-all-3-groove-rouge_framboise {
  border: 3px groove var(--rouge_framboise);
}

.border-all-3-ridge-rouge_framboise {
  border: 3px ridge var(--rouge_framboise);
}

.border-all-3-inset-rouge_framboise {
  border: 3px inset var(--rouge_framboise);
}

.border-all-3-outset-rouge_framboise {
  border: 3px outset var(--rouge_framboise);
}

.border-all-3-none-rouge_framboise {
  border: 3px none var(--rouge_framboise);
}

.border-all-3-hidden-rouge_framboise {
  border: 3px hidden var(--rouge_framboise);
}

.border-all-3-solid-rose_chair_light {
  border: 3px solid var(--rose_chair_light);
}

.border-all-3-dashed-rose_chair_light {
  border: 3px dashed var(--rose_chair_light);
}

.border-all-3-dotted-rose_chair_light {
  border: 3px dotted var(--rose_chair_light);
}

.border-all-3-double-rose_chair_light {
  border: 3px double var(--rose_chair_light);
}

.border-all-3-groove-rose_chair_light {
  border: 3px groove var(--rose_chair_light);
}

.border-all-3-ridge-rose_chair_light {
  border: 3px ridge var(--rose_chair_light);
}

.border-all-3-inset-rose_chair_light {
  border: 3px inset var(--rose_chair_light);
}

.border-all-3-outset-rose_chair_light {
  border: 3px outset var(--rose_chair_light);
}

.border-all-3-none-rose_chair_light {
  border: 3px none var(--rose_chair_light);
}

.border-all-3-hidden-rose_chair_light {
  border: 3px hidden var(--rose_chair_light);
}

.border-all-3-solid-rose_chair_dark {
  border: 3px solid var(--rose_chair_dark);
}

.border-all-3-dashed-rose_chair_dark {
  border: 3px dashed var(--rose_chair_dark);
}

.border-all-3-dotted-rose_chair_dark {
  border: 3px dotted var(--rose_chair_dark);
}

.border-all-3-double-rose_chair_dark {
  border: 3px double var(--rose_chair_dark);
}

.border-all-3-groove-rose_chair_dark {
  border: 3px groove var(--rose_chair_dark);
}

.border-all-3-ridge-rose_chair_dark {
  border: 3px ridge var(--rose_chair_dark);
}

.border-all-3-inset-rose_chair_dark {
  border: 3px inset var(--rose_chair_dark);
}

.border-all-3-outset-rose_chair_dark {
  border: 3px outset var(--rose_chair_dark);
}

.border-all-3-none-rose_chair_dark {
  border: 3px none var(--rose_chair_dark);
}

.border-all-3-hidden-rose_chair_dark {
  border: 3px hidden var(--rose_chair_dark);
}

.border-all-3-solid-vert_positif {
  border: 3px solid var(--vert_positif);
}

.border-all-3-dashed-vert_positif {
  border: 3px dashed var(--vert_positif);
}

.border-all-3-dotted-vert_positif {
  border: 3px dotted var(--vert_positif);
}

.border-all-3-double-vert_positif {
  border: 3px double var(--vert_positif);
}

.border-all-3-groove-vert_positif {
  border: 3px groove var(--vert_positif);
}

.border-all-3-ridge-vert_positif {
  border: 3px ridge var(--vert_positif);
}

.border-all-3-inset-vert_positif {
  border: 3px inset var(--vert_positif);
}

.border-all-3-outset-vert_positif {
  border: 3px outset var(--vert_positif);
}

.border-all-3-none-vert_positif {
  border: 3px none var(--vert_positif);
}

.border-all-3-hidden-vert_positif {
  border: 3px hidden var(--vert_positif);
}

.border-all-3-solid-rouge_negatif {
  border: 3px solid var(--rouge_negatif);
}

.border-all-3-dashed-rouge_negatif {
  border: 3px dashed var(--rouge_negatif);
}

.border-all-3-dotted-rouge_negatif {
  border: 3px dotted var(--rouge_negatif);
}

.border-all-3-double-rouge_negatif {
  border: 3px double var(--rouge_negatif);
}

.border-all-3-groove-rouge_negatif {
  border: 3px groove var(--rouge_negatif);
}

.border-all-3-ridge-rouge_negatif {
  border: 3px ridge var(--rouge_negatif);
}

.border-all-3-inset-rouge_negatif {
  border: 3px inset var(--rouge_negatif);
}

.border-all-3-outset-rouge_negatif {
  border: 3px outset var(--rouge_negatif);
}

.border-all-3-none-rouge_negatif {
  border: 3px none var(--rouge_negatif);
}

.border-all-3-hidden-rouge_negatif {
  border: 3px hidden var(--rouge_negatif);
}

.border-all-3-solid-bleu_texte {
  border: 3px solid var(--bleu_texte);
}

.border-all-3-dashed-bleu_texte {
  border: 3px dashed var(--bleu_texte);
}

.border-all-3-dotted-bleu_texte {
  border: 3px dotted var(--bleu_texte);
}

.border-all-3-double-bleu_texte {
  border: 3px double var(--bleu_texte);
}

.border-all-3-groove-bleu_texte {
  border: 3px groove var(--bleu_texte);
}

.border-all-3-ridge-bleu_texte {
  border: 3px ridge var(--bleu_texte);
}

.border-all-3-inset-bleu_texte {
  border: 3px inset var(--bleu_texte);
}

.border-all-3-outset-bleu_texte {
  border: 3px outset var(--bleu_texte);
}

.border-all-3-none-bleu_texte {
  border: 3px none var(--bleu_texte);
}

.border-all-3-hidden-bleu_texte {
  border: 3px hidden var(--bleu_texte);
}

.border-all-3-solid-alerte_vert_positif {
  border: 3px solid var(--alerte_vert_positif);
}

.border-all-3-dashed-alerte_vert_positif {
  border: 3px dashed var(--alerte_vert_positif);
}

.border-all-3-dotted-alerte_vert_positif {
  border: 3px dotted var(--alerte_vert_positif);
}

.border-all-3-double-alerte_vert_positif {
  border: 3px double var(--alerte_vert_positif);
}

.border-all-3-groove-alerte_vert_positif {
  border: 3px groove var(--alerte_vert_positif);
}

.border-all-3-ridge-alerte_vert_positif {
  border: 3px ridge var(--alerte_vert_positif);
}

.border-all-3-inset-alerte_vert_positif {
  border: 3px inset var(--alerte_vert_positif);
}

.border-all-3-outset-alerte_vert_positif {
  border: 3px outset var(--alerte_vert_positif);
}

.border-all-3-none-alerte_vert_positif {
  border: 3px none var(--alerte_vert_positif);
}

.border-all-3-hidden-alerte_vert_positif {
  border: 3px hidden var(--alerte_vert_positif);
}

.border-all-3-solid-alerte_rouge_negatif {
  border: 3px solid var(--alerte_rouge_negatif);
}

.border-all-3-dashed-alerte_rouge_negatif {
  border: 3px dashed var(--alerte_rouge_negatif);
}

.border-all-3-dotted-alerte_rouge_negatif {
  border: 3px dotted var(--alerte_rouge_negatif);
}

.border-all-3-double-alerte_rouge_negatif {
  border: 3px double var(--alerte_rouge_negatif);
}

.border-all-3-groove-alerte_rouge_negatif {
  border: 3px groove var(--alerte_rouge_negatif);
}

.border-all-3-ridge-alerte_rouge_negatif {
  border: 3px ridge var(--alerte_rouge_negatif);
}

.border-all-3-inset-alerte_rouge_negatif {
  border: 3px inset var(--alerte_rouge_negatif);
}

.border-all-3-outset-alerte_rouge_negatif {
  border: 3px outset var(--alerte_rouge_negatif);
}

.border-all-3-none-alerte_rouge_negatif {
  border: 3px none var(--alerte_rouge_negatif);
}

.border-all-3-hidden-alerte_rouge_negatif {
  border: 3px hidden var(--alerte_rouge_negatif);
}

.border-all-3-solid-alerte_bleu_information {
  border: 3px solid var(--alerte_bleu_information);
}

.border-all-3-dashed-alerte_bleu_information {
  border: 3px dashed var(--alerte_bleu_information);
}

.border-all-3-dotted-alerte_bleu_information {
  border: 3px dotted var(--alerte_bleu_information);
}

.border-all-3-double-alerte_bleu_information {
  border: 3px double var(--alerte_bleu_information);
}

.border-all-3-groove-alerte_bleu_information {
  border: 3px groove var(--alerte_bleu_information);
}

.border-all-3-ridge-alerte_bleu_information {
  border: 3px ridge var(--alerte_bleu_information);
}

.border-all-3-inset-alerte_bleu_information {
  border: 3px inset var(--alerte_bleu_information);
}

.border-all-3-outset-alerte_bleu_information {
  border: 3px outset var(--alerte_bleu_information);
}

.border-all-3-none-alerte_bleu_information {
  border: 3px none var(--alerte_bleu_information);
}

.border-all-3-hidden-alerte_bleu_information {
  border: 3px hidden var(--alerte_bleu_information);
}

.border-all-3-solid-alerte_jaune_attention {
  border: 3px solid var(--alerte_jaune_attention);
}

.border-all-3-dashed-alerte_jaune_attention {
  border: 3px dashed var(--alerte_jaune_attention);
}

.border-all-3-dotted-alerte_jaune_attention {
  border: 3px dotted var(--alerte_jaune_attention);
}

.border-all-3-double-alerte_jaune_attention {
  border: 3px double var(--alerte_jaune_attention);
}

.border-all-3-groove-alerte_jaune_attention {
  border: 3px groove var(--alerte_jaune_attention);
}

.border-all-3-ridge-alerte_jaune_attention {
  border: 3px ridge var(--alerte_jaune_attention);
}

.border-all-3-inset-alerte_jaune_attention {
  border: 3px inset var(--alerte_jaune_attention);
}

.border-all-3-outset-alerte_jaune_attention {
  border: 3px outset var(--alerte_jaune_attention);
}

.border-all-3-none-alerte_jaune_attention {
  border: 3px none var(--alerte_jaune_attention);
}

.border-all-3-hidden-alerte_jaune_attention {
  border: 3px hidden var(--alerte_jaune_attention);
}

.border-all-3-solid-blanc {
  border: 3px solid #fff;
}

.border-all-3-dashed-blanc {
  border: 3px dashed #fff;
}

.border-all-3-dotted-blanc {
  border: 3px dotted #fff;
}

.border-all-3-double-blanc {
  border: 3px double #fff;
}

.border-all-3-groove-blanc {
  border: 3px groove #fff;
}

.border-all-3-ridge-blanc {
  border: 3px ridge #fff;
}

.border-all-3-inset-blanc {
  border: 3px inset #fff;
}

.border-all-3-outset-blanc {
  border: 3px outset #fff;
}

.border-all-3-none-blanc {
  border: 3px #fff;
}

.border-all-3-hidden-blanc {
  border: 3px hidden #fff;
}

.border-all-3-solid-noir {
  border: 3px solid #222;
}

.border-all-3-dashed-noir {
  border: 3px dashed #222;
}

.border-all-3-dotted-noir {
  border: 3px dotted #222;
}

.border-all-3-double-noir {
  border: 3px double #222;
}

.border-all-3-groove-noir {
  border: 3px groove #222;
}

.border-all-3-ridge-noir {
  border: 3px ridge #222;
}

.border-all-3-inset-noir {
  border: 3px inset #222;
}

.border-all-3-outset-noir {
  border: 3px outset #222;
}

.border-all-3-none-noir {
  border: 3px #222;
}

.border-all-3-hidden-noir {
  border: 3px hidden #222;
}

.border-all-3-solid-gris_light {
  border: 3px solid var(--gris_light);
}

.border-all-3-dashed-gris_light {
  border: 3px dashed var(--gris_light);
}

.border-all-3-dotted-gris_light {
  border: 3px dotted var(--gris_light);
}

.border-all-3-double-gris_light {
  border: 3px double var(--gris_light);
}

.border-all-3-groove-gris_light {
  border: 3px groove var(--gris_light);
}

.border-all-3-ridge-gris_light {
  border: 3px ridge var(--gris_light);
}

.border-all-3-inset-gris_light {
  border: 3px inset var(--gris_light);
}

.border-all-3-outset-gris_light {
  border: 3px outset var(--gris_light);
}

.border-all-3-none-gris_light {
  border: 3px none var(--gris_light);
}

.border-all-3-hidden-gris_light {
  border: 3px hidden var(--gris_light);
}

.border-all-3-solid-gris_dark {
  border: 3px solid var(--gris_dark);
}

.border-all-3-dashed-gris_dark {
  border: 3px dashed var(--gris_dark);
}

.border-all-3-dotted-gris_dark {
  border: 3px dotted var(--gris_dark);
}

.border-all-3-double-gris_dark {
  border: 3px double var(--gris_dark);
}

.border-all-3-groove-gris_dark {
  border: 3px groove var(--gris_dark);
}

.border-all-3-ridge-gris_dark {
  border: 3px ridge var(--gris_dark);
}

.border-all-3-inset-gris_dark {
  border: 3px inset var(--gris_dark);
}

.border-all-3-outset-gris_dark {
  border: 3px outset var(--gris_dark);
}

.border-all-3-none-gris_dark {
  border: 3px none var(--gris_dark);
}

.border-all-3-hidden-gris_dark {
  border: 3px hidden var(--gris_dark);
}

.border-all-3-solid-gris_background {
  border: 3px solid var(--gris_background);
}

.border-all-3-dashed-gris_background {
  border: 3px dashed var(--gris_background);
}

.border-all-3-dotted-gris_background {
  border: 3px dotted var(--gris_background);
}

.border-all-3-double-gris_background {
  border: 3px double var(--gris_background);
}

.border-all-3-groove-gris_background {
  border: 3px groove var(--gris_background);
}

.border-all-3-ridge-gris_background {
  border: 3px ridge var(--gris_background);
}

.border-all-3-inset-gris_background {
  border: 3px inset var(--gris_background);
}

.border-all-3-outset-gris_background {
  border: 3px outset var(--gris_background);
}

.border-all-3-none-gris_background {
  border: 3px none var(--gris_background);
}

.border-all-3-hidden-gris_background {
  border: 3px hidden var(--gris_background);
}

.border-top-4-solid-corail_mh_brand {
  border-top: 4px solid var(--corail_mh_brand);
}

.border-top-4-dashed-corail_mh_brand {
  border-top: 4px dashed var(--corail_mh_brand);
}

.border-top-4-dotted-corail_mh_brand {
  border-top: 4px dotted var(--corail_mh_brand);
}

.border-top-4-double-corail_mh_brand {
  border-top: 4px double var(--corail_mh_brand);
}

.border-top-4-groove-corail_mh_brand {
  border-top: 4px groove var(--corail_mh_brand);
}

.border-top-4-ridge-corail_mh_brand {
  border-top: 4px ridge var(--corail_mh_brand);
}

.border-top-4-inset-corail_mh_brand {
  border-top: 4px inset var(--corail_mh_brand);
}

.border-top-4-outset-corail_mh_brand {
  border-top: 4px outset var(--corail_mh_brand);
}

.border-top-4-none-corail_mh_brand {
  border-top: 4px none var(--corail_mh_brand);
}

.border-top-4-hidden-corail_mh_brand {
  border-top: 4px hidden var(--corail_mh_brand);
}

.border-top-4-solid-corail_mh_dark {
  border-top: 4px solid var(--corail_mh_dark);
}

.border-top-4-dashed-corail_mh_dark {
  border-top: 4px dashed var(--corail_mh_dark);
}

.border-top-4-dotted-corail_mh_dark {
  border-top: 4px dotted var(--corail_mh_dark);
}

.border-top-4-double-corail_mh_dark {
  border-top: 4px double var(--corail_mh_dark);
}

.border-top-4-groove-corail_mh_dark {
  border-top: 4px groove var(--corail_mh_dark);
}

.border-top-4-ridge-corail_mh_dark {
  border-top: 4px ridge var(--corail_mh_dark);
}

.border-top-4-inset-corail_mh_dark {
  border-top: 4px inset var(--corail_mh_dark);
}

.border-top-4-outset-corail_mh_dark {
  border-top: 4px outset var(--corail_mh_dark);
}

.border-top-4-none-corail_mh_dark {
  border-top: 4px none var(--corail_mh_dark);
}

.border-top-4-hidden-corail_mh_dark {
  border-top: 4px hidden var(--corail_mh_dark);
}

.border-top-4-solid-corail_mh_light {
  border-top: 4px solid var(--corail_mh_light);
}

.border-top-4-dashed-corail_mh_light {
  border-top: 4px dashed var(--corail_mh_light);
}

.border-top-4-dotted-corail_mh_light {
  border-top: 4px dotted var(--corail_mh_light);
}

.border-top-4-double-corail_mh_light {
  border-top: 4px double var(--corail_mh_light);
}

.border-top-4-groove-corail_mh_light {
  border-top: 4px groove var(--corail_mh_light);
}

.border-top-4-ridge-corail_mh_light {
  border-top: 4px ridge var(--corail_mh_light);
}

.border-top-4-inset-corail_mh_light {
  border-top: 4px inset var(--corail_mh_light);
}

.border-top-4-outset-corail_mh_light {
  border-top: 4px outset var(--corail_mh_light);
}

.border-top-4-none-corail_mh_light {
  border-top: 4px none var(--corail_mh_light);
}

.border-top-4-hidden-corail_mh_light {
  border-top: 4px hidden var(--corail_mh_light);
}

.border-top-4-solid-gris_sable_light {
  border-top: 4px solid var(--gris_sable_light);
}

.border-top-4-dashed-gris_sable_light {
  border-top: 4px dashed var(--gris_sable_light);
}

.border-top-4-dotted-gris_sable_light {
  border-top: 4px dotted var(--gris_sable_light);
}

.border-top-4-double-gris_sable_light {
  border-top: 4px double var(--gris_sable_light);
}

.border-top-4-groove-gris_sable_light {
  border-top: 4px groove var(--gris_sable_light);
}

.border-top-4-ridge-gris_sable_light {
  border-top: 4px ridge var(--gris_sable_light);
}

.border-top-4-inset-gris_sable_light {
  border-top: 4px inset var(--gris_sable_light);
}

.border-top-4-outset-gris_sable_light {
  border-top: 4px outset var(--gris_sable_light);
}

.border-top-4-none-gris_sable_light {
  border-top: 4px none var(--gris_sable_light);
}

.border-top-4-hidden-gris_sable_light {
  border-top: 4px hidden var(--gris_sable_light);
}

.border-top-4-solid-gris_sable_dark {
  border-top: 4px solid var(--gris_sable_dark);
}

.border-top-4-dashed-gris_sable_dark {
  border-top: 4px dashed var(--gris_sable_dark);
}

.border-top-4-dotted-gris_sable_dark {
  border-top: 4px dotted var(--gris_sable_dark);
}

.border-top-4-double-gris_sable_dark {
  border-top: 4px double var(--gris_sable_dark);
}

.border-top-4-groove-gris_sable_dark {
  border-top: 4px groove var(--gris_sable_dark);
}

.border-top-4-ridge-gris_sable_dark {
  border-top: 4px ridge var(--gris_sable_dark);
}

.border-top-4-inset-gris_sable_dark {
  border-top: 4px inset var(--gris_sable_dark);
}

.border-top-4-outset-gris_sable_dark {
  border-top: 4px outset var(--gris_sable_dark);
}

.border-top-4-none-gris_sable_dark {
  border-top: 4px none var(--gris_sable_dark);
}

.border-top-4-hidden-gris_sable_dark {
  border-top: 4px hidden var(--gris_sable_dark);
}

.border-top-4-solid-jaune_ivoire_light {
  border-top: 4px solid var(--jaune_ivoire_light);
}

.border-top-4-dashed-jaune_ivoire_light {
  border-top: 4px dashed var(--jaune_ivoire_light);
}

.border-top-4-dotted-jaune_ivoire_light {
  border-top: 4px dotted var(--jaune_ivoire_light);
}

.border-top-4-double-jaune_ivoire_light {
  border-top: 4px double var(--jaune_ivoire_light);
}

.border-top-4-groove-jaune_ivoire_light {
  border-top: 4px groove var(--jaune_ivoire_light);
}

.border-top-4-ridge-jaune_ivoire_light {
  border-top: 4px ridge var(--jaune_ivoire_light);
}

.border-top-4-inset-jaune_ivoire_light {
  border-top: 4px inset var(--jaune_ivoire_light);
}

.border-top-4-outset-jaune_ivoire_light {
  border-top: 4px outset var(--jaune_ivoire_light);
}

.border-top-4-none-jaune_ivoire_light {
  border-top: 4px none var(--jaune_ivoire_light);
}

.border-top-4-hidden-jaune_ivoire_light {
  border-top: 4px hidden var(--jaune_ivoire_light);
}

.border-top-4-solid-jaune_ivoire_dark {
  border-top: 4px solid var(--jaune_ivoire_dark);
}

.border-top-4-dashed-jaune_ivoire_dark {
  border-top: 4px dashed var(--jaune_ivoire_dark);
}

.border-top-4-dotted-jaune_ivoire_dark {
  border-top: 4px dotted var(--jaune_ivoire_dark);
}

.border-top-4-double-jaune_ivoire_dark {
  border-top: 4px double var(--jaune_ivoire_dark);
}

.border-top-4-groove-jaune_ivoire_dark {
  border-top: 4px groove var(--jaune_ivoire_dark);
}

.border-top-4-ridge-jaune_ivoire_dark {
  border-top: 4px ridge var(--jaune_ivoire_dark);
}

.border-top-4-inset-jaune_ivoire_dark {
  border-top: 4px inset var(--jaune_ivoire_dark);
}

.border-top-4-outset-jaune_ivoire_dark {
  border-top: 4px outset var(--jaune_ivoire_dark);
}

.border-top-4-none-jaune_ivoire_dark {
  border-top: 4px none var(--jaune_ivoire_dark);
}

.border-top-4-hidden-jaune_ivoire_dark {
  border-top: 4px hidden var(--jaune_ivoire_dark);
}

.border-top-4-solid-jaune_light {
  border-top: 4px solid var(--jaune_light);
}

.border-top-4-dashed-jaune_light {
  border-top: 4px dashed var(--jaune_light);
}

.border-top-4-dotted-jaune_light {
  border-top: 4px dotted var(--jaune_light);
}

.border-top-4-double-jaune_light {
  border-top: 4px double var(--jaune_light);
}

.border-top-4-groove-jaune_light {
  border-top: 4px groove var(--jaune_light);
}

.border-top-4-ridge-jaune_light {
  border-top: 4px ridge var(--jaune_light);
}

.border-top-4-inset-jaune_light {
  border-top: 4px inset var(--jaune_light);
}

.border-top-4-outset-jaune_light {
  border-top: 4px outset var(--jaune_light);
}

.border-top-4-none-jaune_light {
  border-top: 4px none var(--jaune_light);
}

.border-top-4-hidden-jaune_light {
  border-top: 4px hidden var(--jaune_light);
}

.border-top-4-solid-jaune_dark {
  border-top: 4px solid var(--jaune_dark);
}

.border-top-4-dashed-jaune_dark {
  border-top: 4px dashed var(--jaune_dark);
}

.border-top-4-dotted-jaune_dark {
  border-top: 4px dotted var(--jaune_dark);
}

.border-top-4-double-jaune_dark {
  border-top: 4px double var(--jaune_dark);
}

.border-top-4-groove-jaune_dark {
  border-top: 4px groove var(--jaune_dark);
}

.border-top-4-ridge-jaune_dark {
  border-top: 4px ridge var(--jaune_dark);
}

.border-top-4-inset-jaune_dark {
  border-top: 4px inset var(--jaune_dark);
}

.border-top-4-outset-jaune_dark {
  border-top: 4px outset var(--jaune_dark);
}

.border-top-4-none-jaune_dark {
  border-top: 4px none var(--jaune_dark);
}

.border-top-4-hidden-jaune_dark {
  border-top: 4px hidden var(--jaune_dark);
}

.border-top-4-solid-orange_normal {
  border-top: 4px solid var(--orange_normal);
}

.border-top-4-dashed-orange_normal {
  border-top: 4px dashed var(--orange_normal);
}

.border-top-4-dotted-orange_normal {
  border-top: 4px dotted var(--orange_normal);
}

.border-top-4-double-orange_normal {
  border-top: 4px double var(--orange_normal);
}

.border-top-4-groove-orange_normal {
  border-top: 4px groove var(--orange_normal);
}

.border-top-4-ridge-orange_normal {
  border-top: 4px ridge var(--orange_normal);
}

.border-top-4-inset-orange_normal {
  border-top: 4px inset var(--orange_normal);
}

.border-top-4-outset-orange_normal {
  border-top: 4px outset var(--orange_normal);
}

.border-top-4-none-orange_normal {
  border-top: 4px none var(--orange_normal);
}

.border-top-4-hidden-orange_normal {
  border-top: 4px hidden var(--orange_normal);
}

.border-top-4-solid-orange_dark {
  border-top: 4px solid var(--orange_dark);
}

.border-top-4-dashed-orange_dark {
  border-top: 4px dashed var(--orange_dark);
}

.border-top-4-dotted-orange_dark {
  border-top: 4px dotted var(--orange_dark);
}

.border-top-4-double-orange_dark {
  border-top: 4px double var(--orange_dark);
}

.border-top-4-groove-orange_dark {
  border-top: 4px groove var(--orange_dark);
}

.border-top-4-ridge-orange_dark {
  border-top: 4px ridge var(--orange_dark);
}

.border-top-4-inset-orange_dark {
  border-top: 4px inset var(--orange_dark);
}

.border-top-4-outset-orange_dark {
  border-top: 4px outset var(--orange_dark);
}

.border-top-4-none-orange_dark {
  border-top: 4px none var(--orange_dark);
}

.border-top-4-hidden-orange_dark {
  border-top: 4px hidden var(--orange_dark);
}

.border-top-4-solid-vert_light {
  border-top: 4px solid var(--vert_light);
}

.border-top-4-dashed-vert_light {
  border-top: 4px dashed var(--vert_light);
}

.border-top-4-dotted-vert_light {
  border-top: 4px dotted var(--vert_light);
}

.border-top-4-double-vert_light {
  border-top: 4px double var(--vert_light);
}

.border-top-4-groove-vert_light {
  border-top: 4px groove var(--vert_light);
}

.border-top-4-ridge-vert_light {
  border-top: 4px ridge var(--vert_light);
}

.border-top-4-inset-vert_light {
  border-top: 4px inset var(--vert_light);
}

.border-top-4-outset-vert_light {
  border-top: 4px outset var(--vert_light);
}

.border-top-4-none-vert_light {
  border-top: 4px none var(--vert_light);
}

.border-top-4-hidden-vert_light {
  border-top: 4px hidden var(--vert_light);
}

.border-top-4-solid-vert_dark {
  border-top: 4px solid var(--vert_dark);
}

.border-top-4-dashed-vert_dark {
  border-top: 4px dashed var(--vert_dark);
}

.border-top-4-dotted-vert_dark {
  border-top: 4px dotted var(--vert_dark);
}

.border-top-4-double-vert_dark {
  border-top: 4px double var(--vert_dark);
}

.border-top-4-groove-vert_dark {
  border-top: 4px groove var(--vert_dark);
}

.border-top-4-ridge-vert_dark {
  border-top: 4px ridge var(--vert_dark);
}

.border-top-4-inset-vert_dark {
  border-top: 4px inset var(--vert_dark);
}

.border-top-4-outset-vert_dark {
  border-top: 4px outset var(--vert_dark);
}

.border-top-4-none-vert_dark {
  border-top: 4px none var(--vert_dark);
}

.border-top-4-hidden-vert_dark {
  border-top: 4px hidden var(--vert_dark);
}

.border-top-4-solid-bleu_turquoise_light {
  border-top: 4px solid var(--bleu_turquoise_light);
}

.border-top-4-dashed-bleu_turquoise_light {
  border-top: 4px dashed var(--bleu_turquoise_light);
}

.border-top-4-dotted-bleu_turquoise_light {
  border-top: 4px dotted var(--bleu_turquoise_light);
}

.border-top-4-double-bleu_turquoise_light {
  border-top: 4px double var(--bleu_turquoise_light);
}

.border-top-4-groove-bleu_turquoise_light {
  border-top: 4px groove var(--bleu_turquoise_light);
}

.border-top-4-ridge-bleu_turquoise_light {
  border-top: 4px ridge var(--bleu_turquoise_light);
}

.border-top-4-inset-bleu_turquoise_light {
  border-top: 4px inset var(--bleu_turquoise_light);
}

.border-top-4-outset-bleu_turquoise_light {
  border-top: 4px outset var(--bleu_turquoise_light);
}

.border-top-4-none-bleu_turquoise_light {
  border-top: 4px none var(--bleu_turquoise_light);
}

.border-top-4-hidden-bleu_turquoise_light {
  border-top: 4px hidden var(--bleu_turquoise_light);
}

.border-top-4-solid-bleu_turquoise_middle {
  border-top: 4px solid var(--bleu_turquoise_middle);
}

.border-top-4-dashed-bleu_turquoise_middle {
  border-top: 4px dashed var(--bleu_turquoise_middle);
}

.border-top-4-dotted-bleu_turquoise_middle {
  border-top: 4px dotted var(--bleu_turquoise_middle);
}

.border-top-4-double-bleu_turquoise_middle {
  border-top: 4px double var(--bleu_turquoise_middle);
}

.border-top-4-groove-bleu_turquoise_middle {
  border-top: 4px groove var(--bleu_turquoise_middle);
}

.border-top-4-ridge-bleu_turquoise_middle {
  border-top: 4px ridge var(--bleu_turquoise_middle);
}

.border-top-4-inset-bleu_turquoise_middle {
  border-top: 4px inset var(--bleu_turquoise_middle);
}

.border-top-4-outset-bleu_turquoise_middle {
  border-top: 4px outset var(--bleu_turquoise_middle);
}

.border-top-4-none-bleu_turquoise_middle {
  border-top: 4px none var(--bleu_turquoise_middle);
}

.border-top-4-hidden-bleu_turquoise_middle {
  border-top: 4px hidden var(--bleu_turquoise_middle);
}

.border-top-4-solid-bleu_turquoise_dark {
  border-top: 4px solid var(--bleu_turquoise_dark);
}

.border-top-4-dashed-bleu_turquoise_dark {
  border-top: 4px dashed var(--bleu_turquoise_dark);
}

.border-top-4-dotted-bleu_turquoise_dark {
  border-top: 4px dotted var(--bleu_turquoise_dark);
}

.border-top-4-double-bleu_turquoise_dark {
  border-top: 4px double var(--bleu_turquoise_dark);
}

.border-top-4-groove-bleu_turquoise_dark {
  border-top: 4px groove var(--bleu_turquoise_dark);
}

.border-top-4-ridge-bleu_turquoise_dark {
  border-top: 4px ridge var(--bleu_turquoise_dark);
}

.border-top-4-inset-bleu_turquoise_dark {
  border-top: 4px inset var(--bleu_turquoise_dark);
}

.border-top-4-outset-bleu_turquoise_dark {
  border-top: 4px outset var(--bleu_turquoise_dark);
}

.border-top-4-none-bleu_turquoise_dark {
  border-top: 4px none var(--bleu_turquoise_dark);
}

.border-top-4-hidden-bleu_turquoise_dark {
  border-top: 4px hidden var(--bleu_turquoise_dark);
}

.border-top-4-solid-bleu_light {
  border-top: 4px solid var(--bleu_light);
}

.border-top-4-dashed-bleu_light {
  border-top: 4px dashed var(--bleu_light);
}

.border-top-4-dotted-bleu_light {
  border-top: 4px dotted var(--bleu_light);
}

.border-top-4-double-bleu_light {
  border-top: 4px double var(--bleu_light);
}

.border-top-4-groove-bleu_light {
  border-top: 4px groove var(--bleu_light);
}

.border-top-4-ridge-bleu_light {
  border-top: 4px ridge var(--bleu_light);
}

.border-top-4-inset-bleu_light {
  border-top: 4px inset var(--bleu_light);
}

.border-top-4-outset-bleu_light {
  border-top: 4px outset var(--bleu_light);
}

.border-top-4-none-bleu_light {
  border-top: 4px none var(--bleu_light);
}

.border-top-4-hidden-bleu_light {
  border-top: 4px hidden var(--bleu_light);
}

.border-top-4-solid-bleu_dark {
  border-top: 4px solid var(--bleu_dark);
}

.border-top-4-dashed-bleu_dark {
  border-top: 4px dashed var(--bleu_dark);
}

.border-top-4-dotted-bleu_dark {
  border-top: 4px dotted var(--bleu_dark);
}

.border-top-4-double-bleu_dark {
  border-top: 4px double var(--bleu_dark);
}

.border-top-4-groove-bleu_dark {
  border-top: 4px groove var(--bleu_dark);
}

.border-top-4-ridge-bleu_dark {
  border-top: 4px ridge var(--bleu_dark);
}

.border-top-4-inset-bleu_dark {
  border-top: 4px inset var(--bleu_dark);
}

.border-top-4-outset-bleu_dark {
  border-top: 4px outset var(--bleu_dark);
}

.border-top-4-none-bleu_dark {
  border-top: 4px none var(--bleu_dark);
}

.border-top-4-hidden-bleu_dark {
  border-top: 4px hidden var(--bleu_dark);
}

.border-top-4-solid-bleu_klein_light {
  border-top: 4px solid var(--bleu_klein_light);
}

.border-top-4-dashed-bleu_klein_light {
  border-top: 4px dashed var(--bleu_klein_light);
}

.border-top-4-dotted-bleu_klein_light {
  border-top: 4px dotted var(--bleu_klein_light);
}

.border-top-4-double-bleu_klein_light {
  border-top: 4px double var(--bleu_klein_light);
}

.border-top-4-groove-bleu_klein_light {
  border-top: 4px groove var(--bleu_klein_light);
}

.border-top-4-ridge-bleu_klein_light {
  border-top: 4px ridge var(--bleu_klein_light);
}

.border-top-4-inset-bleu_klein_light {
  border-top: 4px inset var(--bleu_klein_light);
}

.border-top-4-outset-bleu_klein_light {
  border-top: 4px outset var(--bleu_klein_light);
}

.border-top-4-none-bleu_klein_light {
  border-top: 4px none var(--bleu_klein_light);
}

.border-top-4-hidden-bleu_klein_light {
  border-top: 4px hidden var(--bleu_klein_light);
}

.border-top-4-solid-bleu_klein_dark {
  border-top: 4px solid var(--bleu_klein_dark);
}

.border-top-4-dashed-bleu_klein_dark {
  border-top: 4px dashed var(--bleu_klein_dark);
}

.border-top-4-dotted-bleu_klein_dark {
  border-top: 4px dotted var(--bleu_klein_dark);
}

.border-top-4-double-bleu_klein_dark {
  border-top: 4px double var(--bleu_klein_dark);
}

.border-top-4-groove-bleu_klein_dark {
  border-top: 4px groove var(--bleu_klein_dark);
}

.border-top-4-ridge-bleu_klein_dark {
  border-top: 4px ridge var(--bleu_klein_dark);
}

.border-top-4-inset-bleu_klein_dark {
  border-top: 4px inset var(--bleu_klein_dark);
}

.border-top-4-outset-bleu_klein_dark {
  border-top: 4px outset var(--bleu_klein_dark);
}

.border-top-4-none-bleu_klein_dark {
  border-top: 4px none var(--bleu_klein_dark);
}

.border-top-4-hidden-bleu_klein_dark {
  border-top: 4px hidden var(--bleu_klein_dark);
}

.border-top-4-solid-violet_light {
  border-top: 4px solid var(--violet_light);
}

.border-top-4-dashed-violet_light {
  border-top: 4px dashed var(--violet_light);
}

.border-top-4-dotted-violet_light {
  border-top: 4px dotted var(--violet_light);
}

.border-top-4-double-violet_light {
  border-top: 4px double var(--violet_light);
}

.border-top-4-groove-violet_light {
  border-top: 4px groove var(--violet_light);
}

.border-top-4-ridge-violet_light {
  border-top: 4px ridge var(--violet_light);
}

.border-top-4-inset-violet_light {
  border-top: 4px inset var(--violet_light);
}

.border-top-4-outset-violet_light {
  border-top: 4px outset var(--violet_light);
}

.border-top-4-none-violet_light {
  border-top: 4px none var(--violet_light);
}

.border-top-4-hidden-violet_light {
  border-top: 4px hidden var(--violet_light);
}

.border-top-4-solid-violet_dark {
  border-top: 4px solid var(--violet_dark);
}

.border-top-4-dashed-violet_dark {
  border-top: 4px dashed var(--violet_dark);
}

.border-top-4-dotted-violet_dark {
  border-top: 4px dotted var(--violet_dark);
}

.border-top-4-double-violet_dark {
  border-top: 4px double var(--violet_dark);
}

.border-top-4-groove-violet_dark {
  border-top: 4px groove var(--violet_dark);
}

.border-top-4-ridge-violet_dark {
  border-top: 4px ridge var(--violet_dark);
}

.border-top-4-inset-violet_dark {
  border-top: 4px inset var(--violet_dark);
}

.border-top-4-outset-violet_dark {
  border-top: 4px outset var(--violet_dark);
}

.border-top-4-none-violet_dark {
  border-top: 4px none var(--violet_dark);
}

.border-top-4-hidden-violet_dark {
  border-top: 4px hidden var(--violet_dark);
}

.border-top-4-solid-mauve {
  border-top: 4px solid var(--mauve);
}

.border-top-4-dashed-mauve {
  border-top: 4px dashed var(--mauve);
}

.border-top-4-dotted-mauve {
  border-top: 4px dotted var(--mauve);
}

.border-top-4-double-mauve {
  border-top: 4px double var(--mauve);
}

.border-top-4-groove-mauve {
  border-top: 4px groove var(--mauve);
}

.border-top-4-ridge-mauve {
  border-top: 4px ridge var(--mauve);
}

.border-top-4-inset-mauve {
  border-top: 4px inset var(--mauve);
}

.border-top-4-outset-mauve {
  border-top: 4px outset var(--mauve);
}

.border-top-4-none-mauve {
  border-top: 4px none var(--mauve);
}

.border-top-4-hidden-mauve {
  border-top: 4px hidden var(--mauve);
}

.border-top-4-solid-rose_light {
  border-top: 4px solid var(--rose_light);
}

.border-top-4-dashed-rose_light {
  border-top: 4px dashed var(--rose_light);
}

.border-top-4-dotted-rose_light {
  border-top: 4px dotted var(--rose_light);
}

.border-top-4-double-rose_light {
  border-top: 4px double var(--rose_light);
}

.border-top-4-groove-rose_light {
  border-top: 4px groove var(--rose_light);
}

.border-top-4-ridge-rose_light {
  border-top: 4px ridge var(--rose_light);
}

.border-top-4-inset-rose_light {
  border-top: 4px inset var(--rose_light);
}

.border-top-4-outset-rose_light {
  border-top: 4px outset var(--rose_light);
}

.border-top-4-none-rose_light {
  border-top: 4px none var(--rose_light);
}

.border-top-4-hidden-rose_light {
  border-top: 4px hidden var(--rose_light);
}

.border-top-4-solid-rose_dark {
  border-top: 4px solid var(--rose_dark);
}

.border-top-4-dashed-rose_dark {
  border-top: 4px dashed var(--rose_dark);
}

.border-top-4-dotted-rose_dark {
  border-top: 4px dotted var(--rose_dark);
}

.border-top-4-double-rose_dark {
  border-top: 4px double var(--rose_dark);
}

.border-top-4-groove-rose_dark {
  border-top: 4px groove var(--rose_dark);
}

.border-top-4-ridge-rose_dark {
  border-top: 4px ridge var(--rose_dark);
}

.border-top-4-inset-rose_dark {
  border-top: 4px inset var(--rose_dark);
}

.border-top-4-outset-rose_dark {
  border-top: 4px outset var(--rose_dark);
}

.border-top-4-none-rose_dark {
  border-top: 4px none var(--rose_dark);
}

.border-top-4-hidden-rose_dark {
  border-top: 4px hidden var(--rose_dark);
}

.border-top-4-solid-rose_fushia {
  border-top: 4px solid var(--rose_fushia);
}

.border-top-4-dashed-rose_fushia {
  border-top: 4px dashed var(--rose_fushia);
}

.border-top-4-dotted-rose_fushia {
  border-top: 4px dotted var(--rose_fushia);
}

.border-top-4-double-rose_fushia {
  border-top: 4px double var(--rose_fushia);
}

.border-top-4-groove-rose_fushia {
  border-top: 4px groove var(--rose_fushia);
}

.border-top-4-ridge-rose_fushia {
  border-top: 4px ridge var(--rose_fushia);
}

.border-top-4-inset-rose_fushia {
  border-top: 4px inset var(--rose_fushia);
}

.border-top-4-outset-rose_fushia {
  border-top: 4px outset var(--rose_fushia);
}

.border-top-4-none-rose_fushia {
  border-top: 4px none var(--rose_fushia);
}

.border-top-4-hidden-rose_fushia {
  border-top: 4px hidden var(--rose_fushia);
}

.border-top-4-solid-rouge_framboise {
  border-top: 4px solid var(--rouge_framboise);
}

.border-top-4-dashed-rouge_framboise {
  border-top: 4px dashed var(--rouge_framboise);
}

.border-top-4-dotted-rouge_framboise {
  border-top: 4px dotted var(--rouge_framboise);
}

.border-top-4-double-rouge_framboise {
  border-top: 4px double var(--rouge_framboise);
}

.border-top-4-groove-rouge_framboise {
  border-top: 4px groove var(--rouge_framboise);
}

.border-top-4-ridge-rouge_framboise {
  border-top: 4px ridge var(--rouge_framboise);
}

.border-top-4-inset-rouge_framboise {
  border-top: 4px inset var(--rouge_framboise);
}

.border-top-4-outset-rouge_framboise {
  border-top: 4px outset var(--rouge_framboise);
}

.border-top-4-none-rouge_framboise {
  border-top: 4px none var(--rouge_framboise);
}

.border-top-4-hidden-rouge_framboise {
  border-top: 4px hidden var(--rouge_framboise);
}

.border-top-4-solid-rose_chair_light {
  border-top: 4px solid var(--rose_chair_light);
}

.border-top-4-dashed-rose_chair_light {
  border-top: 4px dashed var(--rose_chair_light);
}

.border-top-4-dotted-rose_chair_light {
  border-top: 4px dotted var(--rose_chair_light);
}

.border-top-4-double-rose_chair_light {
  border-top: 4px double var(--rose_chair_light);
}

.border-top-4-groove-rose_chair_light {
  border-top: 4px groove var(--rose_chair_light);
}

.border-top-4-ridge-rose_chair_light {
  border-top: 4px ridge var(--rose_chair_light);
}

.border-top-4-inset-rose_chair_light {
  border-top: 4px inset var(--rose_chair_light);
}

.border-top-4-outset-rose_chair_light {
  border-top: 4px outset var(--rose_chair_light);
}

.border-top-4-none-rose_chair_light {
  border-top: 4px none var(--rose_chair_light);
}

.border-top-4-hidden-rose_chair_light {
  border-top: 4px hidden var(--rose_chair_light);
}

.border-top-4-solid-rose_chair_dark {
  border-top: 4px solid var(--rose_chair_dark);
}

.border-top-4-dashed-rose_chair_dark {
  border-top: 4px dashed var(--rose_chair_dark);
}

.border-top-4-dotted-rose_chair_dark {
  border-top: 4px dotted var(--rose_chair_dark);
}

.border-top-4-double-rose_chair_dark {
  border-top: 4px double var(--rose_chair_dark);
}

.border-top-4-groove-rose_chair_dark {
  border-top: 4px groove var(--rose_chair_dark);
}

.border-top-4-ridge-rose_chair_dark {
  border-top: 4px ridge var(--rose_chair_dark);
}

.border-top-4-inset-rose_chair_dark {
  border-top: 4px inset var(--rose_chair_dark);
}

.border-top-4-outset-rose_chair_dark {
  border-top: 4px outset var(--rose_chair_dark);
}

.border-top-4-none-rose_chair_dark {
  border-top: 4px none var(--rose_chair_dark);
}

.border-top-4-hidden-rose_chair_dark {
  border-top: 4px hidden var(--rose_chair_dark);
}

.border-top-4-solid-vert_positif {
  border-top: 4px solid var(--vert_positif);
}

.border-top-4-dashed-vert_positif {
  border-top: 4px dashed var(--vert_positif);
}

.border-top-4-dotted-vert_positif {
  border-top: 4px dotted var(--vert_positif);
}

.border-top-4-double-vert_positif {
  border-top: 4px double var(--vert_positif);
}

.border-top-4-groove-vert_positif {
  border-top: 4px groove var(--vert_positif);
}

.border-top-4-ridge-vert_positif {
  border-top: 4px ridge var(--vert_positif);
}

.border-top-4-inset-vert_positif {
  border-top: 4px inset var(--vert_positif);
}

.border-top-4-outset-vert_positif {
  border-top: 4px outset var(--vert_positif);
}

.border-top-4-none-vert_positif {
  border-top: 4px none var(--vert_positif);
}

.border-top-4-hidden-vert_positif {
  border-top: 4px hidden var(--vert_positif);
}

.border-top-4-solid-rouge_negatif {
  border-top: 4px solid var(--rouge_negatif);
}

.border-top-4-dashed-rouge_negatif {
  border-top: 4px dashed var(--rouge_negatif);
}

.border-top-4-dotted-rouge_negatif {
  border-top: 4px dotted var(--rouge_negatif);
}

.border-top-4-double-rouge_negatif {
  border-top: 4px double var(--rouge_negatif);
}

.border-top-4-groove-rouge_negatif {
  border-top: 4px groove var(--rouge_negatif);
}

.border-top-4-ridge-rouge_negatif {
  border-top: 4px ridge var(--rouge_negatif);
}

.border-top-4-inset-rouge_negatif {
  border-top: 4px inset var(--rouge_negatif);
}

.border-top-4-outset-rouge_negatif {
  border-top: 4px outset var(--rouge_negatif);
}

.border-top-4-none-rouge_negatif {
  border-top: 4px none var(--rouge_negatif);
}

.border-top-4-hidden-rouge_negatif {
  border-top: 4px hidden var(--rouge_negatif);
}

.border-top-4-solid-bleu_texte {
  border-top: 4px solid var(--bleu_texte);
}

.border-top-4-dashed-bleu_texte {
  border-top: 4px dashed var(--bleu_texte);
}

.border-top-4-dotted-bleu_texte {
  border-top: 4px dotted var(--bleu_texte);
}

.border-top-4-double-bleu_texte {
  border-top: 4px double var(--bleu_texte);
}

.border-top-4-groove-bleu_texte {
  border-top: 4px groove var(--bleu_texte);
}

.border-top-4-ridge-bleu_texte {
  border-top: 4px ridge var(--bleu_texte);
}

.border-top-4-inset-bleu_texte {
  border-top: 4px inset var(--bleu_texte);
}

.border-top-4-outset-bleu_texte {
  border-top: 4px outset var(--bleu_texte);
}

.border-top-4-none-bleu_texte {
  border-top: 4px none var(--bleu_texte);
}

.border-top-4-hidden-bleu_texte {
  border-top: 4px hidden var(--bleu_texte);
}

.border-top-4-solid-alerte_vert_positif {
  border-top: 4px solid var(--alerte_vert_positif);
}

.border-top-4-dashed-alerte_vert_positif {
  border-top: 4px dashed var(--alerte_vert_positif);
}

.border-top-4-dotted-alerte_vert_positif {
  border-top: 4px dotted var(--alerte_vert_positif);
}

.border-top-4-double-alerte_vert_positif {
  border-top: 4px double var(--alerte_vert_positif);
}

.border-top-4-groove-alerte_vert_positif {
  border-top: 4px groove var(--alerte_vert_positif);
}

.border-top-4-ridge-alerte_vert_positif {
  border-top: 4px ridge var(--alerte_vert_positif);
}

.border-top-4-inset-alerte_vert_positif {
  border-top: 4px inset var(--alerte_vert_positif);
}

.border-top-4-outset-alerte_vert_positif {
  border-top: 4px outset var(--alerte_vert_positif);
}

.border-top-4-none-alerte_vert_positif {
  border-top: 4px none var(--alerte_vert_positif);
}

.border-top-4-hidden-alerte_vert_positif {
  border-top: 4px hidden var(--alerte_vert_positif);
}

.border-top-4-solid-alerte_rouge_negatif {
  border-top: 4px solid var(--alerte_rouge_negatif);
}

.border-top-4-dashed-alerte_rouge_negatif {
  border-top: 4px dashed var(--alerte_rouge_negatif);
}

.border-top-4-dotted-alerte_rouge_negatif {
  border-top: 4px dotted var(--alerte_rouge_negatif);
}

.border-top-4-double-alerte_rouge_negatif {
  border-top: 4px double var(--alerte_rouge_negatif);
}

.border-top-4-groove-alerte_rouge_negatif {
  border-top: 4px groove var(--alerte_rouge_negatif);
}

.border-top-4-ridge-alerte_rouge_negatif {
  border-top: 4px ridge var(--alerte_rouge_negatif);
}

.border-top-4-inset-alerte_rouge_negatif {
  border-top: 4px inset var(--alerte_rouge_negatif);
}

.border-top-4-outset-alerte_rouge_negatif {
  border-top: 4px outset var(--alerte_rouge_negatif);
}

.border-top-4-none-alerte_rouge_negatif {
  border-top: 4px none var(--alerte_rouge_negatif);
}

.border-top-4-hidden-alerte_rouge_negatif {
  border-top: 4px hidden var(--alerte_rouge_negatif);
}

.border-top-4-solid-alerte_bleu_information {
  border-top: 4px solid var(--alerte_bleu_information);
}

.border-top-4-dashed-alerte_bleu_information {
  border-top: 4px dashed var(--alerte_bleu_information);
}

.border-top-4-dotted-alerte_bleu_information {
  border-top: 4px dotted var(--alerte_bleu_information);
}

.border-top-4-double-alerte_bleu_information {
  border-top: 4px double var(--alerte_bleu_information);
}

.border-top-4-groove-alerte_bleu_information {
  border-top: 4px groove var(--alerte_bleu_information);
}

.border-top-4-ridge-alerte_bleu_information {
  border-top: 4px ridge var(--alerte_bleu_information);
}

.border-top-4-inset-alerte_bleu_information {
  border-top: 4px inset var(--alerte_bleu_information);
}

.border-top-4-outset-alerte_bleu_information {
  border-top: 4px outset var(--alerte_bleu_information);
}

.border-top-4-none-alerte_bleu_information {
  border-top: 4px none var(--alerte_bleu_information);
}

.border-top-4-hidden-alerte_bleu_information {
  border-top: 4px hidden var(--alerte_bleu_information);
}

.border-top-4-solid-alerte_jaune_attention {
  border-top: 4px solid var(--alerte_jaune_attention);
}

.border-top-4-dashed-alerte_jaune_attention {
  border-top: 4px dashed var(--alerte_jaune_attention);
}

.border-top-4-dotted-alerte_jaune_attention {
  border-top: 4px dotted var(--alerte_jaune_attention);
}

.border-top-4-double-alerte_jaune_attention {
  border-top: 4px double var(--alerte_jaune_attention);
}

.border-top-4-groove-alerte_jaune_attention {
  border-top: 4px groove var(--alerte_jaune_attention);
}

.border-top-4-ridge-alerte_jaune_attention {
  border-top: 4px ridge var(--alerte_jaune_attention);
}

.border-top-4-inset-alerte_jaune_attention {
  border-top: 4px inset var(--alerte_jaune_attention);
}

.border-top-4-outset-alerte_jaune_attention {
  border-top: 4px outset var(--alerte_jaune_attention);
}

.border-top-4-none-alerte_jaune_attention {
  border-top: 4px none var(--alerte_jaune_attention);
}

.border-top-4-hidden-alerte_jaune_attention {
  border-top: 4px hidden var(--alerte_jaune_attention);
}

.border-top-4-solid-blanc {
  border-top: 4px solid #fff;
}

.border-top-4-dashed-blanc {
  border-top: 4px dashed #fff;
}

.border-top-4-dotted-blanc {
  border-top: 4px dotted #fff;
}

.border-top-4-double-blanc {
  border-top: 4px double #fff;
}

.border-top-4-groove-blanc {
  border-top: 4px groove #fff;
}

.border-top-4-ridge-blanc {
  border-top: 4px ridge #fff;
}

.border-top-4-inset-blanc {
  border-top: 4px inset #fff;
}

.border-top-4-outset-blanc {
  border-top: 4px outset #fff;
}

.border-top-4-none-blanc {
  border-top: 4px #fff;
}

.border-top-4-hidden-blanc {
  border-top: 4px hidden #fff;
}

.border-top-4-solid-noir {
  border-top: 4px solid #222;
}

.border-top-4-dashed-noir {
  border-top: 4px dashed #222;
}

.border-top-4-dotted-noir {
  border-top: 4px dotted #222;
}

.border-top-4-double-noir {
  border-top: 4px double #222;
}

.border-top-4-groove-noir {
  border-top: 4px groove #222;
}

.border-top-4-ridge-noir {
  border-top: 4px ridge #222;
}

.border-top-4-inset-noir {
  border-top: 4px inset #222;
}

.border-top-4-outset-noir {
  border-top: 4px outset #222;
}

.border-top-4-none-noir {
  border-top: 4px #222;
}

.border-top-4-hidden-noir {
  border-top: 4px hidden #222;
}

.border-top-4-solid-gris_light {
  border-top: 4px solid var(--gris_light);
}

.border-top-4-dashed-gris_light {
  border-top: 4px dashed var(--gris_light);
}

.border-top-4-dotted-gris_light {
  border-top: 4px dotted var(--gris_light);
}

.border-top-4-double-gris_light {
  border-top: 4px double var(--gris_light);
}

.border-top-4-groove-gris_light {
  border-top: 4px groove var(--gris_light);
}

.border-top-4-ridge-gris_light {
  border-top: 4px ridge var(--gris_light);
}

.border-top-4-inset-gris_light {
  border-top: 4px inset var(--gris_light);
}

.border-top-4-outset-gris_light {
  border-top: 4px outset var(--gris_light);
}

.border-top-4-none-gris_light {
  border-top: 4px none var(--gris_light);
}

.border-top-4-hidden-gris_light {
  border-top: 4px hidden var(--gris_light);
}

.border-top-4-solid-gris_dark {
  border-top: 4px solid var(--gris_dark);
}

.border-top-4-dashed-gris_dark {
  border-top: 4px dashed var(--gris_dark);
}

.border-top-4-dotted-gris_dark {
  border-top: 4px dotted var(--gris_dark);
}

.border-top-4-double-gris_dark {
  border-top: 4px double var(--gris_dark);
}

.border-top-4-groove-gris_dark {
  border-top: 4px groove var(--gris_dark);
}

.border-top-4-ridge-gris_dark {
  border-top: 4px ridge var(--gris_dark);
}

.border-top-4-inset-gris_dark {
  border-top: 4px inset var(--gris_dark);
}

.border-top-4-outset-gris_dark {
  border-top: 4px outset var(--gris_dark);
}

.border-top-4-none-gris_dark {
  border-top: 4px none var(--gris_dark);
}

.border-top-4-hidden-gris_dark {
  border-top: 4px hidden var(--gris_dark);
}

.border-top-4-solid-gris_background {
  border-top: 4px solid var(--gris_background);
}

.border-top-4-dashed-gris_background {
  border-top: 4px dashed var(--gris_background);
}

.border-top-4-dotted-gris_background {
  border-top: 4px dotted var(--gris_background);
}

.border-top-4-double-gris_background {
  border-top: 4px double var(--gris_background);
}

.border-top-4-groove-gris_background {
  border-top: 4px groove var(--gris_background);
}

.border-top-4-ridge-gris_background {
  border-top: 4px ridge var(--gris_background);
}

.border-top-4-inset-gris_background {
  border-top: 4px inset var(--gris_background);
}

.border-top-4-outset-gris_background {
  border-top: 4px outset var(--gris_background);
}

.border-top-4-none-gris_background {
  border-top: 4px none var(--gris_background);
}

.border-top-4-hidden-gris_background {
  border-top: 4px hidden var(--gris_background);
}

.border-bottom-4-solid-corail_mh_brand {
  border-bottom: 4px solid var(--corail_mh_brand);
}

.border-bottom-4-dashed-corail_mh_brand {
  border-bottom: 4px dashed var(--corail_mh_brand);
}

.border-bottom-4-dotted-corail_mh_brand {
  border-bottom: 4px dotted var(--corail_mh_brand);
}

.border-bottom-4-double-corail_mh_brand {
  border-bottom: 4px double var(--corail_mh_brand);
}

.border-bottom-4-groove-corail_mh_brand {
  border-bottom: 4px groove var(--corail_mh_brand);
}

.border-bottom-4-ridge-corail_mh_brand {
  border-bottom: 4px ridge var(--corail_mh_brand);
}

.border-bottom-4-inset-corail_mh_brand {
  border-bottom: 4px inset var(--corail_mh_brand);
}

.border-bottom-4-outset-corail_mh_brand {
  border-bottom: 4px outset var(--corail_mh_brand);
}

.border-bottom-4-none-corail_mh_brand {
  border-bottom: 4px none var(--corail_mh_brand);
}

.border-bottom-4-hidden-corail_mh_brand {
  border-bottom: 4px hidden var(--corail_mh_brand);
}

.border-bottom-4-solid-corail_mh_dark {
  border-bottom: 4px solid var(--corail_mh_dark);
}

.border-bottom-4-dashed-corail_mh_dark {
  border-bottom: 4px dashed var(--corail_mh_dark);
}

.border-bottom-4-dotted-corail_mh_dark {
  border-bottom: 4px dotted var(--corail_mh_dark);
}

.border-bottom-4-double-corail_mh_dark {
  border-bottom: 4px double var(--corail_mh_dark);
}

.border-bottom-4-groove-corail_mh_dark {
  border-bottom: 4px groove var(--corail_mh_dark);
}

.border-bottom-4-ridge-corail_mh_dark {
  border-bottom: 4px ridge var(--corail_mh_dark);
}

.border-bottom-4-inset-corail_mh_dark {
  border-bottom: 4px inset var(--corail_mh_dark);
}

.border-bottom-4-outset-corail_mh_dark {
  border-bottom: 4px outset var(--corail_mh_dark);
}

.border-bottom-4-none-corail_mh_dark {
  border-bottom: 4px none var(--corail_mh_dark);
}

.border-bottom-4-hidden-corail_mh_dark {
  border-bottom: 4px hidden var(--corail_mh_dark);
}

.border-bottom-4-solid-corail_mh_light {
  border-bottom: 4px solid var(--corail_mh_light);
}

.border-bottom-4-dashed-corail_mh_light {
  border-bottom: 4px dashed var(--corail_mh_light);
}

.border-bottom-4-dotted-corail_mh_light {
  border-bottom: 4px dotted var(--corail_mh_light);
}

.border-bottom-4-double-corail_mh_light {
  border-bottom: 4px double var(--corail_mh_light);
}

.border-bottom-4-groove-corail_mh_light {
  border-bottom: 4px groove var(--corail_mh_light);
}

.border-bottom-4-ridge-corail_mh_light {
  border-bottom: 4px ridge var(--corail_mh_light);
}

.border-bottom-4-inset-corail_mh_light {
  border-bottom: 4px inset var(--corail_mh_light);
}

.border-bottom-4-outset-corail_mh_light {
  border-bottom: 4px outset var(--corail_mh_light);
}

.border-bottom-4-none-corail_mh_light {
  border-bottom: 4px none var(--corail_mh_light);
}

.border-bottom-4-hidden-corail_mh_light {
  border-bottom: 4px hidden var(--corail_mh_light);
}

.border-bottom-4-solid-gris_sable_light {
  border-bottom: 4px solid var(--gris_sable_light);
}

.border-bottom-4-dashed-gris_sable_light {
  border-bottom: 4px dashed var(--gris_sable_light);
}

.border-bottom-4-dotted-gris_sable_light {
  border-bottom: 4px dotted var(--gris_sable_light);
}

.border-bottom-4-double-gris_sable_light {
  border-bottom: 4px double var(--gris_sable_light);
}

.border-bottom-4-groove-gris_sable_light {
  border-bottom: 4px groove var(--gris_sable_light);
}

.border-bottom-4-ridge-gris_sable_light {
  border-bottom: 4px ridge var(--gris_sable_light);
}

.border-bottom-4-inset-gris_sable_light {
  border-bottom: 4px inset var(--gris_sable_light);
}

.border-bottom-4-outset-gris_sable_light {
  border-bottom: 4px outset var(--gris_sable_light);
}

.border-bottom-4-none-gris_sable_light {
  border-bottom: 4px none var(--gris_sable_light);
}

.border-bottom-4-hidden-gris_sable_light {
  border-bottom: 4px hidden var(--gris_sable_light);
}

.border-bottom-4-solid-gris_sable_dark {
  border-bottom: 4px solid var(--gris_sable_dark);
}

.border-bottom-4-dashed-gris_sable_dark {
  border-bottom: 4px dashed var(--gris_sable_dark);
}

.border-bottom-4-dotted-gris_sable_dark {
  border-bottom: 4px dotted var(--gris_sable_dark);
}

.border-bottom-4-double-gris_sable_dark {
  border-bottom: 4px double var(--gris_sable_dark);
}

.border-bottom-4-groove-gris_sable_dark {
  border-bottom: 4px groove var(--gris_sable_dark);
}

.border-bottom-4-ridge-gris_sable_dark {
  border-bottom: 4px ridge var(--gris_sable_dark);
}

.border-bottom-4-inset-gris_sable_dark {
  border-bottom: 4px inset var(--gris_sable_dark);
}

.border-bottom-4-outset-gris_sable_dark {
  border-bottom: 4px outset var(--gris_sable_dark);
}

.border-bottom-4-none-gris_sable_dark {
  border-bottom: 4px none var(--gris_sable_dark);
}

.border-bottom-4-hidden-gris_sable_dark {
  border-bottom: 4px hidden var(--gris_sable_dark);
}

.border-bottom-4-solid-jaune_ivoire_light {
  border-bottom: 4px solid var(--jaune_ivoire_light);
}

.border-bottom-4-dashed-jaune_ivoire_light {
  border-bottom: 4px dashed var(--jaune_ivoire_light);
}

.border-bottom-4-dotted-jaune_ivoire_light {
  border-bottom: 4px dotted var(--jaune_ivoire_light);
}

.border-bottom-4-double-jaune_ivoire_light {
  border-bottom: 4px double var(--jaune_ivoire_light);
}

.border-bottom-4-groove-jaune_ivoire_light {
  border-bottom: 4px groove var(--jaune_ivoire_light);
}

.border-bottom-4-ridge-jaune_ivoire_light {
  border-bottom: 4px ridge var(--jaune_ivoire_light);
}

.border-bottom-4-inset-jaune_ivoire_light {
  border-bottom: 4px inset var(--jaune_ivoire_light);
}

.border-bottom-4-outset-jaune_ivoire_light {
  border-bottom: 4px outset var(--jaune_ivoire_light);
}

.border-bottom-4-none-jaune_ivoire_light {
  border-bottom: 4px none var(--jaune_ivoire_light);
}

.border-bottom-4-hidden-jaune_ivoire_light {
  border-bottom: 4px hidden var(--jaune_ivoire_light);
}

.border-bottom-4-solid-jaune_ivoire_dark {
  border-bottom: 4px solid var(--jaune_ivoire_dark);
}

.border-bottom-4-dashed-jaune_ivoire_dark {
  border-bottom: 4px dashed var(--jaune_ivoire_dark);
}

.border-bottom-4-dotted-jaune_ivoire_dark {
  border-bottom: 4px dotted var(--jaune_ivoire_dark);
}

.border-bottom-4-double-jaune_ivoire_dark {
  border-bottom: 4px double var(--jaune_ivoire_dark);
}

.border-bottom-4-groove-jaune_ivoire_dark {
  border-bottom: 4px groove var(--jaune_ivoire_dark);
}

.border-bottom-4-ridge-jaune_ivoire_dark {
  border-bottom: 4px ridge var(--jaune_ivoire_dark);
}

.border-bottom-4-inset-jaune_ivoire_dark {
  border-bottom: 4px inset var(--jaune_ivoire_dark);
}

.border-bottom-4-outset-jaune_ivoire_dark {
  border-bottom: 4px outset var(--jaune_ivoire_dark);
}

.border-bottom-4-none-jaune_ivoire_dark {
  border-bottom: 4px none var(--jaune_ivoire_dark);
}

.border-bottom-4-hidden-jaune_ivoire_dark {
  border-bottom: 4px hidden var(--jaune_ivoire_dark);
}

.border-bottom-4-solid-jaune_light {
  border-bottom: 4px solid var(--jaune_light);
}

.border-bottom-4-dashed-jaune_light {
  border-bottom: 4px dashed var(--jaune_light);
}

.border-bottom-4-dotted-jaune_light {
  border-bottom: 4px dotted var(--jaune_light);
}

.border-bottom-4-double-jaune_light {
  border-bottom: 4px double var(--jaune_light);
}

.border-bottom-4-groove-jaune_light {
  border-bottom: 4px groove var(--jaune_light);
}

.border-bottom-4-ridge-jaune_light {
  border-bottom: 4px ridge var(--jaune_light);
}

.border-bottom-4-inset-jaune_light {
  border-bottom: 4px inset var(--jaune_light);
}

.border-bottom-4-outset-jaune_light {
  border-bottom: 4px outset var(--jaune_light);
}

.border-bottom-4-none-jaune_light {
  border-bottom: 4px none var(--jaune_light);
}

.border-bottom-4-hidden-jaune_light {
  border-bottom: 4px hidden var(--jaune_light);
}

.border-bottom-4-solid-jaune_dark {
  border-bottom: 4px solid var(--jaune_dark);
}

.border-bottom-4-dashed-jaune_dark {
  border-bottom: 4px dashed var(--jaune_dark);
}

.border-bottom-4-dotted-jaune_dark {
  border-bottom: 4px dotted var(--jaune_dark);
}

.border-bottom-4-double-jaune_dark {
  border-bottom: 4px double var(--jaune_dark);
}

.border-bottom-4-groove-jaune_dark {
  border-bottom: 4px groove var(--jaune_dark);
}

.border-bottom-4-ridge-jaune_dark {
  border-bottom: 4px ridge var(--jaune_dark);
}

.border-bottom-4-inset-jaune_dark {
  border-bottom: 4px inset var(--jaune_dark);
}

.border-bottom-4-outset-jaune_dark {
  border-bottom: 4px outset var(--jaune_dark);
}

.border-bottom-4-none-jaune_dark {
  border-bottom: 4px none var(--jaune_dark);
}

.border-bottom-4-hidden-jaune_dark {
  border-bottom: 4px hidden var(--jaune_dark);
}

.border-bottom-4-solid-orange_normal {
  border-bottom: 4px solid var(--orange_normal);
}

.border-bottom-4-dashed-orange_normal {
  border-bottom: 4px dashed var(--orange_normal);
}

.border-bottom-4-dotted-orange_normal {
  border-bottom: 4px dotted var(--orange_normal);
}

.border-bottom-4-double-orange_normal {
  border-bottom: 4px double var(--orange_normal);
}

.border-bottom-4-groove-orange_normal {
  border-bottom: 4px groove var(--orange_normal);
}

.border-bottom-4-ridge-orange_normal {
  border-bottom: 4px ridge var(--orange_normal);
}

.border-bottom-4-inset-orange_normal {
  border-bottom: 4px inset var(--orange_normal);
}

.border-bottom-4-outset-orange_normal {
  border-bottom: 4px outset var(--orange_normal);
}

.border-bottom-4-none-orange_normal {
  border-bottom: 4px none var(--orange_normal);
}

.border-bottom-4-hidden-orange_normal {
  border-bottom: 4px hidden var(--orange_normal);
}

.border-bottom-4-solid-orange_dark {
  border-bottom: 4px solid var(--orange_dark);
}

.border-bottom-4-dashed-orange_dark {
  border-bottom: 4px dashed var(--orange_dark);
}

.border-bottom-4-dotted-orange_dark {
  border-bottom: 4px dotted var(--orange_dark);
}

.border-bottom-4-double-orange_dark {
  border-bottom: 4px double var(--orange_dark);
}

.border-bottom-4-groove-orange_dark {
  border-bottom: 4px groove var(--orange_dark);
}

.border-bottom-4-ridge-orange_dark {
  border-bottom: 4px ridge var(--orange_dark);
}

.border-bottom-4-inset-orange_dark {
  border-bottom: 4px inset var(--orange_dark);
}

.border-bottom-4-outset-orange_dark {
  border-bottom: 4px outset var(--orange_dark);
}

.border-bottom-4-none-orange_dark {
  border-bottom: 4px none var(--orange_dark);
}

.border-bottom-4-hidden-orange_dark {
  border-bottom: 4px hidden var(--orange_dark);
}

.border-bottom-4-solid-vert_light {
  border-bottom: 4px solid var(--vert_light);
}

.border-bottom-4-dashed-vert_light {
  border-bottom: 4px dashed var(--vert_light);
}

.border-bottom-4-dotted-vert_light {
  border-bottom: 4px dotted var(--vert_light);
}

.border-bottom-4-double-vert_light {
  border-bottom: 4px double var(--vert_light);
}

.border-bottom-4-groove-vert_light {
  border-bottom: 4px groove var(--vert_light);
}

.border-bottom-4-ridge-vert_light {
  border-bottom: 4px ridge var(--vert_light);
}

.border-bottom-4-inset-vert_light {
  border-bottom: 4px inset var(--vert_light);
}

.border-bottom-4-outset-vert_light {
  border-bottom: 4px outset var(--vert_light);
}

.border-bottom-4-none-vert_light {
  border-bottom: 4px none var(--vert_light);
}

.border-bottom-4-hidden-vert_light {
  border-bottom: 4px hidden var(--vert_light);
}

.border-bottom-4-solid-vert_dark {
  border-bottom: 4px solid var(--vert_dark);
}

.border-bottom-4-dashed-vert_dark {
  border-bottom: 4px dashed var(--vert_dark);
}

.border-bottom-4-dotted-vert_dark {
  border-bottom: 4px dotted var(--vert_dark);
}

.border-bottom-4-double-vert_dark {
  border-bottom: 4px double var(--vert_dark);
}

.border-bottom-4-groove-vert_dark {
  border-bottom: 4px groove var(--vert_dark);
}

.border-bottom-4-ridge-vert_dark {
  border-bottom: 4px ridge var(--vert_dark);
}

.border-bottom-4-inset-vert_dark {
  border-bottom: 4px inset var(--vert_dark);
}

.border-bottom-4-outset-vert_dark {
  border-bottom: 4px outset var(--vert_dark);
}

.border-bottom-4-none-vert_dark {
  border-bottom: 4px none var(--vert_dark);
}

.border-bottom-4-hidden-vert_dark {
  border-bottom: 4px hidden var(--vert_dark);
}

.border-bottom-4-solid-bleu_turquoise_light {
  border-bottom: 4px solid var(--bleu_turquoise_light);
}

.border-bottom-4-dashed-bleu_turquoise_light {
  border-bottom: 4px dashed var(--bleu_turquoise_light);
}

.border-bottom-4-dotted-bleu_turquoise_light {
  border-bottom: 4px dotted var(--bleu_turquoise_light);
}

.border-bottom-4-double-bleu_turquoise_light {
  border-bottom: 4px double var(--bleu_turquoise_light);
}

.border-bottom-4-groove-bleu_turquoise_light {
  border-bottom: 4px groove var(--bleu_turquoise_light);
}

.border-bottom-4-ridge-bleu_turquoise_light {
  border-bottom: 4px ridge var(--bleu_turquoise_light);
}

.border-bottom-4-inset-bleu_turquoise_light {
  border-bottom: 4px inset var(--bleu_turquoise_light);
}

.border-bottom-4-outset-bleu_turquoise_light {
  border-bottom: 4px outset var(--bleu_turquoise_light);
}

.border-bottom-4-none-bleu_turquoise_light {
  border-bottom: 4px none var(--bleu_turquoise_light);
}

.border-bottom-4-hidden-bleu_turquoise_light {
  border-bottom: 4px hidden var(--bleu_turquoise_light);
}

.border-bottom-4-solid-bleu_turquoise_middle {
  border-bottom: 4px solid var(--bleu_turquoise_middle);
}

.border-bottom-4-dashed-bleu_turquoise_middle {
  border-bottom: 4px dashed var(--bleu_turquoise_middle);
}

.border-bottom-4-dotted-bleu_turquoise_middle {
  border-bottom: 4px dotted var(--bleu_turquoise_middle);
}

.border-bottom-4-double-bleu_turquoise_middle {
  border-bottom: 4px double var(--bleu_turquoise_middle);
}

.border-bottom-4-groove-bleu_turquoise_middle {
  border-bottom: 4px groove var(--bleu_turquoise_middle);
}

.border-bottom-4-ridge-bleu_turquoise_middle {
  border-bottom: 4px ridge var(--bleu_turquoise_middle);
}

.border-bottom-4-inset-bleu_turquoise_middle {
  border-bottom: 4px inset var(--bleu_turquoise_middle);
}

.border-bottom-4-outset-bleu_turquoise_middle {
  border-bottom: 4px outset var(--bleu_turquoise_middle);
}

.border-bottom-4-none-bleu_turquoise_middle {
  border-bottom: 4px none var(--bleu_turquoise_middle);
}

.border-bottom-4-hidden-bleu_turquoise_middle {
  border-bottom: 4px hidden var(--bleu_turquoise_middle);
}

.border-bottom-4-solid-bleu_turquoise_dark {
  border-bottom: 4px solid var(--bleu_turquoise_dark);
}

.border-bottom-4-dashed-bleu_turquoise_dark {
  border-bottom: 4px dashed var(--bleu_turquoise_dark);
}

.border-bottom-4-dotted-bleu_turquoise_dark {
  border-bottom: 4px dotted var(--bleu_turquoise_dark);
}

.border-bottom-4-double-bleu_turquoise_dark {
  border-bottom: 4px double var(--bleu_turquoise_dark);
}

.border-bottom-4-groove-bleu_turquoise_dark {
  border-bottom: 4px groove var(--bleu_turquoise_dark);
}

.border-bottom-4-ridge-bleu_turquoise_dark {
  border-bottom: 4px ridge var(--bleu_turquoise_dark);
}

.border-bottom-4-inset-bleu_turquoise_dark {
  border-bottom: 4px inset var(--bleu_turquoise_dark);
}

.border-bottom-4-outset-bleu_turquoise_dark {
  border-bottom: 4px outset var(--bleu_turquoise_dark);
}

.border-bottom-4-none-bleu_turquoise_dark {
  border-bottom: 4px none var(--bleu_turquoise_dark);
}

.border-bottom-4-hidden-bleu_turquoise_dark {
  border-bottom: 4px hidden var(--bleu_turquoise_dark);
}

.border-bottom-4-solid-bleu_light {
  border-bottom: 4px solid var(--bleu_light);
}

.border-bottom-4-dashed-bleu_light {
  border-bottom: 4px dashed var(--bleu_light);
}

.border-bottom-4-dotted-bleu_light {
  border-bottom: 4px dotted var(--bleu_light);
}

.border-bottom-4-double-bleu_light {
  border-bottom: 4px double var(--bleu_light);
}

.border-bottom-4-groove-bleu_light {
  border-bottom: 4px groove var(--bleu_light);
}

.border-bottom-4-ridge-bleu_light {
  border-bottom: 4px ridge var(--bleu_light);
}

.border-bottom-4-inset-bleu_light {
  border-bottom: 4px inset var(--bleu_light);
}

.border-bottom-4-outset-bleu_light {
  border-bottom: 4px outset var(--bleu_light);
}

.border-bottom-4-none-bleu_light {
  border-bottom: 4px none var(--bleu_light);
}

.border-bottom-4-hidden-bleu_light {
  border-bottom: 4px hidden var(--bleu_light);
}

.border-bottom-4-solid-bleu_dark {
  border-bottom: 4px solid var(--bleu_dark);
}

.border-bottom-4-dashed-bleu_dark {
  border-bottom: 4px dashed var(--bleu_dark);
}

.border-bottom-4-dotted-bleu_dark {
  border-bottom: 4px dotted var(--bleu_dark);
}

.border-bottom-4-double-bleu_dark {
  border-bottom: 4px double var(--bleu_dark);
}

.border-bottom-4-groove-bleu_dark {
  border-bottom: 4px groove var(--bleu_dark);
}

.border-bottom-4-ridge-bleu_dark {
  border-bottom: 4px ridge var(--bleu_dark);
}

.border-bottom-4-inset-bleu_dark {
  border-bottom: 4px inset var(--bleu_dark);
}

.border-bottom-4-outset-bleu_dark {
  border-bottom: 4px outset var(--bleu_dark);
}

.border-bottom-4-none-bleu_dark {
  border-bottom: 4px none var(--bleu_dark);
}

.border-bottom-4-hidden-bleu_dark {
  border-bottom: 4px hidden var(--bleu_dark);
}

.border-bottom-4-solid-bleu_klein_light {
  border-bottom: 4px solid var(--bleu_klein_light);
}

.border-bottom-4-dashed-bleu_klein_light {
  border-bottom: 4px dashed var(--bleu_klein_light);
}

.border-bottom-4-dotted-bleu_klein_light {
  border-bottom: 4px dotted var(--bleu_klein_light);
}

.border-bottom-4-double-bleu_klein_light {
  border-bottom: 4px double var(--bleu_klein_light);
}

.border-bottom-4-groove-bleu_klein_light {
  border-bottom: 4px groove var(--bleu_klein_light);
}

.border-bottom-4-ridge-bleu_klein_light {
  border-bottom: 4px ridge var(--bleu_klein_light);
}

.border-bottom-4-inset-bleu_klein_light {
  border-bottom: 4px inset var(--bleu_klein_light);
}

.border-bottom-4-outset-bleu_klein_light {
  border-bottom: 4px outset var(--bleu_klein_light);
}

.border-bottom-4-none-bleu_klein_light {
  border-bottom: 4px none var(--bleu_klein_light);
}

.border-bottom-4-hidden-bleu_klein_light {
  border-bottom: 4px hidden var(--bleu_klein_light);
}

.border-bottom-4-solid-bleu_klein_dark {
  border-bottom: 4px solid var(--bleu_klein_dark);
}

.border-bottom-4-dashed-bleu_klein_dark {
  border-bottom: 4px dashed var(--bleu_klein_dark);
}

.border-bottom-4-dotted-bleu_klein_dark {
  border-bottom: 4px dotted var(--bleu_klein_dark);
}

.border-bottom-4-double-bleu_klein_dark {
  border-bottom: 4px double var(--bleu_klein_dark);
}

.border-bottom-4-groove-bleu_klein_dark {
  border-bottom: 4px groove var(--bleu_klein_dark);
}

.border-bottom-4-ridge-bleu_klein_dark {
  border-bottom: 4px ridge var(--bleu_klein_dark);
}

.border-bottom-4-inset-bleu_klein_dark {
  border-bottom: 4px inset var(--bleu_klein_dark);
}

.border-bottom-4-outset-bleu_klein_dark {
  border-bottom: 4px outset var(--bleu_klein_dark);
}

.border-bottom-4-none-bleu_klein_dark {
  border-bottom: 4px none var(--bleu_klein_dark);
}

.border-bottom-4-hidden-bleu_klein_dark {
  border-bottom: 4px hidden var(--bleu_klein_dark);
}

.border-bottom-4-solid-violet_light {
  border-bottom: 4px solid var(--violet_light);
}

.border-bottom-4-dashed-violet_light {
  border-bottom: 4px dashed var(--violet_light);
}

.border-bottom-4-dotted-violet_light {
  border-bottom: 4px dotted var(--violet_light);
}

.border-bottom-4-double-violet_light {
  border-bottom: 4px double var(--violet_light);
}

.border-bottom-4-groove-violet_light {
  border-bottom: 4px groove var(--violet_light);
}

.border-bottom-4-ridge-violet_light {
  border-bottom: 4px ridge var(--violet_light);
}

.border-bottom-4-inset-violet_light {
  border-bottom: 4px inset var(--violet_light);
}

.border-bottom-4-outset-violet_light {
  border-bottom: 4px outset var(--violet_light);
}

.border-bottom-4-none-violet_light {
  border-bottom: 4px none var(--violet_light);
}

.border-bottom-4-hidden-violet_light {
  border-bottom: 4px hidden var(--violet_light);
}

.border-bottom-4-solid-violet_dark {
  border-bottom: 4px solid var(--violet_dark);
}

.border-bottom-4-dashed-violet_dark {
  border-bottom: 4px dashed var(--violet_dark);
}

.border-bottom-4-dotted-violet_dark {
  border-bottom: 4px dotted var(--violet_dark);
}

.border-bottom-4-double-violet_dark {
  border-bottom: 4px double var(--violet_dark);
}

.border-bottom-4-groove-violet_dark {
  border-bottom: 4px groove var(--violet_dark);
}

.border-bottom-4-ridge-violet_dark {
  border-bottom: 4px ridge var(--violet_dark);
}

.border-bottom-4-inset-violet_dark {
  border-bottom: 4px inset var(--violet_dark);
}

.border-bottom-4-outset-violet_dark {
  border-bottom: 4px outset var(--violet_dark);
}

.border-bottom-4-none-violet_dark {
  border-bottom: 4px none var(--violet_dark);
}

.border-bottom-4-hidden-violet_dark {
  border-bottom: 4px hidden var(--violet_dark);
}

.border-bottom-4-solid-mauve {
  border-bottom: 4px solid var(--mauve);
}

.border-bottom-4-dashed-mauve {
  border-bottom: 4px dashed var(--mauve);
}

.border-bottom-4-dotted-mauve {
  border-bottom: 4px dotted var(--mauve);
}

.border-bottom-4-double-mauve {
  border-bottom: 4px double var(--mauve);
}

.border-bottom-4-groove-mauve {
  border-bottom: 4px groove var(--mauve);
}

.border-bottom-4-ridge-mauve {
  border-bottom: 4px ridge var(--mauve);
}

.border-bottom-4-inset-mauve {
  border-bottom: 4px inset var(--mauve);
}

.border-bottom-4-outset-mauve {
  border-bottom: 4px outset var(--mauve);
}

.border-bottom-4-none-mauve {
  border-bottom: 4px none var(--mauve);
}

.border-bottom-4-hidden-mauve {
  border-bottom: 4px hidden var(--mauve);
}

.border-bottom-4-solid-rose_light {
  border-bottom: 4px solid var(--rose_light);
}

.border-bottom-4-dashed-rose_light {
  border-bottom: 4px dashed var(--rose_light);
}

.border-bottom-4-dotted-rose_light {
  border-bottom: 4px dotted var(--rose_light);
}

.border-bottom-4-double-rose_light {
  border-bottom: 4px double var(--rose_light);
}

.border-bottom-4-groove-rose_light {
  border-bottom: 4px groove var(--rose_light);
}

.border-bottom-4-ridge-rose_light {
  border-bottom: 4px ridge var(--rose_light);
}

.border-bottom-4-inset-rose_light {
  border-bottom: 4px inset var(--rose_light);
}

.border-bottom-4-outset-rose_light {
  border-bottom: 4px outset var(--rose_light);
}

.border-bottom-4-none-rose_light {
  border-bottom: 4px none var(--rose_light);
}

.border-bottom-4-hidden-rose_light {
  border-bottom: 4px hidden var(--rose_light);
}

.border-bottom-4-solid-rose_dark {
  border-bottom: 4px solid var(--rose_dark);
}

.border-bottom-4-dashed-rose_dark {
  border-bottom: 4px dashed var(--rose_dark);
}

.border-bottom-4-dotted-rose_dark {
  border-bottom: 4px dotted var(--rose_dark);
}

.border-bottom-4-double-rose_dark {
  border-bottom: 4px double var(--rose_dark);
}

.border-bottom-4-groove-rose_dark {
  border-bottom: 4px groove var(--rose_dark);
}

.border-bottom-4-ridge-rose_dark {
  border-bottom: 4px ridge var(--rose_dark);
}

.border-bottom-4-inset-rose_dark {
  border-bottom: 4px inset var(--rose_dark);
}

.border-bottom-4-outset-rose_dark {
  border-bottom: 4px outset var(--rose_dark);
}

.border-bottom-4-none-rose_dark {
  border-bottom: 4px none var(--rose_dark);
}

.border-bottom-4-hidden-rose_dark {
  border-bottom: 4px hidden var(--rose_dark);
}

.border-bottom-4-solid-rose_fushia {
  border-bottom: 4px solid var(--rose_fushia);
}

.border-bottom-4-dashed-rose_fushia {
  border-bottom: 4px dashed var(--rose_fushia);
}

.border-bottom-4-dotted-rose_fushia {
  border-bottom: 4px dotted var(--rose_fushia);
}

.border-bottom-4-double-rose_fushia {
  border-bottom: 4px double var(--rose_fushia);
}

.border-bottom-4-groove-rose_fushia {
  border-bottom: 4px groove var(--rose_fushia);
}

.border-bottom-4-ridge-rose_fushia {
  border-bottom: 4px ridge var(--rose_fushia);
}

.border-bottom-4-inset-rose_fushia {
  border-bottom: 4px inset var(--rose_fushia);
}

.border-bottom-4-outset-rose_fushia {
  border-bottom: 4px outset var(--rose_fushia);
}

.border-bottom-4-none-rose_fushia {
  border-bottom: 4px none var(--rose_fushia);
}

.border-bottom-4-hidden-rose_fushia {
  border-bottom: 4px hidden var(--rose_fushia);
}

.border-bottom-4-solid-rouge_framboise {
  border-bottom: 4px solid var(--rouge_framboise);
}

.border-bottom-4-dashed-rouge_framboise {
  border-bottom: 4px dashed var(--rouge_framboise);
}

.border-bottom-4-dotted-rouge_framboise {
  border-bottom: 4px dotted var(--rouge_framboise);
}

.border-bottom-4-double-rouge_framboise {
  border-bottom: 4px double var(--rouge_framboise);
}

.border-bottom-4-groove-rouge_framboise {
  border-bottom: 4px groove var(--rouge_framboise);
}

.border-bottom-4-ridge-rouge_framboise {
  border-bottom: 4px ridge var(--rouge_framboise);
}

.border-bottom-4-inset-rouge_framboise {
  border-bottom: 4px inset var(--rouge_framboise);
}

.border-bottom-4-outset-rouge_framboise {
  border-bottom: 4px outset var(--rouge_framboise);
}

.border-bottom-4-none-rouge_framboise {
  border-bottom: 4px none var(--rouge_framboise);
}

.border-bottom-4-hidden-rouge_framboise {
  border-bottom: 4px hidden var(--rouge_framboise);
}

.border-bottom-4-solid-rose_chair_light {
  border-bottom: 4px solid var(--rose_chair_light);
}

.border-bottom-4-dashed-rose_chair_light {
  border-bottom: 4px dashed var(--rose_chair_light);
}

.border-bottom-4-dotted-rose_chair_light {
  border-bottom: 4px dotted var(--rose_chair_light);
}

.border-bottom-4-double-rose_chair_light {
  border-bottom: 4px double var(--rose_chair_light);
}

.border-bottom-4-groove-rose_chair_light {
  border-bottom: 4px groove var(--rose_chair_light);
}

.border-bottom-4-ridge-rose_chair_light {
  border-bottom: 4px ridge var(--rose_chair_light);
}

.border-bottom-4-inset-rose_chair_light {
  border-bottom: 4px inset var(--rose_chair_light);
}

.border-bottom-4-outset-rose_chair_light {
  border-bottom: 4px outset var(--rose_chair_light);
}

.border-bottom-4-none-rose_chair_light {
  border-bottom: 4px none var(--rose_chair_light);
}

.border-bottom-4-hidden-rose_chair_light {
  border-bottom: 4px hidden var(--rose_chair_light);
}

.border-bottom-4-solid-rose_chair_dark {
  border-bottom: 4px solid var(--rose_chair_dark);
}

.border-bottom-4-dashed-rose_chair_dark {
  border-bottom: 4px dashed var(--rose_chair_dark);
}

.border-bottom-4-dotted-rose_chair_dark {
  border-bottom: 4px dotted var(--rose_chair_dark);
}

.border-bottom-4-double-rose_chair_dark {
  border-bottom: 4px double var(--rose_chair_dark);
}

.border-bottom-4-groove-rose_chair_dark {
  border-bottom: 4px groove var(--rose_chair_dark);
}

.border-bottom-4-ridge-rose_chair_dark {
  border-bottom: 4px ridge var(--rose_chair_dark);
}

.border-bottom-4-inset-rose_chair_dark {
  border-bottom: 4px inset var(--rose_chair_dark);
}

.border-bottom-4-outset-rose_chair_dark {
  border-bottom: 4px outset var(--rose_chair_dark);
}

.border-bottom-4-none-rose_chair_dark {
  border-bottom: 4px none var(--rose_chair_dark);
}

.border-bottom-4-hidden-rose_chair_dark {
  border-bottom: 4px hidden var(--rose_chair_dark);
}

.border-bottom-4-solid-vert_positif {
  border-bottom: 4px solid var(--vert_positif);
}

.border-bottom-4-dashed-vert_positif {
  border-bottom: 4px dashed var(--vert_positif);
}

.border-bottom-4-dotted-vert_positif {
  border-bottom: 4px dotted var(--vert_positif);
}

.border-bottom-4-double-vert_positif {
  border-bottom: 4px double var(--vert_positif);
}

.border-bottom-4-groove-vert_positif {
  border-bottom: 4px groove var(--vert_positif);
}

.border-bottom-4-ridge-vert_positif {
  border-bottom: 4px ridge var(--vert_positif);
}

.border-bottom-4-inset-vert_positif {
  border-bottom: 4px inset var(--vert_positif);
}

.border-bottom-4-outset-vert_positif {
  border-bottom: 4px outset var(--vert_positif);
}

.border-bottom-4-none-vert_positif {
  border-bottom: 4px none var(--vert_positif);
}

.border-bottom-4-hidden-vert_positif {
  border-bottom: 4px hidden var(--vert_positif);
}

.border-bottom-4-solid-rouge_negatif {
  border-bottom: 4px solid var(--rouge_negatif);
}

.border-bottom-4-dashed-rouge_negatif {
  border-bottom: 4px dashed var(--rouge_negatif);
}

.border-bottom-4-dotted-rouge_negatif {
  border-bottom: 4px dotted var(--rouge_negatif);
}

.border-bottom-4-double-rouge_negatif {
  border-bottom: 4px double var(--rouge_negatif);
}

.border-bottom-4-groove-rouge_negatif {
  border-bottom: 4px groove var(--rouge_negatif);
}

.border-bottom-4-ridge-rouge_negatif {
  border-bottom: 4px ridge var(--rouge_negatif);
}

.border-bottom-4-inset-rouge_negatif {
  border-bottom: 4px inset var(--rouge_negatif);
}

.border-bottom-4-outset-rouge_negatif {
  border-bottom: 4px outset var(--rouge_negatif);
}

.border-bottom-4-none-rouge_negatif {
  border-bottom: 4px none var(--rouge_negatif);
}

.border-bottom-4-hidden-rouge_negatif {
  border-bottom: 4px hidden var(--rouge_negatif);
}

.border-bottom-4-solid-bleu_texte {
  border-bottom: 4px solid var(--bleu_texte);
}

.border-bottom-4-dashed-bleu_texte {
  border-bottom: 4px dashed var(--bleu_texte);
}

.border-bottom-4-dotted-bleu_texte {
  border-bottom: 4px dotted var(--bleu_texte);
}

.border-bottom-4-double-bleu_texte {
  border-bottom: 4px double var(--bleu_texte);
}

.border-bottom-4-groove-bleu_texte {
  border-bottom: 4px groove var(--bleu_texte);
}

.border-bottom-4-ridge-bleu_texte {
  border-bottom: 4px ridge var(--bleu_texte);
}

.border-bottom-4-inset-bleu_texte {
  border-bottom: 4px inset var(--bleu_texte);
}

.border-bottom-4-outset-bleu_texte {
  border-bottom: 4px outset var(--bleu_texte);
}

.border-bottom-4-none-bleu_texte {
  border-bottom: 4px none var(--bleu_texte);
}

.border-bottom-4-hidden-bleu_texte {
  border-bottom: 4px hidden var(--bleu_texte);
}

.border-bottom-4-solid-alerte_vert_positif {
  border-bottom: 4px solid var(--alerte_vert_positif);
}

.border-bottom-4-dashed-alerte_vert_positif {
  border-bottom: 4px dashed var(--alerte_vert_positif);
}

.border-bottom-4-dotted-alerte_vert_positif {
  border-bottom: 4px dotted var(--alerte_vert_positif);
}

.border-bottom-4-double-alerte_vert_positif {
  border-bottom: 4px double var(--alerte_vert_positif);
}

.border-bottom-4-groove-alerte_vert_positif {
  border-bottom: 4px groove var(--alerte_vert_positif);
}

.border-bottom-4-ridge-alerte_vert_positif {
  border-bottom: 4px ridge var(--alerte_vert_positif);
}

.border-bottom-4-inset-alerte_vert_positif {
  border-bottom: 4px inset var(--alerte_vert_positif);
}

.border-bottom-4-outset-alerte_vert_positif {
  border-bottom: 4px outset var(--alerte_vert_positif);
}

.border-bottom-4-none-alerte_vert_positif {
  border-bottom: 4px none var(--alerte_vert_positif);
}

.border-bottom-4-hidden-alerte_vert_positif {
  border-bottom: 4px hidden var(--alerte_vert_positif);
}

.border-bottom-4-solid-alerte_rouge_negatif {
  border-bottom: 4px solid var(--alerte_rouge_negatif);
}

.border-bottom-4-dashed-alerte_rouge_negatif {
  border-bottom: 4px dashed var(--alerte_rouge_negatif);
}

.border-bottom-4-dotted-alerte_rouge_negatif {
  border-bottom: 4px dotted var(--alerte_rouge_negatif);
}

.border-bottom-4-double-alerte_rouge_negatif {
  border-bottom: 4px double var(--alerte_rouge_negatif);
}

.border-bottom-4-groove-alerte_rouge_negatif {
  border-bottom: 4px groove var(--alerte_rouge_negatif);
}

.border-bottom-4-ridge-alerte_rouge_negatif {
  border-bottom: 4px ridge var(--alerte_rouge_negatif);
}

.border-bottom-4-inset-alerte_rouge_negatif {
  border-bottom: 4px inset var(--alerte_rouge_negatif);
}

.border-bottom-4-outset-alerte_rouge_negatif {
  border-bottom: 4px outset var(--alerte_rouge_negatif);
}

.border-bottom-4-none-alerte_rouge_negatif {
  border-bottom: 4px none var(--alerte_rouge_negatif);
}

.border-bottom-4-hidden-alerte_rouge_negatif {
  border-bottom: 4px hidden var(--alerte_rouge_negatif);
}

.border-bottom-4-solid-alerte_bleu_information {
  border-bottom: 4px solid var(--alerte_bleu_information);
}

.border-bottom-4-dashed-alerte_bleu_information {
  border-bottom: 4px dashed var(--alerte_bleu_information);
}

.border-bottom-4-dotted-alerte_bleu_information {
  border-bottom: 4px dotted var(--alerte_bleu_information);
}

.border-bottom-4-double-alerte_bleu_information {
  border-bottom: 4px double var(--alerte_bleu_information);
}

.border-bottom-4-groove-alerte_bleu_information {
  border-bottom: 4px groove var(--alerte_bleu_information);
}

.border-bottom-4-ridge-alerte_bleu_information {
  border-bottom: 4px ridge var(--alerte_bleu_information);
}

.border-bottom-4-inset-alerte_bleu_information {
  border-bottom: 4px inset var(--alerte_bleu_information);
}

.border-bottom-4-outset-alerte_bleu_information {
  border-bottom: 4px outset var(--alerte_bleu_information);
}

.border-bottom-4-none-alerte_bleu_information {
  border-bottom: 4px none var(--alerte_bleu_information);
}

.border-bottom-4-hidden-alerte_bleu_information {
  border-bottom: 4px hidden var(--alerte_bleu_information);
}

.border-bottom-4-solid-alerte_jaune_attention {
  border-bottom: 4px solid var(--alerte_jaune_attention);
}

.border-bottom-4-dashed-alerte_jaune_attention {
  border-bottom: 4px dashed var(--alerte_jaune_attention);
}

.border-bottom-4-dotted-alerte_jaune_attention {
  border-bottom: 4px dotted var(--alerte_jaune_attention);
}

.border-bottom-4-double-alerte_jaune_attention {
  border-bottom: 4px double var(--alerte_jaune_attention);
}

.border-bottom-4-groove-alerte_jaune_attention {
  border-bottom: 4px groove var(--alerte_jaune_attention);
}

.border-bottom-4-ridge-alerte_jaune_attention {
  border-bottom: 4px ridge var(--alerte_jaune_attention);
}

.border-bottom-4-inset-alerte_jaune_attention {
  border-bottom: 4px inset var(--alerte_jaune_attention);
}

.border-bottom-4-outset-alerte_jaune_attention {
  border-bottom: 4px outset var(--alerte_jaune_attention);
}

.border-bottom-4-none-alerte_jaune_attention {
  border-bottom: 4px none var(--alerte_jaune_attention);
}

.border-bottom-4-hidden-alerte_jaune_attention {
  border-bottom: 4px hidden var(--alerte_jaune_attention);
}

.border-bottom-4-solid-blanc {
  border-bottom: 4px solid #fff;
}

.border-bottom-4-dashed-blanc {
  border-bottom: 4px dashed #fff;
}

.border-bottom-4-dotted-blanc {
  border-bottom: 4px dotted #fff;
}

.border-bottom-4-double-blanc {
  border-bottom: 4px double #fff;
}

.border-bottom-4-groove-blanc {
  border-bottom: 4px groove #fff;
}

.border-bottom-4-ridge-blanc {
  border-bottom: 4px ridge #fff;
}

.border-bottom-4-inset-blanc {
  border-bottom: 4px inset #fff;
}

.border-bottom-4-outset-blanc {
  border-bottom: 4px outset #fff;
}

.border-bottom-4-none-blanc {
  border-bottom: 4px #fff;
}

.border-bottom-4-hidden-blanc {
  border-bottom: 4px hidden #fff;
}

.border-bottom-4-solid-noir {
  border-bottom: 4px solid #222;
}

.border-bottom-4-dashed-noir {
  border-bottom: 4px dashed #222;
}

.border-bottom-4-dotted-noir {
  border-bottom: 4px dotted #222;
}

.border-bottom-4-double-noir {
  border-bottom: 4px double #222;
}

.border-bottom-4-groove-noir {
  border-bottom: 4px groove #222;
}

.border-bottom-4-ridge-noir {
  border-bottom: 4px ridge #222;
}

.border-bottom-4-inset-noir {
  border-bottom: 4px inset #222;
}

.border-bottom-4-outset-noir {
  border-bottom: 4px outset #222;
}

.border-bottom-4-none-noir {
  border-bottom: 4px #222;
}

.border-bottom-4-hidden-noir {
  border-bottom: 4px hidden #222;
}

.border-bottom-4-solid-gris_light {
  border-bottom: 4px solid var(--gris_light);
}

.border-bottom-4-dashed-gris_light {
  border-bottom: 4px dashed var(--gris_light);
}

.border-bottom-4-dotted-gris_light {
  border-bottom: 4px dotted var(--gris_light);
}

.border-bottom-4-double-gris_light {
  border-bottom: 4px double var(--gris_light);
}

.border-bottom-4-groove-gris_light {
  border-bottom: 4px groove var(--gris_light);
}

.border-bottom-4-ridge-gris_light {
  border-bottom: 4px ridge var(--gris_light);
}

.border-bottom-4-inset-gris_light {
  border-bottom: 4px inset var(--gris_light);
}

.border-bottom-4-outset-gris_light {
  border-bottom: 4px outset var(--gris_light);
}

.border-bottom-4-none-gris_light {
  border-bottom: 4px none var(--gris_light);
}

.border-bottom-4-hidden-gris_light {
  border-bottom: 4px hidden var(--gris_light);
}

.border-bottom-4-solid-gris_dark {
  border-bottom: 4px solid var(--gris_dark);
}

.border-bottom-4-dashed-gris_dark {
  border-bottom: 4px dashed var(--gris_dark);
}

.border-bottom-4-dotted-gris_dark {
  border-bottom: 4px dotted var(--gris_dark);
}

.border-bottom-4-double-gris_dark {
  border-bottom: 4px double var(--gris_dark);
}

.border-bottom-4-groove-gris_dark {
  border-bottom: 4px groove var(--gris_dark);
}

.border-bottom-4-ridge-gris_dark {
  border-bottom: 4px ridge var(--gris_dark);
}

.border-bottom-4-inset-gris_dark {
  border-bottom: 4px inset var(--gris_dark);
}

.border-bottom-4-outset-gris_dark {
  border-bottom: 4px outset var(--gris_dark);
}

.border-bottom-4-none-gris_dark {
  border-bottom: 4px none var(--gris_dark);
}

.border-bottom-4-hidden-gris_dark {
  border-bottom: 4px hidden var(--gris_dark);
}

.border-bottom-4-solid-gris_background {
  border-bottom: 4px solid var(--gris_background);
}

.border-bottom-4-dashed-gris_background {
  border-bottom: 4px dashed var(--gris_background);
}

.border-bottom-4-dotted-gris_background {
  border-bottom: 4px dotted var(--gris_background);
}

.border-bottom-4-double-gris_background {
  border-bottom: 4px double var(--gris_background);
}

.border-bottom-4-groove-gris_background {
  border-bottom: 4px groove var(--gris_background);
}

.border-bottom-4-ridge-gris_background {
  border-bottom: 4px ridge var(--gris_background);
}

.border-bottom-4-inset-gris_background {
  border-bottom: 4px inset var(--gris_background);
}

.border-bottom-4-outset-gris_background {
  border-bottom: 4px outset var(--gris_background);
}

.border-bottom-4-none-gris_background {
  border-bottom: 4px none var(--gris_background);
}

.border-bottom-4-hidden-gris_background {
  border-bottom: 4px hidden var(--gris_background);
}

.border-right-4-solid-corail_mh_brand {
  border-right: 4px solid var(--corail_mh_brand);
}

.border-right-4-dashed-corail_mh_brand {
  border-right: 4px dashed var(--corail_mh_brand);
}

.border-right-4-dotted-corail_mh_brand {
  border-right: 4px dotted var(--corail_mh_brand);
}

.border-right-4-double-corail_mh_brand {
  border-right: 4px double var(--corail_mh_brand);
}

.border-right-4-groove-corail_mh_brand {
  border-right: 4px groove var(--corail_mh_brand);
}

.border-right-4-ridge-corail_mh_brand {
  border-right: 4px ridge var(--corail_mh_brand);
}

.border-right-4-inset-corail_mh_brand {
  border-right: 4px inset var(--corail_mh_brand);
}

.border-right-4-outset-corail_mh_brand {
  border-right: 4px outset var(--corail_mh_brand);
}

.border-right-4-none-corail_mh_brand {
  border-right: 4px none var(--corail_mh_brand);
}

.border-right-4-hidden-corail_mh_brand {
  border-right: 4px hidden var(--corail_mh_brand);
}

.border-right-4-solid-corail_mh_dark {
  border-right: 4px solid var(--corail_mh_dark);
}

.border-right-4-dashed-corail_mh_dark {
  border-right: 4px dashed var(--corail_mh_dark);
}

.border-right-4-dotted-corail_mh_dark {
  border-right: 4px dotted var(--corail_mh_dark);
}

.border-right-4-double-corail_mh_dark {
  border-right: 4px double var(--corail_mh_dark);
}

.border-right-4-groove-corail_mh_dark {
  border-right: 4px groove var(--corail_mh_dark);
}

.border-right-4-ridge-corail_mh_dark {
  border-right: 4px ridge var(--corail_mh_dark);
}

.border-right-4-inset-corail_mh_dark {
  border-right: 4px inset var(--corail_mh_dark);
}

.border-right-4-outset-corail_mh_dark {
  border-right: 4px outset var(--corail_mh_dark);
}

.border-right-4-none-corail_mh_dark {
  border-right: 4px none var(--corail_mh_dark);
}

.border-right-4-hidden-corail_mh_dark {
  border-right: 4px hidden var(--corail_mh_dark);
}

.border-right-4-solid-corail_mh_light {
  border-right: 4px solid var(--corail_mh_light);
}

.border-right-4-dashed-corail_mh_light {
  border-right: 4px dashed var(--corail_mh_light);
}

.border-right-4-dotted-corail_mh_light {
  border-right: 4px dotted var(--corail_mh_light);
}

.border-right-4-double-corail_mh_light {
  border-right: 4px double var(--corail_mh_light);
}

.border-right-4-groove-corail_mh_light {
  border-right: 4px groove var(--corail_mh_light);
}

.border-right-4-ridge-corail_mh_light {
  border-right: 4px ridge var(--corail_mh_light);
}

.border-right-4-inset-corail_mh_light {
  border-right: 4px inset var(--corail_mh_light);
}

.border-right-4-outset-corail_mh_light {
  border-right: 4px outset var(--corail_mh_light);
}

.border-right-4-none-corail_mh_light {
  border-right: 4px none var(--corail_mh_light);
}

.border-right-4-hidden-corail_mh_light {
  border-right: 4px hidden var(--corail_mh_light);
}

.border-right-4-solid-gris_sable_light {
  border-right: 4px solid var(--gris_sable_light);
}

.border-right-4-dashed-gris_sable_light {
  border-right: 4px dashed var(--gris_sable_light);
}

.border-right-4-dotted-gris_sable_light {
  border-right: 4px dotted var(--gris_sable_light);
}

.border-right-4-double-gris_sable_light {
  border-right: 4px double var(--gris_sable_light);
}

.border-right-4-groove-gris_sable_light {
  border-right: 4px groove var(--gris_sable_light);
}

.border-right-4-ridge-gris_sable_light {
  border-right: 4px ridge var(--gris_sable_light);
}

.border-right-4-inset-gris_sable_light {
  border-right: 4px inset var(--gris_sable_light);
}

.border-right-4-outset-gris_sable_light {
  border-right: 4px outset var(--gris_sable_light);
}

.border-right-4-none-gris_sable_light {
  border-right: 4px none var(--gris_sable_light);
}

.border-right-4-hidden-gris_sable_light {
  border-right: 4px hidden var(--gris_sable_light);
}

.border-right-4-solid-gris_sable_dark {
  border-right: 4px solid var(--gris_sable_dark);
}

.border-right-4-dashed-gris_sable_dark {
  border-right: 4px dashed var(--gris_sable_dark);
}

.border-right-4-dotted-gris_sable_dark {
  border-right: 4px dotted var(--gris_sable_dark);
}

.border-right-4-double-gris_sable_dark {
  border-right: 4px double var(--gris_sable_dark);
}

.border-right-4-groove-gris_sable_dark {
  border-right: 4px groove var(--gris_sable_dark);
}

.border-right-4-ridge-gris_sable_dark {
  border-right: 4px ridge var(--gris_sable_dark);
}

.border-right-4-inset-gris_sable_dark {
  border-right: 4px inset var(--gris_sable_dark);
}

.border-right-4-outset-gris_sable_dark {
  border-right: 4px outset var(--gris_sable_dark);
}

.border-right-4-none-gris_sable_dark {
  border-right: 4px none var(--gris_sable_dark);
}

.border-right-4-hidden-gris_sable_dark {
  border-right: 4px hidden var(--gris_sable_dark);
}

.border-right-4-solid-jaune_ivoire_light {
  border-right: 4px solid var(--jaune_ivoire_light);
}

.border-right-4-dashed-jaune_ivoire_light {
  border-right: 4px dashed var(--jaune_ivoire_light);
}

.border-right-4-dotted-jaune_ivoire_light {
  border-right: 4px dotted var(--jaune_ivoire_light);
}

.border-right-4-double-jaune_ivoire_light {
  border-right: 4px double var(--jaune_ivoire_light);
}

.border-right-4-groove-jaune_ivoire_light {
  border-right: 4px groove var(--jaune_ivoire_light);
}

.border-right-4-ridge-jaune_ivoire_light {
  border-right: 4px ridge var(--jaune_ivoire_light);
}

.border-right-4-inset-jaune_ivoire_light {
  border-right: 4px inset var(--jaune_ivoire_light);
}

.border-right-4-outset-jaune_ivoire_light {
  border-right: 4px outset var(--jaune_ivoire_light);
}

.border-right-4-none-jaune_ivoire_light {
  border-right: 4px none var(--jaune_ivoire_light);
}

.border-right-4-hidden-jaune_ivoire_light {
  border-right: 4px hidden var(--jaune_ivoire_light);
}

.border-right-4-solid-jaune_ivoire_dark {
  border-right: 4px solid var(--jaune_ivoire_dark);
}

.border-right-4-dashed-jaune_ivoire_dark {
  border-right: 4px dashed var(--jaune_ivoire_dark);
}

.border-right-4-dotted-jaune_ivoire_dark {
  border-right: 4px dotted var(--jaune_ivoire_dark);
}

.border-right-4-double-jaune_ivoire_dark {
  border-right: 4px double var(--jaune_ivoire_dark);
}

.border-right-4-groove-jaune_ivoire_dark {
  border-right: 4px groove var(--jaune_ivoire_dark);
}

.border-right-4-ridge-jaune_ivoire_dark {
  border-right: 4px ridge var(--jaune_ivoire_dark);
}

.border-right-4-inset-jaune_ivoire_dark {
  border-right: 4px inset var(--jaune_ivoire_dark);
}

.border-right-4-outset-jaune_ivoire_dark {
  border-right: 4px outset var(--jaune_ivoire_dark);
}

.border-right-4-none-jaune_ivoire_dark {
  border-right: 4px none var(--jaune_ivoire_dark);
}

.border-right-4-hidden-jaune_ivoire_dark {
  border-right: 4px hidden var(--jaune_ivoire_dark);
}

.border-right-4-solid-jaune_light {
  border-right: 4px solid var(--jaune_light);
}

.border-right-4-dashed-jaune_light {
  border-right: 4px dashed var(--jaune_light);
}

.border-right-4-dotted-jaune_light {
  border-right: 4px dotted var(--jaune_light);
}

.border-right-4-double-jaune_light {
  border-right: 4px double var(--jaune_light);
}

.border-right-4-groove-jaune_light {
  border-right: 4px groove var(--jaune_light);
}

.border-right-4-ridge-jaune_light {
  border-right: 4px ridge var(--jaune_light);
}

.border-right-4-inset-jaune_light {
  border-right: 4px inset var(--jaune_light);
}

.border-right-4-outset-jaune_light {
  border-right: 4px outset var(--jaune_light);
}

.border-right-4-none-jaune_light {
  border-right: 4px none var(--jaune_light);
}

.border-right-4-hidden-jaune_light {
  border-right: 4px hidden var(--jaune_light);
}

.border-right-4-solid-jaune_dark {
  border-right: 4px solid var(--jaune_dark);
}

.border-right-4-dashed-jaune_dark {
  border-right: 4px dashed var(--jaune_dark);
}

.border-right-4-dotted-jaune_dark {
  border-right: 4px dotted var(--jaune_dark);
}

.border-right-4-double-jaune_dark {
  border-right: 4px double var(--jaune_dark);
}

.border-right-4-groove-jaune_dark {
  border-right: 4px groove var(--jaune_dark);
}

.border-right-4-ridge-jaune_dark {
  border-right: 4px ridge var(--jaune_dark);
}

.border-right-4-inset-jaune_dark {
  border-right: 4px inset var(--jaune_dark);
}

.border-right-4-outset-jaune_dark {
  border-right: 4px outset var(--jaune_dark);
}

.border-right-4-none-jaune_dark {
  border-right: 4px none var(--jaune_dark);
}

.border-right-4-hidden-jaune_dark {
  border-right: 4px hidden var(--jaune_dark);
}

.border-right-4-solid-orange_normal {
  border-right: 4px solid var(--orange_normal);
}

.border-right-4-dashed-orange_normal {
  border-right: 4px dashed var(--orange_normal);
}

.border-right-4-dotted-orange_normal {
  border-right: 4px dotted var(--orange_normal);
}

.border-right-4-double-orange_normal {
  border-right: 4px double var(--orange_normal);
}

.border-right-4-groove-orange_normal {
  border-right: 4px groove var(--orange_normal);
}

.border-right-4-ridge-orange_normal {
  border-right: 4px ridge var(--orange_normal);
}

.border-right-4-inset-orange_normal {
  border-right: 4px inset var(--orange_normal);
}

.border-right-4-outset-orange_normal {
  border-right: 4px outset var(--orange_normal);
}

.border-right-4-none-orange_normal {
  border-right: 4px none var(--orange_normal);
}

.border-right-4-hidden-orange_normal {
  border-right: 4px hidden var(--orange_normal);
}

.border-right-4-solid-orange_dark {
  border-right: 4px solid var(--orange_dark);
}

.border-right-4-dashed-orange_dark {
  border-right: 4px dashed var(--orange_dark);
}

.border-right-4-dotted-orange_dark {
  border-right: 4px dotted var(--orange_dark);
}

.border-right-4-double-orange_dark {
  border-right: 4px double var(--orange_dark);
}

.border-right-4-groove-orange_dark {
  border-right: 4px groove var(--orange_dark);
}

.border-right-4-ridge-orange_dark {
  border-right: 4px ridge var(--orange_dark);
}

.border-right-4-inset-orange_dark {
  border-right: 4px inset var(--orange_dark);
}

.border-right-4-outset-orange_dark {
  border-right: 4px outset var(--orange_dark);
}

.border-right-4-none-orange_dark {
  border-right: 4px none var(--orange_dark);
}

.border-right-4-hidden-orange_dark {
  border-right: 4px hidden var(--orange_dark);
}

.border-right-4-solid-vert_light {
  border-right: 4px solid var(--vert_light);
}

.border-right-4-dashed-vert_light {
  border-right: 4px dashed var(--vert_light);
}

.border-right-4-dotted-vert_light {
  border-right: 4px dotted var(--vert_light);
}

.border-right-4-double-vert_light {
  border-right: 4px double var(--vert_light);
}

.border-right-4-groove-vert_light {
  border-right: 4px groove var(--vert_light);
}

.border-right-4-ridge-vert_light {
  border-right: 4px ridge var(--vert_light);
}

.border-right-4-inset-vert_light {
  border-right: 4px inset var(--vert_light);
}

.border-right-4-outset-vert_light {
  border-right: 4px outset var(--vert_light);
}

.border-right-4-none-vert_light {
  border-right: 4px none var(--vert_light);
}

.border-right-4-hidden-vert_light {
  border-right: 4px hidden var(--vert_light);
}

.border-right-4-solid-vert_dark {
  border-right: 4px solid var(--vert_dark);
}

.border-right-4-dashed-vert_dark {
  border-right: 4px dashed var(--vert_dark);
}

.border-right-4-dotted-vert_dark {
  border-right: 4px dotted var(--vert_dark);
}

.border-right-4-double-vert_dark {
  border-right: 4px double var(--vert_dark);
}

.border-right-4-groove-vert_dark {
  border-right: 4px groove var(--vert_dark);
}

.border-right-4-ridge-vert_dark {
  border-right: 4px ridge var(--vert_dark);
}

.border-right-4-inset-vert_dark {
  border-right: 4px inset var(--vert_dark);
}

.border-right-4-outset-vert_dark {
  border-right: 4px outset var(--vert_dark);
}

.border-right-4-none-vert_dark {
  border-right: 4px none var(--vert_dark);
}

.border-right-4-hidden-vert_dark {
  border-right: 4px hidden var(--vert_dark);
}

.border-right-4-solid-bleu_turquoise_light {
  border-right: 4px solid var(--bleu_turquoise_light);
}

.border-right-4-dashed-bleu_turquoise_light {
  border-right: 4px dashed var(--bleu_turquoise_light);
}

.border-right-4-dotted-bleu_turquoise_light {
  border-right: 4px dotted var(--bleu_turquoise_light);
}

.border-right-4-double-bleu_turquoise_light {
  border-right: 4px double var(--bleu_turquoise_light);
}

.border-right-4-groove-bleu_turquoise_light {
  border-right: 4px groove var(--bleu_turquoise_light);
}

.border-right-4-ridge-bleu_turquoise_light {
  border-right: 4px ridge var(--bleu_turquoise_light);
}

.border-right-4-inset-bleu_turquoise_light {
  border-right: 4px inset var(--bleu_turquoise_light);
}

.border-right-4-outset-bleu_turquoise_light {
  border-right: 4px outset var(--bleu_turquoise_light);
}

.border-right-4-none-bleu_turquoise_light {
  border-right: 4px none var(--bleu_turquoise_light);
}

.border-right-4-hidden-bleu_turquoise_light {
  border-right: 4px hidden var(--bleu_turquoise_light);
}

.border-right-4-solid-bleu_turquoise_middle {
  border-right: 4px solid var(--bleu_turquoise_middle);
}

.border-right-4-dashed-bleu_turquoise_middle {
  border-right: 4px dashed var(--bleu_turquoise_middle);
}

.border-right-4-dotted-bleu_turquoise_middle {
  border-right: 4px dotted var(--bleu_turquoise_middle);
}

.border-right-4-double-bleu_turquoise_middle {
  border-right: 4px double var(--bleu_turquoise_middle);
}

.border-right-4-groove-bleu_turquoise_middle {
  border-right: 4px groove var(--bleu_turquoise_middle);
}

.border-right-4-ridge-bleu_turquoise_middle {
  border-right: 4px ridge var(--bleu_turquoise_middle);
}

.border-right-4-inset-bleu_turquoise_middle {
  border-right: 4px inset var(--bleu_turquoise_middle);
}

.border-right-4-outset-bleu_turquoise_middle {
  border-right: 4px outset var(--bleu_turquoise_middle);
}

.border-right-4-none-bleu_turquoise_middle {
  border-right: 4px none var(--bleu_turquoise_middle);
}

.border-right-4-hidden-bleu_turquoise_middle {
  border-right: 4px hidden var(--bleu_turquoise_middle);
}

.border-right-4-solid-bleu_turquoise_dark {
  border-right: 4px solid var(--bleu_turquoise_dark);
}

.border-right-4-dashed-bleu_turquoise_dark {
  border-right: 4px dashed var(--bleu_turquoise_dark);
}

.border-right-4-dotted-bleu_turquoise_dark {
  border-right: 4px dotted var(--bleu_turquoise_dark);
}

.border-right-4-double-bleu_turquoise_dark {
  border-right: 4px double var(--bleu_turquoise_dark);
}

.border-right-4-groove-bleu_turquoise_dark {
  border-right: 4px groove var(--bleu_turquoise_dark);
}

.border-right-4-ridge-bleu_turquoise_dark {
  border-right: 4px ridge var(--bleu_turquoise_dark);
}

.border-right-4-inset-bleu_turquoise_dark {
  border-right: 4px inset var(--bleu_turquoise_dark);
}

.border-right-4-outset-bleu_turquoise_dark {
  border-right: 4px outset var(--bleu_turquoise_dark);
}

.border-right-4-none-bleu_turquoise_dark {
  border-right: 4px none var(--bleu_turquoise_dark);
}

.border-right-4-hidden-bleu_turquoise_dark {
  border-right: 4px hidden var(--bleu_turquoise_dark);
}

.border-right-4-solid-bleu_light {
  border-right: 4px solid var(--bleu_light);
}

.border-right-4-dashed-bleu_light {
  border-right: 4px dashed var(--bleu_light);
}

.border-right-4-dotted-bleu_light {
  border-right: 4px dotted var(--bleu_light);
}

.border-right-4-double-bleu_light {
  border-right: 4px double var(--bleu_light);
}

.border-right-4-groove-bleu_light {
  border-right: 4px groove var(--bleu_light);
}

.border-right-4-ridge-bleu_light {
  border-right: 4px ridge var(--bleu_light);
}

.border-right-4-inset-bleu_light {
  border-right: 4px inset var(--bleu_light);
}

.border-right-4-outset-bleu_light {
  border-right: 4px outset var(--bleu_light);
}

.border-right-4-none-bleu_light {
  border-right: 4px none var(--bleu_light);
}

.border-right-4-hidden-bleu_light {
  border-right: 4px hidden var(--bleu_light);
}

.border-right-4-solid-bleu_dark {
  border-right: 4px solid var(--bleu_dark);
}

.border-right-4-dashed-bleu_dark {
  border-right: 4px dashed var(--bleu_dark);
}

.border-right-4-dotted-bleu_dark {
  border-right: 4px dotted var(--bleu_dark);
}

.border-right-4-double-bleu_dark {
  border-right: 4px double var(--bleu_dark);
}

.border-right-4-groove-bleu_dark {
  border-right: 4px groove var(--bleu_dark);
}

.border-right-4-ridge-bleu_dark {
  border-right: 4px ridge var(--bleu_dark);
}

.border-right-4-inset-bleu_dark {
  border-right: 4px inset var(--bleu_dark);
}

.border-right-4-outset-bleu_dark {
  border-right: 4px outset var(--bleu_dark);
}

.border-right-4-none-bleu_dark {
  border-right: 4px none var(--bleu_dark);
}

.border-right-4-hidden-bleu_dark {
  border-right: 4px hidden var(--bleu_dark);
}

.border-right-4-solid-bleu_klein_light {
  border-right: 4px solid var(--bleu_klein_light);
}

.border-right-4-dashed-bleu_klein_light {
  border-right: 4px dashed var(--bleu_klein_light);
}

.border-right-4-dotted-bleu_klein_light {
  border-right: 4px dotted var(--bleu_klein_light);
}

.border-right-4-double-bleu_klein_light {
  border-right: 4px double var(--bleu_klein_light);
}

.border-right-4-groove-bleu_klein_light {
  border-right: 4px groove var(--bleu_klein_light);
}

.border-right-4-ridge-bleu_klein_light {
  border-right: 4px ridge var(--bleu_klein_light);
}

.border-right-4-inset-bleu_klein_light {
  border-right: 4px inset var(--bleu_klein_light);
}

.border-right-4-outset-bleu_klein_light {
  border-right: 4px outset var(--bleu_klein_light);
}

.border-right-4-none-bleu_klein_light {
  border-right: 4px none var(--bleu_klein_light);
}

.border-right-4-hidden-bleu_klein_light {
  border-right: 4px hidden var(--bleu_klein_light);
}

.border-right-4-solid-bleu_klein_dark {
  border-right: 4px solid var(--bleu_klein_dark);
}

.border-right-4-dashed-bleu_klein_dark {
  border-right: 4px dashed var(--bleu_klein_dark);
}

.border-right-4-dotted-bleu_klein_dark {
  border-right: 4px dotted var(--bleu_klein_dark);
}

.border-right-4-double-bleu_klein_dark {
  border-right: 4px double var(--bleu_klein_dark);
}

.border-right-4-groove-bleu_klein_dark {
  border-right: 4px groove var(--bleu_klein_dark);
}

.border-right-4-ridge-bleu_klein_dark {
  border-right: 4px ridge var(--bleu_klein_dark);
}

.border-right-4-inset-bleu_klein_dark {
  border-right: 4px inset var(--bleu_klein_dark);
}

.border-right-4-outset-bleu_klein_dark {
  border-right: 4px outset var(--bleu_klein_dark);
}

.border-right-4-none-bleu_klein_dark {
  border-right: 4px none var(--bleu_klein_dark);
}

.border-right-4-hidden-bleu_klein_dark {
  border-right: 4px hidden var(--bleu_klein_dark);
}

.border-right-4-solid-violet_light {
  border-right: 4px solid var(--violet_light);
}

.border-right-4-dashed-violet_light {
  border-right: 4px dashed var(--violet_light);
}

.border-right-4-dotted-violet_light {
  border-right: 4px dotted var(--violet_light);
}

.border-right-4-double-violet_light {
  border-right: 4px double var(--violet_light);
}

.border-right-4-groove-violet_light {
  border-right: 4px groove var(--violet_light);
}

.border-right-4-ridge-violet_light {
  border-right: 4px ridge var(--violet_light);
}

.border-right-4-inset-violet_light {
  border-right: 4px inset var(--violet_light);
}

.border-right-4-outset-violet_light {
  border-right: 4px outset var(--violet_light);
}

.border-right-4-none-violet_light {
  border-right: 4px none var(--violet_light);
}

.border-right-4-hidden-violet_light {
  border-right: 4px hidden var(--violet_light);
}

.border-right-4-solid-violet_dark {
  border-right: 4px solid var(--violet_dark);
}

.border-right-4-dashed-violet_dark {
  border-right: 4px dashed var(--violet_dark);
}

.border-right-4-dotted-violet_dark {
  border-right: 4px dotted var(--violet_dark);
}

.border-right-4-double-violet_dark {
  border-right: 4px double var(--violet_dark);
}

.border-right-4-groove-violet_dark {
  border-right: 4px groove var(--violet_dark);
}

.border-right-4-ridge-violet_dark {
  border-right: 4px ridge var(--violet_dark);
}

.border-right-4-inset-violet_dark {
  border-right: 4px inset var(--violet_dark);
}

.border-right-4-outset-violet_dark {
  border-right: 4px outset var(--violet_dark);
}

.border-right-4-none-violet_dark {
  border-right: 4px none var(--violet_dark);
}

.border-right-4-hidden-violet_dark {
  border-right: 4px hidden var(--violet_dark);
}

.border-right-4-solid-mauve {
  border-right: 4px solid var(--mauve);
}

.border-right-4-dashed-mauve {
  border-right: 4px dashed var(--mauve);
}

.border-right-4-dotted-mauve {
  border-right: 4px dotted var(--mauve);
}

.border-right-4-double-mauve {
  border-right: 4px double var(--mauve);
}

.border-right-4-groove-mauve {
  border-right: 4px groove var(--mauve);
}

.border-right-4-ridge-mauve {
  border-right: 4px ridge var(--mauve);
}

.border-right-4-inset-mauve {
  border-right: 4px inset var(--mauve);
}

.border-right-4-outset-mauve {
  border-right: 4px outset var(--mauve);
}

.border-right-4-none-mauve {
  border-right: 4px none var(--mauve);
}

.border-right-4-hidden-mauve {
  border-right: 4px hidden var(--mauve);
}

.border-right-4-solid-rose_light {
  border-right: 4px solid var(--rose_light);
}

.border-right-4-dashed-rose_light {
  border-right: 4px dashed var(--rose_light);
}

.border-right-4-dotted-rose_light {
  border-right: 4px dotted var(--rose_light);
}

.border-right-4-double-rose_light {
  border-right: 4px double var(--rose_light);
}

.border-right-4-groove-rose_light {
  border-right: 4px groove var(--rose_light);
}

.border-right-4-ridge-rose_light {
  border-right: 4px ridge var(--rose_light);
}

.border-right-4-inset-rose_light {
  border-right: 4px inset var(--rose_light);
}

.border-right-4-outset-rose_light {
  border-right: 4px outset var(--rose_light);
}

.border-right-4-none-rose_light {
  border-right: 4px none var(--rose_light);
}

.border-right-4-hidden-rose_light {
  border-right: 4px hidden var(--rose_light);
}

.border-right-4-solid-rose_dark {
  border-right: 4px solid var(--rose_dark);
}

.border-right-4-dashed-rose_dark {
  border-right: 4px dashed var(--rose_dark);
}

.border-right-4-dotted-rose_dark {
  border-right: 4px dotted var(--rose_dark);
}

.border-right-4-double-rose_dark {
  border-right: 4px double var(--rose_dark);
}

.border-right-4-groove-rose_dark {
  border-right: 4px groove var(--rose_dark);
}

.border-right-4-ridge-rose_dark {
  border-right: 4px ridge var(--rose_dark);
}

.border-right-4-inset-rose_dark {
  border-right: 4px inset var(--rose_dark);
}

.border-right-4-outset-rose_dark {
  border-right: 4px outset var(--rose_dark);
}

.border-right-4-none-rose_dark {
  border-right: 4px none var(--rose_dark);
}

.border-right-4-hidden-rose_dark {
  border-right: 4px hidden var(--rose_dark);
}

.border-right-4-solid-rose_fushia {
  border-right: 4px solid var(--rose_fushia);
}

.border-right-4-dashed-rose_fushia {
  border-right: 4px dashed var(--rose_fushia);
}

.border-right-4-dotted-rose_fushia {
  border-right: 4px dotted var(--rose_fushia);
}

.border-right-4-double-rose_fushia {
  border-right: 4px double var(--rose_fushia);
}

.border-right-4-groove-rose_fushia {
  border-right: 4px groove var(--rose_fushia);
}

.border-right-4-ridge-rose_fushia {
  border-right: 4px ridge var(--rose_fushia);
}

.border-right-4-inset-rose_fushia {
  border-right: 4px inset var(--rose_fushia);
}

.border-right-4-outset-rose_fushia {
  border-right: 4px outset var(--rose_fushia);
}

.border-right-4-none-rose_fushia {
  border-right: 4px none var(--rose_fushia);
}

.border-right-4-hidden-rose_fushia {
  border-right: 4px hidden var(--rose_fushia);
}

.border-right-4-solid-rouge_framboise {
  border-right: 4px solid var(--rouge_framboise);
}

.border-right-4-dashed-rouge_framboise {
  border-right: 4px dashed var(--rouge_framboise);
}

.border-right-4-dotted-rouge_framboise {
  border-right: 4px dotted var(--rouge_framboise);
}

.border-right-4-double-rouge_framboise {
  border-right: 4px double var(--rouge_framboise);
}

.border-right-4-groove-rouge_framboise {
  border-right: 4px groove var(--rouge_framboise);
}

.border-right-4-ridge-rouge_framboise {
  border-right: 4px ridge var(--rouge_framboise);
}

.border-right-4-inset-rouge_framboise {
  border-right: 4px inset var(--rouge_framboise);
}

.border-right-4-outset-rouge_framboise {
  border-right: 4px outset var(--rouge_framboise);
}

.border-right-4-none-rouge_framboise {
  border-right: 4px none var(--rouge_framboise);
}

.border-right-4-hidden-rouge_framboise {
  border-right: 4px hidden var(--rouge_framboise);
}

.border-right-4-solid-rose_chair_light {
  border-right: 4px solid var(--rose_chair_light);
}

.border-right-4-dashed-rose_chair_light {
  border-right: 4px dashed var(--rose_chair_light);
}

.border-right-4-dotted-rose_chair_light {
  border-right: 4px dotted var(--rose_chair_light);
}

.border-right-4-double-rose_chair_light {
  border-right: 4px double var(--rose_chair_light);
}

.border-right-4-groove-rose_chair_light {
  border-right: 4px groove var(--rose_chair_light);
}

.border-right-4-ridge-rose_chair_light {
  border-right: 4px ridge var(--rose_chair_light);
}

.border-right-4-inset-rose_chair_light {
  border-right: 4px inset var(--rose_chair_light);
}

.border-right-4-outset-rose_chair_light {
  border-right: 4px outset var(--rose_chair_light);
}

.border-right-4-none-rose_chair_light {
  border-right: 4px none var(--rose_chair_light);
}

.border-right-4-hidden-rose_chair_light {
  border-right: 4px hidden var(--rose_chair_light);
}

.border-right-4-solid-rose_chair_dark {
  border-right: 4px solid var(--rose_chair_dark);
}

.border-right-4-dashed-rose_chair_dark {
  border-right: 4px dashed var(--rose_chair_dark);
}

.border-right-4-dotted-rose_chair_dark {
  border-right: 4px dotted var(--rose_chair_dark);
}

.border-right-4-double-rose_chair_dark {
  border-right: 4px double var(--rose_chair_dark);
}

.border-right-4-groove-rose_chair_dark {
  border-right: 4px groove var(--rose_chair_dark);
}

.border-right-4-ridge-rose_chair_dark {
  border-right: 4px ridge var(--rose_chair_dark);
}

.border-right-4-inset-rose_chair_dark {
  border-right: 4px inset var(--rose_chair_dark);
}

.border-right-4-outset-rose_chair_dark {
  border-right: 4px outset var(--rose_chair_dark);
}

.border-right-4-none-rose_chair_dark {
  border-right: 4px none var(--rose_chair_dark);
}

.border-right-4-hidden-rose_chair_dark {
  border-right: 4px hidden var(--rose_chair_dark);
}

.border-right-4-solid-vert_positif {
  border-right: 4px solid var(--vert_positif);
}

.border-right-4-dashed-vert_positif {
  border-right: 4px dashed var(--vert_positif);
}

.border-right-4-dotted-vert_positif {
  border-right: 4px dotted var(--vert_positif);
}

.border-right-4-double-vert_positif {
  border-right: 4px double var(--vert_positif);
}

.border-right-4-groove-vert_positif {
  border-right: 4px groove var(--vert_positif);
}

.border-right-4-ridge-vert_positif {
  border-right: 4px ridge var(--vert_positif);
}

.border-right-4-inset-vert_positif {
  border-right: 4px inset var(--vert_positif);
}

.border-right-4-outset-vert_positif {
  border-right: 4px outset var(--vert_positif);
}

.border-right-4-none-vert_positif {
  border-right: 4px none var(--vert_positif);
}

.border-right-4-hidden-vert_positif {
  border-right: 4px hidden var(--vert_positif);
}

.border-right-4-solid-rouge_negatif {
  border-right: 4px solid var(--rouge_negatif);
}

.border-right-4-dashed-rouge_negatif {
  border-right: 4px dashed var(--rouge_negatif);
}

.border-right-4-dotted-rouge_negatif {
  border-right: 4px dotted var(--rouge_negatif);
}

.border-right-4-double-rouge_negatif {
  border-right: 4px double var(--rouge_negatif);
}

.border-right-4-groove-rouge_negatif {
  border-right: 4px groove var(--rouge_negatif);
}

.border-right-4-ridge-rouge_negatif {
  border-right: 4px ridge var(--rouge_negatif);
}

.border-right-4-inset-rouge_negatif {
  border-right: 4px inset var(--rouge_negatif);
}

.border-right-4-outset-rouge_negatif {
  border-right: 4px outset var(--rouge_negatif);
}

.border-right-4-none-rouge_negatif {
  border-right: 4px none var(--rouge_negatif);
}

.border-right-4-hidden-rouge_negatif {
  border-right: 4px hidden var(--rouge_negatif);
}

.border-right-4-solid-bleu_texte {
  border-right: 4px solid var(--bleu_texte);
}

.border-right-4-dashed-bleu_texte {
  border-right: 4px dashed var(--bleu_texte);
}

.border-right-4-dotted-bleu_texte {
  border-right: 4px dotted var(--bleu_texte);
}

.border-right-4-double-bleu_texte {
  border-right: 4px double var(--bleu_texte);
}

.border-right-4-groove-bleu_texte {
  border-right: 4px groove var(--bleu_texte);
}

.border-right-4-ridge-bleu_texte {
  border-right: 4px ridge var(--bleu_texte);
}

.border-right-4-inset-bleu_texte {
  border-right: 4px inset var(--bleu_texte);
}

.border-right-4-outset-bleu_texte {
  border-right: 4px outset var(--bleu_texte);
}

.border-right-4-none-bleu_texte {
  border-right: 4px none var(--bleu_texte);
}

.border-right-4-hidden-bleu_texte {
  border-right: 4px hidden var(--bleu_texte);
}

.border-right-4-solid-alerte_vert_positif {
  border-right: 4px solid var(--alerte_vert_positif);
}

.border-right-4-dashed-alerte_vert_positif {
  border-right: 4px dashed var(--alerte_vert_positif);
}

.border-right-4-dotted-alerte_vert_positif {
  border-right: 4px dotted var(--alerte_vert_positif);
}

.border-right-4-double-alerte_vert_positif {
  border-right: 4px double var(--alerte_vert_positif);
}

.border-right-4-groove-alerte_vert_positif {
  border-right: 4px groove var(--alerte_vert_positif);
}

.border-right-4-ridge-alerte_vert_positif {
  border-right: 4px ridge var(--alerte_vert_positif);
}

.border-right-4-inset-alerte_vert_positif {
  border-right: 4px inset var(--alerte_vert_positif);
}

.border-right-4-outset-alerte_vert_positif {
  border-right: 4px outset var(--alerte_vert_positif);
}

.border-right-4-none-alerte_vert_positif {
  border-right: 4px none var(--alerte_vert_positif);
}

.border-right-4-hidden-alerte_vert_positif {
  border-right: 4px hidden var(--alerte_vert_positif);
}

.border-right-4-solid-alerte_rouge_negatif {
  border-right: 4px solid var(--alerte_rouge_negatif);
}

.border-right-4-dashed-alerte_rouge_negatif {
  border-right: 4px dashed var(--alerte_rouge_negatif);
}

.border-right-4-dotted-alerte_rouge_negatif {
  border-right: 4px dotted var(--alerte_rouge_negatif);
}

.border-right-4-double-alerte_rouge_negatif {
  border-right: 4px double var(--alerte_rouge_negatif);
}

.border-right-4-groove-alerte_rouge_negatif {
  border-right: 4px groove var(--alerte_rouge_negatif);
}

.border-right-4-ridge-alerte_rouge_negatif {
  border-right: 4px ridge var(--alerte_rouge_negatif);
}

.border-right-4-inset-alerte_rouge_negatif {
  border-right: 4px inset var(--alerte_rouge_negatif);
}

.border-right-4-outset-alerte_rouge_negatif {
  border-right: 4px outset var(--alerte_rouge_negatif);
}

.border-right-4-none-alerte_rouge_negatif {
  border-right: 4px none var(--alerte_rouge_negatif);
}

.border-right-4-hidden-alerte_rouge_negatif {
  border-right: 4px hidden var(--alerte_rouge_negatif);
}

.border-right-4-solid-alerte_bleu_information {
  border-right: 4px solid var(--alerte_bleu_information);
}

.border-right-4-dashed-alerte_bleu_information {
  border-right: 4px dashed var(--alerte_bleu_information);
}

.border-right-4-dotted-alerte_bleu_information {
  border-right: 4px dotted var(--alerte_bleu_information);
}

.border-right-4-double-alerte_bleu_information {
  border-right: 4px double var(--alerte_bleu_information);
}

.border-right-4-groove-alerte_bleu_information {
  border-right: 4px groove var(--alerte_bleu_information);
}

.border-right-4-ridge-alerte_bleu_information {
  border-right: 4px ridge var(--alerte_bleu_information);
}

.border-right-4-inset-alerte_bleu_information {
  border-right: 4px inset var(--alerte_bleu_information);
}

.border-right-4-outset-alerte_bleu_information {
  border-right: 4px outset var(--alerte_bleu_information);
}

.border-right-4-none-alerte_bleu_information {
  border-right: 4px none var(--alerte_bleu_information);
}

.border-right-4-hidden-alerte_bleu_information {
  border-right: 4px hidden var(--alerte_bleu_information);
}

.border-right-4-solid-alerte_jaune_attention {
  border-right: 4px solid var(--alerte_jaune_attention);
}

.border-right-4-dashed-alerte_jaune_attention {
  border-right: 4px dashed var(--alerte_jaune_attention);
}

.border-right-4-dotted-alerte_jaune_attention {
  border-right: 4px dotted var(--alerte_jaune_attention);
}

.border-right-4-double-alerte_jaune_attention {
  border-right: 4px double var(--alerte_jaune_attention);
}

.border-right-4-groove-alerte_jaune_attention {
  border-right: 4px groove var(--alerte_jaune_attention);
}

.border-right-4-ridge-alerte_jaune_attention {
  border-right: 4px ridge var(--alerte_jaune_attention);
}

.border-right-4-inset-alerte_jaune_attention {
  border-right: 4px inset var(--alerte_jaune_attention);
}

.border-right-4-outset-alerte_jaune_attention {
  border-right: 4px outset var(--alerte_jaune_attention);
}

.border-right-4-none-alerte_jaune_attention {
  border-right: 4px none var(--alerte_jaune_attention);
}

.border-right-4-hidden-alerte_jaune_attention {
  border-right: 4px hidden var(--alerte_jaune_attention);
}

.border-right-4-solid-blanc {
  border-right: 4px solid #fff;
}

.border-right-4-dashed-blanc {
  border-right: 4px dashed #fff;
}

.border-right-4-dotted-blanc {
  border-right: 4px dotted #fff;
}

.border-right-4-double-blanc {
  border-right: 4px double #fff;
}

.border-right-4-groove-blanc {
  border-right: 4px groove #fff;
}

.border-right-4-ridge-blanc {
  border-right: 4px ridge #fff;
}

.border-right-4-inset-blanc {
  border-right: 4px inset #fff;
}

.border-right-4-outset-blanc {
  border-right: 4px outset #fff;
}

.border-right-4-none-blanc {
  border-right: 4px #fff;
}

.border-right-4-hidden-blanc {
  border-right: 4px hidden #fff;
}

.border-right-4-solid-noir {
  border-right: 4px solid #222;
}

.border-right-4-dashed-noir {
  border-right: 4px dashed #222;
}

.border-right-4-dotted-noir {
  border-right: 4px dotted #222;
}

.border-right-4-double-noir {
  border-right: 4px double #222;
}

.border-right-4-groove-noir {
  border-right: 4px groove #222;
}

.border-right-4-ridge-noir {
  border-right: 4px ridge #222;
}

.border-right-4-inset-noir {
  border-right: 4px inset #222;
}

.border-right-4-outset-noir {
  border-right: 4px outset #222;
}

.border-right-4-none-noir {
  border-right: 4px #222;
}

.border-right-4-hidden-noir {
  border-right: 4px hidden #222;
}

.border-right-4-solid-gris_light {
  border-right: 4px solid var(--gris_light);
}

.border-right-4-dashed-gris_light {
  border-right: 4px dashed var(--gris_light);
}

.border-right-4-dotted-gris_light {
  border-right: 4px dotted var(--gris_light);
}

.border-right-4-double-gris_light {
  border-right: 4px double var(--gris_light);
}

.border-right-4-groove-gris_light {
  border-right: 4px groove var(--gris_light);
}

.border-right-4-ridge-gris_light {
  border-right: 4px ridge var(--gris_light);
}

.border-right-4-inset-gris_light {
  border-right: 4px inset var(--gris_light);
}

.border-right-4-outset-gris_light {
  border-right: 4px outset var(--gris_light);
}

.border-right-4-none-gris_light {
  border-right: 4px none var(--gris_light);
}

.border-right-4-hidden-gris_light {
  border-right: 4px hidden var(--gris_light);
}

.border-right-4-solid-gris_dark {
  border-right: 4px solid var(--gris_dark);
}

.border-right-4-dashed-gris_dark {
  border-right: 4px dashed var(--gris_dark);
}

.border-right-4-dotted-gris_dark {
  border-right: 4px dotted var(--gris_dark);
}

.border-right-4-double-gris_dark {
  border-right: 4px double var(--gris_dark);
}

.border-right-4-groove-gris_dark {
  border-right: 4px groove var(--gris_dark);
}

.border-right-4-ridge-gris_dark {
  border-right: 4px ridge var(--gris_dark);
}

.border-right-4-inset-gris_dark {
  border-right: 4px inset var(--gris_dark);
}

.border-right-4-outset-gris_dark {
  border-right: 4px outset var(--gris_dark);
}

.border-right-4-none-gris_dark {
  border-right: 4px none var(--gris_dark);
}

.border-right-4-hidden-gris_dark {
  border-right: 4px hidden var(--gris_dark);
}

.border-right-4-solid-gris_background {
  border-right: 4px solid var(--gris_background);
}

.border-right-4-dashed-gris_background {
  border-right: 4px dashed var(--gris_background);
}

.border-right-4-dotted-gris_background {
  border-right: 4px dotted var(--gris_background);
}

.border-right-4-double-gris_background {
  border-right: 4px double var(--gris_background);
}

.border-right-4-groove-gris_background {
  border-right: 4px groove var(--gris_background);
}

.border-right-4-ridge-gris_background {
  border-right: 4px ridge var(--gris_background);
}

.border-right-4-inset-gris_background {
  border-right: 4px inset var(--gris_background);
}

.border-right-4-outset-gris_background {
  border-right: 4px outset var(--gris_background);
}

.border-right-4-none-gris_background {
  border-right: 4px none var(--gris_background);
}

.border-right-4-hidden-gris_background {
  border-right: 4px hidden var(--gris_background);
}

.border-left-4-solid-corail_mh_brand {
  border-left: 4px solid var(--corail_mh_brand);
}

.border-left-4-dashed-corail_mh_brand {
  border-left: 4px dashed var(--corail_mh_brand);
}

.border-left-4-dotted-corail_mh_brand {
  border-left: 4px dotted var(--corail_mh_brand);
}

.border-left-4-double-corail_mh_brand {
  border-left: 4px double var(--corail_mh_brand);
}

.border-left-4-groove-corail_mh_brand {
  border-left: 4px groove var(--corail_mh_brand);
}

.border-left-4-ridge-corail_mh_brand {
  border-left: 4px ridge var(--corail_mh_brand);
}

.border-left-4-inset-corail_mh_brand {
  border-left: 4px inset var(--corail_mh_brand);
}

.border-left-4-outset-corail_mh_brand {
  border-left: 4px outset var(--corail_mh_brand);
}

.border-left-4-none-corail_mh_brand {
  border-left: 4px none var(--corail_mh_brand);
}

.border-left-4-hidden-corail_mh_brand {
  border-left: 4px hidden var(--corail_mh_brand);
}

.border-left-4-solid-corail_mh_dark {
  border-left: 4px solid var(--corail_mh_dark);
}

.border-left-4-dashed-corail_mh_dark {
  border-left: 4px dashed var(--corail_mh_dark);
}

.border-left-4-dotted-corail_mh_dark {
  border-left: 4px dotted var(--corail_mh_dark);
}

.border-left-4-double-corail_mh_dark {
  border-left: 4px double var(--corail_mh_dark);
}

.border-left-4-groove-corail_mh_dark {
  border-left: 4px groove var(--corail_mh_dark);
}

.border-left-4-ridge-corail_mh_dark {
  border-left: 4px ridge var(--corail_mh_dark);
}

.border-left-4-inset-corail_mh_dark {
  border-left: 4px inset var(--corail_mh_dark);
}

.border-left-4-outset-corail_mh_dark {
  border-left: 4px outset var(--corail_mh_dark);
}

.border-left-4-none-corail_mh_dark {
  border-left: 4px none var(--corail_mh_dark);
}

.border-left-4-hidden-corail_mh_dark {
  border-left: 4px hidden var(--corail_mh_dark);
}

.border-left-4-solid-corail_mh_light {
  border-left: 4px solid var(--corail_mh_light);
}

.border-left-4-dashed-corail_mh_light {
  border-left: 4px dashed var(--corail_mh_light);
}

.border-left-4-dotted-corail_mh_light {
  border-left: 4px dotted var(--corail_mh_light);
}

.border-left-4-double-corail_mh_light {
  border-left: 4px double var(--corail_mh_light);
}

.border-left-4-groove-corail_mh_light {
  border-left: 4px groove var(--corail_mh_light);
}

.border-left-4-ridge-corail_mh_light {
  border-left: 4px ridge var(--corail_mh_light);
}

.border-left-4-inset-corail_mh_light {
  border-left: 4px inset var(--corail_mh_light);
}

.border-left-4-outset-corail_mh_light {
  border-left: 4px outset var(--corail_mh_light);
}

.border-left-4-none-corail_mh_light {
  border-left: 4px none var(--corail_mh_light);
}

.border-left-4-hidden-corail_mh_light {
  border-left: 4px hidden var(--corail_mh_light);
}

.border-left-4-solid-gris_sable_light {
  border-left: 4px solid var(--gris_sable_light);
}

.border-left-4-dashed-gris_sable_light {
  border-left: 4px dashed var(--gris_sable_light);
}

.border-left-4-dotted-gris_sable_light {
  border-left: 4px dotted var(--gris_sable_light);
}

.border-left-4-double-gris_sable_light {
  border-left: 4px double var(--gris_sable_light);
}

.border-left-4-groove-gris_sable_light {
  border-left: 4px groove var(--gris_sable_light);
}

.border-left-4-ridge-gris_sable_light {
  border-left: 4px ridge var(--gris_sable_light);
}

.border-left-4-inset-gris_sable_light {
  border-left: 4px inset var(--gris_sable_light);
}

.border-left-4-outset-gris_sable_light {
  border-left: 4px outset var(--gris_sable_light);
}

.border-left-4-none-gris_sable_light {
  border-left: 4px none var(--gris_sable_light);
}

.border-left-4-hidden-gris_sable_light {
  border-left: 4px hidden var(--gris_sable_light);
}

.border-left-4-solid-gris_sable_dark {
  border-left: 4px solid var(--gris_sable_dark);
}

.border-left-4-dashed-gris_sable_dark {
  border-left: 4px dashed var(--gris_sable_dark);
}

.border-left-4-dotted-gris_sable_dark {
  border-left: 4px dotted var(--gris_sable_dark);
}

.border-left-4-double-gris_sable_dark {
  border-left: 4px double var(--gris_sable_dark);
}

.border-left-4-groove-gris_sable_dark {
  border-left: 4px groove var(--gris_sable_dark);
}

.border-left-4-ridge-gris_sable_dark {
  border-left: 4px ridge var(--gris_sable_dark);
}

.border-left-4-inset-gris_sable_dark {
  border-left: 4px inset var(--gris_sable_dark);
}

.border-left-4-outset-gris_sable_dark {
  border-left: 4px outset var(--gris_sable_dark);
}

.border-left-4-none-gris_sable_dark {
  border-left: 4px none var(--gris_sable_dark);
}

.border-left-4-hidden-gris_sable_dark {
  border-left: 4px hidden var(--gris_sable_dark);
}

.border-left-4-solid-jaune_ivoire_light {
  border-left: 4px solid var(--jaune_ivoire_light);
}

.border-left-4-dashed-jaune_ivoire_light {
  border-left: 4px dashed var(--jaune_ivoire_light);
}

.border-left-4-dotted-jaune_ivoire_light {
  border-left: 4px dotted var(--jaune_ivoire_light);
}

.border-left-4-double-jaune_ivoire_light {
  border-left: 4px double var(--jaune_ivoire_light);
}

.border-left-4-groove-jaune_ivoire_light {
  border-left: 4px groove var(--jaune_ivoire_light);
}

.border-left-4-ridge-jaune_ivoire_light {
  border-left: 4px ridge var(--jaune_ivoire_light);
}

.border-left-4-inset-jaune_ivoire_light {
  border-left: 4px inset var(--jaune_ivoire_light);
}

.border-left-4-outset-jaune_ivoire_light {
  border-left: 4px outset var(--jaune_ivoire_light);
}

.border-left-4-none-jaune_ivoire_light {
  border-left: 4px none var(--jaune_ivoire_light);
}

.border-left-4-hidden-jaune_ivoire_light {
  border-left: 4px hidden var(--jaune_ivoire_light);
}

.border-left-4-solid-jaune_ivoire_dark {
  border-left: 4px solid var(--jaune_ivoire_dark);
}

.border-left-4-dashed-jaune_ivoire_dark {
  border-left: 4px dashed var(--jaune_ivoire_dark);
}

.border-left-4-dotted-jaune_ivoire_dark {
  border-left: 4px dotted var(--jaune_ivoire_dark);
}

.border-left-4-double-jaune_ivoire_dark {
  border-left: 4px double var(--jaune_ivoire_dark);
}

.border-left-4-groove-jaune_ivoire_dark {
  border-left: 4px groove var(--jaune_ivoire_dark);
}

.border-left-4-ridge-jaune_ivoire_dark {
  border-left: 4px ridge var(--jaune_ivoire_dark);
}

.border-left-4-inset-jaune_ivoire_dark {
  border-left: 4px inset var(--jaune_ivoire_dark);
}

.border-left-4-outset-jaune_ivoire_dark {
  border-left: 4px outset var(--jaune_ivoire_dark);
}

.border-left-4-none-jaune_ivoire_dark {
  border-left: 4px none var(--jaune_ivoire_dark);
}

.border-left-4-hidden-jaune_ivoire_dark {
  border-left: 4px hidden var(--jaune_ivoire_dark);
}

.border-left-4-solid-jaune_light {
  border-left: 4px solid var(--jaune_light);
}

.border-left-4-dashed-jaune_light {
  border-left: 4px dashed var(--jaune_light);
}

.border-left-4-dotted-jaune_light {
  border-left: 4px dotted var(--jaune_light);
}

.border-left-4-double-jaune_light {
  border-left: 4px double var(--jaune_light);
}

.border-left-4-groove-jaune_light {
  border-left: 4px groove var(--jaune_light);
}

.border-left-4-ridge-jaune_light {
  border-left: 4px ridge var(--jaune_light);
}

.border-left-4-inset-jaune_light {
  border-left: 4px inset var(--jaune_light);
}

.border-left-4-outset-jaune_light {
  border-left: 4px outset var(--jaune_light);
}

.border-left-4-none-jaune_light {
  border-left: 4px none var(--jaune_light);
}

.border-left-4-hidden-jaune_light {
  border-left: 4px hidden var(--jaune_light);
}

.border-left-4-solid-jaune_dark {
  border-left: 4px solid var(--jaune_dark);
}

.border-left-4-dashed-jaune_dark {
  border-left: 4px dashed var(--jaune_dark);
}

.border-left-4-dotted-jaune_dark {
  border-left: 4px dotted var(--jaune_dark);
}

.border-left-4-double-jaune_dark {
  border-left: 4px double var(--jaune_dark);
}

.border-left-4-groove-jaune_dark {
  border-left: 4px groove var(--jaune_dark);
}

.border-left-4-ridge-jaune_dark {
  border-left: 4px ridge var(--jaune_dark);
}

.border-left-4-inset-jaune_dark {
  border-left: 4px inset var(--jaune_dark);
}

.border-left-4-outset-jaune_dark {
  border-left: 4px outset var(--jaune_dark);
}

.border-left-4-none-jaune_dark {
  border-left: 4px none var(--jaune_dark);
}

.border-left-4-hidden-jaune_dark {
  border-left: 4px hidden var(--jaune_dark);
}

.border-left-4-solid-orange_normal {
  border-left: 4px solid var(--orange_normal);
}

.border-left-4-dashed-orange_normal {
  border-left: 4px dashed var(--orange_normal);
}

.border-left-4-dotted-orange_normal {
  border-left: 4px dotted var(--orange_normal);
}

.border-left-4-double-orange_normal {
  border-left: 4px double var(--orange_normal);
}

.border-left-4-groove-orange_normal {
  border-left: 4px groove var(--orange_normal);
}

.border-left-4-ridge-orange_normal {
  border-left: 4px ridge var(--orange_normal);
}

.border-left-4-inset-orange_normal {
  border-left: 4px inset var(--orange_normal);
}

.border-left-4-outset-orange_normal {
  border-left: 4px outset var(--orange_normal);
}

.border-left-4-none-orange_normal {
  border-left: 4px none var(--orange_normal);
}

.border-left-4-hidden-orange_normal {
  border-left: 4px hidden var(--orange_normal);
}

.border-left-4-solid-orange_dark {
  border-left: 4px solid var(--orange_dark);
}

.border-left-4-dashed-orange_dark {
  border-left: 4px dashed var(--orange_dark);
}

.border-left-4-dotted-orange_dark {
  border-left: 4px dotted var(--orange_dark);
}

.border-left-4-double-orange_dark {
  border-left: 4px double var(--orange_dark);
}

.border-left-4-groove-orange_dark {
  border-left: 4px groove var(--orange_dark);
}

.border-left-4-ridge-orange_dark {
  border-left: 4px ridge var(--orange_dark);
}

.border-left-4-inset-orange_dark {
  border-left: 4px inset var(--orange_dark);
}

.border-left-4-outset-orange_dark {
  border-left: 4px outset var(--orange_dark);
}

.border-left-4-none-orange_dark {
  border-left: 4px none var(--orange_dark);
}

.border-left-4-hidden-orange_dark {
  border-left: 4px hidden var(--orange_dark);
}

.border-left-4-solid-vert_light {
  border-left: 4px solid var(--vert_light);
}

.border-left-4-dashed-vert_light {
  border-left: 4px dashed var(--vert_light);
}

.border-left-4-dotted-vert_light {
  border-left: 4px dotted var(--vert_light);
}

.border-left-4-double-vert_light {
  border-left: 4px double var(--vert_light);
}

.border-left-4-groove-vert_light {
  border-left: 4px groove var(--vert_light);
}

.border-left-4-ridge-vert_light {
  border-left: 4px ridge var(--vert_light);
}

.border-left-4-inset-vert_light {
  border-left: 4px inset var(--vert_light);
}

.border-left-4-outset-vert_light {
  border-left: 4px outset var(--vert_light);
}

.border-left-4-none-vert_light {
  border-left: 4px none var(--vert_light);
}

.border-left-4-hidden-vert_light {
  border-left: 4px hidden var(--vert_light);
}

.border-left-4-solid-vert_dark {
  border-left: 4px solid var(--vert_dark);
}

.border-left-4-dashed-vert_dark {
  border-left: 4px dashed var(--vert_dark);
}

.border-left-4-dotted-vert_dark {
  border-left: 4px dotted var(--vert_dark);
}

.border-left-4-double-vert_dark {
  border-left: 4px double var(--vert_dark);
}

.border-left-4-groove-vert_dark {
  border-left: 4px groove var(--vert_dark);
}

.border-left-4-ridge-vert_dark {
  border-left: 4px ridge var(--vert_dark);
}

.border-left-4-inset-vert_dark {
  border-left: 4px inset var(--vert_dark);
}

.border-left-4-outset-vert_dark {
  border-left: 4px outset var(--vert_dark);
}

.border-left-4-none-vert_dark {
  border-left: 4px none var(--vert_dark);
}

.border-left-4-hidden-vert_dark {
  border-left: 4px hidden var(--vert_dark);
}

.border-left-4-solid-bleu_turquoise_light {
  border-left: 4px solid var(--bleu_turquoise_light);
}

.border-left-4-dashed-bleu_turquoise_light {
  border-left: 4px dashed var(--bleu_turquoise_light);
}

.border-left-4-dotted-bleu_turquoise_light {
  border-left: 4px dotted var(--bleu_turquoise_light);
}

.border-left-4-double-bleu_turquoise_light {
  border-left: 4px double var(--bleu_turquoise_light);
}

.border-left-4-groove-bleu_turquoise_light {
  border-left: 4px groove var(--bleu_turquoise_light);
}

.border-left-4-ridge-bleu_turquoise_light {
  border-left: 4px ridge var(--bleu_turquoise_light);
}

.border-left-4-inset-bleu_turquoise_light {
  border-left: 4px inset var(--bleu_turquoise_light);
}

.border-left-4-outset-bleu_turquoise_light {
  border-left: 4px outset var(--bleu_turquoise_light);
}

.border-left-4-none-bleu_turquoise_light {
  border-left: 4px none var(--bleu_turquoise_light);
}

.border-left-4-hidden-bleu_turquoise_light {
  border-left: 4px hidden var(--bleu_turquoise_light);
}

.border-left-4-solid-bleu_turquoise_middle {
  border-left: 4px solid var(--bleu_turquoise_middle);
}

.border-left-4-dashed-bleu_turquoise_middle {
  border-left: 4px dashed var(--bleu_turquoise_middle);
}

.border-left-4-dotted-bleu_turquoise_middle {
  border-left: 4px dotted var(--bleu_turquoise_middle);
}

.border-left-4-double-bleu_turquoise_middle {
  border-left: 4px double var(--bleu_turquoise_middle);
}

.border-left-4-groove-bleu_turquoise_middle {
  border-left: 4px groove var(--bleu_turquoise_middle);
}

.border-left-4-ridge-bleu_turquoise_middle {
  border-left: 4px ridge var(--bleu_turquoise_middle);
}

.border-left-4-inset-bleu_turquoise_middle {
  border-left: 4px inset var(--bleu_turquoise_middle);
}

.border-left-4-outset-bleu_turquoise_middle {
  border-left: 4px outset var(--bleu_turquoise_middle);
}

.border-left-4-none-bleu_turquoise_middle {
  border-left: 4px none var(--bleu_turquoise_middle);
}

.border-left-4-hidden-bleu_turquoise_middle {
  border-left: 4px hidden var(--bleu_turquoise_middle);
}

.border-left-4-solid-bleu_turquoise_dark {
  border-left: 4px solid var(--bleu_turquoise_dark);
}

.border-left-4-dashed-bleu_turquoise_dark {
  border-left: 4px dashed var(--bleu_turquoise_dark);
}

.border-left-4-dotted-bleu_turquoise_dark {
  border-left: 4px dotted var(--bleu_turquoise_dark);
}

.border-left-4-double-bleu_turquoise_dark {
  border-left: 4px double var(--bleu_turquoise_dark);
}

.border-left-4-groove-bleu_turquoise_dark {
  border-left: 4px groove var(--bleu_turquoise_dark);
}

.border-left-4-ridge-bleu_turquoise_dark {
  border-left: 4px ridge var(--bleu_turquoise_dark);
}

.border-left-4-inset-bleu_turquoise_dark {
  border-left: 4px inset var(--bleu_turquoise_dark);
}

.border-left-4-outset-bleu_turquoise_dark {
  border-left: 4px outset var(--bleu_turquoise_dark);
}

.border-left-4-none-bleu_turquoise_dark {
  border-left: 4px none var(--bleu_turquoise_dark);
}

.border-left-4-hidden-bleu_turquoise_dark {
  border-left: 4px hidden var(--bleu_turquoise_dark);
}

.border-left-4-solid-bleu_light {
  border-left: 4px solid var(--bleu_light);
}

.border-left-4-dashed-bleu_light {
  border-left: 4px dashed var(--bleu_light);
}

.border-left-4-dotted-bleu_light {
  border-left: 4px dotted var(--bleu_light);
}

.border-left-4-double-bleu_light {
  border-left: 4px double var(--bleu_light);
}

.border-left-4-groove-bleu_light {
  border-left: 4px groove var(--bleu_light);
}

.border-left-4-ridge-bleu_light {
  border-left: 4px ridge var(--bleu_light);
}

.border-left-4-inset-bleu_light {
  border-left: 4px inset var(--bleu_light);
}

.border-left-4-outset-bleu_light {
  border-left: 4px outset var(--bleu_light);
}

.border-left-4-none-bleu_light {
  border-left: 4px none var(--bleu_light);
}

.border-left-4-hidden-bleu_light {
  border-left: 4px hidden var(--bleu_light);
}

.border-left-4-solid-bleu_dark {
  border-left: 4px solid var(--bleu_dark);
}

.border-left-4-dashed-bleu_dark {
  border-left: 4px dashed var(--bleu_dark);
}

.border-left-4-dotted-bleu_dark {
  border-left: 4px dotted var(--bleu_dark);
}

.border-left-4-double-bleu_dark {
  border-left: 4px double var(--bleu_dark);
}

.border-left-4-groove-bleu_dark {
  border-left: 4px groove var(--bleu_dark);
}

.border-left-4-ridge-bleu_dark {
  border-left: 4px ridge var(--bleu_dark);
}

.border-left-4-inset-bleu_dark {
  border-left: 4px inset var(--bleu_dark);
}

.border-left-4-outset-bleu_dark {
  border-left: 4px outset var(--bleu_dark);
}

.border-left-4-none-bleu_dark {
  border-left: 4px none var(--bleu_dark);
}

.border-left-4-hidden-bleu_dark {
  border-left: 4px hidden var(--bleu_dark);
}

.border-left-4-solid-bleu_klein_light {
  border-left: 4px solid var(--bleu_klein_light);
}

.border-left-4-dashed-bleu_klein_light {
  border-left: 4px dashed var(--bleu_klein_light);
}

.border-left-4-dotted-bleu_klein_light {
  border-left: 4px dotted var(--bleu_klein_light);
}

.border-left-4-double-bleu_klein_light {
  border-left: 4px double var(--bleu_klein_light);
}

.border-left-4-groove-bleu_klein_light {
  border-left: 4px groove var(--bleu_klein_light);
}

.border-left-4-ridge-bleu_klein_light {
  border-left: 4px ridge var(--bleu_klein_light);
}

.border-left-4-inset-bleu_klein_light {
  border-left: 4px inset var(--bleu_klein_light);
}

.border-left-4-outset-bleu_klein_light {
  border-left: 4px outset var(--bleu_klein_light);
}

.border-left-4-none-bleu_klein_light {
  border-left: 4px none var(--bleu_klein_light);
}

.border-left-4-hidden-bleu_klein_light {
  border-left: 4px hidden var(--bleu_klein_light);
}

.border-left-4-solid-bleu_klein_dark {
  border-left: 4px solid var(--bleu_klein_dark);
}

.border-left-4-dashed-bleu_klein_dark {
  border-left: 4px dashed var(--bleu_klein_dark);
}

.border-left-4-dotted-bleu_klein_dark {
  border-left: 4px dotted var(--bleu_klein_dark);
}

.border-left-4-double-bleu_klein_dark {
  border-left: 4px double var(--bleu_klein_dark);
}

.border-left-4-groove-bleu_klein_dark {
  border-left: 4px groove var(--bleu_klein_dark);
}

.border-left-4-ridge-bleu_klein_dark {
  border-left: 4px ridge var(--bleu_klein_dark);
}

.border-left-4-inset-bleu_klein_dark {
  border-left: 4px inset var(--bleu_klein_dark);
}

.border-left-4-outset-bleu_klein_dark {
  border-left: 4px outset var(--bleu_klein_dark);
}

.border-left-4-none-bleu_klein_dark {
  border-left: 4px none var(--bleu_klein_dark);
}

.border-left-4-hidden-bleu_klein_dark {
  border-left: 4px hidden var(--bleu_klein_dark);
}

.border-left-4-solid-violet_light {
  border-left: 4px solid var(--violet_light);
}

.border-left-4-dashed-violet_light {
  border-left: 4px dashed var(--violet_light);
}

.border-left-4-dotted-violet_light {
  border-left: 4px dotted var(--violet_light);
}

.border-left-4-double-violet_light {
  border-left: 4px double var(--violet_light);
}

.border-left-4-groove-violet_light {
  border-left: 4px groove var(--violet_light);
}

.border-left-4-ridge-violet_light {
  border-left: 4px ridge var(--violet_light);
}

.border-left-4-inset-violet_light {
  border-left: 4px inset var(--violet_light);
}

.border-left-4-outset-violet_light {
  border-left: 4px outset var(--violet_light);
}

.border-left-4-none-violet_light {
  border-left: 4px none var(--violet_light);
}

.border-left-4-hidden-violet_light {
  border-left: 4px hidden var(--violet_light);
}

.border-left-4-solid-violet_dark {
  border-left: 4px solid var(--violet_dark);
}

.border-left-4-dashed-violet_dark {
  border-left: 4px dashed var(--violet_dark);
}

.border-left-4-dotted-violet_dark {
  border-left: 4px dotted var(--violet_dark);
}

.border-left-4-double-violet_dark {
  border-left: 4px double var(--violet_dark);
}

.border-left-4-groove-violet_dark {
  border-left: 4px groove var(--violet_dark);
}

.border-left-4-ridge-violet_dark {
  border-left: 4px ridge var(--violet_dark);
}

.border-left-4-inset-violet_dark {
  border-left: 4px inset var(--violet_dark);
}

.border-left-4-outset-violet_dark {
  border-left: 4px outset var(--violet_dark);
}

.border-left-4-none-violet_dark {
  border-left: 4px none var(--violet_dark);
}

.border-left-4-hidden-violet_dark {
  border-left: 4px hidden var(--violet_dark);
}

.border-left-4-solid-mauve {
  border-left: 4px solid var(--mauve);
}

.border-left-4-dashed-mauve {
  border-left: 4px dashed var(--mauve);
}

.border-left-4-dotted-mauve {
  border-left: 4px dotted var(--mauve);
}

.border-left-4-double-mauve {
  border-left: 4px double var(--mauve);
}

.border-left-4-groove-mauve {
  border-left: 4px groove var(--mauve);
}

.border-left-4-ridge-mauve {
  border-left: 4px ridge var(--mauve);
}

.border-left-4-inset-mauve {
  border-left: 4px inset var(--mauve);
}

.border-left-4-outset-mauve {
  border-left: 4px outset var(--mauve);
}

.border-left-4-none-mauve {
  border-left: 4px none var(--mauve);
}

.border-left-4-hidden-mauve {
  border-left: 4px hidden var(--mauve);
}

.border-left-4-solid-rose_light {
  border-left: 4px solid var(--rose_light);
}

.border-left-4-dashed-rose_light {
  border-left: 4px dashed var(--rose_light);
}

.border-left-4-dotted-rose_light {
  border-left: 4px dotted var(--rose_light);
}

.border-left-4-double-rose_light {
  border-left: 4px double var(--rose_light);
}

.border-left-4-groove-rose_light {
  border-left: 4px groove var(--rose_light);
}

.border-left-4-ridge-rose_light {
  border-left: 4px ridge var(--rose_light);
}

.border-left-4-inset-rose_light {
  border-left: 4px inset var(--rose_light);
}

.border-left-4-outset-rose_light {
  border-left: 4px outset var(--rose_light);
}

.border-left-4-none-rose_light {
  border-left: 4px none var(--rose_light);
}

.border-left-4-hidden-rose_light {
  border-left: 4px hidden var(--rose_light);
}

.border-left-4-solid-rose_dark {
  border-left: 4px solid var(--rose_dark);
}

.border-left-4-dashed-rose_dark {
  border-left: 4px dashed var(--rose_dark);
}

.border-left-4-dotted-rose_dark {
  border-left: 4px dotted var(--rose_dark);
}

.border-left-4-double-rose_dark {
  border-left: 4px double var(--rose_dark);
}

.border-left-4-groove-rose_dark {
  border-left: 4px groove var(--rose_dark);
}

.border-left-4-ridge-rose_dark {
  border-left: 4px ridge var(--rose_dark);
}

.border-left-4-inset-rose_dark {
  border-left: 4px inset var(--rose_dark);
}

.border-left-4-outset-rose_dark {
  border-left: 4px outset var(--rose_dark);
}

.border-left-4-none-rose_dark {
  border-left: 4px none var(--rose_dark);
}

.border-left-4-hidden-rose_dark {
  border-left: 4px hidden var(--rose_dark);
}

.border-left-4-solid-rose_fushia {
  border-left: 4px solid var(--rose_fushia);
}

.border-left-4-dashed-rose_fushia {
  border-left: 4px dashed var(--rose_fushia);
}

.border-left-4-dotted-rose_fushia {
  border-left: 4px dotted var(--rose_fushia);
}

.border-left-4-double-rose_fushia {
  border-left: 4px double var(--rose_fushia);
}

.border-left-4-groove-rose_fushia {
  border-left: 4px groove var(--rose_fushia);
}

.border-left-4-ridge-rose_fushia {
  border-left: 4px ridge var(--rose_fushia);
}

.border-left-4-inset-rose_fushia {
  border-left: 4px inset var(--rose_fushia);
}

.border-left-4-outset-rose_fushia {
  border-left: 4px outset var(--rose_fushia);
}

.border-left-4-none-rose_fushia {
  border-left: 4px none var(--rose_fushia);
}

.border-left-4-hidden-rose_fushia {
  border-left: 4px hidden var(--rose_fushia);
}

.border-left-4-solid-rouge_framboise {
  border-left: 4px solid var(--rouge_framboise);
}

.border-left-4-dashed-rouge_framboise {
  border-left: 4px dashed var(--rouge_framboise);
}

.border-left-4-dotted-rouge_framboise {
  border-left: 4px dotted var(--rouge_framboise);
}

.border-left-4-double-rouge_framboise {
  border-left: 4px double var(--rouge_framboise);
}

.border-left-4-groove-rouge_framboise {
  border-left: 4px groove var(--rouge_framboise);
}

.border-left-4-ridge-rouge_framboise {
  border-left: 4px ridge var(--rouge_framboise);
}

.border-left-4-inset-rouge_framboise {
  border-left: 4px inset var(--rouge_framboise);
}

.border-left-4-outset-rouge_framboise {
  border-left: 4px outset var(--rouge_framboise);
}

.border-left-4-none-rouge_framboise {
  border-left: 4px none var(--rouge_framboise);
}

.border-left-4-hidden-rouge_framboise {
  border-left: 4px hidden var(--rouge_framboise);
}

.border-left-4-solid-rose_chair_light {
  border-left: 4px solid var(--rose_chair_light);
}

.border-left-4-dashed-rose_chair_light {
  border-left: 4px dashed var(--rose_chair_light);
}

.border-left-4-dotted-rose_chair_light {
  border-left: 4px dotted var(--rose_chair_light);
}

.border-left-4-double-rose_chair_light {
  border-left: 4px double var(--rose_chair_light);
}

.border-left-4-groove-rose_chair_light {
  border-left: 4px groove var(--rose_chair_light);
}

.border-left-4-ridge-rose_chair_light {
  border-left: 4px ridge var(--rose_chair_light);
}

.border-left-4-inset-rose_chair_light {
  border-left: 4px inset var(--rose_chair_light);
}

.border-left-4-outset-rose_chair_light {
  border-left: 4px outset var(--rose_chair_light);
}

.border-left-4-none-rose_chair_light {
  border-left: 4px none var(--rose_chair_light);
}

.border-left-4-hidden-rose_chair_light {
  border-left: 4px hidden var(--rose_chair_light);
}

.border-left-4-solid-rose_chair_dark {
  border-left: 4px solid var(--rose_chair_dark);
}

.border-left-4-dashed-rose_chair_dark {
  border-left: 4px dashed var(--rose_chair_dark);
}

.border-left-4-dotted-rose_chair_dark {
  border-left: 4px dotted var(--rose_chair_dark);
}

.border-left-4-double-rose_chair_dark {
  border-left: 4px double var(--rose_chair_dark);
}

.border-left-4-groove-rose_chair_dark {
  border-left: 4px groove var(--rose_chair_dark);
}

.border-left-4-ridge-rose_chair_dark {
  border-left: 4px ridge var(--rose_chair_dark);
}

.border-left-4-inset-rose_chair_dark {
  border-left: 4px inset var(--rose_chair_dark);
}

.border-left-4-outset-rose_chair_dark {
  border-left: 4px outset var(--rose_chair_dark);
}

.border-left-4-none-rose_chair_dark {
  border-left: 4px none var(--rose_chair_dark);
}

.border-left-4-hidden-rose_chair_dark {
  border-left: 4px hidden var(--rose_chair_dark);
}

.border-left-4-solid-vert_positif {
  border-left: 4px solid var(--vert_positif);
}

.border-left-4-dashed-vert_positif {
  border-left: 4px dashed var(--vert_positif);
}

.border-left-4-dotted-vert_positif {
  border-left: 4px dotted var(--vert_positif);
}

.border-left-4-double-vert_positif {
  border-left: 4px double var(--vert_positif);
}

.border-left-4-groove-vert_positif {
  border-left: 4px groove var(--vert_positif);
}

.border-left-4-ridge-vert_positif {
  border-left: 4px ridge var(--vert_positif);
}

.border-left-4-inset-vert_positif {
  border-left: 4px inset var(--vert_positif);
}

.border-left-4-outset-vert_positif {
  border-left: 4px outset var(--vert_positif);
}

.border-left-4-none-vert_positif {
  border-left: 4px none var(--vert_positif);
}

.border-left-4-hidden-vert_positif {
  border-left: 4px hidden var(--vert_positif);
}

.border-left-4-solid-rouge_negatif {
  border-left: 4px solid var(--rouge_negatif);
}

.border-left-4-dashed-rouge_negatif {
  border-left: 4px dashed var(--rouge_negatif);
}

.border-left-4-dotted-rouge_negatif {
  border-left: 4px dotted var(--rouge_negatif);
}

.border-left-4-double-rouge_negatif {
  border-left: 4px double var(--rouge_negatif);
}

.border-left-4-groove-rouge_negatif {
  border-left: 4px groove var(--rouge_negatif);
}

.border-left-4-ridge-rouge_negatif {
  border-left: 4px ridge var(--rouge_negatif);
}

.border-left-4-inset-rouge_negatif {
  border-left: 4px inset var(--rouge_negatif);
}

.border-left-4-outset-rouge_negatif {
  border-left: 4px outset var(--rouge_negatif);
}

.border-left-4-none-rouge_negatif {
  border-left: 4px none var(--rouge_negatif);
}

.border-left-4-hidden-rouge_negatif {
  border-left: 4px hidden var(--rouge_negatif);
}

.border-left-4-solid-bleu_texte {
  border-left: 4px solid var(--bleu_texte);
}

.border-left-4-dashed-bleu_texte {
  border-left: 4px dashed var(--bleu_texte);
}

.border-left-4-dotted-bleu_texte {
  border-left: 4px dotted var(--bleu_texte);
}

.border-left-4-double-bleu_texte {
  border-left: 4px double var(--bleu_texte);
}

.border-left-4-groove-bleu_texte {
  border-left: 4px groove var(--bleu_texte);
}

.border-left-4-ridge-bleu_texte {
  border-left: 4px ridge var(--bleu_texte);
}

.border-left-4-inset-bleu_texte {
  border-left: 4px inset var(--bleu_texte);
}

.border-left-4-outset-bleu_texte {
  border-left: 4px outset var(--bleu_texte);
}

.border-left-4-none-bleu_texte {
  border-left: 4px none var(--bleu_texte);
}

.border-left-4-hidden-bleu_texte {
  border-left: 4px hidden var(--bleu_texte);
}

.border-left-4-solid-alerte_vert_positif {
  border-left: 4px solid var(--alerte_vert_positif);
}

.border-left-4-dashed-alerte_vert_positif {
  border-left: 4px dashed var(--alerte_vert_positif);
}

.border-left-4-dotted-alerte_vert_positif {
  border-left: 4px dotted var(--alerte_vert_positif);
}

.border-left-4-double-alerte_vert_positif {
  border-left: 4px double var(--alerte_vert_positif);
}

.border-left-4-groove-alerte_vert_positif {
  border-left: 4px groove var(--alerte_vert_positif);
}

.border-left-4-ridge-alerte_vert_positif {
  border-left: 4px ridge var(--alerte_vert_positif);
}

.border-left-4-inset-alerte_vert_positif {
  border-left: 4px inset var(--alerte_vert_positif);
}

.border-left-4-outset-alerte_vert_positif {
  border-left: 4px outset var(--alerte_vert_positif);
}

.border-left-4-none-alerte_vert_positif {
  border-left: 4px none var(--alerte_vert_positif);
}

.border-left-4-hidden-alerte_vert_positif {
  border-left: 4px hidden var(--alerte_vert_positif);
}

.border-left-4-solid-alerte_rouge_negatif {
  border-left: 4px solid var(--alerte_rouge_negatif);
}

.border-left-4-dashed-alerte_rouge_negatif {
  border-left: 4px dashed var(--alerte_rouge_negatif);
}

.border-left-4-dotted-alerte_rouge_negatif {
  border-left: 4px dotted var(--alerte_rouge_negatif);
}

.border-left-4-double-alerte_rouge_negatif {
  border-left: 4px double var(--alerte_rouge_negatif);
}

.border-left-4-groove-alerte_rouge_negatif {
  border-left: 4px groove var(--alerte_rouge_negatif);
}

.border-left-4-ridge-alerte_rouge_negatif {
  border-left: 4px ridge var(--alerte_rouge_negatif);
}

.border-left-4-inset-alerte_rouge_negatif {
  border-left: 4px inset var(--alerte_rouge_negatif);
}

.border-left-4-outset-alerte_rouge_negatif {
  border-left: 4px outset var(--alerte_rouge_negatif);
}

.border-left-4-none-alerte_rouge_negatif {
  border-left: 4px none var(--alerte_rouge_negatif);
}

.border-left-4-hidden-alerte_rouge_negatif {
  border-left: 4px hidden var(--alerte_rouge_negatif);
}

.border-left-4-solid-alerte_bleu_information {
  border-left: 4px solid var(--alerte_bleu_information);
}

.border-left-4-dashed-alerte_bleu_information {
  border-left: 4px dashed var(--alerte_bleu_information);
}

.border-left-4-dotted-alerte_bleu_information {
  border-left: 4px dotted var(--alerte_bleu_information);
}

.border-left-4-double-alerte_bleu_information {
  border-left: 4px double var(--alerte_bleu_information);
}

.border-left-4-groove-alerte_bleu_information {
  border-left: 4px groove var(--alerte_bleu_information);
}

.border-left-4-ridge-alerte_bleu_information {
  border-left: 4px ridge var(--alerte_bleu_information);
}

.border-left-4-inset-alerte_bleu_information {
  border-left: 4px inset var(--alerte_bleu_information);
}

.border-left-4-outset-alerte_bleu_information {
  border-left: 4px outset var(--alerte_bleu_information);
}

.border-left-4-none-alerte_bleu_information {
  border-left: 4px none var(--alerte_bleu_information);
}

.border-left-4-hidden-alerte_bleu_information {
  border-left: 4px hidden var(--alerte_bleu_information);
}

.border-left-4-solid-alerte_jaune_attention {
  border-left: 4px solid var(--alerte_jaune_attention);
}

.border-left-4-dashed-alerte_jaune_attention {
  border-left: 4px dashed var(--alerte_jaune_attention);
}

.border-left-4-dotted-alerte_jaune_attention {
  border-left: 4px dotted var(--alerte_jaune_attention);
}

.border-left-4-double-alerte_jaune_attention {
  border-left: 4px double var(--alerte_jaune_attention);
}

.border-left-4-groove-alerte_jaune_attention {
  border-left: 4px groove var(--alerte_jaune_attention);
}

.border-left-4-ridge-alerte_jaune_attention {
  border-left: 4px ridge var(--alerte_jaune_attention);
}

.border-left-4-inset-alerte_jaune_attention {
  border-left: 4px inset var(--alerte_jaune_attention);
}

.border-left-4-outset-alerte_jaune_attention {
  border-left: 4px outset var(--alerte_jaune_attention);
}

.border-left-4-none-alerte_jaune_attention {
  border-left: 4px none var(--alerte_jaune_attention);
}

.border-left-4-hidden-alerte_jaune_attention {
  border-left: 4px hidden var(--alerte_jaune_attention);
}

.border-left-4-solid-blanc {
  border-left: 4px solid #fff;
}

.border-left-4-dashed-blanc {
  border-left: 4px dashed #fff;
}

.border-left-4-dotted-blanc {
  border-left: 4px dotted #fff;
}

.border-left-4-double-blanc {
  border-left: 4px double #fff;
}

.border-left-4-groove-blanc {
  border-left: 4px groove #fff;
}

.border-left-4-ridge-blanc {
  border-left: 4px ridge #fff;
}

.border-left-4-inset-blanc {
  border-left: 4px inset #fff;
}

.border-left-4-outset-blanc {
  border-left: 4px outset #fff;
}

.border-left-4-none-blanc {
  border-left: 4px #fff;
}

.border-left-4-hidden-blanc {
  border-left: 4px hidden #fff;
}

.border-left-4-solid-noir {
  border-left: 4px solid #222;
}

.border-left-4-dashed-noir {
  border-left: 4px dashed #222;
}

.border-left-4-dotted-noir {
  border-left: 4px dotted #222;
}

.border-left-4-double-noir {
  border-left: 4px double #222;
}

.border-left-4-groove-noir {
  border-left: 4px groove #222;
}

.border-left-4-ridge-noir {
  border-left: 4px ridge #222;
}

.border-left-4-inset-noir {
  border-left: 4px inset #222;
}

.border-left-4-outset-noir {
  border-left: 4px outset #222;
}

.border-left-4-none-noir {
  border-left: 4px #222;
}

.border-left-4-hidden-noir {
  border-left: 4px hidden #222;
}

.border-left-4-solid-gris_light {
  border-left: 4px solid var(--gris_light);
}

.border-left-4-dashed-gris_light {
  border-left: 4px dashed var(--gris_light);
}

.border-left-4-dotted-gris_light {
  border-left: 4px dotted var(--gris_light);
}

.border-left-4-double-gris_light {
  border-left: 4px double var(--gris_light);
}

.border-left-4-groove-gris_light {
  border-left: 4px groove var(--gris_light);
}

.border-left-4-ridge-gris_light {
  border-left: 4px ridge var(--gris_light);
}

.border-left-4-inset-gris_light {
  border-left: 4px inset var(--gris_light);
}

.border-left-4-outset-gris_light {
  border-left: 4px outset var(--gris_light);
}

.border-left-4-none-gris_light {
  border-left: 4px none var(--gris_light);
}

.border-left-4-hidden-gris_light {
  border-left: 4px hidden var(--gris_light);
}

.border-left-4-solid-gris_dark {
  border-left: 4px solid var(--gris_dark);
}

.border-left-4-dashed-gris_dark {
  border-left: 4px dashed var(--gris_dark);
}

.border-left-4-dotted-gris_dark {
  border-left: 4px dotted var(--gris_dark);
}

.border-left-4-double-gris_dark {
  border-left: 4px double var(--gris_dark);
}

.border-left-4-groove-gris_dark {
  border-left: 4px groove var(--gris_dark);
}

.border-left-4-ridge-gris_dark {
  border-left: 4px ridge var(--gris_dark);
}

.border-left-4-inset-gris_dark {
  border-left: 4px inset var(--gris_dark);
}

.border-left-4-outset-gris_dark {
  border-left: 4px outset var(--gris_dark);
}

.border-left-4-none-gris_dark {
  border-left: 4px none var(--gris_dark);
}

.border-left-4-hidden-gris_dark {
  border-left: 4px hidden var(--gris_dark);
}

.border-left-4-solid-gris_background {
  border-left: 4px solid var(--gris_background);
}

.border-left-4-dashed-gris_background {
  border-left: 4px dashed var(--gris_background);
}

.border-left-4-dotted-gris_background {
  border-left: 4px dotted var(--gris_background);
}

.border-left-4-double-gris_background {
  border-left: 4px double var(--gris_background);
}

.border-left-4-groove-gris_background {
  border-left: 4px groove var(--gris_background);
}

.border-left-4-ridge-gris_background {
  border-left: 4px ridge var(--gris_background);
}

.border-left-4-inset-gris_background {
  border-left: 4px inset var(--gris_background);
}

.border-left-4-outset-gris_background {
  border-left: 4px outset var(--gris_background);
}

.border-left-4-none-gris_background {
  border-left: 4px none var(--gris_background);
}

.border-left-4-hidden-gris_background {
  border-left: 4px hidden var(--gris_background);
}

.border-all-4-solid-corail_mh_brand {
  border: 4px solid var(--corail_mh_brand);
}

.border-all-4-dashed-corail_mh_brand {
  border: 4px dashed var(--corail_mh_brand);
}

.border-all-4-dotted-corail_mh_brand {
  border: 4px dotted var(--corail_mh_brand);
}

.border-all-4-double-corail_mh_brand {
  border: 4px double var(--corail_mh_brand);
}

.border-all-4-groove-corail_mh_brand {
  border: 4px groove var(--corail_mh_brand);
}

.border-all-4-ridge-corail_mh_brand {
  border: 4px ridge var(--corail_mh_brand);
}

.border-all-4-inset-corail_mh_brand {
  border: 4px inset var(--corail_mh_brand);
}

.border-all-4-outset-corail_mh_brand {
  border: 4px outset var(--corail_mh_brand);
}

.border-all-4-none-corail_mh_brand {
  border: 4px none var(--corail_mh_brand);
}

.border-all-4-hidden-corail_mh_brand {
  border: 4px hidden var(--corail_mh_brand);
}

.border-all-4-solid-corail_mh_dark {
  border: 4px solid var(--corail_mh_dark);
}

.border-all-4-dashed-corail_mh_dark {
  border: 4px dashed var(--corail_mh_dark);
}

.border-all-4-dotted-corail_mh_dark {
  border: 4px dotted var(--corail_mh_dark);
}

.border-all-4-double-corail_mh_dark {
  border: 4px double var(--corail_mh_dark);
}

.border-all-4-groove-corail_mh_dark {
  border: 4px groove var(--corail_mh_dark);
}

.border-all-4-ridge-corail_mh_dark {
  border: 4px ridge var(--corail_mh_dark);
}

.border-all-4-inset-corail_mh_dark {
  border: 4px inset var(--corail_mh_dark);
}

.border-all-4-outset-corail_mh_dark {
  border: 4px outset var(--corail_mh_dark);
}

.border-all-4-none-corail_mh_dark {
  border: 4px none var(--corail_mh_dark);
}

.border-all-4-hidden-corail_mh_dark {
  border: 4px hidden var(--corail_mh_dark);
}

.border-all-4-solid-corail_mh_light {
  border: 4px solid var(--corail_mh_light);
}

.border-all-4-dashed-corail_mh_light {
  border: 4px dashed var(--corail_mh_light);
}

.border-all-4-dotted-corail_mh_light {
  border: 4px dotted var(--corail_mh_light);
}

.border-all-4-double-corail_mh_light {
  border: 4px double var(--corail_mh_light);
}

.border-all-4-groove-corail_mh_light {
  border: 4px groove var(--corail_mh_light);
}

.border-all-4-ridge-corail_mh_light {
  border: 4px ridge var(--corail_mh_light);
}

.border-all-4-inset-corail_mh_light {
  border: 4px inset var(--corail_mh_light);
}

.border-all-4-outset-corail_mh_light {
  border: 4px outset var(--corail_mh_light);
}

.border-all-4-none-corail_mh_light {
  border: 4px none var(--corail_mh_light);
}

.border-all-4-hidden-corail_mh_light {
  border: 4px hidden var(--corail_mh_light);
}

.border-all-4-solid-gris_sable_light {
  border: 4px solid var(--gris_sable_light);
}

.border-all-4-dashed-gris_sable_light {
  border: 4px dashed var(--gris_sable_light);
}

.border-all-4-dotted-gris_sable_light {
  border: 4px dotted var(--gris_sable_light);
}

.border-all-4-double-gris_sable_light {
  border: 4px double var(--gris_sable_light);
}

.border-all-4-groove-gris_sable_light {
  border: 4px groove var(--gris_sable_light);
}

.border-all-4-ridge-gris_sable_light {
  border: 4px ridge var(--gris_sable_light);
}

.border-all-4-inset-gris_sable_light {
  border: 4px inset var(--gris_sable_light);
}

.border-all-4-outset-gris_sable_light {
  border: 4px outset var(--gris_sable_light);
}

.border-all-4-none-gris_sable_light {
  border: 4px none var(--gris_sable_light);
}

.border-all-4-hidden-gris_sable_light {
  border: 4px hidden var(--gris_sable_light);
}

.border-all-4-solid-gris_sable_dark {
  border: 4px solid var(--gris_sable_dark);
}

.border-all-4-dashed-gris_sable_dark {
  border: 4px dashed var(--gris_sable_dark);
}

.border-all-4-dotted-gris_sable_dark {
  border: 4px dotted var(--gris_sable_dark);
}

.border-all-4-double-gris_sable_dark {
  border: 4px double var(--gris_sable_dark);
}

.border-all-4-groove-gris_sable_dark {
  border: 4px groove var(--gris_sable_dark);
}

.border-all-4-ridge-gris_sable_dark {
  border: 4px ridge var(--gris_sable_dark);
}

.border-all-4-inset-gris_sable_dark {
  border: 4px inset var(--gris_sable_dark);
}

.border-all-4-outset-gris_sable_dark {
  border: 4px outset var(--gris_sable_dark);
}

.border-all-4-none-gris_sable_dark {
  border: 4px none var(--gris_sable_dark);
}

.border-all-4-hidden-gris_sable_dark {
  border: 4px hidden var(--gris_sable_dark);
}

.border-all-4-solid-jaune_ivoire_light {
  border: 4px solid var(--jaune_ivoire_light);
}

.border-all-4-dashed-jaune_ivoire_light {
  border: 4px dashed var(--jaune_ivoire_light);
}

.border-all-4-dotted-jaune_ivoire_light {
  border: 4px dotted var(--jaune_ivoire_light);
}

.border-all-4-double-jaune_ivoire_light {
  border: 4px double var(--jaune_ivoire_light);
}

.border-all-4-groove-jaune_ivoire_light {
  border: 4px groove var(--jaune_ivoire_light);
}

.border-all-4-ridge-jaune_ivoire_light {
  border: 4px ridge var(--jaune_ivoire_light);
}

.border-all-4-inset-jaune_ivoire_light {
  border: 4px inset var(--jaune_ivoire_light);
}

.border-all-4-outset-jaune_ivoire_light {
  border: 4px outset var(--jaune_ivoire_light);
}

.border-all-4-none-jaune_ivoire_light {
  border: 4px none var(--jaune_ivoire_light);
}

.border-all-4-hidden-jaune_ivoire_light {
  border: 4px hidden var(--jaune_ivoire_light);
}

.border-all-4-solid-jaune_ivoire_dark {
  border: 4px solid var(--jaune_ivoire_dark);
}

.border-all-4-dashed-jaune_ivoire_dark {
  border: 4px dashed var(--jaune_ivoire_dark);
}

.border-all-4-dotted-jaune_ivoire_dark {
  border: 4px dotted var(--jaune_ivoire_dark);
}

.border-all-4-double-jaune_ivoire_dark {
  border: 4px double var(--jaune_ivoire_dark);
}

.border-all-4-groove-jaune_ivoire_dark {
  border: 4px groove var(--jaune_ivoire_dark);
}

.border-all-4-ridge-jaune_ivoire_dark {
  border: 4px ridge var(--jaune_ivoire_dark);
}

.border-all-4-inset-jaune_ivoire_dark {
  border: 4px inset var(--jaune_ivoire_dark);
}

.border-all-4-outset-jaune_ivoire_dark {
  border: 4px outset var(--jaune_ivoire_dark);
}

.border-all-4-none-jaune_ivoire_dark {
  border: 4px none var(--jaune_ivoire_dark);
}

.border-all-4-hidden-jaune_ivoire_dark {
  border: 4px hidden var(--jaune_ivoire_dark);
}

.border-all-4-solid-jaune_light {
  border: 4px solid var(--jaune_light);
}

.border-all-4-dashed-jaune_light {
  border: 4px dashed var(--jaune_light);
}

.border-all-4-dotted-jaune_light {
  border: 4px dotted var(--jaune_light);
}

.border-all-4-double-jaune_light {
  border: 4px double var(--jaune_light);
}

.border-all-4-groove-jaune_light {
  border: 4px groove var(--jaune_light);
}

.border-all-4-ridge-jaune_light {
  border: 4px ridge var(--jaune_light);
}

.border-all-4-inset-jaune_light {
  border: 4px inset var(--jaune_light);
}

.border-all-4-outset-jaune_light {
  border: 4px outset var(--jaune_light);
}

.border-all-4-none-jaune_light {
  border: 4px none var(--jaune_light);
}

.border-all-4-hidden-jaune_light {
  border: 4px hidden var(--jaune_light);
}

.border-all-4-solid-jaune_dark {
  border: 4px solid var(--jaune_dark);
}

.border-all-4-dashed-jaune_dark {
  border: 4px dashed var(--jaune_dark);
}

.border-all-4-dotted-jaune_dark {
  border: 4px dotted var(--jaune_dark);
}

.border-all-4-double-jaune_dark {
  border: 4px double var(--jaune_dark);
}

.border-all-4-groove-jaune_dark {
  border: 4px groove var(--jaune_dark);
}

.border-all-4-ridge-jaune_dark {
  border: 4px ridge var(--jaune_dark);
}

.border-all-4-inset-jaune_dark {
  border: 4px inset var(--jaune_dark);
}

.border-all-4-outset-jaune_dark {
  border: 4px outset var(--jaune_dark);
}

.border-all-4-none-jaune_dark {
  border: 4px none var(--jaune_dark);
}

.border-all-4-hidden-jaune_dark {
  border: 4px hidden var(--jaune_dark);
}

.border-all-4-solid-orange_normal {
  border: 4px solid var(--orange_normal);
}

.border-all-4-dashed-orange_normal {
  border: 4px dashed var(--orange_normal);
}

.border-all-4-dotted-orange_normal {
  border: 4px dotted var(--orange_normal);
}

.border-all-4-double-orange_normal {
  border: 4px double var(--orange_normal);
}

.border-all-4-groove-orange_normal {
  border: 4px groove var(--orange_normal);
}

.border-all-4-ridge-orange_normal {
  border: 4px ridge var(--orange_normal);
}

.border-all-4-inset-orange_normal {
  border: 4px inset var(--orange_normal);
}

.border-all-4-outset-orange_normal {
  border: 4px outset var(--orange_normal);
}

.border-all-4-none-orange_normal {
  border: 4px none var(--orange_normal);
}

.border-all-4-hidden-orange_normal {
  border: 4px hidden var(--orange_normal);
}

.border-all-4-solid-orange_dark {
  border: 4px solid var(--orange_dark);
}

.border-all-4-dashed-orange_dark {
  border: 4px dashed var(--orange_dark);
}

.border-all-4-dotted-orange_dark {
  border: 4px dotted var(--orange_dark);
}

.border-all-4-double-orange_dark {
  border: 4px double var(--orange_dark);
}

.border-all-4-groove-orange_dark {
  border: 4px groove var(--orange_dark);
}

.border-all-4-ridge-orange_dark {
  border: 4px ridge var(--orange_dark);
}

.border-all-4-inset-orange_dark {
  border: 4px inset var(--orange_dark);
}

.border-all-4-outset-orange_dark {
  border: 4px outset var(--orange_dark);
}

.border-all-4-none-orange_dark {
  border: 4px none var(--orange_dark);
}

.border-all-4-hidden-orange_dark {
  border: 4px hidden var(--orange_dark);
}

.border-all-4-solid-vert_light {
  border: 4px solid var(--vert_light);
}

.border-all-4-dashed-vert_light {
  border: 4px dashed var(--vert_light);
}

.border-all-4-dotted-vert_light {
  border: 4px dotted var(--vert_light);
}

.border-all-4-double-vert_light {
  border: 4px double var(--vert_light);
}

.border-all-4-groove-vert_light {
  border: 4px groove var(--vert_light);
}

.border-all-4-ridge-vert_light {
  border: 4px ridge var(--vert_light);
}

.border-all-4-inset-vert_light {
  border: 4px inset var(--vert_light);
}

.border-all-4-outset-vert_light {
  border: 4px outset var(--vert_light);
}

.border-all-4-none-vert_light {
  border: 4px none var(--vert_light);
}

.border-all-4-hidden-vert_light {
  border: 4px hidden var(--vert_light);
}

.border-all-4-solid-vert_dark {
  border: 4px solid var(--vert_dark);
}

.border-all-4-dashed-vert_dark {
  border: 4px dashed var(--vert_dark);
}

.border-all-4-dotted-vert_dark {
  border: 4px dotted var(--vert_dark);
}

.border-all-4-double-vert_dark {
  border: 4px double var(--vert_dark);
}

.border-all-4-groove-vert_dark {
  border: 4px groove var(--vert_dark);
}

.border-all-4-ridge-vert_dark {
  border: 4px ridge var(--vert_dark);
}

.border-all-4-inset-vert_dark {
  border: 4px inset var(--vert_dark);
}

.border-all-4-outset-vert_dark {
  border: 4px outset var(--vert_dark);
}

.border-all-4-none-vert_dark {
  border: 4px none var(--vert_dark);
}

.border-all-4-hidden-vert_dark {
  border: 4px hidden var(--vert_dark);
}

.border-all-4-solid-bleu_turquoise_light {
  border: 4px solid var(--bleu_turquoise_light);
}

.border-all-4-dashed-bleu_turquoise_light {
  border: 4px dashed var(--bleu_turquoise_light);
}

.border-all-4-dotted-bleu_turquoise_light {
  border: 4px dotted var(--bleu_turquoise_light);
}

.border-all-4-double-bleu_turquoise_light {
  border: 4px double var(--bleu_turquoise_light);
}

.border-all-4-groove-bleu_turquoise_light {
  border: 4px groove var(--bleu_turquoise_light);
}

.border-all-4-ridge-bleu_turquoise_light {
  border: 4px ridge var(--bleu_turquoise_light);
}

.border-all-4-inset-bleu_turquoise_light {
  border: 4px inset var(--bleu_turquoise_light);
}

.border-all-4-outset-bleu_turquoise_light {
  border: 4px outset var(--bleu_turquoise_light);
}

.border-all-4-none-bleu_turquoise_light {
  border: 4px none var(--bleu_turquoise_light);
}

.border-all-4-hidden-bleu_turquoise_light {
  border: 4px hidden var(--bleu_turquoise_light);
}

.border-all-4-solid-bleu_turquoise_middle {
  border: 4px solid var(--bleu_turquoise_middle);
}

.border-all-4-dashed-bleu_turquoise_middle {
  border: 4px dashed var(--bleu_turquoise_middle);
}

.border-all-4-dotted-bleu_turquoise_middle {
  border: 4px dotted var(--bleu_turquoise_middle);
}

.border-all-4-double-bleu_turquoise_middle {
  border: 4px double var(--bleu_turquoise_middle);
}

.border-all-4-groove-bleu_turquoise_middle {
  border: 4px groove var(--bleu_turquoise_middle);
}

.border-all-4-ridge-bleu_turquoise_middle {
  border: 4px ridge var(--bleu_turquoise_middle);
}

.border-all-4-inset-bleu_turquoise_middle {
  border: 4px inset var(--bleu_turquoise_middle);
}

.border-all-4-outset-bleu_turquoise_middle {
  border: 4px outset var(--bleu_turquoise_middle);
}

.border-all-4-none-bleu_turquoise_middle {
  border: 4px none var(--bleu_turquoise_middle);
}

.border-all-4-hidden-bleu_turquoise_middle {
  border: 4px hidden var(--bleu_turquoise_middle);
}

.border-all-4-solid-bleu_turquoise_dark {
  border: 4px solid var(--bleu_turquoise_dark);
}

.border-all-4-dashed-bleu_turquoise_dark {
  border: 4px dashed var(--bleu_turquoise_dark);
}

.border-all-4-dotted-bleu_turquoise_dark {
  border: 4px dotted var(--bleu_turquoise_dark);
}

.border-all-4-double-bleu_turquoise_dark {
  border: 4px double var(--bleu_turquoise_dark);
}

.border-all-4-groove-bleu_turquoise_dark {
  border: 4px groove var(--bleu_turquoise_dark);
}

.border-all-4-ridge-bleu_turquoise_dark {
  border: 4px ridge var(--bleu_turquoise_dark);
}

.border-all-4-inset-bleu_turquoise_dark {
  border: 4px inset var(--bleu_turquoise_dark);
}

.border-all-4-outset-bleu_turquoise_dark {
  border: 4px outset var(--bleu_turquoise_dark);
}

.border-all-4-none-bleu_turquoise_dark {
  border: 4px none var(--bleu_turquoise_dark);
}

.border-all-4-hidden-bleu_turquoise_dark {
  border: 4px hidden var(--bleu_turquoise_dark);
}

.border-all-4-solid-bleu_light {
  border: 4px solid var(--bleu_light);
}

.border-all-4-dashed-bleu_light {
  border: 4px dashed var(--bleu_light);
}

.border-all-4-dotted-bleu_light {
  border: 4px dotted var(--bleu_light);
}

.border-all-4-double-bleu_light {
  border: 4px double var(--bleu_light);
}

.border-all-4-groove-bleu_light {
  border: 4px groove var(--bleu_light);
}

.border-all-4-ridge-bleu_light {
  border: 4px ridge var(--bleu_light);
}

.border-all-4-inset-bleu_light {
  border: 4px inset var(--bleu_light);
}

.border-all-4-outset-bleu_light {
  border: 4px outset var(--bleu_light);
}

.border-all-4-none-bleu_light {
  border: 4px none var(--bleu_light);
}

.border-all-4-hidden-bleu_light {
  border: 4px hidden var(--bleu_light);
}

.border-all-4-solid-bleu_dark {
  border: 4px solid var(--bleu_dark);
}

.border-all-4-dashed-bleu_dark {
  border: 4px dashed var(--bleu_dark);
}

.border-all-4-dotted-bleu_dark {
  border: 4px dotted var(--bleu_dark);
}

.border-all-4-double-bleu_dark {
  border: 4px double var(--bleu_dark);
}

.border-all-4-groove-bleu_dark {
  border: 4px groove var(--bleu_dark);
}

.border-all-4-ridge-bleu_dark {
  border: 4px ridge var(--bleu_dark);
}

.border-all-4-inset-bleu_dark {
  border: 4px inset var(--bleu_dark);
}

.border-all-4-outset-bleu_dark {
  border: 4px outset var(--bleu_dark);
}

.border-all-4-none-bleu_dark {
  border: 4px none var(--bleu_dark);
}

.border-all-4-hidden-bleu_dark {
  border: 4px hidden var(--bleu_dark);
}

.border-all-4-solid-bleu_klein_light {
  border: 4px solid var(--bleu_klein_light);
}

.border-all-4-dashed-bleu_klein_light {
  border: 4px dashed var(--bleu_klein_light);
}

.border-all-4-dotted-bleu_klein_light {
  border: 4px dotted var(--bleu_klein_light);
}

.border-all-4-double-bleu_klein_light {
  border: 4px double var(--bleu_klein_light);
}

.border-all-4-groove-bleu_klein_light {
  border: 4px groove var(--bleu_klein_light);
}

.border-all-4-ridge-bleu_klein_light {
  border: 4px ridge var(--bleu_klein_light);
}

.border-all-4-inset-bleu_klein_light {
  border: 4px inset var(--bleu_klein_light);
}

.border-all-4-outset-bleu_klein_light {
  border: 4px outset var(--bleu_klein_light);
}

.border-all-4-none-bleu_klein_light {
  border: 4px none var(--bleu_klein_light);
}

.border-all-4-hidden-bleu_klein_light {
  border: 4px hidden var(--bleu_klein_light);
}

.border-all-4-solid-bleu_klein_dark {
  border: 4px solid var(--bleu_klein_dark);
}

.border-all-4-dashed-bleu_klein_dark {
  border: 4px dashed var(--bleu_klein_dark);
}

.border-all-4-dotted-bleu_klein_dark {
  border: 4px dotted var(--bleu_klein_dark);
}

.border-all-4-double-bleu_klein_dark {
  border: 4px double var(--bleu_klein_dark);
}

.border-all-4-groove-bleu_klein_dark {
  border: 4px groove var(--bleu_klein_dark);
}

.border-all-4-ridge-bleu_klein_dark {
  border: 4px ridge var(--bleu_klein_dark);
}

.border-all-4-inset-bleu_klein_dark {
  border: 4px inset var(--bleu_klein_dark);
}

.border-all-4-outset-bleu_klein_dark {
  border: 4px outset var(--bleu_klein_dark);
}

.border-all-4-none-bleu_klein_dark {
  border: 4px none var(--bleu_klein_dark);
}

.border-all-4-hidden-bleu_klein_dark {
  border: 4px hidden var(--bleu_klein_dark);
}

.border-all-4-solid-violet_light {
  border: 4px solid var(--violet_light);
}

.border-all-4-dashed-violet_light {
  border: 4px dashed var(--violet_light);
}

.border-all-4-dotted-violet_light {
  border: 4px dotted var(--violet_light);
}

.border-all-4-double-violet_light {
  border: 4px double var(--violet_light);
}

.border-all-4-groove-violet_light {
  border: 4px groove var(--violet_light);
}

.border-all-4-ridge-violet_light {
  border: 4px ridge var(--violet_light);
}

.border-all-4-inset-violet_light {
  border: 4px inset var(--violet_light);
}

.border-all-4-outset-violet_light {
  border: 4px outset var(--violet_light);
}

.border-all-4-none-violet_light {
  border: 4px none var(--violet_light);
}

.border-all-4-hidden-violet_light {
  border: 4px hidden var(--violet_light);
}

.border-all-4-solid-violet_dark {
  border: 4px solid var(--violet_dark);
}

.border-all-4-dashed-violet_dark {
  border: 4px dashed var(--violet_dark);
}

.border-all-4-dotted-violet_dark {
  border: 4px dotted var(--violet_dark);
}

.border-all-4-double-violet_dark {
  border: 4px double var(--violet_dark);
}

.border-all-4-groove-violet_dark {
  border: 4px groove var(--violet_dark);
}

.border-all-4-ridge-violet_dark {
  border: 4px ridge var(--violet_dark);
}

.border-all-4-inset-violet_dark {
  border: 4px inset var(--violet_dark);
}

.border-all-4-outset-violet_dark {
  border: 4px outset var(--violet_dark);
}

.border-all-4-none-violet_dark {
  border: 4px none var(--violet_dark);
}

.border-all-4-hidden-violet_dark {
  border: 4px hidden var(--violet_dark);
}

.border-all-4-solid-mauve {
  border: 4px solid var(--mauve);
}

.border-all-4-dashed-mauve {
  border: 4px dashed var(--mauve);
}

.border-all-4-dotted-mauve {
  border: 4px dotted var(--mauve);
}

.border-all-4-double-mauve {
  border: 4px double var(--mauve);
}

.border-all-4-groove-mauve {
  border: 4px groove var(--mauve);
}

.border-all-4-ridge-mauve {
  border: 4px ridge var(--mauve);
}

.border-all-4-inset-mauve {
  border: 4px inset var(--mauve);
}

.border-all-4-outset-mauve {
  border: 4px outset var(--mauve);
}

.border-all-4-none-mauve {
  border: 4px none var(--mauve);
}

.border-all-4-hidden-mauve {
  border: 4px hidden var(--mauve);
}

.border-all-4-solid-rose_light {
  border: 4px solid var(--rose_light);
}

.border-all-4-dashed-rose_light {
  border: 4px dashed var(--rose_light);
}

.border-all-4-dotted-rose_light {
  border: 4px dotted var(--rose_light);
}

.border-all-4-double-rose_light {
  border: 4px double var(--rose_light);
}

.border-all-4-groove-rose_light {
  border: 4px groove var(--rose_light);
}

.border-all-4-ridge-rose_light {
  border: 4px ridge var(--rose_light);
}

.border-all-4-inset-rose_light {
  border: 4px inset var(--rose_light);
}

.border-all-4-outset-rose_light {
  border: 4px outset var(--rose_light);
}

.border-all-4-none-rose_light {
  border: 4px none var(--rose_light);
}

.border-all-4-hidden-rose_light {
  border: 4px hidden var(--rose_light);
}

.border-all-4-solid-rose_dark {
  border: 4px solid var(--rose_dark);
}

.border-all-4-dashed-rose_dark {
  border: 4px dashed var(--rose_dark);
}

.border-all-4-dotted-rose_dark {
  border: 4px dotted var(--rose_dark);
}

.border-all-4-double-rose_dark {
  border: 4px double var(--rose_dark);
}

.border-all-4-groove-rose_dark {
  border: 4px groove var(--rose_dark);
}

.border-all-4-ridge-rose_dark {
  border: 4px ridge var(--rose_dark);
}

.border-all-4-inset-rose_dark {
  border: 4px inset var(--rose_dark);
}

.border-all-4-outset-rose_dark {
  border: 4px outset var(--rose_dark);
}

.border-all-4-none-rose_dark {
  border: 4px none var(--rose_dark);
}

.border-all-4-hidden-rose_dark {
  border: 4px hidden var(--rose_dark);
}

.border-all-4-solid-rose_fushia {
  border: 4px solid var(--rose_fushia);
}

.border-all-4-dashed-rose_fushia {
  border: 4px dashed var(--rose_fushia);
}

.border-all-4-dotted-rose_fushia {
  border: 4px dotted var(--rose_fushia);
}

.border-all-4-double-rose_fushia {
  border: 4px double var(--rose_fushia);
}

.border-all-4-groove-rose_fushia {
  border: 4px groove var(--rose_fushia);
}

.border-all-4-ridge-rose_fushia {
  border: 4px ridge var(--rose_fushia);
}

.border-all-4-inset-rose_fushia {
  border: 4px inset var(--rose_fushia);
}

.border-all-4-outset-rose_fushia {
  border: 4px outset var(--rose_fushia);
}

.border-all-4-none-rose_fushia {
  border: 4px none var(--rose_fushia);
}

.border-all-4-hidden-rose_fushia {
  border: 4px hidden var(--rose_fushia);
}

.border-all-4-solid-rouge_framboise {
  border: 4px solid var(--rouge_framboise);
}

.border-all-4-dashed-rouge_framboise {
  border: 4px dashed var(--rouge_framboise);
}

.border-all-4-dotted-rouge_framboise {
  border: 4px dotted var(--rouge_framboise);
}

.border-all-4-double-rouge_framboise {
  border: 4px double var(--rouge_framboise);
}

.border-all-4-groove-rouge_framboise {
  border: 4px groove var(--rouge_framboise);
}

.border-all-4-ridge-rouge_framboise {
  border: 4px ridge var(--rouge_framboise);
}

.border-all-4-inset-rouge_framboise {
  border: 4px inset var(--rouge_framboise);
}

.border-all-4-outset-rouge_framboise {
  border: 4px outset var(--rouge_framboise);
}

.border-all-4-none-rouge_framboise {
  border: 4px none var(--rouge_framboise);
}

.border-all-4-hidden-rouge_framboise {
  border: 4px hidden var(--rouge_framboise);
}

.border-all-4-solid-rose_chair_light {
  border: 4px solid var(--rose_chair_light);
}

.border-all-4-dashed-rose_chair_light {
  border: 4px dashed var(--rose_chair_light);
}

.border-all-4-dotted-rose_chair_light {
  border: 4px dotted var(--rose_chair_light);
}

.border-all-4-double-rose_chair_light {
  border: 4px double var(--rose_chair_light);
}

.border-all-4-groove-rose_chair_light {
  border: 4px groove var(--rose_chair_light);
}

.border-all-4-ridge-rose_chair_light {
  border: 4px ridge var(--rose_chair_light);
}

.border-all-4-inset-rose_chair_light {
  border: 4px inset var(--rose_chair_light);
}

.border-all-4-outset-rose_chair_light {
  border: 4px outset var(--rose_chair_light);
}

.border-all-4-none-rose_chair_light {
  border: 4px none var(--rose_chair_light);
}

.border-all-4-hidden-rose_chair_light {
  border: 4px hidden var(--rose_chair_light);
}

.border-all-4-solid-rose_chair_dark {
  border: 4px solid var(--rose_chair_dark);
}

.border-all-4-dashed-rose_chair_dark {
  border: 4px dashed var(--rose_chair_dark);
}

.border-all-4-dotted-rose_chair_dark {
  border: 4px dotted var(--rose_chair_dark);
}

.border-all-4-double-rose_chair_dark {
  border: 4px double var(--rose_chair_dark);
}

.border-all-4-groove-rose_chair_dark {
  border: 4px groove var(--rose_chair_dark);
}

.border-all-4-ridge-rose_chair_dark {
  border: 4px ridge var(--rose_chair_dark);
}

.border-all-4-inset-rose_chair_dark {
  border: 4px inset var(--rose_chair_dark);
}

.border-all-4-outset-rose_chair_dark {
  border: 4px outset var(--rose_chair_dark);
}

.border-all-4-none-rose_chair_dark {
  border: 4px none var(--rose_chair_dark);
}

.border-all-4-hidden-rose_chair_dark {
  border: 4px hidden var(--rose_chair_dark);
}

.border-all-4-solid-vert_positif {
  border: 4px solid var(--vert_positif);
}

.border-all-4-dashed-vert_positif {
  border: 4px dashed var(--vert_positif);
}

.border-all-4-dotted-vert_positif {
  border: 4px dotted var(--vert_positif);
}

.border-all-4-double-vert_positif {
  border: 4px double var(--vert_positif);
}

.border-all-4-groove-vert_positif {
  border: 4px groove var(--vert_positif);
}

.border-all-4-ridge-vert_positif {
  border: 4px ridge var(--vert_positif);
}

.border-all-4-inset-vert_positif {
  border: 4px inset var(--vert_positif);
}

.border-all-4-outset-vert_positif {
  border: 4px outset var(--vert_positif);
}

.border-all-4-none-vert_positif {
  border: 4px none var(--vert_positif);
}

.border-all-4-hidden-vert_positif {
  border: 4px hidden var(--vert_positif);
}

.border-all-4-solid-rouge_negatif {
  border: 4px solid var(--rouge_negatif);
}

.border-all-4-dashed-rouge_negatif {
  border: 4px dashed var(--rouge_negatif);
}

.border-all-4-dotted-rouge_negatif {
  border: 4px dotted var(--rouge_negatif);
}

.border-all-4-double-rouge_negatif {
  border: 4px double var(--rouge_negatif);
}

.border-all-4-groove-rouge_negatif {
  border: 4px groove var(--rouge_negatif);
}

.border-all-4-ridge-rouge_negatif {
  border: 4px ridge var(--rouge_negatif);
}

.border-all-4-inset-rouge_negatif {
  border: 4px inset var(--rouge_negatif);
}

.border-all-4-outset-rouge_negatif {
  border: 4px outset var(--rouge_negatif);
}

.border-all-4-none-rouge_negatif {
  border: 4px none var(--rouge_negatif);
}

.border-all-4-hidden-rouge_negatif {
  border: 4px hidden var(--rouge_negatif);
}

.border-all-4-solid-bleu_texte {
  border: 4px solid var(--bleu_texte);
}

.border-all-4-dashed-bleu_texte {
  border: 4px dashed var(--bleu_texte);
}

.border-all-4-dotted-bleu_texte {
  border: 4px dotted var(--bleu_texte);
}

.border-all-4-double-bleu_texte {
  border: 4px double var(--bleu_texte);
}

.border-all-4-groove-bleu_texte {
  border: 4px groove var(--bleu_texte);
}

.border-all-4-ridge-bleu_texte {
  border: 4px ridge var(--bleu_texte);
}

.border-all-4-inset-bleu_texte {
  border: 4px inset var(--bleu_texte);
}

.border-all-4-outset-bleu_texte {
  border: 4px outset var(--bleu_texte);
}

.border-all-4-none-bleu_texte {
  border: 4px none var(--bleu_texte);
}

.border-all-4-hidden-bleu_texte {
  border: 4px hidden var(--bleu_texte);
}

.border-all-4-solid-alerte_vert_positif {
  border: 4px solid var(--alerte_vert_positif);
}

.border-all-4-dashed-alerte_vert_positif {
  border: 4px dashed var(--alerte_vert_positif);
}

.border-all-4-dotted-alerte_vert_positif {
  border: 4px dotted var(--alerte_vert_positif);
}

.border-all-4-double-alerte_vert_positif {
  border: 4px double var(--alerte_vert_positif);
}

.border-all-4-groove-alerte_vert_positif {
  border: 4px groove var(--alerte_vert_positif);
}

.border-all-4-ridge-alerte_vert_positif {
  border: 4px ridge var(--alerte_vert_positif);
}

.border-all-4-inset-alerte_vert_positif {
  border: 4px inset var(--alerte_vert_positif);
}

.border-all-4-outset-alerte_vert_positif {
  border: 4px outset var(--alerte_vert_positif);
}

.border-all-4-none-alerte_vert_positif {
  border: 4px none var(--alerte_vert_positif);
}

.border-all-4-hidden-alerte_vert_positif {
  border: 4px hidden var(--alerte_vert_positif);
}

.border-all-4-solid-alerte_rouge_negatif {
  border: 4px solid var(--alerte_rouge_negatif);
}

.border-all-4-dashed-alerte_rouge_negatif {
  border: 4px dashed var(--alerte_rouge_negatif);
}

.border-all-4-dotted-alerte_rouge_negatif {
  border: 4px dotted var(--alerte_rouge_negatif);
}

.border-all-4-double-alerte_rouge_negatif {
  border: 4px double var(--alerte_rouge_negatif);
}

.border-all-4-groove-alerte_rouge_negatif {
  border: 4px groove var(--alerte_rouge_negatif);
}

.border-all-4-ridge-alerte_rouge_negatif {
  border: 4px ridge var(--alerte_rouge_negatif);
}

.border-all-4-inset-alerte_rouge_negatif {
  border: 4px inset var(--alerte_rouge_negatif);
}

.border-all-4-outset-alerte_rouge_negatif {
  border: 4px outset var(--alerte_rouge_negatif);
}

.border-all-4-none-alerte_rouge_negatif {
  border: 4px none var(--alerte_rouge_negatif);
}

.border-all-4-hidden-alerte_rouge_negatif {
  border: 4px hidden var(--alerte_rouge_negatif);
}

.border-all-4-solid-alerte_bleu_information {
  border: 4px solid var(--alerte_bleu_information);
}

.border-all-4-dashed-alerte_bleu_information {
  border: 4px dashed var(--alerte_bleu_information);
}

.border-all-4-dotted-alerte_bleu_information {
  border: 4px dotted var(--alerte_bleu_information);
}

.border-all-4-double-alerte_bleu_information {
  border: 4px double var(--alerte_bleu_information);
}

.border-all-4-groove-alerte_bleu_information {
  border: 4px groove var(--alerte_bleu_information);
}

.border-all-4-ridge-alerte_bleu_information {
  border: 4px ridge var(--alerte_bleu_information);
}

.border-all-4-inset-alerte_bleu_information {
  border: 4px inset var(--alerte_bleu_information);
}

.border-all-4-outset-alerte_bleu_information {
  border: 4px outset var(--alerte_bleu_information);
}

.border-all-4-none-alerte_bleu_information {
  border: 4px none var(--alerte_bleu_information);
}

.border-all-4-hidden-alerte_bleu_information {
  border: 4px hidden var(--alerte_bleu_information);
}

.border-all-4-solid-alerte_jaune_attention {
  border: 4px solid var(--alerte_jaune_attention);
}

.border-all-4-dashed-alerte_jaune_attention {
  border: 4px dashed var(--alerte_jaune_attention);
}

.border-all-4-dotted-alerte_jaune_attention {
  border: 4px dotted var(--alerte_jaune_attention);
}

.border-all-4-double-alerte_jaune_attention {
  border: 4px double var(--alerte_jaune_attention);
}

.border-all-4-groove-alerte_jaune_attention {
  border: 4px groove var(--alerte_jaune_attention);
}

.border-all-4-ridge-alerte_jaune_attention {
  border: 4px ridge var(--alerte_jaune_attention);
}

.border-all-4-inset-alerte_jaune_attention {
  border: 4px inset var(--alerte_jaune_attention);
}

.border-all-4-outset-alerte_jaune_attention {
  border: 4px outset var(--alerte_jaune_attention);
}

.border-all-4-none-alerte_jaune_attention {
  border: 4px none var(--alerte_jaune_attention);
}

.border-all-4-hidden-alerte_jaune_attention {
  border: 4px hidden var(--alerte_jaune_attention);
}

.border-all-4-solid-blanc {
  border: 4px solid #fff;
}

.border-all-4-dashed-blanc {
  border: 4px dashed #fff;
}

.border-all-4-dotted-blanc {
  border: 4px dotted #fff;
}

.border-all-4-double-blanc {
  border: 4px double #fff;
}

.border-all-4-groove-blanc {
  border: 4px groove #fff;
}

.border-all-4-ridge-blanc {
  border: 4px ridge #fff;
}

.border-all-4-inset-blanc {
  border: 4px inset #fff;
}

.border-all-4-outset-blanc {
  border: 4px outset #fff;
}

.border-all-4-none-blanc {
  border: 4px #fff;
}

.border-all-4-hidden-blanc {
  border: 4px hidden #fff;
}

.border-all-4-solid-noir {
  border: 4px solid #222;
}

.border-all-4-dashed-noir {
  border: 4px dashed #222;
}

.border-all-4-dotted-noir {
  border: 4px dotted #222;
}

.border-all-4-double-noir {
  border: 4px double #222;
}

.border-all-4-groove-noir {
  border: 4px groove #222;
}

.border-all-4-ridge-noir {
  border: 4px ridge #222;
}

.border-all-4-inset-noir {
  border: 4px inset #222;
}

.border-all-4-outset-noir {
  border: 4px outset #222;
}

.border-all-4-none-noir {
  border: 4px #222;
}

.border-all-4-hidden-noir {
  border: 4px hidden #222;
}

.border-all-4-solid-gris_light {
  border: 4px solid var(--gris_light);
}

.border-all-4-dashed-gris_light {
  border: 4px dashed var(--gris_light);
}

.border-all-4-dotted-gris_light {
  border: 4px dotted var(--gris_light);
}

.border-all-4-double-gris_light {
  border: 4px double var(--gris_light);
}

.border-all-4-groove-gris_light {
  border: 4px groove var(--gris_light);
}

.border-all-4-ridge-gris_light {
  border: 4px ridge var(--gris_light);
}

.border-all-4-inset-gris_light {
  border: 4px inset var(--gris_light);
}

.border-all-4-outset-gris_light {
  border: 4px outset var(--gris_light);
}

.border-all-4-none-gris_light {
  border: 4px none var(--gris_light);
}

.border-all-4-hidden-gris_light {
  border: 4px hidden var(--gris_light);
}

.border-all-4-solid-gris_dark {
  border: 4px solid var(--gris_dark);
}

.border-all-4-dashed-gris_dark {
  border: 4px dashed var(--gris_dark);
}

.border-all-4-dotted-gris_dark {
  border: 4px dotted var(--gris_dark);
}

.border-all-4-double-gris_dark {
  border: 4px double var(--gris_dark);
}

.border-all-4-groove-gris_dark {
  border: 4px groove var(--gris_dark);
}

.border-all-4-ridge-gris_dark {
  border: 4px ridge var(--gris_dark);
}

.border-all-4-inset-gris_dark {
  border: 4px inset var(--gris_dark);
}

.border-all-4-outset-gris_dark {
  border: 4px outset var(--gris_dark);
}

.border-all-4-none-gris_dark {
  border: 4px none var(--gris_dark);
}

.border-all-4-hidden-gris_dark {
  border: 4px hidden var(--gris_dark);
}

.border-all-4-solid-gris_background {
  border: 4px solid var(--gris_background);
}

.border-all-4-dashed-gris_background {
  border: 4px dashed var(--gris_background);
}

.border-all-4-dotted-gris_background {
  border: 4px dotted var(--gris_background);
}

.border-all-4-double-gris_background {
  border: 4px double var(--gris_background);
}

.border-all-4-groove-gris_background {
  border: 4px groove var(--gris_background);
}

.border-all-4-ridge-gris_background {
  border: 4px ridge var(--gris_background);
}

.border-all-4-inset-gris_background {
  border: 4px inset var(--gris_background);
}

.border-all-4-outset-gris_background {
  border: 4px outset var(--gris_background);
}

.border-all-4-none-gris_background {
  border: 4px none var(--gris_background);
}

.border-all-4-hidden-gris_background {
  border: 4px hidden var(--gris_background);
}

.border-top-5-solid-corail_mh_brand {
  border-top: 5px solid var(--corail_mh_brand);
}

.border-top-5-dashed-corail_mh_brand {
  border-top: 5px dashed var(--corail_mh_brand);
}

.border-top-5-dotted-corail_mh_brand {
  border-top: 5px dotted var(--corail_mh_brand);
}

.border-top-5-double-corail_mh_brand {
  border-top: 5px double var(--corail_mh_brand);
}

.border-top-5-groove-corail_mh_brand {
  border-top: 5px groove var(--corail_mh_brand);
}

.border-top-5-ridge-corail_mh_brand {
  border-top: 5px ridge var(--corail_mh_brand);
}

.border-top-5-inset-corail_mh_brand {
  border-top: 5px inset var(--corail_mh_brand);
}

.border-top-5-outset-corail_mh_brand {
  border-top: 5px outset var(--corail_mh_brand);
}

.border-top-5-none-corail_mh_brand {
  border-top: 5px none var(--corail_mh_brand);
}

.border-top-5-hidden-corail_mh_brand {
  border-top: 5px hidden var(--corail_mh_brand);
}

.border-top-5-solid-corail_mh_dark {
  border-top: 5px solid var(--corail_mh_dark);
}

.border-top-5-dashed-corail_mh_dark {
  border-top: 5px dashed var(--corail_mh_dark);
}

.border-top-5-dotted-corail_mh_dark {
  border-top: 5px dotted var(--corail_mh_dark);
}

.border-top-5-double-corail_mh_dark {
  border-top: 5px double var(--corail_mh_dark);
}

.border-top-5-groove-corail_mh_dark {
  border-top: 5px groove var(--corail_mh_dark);
}

.border-top-5-ridge-corail_mh_dark {
  border-top: 5px ridge var(--corail_mh_dark);
}

.border-top-5-inset-corail_mh_dark {
  border-top: 5px inset var(--corail_mh_dark);
}

.border-top-5-outset-corail_mh_dark {
  border-top: 5px outset var(--corail_mh_dark);
}

.border-top-5-none-corail_mh_dark {
  border-top: 5px none var(--corail_mh_dark);
}

.border-top-5-hidden-corail_mh_dark {
  border-top: 5px hidden var(--corail_mh_dark);
}

.border-top-5-solid-corail_mh_light {
  border-top: 5px solid var(--corail_mh_light);
}

.border-top-5-dashed-corail_mh_light {
  border-top: 5px dashed var(--corail_mh_light);
}

.border-top-5-dotted-corail_mh_light {
  border-top: 5px dotted var(--corail_mh_light);
}

.border-top-5-double-corail_mh_light {
  border-top: 5px double var(--corail_mh_light);
}

.border-top-5-groove-corail_mh_light {
  border-top: 5px groove var(--corail_mh_light);
}

.border-top-5-ridge-corail_mh_light {
  border-top: 5px ridge var(--corail_mh_light);
}

.border-top-5-inset-corail_mh_light {
  border-top: 5px inset var(--corail_mh_light);
}

.border-top-5-outset-corail_mh_light {
  border-top: 5px outset var(--corail_mh_light);
}

.border-top-5-none-corail_mh_light {
  border-top: 5px none var(--corail_mh_light);
}

.border-top-5-hidden-corail_mh_light {
  border-top: 5px hidden var(--corail_mh_light);
}

.border-top-5-solid-gris_sable_light {
  border-top: 5px solid var(--gris_sable_light);
}

.border-top-5-dashed-gris_sable_light {
  border-top: 5px dashed var(--gris_sable_light);
}

.border-top-5-dotted-gris_sable_light {
  border-top: 5px dotted var(--gris_sable_light);
}

.border-top-5-double-gris_sable_light {
  border-top: 5px double var(--gris_sable_light);
}

.border-top-5-groove-gris_sable_light {
  border-top: 5px groove var(--gris_sable_light);
}

.border-top-5-ridge-gris_sable_light {
  border-top: 5px ridge var(--gris_sable_light);
}

.border-top-5-inset-gris_sable_light {
  border-top: 5px inset var(--gris_sable_light);
}

.border-top-5-outset-gris_sable_light {
  border-top: 5px outset var(--gris_sable_light);
}

.border-top-5-none-gris_sable_light {
  border-top: 5px none var(--gris_sable_light);
}

.border-top-5-hidden-gris_sable_light {
  border-top: 5px hidden var(--gris_sable_light);
}

.border-top-5-solid-gris_sable_dark {
  border-top: 5px solid var(--gris_sable_dark);
}

.border-top-5-dashed-gris_sable_dark {
  border-top: 5px dashed var(--gris_sable_dark);
}

.border-top-5-dotted-gris_sable_dark {
  border-top: 5px dotted var(--gris_sable_dark);
}

.border-top-5-double-gris_sable_dark {
  border-top: 5px double var(--gris_sable_dark);
}

.border-top-5-groove-gris_sable_dark {
  border-top: 5px groove var(--gris_sable_dark);
}

.border-top-5-ridge-gris_sable_dark {
  border-top: 5px ridge var(--gris_sable_dark);
}

.border-top-5-inset-gris_sable_dark {
  border-top: 5px inset var(--gris_sable_dark);
}

.border-top-5-outset-gris_sable_dark {
  border-top: 5px outset var(--gris_sable_dark);
}

.border-top-5-none-gris_sable_dark {
  border-top: 5px none var(--gris_sable_dark);
}

.border-top-5-hidden-gris_sable_dark {
  border-top: 5px hidden var(--gris_sable_dark);
}

.border-top-5-solid-jaune_ivoire_light {
  border-top: 5px solid var(--jaune_ivoire_light);
}

.border-top-5-dashed-jaune_ivoire_light {
  border-top: 5px dashed var(--jaune_ivoire_light);
}

.border-top-5-dotted-jaune_ivoire_light {
  border-top: 5px dotted var(--jaune_ivoire_light);
}

.border-top-5-double-jaune_ivoire_light {
  border-top: 5px double var(--jaune_ivoire_light);
}

.border-top-5-groove-jaune_ivoire_light {
  border-top: 5px groove var(--jaune_ivoire_light);
}

.border-top-5-ridge-jaune_ivoire_light {
  border-top: 5px ridge var(--jaune_ivoire_light);
}

.border-top-5-inset-jaune_ivoire_light {
  border-top: 5px inset var(--jaune_ivoire_light);
}

.border-top-5-outset-jaune_ivoire_light {
  border-top: 5px outset var(--jaune_ivoire_light);
}

.border-top-5-none-jaune_ivoire_light {
  border-top: 5px none var(--jaune_ivoire_light);
}

.border-top-5-hidden-jaune_ivoire_light {
  border-top: 5px hidden var(--jaune_ivoire_light);
}

.border-top-5-solid-jaune_ivoire_dark {
  border-top: 5px solid var(--jaune_ivoire_dark);
}

.border-top-5-dashed-jaune_ivoire_dark {
  border-top: 5px dashed var(--jaune_ivoire_dark);
}

.border-top-5-dotted-jaune_ivoire_dark {
  border-top: 5px dotted var(--jaune_ivoire_dark);
}

.border-top-5-double-jaune_ivoire_dark {
  border-top: 5px double var(--jaune_ivoire_dark);
}

.border-top-5-groove-jaune_ivoire_dark {
  border-top: 5px groove var(--jaune_ivoire_dark);
}

.border-top-5-ridge-jaune_ivoire_dark {
  border-top: 5px ridge var(--jaune_ivoire_dark);
}

.border-top-5-inset-jaune_ivoire_dark {
  border-top: 5px inset var(--jaune_ivoire_dark);
}

.border-top-5-outset-jaune_ivoire_dark {
  border-top: 5px outset var(--jaune_ivoire_dark);
}

.border-top-5-none-jaune_ivoire_dark {
  border-top: 5px none var(--jaune_ivoire_dark);
}

.border-top-5-hidden-jaune_ivoire_dark {
  border-top: 5px hidden var(--jaune_ivoire_dark);
}

.border-top-5-solid-jaune_light {
  border-top: 5px solid var(--jaune_light);
}

.border-top-5-dashed-jaune_light {
  border-top: 5px dashed var(--jaune_light);
}

.border-top-5-dotted-jaune_light {
  border-top: 5px dotted var(--jaune_light);
}

.border-top-5-double-jaune_light {
  border-top: 5px double var(--jaune_light);
}

.border-top-5-groove-jaune_light {
  border-top: 5px groove var(--jaune_light);
}

.border-top-5-ridge-jaune_light {
  border-top: 5px ridge var(--jaune_light);
}

.border-top-5-inset-jaune_light {
  border-top: 5px inset var(--jaune_light);
}

.border-top-5-outset-jaune_light {
  border-top: 5px outset var(--jaune_light);
}

.border-top-5-none-jaune_light {
  border-top: 5px none var(--jaune_light);
}

.border-top-5-hidden-jaune_light {
  border-top: 5px hidden var(--jaune_light);
}

.border-top-5-solid-jaune_dark {
  border-top: 5px solid var(--jaune_dark);
}

.border-top-5-dashed-jaune_dark {
  border-top: 5px dashed var(--jaune_dark);
}

.border-top-5-dotted-jaune_dark {
  border-top: 5px dotted var(--jaune_dark);
}

.border-top-5-double-jaune_dark {
  border-top: 5px double var(--jaune_dark);
}

.border-top-5-groove-jaune_dark {
  border-top: 5px groove var(--jaune_dark);
}

.border-top-5-ridge-jaune_dark {
  border-top: 5px ridge var(--jaune_dark);
}

.border-top-5-inset-jaune_dark {
  border-top: 5px inset var(--jaune_dark);
}

.border-top-5-outset-jaune_dark {
  border-top: 5px outset var(--jaune_dark);
}

.border-top-5-none-jaune_dark {
  border-top: 5px none var(--jaune_dark);
}

.border-top-5-hidden-jaune_dark {
  border-top: 5px hidden var(--jaune_dark);
}

.border-top-5-solid-orange_normal {
  border-top: 5px solid var(--orange_normal);
}

.border-top-5-dashed-orange_normal {
  border-top: 5px dashed var(--orange_normal);
}

.border-top-5-dotted-orange_normal {
  border-top: 5px dotted var(--orange_normal);
}

.border-top-5-double-orange_normal {
  border-top: 5px double var(--orange_normal);
}

.border-top-5-groove-orange_normal {
  border-top: 5px groove var(--orange_normal);
}

.border-top-5-ridge-orange_normal {
  border-top: 5px ridge var(--orange_normal);
}

.border-top-5-inset-orange_normal {
  border-top: 5px inset var(--orange_normal);
}

.border-top-5-outset-orange_normal {
  border-top: 5px outset var(--orange_normal);
}

.border-top-5-none-orange_normal {
  border-top: 5px none var(--orange_normal);
}

.border-top-5-hidden-orange_normal {
  border-top: 5px hidden var(--orange_normal);
}

.border-top-5-solid-orange_dark {
  border-top: 5px solid var(--orange_dark);
}

.border-top-5-dashed-orange_dark {
  border-top: 5px dashed var(--orange_dark);
}

.border-top-5-dotted-orange_dark {
  border-top: 5px dotted var(--orange_dark);
}

.border-top-5-double-orange_dark {
  border-top: 5px double var(--orange_dark);
}

.border-top-5-groove-orange_dark {
  border-top: 5px groove var(--orange_dark);
}

.border-top-5-ridge-orange_dark {
  border-top: 5px ridge var(--orange_dark);
}

.border-top-5-inset-orange_dark {
  border-top: 5px inset var(--orange_dark);
}

.border-top-5-outset-orange_dark {
  border-top: 5px outset var(--orange_dark);
}

.border-top-5-none-orange_dark {
  border-top: 5px none var(--orange_dark);
}

.border-top-5-hidden-orange_dark {
  border-top: 5px hidden var(--orange_dark);
}

.border-top-5-solid-vert_light {
  border-top: 5px solid var(--vert_light);
}

.border-top-5-dashed-vert_light {
  border-top: 5px dashed var(--vert_light);
}

.border-top-5-dotted-vert_light {
  border-top: 5px dotted var(--vert_light);
}

.border-top-5-double-vert_light {
  border-top: 5px double var(--vert_light);
}

.border-top-5-groove-vert_light {
  border-top: 5px groove var(--vert_light);
}

.border-top-5-ridge-vert_light {
  border-top: 5px ridge var(--vert_light);
}

.border-top-5-inset-vert_light {
  border-top: 5px inset var(--vert_light);
}

.border-top-5-outset-vert_light {
  border-top: 5px outset var(--vert_light);
}

.border-top-5-none-vert_light {
  border-top: 5px none var(--vert_light);
}

.border-top-5-hidden-vert_light {
  border-top: 5px hidden var(--vert_light);
}

.border-top-5-solid-vert_dark {
  border-top: 5px solid var(--vert_dark);
}

.border-top-5-dashed-vert_dark {
  border-top: 5px dashed var(--vert_dark);
}

.border-top-5-dotted-vert_dark {
  border-top: 5px dotted var(--vert_dark);
}

.border-top-5-double-vert_dark {
  border-top: 5px double var(--vert_dark);
}

.border-top-5-groove-vert_dark {
  border-top: 5px groove var(--vert_dark);
}

.border-top-5-ridge-vert_dark {
  border-top: 5px ridge var(--vert_dark);
}

.border-top-5-inset-vert_dark {
  border-top: 5px inset var(--vert_dark);
}

.border-top-5-outset-vert_dark {
  border-top: 5px outset var(--vert_dark);
}

.border-top-5-none-vert_dark {
  border-top: 5px none var(--vert_dark);
}

.border-top-5-hidden-vert_dark {
  border-top: 5px hidden var(--vert_dark);
}

.border-top-5-solid-bleu_turquoise_light {
  border-top: 5px solid var(--bleu_turquoise_light);
}

.border-top-5-dashed-bleu_turquoise_light {
  border-top: 5px dashed var(--bleu_turquoise_light);
}

.border-top-5-dotted-bleu_turquoise_light {
  border-top: 5px dotted var(--bleu_turquoise_light);
}

.border-top-5-double-bleu_turquoise_light {
  border-top: 5px double var(--bleu_turquoise_light);
}

.border-top-5-groove-bleu_turquoise_light {
  border-top: 5px groove var(--bleu_turquoise_light);
}

.border-top-5-ridge-bleu_turquoise_light {
  border-top: 5px ridge var(--bleu_turquoise_light);
}

.border-top-5-inset-bleu_turquoise_light {
  border-top: 5px inset var(--bleu_turquoise_light);
}

.border-top-5-outset-bleu_turquoise_light {
  border-top: 5px outset var(--bleu_turquoise_light);
}

.border-top-5-none-bleu_turquoise_light {
  border-top: 5px none var(--bleu_turquoise_light);
}

.border-top-5-hidden-bleu_turquoise_light {
  border-top: 5px hidden var(--bleu_turquoise_light);
}

.border-top-5-solid-bleu_turquoise_middle {
  border-top: 5px solid var(--bleu_turquoise_middle);
}

.border-top-5-dashed-bleu_turquoise_middle {
  border-top: 5px dashed var(--bleu_turquoise_middle);
}

.border-top-5-dotted-bleu_turquoise_middle {
  border-top: 5px dotted var(--bleu_turquoise_middle);
}

.border-top-5-double-bleu_turquoise_middle {
  border-top: 5px double var(--bleu_turquoise_middle);
}

.border-top-5-groove-bleu_turquoise_middle {
  border-top: 5px groove var(--bleu_turquoise_middle);
}

.border-top-5-ridge-bleu_turquoise_middle {
  border-top: 5px ridge var(--bleu_turquoise_middle);
}

.border-top-5-inset-bleu_turquoise_middle {
  border-top: 5px inset var(--bleu_turquoise_middle);
}

.border-top-5-outset-bleu_turquoise_middle {
  border-top: 5px outset var(--bleu_turquoise_middle);
}

.border-top-5-none-bleu_turquoise_middle {
  border-top: 5px none var(--bleu_turquoise_middle);
}

.border-top-5-hidden-bleu_turquoise_middle {
  border-top: 5px hidden var(--bleu_turquoise_middle);
}

.border-top-5-solid-bleu_turquoise_dark {
  border-top: 5px solid var(--bleu_turquoise_dark);
}

.border-top-5-dashed-bleu_turquoise_dark {
  border-top: 5px dashed var(--bleu_turquoise_dark);
}

.border-top-5-dotted-bleu_turquoise_dark {
  border-top: 5px dotted var(--bleu_turquoise_dark);
}

.border-top-5-double-bleu_turquoise_dark {
  border-top: 5px double var(--bleu_turquoise_dark);
}

.border-top-5-groove-bleu_turquoise_dark {
  border-top: 5px groove var(--bleu_turquoise_dark);
}

.border-top-5-ridge-bleu_turquoise_dark {
  border-top: 5px ridge var(--bleu_turquoise_dark);
}

.border-top-5-inset-bleu_turquoise_dark {
  border-top: 5px inset var(--bleu_turquoise_dark);
}

.border-top-5-outset-bleu_turquoise_dark {
  border-top: 5px outset var(--bleu_turquoise_dark);
}

.border-top-5-none-bleu_turquoise_dark {
  border-top: 5px none var(--bleu_turquoise_dark);
}

.border-top-5-hidden-bleu_turquoise_dark {
  border-top: 5px hidden var(--bleu_turquoise_dark);
}

.border-top-5-solid-bleu_light {
  border-top: 5px solid var(--bleu_light);
}

.border-top-5-dashed-bleu_light {
  border-top: 5px dashed var(--bleu_light);
}

.border-top-5-dotted-bleu_light {
  border-top: 5px dotted var(--bleu_light);
}

.border-top-5-double-bleu_light {
  border-top: 5px double var(--bleu_light);
}

.border-top-5-groove-bleu_light {
  border-top: 5px groove var(--bleu_light);
}

.border-top-5-ridge-bleu_light {
  border-top: 5px ridge var(--bleu_light);
}

.border-top-5-inset-bleu_light {
  border-top: 5px inset var(--bleu_light);
}

.border-top-5-outset-bleu_light {
  border-top: 5px outset var(--bleu_light);
}

.border-top-5-none-bleu_light {
  border-top: 5px none var(--bleu_light);
}

.border-top-5-hidden-bleu_light {
  border-top: 5px hidden var(--bleu_light);
}

.border-top-5-solid-bleu_dark {
  border-top: 5px solid var(--bleu_dark);
}

.border-top-5-dashed-bleu_dark {
  border-top: 5px dashed var(--bleu_dark);
}

.border-top-5-dotted-bleu_dark {
  border-top: 5px dotted var(--bleu_dark);
}

.border-top-5-double-bleu_dark {
  border-top: 5px double var(--bleu_dark);
}

.border-top-5-groove-bleu_dark {
  border-top: 5px groove var(--bleu_dark);
}

.border-top-5-ridge-bleu_dark {
  border-top: 5px ridge var(--bleu_dark);
}

.border-top-5-inset-bleu_dark {
  border-top: 5px inset var(--bleu_dark);
}

.border-top-5-outset-bleu_dark {
  border-top: 5px outset var(--bleu_dark);
}

.border-top-5-none-bleu_dark {
  border-top: 5px none var(--bleu_dark);
}

.border-top-5-hidden-bleu_dark {
  border-top: 5px hidden var(--bleu_dark);
}

.border-top-5-solid-bleu_klein_light {
  border-top: 5px solid var(--bleu_klein_light);
}

.border-top-5-dashed-bleu_klein_light {
  border-top: 5px dashed var(--bleu_klein_light);
}

.border-top-5-dotted-bleu_klein_light {
  border-top: 5px dotted var(--bleu_klein_light);
}

.border-top-5-double-bleu_klein_light {
  border-top: 5px double var(--bleu_klein_light);
}

.border-top-5-groove-bleu_klein_light {
  border-top: 5px groove var(--bleu_klein_light);
}

.border-top-5-ridge-bleu_klein_light {
  border-top: 5px ridge var(--bleu_klein_light);
}

.border-top-5-inset-bleu_klein_light {
  border-top: 5px inset var(--bleu_klein_light);
}

.border-top-5-outset-bleu_klein_light {
  border-top: 5px outset var(--bleu_klein_light);
}

.border-top-5-none-bleu_klein_light {
  border-top: 5px none var(--bleu_klein_light);
}

.border-top-5-hidden-bleu_klein_light {
  border-top: 5px hidden var(--bleu_klein_light);
}

.border-top-5-solid-bleu_klein_dark {
  border-top: 5px solid var(--bleu_klein_dark);
}

.border-top-5-dashed-bleu_klein_dark {
  border-top: 5px dashed var(--bleu_klein_dark);
}

.border-top-5-dotted-bleu_klein_dark {
  border-top: 5px dotted var(--bleu_klein_dark);
}

.border-top-5-double-bleu_klein_dark {
  border-top: 5px double var(--bleu_klein_dark);
}

.border-top-5-groove-bleu_klein_dark {
  border-top: 5px groove var(--bleu_klein_dark);
}

.border-top-5-ridge-bleu_klein_dark {
  border-top: 5px ridge var(--bleu_klein_dark);
}

.border-top-5-inset-bleu_klein_dark {
  border-top: 5px inset var(--bleu_klein_dark);
}

.border-top-5-outset-bleu_klein_dark {
  border-top: 5px outset var(--bleu_klein_dark);
}

.border-top-5-none-bleu_klein_dark {
  border-top: 5px none var(--bleu_klein_dark);
}

.border-top-5-hidden-bleu_klein_dark {
  border-top: 5px hidden var(--bleu_klein_dark);
}

.border-top-5-solid-violet_light {
  border-top: 5px solid var(--violet_light);
}

.border-top-5-dashed-violet_light {
  border-top: 5px dashed var(--violet_light);
}

.border-top-5-dotted-violet_light {
  border-top: 5px dotted var(--violet_light);
}

.border-top-5-double-violet_light {
  border-top: 5px double var(--violet_light);
}

.border-top-5-groove-violet_light {
  border-top: 5px groove var(--violet_light);
}

.border-top-5-ridge-violet_light {
  border-top: 5px ridge var(--violet_light);
}

.border-top-5-inset-violet_light {
  border-top: 5px inset var(--violet_light);
}

.border-top-5-outset-violet_light {
  border-top: 5px outset var(--violet_light);
}

.border-top-5-none-violet_light {
  border-top: 5px none var(--violet_light);
}

.border-top-5-hidden-violet_light {
  border-top: 5px hidden var(--violet_light);
}

.border-top-5-solid-violet_dark {
  border-top: 5px solid var(--violet_dark);
}

.border-top-5-dashed-violet_dark {
  border-top: 5px dashed var(--violet_dark);
}

.border-top-5-dotted-violet_dark {
  border-top: 5px dotted var(--violet_dark);
}

.border-top-5-double-violet_dark {
  border-top: 5px double var(--violet_dark);
}

.border-top-5-groove-violet_dark {
  border-top: 5px groove var(--violet_dark);
}

.border-top-5-ridge-violet_dark {
  border-top: 5px ridge var(--violet_dark);
}

.border-top-5-inset-violet_dark {
  border-top: 5px inset var(--violet_dark);
}

.border-top-5-outset-violet_dark {
  border-top: 5px outset var(--violet_dark);
}

.border-top-5-none-violet_dark {
  border-top: 5px none var(--violet_dark);
}

.border-top-5-hidden-violet_dark {
  border-top: 5px hidden var(--violet_dark);
}

.border-top-5-solid-mauve {
  border-top: 5px solid var(--mauve);
}

.border-top-5-dashed-mauve {
  border-top: 5px dashed var(--mauve);
}

.border-top-5-dotted-mauve {
  border-top: 5px dotted var(--mauve);
}

.border-top-5-double-mauve {
  border-top: 5px double var(--mauve);
}

.border-top-5-groove-mauve {
  border-top: 5px groove var(--mauve);
}

.border-top-5-ridge-mauve {
  border-top: 5px ridge var(--mauve);
}

.border-top-5-inset-mauve {
  border-top: 5px inset var(--mauve);
}

.border-top-5-outset-mauve {
  border-top: 5px outset var(--mauve);
}

.border-top-5-none-mauve {
  border-top: 5px none var(--mauve);
}

.border-top-5-hidden-mauve {
  border-top: 5px hidden var(--mauve);
}

.border-top-5-solid-rose_light {
  border-top: 5px solid var(--rose_light);
}

.border-top-5-dashed-rose_light {
  border-top: 5px dashed var(--rose_light);
}

.border-top-5-dotted-rose_light {
  border-top: 5px dotted var(--rose_light);
}

.border-top-5-double-rose_light {
  border-top: 5px double var(--rose_light);
}

.border-top-5-groove-rose_light {
  border-top: 5px groove var(--rose_light);
}

.border-top-5-ridge-rose_light {
  border-top: 5px ridge var(--rose_light);
}

.border-top-5-inset-rose_light {
  border-top: 5px inset var(--rose_light);
}

.border-top-5-outset-rose_light {
  border-top: 5px outset var(--rose_light);
}

.border-top-5-none-rose_light {
  border-top: 5px none var(--rose_light);
}

.border-top-5-hidden-rose_light {
  border-top: 5px hidden var(--rose_light);
}

.border-top-5-solid-rose_dark {
  border-top: 5px solid var(--rose_dark);
}

.border-top-5-dashed-rose_dark {
  border-top: 5px dashed var(--rose_dark);
}

.border-top-5-dotted-rose_dark {
  border-top: 5px dotted var(--rose_dark);
}

.border-top-5-double-rose_dark {
  border-top: 5px double var(--rose_dark);
}

.border-top-5-groove-rose_dark {
  border-top: 5px groove var(--rose_dark);
}

.border-top-5-ridge-rose_dark {
  border-top: 5px ridge var(--rose_dark);
}

.border-top-5-inset-rose_dark {
  border-top: 5px inset var(--rose_dark);
}

.border-top-5-outset-rose_dark {
  border-top: 5px outset var(--rose_dark);
}

.border-top-5-none-rose_dark {
  border-top: 5px none var(--rose_dark);
}

.border-top-5-hidden-rose_dark {
  border-top: 5px hidden var(--rose_dark);
}

.border-top-5-solid-rose_fushia {
  border-top: 5px solid var(--rose_fushia);
}

.border-top-5-dashed-rose_fushia {
  border-top: 5px dashed var(--rose_fushia);
}

.border-top-5-dotted-rose_fushia {
  border-top: 5px dotted var(--rose_fushia);
}

.border-top-5-double-rose_fushia {
  border-top: 5px double var(--rose_fushia);
}

.border-top-5-groove-rose_fushia {
  border-top: 5px groove var(--rose_fushia);
}

.border-top-5-ridge-rose_fushia {
  border-top: 5px ridge var(--rose_fushia);
}

.border-top-5-inset-rose_fushia {
  border-top: 5px inset var(--rose_fushia);
}

.border-top-5-outset-rose_fushia {
  border-top: 5px outset var(--rose_fushia);
}

.border-top-5-none-rose_fushia {
  border-top: 5px none var(--rose_fushia);
}

.border-top-5-hidden-rose_fushia {
  border-top: 5px hidden var(--rose_fushia);
}

.border-top-5-solid-rouge_framboise {
  border-top: 5px solid var(--rouge_framboise);
}

.border-top-5-dashed-rouge_framboise {
  border-top: 5px dashed var(--rouge_framboise);
}

.border-top-5-dotted-rouge_framboise {
  border-top: 5px dotted var(--rouge_framboise);
}

.border-top-5-double-rouge_framboise {
  border-top: 5px double var(--rouge_framboise);
}

.border-top-5-groove-rouge_framboise {
  border-top: 5px groove var(--rouge_framboise);
}

.border-top-5-ridge-rouge_framboise {
  border-top: 5px ridge var(--rouge_framboise);
}

.border-top-5-inset-rouge_framboise {
  border-top: 5px inset var(--rouge_framboise);
}

.border-top-5-outset-rouge_framboise {
  border-top: 5px outset var(--rouge_framboise);
}

.border-top-5-none-rouge_framboise {
  border-top: 5px none var(--rouge_framboise);
}

.border-top-5-hidden-rouge_framboise {
  border-top: 5px hidden var(--rouge_framboise);
}

.border-top-5-solid-rose_chair_light {
  border-top: 5px solid var(--rose_chair_light);
}

.border-top-5-dashed-rose_chair_light {
  border-top: 5px dashed var(--rose_chair_light);
}

.border-top-5-dotted-rose_chair_light {
  border-top: 5px dotted var(--rose_chair_light);
}

.border-top-5-double-rose_chair_light {
  border-top: 5px double var(--rose_chair_light);
}

.border-top-5-groove-rose_chair_light {
  border-top: 5px groove var(--rose_chair_light);
}

.border-top-5-ridge-rose_chair_light {
  border-top: 5px ridge var(--rose_chair_light);
}

.border-top-5-inset-rose_chair_light {
  border-top: 5px inset var(--rose_chair_light);
}

.border-top-5-outset-rose_chair_light {
  border-top: 5px outset var(--rose_chair_light);
}

.border-top-5-none-rose_chair_light {
  border-top: 5px none var(--rose_chair_light);
}

.border-top-5-hidden-rose_chair_light {
  border-top: 5px hidden var(--rose_chair_light);
}

.border-top-5-solid-rose_chair_dark {
  border-top: 5px solid var(--rose_chair_dark);
}

.border-top-5-dashed-rose_chair_dark {
  border-top: 5px dashed var(--rose_chair_dark);
}

.border-top-5-dotted-rose_chair_dark {
  border-top: 5px dotted var(--rose_chair_dark);
}

.border-top-5-double-rose_chair_dark {
  border-top: 5px double var(--rose_chair_dark);
}

.border-top-5-groove-rose_chair_dark {
  border-top: 5px groove var(--rose_chair_dark);
}

.border-top-5-ridge-rose_chair_dark {
  border-top: 5px ridge var(--rose_chair_dark);
}

.border-top-5-inset-rose_chair_dark {
  border-top: 5px inset var(--rose_chair_dark);
}

.border-top-5-outset-rose_chair_dark {
  border-top: 5px outset var(--rose_chair_dark);
}

.border-top-5-none-rose_chair_dark {
  border-top: 5px none var(--rose_chair_dark);
}

.border-top-5-hidden-rose_chair_dark {
  border-top: 5px hidden var(--rose_chair_dark);
}

.border-top-5-solid-vert_positif {
  border-top: 5px solid var(--vert_positif);
}

.border-top-5-dashed-vert_positif {
  border-top: 5px dashed var(--vert_positif);
}

.border-top-5-dotted-vert_positif {
  border-top: 5px dotted var(--vert_positif);
}

.border-top-5-double-vert_positif {
  border-top: 5px double var(--vert_positif);
}

.border-top-5-groove-vert_positif {
  border-top: 5px groove var(--vert_positif);
}

.border-top-5-ridge-vert_positif {
  border-top: 5px ridge var(--vert_positif);
}

.border-top-5-inset-vert_positif {
  border-top: 5px inset var(--vert_positif);
}

.border-top-5-outset-vert_positif {
  border-top: 5px outset var(--vert_positif);
}

.border-top-5-none-vert_positif {
  border-top: 5px none var(--vert_positif);
}

.border-top-5-hidden-vert_positif {
  border-top: 5px hidden var(--vert_positif);
}

.border-top-5-solid-rouge_negatif {
  border-top: 5px solid var(--rouge_negatif);
}

.border-top-5-dashed-rouge_negatif {
  border-top: 5px dashed var(--rouge_negatif);
}

.border-top-5-dotted-rouge_negatif {
  border-top: 5px dotted var(--rouge_negatif);
}

.border-top-5-double-rouge_negatif {
  border-top: 5px double var(--rouge_negatif);
}

.border-top-5-groove-rouge_negatif {
  border-top: 5px groove var(--rouge_negatif);
}

.border-top-5-ridge-rouge_negatif {
  border-top: 5px ridge var(--rouge_negatif);
}

.border-top-5-inset-rouge_negatif {
  border-top: 5px inset var(--rouge_negatif);
}

.border-top-5-outset-rouge_negatif {
  border-top: 5px outset var(--rouge_negatif);
}

.border-top-5-none-rouge_negatif {
  border-top: 5px none var(--rouge_negatif);
}

.border-top-5-hidden-rouge_negatif {
  border-top: 5px hidden var(--rouge_negatif);
}

.border-top-5-solid-bleu_texte {
  border-top: 5px solid var(--bleu_texte);
}

.border-top-5-dashed-bleu_texte {
  border-top: 5px dashed var(--bleu_texte);
}

.border-top-5-dotted-bleu_texte {
  border-top: 5px dotted var(--bleu_texte);
}

.border-top-5-double-bleu_texte {
  border-top: 5px double var(--bleu_texte);
}

.border-top-5-groove-bleu_texte {
  border-top: 5px groove var(--bleu_texte);
}

.border-top-5-ridge-bleu_texte {
  border-top: 5px ridge var(--bleu_texte);
}

.border-top-5-inset-bleu_texte {
  border-top: 5px inset var(--bleu_texte);
}

.border-top-5-outset-bleu_texte {
  border-top: 5px outset var(--bleu_texte);
}

.border-top-5-none-bleu_texte {
  border-top: 5px none var(--bleu_texte);
}

.border-top-5-hidden-bleu_texte {
  border-top: 5px hidden var(--bleu_texte);
}

.border-top-5-solid-alerte_vert_positif {
  border-top: 5px solid var(--alerte_vert_positif);
}

.border-top-5-dashed-alerte_vert_positif {
  border-top: 5px dashed var(--alerte_vert_positif);
}

.border-top-5-dotted-alerte_vert_positif {
  border-top: 5px dotted var(--alerte_vert_positif);
}

.border-top-5-double-alerte_vert_positif {
  border-top: 5px double var(--alerte_vert_positif);
}

.border-top-5-groove-alerte_vert_positif {
  border-top: 5px groove var(--alerte_vert_positif);
}

.border-top-5-ridge-alerte_vert_positif {
  border-top: 5px ridge var(--alerte_vert_positif);
}

.border-top-5-inset-alerte_vert_positif {
  border-top: 5px inset var(--alerte_vert_positif);
}

.border-top-5-outset-alerte_vert_positif {
  border-top: 5px outset var(--alerte_vert_positif);
}

.border-top-5-none-alerte_vert_positif {
  border-top: 5px none var(--alerte_vert_positif);
}

.border-top-5-hidden-alerte_vert_positif {
  border-top: 5px hidden var(--alerte_vert_positif);
}

.border-top-5-solid-alerte_rouge_negatif {
  border-top: 5px solid var(--alerte_rouge_negatif);
}

.border-top-5-dashed-alerte_rouge_negatif {
  border-top: 5px dashed var(--alerte_rouge_negatif);
}

.border-top-5-dotted-alerte_rouge_negatif {
  border-top: 5px dotted var(--alerte_rouge_negatif);
}

.border-top-5-double-alerte_rouge_negatif {
  border-top: 5px double var(--alerte_rouge_negatif);
}

.border-top-5-groove-alerte_rouge_negatif {
  border-top: 5px groove var(--alerte_rouge_negatif);
}

.border-top-5-ridge-alerte_rouge_negatif {
  border-top: 5px ridge var(--alerte_rouge_negatif);
}

.border-top-5-inset-alerte_rouge_negatif {
  border-top: 5px inset var(--alerte_rouge_negatif);
}

.border-top-5-outset-alerte_rouge_negatif {
  border-top: 5px outset var(--alerte_rouge_negatif);
}

.border-top-5-none-alerte_rouge_negatif {
  border-top: 5px none var(--alerte_rouge_negatif);
}

.border-top-5-hidden-alerte_rouge_negatif {
  border-top: 5px hidden var(--alerte_rouge_negatif);
}

.border-top-5-solid-alerte_bleu_information {
  border-top: 5px solid var(--alerte_bleu_information);
}

.border-top-5-dashed-alerte_bleu_information {
  border-top: 5px dashed var(--alerte_bleu_information);
}

.border-top-5-dotted-alerte_bleu_information {
  border-top: 5px dotted var(--alerte_bleu_information);
}

.border-top-5-double-alerte_bleu_information {
  border-top: 5px double var(--alerte_bleu_information);
}

.border-top-5-groove-alerte_bleu_information {
  border-top: 5px groove var(--alerte_bleu_information);
}

.border-top-5-ridge-alerte_bleu_information {
  border-top: 5px ridge var(--alerte_bleu_information);
}

.border-top-5-inset-alerte_bleu_information {
  border-top: 5px inset var(--alerte_bleu_information);
}

.border-top-5-outset-alerte_bleu_information {
  border-top: 5px outset var(--alerte_bleu_information);
}

.border-top-5-none-alerte_bleu_information {
  border-top: 5px none var(--alerte_bleu_information);
}

.border-top-5-hidden-alerte_bleu_information {
  border-top: 5px hidden var(--alerte_bleu_information);
}

.border-top-5-solid-alerte_jaune_attention {
  border-top: 5px solid var(--alerte_jaune_attention);
}

.border-top-5-dashed-alerte_jaune_attention {
  border-top: 5px dashed var(--alerte_jaune_attention);
}

.border-top-5-dotted-alerte_jaune_attention {
  border-top: 5px dotted var(--alerte_jaune_attention);
}

.border-top-5-double-alerte_jaune_attention {
  border-top: 5px double var(--alerte_jaune_attention);
}

.border-top-5-groove-alerte_jaune_attention {
  border-top: 5px groove var(--alerte_jaune_attention);
}

.border-top-5-ridge-alerte_jaune_attention {
  border-top: 5px ridge var(--alerte_jaune_attention);
}

.border-top-5-inset-alerte_jaune_attention {
  border-top: 5px inset var(--alerte_jaune_attention);
}

.border-top-5-outset-alerte_jaune_attention {
  border-top: 5px outset var(--alerte_jaune_attention);
}

.border-top-5-none-alerte_jaune_attention {
  border-top: 5px none var(--alerte_jaune_attention);
}

.border-top-5-hidden-alerte_jaune_attention {
  border-top: 5px hidden var(--alerte_jaune_attention);
}

.border-top-5-solid-blanc {
  border-top: 5px solid #fff;
}

.border-top-5-dashed-blanc {
  border-top: 5px dashed #fff;
}

.border-top-5-dotted-blanc {
  border-top: 5px dotted #fff;
}

.border-top-5-double-blanc {
  border-top: 5px double #fff;
}

.border-top-5-groove-blanc {
  border-top: 5px groove #fff;
}

.border-top-5-ridge-blanc {
  border-top: 5px ridge #fff;
}

.border-top-5-inset-blanc {
  border-top: 5px inset #fff;
}

.border-top-5-outset-blanc {
  border-top: 5px outset #fff;
}

.border-top-5-none-blanc {
  border-top: 5px #fff;
}

.border-top-5-hidden-blanc {
  border-top: 5px hidden #fff;
}

.border-top-5-solid-noir {
  border-top: 5px solid #222;
}

.border-top-5-dashed-noir {
  border-top: 5px dashed #222;
}

.border-top-5-dotted-noir {
  border-top: 5px dotted #222;
}

.border-top-5-double-noir {
  border-top: 5px double #222;
}

.border-top-5-groove-noir {
  border-top: 5px groove #222;
}

.border-top-5-ridge-noir {
  border-top: 5px ridge #222;
}

.border-top-5-inset-noir {
  border-top: 5px inset #222;
}

.border-top-5-outset-noir {
  border-top: 5px outset #222;
}

.border-top-5-none-noir {
  border-top: 5px #222;
}

.border-top-5-hidden-noir {
  border-top: 5px hidden #222;
}

.border-top-5-solid-gris_light {
  border-top: 5px solid var(--gris_light);
}

.border-top-5-dashed-gris_light {
  border-top: 5px dashed var(--gris_light);
}

.border-top-5-dotted-gris_light {
  border-top: 5px dotted var(--gris_light);
}

.border-top-5-double-gris_light {
  border-top: 5px double var(--gris_light);
}

.border-top-5-groove-gris_light {
  border-top: 5px groove var(--gris_light);
}

.border-top-5-ridge-gris_light {
  border-top: 5px ridge var(--gris_light);
}

.border-top-5-inset-gris_light {
  border-top: 5px inset var(--gris_light);
}

.border-top-5-outset-gris_light {
  border-top: 5px outset var(--gris_light);
}

.border-top-5-none-gris_light {
  border-top: 5px none var(--gris_light);
}

.border-top-5-hidden-gris_light {
  border-top: 5px hidden var(--gris_light);
}

.border-top-5-solid-gris_dark {
  border-top: 5px solid var(--gris_dark);
}

.border-top-5-dashed-gris_dark {
  border-top: 5px dashed var(--gris_dark);
}

.border-top-5-dotted-gris_dark {
  border-top: 5px dotted var(--gris_dark);
}

.border-top-5-double-gris_dark {
  border-top: 5px double var(--gris_dark);
}

.border-top-5-groove-gris_dark {
  border-top: 5px groove var(--gris_dark);
}

.border-top-5-ridge-gris_dark {
  border-top: 5px ridge var(--gris_dark);
}

.border-top-5-inset-gris_dark {
  border-top: 5px inset var(--gris_dark);
}

.border-top-5-outset-gris_dark {
  border-top: 5px outset var(--gris_dark);
}

.border-top-5-none-gris_dark {
  border-top: 5px none var(--gris_dark);
}

.border-top-5-hidden-gris_dark {
  border-top: 5px hidden var(--gris_dark);
}

.border-top-5-solid-gris_background {
  border-top: 5px solid var(--gris_background);
}

.border-top-5-dashed-gris_background {
  border-top: 5px dashed var(--gris_background);
}

.border-top-5-dotted-gris_background {
  border-top: 5px dotted var(--gris_background);
}

.border-top-5-double-gris_background {
  border-top: 5px double var(--gris_background);
}

.border-top-5-groove-gris_background {
  border-top: 5px groove var(--gris_background);
}

.border-top-5-ridge-gris_background {
  border-top: 5px ridge var(--gris_background);
}

.border-top-5-inset-gris_background {
  border-top: 5px inset var(--gris_background);
}

.border-top-5-outset-gris_background {
  border-top: 5px outset var(--gris_background);
}

.border-top-5-none-gris_background {
  border-top: 5px none var(--gris_background);
}

.border-top-5-hidden-gris_background {
  border-top: 5px hidden var(--gris_background);
}

.border-bottom-5-solid-corail_mh_brand {
  border-bottom: 5px solid var(--corail_mh_brand);
}

.border-bottom-5-dashed-corail_mh_brand {
  border-bottom: 5px dashed var(--corail_mh_brand);
}

.border-bottom-5-dotted-corail_mh_brand {
  border-bottom: 5px dotted var(--corail_mh_brand);
}

.border-bottom-5-double-corail_mh_brand {
  border-bottom: 5px double var(--corail_mh_brand);
}

.border-bottom-5-groove-corail_mh_brand {
  border-bottom: 5px groove var(--corail_mh_brand);
}

.border-bottom-5-ridge-corail_mh_brand {
  border-bottom: 5px ridge var(--corail_mh_brand);
}

.border-bottom-5-inset-corail_mh_brand {
  border-bottom: 5px inset var(--corail_mh_brand);
}

.border-bottom-5-outset-corail_mh_brand {
  border-bottom: 5px outset var(--corail_mh_brand);
}

.border-bottom-5-none-corail_mh_brand {
  border-bottom: 5px none var(--corail_mh_brand);
}

.border-bottom-5-hidden-corail_mh_brand {
  border-bottom: 5px hidden var(--corail_mh_brand);
}

.border-bottom-5-solid-corail_mh_dark {
  border-bottom: 5px solid var(--corail_mh_dark);
}

.border-bottom-5-dashed-corail_mh_dark {
  border-bottom: 5px dashed var(--corail_mh_dark);
}

.border-bottom-5-dotted-corail_mh_dark {
  border-bottom: 5px dotted var(--corail_mh_dark);
}

.border-bottom-5-double-corail_mh_dark {
  border-bottom: 5px double var(--corail_mh_dark);
}

.border-bottom-5-groove-corail_mh_dark {
  border-bottom: 5px groove var(--corail_mh_dark);
}

.border-bottom-5-ridge-corail_mh_dark {
  border-bottom: 5px ridge var(--corail_mh_dark);
}

.border-bottom-5-inset-corail_mh_dark {
  border-bottom: 5px inset var(--corail_mh_dark);
}

.border-bottom-5-outset-corail_mh_dark {
  border-bottom: 5px outset var(--corail_mh_dark);
}

.border-bottom-5-none-corail_mh_dark {
  border-bottom: 5px none var(--corail_mh_dark);
}

.border-bottom-5-hidden-corail_mh_dark {
  border-bottom: 5px hidden var(--corail_mh_dark);
}

.border-bottom-5-solid-corail_mh_light {
  border-bottom: 5px solid var(--corail_mh_light);
}

.border-bottom-5-dashed-corail_mh_light {
  border-bottom: 5px dashed var(--corail_mh_light);
}

.border-bottom-5-dotted-corail_mh_light {
  border-bottom: 5px dotted var(--corail_mh_light);
}

.border-bottom-5-double-corail_mh_light {
  border-bottom: 5px double var(--corail_mh_light);
}

.border-bottom-5-groove-corail_mh_light {
  border-bottom: 5px groove var(--corail_mh_light);
}

.border-bottom-5-ridge-corail_mh_light {
  border-bottom: 5px ridge var(--corail_mh_light);
}

.border-bottom-5-inset-corail_mh_light {
  border-bottom: 5px inset var(--corail_mh_light);
}

.border-bottom-5-outset-corail_mh_light {
  border-bottom: 5px outset var(--corail_mh_light);
}

.border-bottom-5-none-corail_mh_light {
  border-bottom: 5px none var(--corail_mh_light);
}

.border-bottom-5-hidden-corail_mh_light {
  border-bottom: 5px hidden var(--corail_mh_light);
}

.border-bottom-5-solid-gris_sable_light {
  border-bottom: 5px solid var(--gris_sable_light);
}

.border-bottom-5-dashed-gris_sable_light {
  border-bottom: 5px dashed var(--gris_sable_light);
}

.border-bottom-5-dotted-gris_sable_light {
  border-bottom: 5px dotted var(--gris_sable_light);
}

.border-bottom-5-double-gris_sable_light {
  border-bottom: 5px double var(--gris_sable_light);
}

.border-bottom-5-groove-gris_sable_light {
  border-bottom: 5px groove var(--gris_sable_light);
}

.border-bottom-5-ridge-gris_sable_light {
  border-bottom: 5px ridge var(--gris_sable_light);
}

.border-bottom-5-inset-gris_sable_light {
  border-bottom: 5px inset var(--gris_sable_light);
}

.border-bottom-5-outset-gris_sable_light {
  border-bottom: 5px outset var(--gris_sable_light);
}

.border-bottom-5-none-gris_sable_light {
  border-bottom: 5px none var(--gris_sable_light);
}

.border-bottom-5-hidden-gris_sable_light {
  border-bottom: 5px hidden var(--gris_sable_light);
}

.border-bottom-5-solid-gris_sable_dark {
  border-bottom: 5px solid var(--gris_sable_dark);
}

.border-bottom-5-dashed-gris_sable_dark {
  border-bottom: 5px dashed var(--gris_sable_dark);
}

.border-bottom-5-dotted-gris_sable_dark {
  border-bottom: 5px dotted var(--gris_sable_dark);
}

.border-bottom-5-double-gris_sable_dark {
  border-bottom: 5px double var(--gris_sable_dark);
}

.border-bottom-5-groove-gris_sable_dark {
  border-bottom: 5px groove var(--gris_sable_dark);
}

.border-bottom-5-ridge-gris_sable_dark {
  border-bottom: 5px ridge var(--gris_sable_dark);
}

.border-bottom-5-inset-gris_sable_dark {
  border-bottom: 5px inset var(--gris_sable_dark);
}

.border-bottom-5-outset-gris_sable_dark {
  border-bottom: 5px outset var(--gris_sable_dark);
}

.border-bottom-5-none-gris_sable_dark {
  border-bottom: 5px none var(--gris_sable_dark);
}

.border-bottom-5-hidden-gris_sable_dark {
  border-bottom: 5px hidden var(--gris_sable_dark);
}

.border-bottom-5-solid-jaune_ivoire_light {
  border-bottom: 5px solid var(--jaune_ivoire_light);
}

.border-bottom-5-dashed-jaune_ivoire_light {
  border-bottom: 5px dashed var(--jaune_ivoire_light);
}

.border-bottom-5-dotted-jaune_ivoire_light {
  border-bottom: 5px dotted var(--jaune_ivoire_light);
}

.border-bottom-5-double-jaune_ivoire_light {
  border-bottom: 5px double var(--jaune_ivoire_light);
}

.border-bottom-5-groove-jaune_ivoire_light {
  border-bottom: 5px groove var(--jaune_ivoire_light);
}

.border-bottom-5-ridge-jaune_ivoire_light {
  border-bottom: 5px ridge var(--jaune_ivoire_light);
}

.border-bottom-5-inset-jaune_ivoire_light {
  border-bottom: 5px inset var(--jaune_ivoire_light);
}

.border-bottom-5-outset-jaune_ivoire_light {
  border-bottom: 5px outset var(--jaune_ivoire_light);
}

.border-bottom-5-none-jaune_ivoire_light {
  border-bottom: 5px none var(--jaune_ivoire_light);
}

.border-bottom-5-hidden-jaune_ivoire_light {
  border-bottom: 5px hidden var(--jaune_ivoire_light);
}

.border-bottom-5-solid-jaune_ivoire_dark {
  border-bottom: 5px solid var(--jaune_ivoire_dark);
}

.border-bottom-5-dashed-jaune_ivoire_dark {
  border-bottom: 5px dashed var(--jaune_ivoire_dark);
}

.border-bottom-5-dotted-jaune_ivoire_dark {
  border-bottom: 5px dotted var(--jaune_ivoire_dark);
}

.border-bottom-5-double-jaune_ivoire_dark {
  border-bottom: 5px double var(--jaune_ivoire_dark);
}

.border-bottom-5-groove-jaune_ivoire_dark {
  border-bottom: 5px groove var(--jaune_ivoire_dark);
}

.border-bottom-5-ridge-jaune_ivoire_dark {
  border-bottom: 5px ridge var(--jaune_ivoire_dark);
}

.border-bottom-5-inset-jaune_ivoire_dark {
  border-bottom: 5px inset var(--jaune_ivoire_dark);
}

.border-bottom-5-outset-jaune_ivoire_dark {
  border-bottom: 5px outset var(--jaune_ivoire_dark);
}

.border-bottom-5-none-jaune_ivoire_dark {
  border-bottom: 5px none var(--jaune_ivoire_dark);
}

.border-bottom-5-hidden-jaune_ivoire_dark {
  border-bottom: 5px hidden var(--jaune_ivoire_dark);
}

.border-bottom-5-solid-jaune_light {
  border-bottom: 5px solid var(--jaune_light);
}

.border-bottom-5-dashed-jaune_light {
  border-bottom: 5px dashed var(--jaune_light);
}

.border-bottom-5-dotted-jaune_light {
  border-bottom: 5px dotted var(--jaune_light);
}

.border-bottom-5-double-jaune_light {
  border-bottom: 5px double var(--jaune_light);
}

.border-bottom-5-groove-jaune_light {
  border-bottom: 5px groove var(--jaune_light);
}

.border-bottom-5-ridge-jaune_light {
  border-bottom: 5px ridge var(--jaune_light);
}

.border-bottom-5-inset-jaune_light {
  border-bottom: 5px inset var(--jaune_light);
}

.border-bottom-5-outset-jaune_light {
  border-bottom: 5px outset var(--jaune_light);
}

.border-bottom-5-none-jaune_light {
  border-bottom: 5px none var(--jaune_light);
}

.border-bottom-5-hidden-jaune_light {
  border-bottom: 5px hidden var(--jaune_light);
}

.border-bottom-5-solid-jaune_dark {
  border-bottom: 5px solid var(--jaune_dark);
}

.border-bottom-5-dashed-jaune_dark {
  border-bottom: 5px dashed var(--jaune_dark);
}

.border-bottom-5-dotted-jaune_dark {
  border-bottom: 5px dotted var(--jaune_dark);
}

.border-bottom-5-double-jaune_dark {
  border-bottom: 5px double var(--jaune_dark);
}

.border-bottom-5-groove-jaune_dark {
  border-bottom: 5px groove var(--jaune_dark);
}

.border-bottom-5-ridge-jaune_dark {
  border-bottom: 5px ridge var(--jaune_dark);
}

.border-bottom-5-inset-jaune_dark {
  border-bottom: 5px inset var(--jaune_dark);
}

.border-bottom-5-outset-jaune_dark {
  border-bottom: 5px outset var(--jaune_dark);
}

.border-bottom-5-none-jaune_dark {
  border-bottom: 5px none var(--jaune_dark);
}

.border-bottom-5-hidden-jaune_dark {
  border-bottom: 5px hidden var(--jaune_dark);
}

.border-bottom-5-solid-orange_normal {
  border-bottom: 5px solid var(--orange_normal);
}

.border-bottom-5-dashed-orange_normal {
  border-bottom: 5px dashed var(--orange_normal);
}

.border-bottom-5-dotted-orange_normal {
  border-bottom: 5px dotted var(--orange_normal);
}

.border-bottom-5-double-orange_normal {
  border-bottom: 5px double var(--orange_normal);
}

.border-bottom-5-groove-orange_normal {
  border-bottom: 5px groove var(--orange_normal);
}

.border-bottom-5-ridge-orange_normal {
  border-bottom: 5px ridge var(--orange_normal);
}

.border-bottom-5-inset-orange_normal {
  border-bottom: 5px inset var(--orange_normal);
}

.border-bottom-5-outset-orange_normal {
  border-bottom: 5px outset var(--orange_normal);
}

.border-bottom-5-none-orange_normal {
  border-bottom: 5px none var(--orange_normal);
}

.border-bottom-5-hidden-orange_normal {
  border-bottom: 5px hidden var(--orange_normal);
}

.border-bottom-5-solid-orange_dark {
  border-bottom: 5px solid var(--orange_dark);
}

.border-bottom-5-dashed-orange_dark {
  border-bottom: 5px dashed var(--orange_dark);
}

.border-bottom-5-dotted-orange_dark {
  border-bottom: 5px dotted var(--orange_dark);
}

.border-bottom-5-double-orange_dark {
  border-bottom: 5px double var(--orange_dark);
}

.border-bottom-5-groove-orange_dark {
  border-bottom: 5px groove var(--orange_dark);
}

.border-bottom-5-ridge-orange_dark {
  border-bottom: 5px ridge var(--orange_dark);
}

.border-bottom-5-inset-orange_dark {
  border-bottom: 5px inset var(--orange_dark);
}

.border-bottom-5-outset-orange_dark {
  border-bottom: 5px outset var(--orange_dark);
}

.border-bottom-5-none-orange_dark {
  border-bottom: 5px none var(--orange_dark);
}

.border-bottom-5-hidden-orange_dark {
  border-bottom: 5px hidden var(--orange_dark);
}

.border-bottom-5-solid-vert_light {
  border-bottom: 5px solid var(--vert_light);
}

.border-bottom-5-dashed-vert_light {
  border-bottom: 5px dashed var(--vert_light);
}

.border-bottom-5-dotted-vert_light {
  border-bottom: 5px dotted var(--vert_light);
}

.border-bottom-5-double-vert_light {
  border-bottom: 5px double var(--vert_light);
}

.border-bottom-5-groove-vert_light {
  border-bottom: 5px groove var(--vert_light);
}

.border-bottom-5-ridge-vert_light {
  border-bottom: 5px ridge var(--vert_light);
}

.border-bottom-5-inset-vert_light {
  border-bottom: 5px inset var(--vert_light);
}

.border-bottom-5-outset-vert_light {
  border-bottom: 5px outset var(--vert_light);
}

.border-bottom-5-none-vert_light {
  border-bottom: 5px none var(--vert_light);
}

.border-bottom-5-hidden-vert_light {
  border-bottom: 5px hidden var(--vert_light);
}

.border-bottom-5-solid-vert_dark {
  border-bottom: 5px solid var(--vert_dark);
}

.border-bottom-5-dashed-vert_dark {
  border-bottom: 5px dashed var(--vert_dark);
}

.border-bottom-5-dotted-vert_dark {
  border-bottom: 5px dotted var(--vert_dark);
}

.border-bottom-5-double-vert_dark {
  border-bottom: 5px double var(--vert_dark);
}

.border-bottom-5-groove-vert_dark {
  border-bottom: 5px groove var(--vert_dark);
}

.border-bottom-5-ridge-vert_dark {
  border-bottom: 5px ridge var(--vert_dark);
}

.border-bottom-5-inset-vert_dark {
  border-bottom: 5px inset var(--vert_dark);
}

.border-bottom-5-outset-vert_dark {
  border-bottom: 5px outset var(--vert_dark);
}

.border-bottom-5-none-vert_dark {
  border-bottom: 5px none var(--vert_dark);
}

.border-bottom-5-hidden-vert_dark {
  border-bottom: 5px hidden var(--vert_dark);
}

.border-bottom-5-solid-bleu_turquoise_light {
  border-bottom: 5px solid var(--bleu_turquoise_light);
}

.border-bottom-5-dashed-bleu_turquoise_light {
  border-bottom: 5px dashed var(--bleu_turquoise_light);
}

.border-bottom-5-dotted-bleu_turquoise_light {
  border-bottom: 5px dotted var(--bleu_turquoise_light);
}

.border-bottom-5-double-bleu_turquoise_light {
  border-bottom: 5px double var(--bleu_turquoise_light);
}

.border-bottom-5-groove-bleu_turquoise_light {
  border-bottom: 5px groove var(--bleu_turquoise_light);
}

.border-bottom-5-ridge-bleu_turquoise_light {
  border-bottom: 5px ridge var(--bleu_turquoise_light);
}

.border-bottom-5-inset-bleu_turquoise_light {
  border-bottom: 5px inset var(--bleu_turquoise_light);
}

.border-bottom-5-outset-bleu_turquoise_light {
  border-bottom: 5px outset var(--bleu_turquoise_light);
}

.border-bottom-5-none-bleu_turquoise_light {
  border-bottom: 5px none var(--bleu_turquoise_light);
}

.border-bottom-5-hidden-bleu_turquoise_light {
  border-bottom: 5px hidden var(--bleu_turquoise_light);
}

.border-bottom-5-solid-bleu_turquoise_middle {
  border-bottom: 5px solid var(--bleu_turquoise_middle);
}

.border-bottom-5-dashed-bleu_turquoise_middle {
  border-bottom: 5px dashed var(--bleu_turquoise_middle);
}

.border-bottom-5-dotted-bleu_turquoise_middle {
  border-bottom: 5px dotted var(--bleu_turquoise_middle);
}

.border-bottom-5-double-bleu_turquoise_middle {
  border-bottom: 5px double var(--bleu_turquoise_middle);
}

.border-bottom-5-groove-bleu_turquoise_middle {
  border-bottom: 5px groove var(--bleu_turquoise_middle);
}

.border-bottom-5-ridge-bleu_turquoise_middle {
  border-bottom: 5px ridge var(--bleu_turquoise_middle);
}

.border-bottom-5-inset-bleu_turquoise_middle {
  border-bottom: 5px inset var(--bleu_turquoise_middle);
}

.border-bottom-5-outset-bleu_turquoise_middle {
  border-bottom: 5px outset var(--bleu_turquoise_middle);
}

.border-bottom-5-none-bleu_turquoise_middle {
  border-bottom: 5px none var(--bleu_turquoise_middle);
}

.border-bottom-5-hidden-bleu_turquoise_middle {
  border-bottom: 5px hidden var(--bleu_turquoise_middle);
}

.border-bottom-5-solid-bleu_turquoise_dark {
  border-bottom: 5px solid var(--bleu_turquoise_dark);
}

.border-bottom-5-dashed-bleu_turquoise_dark {
  border-bottom: 5px dashed var(--bleu_turquoise_dark);
}

.border-bottom-5-dotted-bleu_turquoise_dark {
  border-bottom: 5px dotted var(--bleu_turquoise_dark);
}

.border-bottom-5-double-bleu_turquoise_dark {
  border-bottom: 5px double var(--bleu_turquoise_dark);
}

.border-bottom-5-groove-bleu_turquoise_dark {
  border-bottom: 5px groove var(--bleu_turquoise_dark);
}

.border-bottom-5-ridge-bleu_turquoise_dark {
  border-bottom: 5px ridge var(--bleu_turquoise_dark);
}

.border-bottom-5-inset-bleu_turquoise_dark {
  border-bottom: 5px inset var(--bleu_turquoise_dark);
}

.border-bottom-5-outset-bleu_turquoise_dark {
  border-bottom: 5px outset var(--bleu_turquoise_dark);
}

.border-bottom-5-none-bleu_turquoise_dark {
  border-bottom: 5px none var(--bleu_turquoise_dark);
}

.border-bottom-5-hidden-bleu_turquoise_dark {
  border-bottom: 5px hidden var(--bleu_turquoise_dark);
}

.border-bottom-5-solid-bleu_light {
  border-bottom: 5px solid var(--bleu_light);
}

.border-bottom-5-dashed-bleu_light {
  border-bottom: 5px dashed var(--bleu_light);
}

.border-bottom-5-dotted-bleu_light {
  border-bottom: 5px dotted var(--bleu_light);
}

.border-bottom-5-double-bleu_light {
  border-bottom: 5px double var(--bleu_light);
}

.border-bottom-5-groove-bleu_light {
  border-bottom: 5px groove var(--bleu_light);
}

.border-bottom-5-ridge-bleu_light {
  border-bottom: 5px ridge var(--bleu_light);
}

.border-bottom-5-inset-bleu_light {
  border-bottom: 5px inset var(--bleu_light);
}

.border-bottom-5-outset-bleu_light {
  border-bottom: 5px outset var(--bleu_light);
}

.border-bottom-5-none-bleu_light {
  border-bottom: 5px none var(--bleu_light);
}

.border-bottom-5-hidden-bleu_light {
  border-bottom: 5px hidden var(--bleu_light);
}

.border-bottom-5-solid-bleu_dark {
  border-bottom: 5px solid var(--bleu_dark);
}

.border-bottom-5-dashed-bleu_dark {
  border-bottom: 5px dashed var(--bleu_dark);
}

.border-bottom-5-dotted-bleu_dark {
  border-bottom: 5px dotted var(--bleu_dark);
}

.border-bottom-5-double-bleu_dark {
  border-bottom: 5px double var(--bleu_dark);
}

.border-bottom-5-groove-bleu_dark {
  border-bottom: 5px groove var(--bleu_dark);
}

.border-bottom-5-ridge-bleu_dark {
  border-bottom: 5px ridge var(--bleu_dark);
}

.border-bottom-5-inset-bleu_dark {
  border-bottom: 5px inset var(--bleu_dark);
}

.border-bottom-5-outset-bleu_dark {
  border-bottom: 5px outset var(--bleu_dark);
}

.border-bottom-5-none-bleu_dark {
  border-bottom: 5px none var(--bleu_dark);
}

.border-bottom-5-hidden-bleu_dark {
  border-bottom: 5px hidden var(--bleu_dark);
}

.border-bottom-5-solid-bleu_klein_light {
  border-bottom: 5px solid var(--bleu_klein_light);
}

.border-bottom-5-dashed-bleu_klein_light {
  border-bottom: 5px dashed var(--bleu_klein_light);
}

.border-bottom-5-dotted-bleu_klein_light {
  border-bottom: 5px dotted var(--bleu_klein_light);
}

.border-bottom-5-double-bleu_klein_light {
  border-bottom: 5px double var(--bleu_klein_light);
}

.border-bottom-5-groove-bleu_klein_light {
  border-bottom: 5px groove var(--bleu_klein_light);
}

.border-bottom-5-ridge-bleu_klein_light {
  border-bottom: 5px ridge var(--bleu_klein_light);
}

.border-bottom-5-inset-bleu_klein_light {
  border-bottom: 5px inset var(--bleu_klein_light);
}

.border-bottom-5-outset-bleu_klein_light {
  border-bottom: 5px outset var(--bleu_klein_light);
}

.border-bottom-5-none-bleu_klein_light {
  border-bottom: 5px none var(--bleu_klein_light);
}

.border-bottom-5-hidden-bleu_klein_light {
  border-bottom: 5px hidden var(--bleu_klein_light);
}

.border-bottom-5-solid-bleu_klein_dark {
  border-bottom: 5px solid var(--bleu_klein_dark);
}

.border-bottom-5-dashed-bleu_klein_dark {
  border-bottom: 5px dashed var(--bleu_klein_dark);
}

.border-bottom-5-dotted-bleu_klein_dark {
  border-bottom: 5px dotted var(--bleu_klein_dark);
}

.border-bottom-5-double-bleu_klein_dark {
  border-bottom: 5px double var(--bleu_klein_dark);
}

.border-bottom-5-groove-bleu_klein_dark {
  border-bottom: 5px groove var(--bleu_klein_dark);
}

.border-bottom-5-ridge-bleu_klein_dark {
  border-bottom: 5px ridge var(--bleu_klein_dark);
}

.border-bottom-5-inset-bleu_klein_dark {
  border-bottom: 5px inset var(--bleu_klein_dark);
}

.border-bottom-5-outset-bleu_klein_dark {
  border-bottom: 5px outset var(--bleu_klein_dark);
}

.border-bottom-5-none-bleu_klein_dark {
  border-bottom: 5px none var(--bleu_klein_dark);
}

.border-bottom-5-hidden-bleu_klein_dark {
  border-bottom: 5px hidden var(--bleu_klein_dark);
}

.border-bottom-5-solid-violet_light {
  border-bottom: 5px solid var(--violet_light);
}

.border-bottom-5-dashed-violet_light {
  border-bottom: 5px dashed var(--violet_light);
}

.border-bottom-5-dotted-violet_light {
  border-bottom: 5px dotted var(--violet_light);
}

.border-bottom-5-double-violet_light {
  border-bottom: 5px double var(--violet_light);
}

.border-bottom-5-groove-violet_light {
  border-bottom: 5px groove var(--violet_light);
}

.border-bottom-5-ridge-violet_light {
  border-bottom: 5px ridge var(--violet_light);
}

.border-bottom-5-inset-violet_light {
  border-bottom: 5px inset var(--violet_light);
}

.border-bottom-5-outset-violet_light {
  border-bottom: 5px outset var(--violet_light);
}

.border-bottom-5-none-violet_light {
  border-bottom: 5px none var(--violet_light);
}

.border-bottom-5-hidden-violet_light {
  border-bottom: 5px hidden var(--violet_light);
}

.border-bottom-5-solid-violet_dark {
  border-bottom: 5px solid var(--violet_dark);
}

.border-bottom-5-dashed-violet_dark {
  border-bottom: 5px dashed var(--violet_dark);
}

.border-bottom-5-dotted-violet_dark {
  border-bottom: 5px dotted var(--violet_dark);
}

.border-bottom-5-double-violet_dark {
  border-bottom: 5px double var(--violet_dark);
}

.border-bottom-5-groove-violet_dark {
  border-bottom: 5px groove var(--violet_dark);
}

.border-bottom-5-ridge-violet_dark {
  border-bottom: 5px ridge var(--violet_dark);
}

.border-bottom-5-inset-violet_dark {
  border-bottom: 5px inset var(--violet_dark);
}

.border-bottom-5-outset-violet_dark {
  border-bottom: 5px outset var(--violet_dark);
}

.border-bottom-5-none-violet_dark {
  border-bottom: 5px none var(--violet_dark);
}

.border-bottom-5-hidden-violet_dark {
  border-bottom: 5px hidden var(--violet_dark);
}

.border-bottom-5-solid-mauve {
  border-bottom: 5px solid var(--mauve);
}

.border-bottom-5-dashed-mauve {
  border-bottom: 5px dashed var(--mauve);
}

.border-bottom-5-dotted-mauve {
  border-bottom: 5px dotted var(--mauve);
}

.border-bottom-5-double-mauve {
  border-bottom: 5px double var(--mauve);
}

.border-bottom-5-groove-mauve {
  border-bottom: 5px groove var(--mauve);
}

.border-bottom-5-ridge-mauve {
  border-bottom: 5px ridge var(--mauve);
}

.border-bottom-5-inset-mauve {
  border-bottom: 5px inset var(--mauve);
}

.border-bottom-5-outset-mauve {
  border-bottom: 5px outset var(--mauve);
}

.border-bottom-5-none-mauve {
  border-bottom: 5px none var(--mauve);
}

.border-bottom-5-hidden-mauve {
  border-bottom: 5px hidden var(--mauve);
}

.border-bottom-5-solid-rose_light {
  border-bottom: 5px solid var(--rose_light);
}

.border-bottom-5-dashed-rose_light {
  border-bottom: 5px dashed var(--rose_light);
}

.border-bottom-5-dotted-rose_light {
  border-bottom: 5px dotted var(--rose_light);
}

.border-bottom-5-double-rose_light {
  border-bottom: 5px double var(--rose_light);
}

.border-bottom-5-groove-rose_light {
  border-bottom: 5px groove var(--rose_light);
}

.border-bottom-5-ridge-rose_light {
  border-bottom: 5px ridge var(--rose_light);
}

.border-bottom-5-inset-rose_light {
  border-bottom: 5px inset var(--rose_light);
}

.border-bottom-5-outset-rose_light {
  border-bottom: 5px outset var(--rose_light);
}

.border-bottom-5-none-rose_light {
  border-bottom: 5px none var(--rose_light);
}

.border-bottom-5-hidden-rose_light {
  border-bottom: 5px hidden var(--rose_light);
}

.border-bottom-5-solid-rose_dark {
  border-bottom: 5px solid var(--rose_dark);
}

.border-bottom-5-dashed-rose_dark {
  border-bottom: 5px dashed var(--rose_dark);
}

.border-bottom-5-dotted-rose_dark {
  border-bottom: 5px dotted var(--rose_dark);
}

.border-bottom-5-double-rose_dark {
  border-bottom: 5px double var(--rose_dark);
}

.border-bottom-5-groove-rose_dark {
  border-bottom: 5px groove var(--rose_dark);
}

.border-bottom-5-ridge-rose_dark {
  border-bottom: 5px ridge var(--rose_dark);
}

.border-bottom-5-inset-rose_dark {
  border-bottom: 5px inset var(--rose_dark);
}

.border-bottom-5-outset-rose_dark {
  border-bottom: 5px outset var(--rose_dark);
}

.border-bottom-5-none-rose_dark {
  border-bottom: 5px none var(--rose_dark);
}

.border-bottom-5-hidden-rose_dark {
  border-bottom: 5px hidden var(--rose_dark);
}

.border-bottom-5-solid-rose_fushia {
  border-bottom: 5px solid var(--rose_fushia);
}

.border-bottom-5-dashed-rose_fushia {
  border-bottom: 5px dashed var(--rose_fushia);
}

.border-bottom-5-dotted-rose_fushia {
  border-bottom: 5px dotted var(--rose_fushia);
}

.border-bottom-5-double-rose_fushia {
  border-bottom: 5px double var(--rose_fushia);
}

.border-bottom-5-groove-rose_fushia {
  border-bottom: 5px groove var(--rose_fushia);
}

.border-bottom-5-ridge-rose_fushia {
  border-bottom: 5px ridge var(--rose_fushia);
}

.border-bottom-5-inset-rose_fushia {
  border-bottom: 5px inset var(--rose_fushia);
}

.border-bottom-5-outset-rose_fushia {
  border-bottom: 5px outset var(--rose_fushia);
}

.border-bottom-5-none-rose_fushia {
  border-bottom: 5px none var(--rose_fushia);
}

.border-bottom-5-hidden-rose_fushia {
  border-bottom: 5px hidden var(--rose_fushia);
}

.border-bottom-5-solid-rouge_framboise {
  border-bottom: 5px solid var(--rouge_framboise);
}

.border-bottom-5-dashed-rouge_framboise {
  border-bottom: 5px dashed var(--rouge_framboise);
}

.border-bottom-5-dotted-rouge_framboise {
  border-bottom: 5px dotted var(--rouge_framboise);
}

.border-bottom-5-double-rouge_framboise {
  border-bottom: 5px double var(--rouge_framboise);
}

.border-bottom-5-groove-rouge_framboise {
  border-bottom: 5px groove var(--rouge_framboise);
}

.border-bottom-5-ridge-rouge_framboise {
  border-bottom: 5px ridge var(--rouge_framboise);
}

.border-bottom-5-inset-rouge_framboise {
  border-bottom: 5px inset var(--rouge_framboise);
}

.border-bottom-5-outset-rouge_framboise {
  border-bottom: 5px outset var(--rouge_framboise);
}

.border-bottom-5-none-rouge_framboise {
  border-bottom: 5px none var(--rouge_framboise);
}

.border-bottom-5-hidden-rouge_framboise {
  border-bottom: 5px hidden var(--rouge_framboise);
}

.border-bottom-5-solid-rose_chair_light {
  border-bottom: 5px solid var(--rose_chair_light);
}

.border-bottom-5-dashed-rose_chair_light {
  border-bottom: 5px dashed var(--rose_chair_light);
}

.border-bottom-5-dotted-rose_chair_light {
  border-bottom: 5px dotted var(--rose_chair_light);
}

.border-bottom-5-double-rose_chair_light {
  border-bottom: 5px double var(--rose_chair_light);
}

.border-bottom-5-groove-rose_chair_light {
  border-bottom: 5px groove var(--rose_chair_light);
}

.border-bottom-5-ridge-rose_chair_light {
  border-bottom: 5px ridge var(--rose_chair_light);
}

.border-bottom-5-inset-rose_chair_light {
  border-bottom: 5px inset var(--rose_chair_light);
}

.border-bottom-5-outset-rose_chair_light {
  border-bottom: 5px outset var(--rose_chair_light);
}

.border-bottom-5-none-rose_chair_light {
  border-bottom: 5px none var(--rose_chair_light);
}

.border-bottom-5-hidden-rose_chair_light {
  border-bottom: 5px hidden var(--rose_chair_light);
}

.border-bottom-5-solid-rose_chair_dark {
  border-bottom: 5px solid var(--rose_chair_dark);
}

.border-bottom-5-dashed-rose_chair_dark {
  border-bottom: 5px dashed var(--rose_chair_dark);
}

.border-bottom-5-dotted-rose_chair_dark {
  border-bottom: 5px dotted var(--rose_chair_dark);
}

.border-bottom-5-double-rose_chair_dark {
  border-bottom: 5px double var(--rose_chair_dark);
}

.border-bottom-5-groove-rose_chair_dark {
  border-bottom: 5px groove var(--rose_chair_dark);
}

.border-bottom-5-ridge-rose_chair_dark {
  border-bottom: 5px ridge var(--rose_chair_dark);
}

.border-bottom-5-inset-rose_chair_dark {
  border-bottom: 5px inset var(--rose_chair_dark);
}

.border-bottom-5-outset-rose_chair_dark {
  border-bottom: 5px outset var(--rose_chair_dark);
}

.border-bottom-5-none-rose_chair_dark {
  border-bottom: 5px none var(--rose_chair_dark);
}

.border-bottom-5-hidden-rose_chair_dark {
  border-bottom: 5px hidden var(--rose_chair_dark);
}

.border-bottom-5-solid-vert_positif {
  border-bottom: 5px solid var(--vert_positif);
}

.border-bottom-5-dashed-vert_positif {
  border-bottom: 5px dashed var(--vert_positif);
}

.border-bottom-5-dotted-vert_positif {
  border-bottom: 5px dotted var(--vert_positif);
}

.border-bottom-5-double-vert_positif {
  border-bottom: 5px double var(--vert_positif);
}

.border-bottom-5-groove-vert_positif {
  border-bottom: 5px groove var(--vert_positif);
}

.border-bottom-5-ridge-vert_positif {
  border-bottom: 5px ridge var(--vert_positif);
}

.border-bottom-5-inset-vert_positif {
  border-bottom: 5px inset var(--vert_positif);
}

.border-bottom-5-outset-vert_positif {
  border-bottom: 5px outset var(--vert_positif);
}

.border-bottom-5-none-vert_positif {
  border-bottom: 5px none var(--vert_positif);
}

.border-bottom-5-hidden-vert_positif {
  border-bottom: 5px hidden var(--vert_positif);
}

.border-bottom-5-solid-rouge_negatif {
  border-bottom: 5px solid var(--rouge_negatif);
}

.border-bottom-5-dashed-rouge_negatif {
  border-bottom: 5px dashed var(--rouge_negatif);
}

.border-bottom-5-dotted-rouge_negatif {
  border-bottom: 5px dotted var(--rouge_negatif);
}

.border-bottom-5-double-rouge_negatif {
  border-bottom: 5px double var(--rouge_negatif);
}

.border-bottom-5-groove-rouge_negatif {
  border-bottom: 5px groove var(--rouge_negatif);
}

.border-bottom-5-ridge-rouge_negatif {
  border-bottom: 5px ridge var(--rouge_negatif);
}

.border-bottom-5-inset-rouge_negatif {
  border-bottom: 5px inset var(--rouge_negatif);
}

.border-bottom-5-outset-rouge_negatif {
  border-bottom: 5px outset var(--rouge_negatif);
}

.border-bottom-5-none-rouge_negatif {
  border-bottom: 5px none var(--rouge_negatif);
}

.border-bottom-5-hidden-rouge_negatif {
  border-bottom: 5px hidden var(--rouge_negatif);
}

.border-bottom-5-solid-bleu_texte {
  border-bottom: 5px solid var(--bleu_texte);
}

.border-bottom-5-dashed-bleu_texte {
  border-bottom: 5px dashed var(--bleu_texte);
}

.border-bottom-5-dotted-bleu_texte {
  border-bottom: 5px dotted var(--bleu_texte);
}

.border-bottom-5-double-bleu_texte {
  border-bottom: 5px double var(--bleu_texte);
}

.border-bottom-5-groove-bleu_texte {
  border-bottom: 5px groove var(--bleu_texte);
}

.border-bottom-5-ridge-bleu_texte {
  border-bottom: 5px ridge var(--bleu_texte);
}

.border-bottom-5-inset-bleu_texte {
  border-bottom: 5px inset var(--bleu_texte);
}

.border-bottom-5-outset-bleu_texte {
  border-bottom: 5px outset var(--bleu_texte);
}

.border-bottom-5-none-bleu_texte {
  border-bottom: 5px none var(--bleu_texte);
}

.border-bottom-5-hidden-bleu_texte {
  border-bottom: 5px hidden var(--bleu_texte);
}

.border-bottom-5-solid-alerte_vert_positif {
  border-bottom: 5px solid var(--alerte_vert_positif);
}

.border-bottom-5-dashed-alerte_vert_positif {
  border-bottom: 5px dashed var(--alerte_vert_positif);
}

.border-bottom-5-dotted-alerte_vert_positif {
  border-bottom: 5px dotted var(--alerte_vert_positif);
}

.border-bottom-5-double-alerte_vert_positif {
  border-bottom: 5px double var(--alerte_vert_positif);
}

.border-bottom-5-groove-alerte_vert_positif {
  border-bottom: 5px groove var(--alerte_vert_positif);
}

.border-bottom-5-ridge-alerte_vert_positif {
  border-bottom: 5px ridge var(--alerte_vert_positif);
}

.border-bottom-5-inset-alerte_vert_positif {
  border-bottom: 5px inset var(--alerte_vert_positif);
}

.border-bottom-5-outset-alerte_vert_positif {
  border-bottom: 5px outset var(--alerte_vert_positif);
}

.border-bottom-5-none-alerte_vert_positif {
  border-bottom: 5px none var(--alerte_vert_positif);
}

.border-bottom-5-hidden-alerte_vert_positif {
  border-bottom: 5px hidden var(--alerte_vert_positif);
}

.border-bottom-5-solid-alerte_rouge_negatif {
  border-bottom: 5px solid var(--alerte_rouge_negatif);
}

.border-bottom-5-dashed-alerte_rouge_negatif {
  border-bottom: 5px dashed var(--alerte_rouge_negatif);
}

.border-bottom-5-dotted-alerte_rouge_negatif {
  border-bottom: 5px dotted var(--alerte_rouge_negatif);
}

.border-bottom-5-double-alerte_rouge_negatif {
  border-bottom: 5px double var(--alerte_rouge_negatif);
}

.border-bottom-5-groove-alerte_rouge_negatif {
  border-bottom: 5px groove var(--alerte_rouge_negatif);
}

.border-bottom-5-ridge-alerte_rouge_negatif {
  border-bottom: 5px ridge var(--alerte_rouge_negatif);
}

.border-bottom-5-inset-alerte_rouge_negatif {
  border-bottom: 5px inset var(--alerte_rouge_negatif);
}

.border-bottom-5-outset-alerte_rouge_negatif {
  border-bottom: 5px outset var(--alerte_rouge_negatif);
}

.border-bottom-5-none-alerte_rouge_negatif {
  border-bottom: 5px none var(--alerte_rouge_negatif);
}

.border-bottom-5-hidden-alerte_rouge_negatif {
  border-bottom: 5px hidden var(--alerte_rouge_negatif);
}

.border-bottom-5-solid-alerte_bleu_information {
  border-bottom: 5px solid var(--alerte_bleu_information);
}

.border-bottom-5-dashed-alerte_bleu_information {
  border-bottom: 5px dashed var(--alerte_bleu_information);
}

.border-bottom-5-dotted-alerte_bleu_information {
  border-bottom: 5px dotted var(--alerte_bleu_information);
}

.border-bottom-5-double-alerte_bleu_information {
  border-bottom: 5px double var(--alerte_bleu_information);
}

.border-bottom-5-groove-alerte_bleu_information {
  border-bottom: 5px groove var(--alerte_bleu_information);
}

.border-bottom-5-ridge-alerte_bleu_information {
  border-bottom: 5px ridge var(--alerte_bleu_information);
}

.border-bottom-5-inset-alerte_bleu_information {
  border-bottom: 5px inset var(--alerte_bleu_information);
}

.border-bottom-5-outset-alerte_bleu_information {
  border-bottom: 5px outset var(--alerte_bleu_information);
}

.border-bottom-5-none-alerte_bleu_information {
  border-bottom: 5px none var(--alerte_bleu_information);
}

.border-bottom-5-hidden-alerte_bleu_information {
  border-bottom: 5px hidden var(--alerte_bleu_information);
}

.border-bottom-5-solid-alerte_jaune_attention {
  border-bottom: 5px solid var(--alerte_jaune_attention);
}

.border-bottom-5-dashed-alerte_jaune_attention {
  border-bottom: 5px dashed var(--alerte_jaune_attention);
}

.border-bottom-5-dotted-alerte_jaune_attention {
  border-bottom: 5px dotted var(--alerte_jaune_attention);
}

.border-bottom-5-double-alerte_jaune_attention {
  border-bottom: 5px double var(--alerte_jaune_attention);
}

.border-bottom-5-groove-alerte_jaune_attention {
  border-bottom: 5px groove var(--alerte_jaune_attention);
}

.border-bottom-5-ridge-alerte_jaune_attention {
  border-bottom: 5px ridge var(--alerte_jaune_attention);
}

.border-bottom-5-inset-alerte_jaune_attention {
  border-bottom: 5px inset var(--alerte_jaune_attention);
}

.border-bottom-5-outset-alerte_jaune_attention {
  border-bottom: 5px outset var(--alerte_jaune_attention);
}

.border-bottom-5-none-alerte_jaune_attention {
  border-bottom: 5px none var(--alerte_jaune_attention);
}

.border-bottom-5-hidden-alerte_jaune_attention {
  border-bottom: 5px hidden var(--alerte_jaune_attention);
}

.border-bottom-5-solid-blanc {
  border-bottom: 5px solid #fff;
}

.border-bottom-5-dashed-blanc {
  border-bottom: 5px dashed #fff;
}

.border-bottom-5-dotted-blanc {
  border-bottom: 5px dotted #fff;
}

.border-bottom-5-double-blanc {
  border-bottom: 5px double #fff;
}

.border-bottom-5-groove-blanc {
  border-bottom: 5px groove #fff;
}

.border-bottom-5-ridge-blanc {
  border-bottom: 5px ridge #fff;
}

.border-bottom-5-inset-blanc {
  border-bottom: 5px inset #fff;
}

.border-bottom-5-outset-blanc {
  border-bottom: 5px outset #fff;
}

.border-bottom-5-none-blanc {
  border-bottom: 5px #fff;
}

.border-bottom-5-hidden-blanc {
  border-bottom: 5px hidden #fff;
}

.border-bottom-5-solid-noir {
  border-bottom: 5px solid #222;
}

.border-bottom-5-dashed-noir {
  border-bottom: 5px dashed #222;
}

.border-bottom-5-dotted-noir {
  border-bottom: 5px dotted #222;
}

.border-bottom-5-double-noir {
  border-bottom: 5px double #222;
}

.border-bottom-5-groove-noir {
  border-bottom: 5px groove #222;
}

.border-bottom-5-ridge-noir {
  border-bottom: 5px ridge #222;
}

.border-bottom-5-inset-noir {
  border-bottom: 5px inset #222;
}

.border-bottom-5-outset-noir {
  border-bottom: 5px outset #222;
}

.border-bottom-5-none-noir {
  border-bottom: 5px #222;
}

.border-bottom-5-hidden-noir {
  border-bottom: 5px hidden #222;
}

.border-bottom-5-solid-gris_light {
  border-bottom: 5px solid var(--gris_light);
}

.border-bottom-5-dashed-gris_light {
  border-bottom: 5px dashed var(--gris_light);
}

.border-bottom-5-dotted-gris_light {
  border-bottom: 5px dotted var(--gris_light);
}

.border-bottom-5-double-gris_light {
  border-bottom: 5px double var(--gris_light);
}

.border-bottom-5-groove-gris_light {
  border-bottom: 5px groove var(--gris_light);
}

.border-bottom-5-ridge-gris_light {
  border-bottom: 5px ridge var(--gris_light);
}

.border-bottom-5-inset-gris_light {
  border-bottom: 5px inset var(--gris_light);
}

.border-bottom-5-outset-gris_light {
  border-bottom: 5px outset var(--gris_light);
}

.border-bottom-5-none-gris_light {
  border-bottom: 5px none var(--gris_light);
}

.border-bottom-5-hidden-gris_light {
  border-bottom: 5px hidden var(--gris_light);
}

.border-bottom-5-solid-gris_dark {
  border-bottom: 5px solid var(--gris_dark);
}

.border-bottom-5-dashed-gris_dark {
  border-bottom: 5px dashed var(--gris_dark);
}

.border-bottom-5-dotted-gris_dark {
  border-bottom: 5px dotted var(--gris_dark);
}

.border-bottom-5-double-gris_dark {
  border-bottom: 5px double var(--gris_dark);
}

.border-bottom-5-groove-gris_dark {
  border-bottom: 5px groove var(--gris_dark);
}

.border-bottom-5-ridge-gris_dark {
  border-bottom: 5px ridge var(--gris_dark);
}

.border-bottom-5-inset-gris_dark {
  border-bottom: 5px inset var(--gris_dark);
}

.border-bottom-5-outset-gris_dark {
  border-bottom: 5px outset var(--gris_dark);
}

.border-bottom-5-none-gris_dark {
  border-bottom: 5px none var(--gris_dark);
}

.border-bottom-5-hidden-gris_dark {
  border-bottom: 5px hidden var(--gris_dark);
}

.border-bottom-5-solid-gris_background {
  border-bottom: 5px solid var(--gris_background);
}

.border-bottom-5-dashed-gris_background {
  border-bottom: 5px dashed var(--gris_background);
}

.border-bottom-5-dotted-gris_background {
  border-bottom: 5px dotted var(--gris_background);
}

.border-bottom-5-double-gris_background {
  border-bottom: 5px double var(--gris_background);
}

.border-bottom-5-groove-gris_background {
  border-bottom: 5px groove var(--gris_background);
}

.border-bottom-5-ridge-gris_background {
  border-bottom: 5px ridge var(--gris_background);
}

.border-bottom-5-inset-gris_background {
  border-bottom: 5px inset var(--gris_background);
}

.border-bottom-5-outset-gris_background {
  border-bottom: 5px outset var(--gris_background);
}

.border-bottom-5-none-gris_background {
  border-bottom: 5px none var(--gris_background);
}

.border-bottom-5-hidden-gris_background {
  border-bottom: 5px hidden var(--gris_background);
}

.border-right-5-solid-corail_mh_brand {
  border-right: 5px solid var(--corail_mh_brand);
}

.border-right-5-dashed-corail_mh_brand {
  border-right: 5px dashed var(--corail_mh_brand);
}

.border-right-5-dotted-corail_mh_brand {
  border-right: 5px dotted var(--corail_mh_brand);
}

.border-right-5-double-corail_mh_brand {
  border-right: 5px double var(--corail_mh_brand);
}

.border-right-5-groove-corail_mh_brand {
  border-right: 5px groove var(--corail_mh_brand);
}

.border-right-5-ridge-corail_mh_brand {
  border-right: 5px ridge var(--corail_mh_brand);
}

.border-right-5-inset-corail_mh_brand {
  border-right: 5px inset var(--corail_mh_brand);
}

.border-right-5-outset-corail_mh_brand {
  border-right: 5px outset var(--corail_mh_brand);
}

.border-right-5-none-corail_mh_brand {
  border-right: 5px none var(--corail_mh_brand);
}

.border-right-5-hidden-corail_mh_brand {
  border-right: 5px hidden var(--corail_mh_brand);
}

.border-right-5-solid-corail_mh_dark {
  border-right: 5px solid var(--corail_mh_dark);
}

.border-right-5-dashed-corail_mh_dark {
  border-right: 5px dashed var(--corail_mh_dark);
}

.border-right-5-dotted-corail_mh_dark {
  border-right: 5px dotted var(--corail_mh_dark);
}

.border-right-5-double-corail_mh_dark {
  border-right: 5px double var(--corail_mh_dark);
}

.border-right-5-groove-corail_mh_dark {
  border-right: 5px groove var(--corail_mh_dark);
}

.border-right-5-ridge-corail_mh_dark {
  border-right: 5px ridge var(--corail_mh_dark);
}

.border-right-5-inset-corail_mh_dark {
  border-right: 5px inset var(--corail_mh_dark);
}

.border-right-5-outset-corail_mh_dark {
  border-right: 5px outset var(--corail_mh_dark);
}

.border-right-5-none-corail_mh_dark {
  border-right: 5px none var(--corail_mh_dark);
}

.border-right-5-hidden-corail_mh_dark {
  border-right: 5px hidden var(--corail_mh_dark);
}

.border-right-5-solid-corail_mh_light {
  border-right: 5px solid var(--corail_mh_light);
}

.border-right-5-dashed-corail_mh_light {
  border-right: 5px dashed var(--corail_mh_light);
}

.border-right-5-dotted-corail_mh_light {
  border-right: 5px dotted var(--corail_mh_light);
}

.border-right-5-double-corail_mh_light {
  border-right: 5px double var(--corail_mh_light);
}

.border-right-5-groove-corail_mh_light {
  border-right: 5px groove var(--corail_mh_light);
}

.border-right-5-ridge-corail_mh_light {
  border-right: 5px ridge var(--corail_mh_light);
}

.border-right-5-inset-corail_mh_light {
  border-right: 5px inset var(--corail_mh_light);
}

.border-right-5-outset-corail_mh_light {
  border-right: 5px outset var(--corail_mh_light);
}

.border-right-5-none-corail_mh_light {
  border-right: 5px none var(--corail_mh_light);
}

.border-right-5-hidden-corail_mh_light {
  border-right: 5px hidden var(--corail_mh_light);
}

.border-right-5-solid-gris_sable_light {
  border-right: 5px solid var(--gris_sable_light);
}

.border-right-5-dashed-gris_sable_light {
  border-right: 5px dashed var(--gris_sable_light);
}

.border-right-5-dotted-gris_sable_light {
  border-right: 5px dotted var(--gris_sable_light);
}

.border-right-5-double-gris_sable_light {
  border-right: 5px double var(--gris_sable_light);
}

.border-right-5-groove-gris_sable_light {
  border-right: 5px groove var(--gris_sable_light);
}

.border-right-5-ridge-gris_sable_light {
  border-right: 5px ridge var(--gris_sable_light);
}

.border-right-5-inset-gris_sable_light {
  border-right: 5px inset var(--gris_sable_light);
}

.border-right-5-outset-gris_sable_light {
  border-right: 5px outset var(--gris_sable_light);
}

.border-right-5-none-gris_sable_light {
  border-right: 5px none var(--gris_sable_light);
}

.border-right-5-hidden-gris_sable_light {
  border-right: 5px hidden var(--gris_sable_light);
}

.border-right-5-solid-gris_sable_dark {
  border-right: 5px solid var(--gris_sable_dark);
}

.border-right-5-dashed-gris_sable_dark {
  border-right: 5px dashed var(--gris_sable_dark);
}

.border-right-5-dotted-gris_sable_dark {
  border-right: 5px dotted var(--gris_sable_dark);
}

.border-right-5-double-gris_sable_dark {
  border-right: 5px double var(--gris_sable_dark);
}

.border-right-5-groove-gris_sable_dark {
  border-right: 5px groove var(--gris_sable_dark);
}

.border-right-5-ridge-gris_sable_dark {
  border-right: 5px ridge var(--gris_sable_dark);
}

.border-right-5-inset-gris_sable_dark {
  border-right: 5px inset var(--gris_sable_dark);
}

.border-right-5-outset-gris_sable_dark {
  border-right: 5px outset var(--gris_sable_dark);
}

.border-right-5-none-gris_sable_dark {
  border-right: 5px none var(--gris_sable_dark);
}

.border-right-5-hidden-gris_sable_dark {
  border-right: 5px hidden var(--gris_sable_dark);
}

.border-right-5-solid-jaune_ivoire_light {
  border-right: 5px solid var(--jaune_ivoire_light);
}

.border-right-5-dashed-jaune_ivoire_light {
  border-right: 5px dashed var(--jaune_ivoire_light);
}

.border-right-5-dotted-jaune_ivoire_light {
  border-right: 5px dotted var(--jaune_ivoire_light);
}

.border-right-5-double-jaune_ivoire_light {
  border-right: 5px double var(--jaune_ivoire_light);
}

.border-right-5-groove-jaune_ivoire_light {
  border-right: 5px groove var(--jaune_ivoire_light);
}

.border-right-5-ridge-jaune_ivoire_light {
  border-right: 5px ridge var(--jaune_ivoire_light);
}

.border-right-5-inset-jaune_ivoire_light {
  border-right: 5px inset var(--jaune_ivoire_light);
}

.border-right-5-outset-jaune_ivoire_light {
  border-right: 5px outset var(--jaune_ivoire_light);
}

.border-right-5-none-jaune_ivoire_light {
  border-right: 5px none var(--jaune_ivoire_light);
}

.border-right-5-hidden-jaune_ivoire_light {
  border-right: 5px hidden var(--jaune_ivoire_light);
}

.border-right-5-solid-jaune_ivoire_dark {
  border-right: 5px solid var(--jaune_ivoire_dark);
}

.border-right-5-dashed-jaune_ivoire_dark {
  border-right: 5px dashed var(--jaune_ivoire_dark);
}

.border-right-5-dotted-jaune_ivoire_dark {
  border-right: 5px dotted var(--jaune_ivoire_dark);
}

.border-right-5-double-jaune_ivoire_dark {
  border-right: 5px double var(--jaune_ivoire_dark);
}

.border-right-5-groove-jaune_ivoire_dark {
  border-right: 5px groove var(--jaune_ivoire_dark);
}

.border-right-5-ridge-jaune_ivoire_dark {
  border-right: 5px ridge var(--jaune_ivoire_dark);
}

.border-right-5-inset-jaune_ivoire_dark {
  border-right: 5px inset var(--jaune_ivoire_dark);
}

.border-right-5-outset-jaune_ivoire_dark {
  border-right: 5px outset var(--jaune_ivoire_dark);
}

.border-right-5-none-jaune_ivoire_dark {
  border-right: 5px none var(--jaune_ivoire_dark);
}

.border-right-5-hidden-jaune_ivoire_dark {
  border-right: 5px hidden var(--jaune_ivoire_dark);
}

.border-right-5-solid-jaune_light {
  border-right: 5px solid var(--jaune_light);
}

.border-right-5-dashed-jaune_light {
  border-right: 5px dashed var(--jaune_light);
}

.border-right-5-dotted-jaune_light {
  border-right: 5px dotted var(--jaune_light);
}

.border-right-5-double-jaune_light {
  border-right: 5px double var(--jaune_light);
}

.border-right-5-groove-jaune_light {
  border-right: 5px groove var(--jaune_light);
}

.border-right-5-ridge-jaune_light {
  border-right: 5px ridge var(--jaune_light);
}

.border-right-5-inset-jaune_light {
  border-right: 5px inset var(--jaune_light);
}

.border-right-5-outset-jaune_light {
  border-right: 5px outset var(--jaune_light);
}

.border-right-5-none-jaune_light {
  border-right: 5px none var(--jaune_light);
}

.border-right-5-hidden-jaune_light {
  border-right: 5px hidden var(--jaune_light);
}

.border-right-5-solid-jaune_dark {
  border-right: 5px solid var(--jaune_dark);
}

.border-right-5-dashed-jaune_dark {
  border-right: 5px dashed var(--jaune_dark);
}

.border-right-5-dotted-jaune_dark {
  border-right: 5px dotted var(--jaune_dark);
}

.border-right-5-double-jaune_dark {
  border-right: 5px double var(--jaune_dark);
}

.border-right-5-groove-jaune_dark {
  border-right: 5px groove var(--jaune_dark);
}

.border-right-5-ridge-jaune_dark {
  border-right: 5px ridge var(--jaune_dark);
}

.border-right-5-inset-jaune_dark {
  border-right: 5px inset var(--jaune_dark);
}

.border-right-5-outset-jaune_dark {
  border-right: 5px outset var(--jaune_dark);
}

.border-right-5-none-jaune_dark {
  border-right: 5px none var(--jaune_dark);
}

.border-right-5-hidden-jaune_dark {
  border-right: 5px hidden var(--jaune_dark);
}

.border-right-5-solid-orange_normal {
  border-right: 5px solid var(--orange_normal);
}

.border-right-5-dashed-orange_normal {
  border-right: 5px dashed var(--orange_normal);
}

.border-right-5-dotted-orange_normal {
  border-right: 5px dotted var(--orange_normal);
}

.border-right-5-double-orange_normal {
  border-right: 5px double var(--orange_normal);
}

.border-right-5-groove-orange_normal {
  border-right: 5px groove var(--orange_normal);
}

.border-right-5-ridge-orange_normal {
  border-right: 5px ridge var(--orange_normal);
}

.border-right-5-inset-orange_normal {
  border-right: 5px inset var(--orange_normal);
}

.border-right-5-outset-orange_normal {
  border-right: 5px outset var(--orange_normal);
}

.border-right-5-none-orange_normal {
  border-right: 5px none var(--orange_normal);
}

.border-right-5-hidden-orange_normal {
  border-right: 5px hidden var(--orange_normal);
}

.border-right-5-solid-orange_dark {
  border-right: 5px solid var(--orange_dark);
}

.border-right-5-dashed-orange_dark {
  border-right: 5px dashed var(--orange_dark);
}

.border-right-5-dotted-orange_dark {
  border-right: 5px dotted var(--orange_dark);
}

.border-right-5-double-orange_dark {
  border-right: 5px double var(--orange_dark);
}

.border-right-5-groove-orange_dark {
  border-right: 5px groove var(--orange_dark);
}

.border-right-5-ridge-orange_dark {
  border-right: 5px ridge var(--orange_dark);
}

.border-right-5-inset-orange_dark {
  border-right: 5px inset var(--orange_dark);
}

.border-right-5-outset-orange_dark {
  border-right: 5px outset var(--orange_dark);
}

.border-right-5-none-orange_dark {
  border-right: 5px none var(--orange_dark);
}

.border-right-5-hidden-orange_dark {
  border-right: 5px hidden var(--orange_dark);
}

.border-right-5-solid-vert_light {
  border-right: 5px solid var(--vert_light);
}

.border-right-5-dashed-vert_light {
  border-right: 5px dashed var(--vert_light);
}

.border-right-5-dotted-vert_light {
  border-right: 5px dotted var(--vert_light);
}

.border-right-5-double-vert_light {
  border-right: 5px double var(--vert_light);
}

.border-right-5-groove-vert_light {
  border-right: 5px groove var(--vert_light);
}

.border-right-5-ridge-vert_light {
  border-right: 5px ridge var(--vert_light);
}

.border-right-5-inset-vert_light {
  border-right: 5px inset var(--vert_light);
}

.border-right-5-outset-vert_light {
  border-right: 5px outset var(--vert_light);
}

.border-right-5-none-vert_light {
  border-right: 5px none var(--vert_light);
}

.border-right-5-hidden-vert_light {
  border-right: 5px hidden var(--vert_light);
}

.border-right-5-solid-vert_dark {
  border-right: 5px solid var(--vert_dark);
}

.border-right-5-dashed-vert_dark {
  border-right: 5px dashed var(--vert_dark);
}

.border-right-5-dotted-vert_dark {
  border-right: 5px dotted var(--vert_dark);
}

.border-right-5-double-vert_dark {
  border-right: 5px double var(--vert_dark);
}

.border-right-5-groove-vert_dark {
  border-right: 5px groove var(--vert_dark);
}

.border-right-5-ridge-vert_dark {
  border-right: 5px ridge var(--vert_dark);
}

.border-right-5-inset-vert_dark {
  border-right: 5px inset var(--vert_dark);
}

.border-right-5-outset-vert_dark {
  border-right: 5px outset var(--vert_dark);
}

.border-right-5-none-vert_dark {
  border-right: 5px none var(--vert_dark);
}

.border-right-5-hidden-vert_dark {
  border-right: 5px hidden var(--vert_dark);
}

.border-right-5-solid-bleu_turquoise_light {
  border-right: 5px solid var(--bleu_turquoise_light);
}

.border-right-5-dashed-bleu_turquoise_light {
  border-right: 5px dashed var(--bleu_turquoise_light);
}

.border-right-5-dotted-bleu_turquoise_light {
  border-right: 5px dotted var(--bleu_turquoise_light);
}

.border-right-5-double-bleu_turquoise_light {
  border-right: 5px double var(--bleu_turquoise_light);
}

.border-right-5-groove-bleu_turquoise_light {
  border-right: 5px groove var(--bleu_turquoise_light);
}

.border-right-5-ridge-bleu_turquoise_light {
  border-right: 5px ridge var(--bleu_turquoise_light);
}

.border-right-5-inset-bleu_turquoise_light {
  border-right: 5px inset var(--bleu_turquoise_light);
}

.border-right-5-outset-bleu_turquoise_light {
  border-right: 5px outset var(--bleu_turquoise_light);
}

.border-right-5-none-bleu_turquoise_light {
  border-right: 5px none var(--bleu_turquoise_light);
}

.border-right-5-hidden-bleu_turquoise_light {
  border-right: 5px hidden var(--bleu_turquoise_light);
}

.border-right-5-solid-bleu_turquoise_middle {
  border-right: 5px solid var(--bleu_turquoise_middle);
}

.border-right-5-dashed-bleu_turquoise_middle {
  border-right: 5px dashed var(--bleu_turquoise_middle);
}

.border-right-5-dotted-bleu_turquoise_middle {
  border-right: 5px dotted var(--bleu_turquoise_middle);
}

.border-right-5-double-bleu_turquoise_middle {
  border-right: 5px double var(--bleu_turquoise_middle);
}

.border-right-5-groove-bleu_turquoise_middle {
  border-right: 5px groove var(--bleu_turquoise_middle);
}

.border-right-5-ridge-bleu_turquoise_middle {
  border-right: 5px ridge var(--bleu_turquoise_middle);
}

.border-right-5-inset-bleu_turquoise_middle {
  border-right: 5px inset var(--bleu_turquoise_middle);
}

.border-right-5-outset-bleu_turquoise_middle {
  border-right: 5px outset var(--bleu_turquoise_middle);
}

.border-right-5-none-bleu_turquoise_middle {
  border-right: 5px none var(--bleu_turquoise_middle);
}

.border-right-5-hidden-bleu_turquoise_middle {
  border-right: 5px hidden var(--bleu_turquoise_middle);
}

.border-right-5-solid-bleu_turquoise_dark {
  border-right: 5px solid var(--bleu_turquoise_dark);
}

.border-right-5-dashed-bleu_turquoise_dark {
  border-right: 5px dashed var(--bleu_turquoise_dark);
}

.border-right-5-dotted-bleu_turquoise_dark {
  border-right: 5px dotted var(--bleu_turquoise_dark);
}

.border-right-5-double-bleu_turquoise_dark {
  border-right: 5px double var(--bleu_turquoise_dark);
}

.border-right-5-groove-bleu_turquoise_dark {
  border-right: 5px groove var(--bleu_turquoise_dark);
}

.border-right-5-ridge-bleu_turquoise_dark {
  border-right: 5px ridge var(--bleu_turquoise_dark);
}

.border-right-5-inset-bleu_turquoise_dark {
  border-right: 5px inset var(--bleu_turquoise_dark);
}

.border-right-5-outset-bleu_turquoise_dark {
  border-right: 5px outset var(--bleu_turquoise_dark);
}

.border-right-5-none-bleu_turquoise_dark {
  border-right: 5px none var(--bleu_turquoise_dark);
}

.border-right-5-hidden-bleu_turquoise_dark {
  border-right: 5px hidden var(--bleu_turquoise_dark);
}

.border-right-5-solid-bleu_light {
  border-right: 5px solid var(--bleu_light);
}

.border-right-5-dashed-bleu_light {
  border-right: 5px dashed var(--bleu_light);
}

.border-right-5-dotted-bleu_light {
  border-right: 5px dotted var(--bleu_light);
}

.border-right-5-double-bleu_light {
  border-right: 5px double var(--bleu_light);
}

.border-right-5-groove-bleu_light {
  border-right: 5px groove var(--bleu_light);
}

.border-right-5-ridge-bleu_light {
  border-right: 5px ridge var(--bleu_light);
}

.border-right-5-inset-bleu_light {
  border-right: 5px inset var(--bleu_light);
}

.border-right-5-outset-bleu_light {
  border-right: 5px outset var(--bleu_light);
}

.border-right-5-none-bleu_light {
  border-right: 5px none var(--bleu_light);
}

.border-right-5-hidden-bleu_light {
  border-right: 5px hidden var(--bleu_light);
}

.border-right-5-solid-bleu_dark {
  border-right: 5px solid var(--bleu_dark);
}

.border-right-5-dashed-bleu_dark {
  border-right: 5px dashed var(--bleu_dark);
}

.border-right-5-dotted-bleu_dark {
  border-right: 5px dotted var(--bleu_dark);
}

.border-right-5-double-bleu_dark {
  border-right: 5px double var(--bleu_dark);
}

.border-right-5-groove-bleu_dark {
  border-right: 5px groove var(--bleu_dark);
}

.border-right-5-ridge-bleu_dark {
  border-right: 5px ridge var(--bleu_dark);
}

.border-right-5-inset-bleu_dark {
  border-right: 5px inset var(--bleu_dark);
}

.border-right-5-outset-bleu_dark {
  border-right: 5px outset var(--bleu_dark);
}

.border-right-5-none-bleu_dark {
  border-right: 5px none var(--bleu_dark);
}

.border-right-5-hidden-bleu_dark {
  border-right: 5px hidden var(--bleu_dark);
}

.border-right-5-solid-bleu_klein_light {
  border-right: 5px solid var(--bleu_klein_light);
}

.border-right-5-dashed-bleu_klein_light {
  border-right: 5px dashed var(--bleu_klein_light);
}

.border-right-5-dotted-bleu_klein_light {
  border-right: 5px dotted var(--bleu_klein_light);
}

.border-right-5-double-bleu_klein_light {
  border-right: 5px double var(--bleu_klein_light);
}

.border-right-5-groove-bleu_klein_light {
  border-right: 5px groove var(--bleu_klein_light);
}

.border-right-5-ridge-bleu_klein_light {
  border-right: 5px ridge var(--bleu_klein_light);
}

.border-right-5-inset-bleu_klein_light {
  border-right: 5px inset var(--bleu_klein_light);
}

.border-right-5-outset-bleu_klein_light {
  border-right: 5px outset var(--bleu_klein_light);
}

.border-right-5-none-bleu_klein_light {
  border-right: 5px none var(--bleu_klein_light);
}

.border-right-5-hidden-bleu_klein_light {
  border-right: 5px hidden var(--bleu_klein_light);
}

.border-right-5-solid-bleu_klein_dark {
  border-right: 5px solid var(--bleu_klein_dark);
}

.border-right-5-dashed-bleu_klein_dark {
  border-right: 5px dashed var(--bleu_klein_dark);
}

.border-right-5-dotted-bleu_klein_dark {
  border-right: 5px dotted var(--bleu_klein_dark);
}

.border-right-5-double-bleu_klein_dark {
  border-right: 5px double var(--bleu_klein_dark);
}

.border-right-5-groove-bleu_klein_dark {
  border-right: 5px groove var(--bleu_klein_dark);
}

.border-right-5-ridge-bleu_klein_dark {
  border-right: 5px ridge var(--bleu_klein_dark);
}

.border-right-5-inset-bleu_klein_dark {
  border-right: 5px inset var(--bleu_klein_dark);
}

.border-right-5-outset-bleu_klein_dark {
  border-right: 5px outset var(--bleu_klein_dark);
}

.border-right-5-none-bleu_klein_dark {
  border-right: 5px none var(--bleu_klein_dark);
}

.border-right-5-hidden-bleu_klein_dark {
  border-right: 5px hidden var(--bleu_klein_dark);
}

.border-right-5-solid-violet_light {
  border-right: 5px solid var(--violet_light);
}

.border-right-5-dashed-violet_light {
  border-right: 5px dashed var(--violet_light);
}

.border-right-5-dotted-violet_light {
  border-right: 5px dotted var(--violet_light);
}

.border-right-5-double-violet_light {
  border-right: 5px double var(--violet_light);
}

.border-right-5-groove-violet_light {
  border-right: 5px groove var(--violet_light);
}

.border-right-5-ridge-violet_light {
  border-right: 5px ridge var(--violet_light);
}

.border-right-5-inset-violet_light {
  border-right: 5px inset var(--violet_light);
}

.border-right-5-outset-violet_light {
  border-right: 5px outset var(--violet_light);
}

.border-right-5-none-violet_light {
  border-right: 5px none var(--violet_light);
}

.border-right-5-hidden-violet_light {
  border-right: 5px hidden var(--violet_light);
}

.border-right-5-solid-violet_dark {
  border-right: 5px solid var(--violet_dark);
}

.border-right-5-dashed-violet_dark {
  border-right: 5px dashed var(--violet_dark);
}

.border-right-5-dotted-violet_dark {
  border-right: 5px dotted var(--violet_dark);
}

.border-right-5-double-violet_dark {
  border-right: 5px double var(--violet_dark);
}

.border-right-5-groove-violet_dark {
  border-right: 5px groove var(--violet_dark);
}

.border-right-5-ridge-violet_dark {
  border-right: 5px ridge var(--violet_dark);
}

.border-right-5-inset-violet_dark {
  border-right: 5px inset var(--violet_dark);
}

.border-right-5-outset-violet_dark {
  border-right: 5px outset var(--violet_dark);
}

.border-right-5-none-violet_dark {
  border-right: 5px none var(--violet_dark);
}

.border-right-5-hidden-violet_dark {
  border-right: 5px hidden var(--violet_dark);
}

.border-right-5-solid-mauve {
  border-right: 5px solid var(--mauve);
}

.border-right-5-dashed-mauve {
  border-right: 5px dashed var(--mauve);
}

.border-right-5-dotted-mauve {
  border-right: 5px dotted var(--mauve);
}

.border-right-5-double-mauve {
  border-right: 5px double var(--mauve);
}

.border-right-5-groove-mauve {
  border-right: 5px groove var(--mauve);
}

.border-right-5-ridge-mauve {
  border-right: 5px ridge var(--mauve);
}

.border-right-5-inset-mauve {
  border-right: 5px inset var(--mauve);
}

.border-right-5-outset-mauve {
  border-right: 5px outset var(--mauve);
}

.border-right-5-none-mauve {
  border-right: 5px none var(--mauve);
}

.border-right-5-hidden-mauve {
  border-right: 5px hidden var(--mauve);
}

.border-right-5-solid-rose_light {
  border-right: 5px solid var(--rose_light);
}

.border-right-5-dashed-rose_light {
  border-right: 5px dashed var(--rose_light);
}

.border-right-5-dotted-rose_light {
  border-right: 5px dotted var(--rose_light);
}

.border-right-5-double-rose_light {
  border-right: 5px double var(--rose_light);
}

.border-right-5-groove-rose_light {
  border-right: 5px groove var(--rose_light);
}

.border-right-5-ridge-rose_light {
  border-right: 5px ridge var(--rose_light);
}

.border-right-5-inset-rose_light {
  border-right: 5px inset var(--rose_light);
}

.border-right-5-outset-rose_light {
  border-right: 5px outset var(--rose_light);
}

.border-right-5-none-rose_light {
  border-right: 5px none var(--rose_light);
}

.border-right-5-hidden-rose_light {
  border-right: 5px hidden var(--rose_light);
}

.border-right-5-solid-rose_dark {
  border-right: 5px solid var(--rose_dark);
}

.border-right-5-dashed-rose_dark {
  border-right: 5px dashed var(--rose_dark);
}

.border-right-5-dotted-rose_dark {
  border-right: 5px dotted var(--rose_dark);
}

.border-right-5-double-rose_dark {
  border-right: 5px double var(--rose_dark);
}

.border-right-5-groove-rose_dark {
  border-right: 5px groove var(--rose_dark);
}

.border-right-5-ridge-rose_dark {
  border-right: 5px ridge var(--rose_dark);
}

.border-right-5-inset-rose_dark {
  border-right: 5px inset var(--rose_dark);
}

.border-right-5-outset-rose_dark {
  border-right: 5px outset var(--rose_dark);
}

.border-right-5-none-rose_dark {
  border-right: 5px none var(--rose_dark);
}

.border-right-5-hidden-rose_dark {
  border-right: 5px hidden var(--rose_dark);
}

.border-right-5-solid-rose_fushia {
  border-right: 5px solid var(--rose_fushia);
}

.border-right-5-dashed-rose_fushia {
  border-right: 5px dashed var(--rose_fushia);
}

.border-right-5-dotted-rose_fushia {
  border-right: 5px dotted var(--rose_fushia);
}

.border-right-5-double-rose_fushia {
  border-right: 5px double var(--rose_fushia);
}

.border-right-5-groove-rose_fushia {
  border-right: 5px groove var(--rose_fushia);
}

.border-right-5-ridge-rose_fushia {
  border-right: 5px ridge var(--rose_fushia);
}

.border-right-5-inset-rose_fushia {
  border-right: 5px inset var(--rose_fushia);
}

.border-right-5-outset-rose_fushia {
  border-right: 5px outset var(--rose_fushia);
}

.border-right-5-none-rose_fushia {
  border-right: 5px none var(--rose_fushia);
}

.border-right-5-hidden-rose_fushia {
  border-right: 5px hidden var(--rose_fushia);
}

.border-right-5-solid-rouge_framboise {
  border-right: 5px solid var(--rouge_framboise);
}

.border-right-5-dashed-rouge_framboise {
  border-right: 5px dashed var(--rouge_framboise);
}

.border-right-5-dotted-rouge_framboise {
  border-right: 5px dotted var(--rouge_framboise);
}

.border-right-5-double-rouge_framboise {
  border-right: 5px double var(--rouge_framboise);
}

.border-right-5-groove-rouge_framboise {
  border-right: 5px groove var(--rouge_framboise);
}

.border-right-5-ridge-rouge_framboise {
  border-right: 5px ridge var(--rouge_framboise);
}

.border-right-5-inset-rouge_framboise {
  border-right: 5px inset var(--rouge_framboise);
}

.border-right-5-outset-rouge_framboise {
  border-right: 5px outset var(--rouge_framboise);
}

.border-right-5-none-rouge_framboise {
  border-right: 5px none var(--rouge_framboise);
}

.border-right-5-hidden-rouge_framboise {
  border-right: 5px hidden var(--rouge_framboise);
}

.border-right-5-solid-rose_chair_light {
  border-right: 5px solid var(--rose_chair_light);
}

.border-right-5-dashed-rose_chair_light {
  border-right: 5px dashed var(--rose_chair_light);
}

.border-right-5-dotted-rose_chair_light {
  border-right: 5px dotted var(--rose_chair_light);
}

.border-right-5-double-rose_chair_light {
  border-right: 5px double var(--rose_chair_light);
}

.border-right-5-groove-rose_chair_light {
  border-right: 5px groove var(--rose_chair_light);
}

.border-right-5-ridge-rose_chair_light {
  border-right: 5px ridge var(--rose_chair_light);
}

.border-right-5-inset-rose_chair_light {
  border-right: 5px inset var(--rose_chair_light);
}

.border-right-5-outset-rose_chair_light {
  border-right: 5px outset var(--rose_chair_light);
}

.border-right-5-none-rose_chair_light {
  border-right: 5px none var(--rose_chair_light);
}

.border-right-5-hidden-rose_chair_light {
  border-right: 5px hidden var(--rose_chair_light);
}

.border-right-5-solid-rose_chair_dark {
  border-right: 5px solid var(--rose_chair_dark);
}

.border-right-5-dashed-rose_chair_dark {
  border-right: 5px dashed var(--rose_chair_dark);
}

.border-right-5-dotted-rose_chair_dark {
  border-right: 5px dotted var(--rose_chair_dark);
}

.border-right-5-double-rose_chair_dark {
  border-right: 5px double var(--rose_chair_dark);
}

.border-right-5-groove-rose_chair_dark {
  border-right: 5px groove var(--rose_chair_dark);
}

.border-right-5-ridge-rose_chair_dark {
  border-right: 5px ridge var(--rose_chair_dark);
}

.border-right-5-inset-rose_chair_dark {
  border-right: 5px inset var(--rose_chair_dark);
}

.border-right-5-outset-rose_chair_dark {
  border-right: 5px outset var(--rose_chair_dark);
}

.border-right-5-none-rose_chair_dark {
  border-right: 5px none var(--rose_chair_dark);
}

.border-right-5-hidden-rose_chair_dark {
  border-right: 5px hidden var(--rose_chair_dark);
}

.border-right-5-solid-vert_positif {
  border-right: 5px solid var(--vert_positif);
}

.border-right-5-dashed-vert_positif {
  border-right: 5px dashed var(--vert_positif);
}

.border-right-5-dotted-vert_positif {
  border-right: 5px dotted var(--vert_positif);
}

.border-right-5-double-vert_positif {
  border-right: 5px double var(--vert_positif);
}

.border-right-5-groove-vert_positif {
  border-right: 5px groove var(--vert_positif);
}

.border-right-5-ridge-vert_positif {
  border-right: 5px ridge var(--vert_positif);
}

.border-right-5-inset-vert_positif {
  border-right: 5px inset var(--vert_positif);
}

.border-right-5-outset-vert_positif {
  border-right: 5px outset var(--vert_positif);
}

.border-right-5-none-vert_positif {
  border-right: 5px none var(--vert_positif);
}

.border-right-5-hidden-vert_positif {
  border-right: 5px hidden var(--vert_positif);
}

.border-right-5-solid-rouge_negatif {
  border-right: 5px solid var(--rouge_negatif);
}

.border-right-5-dashed-rouge_negatif {
  border-right: 5px dashed var(--rouge_negatif);
}

.border-right-5-dotted-rouge_negatif {
  border-right: 5px dotted var(--rouge_negatif);
}

.border-right-5-double-rouge_negatif {
  border-right: 5px double var(--rouge_negatif);
}

.border-right-5-groove-rouge_negatif {
  border-right: 5px groove var(--rouge_negatif);
}

.border-right-5-ridge-rouge_negatif {
  border-right: 5px ridge var(--rouge_negatif);
}

.border-right-5-inset-rouge_negatif {
  border-right: 5px inset var(--rouge_negatif);
}

.border-right-5-outset-rouge_negatif {
  border-right: 5px outset var(--rouge_negatif);
}

.border-right-5-none-rouge_negatif {
  border-right: 5px none var(--rouge_negatif);
}

.border-right-5-hidden-rouge_negatif {
  border-right: 5px hidden var(--rouge_negatif);
}

.border-right-5-solid-bleu_texte {
  border-right: 5px solid var(--bleu_texte);
}

.border-right-5-dashed-bleu_texte {
  border-right: 5px dashed var(--bleu_texte);
}

.border-right-5-dotted-bleu_texte {
  border-right: 5px dotted var(--bleu_texte);
}

.border-right-5-double-bleu_texte {
  border-right: 5px double var(--bleu_texte);
}

.border-right-5-groove-bleu_texte {
  border-right: 5px groove var(--bleu_texte);
}

.border-right-5-ridge-bleu_texte {
  border-right: 5px ridge var(--bleu_texte);
}

.border-right-5-inset-bleu_texte {
  border-right: 5px inset var(--bleu_texte);
}

.border-right-5-outset-bleu_texte {
  border-right: 5px outset var(--bleu_texte);
}

.border-right-5-none-bleu_texte {
  border-right: 5px none var(--bleu_texte);
}

.border-right-5-hidden-bleu_texte {
  border-right: 5px hidden var(--bleu_texte);
}

.border-right-5-solid-alerte_vert_positif {
  border-right: 5px solid var(--alerte_vert_positif);
}

.border-right-5-dashed-alerte_vert_positif {
  border-right: 5px dashed var(--alerte_vert_positif);
}

.border-right-5-dotted-alerte_vert_positif {
  border-right: 5px dotted var(--alerte_vert_positif);
}

.border-right-5-double-alerte_vert_positif {
  border-right: 5px double var(--alerte_vert_positif);
}

.border-right-5-groove-alerte_vert_positif {
  border-right: 5px groove var(--alerte_vert_positif);
}

.border-right-5-ridge-alerte_vert_positif {
  border-right: 5px ridge var(--alerte_vert_positif);
}

.border-right-5-inset-alerte_vert_positif {
  border-right: 5px inset var(--alerte_vert_positif);
}

.border-right-5-outset-alerte_vert_positif {
  border-right: 5px outset var(--alerte_vert_positif);
}

.border-right-5-none-alerte_vert_positif {
  border-right: 5px none var(--alerte_vert_positif);
}

.border-right-5-hidden-alerte_vert_positif {
  border-right: 5px hidden var(--alerte_vert_positif);
}

.border-right-5-solid-alerte_rouge_negatif {
  border-right: 5px solid var(--alerte_rouge_negatif);
}

.border-right-5-dashed-alerte_rouge_negatif {
  border-right: 5px dashed var(--alerte_rouge_negatif);
}

.border-right-5-dotted-alerte_rouge_negatif {
  border-right: 5px dotted var(--alerte_rouge_negatif);
}

.border-right-5-double-alerte_rouge_negatif {
  border-right: 5px double var(--alerte_rouge_negatif);
}

.border-right-5-groove-alerte_rouge_negatif {
  border-right: 5px groove var(--alerte_rouge_negatif);
}

.border-right-5-ridge-alerte_rouge_negatif {
  border-right: 5px ridge var(--alerte_rouge_negatif);
}

.border-right-5-inset-alerte_rouge_negatif {
  border-right: 5px inset var(--alerte_rouge_negatif);
}

.border-right-5-outset-alerte_rouge_negatif {
  border-right: 5px outset var(--alerte_rouge_negatif);
}

.border-right-5-none-alerte_rouge_negatif {
  border-right: 5px none var(--alerte_rouge_negatif);
}

.border-right-5-hidden-alerte_rouge_negatif {
  border-right: 5px hidden var(--alerte_rouge_negatif);
}

.border-right-5-solid-alerte_bleu_information {
  border-right: 5px solid var(--alerte_bleu_information);
}

.border-right-5-dashed-alerte_bleu_information {
  border-right: 5px dashed var(--alerte_bleu_information);
}

.border-right-5-dotted-alerte_bleu_information {
  border-right: 5px dotted var(--alerte_bleu_information);
}

.border-right-5-double-alerte_bleu_information {
  border-right: 5px double var(--alerte_bleu_information);
}

.border-right-5-groove-alerte_bleu_information {
  border-right: 5px groove var(--alerte_bleu_information);
}

.border-right-5-ridge-alerte_bleu_information {
  border-right: 5px ridge var(--alerte_bleu_information);
}

.border-right-5-inset-alerte_bleu_information {
  border-right: 5px inset var(--alerte_bleu_information);
}

.border-right-5-outset-alerte_bleu_information {
  border-right: 5px outset var(--alerte_bleu_information);
}

.border-right-5-none-alerte_bleu_information {
  border-right: 5px none var(--alerte_bleu_information);
}

.border-right-5-hidden-alerte_bleu_information {
  border-right: 5px hidden var(--alerte_bleu_information);
}

.border-right-5-solid-alerte_jaune_attention {
  border-right: 5px solid var(--alerte_jaune_attention);
}

.border-right-5-dashed-alerte_jaune_attention {
  border-right: 5px dashed var(--alerte_jaune_attention);
}

.border-right-5-dotted-alerte_jaune_attention {
  border-right: 5px dotted var(--alerte_jaune_attention);
}

.border-right-5-double-alerte_jaune_attention {
  border-right: 5px double var(--alerte_jaune_attention);
}

.border-right-5-groove-alerte_jaune_attention {
  border-right: 5px groove var(--alerte_jaune_attention);
}

.border-right-5-ridge-alerte_jaune_attention {
  border-right: 5px ridge var(--alerte_jaune_attention);
}

.border-right-5-inset-alerte_jaune_attention {
  border-right: 5px inset var(--alerte_jaune_attention);
}

.border-right-5-outset-alerte_jaune_attention {
  border-right: 5px outset var(--alerte_jaune_attention);
}

.border-right-5-none-alerte_jaune_attention {
  border-right: 5px none var(--alerte_jaune_attention);
}

.border-right-5-hidden-alerte_jaune_attention {
  border-right: 5px hidden var(--alerte_jaune_attention);
}

.border-right-5-solid-blanc {
  border-right: 5px solid #fff;
}

.border-right-5-dashed-blanc {
  border-right: 5px dashed #fff;
}

.border-right-5-dotted-blanc {
  border-right: 5px dotted #fff;
}

.border-right-5-double-blanc {
  border-right: 5px double #fff;
}

.border-right-5-groove-blanc {
  border-right: 5px groove #fff;
}

.border-right-5-ridge-blanc {
  border-right: 5px ridge #fff;
}

.border-right-5-inset-blanc {
  border-right: 5px inset #fff;
}

.border-right-5-outset-blanc {
  border-right: 5px outset #fff;
}

.border-right-5-none-blanc {
  border-right: 5px #fff;
}

.border-right-5-hidden-blanc {
  border-right: 5px hidden #fff;
}

.border-right-5-solid-noir {
  border-right: 5px solid #222;
}

.border-right-5-dashed-noir {
  border-right: 5px dashed #222;
}

.border-right-5-dotted-noir {
  border-right: 5px dotted #222;
}

.border-right-5-double-noir {
  border-right: 5px double #222;
}

.border-right-5-groove-noir {
  border-right: 5px groove #222;
}

.border-right-5-ridge-noir {
  border-right: 5px ridge #222;
}

.border-right-5-inset-noir {
  border-right: 5px inset #222;
}

.border-right-5-outset-noir {
  border-right: 5px outset #222;
}

.border-right-5-none-noir {
  border-right: 5px #222;
}

.border-right-5-hidden-noir {
  border-right: 5px hidden #222;
}

.border-right-5-solid-gris_light {
  border-right: 5px solid var(--gris_light);
}

.border-right-5-dashed-gris_light {
  border-right: 5px dashed var(--gris_light);
}

.border-right-5-dotted-gris_light {
  border-right: 5px dotted var(--gris_light);
}

.border-right-5-double-gris_light {
  border-right: 5px double var(--gris_light);
}

.border-right-5-groove-gris_light {
  border-right: 5px groove var(--gris_light);
}

.border-right-5-ridge-gris_light {
  border-right: 5px ridge var(--gris_light);
}

.border-right-5-inset-gris_light {
  border-right: 5px inset var(--gris_light);
}

.border-right-5-outset-gris_light {
  border-right: 5px outset var(--gris_light);
}

.border-right-5-none-gris_light {
  border-right: 5px none var(--gris_light);
}

.border-right-5-hidden-gris_light {
  border-right: 5px hidden var(--gris_light);
}

.border-right-5-solid-gris_dark {
  border-right: 5px solid var(--gris_dark);
}

.border-right-5-dashed-gris_dark {
  border-right: 5px dashed var(--gris_dark);
}

.border-right-5-dotted-gris_dark {
  border-right: 5px dotted var(--gris_dark);
}

.border-right-5-double-gris_dark {
  border-right: 5px double var(--gris_dark);
}

.border-right-5-groove-gris_dark {
  border-right: 5px groove var(--gris_dark);
}

.border-right-5-ridge-gris_dark {
  border-right: 5px ridge var(--gris_dark);
}

.border-right-5-inset-gris_dark {
  border-right: 5px inset var(--gris_dark);
}

.border-right-5-outset-gris_dark {
  border-right: 5px outset var(--gris_dark);
}

.border-right-5-none-gris_dark {
  border-right: 5px none var(--gris_dark);
}

.border-right-5-hidden-gris_dark {
  border-right: 5px hidden var(--gris_dark);
}

.border-right-5-solid-gris_background {
  border-right: 5px solid var(--gris_background);
}

.border-right-5-dashed-gris_background {
  border-right: 5px dashed var(--gris_background);
}

.border-right-5-dotted-gris_background {
  border-right: 5px dotted var(--gris_background);
}

.border-right-5-double-gris_background {
  border-right: 5px double var(--gris_background);
}

.border-right-5-groove-gris_background {
  border-right: 5px groove var(--gris_background);
}

.border-right-5-ridge-gris_background {
  border-right: 5px ridge var(--gris_background);
}

.border-right-5-inset-gris_background {
  border-right: 5px inset var(--gris_background);
}

.border-right-5-outset-gris_background {
  border-right: 5px outset var(--gris_background);
}

.border-right-5-none-gris_background {
  border-right: 5px none var(--gris_background);
}

.border-right-5-hidden-gris_background {
  border-right: 5px hidden var(--gris_background);
}

.border-left-5-solid-corail_mh_brand {
  border-left: 5px solid var(--corail_mh_brand);
}

.border-left-5-dashed-corail_mh_brand {
  border-left: 5px dashed var(--corail_mh_brand);
}

.border-left-5-dotted-corail_mh_brand {
  border-left: 5px dotted var(--corail_mh_brand);
}

.border-left-5-double-corail_mh_brand {
  border-left: 5px double var(--corail_mh_brand);
}

.border-left-5-groove-corail_mh_brand {
  border-left: 5px groove var(--corail_mh_brand);
}

.border-left-5-ridge-corail_mh_brand {
  border-left: 5px ridge var(--corail_mh_brand);
}

.border-left-5-inset-corail_mh_brand {
  border-left: 5px inset var(--corail_mh_brand);
}

.border-left-5-outset-corail_mh_brand {
  border-left: 5px outset var(--corail_mh_brand);
}

.border-left-5-none-corail_mh_brand {
  border-left: 5px none var(--corail_mh_brand);
}

.border-left-5-hidden-corail_mh_brand {
  border-left: 5px hidden var(--corail_mh_brand);
}

.border-left-5-solid-corail_mh_dark {
  border-left: 5px solid var(--corail_mh_dark);
}

.border-left-5-dashed-corail_mh_dark {
  border-left: 5px dashed var(--corail_mh_dark);
}

.border-left-5-dotted-corail_mh_dark {
  border-left: 5px dotted var(--corail_mh_dark);
}

.border-left-5-double-corail_mh_dark {
  border-left: 5px double var(--corail_mh_dark);
}

.border-left-5-groove-corail_mh_dark {
  border-left: 5px groove var(--corail_mh_dark);
}

.border-left-5-ridge-corail_mh_dark {
  border-left: 5px ridge var(--corail_mh_dark);
}

.border-left-5-inset-corail_mh_dark {
  border-left: 5px inset var(--corail_mh_dark);
}

.border-left-5-outset-corail_mh_dark {
  border-left: 5px outset var(--corail_mh_dark);
}

.border-left-5-none-corail_mh_dark {
  border-left: 5px none var(--corail_mh_dark);
}

.border-left-5-hidden-corail_mh_dark {
  border-left: 5px hidden var(--corail_mh_dark);
}

.border-left-5-solid-corail_mh_light {
  border-left: 5px solid var(--corail_mh_light);
}

.border-left-5-dashed-corail_mh_light {
  border-left: 5px dashed var(--corail_mh_light);
}

.border-left-5-dotted-corail_mh_light {
  border-left: 5px dotted var(--corail_mh_light);
}

.border-left-5-double-corail_mh_light {
  border-left: 5px double var(--corail_mh_light);
}

.border-left-5-groove-corail_mh_light {
  border-left: 5px groove var(--corail_mh_light);
}

.border-left-5-ridge-corail_mh_light {
  border-left: 5px ridge var(--corail_mh_light);
}

.border-left-5-inset-corail_mh_light {
  border-left: 5px inset var(--corail_mh_light);
}

.border-left-5-outset-corail_mh_light {
  border-left: 5px outset var(--corail_mh_light);
}

.border-left-5-none-corail_mh_light {
  border-left: 5px none var(--corail_mh_light);
}

.border-left-5-hidden-corail_mh_light {
  border-left: 5px hidden var(--corail_mh_light);
}

.border-left-5-solid-gris_sable_light {
  border-left: 5px solid var(--gris_sable_light);
}

.border-left-5-dashed-gris_sable_light {
  border-left: 5px dashed var(--gris_sable_light);
}

.border-left-5-dotted-gris_sable_light {
  border-left: 5px dotted var(--gris_sable_light);
}

.border-left-5-double-gris_sable_light {
  border-left: 5px double var(--gris_sable_light);
}

.border-left-5-groove-gris_sable_light {
  border-left: 5px groove var(--gris_sable_light);
}

.border-left-5-ridge-gris_sable_light {
  border-left: 5px ridge var(--gris_sable_light);
}

.border-left-5-inset-gris_sable_light {
  border-left: 5px inset var(--gris_sable_light);
}

.border-left-5-outset-gris_sable_light {
  border-left: 5px outset var(--gris_sable_light);
}

.border-left-5-none-gris_sable_light {
  border-left: 5px none var(--gris_sable_light);
}

.border-left-5-hidden-gris_sable_light {
  border-left: 5px hidden var(--gris_sable_light);
}

.border-left-5-solid-gris_sable_dark {
  border-left: 5px solid var(--gris_sable_dark);
}

.border-left-5-dashed-gris_sable_dark {
  border-left: 5px dashed var(--gris_sable_dark);
}

.border-left-5-dotted-gris_sable_dark {
  border-left: 5px dotted var(--gris_sable_dark);
}

.border-left-5-double-gris_sable_dark {
  border-left: 5px double var(--gris_sable_dark);
}

.border-left-5-groove-gris_sable_dark {
  border-left: 5px groove var(--gris_sable_dark);
}

.border-left-5-ridge-gris_sable_dark {
  border-left: 5px ridge var(--gris_sable_dark);
}

.border-left-5-inset-gris_sable_dark {
  border-left: 5px inset var(--gris_sable_dark);
}

.border-left-5-outset-gris_sable_dark {
  border-left: 5px outset var(--gris_sable_dark);
}

.border-left-5-none-gris_sable_dark {
  border-left: 5px none var(--gris_sable_dark);
}

.border-left-5-hidden-gris_sable_dark {
  border-left: 5px hidden var(--gris_sable_dark);
}

.border-left-5-solid-jaune_ivoire_light {
  border-left: 5px solid var(--jaune_ivoire_light);
}

.border-left-5-dashed-jaune_ivoire_light {
  border-left: 5px dashed var(--jaune_ivoire_light);
}

.border-left-5-dotted-jaune_ivoire_light {
  border-left: 5px dotted var(--jaune_ivoire_light);
}

.border-left-5-double-jaune_ivoire_light {
  border-left: 5px double var(--jaune_ivoire_light);
}

.border-left-5-groove-jaune_ivoire_light {
  border-left: 5px groove var(--jaune_ivoire_light);
}

.border-left-5-ridge-jaune_ivoire_light {
  border-left: 5px ridge var(--jaune_ivoire_light);
}

.border-left-5-inset-jaune_ivoire_light {
  border-left: 5px inset var(--jaune_ivoire_light);
}

.border-left-5-outset-jaune_ivoire_light {
  border-left: 5px outset var(--jaune_ivoire_light);
}

.border-left-5-none-jaune_ivoire_light {
  border-left: 5px none var(--jaune_ivoire_light);
}

.border-left-5-hidden-jaune_ivoire_light {
  border-left: 5px hidden var(--jaune_ivoire_light);
}

.border-left-5-solid-jaune_ivoire_dark {
  border-left: 5px solid var(--jaune_ivoire_dark);
}

.border-left-5-dashed-jaune_ivoire_dark {
  border-left: 5px dashed var(--jaune_ivoire_dark);
}

.border-left-5-dotted-jaune_ivoire_dark {
  border-left: 5px dotted var(--jaune_ivoire_dark);
}

.border-left-5-double-jaune_ivoire_dark {
  border-left: 5px double var(--jaune_ivoire_dark);
}

.border-left-5-groove-jaune_ivoire_dark {
  border-left: 5px groove var(--jaune_ivoire_dark);
}

.border-left-5-ridge-jaune_ivoire_dark {
  border-left: 5px ridge var(--jaune_ivoire_dark);
}

.border-left-5-inset-jaune_ivoire_dark {
  border-left: 5px inset var(--jaune_ivoire_dark);
}

.border-left-5-outset-jaune_ivoire_dark {
  border-left: 5px outset var(--jaune_ivoire_dark);
}

.border-left-5-none-jaune_ivoire_dark {
  border-left: 5px none var(--jaune_ivoire_dark);
}

.border-left-5-hidden-jaune_ivoire_dark {
  border-left: 5px hidden var(--jaune_ivoire_dark);
}

.border-left-5-solid-jaune_light {
  border-left: 5px solid var(--jaune_light);
}

.border-left-5-dashed-jaune_light {
  border-left: 5px dashed var(--jaune_light);
}

.border-left-5-dotted-jaune_light {
  border-left: 5px dotted var(--jaune_light);
}

.border-left-5-double-jaune_light {
  border-left: 5px double var(--jaune_light);
}

.border-left-5-groove-jaune_light {
  border-left: 5px groove var(--jaune_light);
}

.border-left-5-ridge-jaune_light {
  border-left: 5px ridge var(--jaune_light);
}

.border-left-5-inset-jaune_light {
  border-left: 5px inset var(--jaune_light);
}

.border-left-5-outset-jaune_light {
  border-left: 5px outset var(--jaune_light);
}

.border-left-5-none-jaune_light {
  border-left: 5px none var(--jaune_light);
}

.border-left-5-hidden-jaune_light {
  border-left: 5px hidden var(--jaune_light);
}

.border-left-5-solid-jaune_dark {
  border-left: 5px solid var(--jaune_dark);
}

.border-left-5-dashed-jaune_dark {
  border-left: 5px dashed var(--jaune_dark);
}

.border-left-5-dotted-jaune_dark {
  border-left: 5px dotted var(--jaune_dark);
}

.border-left-5-double-jaune_dark {
  border-left: 5px double var(--jaune_dark);
}

.border-left-5-groove-jaune_dark {
  border-left: 5px groove var(--jaune_dark);
}

.border-left-5-ridge-jaune_dark {
  border-left: 5px ridge var(--jaune_dark);
}

.border-left-5-inset-jaune_dark {
  border-left: 5px inset var(--jaune_dark);
}

.border-left-5-outset-jaune_dark {
  border-left: 5px outset var(--jaune_dark);
}

.border-left-5-none-jaune_dark {
  border-left: 5px none var(--jaune_dark);
}

.border-left-5-hidden-jaune_dark {
  border-left: 5px hidden var(--jaune_dark);
}

.border-left-5-solid-orange_normal {
  border-left: 5px solid var(--orange_normal);
}

.border-left-5-dashed-orange_normal {
  border-left: 5px dashed var(--orange_normal);
}

.border-left-5-dotted-orange_normal {
  border-left: 5px dotted var(--orange_normal);
}

.border-left-5-double-orange_normal {
  border-left: 5px double var(--orange_normal);
}

.border-left-5-groove-orange_normal {
  border-left: 5px groove var(--orange_normal);
}

.border-left-5-ridge-orange_normal {
  border-left: 5px ridge var(--orange_normal);
}

.border-left-5-inset-orange_normal {
  border-left: 5px inset var(--orange_normal);
}

.border-left-5-outset-orange_normal {
  border-left: 5px outset var(--orange_normal);
}

.border-left-5-none-orange_normal {
  border-left: 5px none var(--orange_normal);
}

.border-left-5-hidden-orange_normal {
  border-left: 5px hidden var(--orange_normal);
}

.border-left-5-solid-orange_dark {
  border-left: 5px solid var(--orange_dark);
}

.border-left-5-dashed-orange_dark {
  border-left: 5px dashed var(--orange_dark);
}

.border-left-5-dotted-orange_dark {
  border-left: 5px dotted var(--orange_dark);
}

.border-left-5-double-orange_dark {
  border-left: 5px double var(--orange_dark);
}

.border-left-5-groove-orange_dark {
  border-left: 5px groove var(--orange_dark);
}

.border-left-5-ridge-orange_dark {
  border-left: 5px ridge var(--orange_dark);
}

.border-left-5-inset-orange_dark {
  border-left: 5px inset var(--orange_dark);
}

.border-left-5-outset-orange_dark {
  border-left: 5px outset var(--orange_dark);
}

.border-left-5-none-orange_dark {
  border-left: 5px none var(--orange_dark);
}

.border-left-5-hidden-orange_dark {
  border-left: 5px hidden var(--orange_dark);
}

.border-left-5-solid-vert_light {
  border-left: 5px solid var(--vert_light);
}

.border-left-5-dashed-vert_light {
  border-left: 5px dashed var(--vert_light);
}

.border-left-5-dotted-vert_light {
  border-left: 5px dotted var(--vert_light);
}

.border-left-5-double-vert_light {
  border-left: 5px double var(--vert_light);
}

.border-left-5-groove-vert_light {
  border-left: 5px groove var(--vert_light);
}

.border-left-5-ridge-vert_light {
  border-left: 5px ridge var(--vert_light);
}

.border-left-5-inset-vert_light {
  border-left: 5px inset var(--vert_light);
}

.border-left-5-outset-vert_light {
  border-left: 5px outset var(--vert_light);
}

.border-left-5-none-vert_light {
  border-left: 5px none var(--vert_light);
}

.border-left-5-hidden-vert_light {
  border-left: 5px hidden var(--vert_light);
}

.border-left-5-solid-vert_dark {
  border-left: 5px solid var(--vert_dark);
}

.border-left-5-dashed-vert_dark {
  border-left: 5px dashed var(--vert_dark);
}

.border-left-5-dotted-vert_dark {
  border-left: 5px dotted var(--vert_dark);
}

.border-left-5-double-vert_dark {
  border-left: 5px double var(--vert_dark);
}

.border-left-5-groove-vert_dark {
  border-left: 5px groove var(--vert_dark);
}

.border-left-5-ridge-vert_dark {
  border-left: 5px ridge var(--vert_dark);
}

.border-left-5-inset-vert_dark {
  border-left: 5px inset var(--vert_dark);
}

.border-left-5-outset-vert_dark {
  border-left: 5px outset var(--vert_dark);
}

.border-left-5-none-vert_dark {
  border-left: 5px none var(--vert_dark);
}

.border-left-5-hidden-vert_dark {
  border-left: 5px hidden var(--vert_dark);
}

.border-left-5-solid-bleu_turquoise_light {
  border-left: 5px solid var(--bleu_turquoise_light);
}

.border-left-5-dashed-bleu_turquoise_light {
  border-left: 5px dashed var(--bleu_turquoise_light);
}

.border-left-5-dotted-bleu_turquoise_light {
  border-left: 5px dotted var(--bleu_turquoise_light);
}

.border-left-5-double-bleu_turquoise_light {
  border-left: 5px double var(--bleu_turquoise_light);
}

.border-left-5-groove-bleu_turquoise_light {
  border-left: 5px groove var(--bleu_turquoise_light);
}

.border-left-5-ridge-bleu_turquoise_light {
  border-left: 5px ridge var(--bleu_turquoise_light);
}

.border-left-5-inset-bleu_turquoise_light {
  border-left: 5px inset var(--bleu_turquoise_light);
}

.border-left-5-outset-bleu_turquoise_light {
  border-left: 5px outset var(--bleu_turquoise_light);
}

.border-left-5-none-bleu_turquoise_light {
  border-left: 5px none var(--bleu_turquoise_light);
}

.border-left-5-hidden-bleu_turquoise_light {
  border-left: 5px hidden var(--bleu_turquoise_light);
}

.border-left-5-solid-bleu_turquoise_middle {
  border-left: 5px solid var(--bleu_turquoise_middle);
}

.border-left-5-dashed-bleu_turquoise_middle {
  border-left: 5px dashed var(--bleu_turquoise_middle);
}

.border-left-5-dotted-bleu_turquoise_middle {
  border-left: 5px dotted var(--bleu_turquoise_middle);
}

.border-left-5-double-bleu_turquoise_middle {
  border-left: 5px double var(--bleu_turquoise_middle);
}

.border-left-5-groove-bleu_turquoise_middle {
  border-left: 5px groove var(--bleu_turquoise_middle);
}

.border-left-5-ridge-bleu_turquoise_middle {
  border-left: 5px ridge var(--bleu_turquoise_middle);
}

.border-left-5-inset-bleu_turquoise_middle {
  border-left: 5px inset var(--bleu_turquoise_middle);
}

.border-left-5-outset-bleu_turquoise_middle {
  border-left: 5px outset var(--bleu_turquoise_middle);
}

.border-left-5-none-bleu_turquoise_middle {
  border-left: 5px none var(--bleu_turquoise_middle);
}

.border-left-5-hidden-bleu_turquoise_middle {
  border-left: 5px hidden var(--bleu_turquoise_middle);
}

.border-left-5-solid-bleu_turquoise_dark {
  border-left: 5px solid var(--bleu_turquoise_dark);
}

.border-left-5-dashed-bleu_turquoise_dark {
  border-left: 5px dashed var(--bleu_turquoise_dark);
}

.border-left-5-dotted-bleu_turquoise_dark {
  border-left: 5px dotted var(--bleu_turquoise_dark);
}

.border-left-5-double-bleu_turquoise_dark {
  border-left: 5px double var(--bleu_turquoise_dark);
}

.border-left-5-groove-bleu_turquoise_dark {
  border-left: 5px groove var(--bleu_turquoise_dark);
}

.border-left-5-ridge-bleu_turquoise_dark {
  border-left: 5px ridge var(--bleu_turquoise_dark);
}

.border-left-5-inset-bleu_turquoise_dark {
  border-left: 5px inset var(--bleu_turquoise_dark);
}

.border-left-5-outset-bleu_turquoise_dark {
  border-left: 5px outset var(--bleu_turquoise_dark);
}

.border-left-5-none-bleu_turquoise_dark {
  border-left: 5px none var(--bleu_turquoise_dark);
}

.border-left-5-hidden-bleu_turquoise_dark {
  border-left: 5px hidden var(--bleu_turquoise_dark);
}

.border-left-5-solid-bleu_light {
  border-left: 5px solid var(--bleu_light);
}

.border-left-5-dashed-bleu_light {
  border-left: 5px dashed var(--bleu_light);
}

.border-left-5-dotted-bleu_light {
  border-left: 5px dotted var(--bleu_light);
}

.border-left-5-double-bleu_light {
  border-left: 5px double var(--bleu_light);
}

.border-left-5-groove-bleu_light {
  border-left: 5px groove var(--bleu_light);
}

.border-left-5-ridge-bleu_light {
  border-left: 5px ridge var(--bleu_light);
}

.border-left-5-inset-bleu_light {
  border-left: 5px inset var(--bleu_light);
}

.border-left-5-outset-bleu_light {
  border-left: 5px outset var(--bleu_light);
}

.border-left-5-none-bleu_light {
  border-left: 5px none var(--bleu_light);
}

.border-left-5-hidden-bleu_light {
  border-left: 5px hidden var(--bleu_light);
}

.border-left-5-solid-bleu_dark {
  border-left: 5px solid var(--bleu_dark);
}

.border-left-5-dashed-bleu_dark {
  border-left: 5px dashed var(--bleu_dark);
}

.border-left-5-dotted-bleu_dark {
  border-left: 5px dotted var(--bleu_dark);
}

.border-left-5-double-bleu_dark {
  border-left: 5px double var(--bleu_dark);
}

.border-left-5-groove-bleu_dark {
  border-left: 5px groove var(--bleu_dark);
}

.border-left-5-ridge-bleu_dark {
  border-left: 5px ridge var(--bleu_dark);
}

.border-left-5-inset-bleu_dark {
  border-left: 5px inset var(--bleu_dark);
}

.border-left-5-outset-bleu_dark {
  border-left: 5px outset var(--bleu_dark);
}

.border-left-5-none-bleu_dark {
  border-left: 5px none var(--bleu_dark);
}

.border-left-5-hidden-bleu_dark {
  border-left: 5px hidden var(--bleu_dark);
}

.border-left-5-solid-bleu_klein_light {
  border-left: 5px solid var(--bleu_klein_light);
}

.border-left-5-dashed-bleu_klein_light {
  border-left: 5px dashed var(--bleu_klein_light);
}

.border-left-5-dotted-bleu_klein_light {
  border-left: 5px dotted var(--bleu_klein_light);
}

.border-left-5-double-bleu_klein_light {
  border-left: 5px double var(--bleu_klein_light);
}

.border-left-5-groove-bleu_klein_light {
  border-left: 5px groove var(--bleu_klein_light);
}

.border-left-5-ridge-bleu_klein_light {
  border-left: 5px ridge var(--bleu_klein_light);
}

.border-left-5-inset-bleu_klein_light {
  border-left: 5px inset var(--bleu_klein_light);
}

.border-left-5-outset-bleu_klein_light {
  border-left: 5px outset var(--bleu_klein_light);
}

.border-left-5-none-bleu_klein_light {
  border-left: 5px none var(--bleu_klein_light);
}

.border-left-5-hidden-bleu_klein_light {
  border-left: 5px hidden var(--bleu_klein_light);
}

.border-left-5-solid-bleu_klein_dark {
  border-left: 5px solid var(--bleu_klein_dark);
}

.border-left-5-dashed-bleu_klein_dark {
  border-left: 5px dashed var(--bleu_klein_dark);
}

.border-left-5-dotted-bleu_klein_dark {
  border-left: 5px dotted var(--bleu_klein_dark);
}

.border-left-5-double-bleu_klein_dark {
  border-left: 5px double var(--bleu_klein_dark);
}

.border-left-5-groove-bleu_klein_dark {
  border-left: 5px groove var(--bleu_klein_dark);
}

.border-left-5-ridge-bleu_klein_dark {
  border-left: 5px ridge var(--bleu_klein_dark);
}

.border-left-5-inset-bleu_klein_dark {
  border-left: 5px inset var(--bleu_klein_dark);
}

.border-left-5-outset-bleu_klein_dark {
  border-left: 5px outset var(--bleu_klein_dark);
}

.border-left-5-none-bleu_klein_dark {
  border-left: 5px none var(--bleu_klein_dark);
}

.border-left-5-hidden-bleu_klein_dark {
  border-left: 5px hidden var(--bleu_klein_dark);
}

.border-left-5-solid-violet_light {
  border-left: 5px solid var(--violet_light);
}

.border-left-5-dashed-violet_light {
  border-left: 5px dashed var(--violet_light);
}

.border-left-5-dotted-violet_light {
  border-left: 5px dotted var(--violet_light);
}

.border-left-5-double-violet_light {
  border-left: 5px double var(--violet_light);
}

.border-left-5-groove-violet_light {
  border-left: 5px groove var(--violet_light);
}

.border-left-5-ridge-violet_light {
  border-left: 5px ridge var(--violet_light);
}

.border-left-5-inset-violet_light {
  border-left: 5px inset var(--violet_light);
}

.border-left-5-outset-violet_light {
  border-left: 5px outset var(--violet_light);
}

.border-left-5-none-violet_light {
  border-left: 5px none var(--violet_light);
}

.border-left-5-hidden-violet_light {
  border-left: 5px hidden var(--violet_light);
}

.border-left-5-solid-violet_dark {
  border-left: 5px solid var(--violet_dark);
}

.border-left-5-dashed-violet_dark {
  border-left: 5px dashed var(--violet_dark);
}

.border-left-5-dotted-violet_dark {
  border-left: 5px dotted var(--violet_dark);
}

.border-left-5-double-violet_dark {
  border-left: 5px double var(--violet_dark);
}

.border-left-5-groove-violet_dark {
  border-left: 5px groove var(--violet_dark);
}

.border-left-5-ridge-violet_dark {
  border-left: 5px ridge var(--violet_dark);
}

.border-left-5-inset-violet_dark {
  border-left: 5px inset var(--violet_dark);
}

.border-left-5-outset-violet_dark {
  border-left: 5px outset var(--violet_dark);
}

.border-left-5-none-violet_dark {
  border-left: 5px none var(--violet_dark);
}

.border-left-5-hidden-violet_dark {
  border-left: 5px hidden var(--violet_dark);
}

.border-left-5-solid-mauve {
  border-left: 5px solid var(--mauve);
}

.border-left-5-dashed-mauve {
  border-left: 5px dashed var(--mauve);
}

.border-left-5-dotted-mauve {
  border-left: 5px dotted var(--mauve);
}

.border-left-5-double-mauve {
  border-left: 5px double var(--mauve);
}

.border-left-5-groove-mauve {
  border-left: 5px groove var(--mauve);
}

.border-left-5-ridge-mauve {
  border-left: 5px ridge var(--mauve);
}

.border-left-5-inset-mauve {
  border-left: 5px inset var(--mauve);
}

.border-left-5-outset-mauve {
  border-left: 5px outset var(--mauve);
}

.border-left-5-none-mauve {
  border-left: 5px none var(--mauve);
}

.border-left-5-hidden-mauve {
  border-left: 5px hidden var(--mauve);
}

.border-left-5-solid-rose_light {
  border-left: 5px solid var(--rose_light);
}

.border-left-5-dashed-rose_light {
  border-left: 5px dashed var(--rose_light);
}

.border-left-5-dotted-rose_light {
  border-left: 5px dotted var(--rose_light);
}

.border-left-5-double-rose_light {
  border-left: 5px double var(--rose_light);
}

.border-left-5-groove-rose_light {
  border-left: 5px groove var(--rose_light);
}

.border-left-5-ridge-rose_light {
  border-left: 5px ridge var(--rose_light);
}

.border-left-5-inset-rose_light {
  border-left: 5px inset var(--rose_light);
}

.border-left-5-outset-rose_light {
  border-left: 5px outset var(--rose_light);
}

.border-left-5-none-rose_light {
  border-left: 5px none var(--rose_light);
}

.border-left-5-hidden-rose_light {
  border-left: 5px hidden var(--rose_light);
}

.border-left-5-solid-rose_dark {
  border-left: 5px solid var(--rose_dark);
}

.border-left-5-dashed-rose_dark {
  border-left: 5px dashed var(--rose_dark);
}

.border-left-5-dotted-rose_dark {
  border-left: 5px dotted var(--rose_dark);
}

.border-left-5-double-rose_dark {
  border-left: 5px double var(--rose_dark);
}

.border-left-5-groove-rose_dark {
  border-left: 5px groove var(--rose_dark);
}

.border-left-5-ridge-rose_dark {
  border-left: 5px ridge var(--rose_dark);
}

.border-left-5-inset-rose_dark {
  border-left: 5px inset var(--rose_dark);
}

.border-left-5-outset-rose_dark {
  border-left: 5px outset var(--rose_dark);
}

.border-left-5-none-rose_dark {
  border-left: 5px none var(--rose_dark);
}

.border-left-5-hidden-rose_dark {
  border-left: 5px hidden var(--rose_dark);
}

.border-left-5-solid-rose_fushia {
  border-left: 5px solid var(--rose_fushia);
}

.border-left-5-dashed-rose_fushia {
  border-left: 5px dashed var(--rose_fushia);
}

.border-left-5-dotted-rose_fushia {
  border-left: 5px dotted var(--rose_fushia);
}

.border-left-5-double-rose_fushia {
  border-left: 5px double var(--rose_fushia);
}

.border-left-5-groove-rose_fushia {
  border-left: 5px groove var(--rose_fushia);
}

.border-left-5-ridge-rose_fushia {
  border-left: 5px ridge var(--rose_fushia);
}

.border-left-5-inset-rose_fushia {
  border-left: 5px inset var(--rose_fushia);
}

.border-left-5-outset-rose_fushia {
  border-left: 5px outset var(--rose_fushia);
}

.border-left-5-none-rose_fushia {
  border-left: 5px none var(--rose_fushia);
}

.border-left-5-hidden-rose_fushia {
  border-left: 5px hidden var(--rose_fushia);
}

.border-left-5-solid-rouge_framboise {
  border-left: 5px solid var(--rouge_framboise);
}

.border-left-5-dashed-rouge_framboise {
  border-left: 5px dashed var(--rouge_framboise);
}

.border-left-5-dotted-rouge_framboise {
  border-left: 5px dotted var(--rouge_framboise);
}

.border-left-5-double-rouge_framboise {
  border-left: 5px double var(--rouge_framboise);
}

.border-left-5-groove-rouge_framboise {
  border-left: 5px groove var(--rouge_framboise);
}

.border-left-5-ridge-rouge_framboise {
  border-left: 5px ridge var(--rouge_framboise);
}

.border-left-5-inset-rouge_framboise {
  border-left: 5px inset var(--rouge_framboise);
}

.border-left-5-outset-rouge_framboise {
  border-left: 5px outset var(--rouge_framboise);
}

.border-left-5-none-rouge_framboise {
  border-left: 5px none var(--rouge_framboise);
}

.border-left-5-hidden-rouge_framboise {
  border-left: 5px hidden var(--rouge_framboise);
}

.border-left-5-solid-rose_chair_light {
  border-left: 5px solid var(--rose_chair_light);
}

.border-left-5-dashed-rose_chair_light {
  border-left: 5px dashed var(--rose_chair_light);
}

.border-left-5-dotted-rose_chair_light {
  border-left: 5px dotted var(--rose_chair_light);
}

.border-left-5-double-rose_chair_light {
  border-left: 5px double var(--rose_chair_light);
}

.border-left-5-groove-rose_chair_light {
  border-left: 5px groove var(--rose_chair_light);
}

.border-left-5-ridge-rose_chair_light {
  border-left: 5px ridge var(--rose_chair_light);
}

.border-left-5-inset-rose_chair_light {
  border-left: 5px inset var(--rose_chair_light);
}

.border-left-5-outset-rose_chair_light {
  border-left: 5px outset var(--rose_chair_light);
}

.border-left-5-none-rose_chair_light {
  border-left: 5px none var(--rose_chair_light);
}

.border-left-5-hidden-rose_chair_light {
  border-left: 5px hidden var(--rose_chair_light);
}

.border-left-5-solid-rose_chair_dark {
  border-left: 5px solid var(--rose_chair_dark);
}

.border-left-5-dashed-rose_chair_dark {
  border-left: 5px dashed var(--rose_chair_dark);
}

.border-left-5-dotted-rose_chair_dark {
  border-left: 5px dotted var(--rose_chair_dark);
}

.border-left-5-double-rose_chair_dark {
  border-left: 5px double var(--rose_chair_dark);
}

.border-left-5-groove-rose_chair_dark {
  border-left: 5px groove var(--rose_chair_dark);
}

.border-left-5-ridge-rose_chair_dark {
  border-left: 5px ridge var(--rose_chair_dark);
}

.border-left-5-inset-rose_chair_dark {
  border-left: 5px inset var(--rose_chair_dark);
}

.border-left-5-outset-rose_chair_dark {
  border-left: 5px outset var(--rose_chair_dark);
}

.border-left-5-none-rose_chair_dark {
  border-left: 5px none var(--rose_chair_dark);
}

.border-left-5-hidden-rose_chair_dark {
  border-left: 5px hidden var(--rose_chair_dark);
}

.border-left-5-solid-vert_positif {
  border-left: 5px solid var(--vert_positif);
}

.border-left-5-dashed-vert_positif {
  border-left: 5px dashed var(--vert_positif);
}

.border-left-5-dotted-vert_positif {
  border-left: 5px dotted var(--vert_positif);
}

.border-left-5-double-vert_positif {
  border-left: 5px double var(--vert_positif);
}

.border-left-5-groove-vert_positif {
  border-left: 5px groove var(--vert_positif);
}

.border-left-5-ridge-vert_positif {
  border-left: 5px ridge var(--vert_positif);
}

.border-left-5-inset-vert_positif {
  border-left: 5px inset var(--vert_positif);
}

.border-left-5-outset-vert_positif {
  border-left: 5px outset var(--vert_positif);
}

.border-left-5-none-vert_positif {
  border-left: 5px none var(--vert_positif);
}

.border-left-5-hidden-vert_positif {
  border-left: 5px hidden var(--vert_positif);
}

.border-left-5-solid-rouge_negatif {
  border-left: 5px solid var(--rouge_negatif);
}

.border-left-5-dashed-rouge_negatif {
  border-left: 5px dashed var(--rouge_negatif);
}

.border-left-5-dotted-rouge_negatif {
  border-left: 5px dotted var(--rouge_negatif);
}

.border-left-5-double-rouge_negatif {
  border-left: 5px double var(--rouge_negatif);
}

.border-left-5-groove-rouge_negatif {
  border-left: 5px groove var(--rouge_negatif);
}

.border-left-5-ridge-rouge_negatif {
  border-left: 5px ridge var(--rouge_negatif);
}

.border-left-5-inset-rouge_negatif {
  border-left: 5px inset var(--rouge_negatif);
}

.border-left-5-outset-rouge_negatif {
  border-left: 5px outset var(--rouge_negatif);
}

.border-left-5-none-rouge_negatif {
  border-left: 5px none var(--rouge_negatif);
}

.border-left-5-hidden-rouge_negatif {
  border-left: 5px hidden var(--rouge_negatif);
}

.border-left-5-solid-bleu_texte {
  border-left: 5px solid var(--bleu_texte);
}

.border-left-5-dashed-bleu_texte {
  border-left: 5px dashed var(--bleu_texte);
}

.border-left-5-dotted-bleu_texte {
  border-left: 5px dotted var(--bleu_texte);
}

.border-left-5-double-bleu_texte {
  border-left: 5px double var(--bleu_texte);
}

.border-left-5-groove-bleu_texte {
  border-left: 5px groove var(--bleu_texte);
}

.border-left-5-ridge-bleu_texte {
  border-left: 5px ridge var(--bleu_texte);
}

.border-left-5-inset-bleu_texte {
  border-left: 5px inset var(--bleu_texte);
}

.border-left-5-outset-bleu_texte {
  border-left: 5px outset var(--bleu_texte);
}

.border-left-5-none-bleu_texte {
  border-left: 5px none var(--bleu_texte);
}

.border-left-5-hidden-bleu_texte {
  border-left: 5px hidden var(--bleu_texte);
}

.border-left-5-solid-alerte_vert_positif {
  border-left: 5px solid var(--alerte_vert_positif);
}

.border-left-5-dashed-alerte_vert_positif {
  border-left: 5px dashed var(--alerte_vert_positif);
}

.border-left-5-dotted-alerte_vert_positif {
  border-left: 5px dotted var(--alerte_vert_positif);
}

.border-left-5-double-alerte_vert_positif {
  border-left: 5px double var(--alerte_vert_positif);
}

.border-left-5-groove-alerte_vert_positif {
  border-left: 5px groove var(--alerte_vert_positif);
}

.border-left-5-ridge-alerte_vert_positif {
  border-left: 5px ridge var(--alerte_vert_positif);
}

.border-left-5-inset-alerte_vert_positif {
  border-left: 5px inset var(--alerte_vert_positif);
}

.border-left-5-outset-alerte_vert_positif {
  border-left: 5px outset var(--alerte_vert_positif);
}

.border-left-5-none-alerte_vert_positif {
  border-left: 5px none var(--alerte_vert_positif);
}

.border-left-5-hidden-alerte_vert_positif {
  border-left: 5px hidden var(--alerte_vert_positif);
}

.border-left-5-solid-alerte_rouge_negatif {
  border-left: 5px solid var(--alerte_rouge_negatif);
}

.border-left-5-dashed-alerte_rouge_negatif {
  border-left: 5px dashed var(--alerte_rouge_negatif);
}

.border-left-5-dotted-alerte_rouge_negatif {
  border-left: 5px dotted var(--alerte_rouge_negatif);
}

.border-left-5-double-alerte_rouge_negatif {
  border-left: 5px double var(--alerte_rouge_negatif);
}

.border-left-5-groove-alerte_rouge_negatif {
  border-left: 5px groove var(--alerte_rouge_negatif);
}

.border-left-5-ridge-alerte_rouge_negatif {
  border-left: 5px ridge var(--alerte_rouge_negatif);
}

.border-left-5-inset-alerte_rouge_negatif {
  border-left: 5px inset var(--alerte_rouge_negatif);
}

.border-left-5-outset-alerte_rouge_negatif {
  border-left: 5px outset var(--alerte_rouge_negatif);
}

.border-left-5-none-alerte_rouge_negatif {
  border-left: 5px none var(--alerte_rouge_negatif);
}

.border-left-5-hidden-alerte_rouge_negatif {
  border-left: 5px hidden var(--alerte_rouge_negatif);
}

.border-left-5-solid-alerte_bleu_information {
  border-left: 5px solid var(--alerte_bleu_information);
}

.border-left-5-dashed-alerte_bleu_information {
  border-left: 5px dashed var(--alerte_bleu_information);
}

.border-left-5-dotted-alerte_bleu_information {
  border-left: 5px dotted var(--alerte_bleu_information);
}

.border-left-5-double-alerte_bleu_information {
  border-left: 5px double var(--alerte_bleu_information);
}

.border-left-5-groove-alerte_bleu_information {
  border-left: 5px groove var(--alerte_bleu_information);
}

.border-left-5-ridge-alerte_bleu_information {
  border-left: 5px ridge var(--alerte_bleu_information);
}

.border-left-5-inset-alerte_bleu_information {
  border-left: 5px inset var(--alerte_bleu_information);
}

.border-left-5-outset-alerte_bleu_information {
  border-left: 5px outset var(--alerte_bleu_information);
}

.border-left-5-none-alerte_bleu_information {
  border-left: 5px none var(--alerte_bleu_information);
}

.border-left-5-hidden-alerte_bleu_information {
  border-left: 5px hidden var(--alerte_bleu_information);
}

.border-left-5-solid-alerte_jaune_attention {
  border-left: 5px solid var(--alerte_jaune_attention);
}

.border-left-5-dashed-alerte_jaune_attention {
  border-left: 5px dashed var(--alerte_jaune_attention);
}

.border-left-5-dotted-alerte_jaune_attention {
  border-left: 5px dotted var(--alerte_jaune_attention);
}

.border-left-5-double-alerte_jaune_attention {
  border-left: 5px double var(--alerte_jaune_attention);
}

.border-left-5-groove-alerte_jaune_attention {
  border-left: 5px groove var(--alerte_jaune_attention);
}

.border-left-5-ridge-alerte_jaune_attention {
  border-left: 5px ridge var(--alerte_jaune_attention);
}

.border-left-5-inset-alerte_jaune_attention {
  border-left: 5px inset var(--alerte_jaune_attention);
}

.border-left-5-outset-alerte_jaune_attention {
  border-left: 5px outset var(--alerte_jaune_attention);
}

.border-left-5-none-alerte_jaune_attention {
  border-left: 5px none var(--alerte_jaune_attention);
}

.border-left-5-hidden-alerte_jaune_attention {
  border-left: 5px hidden var(--alerte_jaune_attention);
}

.border-left-5-solid-blanc {
  border-left: 5px solid #fff;
}

.border-left-5-dashed-blanc {
  border-left: 5px dashed #fff;
}

.border-left-5-dotted-blanc {
  border-left: 5px dotted #fff;
}

.border-left-5-double-blanc {
  border-left: 5px double #fff;
}

.border-left-5-groove-blanc {
  border-left: 5px groove #fff;
}

.border-left-5-ridge-blanc {
  border-left: 5px ridge #fff;
}

.border-left-5-inset-blanc {
  border-left: 5px inset #fff;
}

.border-left-5-outset-blanc {
  border-left: 5px outset #fff;
}

.border-left-5-none-blanc {
  border-left: 5px #fff;
}

.border-left-5-hidden-blanc {
  border-left: 5px hidden #fff;
}

.border-left-5-solid-noir {
  border-left: 5px solid #222;
}

.border-left-5-dashed-noir {
  border-left: 5px dashed #222;
}

.border-left-5-dotted-noir {
  border-left: 5px dotted #222;
}

.border-left-5-double-noir {
  border-left: 5px double #222;
}

.border-left-5-groove-noir {
  border-left: 5px groove #222;
}

.border-left-5-ridge-noir {
  border-left: 5px ridge #222;
}

.border-left-5-inset-noir {
  border-left: 5px inset #222;
}

.border-left-5-outset-noir {
  border-left: 5px outset #222;
}

.border-left-5-none-noir {
  border-left: 5px #222;
}

.border-left-5-hidden-noir {
  border-left: 5px hidden #222;
}

.border-left-5-solid-gris_light {
  border-left: 5px solid var(--gris_light);
}

.border-left-5-dashed-gris_light {
  border-left: 5px dashed var(--gris_light);
}

.border-left-5-dotted-gris_light {
  border-left: 5px dotted var(--gris_light);
}

.border-left-5-double-gris_light {
  border-left: 5px double var(--gris_light);
}

.border-left-5-groove-gris_light {
  border-left: 5px groove var(--gris_light);
}

.border-left-5-ridge-gris_light {
  border-left: 5px ridge var(--gris_light);
}

.border-left-5-inset-gris_light {
  border-left: 5px inset var(--gris_light);
}

.border-left-5-outset-gris_light {
  border-left: 5px outset var(--gris_light);
}

.border-left-5-none-gris_light {
  border-left: 5px none var(--gris_light);
}

.border-left-5-hidden-gris_light {
  border-left: 5px hidden var(--gris_light);
}

.border-left-5-solid-gris_dark {
  border-left: 5px solid var(--gris_dark);
}

.border-left-5-dashed-gris_dark {
  border-left: 5px dashed var(--gris_dark);
}

.border-left-5-dotted-gris_dark {
  border-left: 5px dotted var(--gris_dark);
}

.border-left-5-double-gris_dark {
  border-left: 5px double var(--gris_dark);
}

.border-left-5-groove-gris_dark {
  border-left: 5px groove var(--gris_dark);
}

.border-left-5-ridge-gris_dark {
  border-left: 5px ridge var(--gris_dark);
}

.border-left-5-inset-gris_dark {
  border-left: 5px inset var(--gris_dark);
}

.border-left-5-outset-gris_dark {
  border-left: 5px outset var(--gris_dark);
}

.border-left-5-none-gris_dark {
  border-left: 5px none var(--gris_dark);
}

.border-left-5-hidden-gris_dark {
  border-left: 5px hidden var(--gris_dark);
}

.border-left-5-solid-gris_background {
  border-left: 5px solid var(--gris_background);
}

.border-left-5-dashed-gris_background {
  border-left: 5px dashed var(--gris_background);
}

.border-left-5-dotted-gris_background {
  border-left: 5px dotted var(--gris_background);
}

.border-left-5-double-gris_background {
  border-left: 5px double var(--gris_background);
}

.border-left-5-groove-gris_background {
  border-left: 5px groove var(--gris_background);
}

.border-left-5-ridge-gris_background {
  border-left: 5px ridge var(--gris_background);
}

.border-left-5-inset-gris_background {
  border-left: 5px inset var(--gris_background);
}

.border-left-5-outset-gris_background {
  border-left: 5px outset var(--gris_background);
}

.border-left-5-none-gris_background {
  border-left: 5px none var(--gris_background);
}

.border-left-5-hidden-gris_background {
  border-left: 5px hidden var(--gris_background);
}

.border-all-5-solid-corail_mh_brand {
  border: 5px solid var(--corail_mh_brand);
}

.border-all-5-dashed-corail_mh_brand {
  border: 5px dashed var(--corail_mh_brand);
}

.border-all-5-dotted-corail_mh_brand {
  border: 5px dotted var(--corail_mh_brand);
}

.border-all-5-double-corail_mh_brand {
  border: 5px double var(--corail_mh_brand);
}

.border-all-5-groove-corail_mh_brand {
  border: 5px groove var(--corail_mh_brand);
}

.border-all-5-ridge-corail_mh_brand {
  border: 5px ridge var(--corail_mh_brand);
}

.border-all-5-inset-corail_mh_brand {
  border: 5px inset var(--corail_mh_brand);
}

.border-all-5-outset-corail_mh_brand {
  border: 5px outset var(--corail_mh_brand);
}

.border-all-5-none-corail_mh_brand {
  border: 5px none var(--corail_mh_brand);
}

.border-all-5-hidden-corail_mh_brand {
  border: 5px hidden var(--corail_mh_brand);
}

.border-all-5-solid-corail_mh_dark {
  border: 5px solid var(--corail_mh_dark);
}

.border-all-5-dashed-corail_mh_dark {
  border: 5px dashed var(--corail_mh_dark);
}

.border-all-5-dotted-corail_mh_dark {
  border: 5px dotted var(--corail_mh_dark);
}

.border-all-5-double-corail_mh_dark {
  border: 5px double var(--corail_mh_dark);
}

.border-all-5-groove-corail_mh_dark {
  border: 5px groove var(--corail_mh_dark);
}

.border-all-5-ridge-corail_mh_dark {
  border: 5px ridge var(--corail_mh_dark);
}

.border-all-5-inset-corail_mh_dark {
  border: 5px inset var(--corail_mh_dark);
}

.border-all-5-outset-corail_mh_dark {
  border: 5px outset var(--corail_mh_dark);
}

.border-all-5-none-corail_mh_dark {
  border: 5px none var(--corail_mh_dark);
}

.border-all-5-hidden-corail_mh_dark {
  border: 5px hidden var(--corail_mh_dark);
}

.border-all-5-solid-corail_mh_light {
  border: 5px solid var(--corail_mh_light);
}

.border-all-5-dashed-corail_mh_light {
  border: 5px dashed var(--corail_mh_light);
}

.border-all-5-dotted-corail_mh_light {
  border: 5px dotted var(--corail_mh_light);
}

.border-all-5-double-corail_mh_light {
  border: 5px double var(--corail_mh_light);
}

.border-all-5-groove-corail_mh_light {
  border: 5px groove var(--corail_mh_light);
}

.border-all-5-ridge-corail_mh_light {
  border: 5px ridge var(--corail_mh_light);
}

.border-all-5-inset-corail_mh_light {
  border: 5px inset var(--corail_mh_light);
}

.border-all-5-outset-corail_mh_light {
  border: 5px outset var(--corail_mh_light);
}

.border-all-5-none-corail_mh_light {
  border: 5px none var(--corail_mh_light);
}

.border-all-5-hidden-corail_mh_light {
  border: 5px hidden var(--corail_mh_light);
}

.border-all-5-solid-gris_sable_light {
  border: 5px solid var(--gris_sable_light);
}

.border-all-5-dashed-gris_sable_light {
  border: 5px dashed var(--gris_sable_light);
}

.border-all-5-dotted-gris_sable_light {
  border: 5px dotted var(--gris_sable_light);
}

.border-all-5-double-gris_sable_light {
  border: 5px double var(--gris_sable_light);
}

.border-all-5-groove-gris_sable_light {
  border: 5px groove var(--gris_sable_light);
}

.border-all-5-ridge-gris_sable_light {
  border: 5px ridge var(--gris_sable_light);
}

.border-all-5-inset-gris_sable_light {
  border: 5px inset var(--gris_sable_light);
}

.border-all-5-outset-gris_sable_light {
  border: 5px outset var(--gris_sable_light);
}

.border-all-5-none-gris_sable_light {
  border: 5px none var(--gris_sable_light);
}

.border-all-5-hidden-gris_sable_light {
  border: 5px hidden var(--gris_sable_light);
}

.border-all-5-solid-gris_sable_dark {
  border: 5px solid var(--gris_sable_dark);
}

.border-all-5-dashed-gris_sable_dark {
  border: 5px dashed var(--gris_sable_dark);
}

.border-all-5-dotted-gris_sable_dark {
  border: 5px dotted var(--gris_sable_dark);
}

.border-all-5-double-gris_sable_dark {
  border: 5px double var(--gris_sable_dark);
}

.border-all-5-groove-gris_sable_dark {
  border: 5px groove var(--gris_sable_dark);
}

.border-all-5-ridge-gris_sable_dark {
  border: 5px ridge var(--gris_sable_dark);
}

.border-all-5-inset-gris_sable_dark {
  border: 5px inset var(--gris_sable_dark);
}

.border-all-5-outset-gris_sable_dark {
  border: 5px outset var(--gris_sable_dark);
}

.border-all-5-none-gris_sable_dark {
  border: 5px none var(--gris_sable_dark);
}

.border-all-5-hidden-gris_sable_dark {
  border: 5px hidden var(--gris_sable_dark);
}

.border-all-5-solid-jaune_ivoire_light {
  border: 5px solid var(--jaune_ivoire_light);
}

.border-all-5-dashed-jaune_ivoire_light {
  border: 5px dashed var(--jaune_ivoire_light);
}

.border-all-5-dotted-jaune_ivoire_light {
  border: 5px dotted var(--jaune_ivoire_light);
}

.border-all-5-double-jaune_ivoire_light {
  border: 5px double var(--jaune_ivoire_light);
}

.border-all-5-groove-jaune_ivoire_light {
  border: 5px groove var(--jaune_ivoire_light);
}

.border-all-5-ridge-jaune_ivoire_light {
  border: 5px ridge var(--jaune_ivoire_light);
}

.border-all-5-inset-jaune_ivoire_light {
  border: 5px inset var(--jaune_ivoire_light);
}

.border-all-5-outset-jaune_ivoire_light {
  border: 5px outset var(--jaune_ivoire_light);
}

.border-all-5-none-jaune_ivoire_light {
  border: 5px none var(--jaune_ivoire_light);
}

.border-all-5-hidden-jaune_ivoire_light {
  border: 5px hidden var(--jaune_ivoire_light);
}

.border-all-5-solid-jaune_ivoire_dark {
  border: 5px solid var(--jaune_ivoire_dark);
}

.border-all-5-dashed-jaune_ivoire_dark {
  border: 5px dashed var(--jaune_ivoire_dark);
}

.border-all-5-dotted-jaune_ivoire_dark {
  border: 5px dotted var(--jaune_ivoire_dark);
}

.border-all-5-double-jaune_ivoire_dark {
  border: 5px double var(--jaune_ivoire_dark);
}

.border-all-5-groove-jaune_ivoire_dark {
  border: 5px groove var(--jaune_ivoire_dark);
}

.border-all-5-ridge-jaune_ivoire_dark {
  border: 5px ridge var(--jaune_ivoire_dark);
}

.border-all-5-inset-jaune_ivoire_dark {
  border: 5px inset var(--jaune_ivoire_dark);
}

.border-all-5-outset-jaune_ivoire_dark {
  border: 5px outset var(--jaune_ivoire_dark);
}

.border-all-5-none-jaune_ivoire_dark {
  border: 5px none var(--jaune_ivoire_dark);
}

.border-all-5-hidden-jaune_ivoire_dark {
  border: 5px hidden var(--jaune_ivoire_dark);
}

.border-all-5-solid-jaune_light {
  border: 5px solid var(--jaune_light);
}

.border-all-5-dashed-jaune_light {
  border: 5px dashed var(--jaune_light);
}

.border-all-5-dotted-jaune_light {
  border: 5px dotted var(--jaune_light);
}

.border-all-5-double-jaune_light {
  border: 5px double var(--jaune_light);
}

.border-all-5-groove-jaune_light {
  border: 5px groove var(--jaune_light);
}

.border-all-5-ridge-jaune_light {
  border: 5px ridge var(--jaune_light);
}

.border-all-5-inset-jaune_light {
  border: 5px inset var(--jaune_light);
}

.border-all-5-outset-jaune_light {
  border: 5px outset var(--jaune_light);
}

.border-all-5-none-jaune_light {
  border: 5px none var(--jaune_light);
}

.border-all-5-hidden-jaune_light {
  border: 5px hidden var(--jaune_light);
}

.border-all-5-solid-jaune_dark {
  border: 5px solid var(--jaune_dark);
}

.border-all-5-dashed-jaune_dark {
  border: 5px dashed var(--jaune_dark);
}

.border-all-5-dotted-jaune_dark {
  border: 5px dotted var(--jaune_dark);
}

.border-all-5-double-jaune_dark {
  border: 5px double var(--jaune_dark);
}

.border-all-5-groove-jaune_dark {
  border: 5px groove var(--jaune_dark);
}

.border-all-5-ridge-jaune_dark {
  border: 5px ridge var(--jaune_dark);
}

.border-all-5-inset-jaune_dark {
  border: 5px inset var(--jaune_dark);
}

.border-all-5-outset-jaune_dark {
  border: 5px outset var(--jaune_dark);
}

.border-all-5-none-jaune_dark {
  border: 5px none var(--jaune_dark);
}

.border-all-5-hidden-jaune_dark {
  border: 5px hidden var(--jaune_dark);
}

.border-all-5-solid-orange_normal {
  border: 5px solid var(--orange_normal);
}

.border-all-5-dashed-orange_normal {
  border: 5px dashed var(--orange_normal);
}

.border-all-5-dotted-orange_normal {
  border: 5px dotted var(--orange_normal);
}

.border-all-5-double-orange_normal {
  border: 5px double var(--orange_normal);
}

.border-all-5-groove-orange_normal {
  border: 5px groove var(--orange_normal);
}

.border-all-5-ridge-orange_normal {
  border: 5px ridge var(--orange_normal);
}

.border-all-5-inset-orange_normal {
  border: 5px inset var(--orange_normal);
}

.border-all-5-outset-orange_normal {
  border: 5px outset var(--orange_normal);
}

.border-all-5-none-orange_normal {
  border: 5px none var(--orange_normal);
}

.border-all-5-hidden-orange_normal {
  border: 5px hidden var(--orange_normal);
}

.border-all-5-solid-orange_dark {
  border: 5px solid var(--orange_dark);
}

.border-all-5-dashed-orange_dark {
  border: 5px dashed var(--orange_dark);
}

.border-all-5-dotted-orange_dark {
  border: 5px dotted var(--orange_dark);
}

.border-all-5-double-orange_dark {
  border: 5px double var(--orange_dark);
}

.border-all-5-groove-orange_dark {
  border: 5px groove var(--orange_dark);
}

.border-all-5-ridge-orange_dark {
  border: 5px ridge var(--orange_dark);
}

.border-all-5-inset-orange_dark {
  border: 5px inset var(--orange_dark);
}

.border-all-5-outset-orange_dark {
  border: 5px outset var(--orange_dark);
}

.border-all-5-none-orange_dark {
  border: 5px none var(--orange_dark);
}

.border-all-5-hidden-orange_dark {
  border: 5px hidden var(--orange_dark);
}

.border-all-5-solid-vert_light {
  border: 5px solid var(--vert_light);
}

.border-all-5-dashed-vert_light {
  border: 5px dashed var(--vert_light);
}

.border-all-5-dotted-vert_light {
  border: 5px dotted var(--vert_light);
}

.border-all-5-double-vert_light {
  border: 5px double var(--vert_light);
}

.border-all-5-groove-vert_light {
  border: 5px groove var(--vert_light);
}

.border-all-5-ridge-vert_light {
  border: 5px ridge var(--vert_light);
}

.border-all-5-inset-vert_light {
  border: 5px inset var(--vert_light);
}

.border-all-5-outset-vert_light {
  border: 5px outset var(--vert_light);
}

.border-all-5-none-vert_light {
  border: 5px none var(--vert_light);
}

.border-all-5-hidden-vert_light {
  border: 5px hidden var(--vert_light);
}

.border-all-5-solid-vert_dark {
  border: 5px solid var(--vert_dark);
}

.border-all-5-dashed-vert_dark {
  border: 5px dashed var(--vert_dark);
}

.border-all-5-dotted-vert_dark {
  border: 5px dotted var(--vert_dark);
}

.border-all-5-double-vert_dark {
  border: 5px double var(--vert_dark);
}

.border-all-5-groove-vert_dark {
  border: 5px groove var(--vert_dark);
}

.border-all-5-ridge-vert_dark {
  border: 5px ridge var(--vert_dark);
}

.border-all-5-inset-vert_dark {
  border: 5px inset var(--vert_dark);
}

.border-all-5-outset-vert_dark {
  border: 5px outset var(--vert_dark);
}

.border-all-5-none-vert_dark {
  border: 5px none var(--vert_dark);
}

.border-all-5-hidden-vert_dark {
  border: 5px hidden var(--vert_dark);
}

.border-all-5-solid-bleu_turquoise_light {
  border: 5px solid var(--bleu_turquoise_light);
}

.border-all-5-dashed-bleu_turquoise_light {
  border: 5px dashed var(--bleu_turquoise_light);
}

.border-all-5-dotted-bleu_turquoise_light {
  border: 5px dotted var(--bleu_turquoise_light);
}

.border-all-5-double-bleu_turquoise_light {
  border: 5px double var(--bleu_turquoise_light);
}

.border-all-5-groove-bleu_turquoise_light {
  border: 5px groove var(--bleu_turquoise_light);
}

.border-all-5-ridge-bleu_turquoise_light {
  border: 5px ridge var(--bleu_turquoise_light);
}

.border-all-5-inset-bleu_turquoise_light {
  border: 5px inset var(--bleu_turquoise_light);
}

.border-all-5-outset-bleu_turquoise_light {
  border: 5px outset var(--bleu_turquoise_light);
}

.border-all-5-none-bleu_turquoise_light {
  border: 5px none var(--bleu_turquoise_light);
}

.border-all-5-hidden-bleu_turquoise_light {
  border: 5px hidden var(--bleu_turquoise_light);
}

.border-all-5-solid-bleu_turquoise_middle {
  border: 5px solid var(--bleu_turquoise_middle);
}

.border-all-5-dashed-bleu_turquoise_middle {
  border: 5px dashed var(--bleu_turquoise_middle);
}

.border-all-5-dotted-bleu_turquoise_middle {
  border: 5px dotted var(--bleu_turquoise_middle);
}

.border-all-5-double-bleu_turquoise_middle {
  border: 5px double var(--bleu_turquoise_middle);
}

.border-all-5-groove-bleu_turquoise_middle {
  border: 5px groove var(--bleu_turquoise_middle);
}

.border-all-5-ridge-bleu_turquoise_middle {
  border: 5px ridge var(--bleu_turquoise_middle);
}

.border-all-5-inset-bleu_turquoise_middle {
  border: 5px inset var(--bleu_turquoise_middle);
}

.border-all-5-outset-bleu_turquoise_middle {
  border: 5px outset var(--bleu_turquoise_middle);
}

.border-all-5-none-bleu_turquoise_middle {
  border: 5px none var(--bleu_turquoise_middle);
}

.border-all-5-hidden-bleu_turquoise_middle {
  border: 5px hidden var(--bleu_turquoise_middle);
}

.border-all-5-solid-bleu_turquoise_dark {
  border: 5px solid var(--bleu_turquoise_dark);
}

.border-all-5-dashed-bleu_turquoise_dark {
  border: 5px dashed var(--bleu_turquoise_dark);
}

.border-all-5-dotted-bleu_turquoise_dark {
  border: 5px dotted var(--bleu_turquoise_dark);
}

.border-all-5-double-bleu_turquoise_dark {
  border: 5px double var(--bleu_turquoise_dark);
}

.border-all-5-groove-bleu_turquoise_dark {
  border: 5px groove var(--bleu_turquoise_dark);
}

.border-all-5-ridge-bleu_turquoise_dark {
  border: 5px ridge var(--bleu_turquoise_dark);
}

.border-all-5-inset-bleu_turquoise_dark {
  border: 5px inset var(--bleu_turquoise_dark);
}

.border-all-5-outset-bleu_turquoise_dark {
  border: 5px outset var(--bleu_turquoise_dark);
}

.border-all-5-none-bleu_turquoise_dark {
  border: 5px none var(--bleu_turquoise_dark);
}

.border-all-5-hidden-bleu_turquoise_dark {
  border: 5px hidden var(--bleu_turquoise_dark);
}

.border-all-5-solid-bleu_light {
  border: 5px solid var(--bleu_light);
}

.border-all-5-dashed-bleu_light {
  border: 5px dashed var(--bleu_light);
}

.border-all-5-dotted-bleu_light {
  border: 5px dotted var(--bleu_light);
}

.border-all-5-double-bleu_light {
  border: 5px double var(--bleu_light);
}

.border-all-5-groove-bleu_light {
  border: 5px groove var(--bleu_light);
}

.border-all-5-ridge-bleu_light {
  border: 5px ridge var(--bleu_light);
}

.border-all-5-inset-bleu_light {
  border: 5px inset var(--bleu_light);
}

.border-all-5-outset-bleu_light {
  border: 5px outset var(--bleu_light);
}

.border-all-5-none-bleu_light {
  border: 5px none var(--bleu_light);
}

.border-all-5-hidden-bleu_light {
  border: 5px hidden var(--bleu_light);
}

.border-all-5-solid-bleu_dark {
  border: 5px solid var(--bleu_dark);
}

.border-all-5-dashed-bleu_dark {
  border: 5px dashed var(--bleu_dark);
}

.border-all-5-dotted-bleu_dark {
  border: 5px dotted var(--bleu_dark);
}

.border-all-5-double-bleu_dark {
  border: 5px double var(--bleu_dark);
}

.border-all-5-groove-bleu_dark {
  border: 5px groove var(--bleu_dark);
}

.border-all-5-ridge-bleu_dark {
  border: 5px ridge var(--bleu_dark);
}

.border-all-5-inset-bleu_dark {
  border: 5px inset var(--bleu_dark);
}

.border-all-5-outset-bleu_dark {
  border: 5px outset var(--bleu_dark);
}

.border-all-5-none-bleu_dark {
  border: 5px none var(--bleu_dark);
}

.border-all-5-hidden-bleu_dark {
  border: 5px hidden var(--bleu_dark);
}

.border-all-5-solid-bleu_klein_light {
  border: 5px solid var(--bleu_klein_light);
}

.border-all-5-dashed-bleu_klein_light {
  border: 5px dashed var(--bleu_klein_light);
}

.border-all-5-dotted-bleu_klein_light {
  border: 5px dotted var(--bleu_klein_light);
}

.border-all-5-double-bleu_klein_light {
  border: 5px double var(--bleu_klein_light);
}

.border-all-5-groove-bleu_klein_light {
  border: 5px groove var(--bleu_klein_light);
}

.border-all-5-ridge-bleu_klein_light {
  border: 5px ridge var(--bleu_klein_light);
}

.border-all-5-inset-bleu_klein_light {
  border: 5px inset var(--bleu_klein_light);
}

.border-all-5-outset-bleu_klein_light {
  border: 5px outset var(--bleu_klein_light);
}

.border-all-5-none-bleu_klein_light {
  border: 5px none var(--bleu_klein_light);
}

.border-all-5-hidden-bleu_klein_light {
  border: 5px hidden var(--bleu_klein_light);
}

.border-all-5-solid-bleu_klein_dark {
  border: 5px solid var(--bleu_klein_dark);
}

.border-all-5-dashed-bleu_klein_dark {
  border: 5px dashed var(--bleu_klein_dark);
}

.border-all-5-dotted-bleu_klein_dark {
  border: 5px dotted var(--bleu_klein_dark);
}

.border-all-5-double-bleu_klein_dark {
  border: 5px double var(--bleu_klein_dark);
}

.border-all-5-groove-bleu_klein_dark {
  border: 5px groove var(--bleu_klein_dark);
}

.border-all-5-ridge-bleu_klein_dark {
  border: 5px ridge var(--bleu_klein_dark);
}

.border-all-5-inset-bleu_klein_dark {
  border: 5px inset var(--bleu_klein_dark);
}

.border-all-5-outset-bleu_klein_dark {
  border: 5px outset var(--bleu_klein_dark);
}

.border-all-5-none-bleu_klein_dark {
  border: 5px none var(--bleu_klein_dark);
}

.border-all-5-hidden-bleu_klein_dark {
  border: 5px hidden var(--bleu_klein_dark);
}

.border-all-5-solid-violet_light {
  border: 5px solid var(--violet_light);
}

.border-all-5-dashed-violet_light {
  border: 5px dashed var(--violet_light);
}

.border-all-5-dotted-violet_light {
  border: 5px dotted var(--violet_light);
}

.border-all-5-double-violet_light {
  border: 5px double var(--violet_light);
}

.border-all-5-groove-violet_light {
  border: 5px groove var(--violet_light);
}

.border-all-5-ridge-violet_light {
  border: 5px ridge var(--violet_light);
}

.border-all-5-inset-violet_light {
  border: 5px inset var(--violet_light);
}

.border-all-5-outset-violet_light {
  border: 5px outset var(--violet_light);
}

.border-all-5-none-violet_light {
  border: 5px none var(--violet_light);
}

.border-all-5-hidden-violet_light {
  border: 5px hidden var(--violet_light);
}

.border-all-5-solid-violet_dark {
  border: 5px solid var(--violet_dark);
}

.border-all-5-dashed-violet_dark {
  border: 5px dashed var(--violet_dark);
}

.border-all-5-dotted-violet_dark {
  border: 5px dotted var(--violet_dark);
}

.border-all-5-double-violet_dark {
  border: 5px double var(--violet_dark);
}

.border-all-5-groove-violet_dark {
  border: 5px groove var(--violet_dark);
}

.border-all-5-ridge-violet_dark {
  border: 5px ridge var(--violet_dark);
}

.border-all-5-inset-violet_dark {
  border: 5px inset var(--violet_dark);
}

.border-all-5-outset-violet_dark {
  border: 5px outset var(--violet_dark);
}

.border-all-5-none-violet_dark {
  border: 5px none var(--violet_dark);
}

.border-all-5-hidden-violet_dark {
  border: 5px hidden var(--violet_dark);
}

.border-all-5-solid-mauve {
  border: 5px solid var(--mauve);
}

.border-all-5-dashed-mauve {
  border: 5px dashed var(--mauve);
}

.border-all-5-dotted-mauve {
  border: 5px dotted var(--mauve);
}

.border-all-5-double-mauve {
  border: 5px double var(--mauve);
}

.border-all-5-groove-mauve {
  border: 5px groove var(--mauve);
}

.border-all-5-ridge-mauve {
  border: 5px ridge var(--mauve);
}

.border-all-5-inset-mauve {
  border: 5px inset var(--mauve);
}

.border-all-5-outset-mauve {
  border: 5px outset var(--mauve);
}

.border-all-5-none-mauve {
  border: 5px none var(--mauve);
}

.border-all-5-hidden-mauve {
  border: 5px hidden var(--mauve);
}

.border-all-5-solid-rose_light {
  border: 5px solid var(--rose_light);
}

.border-all-5-dashed-rose_light {
  border: 5px dashed var(--rose_light);
}

.border-all-5-dotted-rose_light {
  border: 5px dotted var(--rose_light);
}

.border-all-5-double-rose_light {
  border: 5px double var(--rose_light);
}

.border-all-5-groove-rose_light {
  border: 5px groove var(--rose_light);
}

.border-all-5-ridge-rose_light {
  border: 5px ridge var(--rose_light);
}

.border-all-5-inset-rose_light {
  border: 5px inset var(--rose_light);
}

.border-all-5-outset-rose_light {
  border: 5px outset var(--rose_light);
}

.border-all-5-none-rose_light {
  border: 5px none var(--rose_light);
}

.border-all-5-hidden-rose_light {
  border: 5px hidden var(--rose_light);
}

.border-all-5-solid-rose_dark {
  border: 5px solid var(--rose_dark);
}

.border-all-5-dashed-rose_dark {
  border: 5px dashed var(--rose_dark);
}

.border-all-5-dotted-rose_dark {
  border: 5px dotted var(--rose_dark);
}

.border-all-5-double-rose_dark {
  border: 5px double var(--rose_dark);
}

.border-all-5-groove-rose_dark {
  border: 5px groove var(--rose_dark);
}

.border-all-5-ridge-rose_dark {
  border: 5px ridge var(--rose_dark);
}

.border-all-5-inset-rose_dark {
  border: 5px inset var(--rose_dark);
}

.border-all-5-outset-rose_dark {
  border: 5px outset var(--rose_dark);
}

.border-all-5-none-rose_dark {
  border: 5px none var(--rose_dark);
}

.border-all-5-hidden-rose_dark {
  border: 5px hidden var(--rose_dark);
}

.border-all-5-solid-rose_fushia {
  border: 5px solid var(--rose_fushia);
}

.border-all-5-dashed-rose_fushia {
  border: 5px dashed var(--rose_fushia);
}

.border-all-5-dotted-rose_fushia {
  border: 5px dotted var(--rose_fushia);
}

.border-all-5-double-rose_fushia {
  border: 5px double var(--rose_fushia);
}

.border-all-5-groove-rose_fushia {
  border: 5px groove var(--rose_fushia);
}

.border-all-5-ridge-rose_fushia {
  border: 5px ridge var(--rose_fushia);
}

.border-all-5-inset-rose_fushia {
  border: 5px inset var(--rose_fushia);
}

.border-all-5-outset-rose_fushia {
  border: 5px outset var(--rose_fushia);
}

.border-all-5-none-rose_fushia {
  border: 5px none var(--rose_fushia);
}

.border-all-5-hidden-rose_fushia {
  border: 5px hidden var(--rose_fushia);
}

.border-all-5-solid-rouge_framboise {
  border: 5px solid var(--rouge_framboise);
}

.border-all-5-dashed-rouge_framboise {
  border: 5px dashed var(--rouge_framboise);
}

.border-all-5-dotted-rouge_framboise {
  border: 5px dotted var(--rouge_framboise);
}

.border-all-5-double-rouge_framboise {
  border: 5px double var(--rouge_framboise);
}

.border-all-5-groove-rouge_framboise {
  border: 5px groove var(--rouge_framboise);
}

.border-all-5-ridge-rouge_framboise {
  border: 5px ridge var(--rouge_framboise);
}

.border-all-5-inset-rouge_framboise {
  border: 5px inset var(--rouge_framboise);
}

.border-all-5-outset-rouge_framboise {
  border: 5px outset var(--rouge_framboise);
}

.border-all-5-none-rouge_framboise {
  border: 5px none var(--rouge_framboise);
}

.border-all-5-hidden-rouge_framboise {
  border: 5px hidden var(--rouge_framboise);
}

.border-all-5-solid-rose_chair_light {
  border: 5px solid var(--rose_chair_light);
}

.border-all-5-dashed-rose_chair_light {
  border: 5px dashed var(--rose_chair_light);
}

.border-all-5-dotted-rose_chair_light {
  border: 5px dotted var(--rose_chair_light);
}

.border-all-5-double-rose_chair_light {
  border: 5px double var(--rose_chair_light);
}

.border-all-5-groove-rose_chair_light {
  border: 5px groove var(--rose_chair_light);
}

.border-all-5-ridge-rose_chair_light {
  border: 5px ridge var(--rose_chair_light);
}

.border-all-5-inset-rose_chair_light {
  border: 5px inset var(--rose_chair_light);
}

.border-all-5-outset-rose_chair_light {
  border: 5px outset var(--rose_chair_light);
}

.border-all-5-none-rose_chair_light {
  border: 5px none var(--rose_chair_light);
}

.border-all-5-hidden-rose_chair_light {
  border: 5px hidden var(--rose_chair_light);
}

.border-all-5-solid-rose_chair_dark {
  border: 5px solid var(--rose_chair_dark);
}

.border-all-5-dashed-rose_chair_dark {
  border: 5px dashed var(--rose_chair_dark);
}

.border-all-5-dotted-rose_chair_dark {
  border: 5px dotted var(--rose_chair_dark);
}

.border-all-5-double-rose_chair_dark {
  border: 5px double var(--rose_chair_dark);
}

.border-all-5-groove-rose_chair_dark {
  border: 5px groove var(--rose_chair_dark);
}

.border-all-5-ridge-rose_chair_dark {
  border: 5px ridge var(--rose_chair_dark);
}

.border-all-5-inset-rose_chair_dark {
  border: 5px inset var(--rose_chair_dark);
}

.border-all-5-outset-rose_chair_dark {
  border: 5px outset var(--rose_chair_dark);
}

.border-all-5-none-rose_chair_dark {
  border: 5px none var(--rose_chair_dark);
}

.border-all-5-hidden-rose_chair_dark {
  border: 5px hidden var(--rose_chair_dark);
}

.border-all-5-solid-vert_positif {
  border: 5px solid var(--vert_positif);
}

.border-all-5-dashed-vert_positif {
  border: 5px dashed var(--vert_positif);
}

.border-all-5-dotted-vert_positif {
  border: 5px dotted var(--vert_positif);
}

.border-all-5-double-vert_positif {
  border: 5px double var(--vert_positif);
}

.border-all-5-groove-vert_positif {
  border: 5px groove var(--vert_positif);
}

.border-all-5-ridge-vert_positif {
  border: 5px ridge var(--vert_positif);
}

.border-all-5-inset-vert_positif {
  border: 5px inset var(--vert_positif);
}

.border-all-5-outset-vert_positif {
  border: 5px outset var(--vert_positif);
}

.border-all-5-none-vert_positif {
  border: 5px none var(--vert_positif);
}

.border-all-5-hidden-vert_positif {
  border: 5px hidden var(--vert_positif);
}

.border-all-5-solid-rouge_negatif {
  border: 5px solid var(--rouge_negatif);
}

.border-all-5-dashed-rouge_negatif {
  border: 5px dashed var(--rouge_negatif);
}

.border-all-5-dotted-rouge_negatif {
  border: 5px dotted var(--rouge_negatif);
}

.border-all-5-double-rouge_negatif {
  border: 5px double var(--rouge_negatif);
}

.border-all-5-groove-rouge_negatif {
  border: 5px groove var(--rouge_negatif);
}

.border-all-5-ridge-rouge_negatif {
  border: 5px ridge var(--rouge_negatif);
}

.border-all-5-inset-rouge_negatif {
  border: 5px inset var(--rouge_negatif);
}

.border-all-5-outset-rouge_negatif {
  border: 5px outset var(--rouge_negatif);
}

.border-all-5-none-rouge_negatif {
  border: 5px none var(--rouge_negatif);
}

.border-all-5-hidden-rouge_negatif {
  border: 5px hidden var(--rouge_negatif);
}

.border-all-5-solid-bleu_texte {
  border: 5px solid var(--bleu_texte);
}

.border-all-5-dashed-bleu_texte {
  border: 5px dashed var(--bleu_texte);
}

.border-all-5-dotted-bleu_texte {
  border: 5px dotted var(--bleu_texte);
}

.border-all-5-double-bleu_texte {
  border: 5px double var(--bleu_texte);
}

.border-all-5-groove-bleu_texte {
  border: 5px groove var(--bleu_texte);
}

.border-all-5-ridge-bleu_texte {
  border: 5px ridge var(--bleu_texte);
}

.border-all-5-inset-bleu_texte {
  border: 5px inset var(--bleu_texte);
}

.border-all-5-outset-bleu_texte {
  border: 5px outset var(--bleu_texte);
}

.border-all-5-none-bleu_texte {
  border: 5px none var(--bleu_texte);
}

.border-all-5-hidden-bleu_texte {
  border: 5px hidden var(--bleu_texte);
}

.border-all-5-solid-alerte_vert_positif {
  border: 5px solid var(--alerte_vert_positif);
}

.border-all-5-dashed-alerte_vert_positif {
  border: 5px dashed var(--alerte_vert_positif);
}

.border-all-5-dotted-alerte_vert_positif {
  border: 5px dotted var(--alerte_vert_positif);
}

.border-all-5-double-alerte_vert_positif {
  border: 5px double var(--alerte_vert_positif);
}

.border-all-5-groove-alerte_vert_positif {
  border: 5px groove var(--alerte_vert_positif);
}

.border-all-5-ridge-alerte_vert_positif {
  border: 5px ridge var(--alerte_vert_positif);
}

.border-all-5-inset-alerte_vert_positif {
  border: 5px inset var(--alerte_vert_positif);
}

.border-all-5-outset-alerte_vert_positif {
  border: 5px outset var(--alerte_vert_positif);
}

.border-all-5-none-alerte_vert_positif {
  border: 5px none var(--alerte_vert_positif);
}

.border-all-5-hidden-alerte_vert_positif {
  border: 5px hidden var(--alerte_vert_positif);
}

.border-all-5-solid-alerte_rouge_negatif {
  border: 5px solid var(--alerte_rouge_negatif);
}

.border-all-5-dashed-alerte_rouge_negatif {
  border: 5px dashed var(--alerte_rouge_negatif);
}

.border-all-5-dotted-alerte_rouge_negatif {
  border: 5px dotted var(--alerte_rouge_negatif);
}

.border-all-5-double-alerte_rouge_negatif {
  border: 5px double var(--alerte_rouge_negatif);
}

.border-all-5-groove-alerte_rouge_negatif {
  border: 5px groove var(--alerte_rouge_negatif);
}

.border-all-5-ridge-alerte_rouge_negatif {
  border: 5px ridge var(--alerte_rouge_negatif);
}

.border-all-5-inset-alerte_rouge_negatif {
  border: 5px inset var(--alerte_rouge_negatif);
}

.border-all-5-outset-alerte_rouge_negatif {
  border: 5px outset var(--alerte_rouge_negatif);
}

.border-all-5-none-alerte_rouge_negatif {
  border: 5px none var(--alerte_rouge_negatif);
}

.border-all-5-hidden-alerte_rouge_negatif {
  border: 5px hidden var(--alerte_rouge_negatif);
}

.border-all-5-solid-alerte_bleu_information {
  border: 5px solid var(--alerte_bleu_information);
}

.border-all-5-dashed-alerte_bleu_information {
  border: 5px dashed var(--alerte_bleu_information);
}

.border-all-5-dotted-alerte_bleu_information {
  border: 5px dotted var(--alerte_bleu_information);
}

.border-all-5-double-alerte_bleu_information {
  border: 5px double var(--alerte_bleu_information);
}

.border-all-5-groove-alerte_bleu_information {
  border: 5px groove var(--alerte_bleu_information);
}

.border-all-5-ridge-alerte_bleu_information {
  border: 5px ridge var(--alerte_bleu_information);
}

.border-all-5-inset-alerte_bleu_information {
  border: 5px inset var(--alerte_bleu_information);
}

.border-all-5-outset-alerte_bleu_information {
  border: 5px outset var(--alerte_bleu_information);
}

.border-all-5-none-alerte_bleu_information {
  border: 5px none var(--alerte_bleu_information);
}

.border-all-5-hidden-alerte_bleu_information {
  border: 5px hidden var(--alerte_bleu_information);
}

.border-all-5-solid-alerte_jaune_attention {
  border: 5px solid var(--alerte_jaune_attention);
}

.border-all-5-dashed-alerte_jaune_attention {
  border: 5px dashed var(--alerte_jaune_attention);
}

.border-all-5-dotted-alerte_jaune_attention {
  border: 5px dotted var(--alerte_jaune_attention);
}

.border-all-5-double-alerte_jaune_attention {
  border: 5px double var(--alerte_jaune_attention);
}

.border-all-5-groove-alerte_jaune_attention {
  border: 5px groove var(--alerte_jaune_attention);
}

.border-all-5-ridge-alerte_jaune_attention {
  border: 5px ridge var(--alerte_jaune_attention);
}

.border-all-5-inset-alerte_jaune_attention {
  border: 5px inset var(--alerte_jaune_attention);
}

.border-all-5-outset-alerte_jaune_attention {
  border: 5px outset var(--alerte_jaune_attention);
}

.border-all-5-none-alerte_jaune_attention {
  border: 5px none var(--alerte_jaune_attention);
}

.border-all-5-hidden-alerte_jaune_attention {
  border: 5px hidden var(--alerte_jaune_attention);
}

.border-all-5-solid-blanc {
  border: 5px solid #fff;
}

.border-all-5-dashed-blanc {
  border: 5px dashed #fff;
}

.border-all-5-dotted-blanc {
  border: 5px dotted #fff;
}

.border-all-5-double-blanc {
  border: 5px double #fff;
}

.border-all-5-groove-blanc {
  border: 5px groove #fff;
}

.border-all-5-ridge-blanc {
  border: 5px ridge #fff;
}

.border-all-5-inset-blanc {
  border: 5px inset #fff;
}

.border-all-5-outset-blanc {
  border: 5px outset #fff;
}

.border-all-5-none-blanc {
  border: 5px #fff;
}

.border-all-5-hidden-blanc {
  border: 5px hidden #fff;
}

.border-all-5-solid-noir {
  border: 5px solid #222;
}

.border-all-5-dashed-noir {
  border: 5px dashed #222;
}

.border-all-5-dotted-noir {
  border: 5px dotted #222;
}

.border-all-5-double-noir {
  border: 5px double #222;
}

.border-all-5-groove-noir {
  border: 5px groove #222;
}

.border-all-5-ridge-noir {
  border: 5px ridge #222;
}

.border-all-5-inset-noir {
  border: 5px inset #222;
}

.border-all-5-outset-noir {
  border: 5px outset #222;
}

.border-all-5-none-noir {
  border: 5px #222;
}

.border-all-5-hidden-noir {
  border: 5px hidden #222;
}

.border-all-5-solid-gris_light {
  border: 5px solid var(--gris_light);
}

.border-all-5-dashed-gris_light {
  border: 5px dashed var(--gris_light);
}

.border-all-5-dotted-gris_light {
  border: 5px dotted var(--gris_light);
}

.border-all-5-double-gris_light {
  border: 5px double var(--gris_light);
}

.border-all-5-groove-gris_light {
  border: 5px groove var(--gris_light);
}

.border-all-5-ridge-gris_light {
  border: 5px ridge var(--gris_light);
}

.border-all-5-inset-gris_light {
  border: 5px inset var(--gris_light);
}

.border-all-5-outset-gris_light {
  border: 5px outset var(--gris_light);
}

.border-all-5-none-gris_light {
  border: 5px none var(--gris_light);
}

.border-all-5-hidden-gris_light {
  border: 5px hidden var(--gris_light);
}

.border-all-5-solid-gris_dark {
  border: 5px solid var(--gris_dark);
}

.border-all-5-dashed-gris_dark {
  border: 5px dashed var(--gris_dark);
}

.border-all-5-dotted-gris_dark {
  border: 5px dotted var(--gris_dark);
}

.border-all-5-double-gris_dark {
  border: 5px double var(--gris_dark);
}

.border-all-5-groove-gris_dark {
  border: 5px groove var(--gris_dark);
}

.border-all-5-ridge-gris_dark {
  border: 5px ridge var(--gris_dark);
}

.border-all-5-inset-gris_dark {
  border: 5px inset var(--gris_dark);
}

.border-all-5-outset-gris_dark {
  border: 5px outset var(--gris_dark);
}

.border-all-5-none-gris_dark {
  border: 5px none var(--gris_dark);
}

.border-all-5-hidden-gris_dark {
  border: 5px hidden var(--gris_dark);
}

.border-all-5-solid-gris_background {
  border: 5px solid var(--gris_background);
}

.border-all-5-dashed-gris_background {
  border: 5px dashed var(--gris_background);
}

.border-all-5-dotted-gris_background {
  border: 5px dotted var(--gris_background);
}

.border-all-5-double-gris_background {
  border: 5px double var(--gris_background);
}

.border-all-5-groove-gris_background {
  border: 5px groove var(--gris_background);
}

.border-all-5-ridge-gris_background {
  border: 5px ridge var(--gris_background);
}

.border-all-5-inset-gris_background {
  border: 5px inset var(--gris_background);
}

.border-all-5-outset-gris_background {
  border: 5px outset var(--gris_background);
}

.border-all-5-none-gris_background {
  border: 5px none var(--gris_background);
}

.border-all-5-hidden-gris_background {
  border: 5px hidden var(--gris_background);
}

.border-top-6-solid-corail_mh_brand {
  border-top: 6px solid var(--corail_mh_brand);
}

.border-top-6-dashed-corail_mh_brand {
  border-top: 6px dashed var(--corail_mh_brand);
}

.border-top-6-dotted-corail_mh_brand {
  border-top: 6px dotted var(--corail_mh_brand);
}

.border-top-6-double-corail_mh_brand {
  border-top: 6px double var(--corail_mh_brand);
}

.border-top-6-groove-corail_mh_brand {
  border-top: 6px groove var(--corail_mh_brand);
}

.border-top-6-ridge-corail_mh_brand {
  border-top: 6px ridge var(--corail_mh_brand);
}

.border-top-6-inset-corail_mh_brand {
  border-top: 6px inset var(--corail_mh_brand);
}

.border-top-6-outset-corail_mh_brand {
  border-top: 6px outset var(--corail_mh_brand);
}

.border-top-6-none-corail_mh_brand {
  border-top: 6px none var(--corail_mh_brand);
}

.border-top-6-hidden-corail_mh_brand {
  border-top: 6px hidden var(--corail_mh_brand);
}

.border-top-6-solid-corail_mh_dark {
  border-top: 6px solid var(--corail_mh_dark);
}

.border-top-6-dashed-corail_mh_dark {
  border-top: 6px dashed var(--corail_mh_dark);
}

.border-top-6-dotted-corail_mh_dark {
  border-top: 6px dotted var(--corail_mh_dark);
}

.border-top-6-double-corail_mh_dark {
  border-top: 6px double var(--corail_mh_dark);
}

.border-top-6-groove-corail_mh_dark {
  border-top: 6px groove var(--corail_mh_dark);
}

.border-top-6-ridge-corail_mh_dark {
  border-top: 6px ridge var(--corail_mh_dark);
}

.border-top-6-inset-corail_mh_dark {
  border-top: 6px inset var(--corail_mh_dark);
}

.border-top-6-outset-corail_mh_dark {
  border-top: 6px outset var(--corail_mh_dark);
}

.border-top-6-none-corail_mh_dark {
  border-top: 6px none var(--corail_mh_dark);
}

.border-top-6-hidden-corail_mh_dark {
  border-top: 6px hidden var(--corail_mh_dark);
}

.border-top-6-solid-corail_mh_light {
  border-top: 6px solid var(--corail_mh_light);
}

.border-top-6-dashed-corail_mh_light {
  border-top: 6px dashed var(--corail_mh_light);
}

.border-top-6-dotted-corail_mh_light {
  border-top: 6px dotted var(--corail_mh_light);
}

.border-top-6-double-corail_mh_light {
  border-top: 6px double var(--corail_mh_light);
}

.border-top-6-groove-corail_mh_light {
  border-top: 6px groove var(--corail_mh_light);
}

.border-top-6-ridge-corail_mh_light {
  border-top: 6px ridge var(--corail_mh_light);
}

.border-top-6-inset-corail_mh_light {
  border-top: 6px inset var(--corail_mh_light);
}

.border-top-6-outset-corail_mh_light {
  border-top: 6px outset var(--corail_mh_light);
}

.border-top-6-none-corail_mh_light {
  border-top: 6px none var(--corail_mh_light);
}

.border-top-6-hidden-corail_mh_light {
  border-top: 6px hidden var(--corail_mh_light);
}

.border-top-6-solid-gris_sable_light {
  border-top: 6px solid var(--gris_sable_light);
}

.border-top-6-dashed-gris_sable_light {
  border-top: 6px dashed var(--gris_sable_light);
}

.border-top-6-dotted-gris_sable_light {
  border-top: 6px dotted var(--gris_sable_light);
}

.border-top-6-double-gris_sable_light {
  border-top: 6px double var(--gris_sable_light);
}

.border-top-6-groove-gris_sable_light {
  border-top: 6px groove var(--gris_sable_light);
}

.border-top-6-ridge-gris_sable_light {
  border-top: 6px ridge var(--gris_sable_light);
}

.border-top-6-inset-gris_sable_light {
  border-top: 6px inset var(--gris_sable_light);
}

.border-top-6-outset-gris_sable_light {
  border-top: 6px outset var(--gris_sable_light);
}

.border-top-6-none-gris_sable_light {
  border-top: 6px none var(--gris_sable_light);
}

.border-top-6-hidden-gris_sable_light {
  border-top: 6px hidden var(--gris_sable_light);
}

.border-top-6-solid-gris_sable_dark {
  border-top: 6px solid var(--gris_sable_dark);
}

.border-top-6-dashed-gris_sable_dark {
  border-top: 6px dashed var(--gris_sable_dark);
}

.border-top-6-dotted-gris_sable_dark {
  border-top: 6px dotted var(--gris_sable_dark);
}

.border-top-6-double-gris_sable_dark {
  border-top: 6px double var(--gris_sable_dark);
}

.border-top-6-groove-gris_sable_dark {
  border-top: 6px groove var(--gris_sable_dark);
}

.border-top-6-ridge-gris_sable_dark {
  border-top: 6px ridge var(--gris_sable_dark);
}

.border-top-6-inset-gris_sable_dark {
  border-top: 6px inset var(--gris_sable_dark);
}

.border-top-6-outset-gris_sable_dark {
  border-top: 6px outset var(--gris_sable_dark);
}

.border-top-6-none-gris_sable_dark {
  border-top: 6px none var(--gris_sable_dark);
}

.border-top-6-hidden-gris_sable_dark {
  border-top: 6px hidden var(--gris_sable_dark);
}

.border-top-6-solid-jaune_ivoire_light {
  border-top: 6px solid var(--jaune_ivoire_light);
}

.border-top-6-dashed-jaune_ivoire_light {
  border-top: 6px dashed var(--jaune_ivoire_light);
}

.border-top-6-dotted-jaune_ivoire_light {
  border-top: 6px dotted var(--jaune_ivoire_light);
}

.border-top-6-double-jaune_ivoire_light {
  border-top: 6px double var(--jaune_ivoire_light);
}

.border-top-6-groove-jaune_ivoire_light {
  border-top: 6px groove var(--jaune_ivoire_light);
}

.border-top-6-ridge-jaune_ivoire_light {
  border-top: 6px ridge var(--jaune_ivoire_light);
}

.border-top-6-inset-jaune_ivoire_light {
  border-top: 6px inset var(--jaune_ivoire_light);
}

.border-top-6-outset-jaune_ivoire_light {
  border-top: 6px outset var(--jaune_ivoire_light);
}

.border-top-6-none-jaune_ivoire_light {
  border-top: 6px none var(--jaune_ivoire_light);
}

.border-top-6-hidden-jaune_ivoire_light {
  border-top: 6px hidden var(--jaune_ivoire_light);
}

.border-top-6-solid-jaune_ivoire_dark {
  border-top: 6px solid var(--jaune_ivoire_dark);
}

.border-top-6-dashed-jaune_ivoire_dark {
  border-top: 6px dashed var(--jaune_ivoire_dark);
}

.border-top-6-dotted-jaune_ivoire_dark {
  border-top: 6px dotted var(--jaune_ivoire_dark);
}

.border-top-6-double-jaune_ivoire_dark {
  border-top: 6px double var(--jaune_ivoire_dark);
}

.border-top-6-groove-jaune_ivoire_dark {
  border-top: 6px groove var(--jaune_ivoire_dark);
}

.border-top-6-ridge-jaune_ivoire_dark {
  border-top: 6px ridge var(--jaune_ivoire_dark);
}

.border-top-6-inset-jaune_ivoire_dark {
  border-top: 6px inset var(--jaune_ivoire_dark);
}

.border-top-6-outset-jaune_ivoire_dark {
  border-top: 6px outset var(--jaune_ivoire_dark);
}

.border-top-6-none-jaune_ivoire_dark {
  border-top: 6px none var(--jaune_ivoire_dark);
}

.border-top-6-hidden-jaune_ivoire_dark {
  border-top: 6px hidden var(--jaune_ivoire_dark);
}

.border-top-6-solid-jaune_light {
  border-top: 6px solid var(--jaune_light);
}

.border-top-6-dashed-jaune_light {
  border-top: 6px dashed var(--jaune_light);
}

.border-top-6-dotted-jaune_light {
  border-top: 6px dotted var(--jaune_light);
}

.border-top-6-double-jaune_light {
  border-top: 6px double var(--jaune_light);
}

.border-top-6-groove-jaune_light {
  border-top: 6px groove var(--jaune_light);
}

.border-top-6-ridge-jaune_light {
  border-top: 6px ridge var(--jaune_light);
}

.border-top-6-inset-jaune_light {
  border-top: 6px inset var(--jaune_light);
}

.border-top-6-outset-jaune_light {
  border-top: 6px outset var(--jaune_light);
}

.border-top-6-none-jaune_light {
  border-top: 6px none var(--jaune_light);
}

.border-top-6-hidden-jaune_light {
  border-top: 6px hidden var(--jaune_light);
}

.border-top-6-solid-jaune_dark {
  border-top: 6px solid var(--jaune_dark);
}

.border-top-6-dashed-jaune_dark {
  border-top: 6px dashed var(--jaune_dark);
}

.border-top-6-dotted-jaune_dark {
  border-top: 6px dotted var(--jaune_dark);
}

.border-top-6-double-jaune_dark {
  border-top: 6px double var(--jaune_dark);
}

.border-top-6-groove-jaune_dark {
  border-top: 6px groove var(--jaune_dark);
}

.border-top-6-ridge-jaune_dark {
  border-top: 6px ridge var(--jaune_dark);
}

.border-top-6-inset-jaune_dark {
  border-top: 6px inset var(--jaune_dark);
}

.border-top-6-outset-jaune_dark {
  border-top: 6px outset var(--jaune_dark);
}

.border-top-6-none-jaune_dark {
  border-top: 6px none var(--jaune_dark);
}

.border-top-6-hidden-jaune_dark {
  border-top: 6px hidden var(--jaune_dark);
}

.border-top-6-solid-orange_normal {
  border-top: 6px solid var(--orange_normal);
}

.border-top-6-dashed-orange_normal {
  border-top: 6px dashed var(--orange_normal);
}

.border-top-6-dotted-orange_normal {
  border-top: 6px dotted var(--orange_normal);
}

.border-top-6-double-orange_normal {
  border-top: 6px double var(--orange_normal);
}

.border-top-6-groove-orange_normal {
  border-top: 6px groove var(--orange_normal);
}

.border-top-6-ridge-orange_normal {
  border-top: 6px ridge var(--orange_normal);
}

.border-top-6-inset-orange_normal {
  border-top: 6px inset var(--orange_normal);
}

.border-top-6-outset-orange_normal {
  border-top: 6px outset var(--orange_normal);
}

.border-top-6-none-orange_normal {
  border-top: 6px none var(--orange_normal);
}

.border-top-6-hidden-orange_normal {
  border-top: 6px hidden var(--orange_normal);
}

.border-top-6-solid-orange_dark {
  border-top: 6px solid var(--orange_dark);
}

.border-top-6-dashed-orange_dark {
  border-top: 6px dashed var(--orange_dark);
}

.border-top-6-dotted-orange_dark {
  border-top: 6px dotted var(--orange_dark);
}

.border-top-6-double-orange_dark {
  border-top: 6px double var(--orange_dark);
}

.border-top-6-groove-orange_dark {
  border-top: 6px groove var(--orange_dark);
}

.border-top-6-ridge-orange_dark {
  border-top: 6px ridge var(--orange_dark);
}

.border-top-6-inset-orange_dark {
  border-top: 6px inset var(--orange_dark);
}

.border-top-6-outset-orange_dark {
  border-top: 6px outset var(--orange_dark);
}

.border-top-6-none-orange_dark {
  border-top: 6px none var(--orange_dark);
}

.border-top-6-hidden-orange_dark {
  border-top: 6px hidden var(--orange_dark);
}

.border-top-6-solid-vert_light {
  border-top: 6px solid var(--vert_light);
}

.border-top-6-dashed-vert_light {
  border-top: 6px dashed var(--vert_light);
}

.border-top-6-dotted-vert_light {
  border-top: 6px dotted var(--vert_light);
}

.border-top-6-double-vert_light {
  border-top: 6px double var(--vert_light);
}

.border-top-6-groove-vert_light {
  border-top: 6px groove var(--vert_light);
}

.border-top-6-ridge-vert_light {
  border-top: 6px ridge var(--vert_light);
}

.border-top-6-inset-vert_light {
  border-top: 6px inset var(--vert_light);
}

.border-top-6-outset-vert_light {
  border-top: 6px outset var(--vert_light);
}

.border-top-6-none-vert_light {
  border-top: 6px none var(--vert_light);
}

.border-top-6-hidden-vert_light {
  border-top: 6px hidden var(--vert_light);
}

.border-top-6-solid-vert_dark {
  border-top: 6px solid var(--vert_dark);
}

.border-top-6-dashed-vert_dark {
  border-top: 6px dashed var(--vert_dark);
}

.border-top-6-dotted-vert_dark {
  border-top: 6px dotted var(--vert_dark);
}

.border-top-6-double-vert_dark {
  border-top: 6px double var(--vert_dark);
}

.border-top-6-groove-vert_dark {
  border-top: 6px groove var(--vert_dark);
}

.border-top-6-ridge-vert_dark {
  border-top: 6px ridge var(--vert_dark);
}

.border-top-6-inset-vert_dark {
  border-top: 6px inset var(--vert_dark);
}

.border-top-6-outset-vert_dark {
  border-top: 6px outset var(--vert_dark);
}

.border-top-6-none-vert_dark {
  border-top: 6px none var(--vert_dark);
}

.border-top-6-hidden-vert_dark {
  border-top: 6px hidden var(--vert_dark);
}

.border-top-6-solid-bleu_turquoise_light {
  border-top: 6px solid var(--bleu_turquoise_light);
}

.border-top-6-dashed-bleu_turquoise_light {
  border-top: 6px dashed var(--bleu_turquoise_light);
}

.border-top-6-dotted-bleu_turquoise_light {
  border-top: 6px dotted var(--bleu_turquoise_light);
}

.border-top-6-double-bleu_turquoise_light {
  border-top: 6px double var(--bleu_turquoise_light);
}

.border-top-6-groove-bleu_turquoise_light {
  border-top: 6px groove var(--bleu_turquoise_light);
}

.border-top-6-ridge-bleu_turquoise_light {
  border-top: 6px ridge var(--bleu_turquoise_light);
}

.border-top-6-inset-bleu_turquoise_light {
  border-top: 6px inset var(--bleu_turquoise_light);
}

.border-top-6-outset-bleu_turquoise_light {
  border-top: 6px outset var(--bleu_turquoise_light);
}

.border-top-6-none-bleu_turquoise_light {
  border-top: 6px none var(--bleu_turquoise_light);
}

.border-top-6-hidden-bleu_turquoise_light {
  border-top: 6px hidden var(--bleu_turquoise_light);
}

.border-top-6-solid-bleu_turquoise_middle {
  border-top: 6px solid var(--bleu_turquoise_middle);
}

.border-top-6-dashed-bleu_turquoise_middle {
  border-top: 6px dashed var(--bleu_turquoise_middle);
}

.border-top-6-dotted-bleu_turquoise_middle {
  border-top: 6px dotted var(--bleu_turquoise_middle);
}

.border-top-6-double-bleu_turquoise_middle {
  border-top: 6px double var(--bleu_turquoise_middle);
}

.border-top-6-groove-bleu_turquoise_middle {
  border-top: 6px groove var(--bleu_turquoise_middle);
}

.border-top-6-ridge-bleu_turquoise_middle {
  border-top: 6px ridge var(--bleu_turquoise_middle);
}

.border-top-6-inset-bleu_turquoise_middle {
  border-top: 6px inset var(--bleu_turquoise_middle);
}

.border-top-6-outset-bleu_turquoise_middle {
  border-top: 6px outset var(--bleu_turquoise_middle);
}

.border-top-6-none-bleu_turquoise_middle {
  border-top: 6px none var(--bleu_turquoise_middle);
}

.border-top-6-hidden-bleu_turquoise_middle {
  border-top: 6px hidden var(--bleu_turquoise_middle);
}

.border-top-6-solid-bleu_turquoise_dark {
  border-top: 6px solid var(--bleu_turquoise_dark);
}

.border-top-6-dashed-bleu_turquoise_dark {
  border-top: 6px dashed var(--bleu_turquoise_dark);
}

.border-top-6-dotted-bleu_turquoise_dark {
  border-top: 6px dotted var(--bleu_turquoise_dark);
}

.border-top-6-double-bleu_turquoise_dark {
  border-top: 6px double var(--bleu_turquoise_dark);
}

.border-top-6-groove-bleu_turquoise_dark {
  border-top: 6px groove var(--bleu_turquoise_dark);
}

.border-top-6-ridge-bleu_turquoise_dark {
  border-top: 6px ridge var(--bleu_turquoise_dark);
}

.border-top-6-inset-bleu_turquoise_dark {
  border-top: 6px inset var(--bleu_turquoise_dark);
}

.border-top-6-outset-bleu_turquoise_dark {
  border-top: 6px outset var(--bleu_turquoise_dark);
}

.border-top-6-none-bleu_turquoise_dark {
  border-top: 6px none var(--bleu_turquoise_dark);
}

.border-top-6-hidden-bleu_turquoise_dark {
  border-top: 6px hidden var(--bleu_turquoise_dark);
}

.border-top-6-solid-bleu_light {
  border-top: 6px solid var(--bleu_light);
}

.border-top-6-dashed-bleu_light {
  border-top: 6px dashed var(--bleu_light);
}

.border-top-6-dotted-bleu_light {
  border-top: 6px dotted var(--bleu_light);
}

.border-top-6-double-bleu_light {
  border-top: 6px double var(--bleu_light);
}

.border-top-6-groove-bleu_light {
  border-top: 6px groove var(--bleu_light);
}

.border-top-6-ridge-bleu_light {
  border-top: 6px ridge var(--bleu_light);
}

.border-top-6-inset-bleu_light {
  border-top: 6px inset var(--bleu_light);
}

.border-top-6-outset-bleu_light {
  border-top: 6px outset var(--bleu_light);
}

.border-top-6-none-bleu_light {
  border-top: 6px none var(--bleu_light);
}

.border-top-6-hidden-bleu_light {
  border-top: 6px hidden var(--bleu_light);
}

.border-top-6-solid-bleu_dark {
  border-top: 6px solid var(--bleu_dark);
}

.border-top-6-dashed-bleu_dark {
  border-top: 6px dashed var(--bleu_dark);
}

.border-top-6-dotted-bleu_dark {
  border-top: 6px dotted var(--bleu_dark);
}

.border-top-6-double-bleu_dark {
  border-top: 6px double var(--bleu_dark);
}

.border-top-6-groove-bleu_dark {
  border-top: 6px groove var(--bleu_dark);
}

.border-top-6-ridge-bleu_dark {
  border-top: 6px ridge var(--bleu_dark);
}

.border-top-6-inset-bleu_dark {
  border-top: 6px inset var(--bleu_dark);
}

.border-top-6-outset-bleu_dark {
  border-top: 6px outset var(--bleu_dark);
}

.border-top-6-none-bleu_dark {
  border-top: 6px none var(--bleu_dark);
}

.border-top-6-hidden-bleu_dark {
  border-top: 6px hidden var(--bleu_dark);
}

.border-top-6-solid-bleu_klein_light {
  border-top: 6px solid var(--bleu_klein_light);
}

.border-top-6-dashed-bleu_klein_light {
  border-top: 6px dashed var(--bleu_klein_light);
}

.border-top-6-dotted-bleu_klein_light {
  border-top: 6px dotted var(--bleu_klein_light);
}

.border-top-6-double-bleu_klein_light {
  border-top: 6px double var(--bleu_klein_light);
}

.border-top-6-groove-bleu_klein_light {
  border-top: 6px groove var(--bleu_klein_light);
}

.border-top-6-ridge-bleu_klein_light {
  border-top: 6px ridge var(--bleu_klein_light);
}

.border-top-6-inset-bleu_klein_light {
  border-top: 6px inset var(--bleu_klein_light);
}

.border-top-6-outset-bleu_klein_light {
  border-top: 6px outset var(--bleu_klein_light);
}

.border-top-6-none-bleu_klein_light {
  border-top: 6px none var(--bleu_klein_light);
}

.border-top-6-hidden-bleu_klein_light {
  border-top: 6px hidden var(--bleu_klein_light);
}

.border-top-6-solid-bleu_klein_dark {
  border-top: 6px solid var(--bleu_klein_dark);
}

.border-top-6-dashed-bleu_klein_dark {
  border-top: 6px dashed var(--bleu_klein_dark);
}

.border-top-6-dotted-bleu_klein_dark {
  border-top: 6px dotted var(--bleu_klein_dark);
}

.border-top-6-double-bleu_klein_dark {
  border-top: 6px double var(--bleu_klein_dark);
}

.border-top-6-groove-bleu_klein_dark {
  border-top: 6px groove var(--bleu_klein_dark);
}

.border-top-6-ridge-bleu_klein_dark {
  border-top: 6px ridge var(--bleu_klein_dark);
}

.border-top-6-inset-bleu_klein_dark {
  border-top: 6px inset var(--bleu_klein_dark);
}

.border-top-6-outset-bleu_klein_dark {
  border-top: 6px outset var(--bleu_klein_dark);
}

.border-top-6-none-bleu_klein_dark {
  border-top: 6px none var(--bleu_klein_dark);
}

.border-top-6-hidden-bleu_klein_dark {
  border-top: 6px hidden var(--bleu_klein_dark);
}

.border-top-6-solid-violet_light {
  border-top: 6px solid var(--violet_light);
}

.border-top-6-dashed-violet_light {
  border-top: 6px dashed var(--violet_light);
}

.border-top-6-dotted-violet_light {
  border-top: 6px dotted var(--violet_light);
}

.border-top-6-double-violet_light {
  border-top: 6px double var(--violet_light);
}

.border-top-6-groove-violet_light {
  border-top: 6px groove var(--violet_light);
}

.border-top-6-ridge-violet_light {
  border-top: 6px ridge var(--violet_light);
}

.border-top-6-inset-violet_light {
  border-top: 6px inset var(--violet_light);
}

.border-top-6-outset-violet_light {
  border-top: 6px outset var(--violet_light);
}

.border-top-6-none-violet_light {
  border-top: 6px none var(--violet_light);
}

.border-top-6-hidden-violet_light {
  border-top: 6px hidden var(--violet_light);
}

.border-top-6-solid-violet_dark {
  border-top: 6px solid var(--violet_dark);
}

.border-top-6-dashed-violet_dark {
  border-top: 6px dashed var(--violet_dark);
}

.border-top-6-dotted-violet_dark {
  border-top: 6px dotted var(--violet_dark);
}

.border-top-6-double-violet_dark {
  border-top: 6px double var(--violet_dark);
}

.border-top-6-groove-violet_dark {
  border-top: 6px groove var(--violet_dark);
}

.border-top-6-ridge-violet_dark {
  border-top: 6px ridge var(--violet_dark);
}

.border-top-6-inset-violet_dark {
  border-top: 6px inset var(--violet_dark);
}

.border-top-6-outset-violet_dark {
  border-top: 6px outset var(--violet_dark);
}

.border-top-6-none-violet_dark {
  border-top: 6px none var(--violet_dark);
}

.border-top-6-hidden-violet_dark {
  border-top: 6px hidden var(--violet_dark);
}

.border-top-6-solid-mauve {
  border-top: 6px solid var(--mauve);
}

.border-top-6-dashed-mauve {
  border-top: 6px dashed var(--mauve);
}

.border-top-6-dotted-mauve {
  border-top: 6px dotted var(--mauve);
}

.border-top-6-double-mauve {
  border-top: 6px double var(--mauve);
}

.border-top-6-groove-mauve {
  border-top: 6px groove var(--mauve);
}

.border-top-6-ridge-mauve {
  border-top: 6px ridge var(--mauve);
}

.border-top-6-inset-mauve {
  border-top: 6px inset var(--mauve);
}

.border-top-6-outset-mauve {
  border-top: 6px outset var(--mauve);
}

.border-top-6-none-mauve {
  border-top: 6px none var(--mauve);
}

.border-top-6-hidden-mauve {
  border-top: 6px hidden var(--mauve);
}

.border-top-6-solid-rose_light {
  border-top: 6px solid var(--rose_light);
}

.border-top-6-dashed-rose_light {
  border-top: 6px dashed var(--rose_light);
}

.border-top-6-dotted-rose_light {
  border-top: 6px dotted var(--rose_light);
}

.border-top-6-double-rose_light {
  border-top: 6px double var(--rose_light);
}

.border-top-6-groove-rose_light {
  border-top: 6px groove var(--rose_light);
}

.border-top-6-ridge-rose_light {
  border-top: 6px ridge var(--rose_light);
}

.border-top-6-inset-rose_light {
  border-top: 6px inset var(--rose_light);
}

.border-top-6-outset-rose_light {
  border-top: 6px outset var(--rose_light);
}

.border-top-6-none-rose_light {
  border-top: 6px none var(--rose_light);
}

.border-top-6-hidden-rose_light {
  border-top: 6px hidden var(--rose_light);
}

.border-top-6-solid-rose_dark {
  border-top: 6px solid var(--rose_dark);
}

.border-top-6-dashed-rose_dark {
  border-top: 6px dashed var(--rose_dark);
}

.border-top-6-dotted-rose_dark {
  border-top: 6px dotted var(--rose_dark);
}

.border-top-6-double-rose_dark {
  border-top: 6px double var(--rose_dark);
}

.border-top-6-groove-rose_dark {
  border-top: 6px groove var(--rose_dark);
}

.border-top-6-ridge-rose_dark {
  border-top: 6px ridge var(--rose_dark);
}

.border-top-6-inset-rose_dark {
  border-top: 6px inset var(--rose_dark);
}

.border-top-6-outset-rose_dark {
  border-top: 6px outset var(--rose_dark);
}

.border-top-6-none-rose_dark {
  border-top: 6px none var(--rose_dark);
}

.border-top-6-hidden-rose_dark {
  border-top: 6px hidden var(--rose_dark);
}

.border-top-6-solid-rose_fushia {
  border-top: 6px solid var(--rose_fushia);
}

.border-top-6-dashed-rose_fushia {
  border-top: 6px dashed var(--rose_fushia);
}

.border-top-6-dotted-rose_fushia {
  border-top: 6px dotted var(--rose_fushia);
}

.border-top-6-double-rose_fushia {
  border-top: 6px double var(--rose_fushia);
}

.border-top-6-groove-rose_fushia {
  border-top: 6px groove var(--rose_fushia);
}

.border-top-6-ridge-rose_fushia {
  border-top: 6px ridge var(--rose_fushia);
}

.border-top-6-inset-rose_fushia {
  border-top: 6px inset var(--rose_fushia);
}

.border-top-6-outset-rose_fushia {
  border-top: 6px outset var(--rose_fushia);
}

.border-top-6-none-rose_fushia {
  border-top: 6px none var(--rose_fushia);
}

.border-top-6-hidden-rose_fushia {
  border-top: 6px hidden var(--rose_fushia);
}

.border-top-6-solid-rouge_framboise {
  border-top: 6px solid var(--rouge_framboise);
}

.border-top-6-dashed-rouge_framboise {
  border-top: 6px dashed var(--rouge_framboise);
}

.border-top-6-dotted-rouge_framboise {
  border-top: 6px dotted var(--rouge_framboise);
}

.border-top-6-double-rouge_framboise {
  border-top: 6px double var(--rouge_framboise);
}

.border-top-6-groove-rouge_framboise {
  border-top: 6px groove var(--rouge_framboise);
}

.border-top-6-ridge-rouge_framboise {
  border-top: 6px ridge var(--rouge_framboise);
}

.border-top-6-inset-rouge_framboise {
  border-top: 6px inset var(--rouge_framboise);
}

.border-top-6-outset-rouge_framboise {
  border-top: 6px outset var(--rouge_framboise);
}

.border-top-6-none-rouge_framboise {
  border-top: 6px none var(--rouge_framboise);
}

.border-top-6-hidden-rouge_framboise {
  border-top: 6px hidden var(--rouge_framboise);
}

.border-top-6-solid-rose_chair_light {
  border-top: 6px solid var(--rose_chair_light);
}

.border-top-6-dashed-rose_chair_light {
  border-top: 6px dashed var(--rose_chair_light);
}

.border-top-6-dotted-rose_chair_light {
  border-top: 6px dotted var(--rose_chair_light);
}

.border-top-6-double-rose_chair_light {
  border-top: 6px double var(--rose_chair_light);
}

.border-top-6-groove-rose_chair_light {
  border-top: 6px groove var(--rose_chair_light);
}

.border-top-6-ridge-rose_chair_light {
  border-top: 6px ridge var(--rose_chair_light);
}

.border-top-6-inset-rose_chair_light {
  border-top: 6px inset var(--rose_chair_light);
}

.border-top-6-outset-rose_chair_light {
  border-top: 6px outset var(--rose_chair_light);
}

.border-top-6-none-rose_chair_light {
  border-top: 6px none var(--rose_chair_light);
}

.border-top-6-hidden-rose_chair_light {
  border-top: 6px hidden var(--rose_chair_light);
}

.border-top-6-solid-rose_chair_dark {
  border-top: 6px solid var(--rose_chair_dark);
}

.border-top-6-dashed-rose_chair_dark {
  border-top: 6px dashed var(--rose_chair_dark);
}

.border-top-6-dotted-rose_chair_dark {
  border-top: 6px dotted var(--rose_chair_dark);
}

.border-top-6-double-rose_chair_dark {
  border-top: 6px double var(--rose_chair_dark);
}

.border-top-6-groove-rose_chair_dark {
  border-top: 6px groove var(--rose_chair_dark);
}

.border-top-6-ridge-rose_chair_dark {
  border-top: 6px ridge var(--rose_chair_dark);
}

.border-top-6-inset-rose_chair_dark {
  border-top: 6px inset var(--rose_chair_dark);
}

.border-top-6-outset-rose_chair_dark {
  border-top: 6px outset var(--rose_chair_dark);
}

.border-top-6-none-rose_chair_dark {
  border-top: 6px none var(--rose_chair_dark);
}

.border-top-6-hidden-rose_chair_dark {
  border-top: 6px hidden var(--rose_chair_dark);
}

.border-top-6-solid-vert_positif {
  border-top: 6px solid var(--vert_positif);
}

.border-top-6-dashed-vert_positif {
  border-top: 6px dashed var(--vert_positif);
}

.border-top-6-dotted-vert_positif {
  border-top: 6px dotted var(--vert_positif);
}

.border-top-6-double-vert_positif {
  border-top: 6px double var(--vert_positif);
}

.border-top-6-groove-vert_positif {
  border-top: 6px groove var(--vert_positif);
}

.border-top-6-ridge-vert_positif {
  border-top: 6px ridge var(--vert_positif);
}

.border-top-6-inset-vert_positif {
  border-top: 6px inset var(--vert_positif);
}

.border-top-6-outset-vert_positif {
  border-top: 6px outset var(--vert_positif);
}

.border-top-6-none-vert_positif {
  border-top: 6px none var(--vert_positif);
}

.border-top-6-hidden-vert_positif {
  border-top: 6px hidden var(--vert_positif);
}

.border-top-6-solid-rouge_negatif {
  border-top: 6px solid var(--rouge_negatif);
}

.border-top-6-dashed-rouge_negatif {
  border-top: 6px dashed var(--rouge_negatif);
}

.border-top-6-dotted-rouge_negatif {
  border-top: 6px dotted var(--rouge_negatif);
}

.border-top-6-double-rouge_negatif {
  border-top: 6px double var(--rouge_negatif);
}

.border-top-6-groove-rouge_negatif {
  border-top: 6px groove var(--rouge_negatif);
}

.border-top-6-ridge-rouge_negatif {
  border-top: 6px ridge var(--rouge_negatif);
}

.border-top-6-inset-rouge_negatif {
  border-top: 6px inset var(--rouge_negatif);
}

.border-top-6-outset-rouge_negatif {
  border-top: 6px outset var(--rouge_negatif);
}

.border-top-6-none-rouge_negatif {
  border-top: 6px none var(--rouge_negatif);
}

.border-top-6-hidden-rouge_negatif {
  border-top: 6px hidden var(--rouge_negatif);
}

.border-top-6-solid-bleu_texte {
  border-top: 6px solid var(--bleu_texte);
}

.border-top-6-dashed-bleu_texte {
  border-top: 6px dashed var(--bleu_texte);
}

.border-top-6-dotted-bleu_texte {
  border-top: 6px dotted var(--bleu_texte);
}

.border-top-6-double-bleu_texte {
  border-top: 6px double var(--bleu_texte);
}

.border-top-6-groove-bleu_texte {
  border-top: 6px groove var(--bleu_texte);
}

.border-top-6-ridge-bleu_texte {
  border-top: 6px ridge var(--bleu_texte);
}

.border-top-6-inset-bleu_texte {
  border-top: 6px inset var(--bleu_texte);
}

.border-top-6-outset-bleu_texte {
  border-top: 6px outset var(--bleu_texte);
}

.border-top-6-none-bleu_texte {
  border-top: 6px none var(--bleu_texte);
}

.border-top-6-hidden-bleu_texte {
  border-top: 6px hidden var(--bleu_texte);
}

.border-top-6-solid-alerte_vert_positif {
  border-top: 6px solid var(--alerte_vert_positif);
}

.border-top-6-dashed-alerte_vert_positif {
  border-top: 6px dashed var(--alerte_vert_positif);
}

.border-top-6-dotted-alerte_vert_positif {
  border-top: 6px dotted var(--alerte_vert_positif);
}

.border-top-6-double-alerte_vert_positif {
  border-top: 6px double var(--alerte_vert_positif);
}

.border-top-6-groove-alerte_vert_positif {
  border-top: 6px groove var(--alerte_vert_positif);
}

.border-top-6-ridge-alerte_vert_positif {
  border-top: 6px ridge var(--alerte_vert_positif);
}

.border-top-6-inset-alerte_vert_positif {
  border-top: 6px inset var(--alerte_vert_positif);
}

.border-top-6-outset-alerte_vert_positif {
  border-top: 6px outset var(--alerte_vert_positif);
}

.border-top-6-none-alerte_vert_positif {
  border-top: 6px none var(--alerte_vert_positif);
}

.border-top-6-hidden-alerte_vert_positif {
  border-top: 6px hidden var(--alerte_vert_positif);
}

.border-top-6-solid-alerte_rouge_negatif {
  border-top: 6px solid var(--alerte_rouge_negatif);
}

.border-top-6-dashed-alerte_rouge_negatif {
  border-top: 6px dashed var(--alerte_rouge_negatif);
}

.border-top-6-dotted-alerte_rouge_negatif {
  border-top: 6px dotted var(--alerte_rouge_negatif);
}

.border-top-6-double-alerte_rouge_negatif {
  border-top: 6px double var(--alerte_rouge_negatif);
}

.border-top-6-groove-alerte_rouge_negatif {
  border-top: 6px groove var(--alerte_rouge_negatif);
}

.border-top-6-ridge-alerte_rouge_negatif {
  border-top: 6px ridge var(--alerte_rouge_negatif);
}

.border-top-6-inset-alerte_rouge_negatif {
  border-top: 6px inset var(--alerte_rouge_negatif);
}

.border-top-6-outset-alerte_rouge_negatif {
  border-top: 6px outset var(--alerte_rouge_negatif);
}

.border-top-6-none-alerte_rouge_negatif {
  border-top: 6px none var(--alerte_rouge_negatif);
}

.border-top-6-hidden-alerte_rouge_negatif {
  border-top: 6px hidden var(--alerte_rouge_negatif);
}

.border-top-6-solid-alerte_bleu_information {
  border-top: 6px solid var(--alerte_bleu_information);
}

.border-top-6-dashed-alerte_bleu_information {
  border-top: 6px dashed var(--alerte_bleu_information);
}

.border-top-6-dotted-alerte_bleu_information {
  border-top: 6px dotted var(--alerte_bleu_information);
}

.border-top-6-double-alerte_bleu_information {
  border-top: 6px double var(--alerte_bleu_information);
}

.border-top-6-groove-alerte_bleu_information {
  border-top: 6px groove var(--alerte_bleu_information);
}

.border-top-6-ridge-alerte_bleu_information {
  border-top: 6px ridge var(--alerte_bleu_information);
}

.border-top-6-inset-alerte_bleu_information {
  border-top: 6px inset var(--alerte_bleu_information);
}

.border-top-6-outset-alerte_bleu_information {
  border-top: 6px outset var(--alerte_bleu_information);
}

.border-top-6-none-alerte_bleu_information {
  border-top: 6px none var(--alerte_bleu_information);
}

.border-top-6-hidden-alerte_bleu_information {
  border-top: 6px hidden var(--alerte_bleu_information);
}

.border-top-6-solid-alerte_jaune_attention {
  border-top: 6px solid var(--alerte_jaune_attention);
}

.border-top-6-dashed-alerte_jaune_attention {
  border-top: 6px dashed var(--alerte_jaune_attention);
}

.border-top-6-dotted-alerte_jaune_attention {
  border-top: 6px dotted var(--alerte_jaune_attention);
}

.border-top-6-double-alerte_jaune_attention {
  border-top: 6px double var(--alerte_jaune_attention);
}

.border-top-6-groove-alerte_jaune_attention {
  border-top: 6px groove var(--alerte_jaune_attention);
}

.border-top-6-ridge-alerte_jaune_attention {
  border-top: 6px ridge var(--alerte_jaune_attention);
}

.border-top-6-inset-alerte_jaune_attention {
  border-top: 6px inset var(--alerte_jaune_attention);
}

.border-top-6-outset-alerte_jaune_attention {
  border-top: 6px outset var(--alerte_jaune_attention);
}

.border-top-6-none-alerte_jaune_attention {
  border-top: 6px none var(--alerte_jaune_attention);
}

.border-top-6-hidden-alerte_jaune_attention {
  border-top: 6px hidden var(--alerte_jaune_attention);
}

.border-top-6-solid-blanc {
  border-top: 6px solid #fff;
}

.border-top-6-dashed-blanc {
  border-top: 6px dashed #fff;
}

.border-top-6-dotted-blanc {
  border-top: 6px dotted #fff;
}

.border-top-6-double-blanc {
  border-top: 6px double #fff;
}

.border-top-6-groove-blanc {
  border-top: 6px groove #fff;
}

.border-top-6-ridge-blanc {
  border-top: 6px ridge #fff;
}

.border-top-6-inset-blanc {
  border-top: 6px inset #fff;
}

.border-top-6-outset-blanc {
  border-top: 6px outset #fff;
}

.border-top-6-none-blanc {
  border-top: 6px #fff;
}

.border-top-6-hidden-blanc {
  border-top: 6px hidden #fff;
}

.border-top-6-solid-noir {
  border-top: 6px solid #222;
}

.border-top-6-dashed-noir {
  border-top: 6px dashed #222;
}

.border-top-6-dotted-noir {
  border-top: 6px dotted #222;
}

.border-top-6-double-noir {
  border-top: 6px double #222;
}

.border-top-6-groove-noir {
  border-top: 6px groove #222;
}

.border-top-6-ridge-noir {
  border-top: 6px ridge #222;
}

.border-top-6-inset-noir {
  border-top: 6px inset #222;
}

.border-top-6-outset-noir {
  border-top: 6px outset #222;
}

.border-top-6-none-noir {
  border-top: 6px #222;
}

.border-top-6-hidden-noir {
  border-top: 6px hidden #222;
}

.border-top-6-solid-gris_light {
  border-top: 6px solid var(--gris_light);
}

.border-top-6-dashed-gris_light {
  border-top: 6px dashed var(--gris_light);
}

.border-top-6-dotted-gris_light {
  border-top: 6px dotted var(--gris_light);
}

.border-top-6-double-gris_light {
  border-top: 6px double var(--gris_light);
}

.border-top-6-groove-gris_light {
  border-top: 6px groove var(--gris_light);
}

.border-top-6-ridge-gris_light {
  border-top: 6px ridge var(--gris_light);
}

.border-top-6-inset-gris_light {
  border-top: 6px inset var(--gris_light);
}

.border-top-6-outset-gris_light {
  border-top: 6px outset var(--gris_light);
}

.border-top-6-none-gris_light {
  border-top: 6px none var(--gris_light);
}

.border-top-6-hidden-gris_light {
  border-top: 6px hidden var(--gris_light);
}

.border-top-6-solid-gris_dark {
  border-top: 6px solid var(--gris_dark);
}

.border-top-6-dashed-gris_dark {
  border-top: 6px dashed var(--gris_dark);
}

.border-top-6-dotted-gris_dark {
  border-top: 6px dotted var(--gris_dark);
}

.border-top-6-double-gris_dark {
  border-top: 6px double var(--gris_dark);
}

.border-top-6-groove-gris_dark {
  border-top: 6px groove var(--gris_dark);
}

.border-top-6-ridge-gris_dark {
  border-top: 6px ridge var(--gris_dark);
}

.border-top-6-inset-gris_dark {
  border-top: 6px inset var(--gris_dark);
}

.border-top-6-outset-gris_dark {
  border-top: 6px outset var(--gris_dark);
}

.border-top-6-none-gris_dark {
  border-top: 6px none var(--gris_dark);
}

.border-top-6-hidden-gris_dark {
  border-top: 6px hidden var(--gris_dark);
}

.border-top-6-solid-gris_background {
  border-top: 6px solid var(--gris_background);
}

.border-top-6-dashed-gris_background {
  border-top: 6px dashed var(--gris_background);
}

.border-top-6-dotted-gris_background {
  border-top: 6px dotted var(--gris_background);
}

.border-top-6-double-gris_background {
  border-top: 6px double var(--gris_background);
}

.border-top-6-groove-gris_background {
  border-top: 6px groove var(--gris_background);
}

.border-top-6-ridge-gris_background {
  border-top: 6px ridge var(--gris_background);
}

.border-top-6-inset-gris_background {
  border-top: 6px inset var(--gris_background);
}

.border-top-6-outset-gris_background {
  border-top: 6px outset var(--gris_background);
}

.border-top-6-none-gris_background {
  border-top: 6px none var(--gris_background);
}

.border-top-6-hidden-gris_background {
  border-top: 6px hidden var(--gris_background);
}

.border-bottom-6-solid-corail_mh_brand {
  border-bottom: 6px solid var(--corail_mh_brand);
}

.border-bottom-6-dashed-corail_mh_brand {
  border-bottom: 6px dashed var(--corail_mh_brand);
}

.border-bottom-6-dotted-corail_mh_brand {
  border-bottom: 6px dotted var(--corail_mh_brand);
}

.border-bottom-6-double-corail_mh_brand {
  border-bottom: 6px double var(--corail_mh_brand);
}

.border-bottom-6-groove-corail_mh_brand {
  border-bottom: 6px groove var(--corail_mh_brand);
}

.border-bottom-6-ridge-corail_mh_brand {
  border-bottom: 6px ridge var(--corail_mh_brand);
}

.border-bottom-6-inset-corail_mh_brand {
  border-bottom: 6px inset var(--corail_mh_brand);
}

.border-bottom-6-outset-corail_mh_brand {
  border-bottom: 6px outset var(--corail_mh_brand);
}

.border-bottom-6-none-corail_mh_brand {
  border-bottom: 6px none var(--corail_mh_brand);
}

.border-bottom-6-hidden-corail_mh_brand {
  border-bottom: 6px hidden var(--corail_mh_brand);
}

.border-bottom-6-solid-corail_mh_dark {
  border-bottom: 6px solid var(--corail_mh_dark);
}

.border-bottom-6-dashed-corail_mh_dark {
  border-bottom: 6px dashed var(--corail_mh_dark);
}

.border-bottom-6-dotted-corail_mh_dark {
  border-bottom: 6px dotted var(--corail_mh_dark);
}

.border-bottom-6-double-corail_mh_dark {
  border-bottom: 6px double var(--corail_mh_dark);
}

.border-bottom-6-groove-corail_mh_dark {
  border-bottom: 6px groove var(--corail_mh_dark);
}

.border-bottom-6-ridge-corail_mh_dark {
  border-bottom: 6px ridge var(--corail_mh_dark);
}

.border-bottom-6-inset-corail_mh_dark {
  border-bottom: 6px inset var(--corail_mh_dark);
}

.border-bottom-6-outset-corail_mh_dark {
  border-bottom: 6px outset var(--corail_mh_dark);
}

.border-bottom-6-none-corail_mh_dark {
  border-bottom: 6px none var(--corail_mh_dark);
}

.border-bottom-6-hidden-corail_mh_dark {
  border-bottom: 6px hidden var(--corail_mh_dark);
}

.border-bottom-6-solid-corail_mh_light {
  border-bottom: 6px solid var(--corail_mh_light);
}

.border-bottom-6-dashed-corail_mh_light {
  border-bottom: 6px dashed var(--corail_mh_light);
}

.border-bottom-6-dotted-corail_mh_light {
  border-bottom: 6px dotted var(--corail_mh_light);
}

.border-bottom-6-double-corail_mh_light {
  border-bottom: 6px double var(--corail_mh_light);
}

.border-bottom-6-groove-corail_mh_light {
  border-bottom: 6px groove var(--corail_mh_light);
}

.border-bottom-6-ridge-corail_mh_light {
  border-bottom: 6px ridge var(--corail_mh_light);
}

.border-bottom-6-inset-corail_mh_light {
  border-bottom: 6px inset var(--corail_mh_light);
}

.border-bottom-6-outset-corail_mh_light {
  border-bottom: 6px outset var(--corail_mh_light);
}

.border-bottom-6-none-corail_mh_light {
  border-bottom: 6px none var(--corail_mh_light);
}

.border-bottom-6-hidden-corail_mh_light {
  border-bottom: 6px hidden var(--corail_mh_light);
}

.border-bottom-6-solid-gris_sable_light {
  border-bottom: 6px solid var(--gris_sable_light);
}

.border-bottom-6-dashed-gris_sable_light {
  border-bottom: 6px dashed var(--gris_sable_light);
}

.border-bottom-6-dotted-gris_sable_light {
  border-bottom: 6px dotted var(--gris_sable_light);
}

.border-bottom-6-double-gris_sable_light {
  border-bottom: 6px double var(--gris_sable_light);
}

.border-bottom-6-groove-gris_sable_light {
  border-bottom: 6px groove var(--gris_sable_light);
}

.border-bottom-6-ridge-gris_sable_light {
  border-bottom: 6px ridge var(--gris_sable_light);
}

.border-bottom-6-inset-gris_sable_light {
  border-bottom: 6px inset var(--gris_sable_light);
}

.border-bottom-6-outset-gris_sable_light {
  border-bottom: 6px outset var(--gris_sable_light);
}

.border-bottom-6-none-gris_sable_light {
  border-bottom: 6px none var(--gris_sable_light);
}

.border-bottom-6-hidden-gris_sable_light {
  border-bottom: 6px hidden var(--gris_sable_light);
}

.border-bottom-6-solid-gris_sable_dark {
  border-bottom: 6px solid var(--gris_sable_dark);
}

.border-bottom-6-dashed-gris_sable_dark {
  border-bottom: 6px dashed var(--gris_sable_dark);
}

.border-bottom-6-dotted-gris_sable_dark {
  border-bottom: 6px dotted var(--gris_sable_dark);
}

.border-bottom-6-double-gris_sable_dark {
  border-bottom: 6px double var(--gris_sable_dark);
}

.border-bottom-6-groove-gris_sable_dark {
  border-bottom: 6px groove var(--gris_sable_dark);
}

.border-bottom-6-ridge-gris_sable_dark {
  border-bottom: 6px ridge var(--gris_sable_dark);
}

.border-bottom-6-inset-gris_sable_dark {
  border-bottom: 6px inset var(--gris_sable_dark);
}

.border-bottom-6-outset-gris_sable_dark {
  border-bottom: 6px outset var(--gris_sable_dark);
}

.border-bottom-6-none-gris_sable_dark {
  border-bottom: 6px none var(--gris_sable_dark);
}

.border-bottom-6-hidden-gris_sable_dark {
  border-bottom: 6px hidden var(--gris_sable_dark);
}

.border-bottom-6-solid-jaune_ivoire_light {
  border-bottom: 6px solid var(--jaune_ivoire_light);
}

.border-bottom-6-dashed-jaune_ivoire_light {
  border-bottom: 6px dashed var(--jaune_ivoire_light);
}

.border-bottom-6-dotted-jaune_ivoire_light {
  border-bottom: 6px dotted var(--jaune_ivoire_light);
}

.border-bottom-6-double-jaune_ivoire_light {
  border-bottom: 6px double var(--jaune_ivoire_light);
}

.border-bottom-6-groove-jaune_ivoire_light {
  border-bottom: 6px groove var(--jaune_ivoire_light);
}

.border-bottom-6-ridge-jaune_ivoire_light {
  border-bottom: 6px ridge var(--jaune_ivoire_light);
}

.border-bottom-6-inset-jaune_ivoire_light {
  border-bottom: 6px inset var(--jaune_ivoire_light);
}

.border-bottom-6-outset-jaune_ivoire_light {
  border-bottom: 6px outset var(--jaune_ivoire_light);
}

.border-bottom-6-none-jaune_ivoire_light {
  border-bottom: 6px none var(--jaune_ivoire_light);
}

.border-bottom-6-hidden-jaune_ivoire_light {
  border-bottom: 6px hidden var(--jaune_ivoire_light);
}

.border-bottom-6-solid-jaune_ivoire_dark {
  border-bottom: 6px solid var(--jaune_ivoire_dark);
}

.border-bottom-6-dashed-jaune_ivoire_dark {
  border-bottom: 6px dashed var(--jaune_ivoire_dark);
}

.border-bottom-6-dotted-jaune_ivoire_dark {
  border-bottom: 6px dotted var(--jaune_ivoire_dark);
}

.border-bottom-6-double-jaune_ivoire_dark {
  border-bottom: 6px double var(--jaune_ivoire_dark);
}

.border-bottom-6-groove-jaune_ivoire_dark {
  border-bottom: 6px groove var(--jaune_ivoire_dark);
}

.border-bottom-6-ridge-jaune_ivoire_dark {
  border-bottom: 6px ridge var(--jaune_ivoire_dark);
}

.border-bottom-6-inset-jaune_ivoire_dark {
  border-bottom: 6px inset var(--jaune_ivoire_dark);
}

.border-bottom-6-outset-jaune_ivoire_dark {
  border-bottom: 6px outset var(--jaune_ivoire_dark);
}

.border-bottom-6-none-jaune_ivoire_dark {
  border-bottom: 6px none var(--jaune_ivoire_dark);
}

.border-bottom-6-hidden-jaune_ivoire_dark {
  border-bottom: 6px hidden var(--jaune_ivoire_dark);
}

.border-bottom-6-solid-jaune_light {
  border-bottom: 6px solid var(--jaune_light);
}

.border-bottom-6-dashed-jaune_light {
  border-bottom: 6px dashed var(--jaune_light);
}

.border-bottom-6-dotted-jaune_light {
  border-bottom: 6px dotted var(--jaune_light);
}

.border-bottom-6-double-jaune_light {
  border-bottom: 6px double var(--jaune_light);
}

.border-bottom-6-groove-jaune_light {
  border-bottom: 6px groove var(--jaune_light);
}

.border-bottom-6-ridge-jaune_light {
  border-bottom: 6px ridge var(--jaune_light);
}

.border-bottom-6-inset-jaune_light {
  border-bottom: 6px inset var(--jaune_light);
}

.border-bottom-6-outset-jaune_light {
  border-bottom: 6px outset var(--jaune_light);
}

.border-bottom-6-none-jaune_light {
  border-bottom: 6px none var(--jaune_light);
}

.border-bottom-6-hidden-jaune_light {
  border-bottom: 6px hidden var(--jaune_light);
}

.border-bottom-6-solid-jaune_dark {
  border-bottom: 6px solid var(--jaune_dark);
}

.border-bottom-6-dashed-jaune_dark {
  border-bottom: 6px dashed var(--jaune_dark);
}

.border-bottom-6-dotted-jaune_dark {
  border-bottom: 6px dotted var(--jaune_dark);
}

.border-bottom-6-double-jaune_dark {
  border-bottom: 6px double var(--jaune_dark);
}

.border-bottom-6-groove-jaune_dark {
  border-bottom: 6px groove var(--jaune_dark);
}

.border-bottom-6-ridge-jaune_dark {
  border-bottom: 6px ridge var(--jaune_dark);
}

.border-bottom-6-inset-jaune_dark {
  border-bottom: 6px inset var(--jaune_dark);
}

.border-bottom-6-outset-jaune_dark {
  border-bottom: 6px outset var(--jaune_dark);
}

.border-bottom-6-none-jaune_dark {
  border-bottom: 6px none var(--jaune_dark);
}

.border-bottom-6-hidden-jaune_dark {
  border-bottom: 6px hidden var(--jaune_dark);
}

.border-bottom-6-solid-orange_normal {
  border-bottom: 6px solid var(--orange_normal);
}

.border-bottom-6-dashed-orange_normal {
  border-bottom: 6px dashed var(--orange_normal);
}

.border-bottom-6-dotted-orange_normal {
  border-bottom: 6px dotted var(--orange_normal);
}

.border-bottom-6-double-orange_normal {
  border-bottom: 6px double var(--orange_normal);
}

.border-bottom-6-groove-orange_normal {
  border-bottom: 6px groove var(--orange_normal);
}

.border-bottom-6-ridge-orange_normal {
  border-bottom: 6px ridge var(--orange_normal);
}

.border-bottom-6-inset-orange_normal {
  border-bottom: 6px inset var(--orange_normal);
}

.border-bottom-6-outset-orange_normal {
  border-bottom: 6px outset var(--orange_normal);
}

.border-bottom-6-none-orange_normal {
  border-bottom: 6px none var(--orange_normal);
}

.border-bottom-6-hidden-orange_normal {
  border-bottom: 6px hidden var(--orange_normal);
}

.border-bottom-6-solid-orange_dark {
  border-bottom: 6px solid var(--orange_dark);
}

.border-bottom-6-dashed-orange_dark {
  border-bottom: 6px dashed var(--orange_dark);
}

.border-bottom-6-dotted-orange_dark {
  border-bottom: 6px dotted var(--orange_dark);
}

.border-bottom-6-double-orange_dark {
  border-bottom: 6px double var(--orange_dark);
}

.border-bottom-6-groove-orange_dark {
  border-bottom: 6px groove var(--orange_dark);
}

.border-bottom-6-ridge-orange_dark {
  border-bottom: 6px ridge var(--orange_dark);
}

.border-bottom-6-inset-orange_dark {
  border-bottom: 6px inset var(--orange_dark);
}

.border-bottom-6-outset-orange_dark {
  border-bottom: 6px outset var(--orange_dark);
}

.border-bottom-6-none-orange_dark {
  border-bottom: 6px none var(--orange_dark);
}

.border-bottom-6-hidden-orange_dark {
  border-bottom: 6px hidden var(--orange_dark);
}

.border-bottom-6-solid-vert_light {
  border-bottom: 6px solid var(--vert_light);
}

.border-bottom-6-dashed-vert_light {
  border-bottom: 6px dashed var(--vert_light);
}

.border-bottom-6-dotted-vert_light {
  border-bottom: 6px dotted var(--vert_light);
}

.border-bottom-6-double-vert_light {
  border-bottom: 6px double var(--vert_light);
}

.border-bottom-6-groove-vert_light {
  border-bottom: 6px groove var(--vert_light);
}

.border-bottom-6-ridge-vert_light {
  border-bottom: 6px ridge var(--vert_light);
}

.border-bottom-6-inset-vert_light {
  border-bottom: 6px inset var(--vert_light);
}

.border-bottom-6-outset-vert_light {
  border-bottom: 6px outset var(--vert_light);
}

.border-bottom-6-none-vert_light {
  border-bottom: 6px none var(--vert_light);
}

.border-bottom-6-hidden-vert_light {
  border-bottom: 6px hidden var(--vert_light);
}

.border-bottom-6-solid-vert_dark {
  border-bottom: 6px solid var(--vert_dark);
}

.border-bottom-6-dashed-vert_dark {
  border-bottom: 6px dashed var(--vert_dark);
}

.border-bottom-6-dotted-vert_dark {
  border-bottom: 6px dotted var(--vert_dark);
}

.border-bottom-6-double-vert_dark {
  border-bottom: 6px double var(--vert_dark);
}

.border-bottom-6-groove-vert_dark {
  border-bottom: 6px groove var(--vert_dark);
}

.border-bottom-6-ridge-vert_dark {
  border-bottom: 6px ridge var(--vert_dark);
}

.border-bottom-6-inset-vert_dark {
  border-bottom: 6px inset var(--vert_dark);
}

.border-bottom-6-outset-vert_dark {
  border-bottom: 6px outset var(--vert_dark);
}

.border-bottom-6-none-vert_dark {
  border-bottom: 6px none var(--vert_dark);
}

.border-bottom-6-hidden-vert_dark {
  border-bottom: 6px hidden var(--vert_dark);
}

.border-bottom-6-solid-bleu_turquoise_light {
  border-bottom: 6px solid var(--bleu_turquoise_light);
}

.border-bottom-6-dashed-bleu_turquoise_light {
  border-bottom: 6px dashed var(--bleu_turquoise_light);
}

.border-bottom-6-dotted-bleu_turquoise_light {
  border-bottom: 6px dotted var(--bleu_turquoise_light);
}

.border-bottom-6-double-bleu_turquoise_light {
  border-bottom: 6px double var(--bleu_turquoise_light);
}

.border-bottom-6-groove-bleu_turquoise_light {
  border-bottom: 6px groove var(--bleu_turquoise_light);
}

.border-bottom-6-ridge-bleu_turquoise_light {
  border-bottom: 6px ridge var(--bleu_turquoise_light);
}

.border-bottom-6-inset-bleu_turquoise_light {
  border-bottom: 6px inset var(--bleu_turquoise_light);
}

.border-bottom-6-outset-bleu_turquoise_light {
  border-bottom: 6px outset var(--bleu_turquoise_light);
}

.border-bottom-6-none-bleu_turquoise_light {
  border-bottom: 6px none var(--bleu_turquoise_light);
}

.border-bottom-6-hidden-bleu_turquoise_light {
  border-bottom: 6px hidden var(--bleu_turquoise_light);
}

.border-bottom-6-solid-bleu_turquoise_middle {
  border-bottom: 6px solid var(--bleu_turquoise_middle);
}

.border-bottom-6-dashed-bleu_turquoise_middle {
  border-bottom: 6px dashed var(--bleu_turquoise_middle);
}

.border-bottom-6-dotted-bleu_turquoise_middle {
  border-bottom: 6px dotted var(--bleu_turquoise_middle);
}

.border-bottom-6-double-bleu_turquoise_middle {
  border-bottom: 6px double var(--bleu_turquoise_middle);
}

.border-bottom-6-groove-bleu_turquoise_middle {
  border-bottom: 6px groove var(--bleu_turquoise_middle);
}

.border-bottom-6-ridge-bleu_turquoise_middle {
  border-bottom: 6px ridge var(--bleu_turquoise_middle);
}

.border-bottom-6-inset-bleu_turquoise_middle {
  border-bottom: 6px inset var(--bleu_turquoise_middle);
}

.border-bottom-6-outset-bleu_turquoise_middle {
  border-bottom: 6px outset var(--bleu_turquoise_middle);
}

.border-bottom-6-none-bleu_turquoise_middle {
  border-bottom: 6px none var(--bleu_turquoise_middle);
}

.border-bottom-6-hidden-bleu_turquoise_middle {
  border-bottom: 6px hidden var(--bleu_turquoise_middle);
}

.border-bottom-6-solid-bleu_turquoise_dark {
  border-bottom: 6px solid var(--bleu_turquoise_dark);
}

.border-bottom-6-dashed-bleu_turquoise_dark {
  border-bottom: 6px dashed var(--bleu_turquoise_dark);
}

.border-bottom-6-dotted-bleu_turquoise_dark {
  border-bottom: 6px dotted var(--bleu_turquoise_dark);
}

.border-bottom-6-double-bleu_turquoise_dark {
  border-bottom: 6px double var(--bleu_turquoise_dark);
}

.border-bottom-6-groove-bleu_turquoise_dark {
  border-bottom: 6px groove var(--bleu_turquoise_dark);
}

.border-bottom-6-ridge-bleu_turquoise_dark {
  border-bottom: 6px ridge var(--bleu_turquoise_dark);
}

.border-bottom-6-inset-bleu_turquoise_dark {
  border-bottom: 6px inset var(--bleu_turquoise_dark);
}

.border-bottom-6-outset-bleu_turquoise_dark {
  border-bottom: 6px outset var(--bleu_turquoise_dark);
}

.border-bottom-6-none-bleu_turquoise_dark {
  border-bottom: 6px none var(--bleu_turquoise_dark);
}

.border-bottom-6-hidden-bleu_turquoise_dark {
  border-bottom: 6px hidden var(--bleu_turquoise_dark);
}

.border-bottom-6-solid-bleu_light {
  border-bottom: 6px solid var(--bleu_light);
}

.border-bottom-6-dashed-bleu_light {
  border-bottom: 6px dashed var(--bleu_light);
}

.border-bottom-6-dotted-bleu_light {
  border-bottom: 6px dotted var(--bleu_light);
}

.border-bottom-6-double-bleu_light {
  border-bottom: 6px double var(--bleu_light);
}

.border-bottom-6-groove-bleu_light {
  border-bottom: 6px groove var(--bleu_light);
}

.border-bottom-6-ridge-bleu_light {
  border-bottom: 6px ridge var(--bleu_light);
}

.border-bottom-6-inset-bleu_light {
  border-bottom: 6px inset var(--bleu_light);
}

.border-bottom-6-outset-bleu_light {
  border-bottom: 6px outset var(--bleu_light);
}

.border-bottom-6-none-bleu_light {
  border-bottom: 6px none var(--bleu_light);
}

.border-bottom-6-hidden-bleu_light {
  border-bottom: 6px hidden var(--bleu_light);
}

.border-bottom-6-solid-bleu_dark {
  border-bottom: 6px solid var(--bleu_dark);
}

.border-bottom-6-dashed-bleu_dark {
  border-bottom: 6px dashed var(--bleu_dark);
}

.border-bottom-6-dotted-bleu_dark {
  border-bottom: 6px dotted var(--bleu_dark);
}

.border-bottom-6-double-bleu_dark {
  border-bottom: 6px double var(--bleu_dark);
}

.border-bottom-6-groove-bleu_dark {
  border-bottom: 6px groove var(--bleu_dark);
}

.border-bottom-6-ridge-bleu_dark {
  border-bottom: 6px ridge var(--bleu_dark);
}

.border-bottom-6-inset-bleu_dark {
  border-bottom: 6px inset var(--bleu_dark);
}

.border-bottom-6-outset-bleu_dark {
  border-bottom: 6px outset var(--bleu_dark);
}

.border-bottom-6-none-bleu_dark {
  border-bottom: 6px none var(--bleu_dark);
}

.border-bottom-6-hidden-bleu_dark {
  border-bottom: 6px hidden var(--bleu_dark);
}

.border-bottom-6-solid-bleu_klein_light {
  border-bottom: 6px solid var(--bleu_klein_light);
}

.border-bottom-6-dashed-bleu_klein_light {
  border-bottom: 6px dashed var(--bleu_klein_light);
}

.border-bottom-6-dotted-bleu_klein_light {
  border-bottom: 6px dotted var(--bleu_klein_light);
}

.border-bottom-6-double-bleu_klein_light {
  border-bottom: 6px double var(--bleu_klein_light);
}

.border-bottom-6-groove-bleu_klein_light {
  border-bottom: 6px groove var(--bleu_klein_light);
}

.border-bottom-6-ridge-bleu_klein_light {
  border-bottom: 6px ridge var(--bleu_klein_light);
}

.border-bottom-6-inset-bleu_klein_light {
  border-bottom: 6px inset var(--bleu_klein_light);
}

.border-bottom-6-outset-bleu_klein_light {
  border-bottom: 6px outset var(--bleu_klein_light);
}

.border-bottom-6-none-bleu_klein_light {
  border-bottom: 6px none var(--bleu_klein_light);
}

.border-bottom-6-hidden-bleu_klein_light {
  border-bottom: 6px hidden var(--bleu_klein_light);
}

.border-bottom-6-solid-bleu_klein_dark {
  border-bottom: 6px solid var(--bleu_klein_dark);
}

.border-bottom-6-dashed-bleu_klein_dark {
  border-bottom: 6px dashed var(--bleu_klein_dark);
}

.border-bottom-6-dotted-bleu_klein_dark {
  border-bottom: 6px dotted var(--bleu_klein_dark);
}

.border-bottom-6-double-bleu_klein_dark {
  border-bottom: 6px double var(--bleu_klein_dark);
}

.border-bottom-6-groove-bleu_klein_dark {
  border-bottom: 6px groove var(--bleu_klein_dark);
}

.border-bottom-6-ridge-bleu_klein_dark {
  border-bottom: 6px ridge var(--bleu_klein_dark);
}

.border-bottom-6-inset-bleu_klein_dark {
  border-bottom: 6px inset var(--bleu_klein_dark);
}

.border-bottom-6-outset-bleu_klein_dark {
  border-bottom: 6px outset var(--bleu_klein_dark);
}

.border-bottom-6-none-bleu_klein_dark {
  border-bottom: 6px none var(--bleu_klein_dark);
}

.border-bottom-6-hidden-bleu_klein_dark {
  border-bottom: 6px hidden var(--bleu_klein_dark);
}

.border-bottom-6-solid-violet_light {
  border-bottom: 6px solid var(--violet_light);
}

.border-bottom-6-dashed-violet_light {
  border-bottom: 6px dashed var(--violet_light);
}

.border-bottom-6-dotted-violet_light {
  border-bottom: 6px dotted var(--violet_light);
}

.border-bottom-6-double-violet_light {
  border-bottom: 6px double var(--violet_light);
}

.border-bottom-6-groove-violet_light {
  border-bottom: 6px groove var(--violet_light);
}

.border-bottom-6-ridge-violet_light {
  border-bottom: 6px ridge var(--violet_light);
}

.border-bottom-6-inset-violet_light {
  border-bottom: 6px inset var(--violet_light);
}

.border-bottom-6-outset-violet_light {
  border-bottom: 6px outset var(--violet_light);
}

.border-bottom-6-none-violet_light {
  border-bottom: 6px none var(--violet_light);
}

.border-bottom-6-hidden-violet_light {
  border-bottom: 6px hidden var(--violet_light);
}

.border-bottom-6-solid-violet_dark {
  border-bottom: 6px solid var(--violet_dark);
}

.border-bottom-6-dashed-violet_dark {
  border-bottom: 6px dashed var(--violet_dark);
}

.border-bottom-6-dotted-violet_dark {
  border-bottom: 6px dotted var(--violet_dark);
}

.border-bottom-6-double-violet_dark {
  border-bottom: 6px double var(--violet_dark);
}

.border-bottom-6-groove-violet_dark {
  border-bottom: 6px groove var(--violet_dark);
}

.border-bottom-6-ridge-violet_dark {
  border-bottom: 6px ridge var(--violet_dark);
}

.border-bottom-6-inset-violet_dark {
  border-bottom: 6px inset var(--violet_dark);
}

.border-bottom-6-outset-violet_dark {
  border-bottom: 6px outset var(--violet_dark);
}

.border-bottom-6-none-violet_dark {
  border-bottom: 6px none var(--violet_dark);
}

.border-bottom-6-hidden-violet_dark {
  border-bottom: 6px hidden var(--violet_dark);
}

.border-bottom-6-solid-mauve {
  border-bottom: 6px solid var(--mauve);
}

.border-bottom-6-dashed-mauve {
  border-bottom: 6px dashed var(--mauve);
}

.border-bottom-6-dotted-mauve {
  border-bottom: 6px dotted var(--mauve);
}

.border-bottom-6-double-mauve {
  border-bottom: 6px double var(--mauve);
}

.border-bottom-6-groove-mauve {
  border-bottom: 6px groove var(--mauve);
}

.border-bottom-6-ridge-mauve {
  border-bottom: 6px ridge var(--mauve);
}

.border-bottom-6-inset-mauve {
  border-bottom: 6px inset var(--mauve);
}

.border-bottom-6-outset-mauve {
  border-bottom: 6px outset var(--mauve);
}

.border-bottom-6-none-mauve {
  border-bottom: 6px none var(--mauve);
}

.border-bottom-6-hidden-mauve {
  border-bottom: 6px hidden var(--mauve);
}

.border-bottom-6-solid-rose_light {
  border-bottom: 6px solid var(--rose_light);
}

.border-bottom-6-dashed-rose_light {
  border-bottom: 6px dashed var(--rose_light);
}

.border-bottom-6-dotted-rose_light {
  border-bottom: 6px dotted var(--rose_light);
}

.border-bottom-6-double-rose_light {
  border-bottom: 6px double var(--rose_light);
}

.border-bottom-6-groove-rose_light {
  border-bottom: 6px groove var(--rose_light);
}

.border-bottom-6-ridge-rose_light {
  border-bottom: 6px ridge var(--rose_light);
}

.border-bottom-6-inset-rose_light {
  border-bottom: 6px inset var(--rose_light);
}

.border-bottom-6-outset-rose_light {
  border-bottom: 6px outset var(--rose_light);
}

.border-bottom-6-none-rose_light {
  border-bottom: 6px none var(--rose_light);
}

.border-bottom-6-hidden-rose_light {
  border-bottom: 6px hidden var(--rose_light);
}

.border-bottom-6-solid-rose_dark {
  border-bottom: 6px solid var(--rose_dark);
}

.border-bottom-6-dashed-rose_dark {
  border-bottom: 6px dashed var(--rose_dark);
}

.border-bottom-6-dotted-rose_dark {
  border-bottom: 6px dotted var(--rose_dark);
}

.border-bottom-6-double-rose_dark {
  border-bottom: 6px double var(--rose_dark);
}

.border-bottom-6-groove-rose_dark {
  border-bottom: 6px groove var(--rose_dark);
}

.border-bottom-6-ridge-rose_dark {
  border-bottom: 6px ridge var(--rose_dark);
}

.border-bottom-6-inset-rose_dark {
  border-bottom: 6px inset var(--rose_dark);
}

.border-bottom-6-outset-rose_dark {
  border-bottom: 6px outset var(--rose_dark);
}

.border-bottom-6-none-rose_dark {
  border-bottom: 6px none var(--rose_dark);
}

.border-bottom-6-hidden-rose_dark {
  border-bottom: 6px hidden var(--rose_dark);
}

.border-bottom-6-solid-rose_fushia {
  border-bottom: 6px solid var(--rose_fushia);
}

.border-bottom-6-dashed-rose_fushia {
  border-bottom: 6px dashed var(--rose_fushia);
}

.border-bottom-6-dotted-rose_fushia {
  border-bottom: 6px dotted var(--rose_fushia);
}

.border-bottom-6-double-rose_fushia {
  border-bottom: 6px double var(--rose_fushia);
}

.border-bottom-6-groove-rose_fushia {
  border-bottom: 6px groove var(--rose_fushia);
}

.border-bottom-6-ridge-rose_fushia {
  border-bottom: 6px ridge var(--rose_fushia);
}

.border-bottom-6-inset-rose_fushia {
  border-bottom: 6px inset var(--rose_fushia);
}

.border-bottom-6-outset-rose_fushia {
  border-bottom: 6px outset var(--rose_fushia);
}

.border-bottom-6-none-rose_fushia {
  border-bottom: 6px none var(--rose_fushia);
}

.border-bottom-6-hidden-rose_fushia {
  border-bottom: 6px hidden var(--rose_fushia);
}

.border-bottom-6-solid-rouge_framboise {
  border-bottom: 6px solid var(--rouge_framboise);
}

.border-bottom-6-dashed-rouge_framboise {
  border-bottom: 6px dashed var(--rouge_framboise);
}

.border-bottom-6-dotted-rouge_framboise {
  border-bottom: 6px dotted var(--rouge_framboise);
}

.border-bottom-6-double-rouge_framboise {
  border-bottom: 6px double var(--rouge_framboise);
}

.border-bottom-6-groove-rouge_framboise {
  border-bottom: 6px groove var(--rouge_framboise);
}

.border-bottom-6-ridge-rouge_framboise {
  border-bottom: 6px ridge var(--rouge_framboise);
}

.border-bottom-6-inset-rouge_framboise {
  border-bottom: 6px inset var(--rouge_framboise);
}

.border-bottom-6-outset-rouge_framboise {
  border-bottom: 6px outset var(--rouge_framboise);
}

.border-bottom-6-none-rouge_framboise {
  border-bottom: 6px none var(--rouge_framboise);
}

.border-bottom-6-hidden-rouge_framboise {
  border-bottom: 6px hidden var(--rouge_framboise);
}

.border-bottom-6-solid-rose_chair_light {
  border-bottom: 6px solid var(--rose_chair_light);
}

.border-bottom-6-dashed-rose_chair_light {
  border-bottom: 6px dashed var(--rose_chair_light);
}

.border-bottom-6-dotted-rose_chair_light {
  border-bottom: 6px dotted var(--rose_chair_light);
}

.border-bottom-6-double-rose_chair_light {
  border-bottom: 6px double var(--rose_chair_light);
}

.border-bottom-6-groove-rose_chair_light {
  border-bottom: 6px groove var(--rose_chair_light);
}

.border-bottom-6-ridge-rose_chair_light {
  border-bottom: 6px ridge var(--rose_chair_light);
}

.border-bottom-6-inset-rose_chair_light {
  border-bottom: 6px inset var(--rose_chair_light);
}

.border-bottom-6-outset-rose_chair_light {
  border-bottom: 6px outset var(--rose_chair_light);
}

.border-bottom-6-none-rose_chair_light {
  border-bottom: 6px none var(--rose_chair_light);
}

.border-bottom-6-hidden-rose_chair_light {
  border-bottom: 6px hidden var(--rose_chair_light);
}

.border-bottom-6-solid-rose_chair_dark {
  border-bottom: 6px solid var(--rose_chair_dark);
}

.border-bottom-6-dashed-rose_chair_dark {
  border-bottom: 6px dashed var(--rose_chair_dark);
}

.border-bottom-6-dotted-rose_chair_dark {
  border-bottom: 6px dotted var(--rose_chair_dark);
}

.border-bottom-6-double-rose_chair_dark {
  border-bottom: 6px double var(--rose_chair_dark);
}

.border-bottom-6-groove-rose_chair_dark {
  border-bottom: 6px groove var(--rose_chair_dark);
}

.border-bottom-6-ridge-rose_chair_dark {
  border-bottom: 6px ridge var(--rose_chair_dark);
}

.border-bottom-6-inset-rose_chair_dark {
  border-bottom: 6px inset var(--rose_chair_dark);
}

.border-bottom-6-outset-rose_chair_dark {
  border-bottom: 6px outset var(--rose_chair_dark);
}

.border-bottom-6-none-rose_chair_dark {
  border-bottom: 6px none var(--rose_chair_dark);
}

.border-bottom-6-hidden-rose_chair_dark {
  border-bottom: 6px hidden var(--rose_chair_dark);
}

.border-bottom-6-solid-vert_positif {
  border-bottom: 6px solid var(--vert_positif);
}

.border-bottom-6-dashed-vert_positif {
  border-bottom: 6px dashed var(--vert_positif);
}

.border-bottom-6-dotted-vert_positif {
  border-bottom: 6px dotted var(--vert_positif);
}

.border-bottom-6-double-vert_positif {
  border-bottom: 6px double var(--vert_positif);
}

.border-bottom-6-groove-vert_positif {
  border-bottom: 6px groove var(--vert_positif);
}

.border-bottom-6-ridge-vert_positif {
  border-bottom: 6px ridge var(--vert_positif);
}

.border-bottom-6-inset-vert_positif {
  border-bottom: 6px inset var(--vert_positif);
}

.border-bottom-6-outset-vert_positif {
  border-bottom: 6px outset var(--vert_positif);
}

.border-bottom-6-none-vert_positif {
  border-bottom: 6px none var(--vert_positif);
}

.border-bottom-6-hidden-vert_positif {
  border-bottom: 6px hidden var(--vert_positif);
}

.border-bottom-6-solid-rouge_negatif {
  border-bottom: 6px solid var(--rouge_negatif);
}

.border-bottom-6-dashed-rouge_negatif {
  border-bottom: 6px dashed var(--rouge_negatif);
}

.border-bottom-6-dotted-rouge_negatif {
  border-bottom: 6px dotted var(--rouge_negatif);
}

.border-bottom-6-double-rouge_negatif {
  border-bottom: 6px double var(--rouge_negatif);
}

.border-bottom-6-groove-rouge_negatif {
  border-bottom: 6px groove var(--rouge_negatif);
}

.border-bottom-6-ridge-rouge_negatif {
  border-bottom: 6px ridge var(--rouge_negatif);
}

.border-bottom-6-inset-rouge_negatif {
  border-bottom: 6px inset var(--rouge_negatif);
}

.border-bottom-6-outset-rouge_negatif {
  border-bottom: 6px outset var(--rouge_negatif);
}

.border-bottom-6-none-rouge_negatif {
  border-bottom: 6px none var(--rouge_negatif);
}

.border-bottom-6-hidden-rouge_negatif {
  border-bottom: 6px hidden var(--rouge_negatif);
}

.border-bottom-6-solid-bleu_texte {
  border-bottom: 6px solid var(--bleu_texte);
}

.border-bottom-6-dashed-bleu_texte {
  border-bottom: 6px dashed var(--bleu_texte);
}

.border-bottom-6-dotted-bleu_texte {
  border-bottom: 6px dotted var(--bleu_texte);
}

.border-bottom-6-double-bleu_texte {
  border-bottom: 6px double var(--bleu_texte);
}

.border-bottom-6-groove-bleu_texte {
  border-bottom: 6px groove var(--bleu_texte);
}

.border-bottom-6-ridge-bleu_texte {
  border-bottom: 6px ridge var(--bleu_texte);
}

.border-bottom-6-inset-bleu_texte {
  border-bottom: 6px inset var(--bleu_texte);
}

.border-bottom-6-outset-bleu_texte {
  border-bottom: 6px outset var(--bleu_texte);
}

.border-bottom-6-none-bleu_texte {
  border-bottom: 6px none var(--bleu_texte);
}

.border-bottom-6-hidden-bleu_texte {
  border-bottom: 6px hidden var(--bleu_texte);
}

.border-bottom-6-solid-alerte_vert_positif {
  border-bottom: 6px solid var(--alerte_vert_positif);
}

.border-bottom-6-dashed-alerte_vert_positif {
  border-bottom: 6px dashed var(--alerte_vert_positif);
}

.border-bottom-6-dotted-alerte_vert_positif {
  border-bottom: 6px dotted var(--alerte_vert_positif);
}

.border-bottom-6-double-alerte_vert_positif {
  border-bottom: 6px double var(--alerte_vert_positif);
}

.border-bottom-6-groove-alerte_vert_positif {
  border-bottom: 6px groove var(--alerte_vert_positif);
}

.border-bottom-6-ridge-alerte_vert_positif {
  border-bottom: 6px ridge var(--alerte_vert_positif);
}

.border-bottom-6-inset-alerte_vert_positif {
  border-bottom: 6px inset var(--alerte_vert_positif);
}

.border-bottom-6-outset-alerte_vert_positif {
  border-bottom: 6px outset var(--alerte_vert_positif);
}

.border-bottom-6-none-alerte_vert_positif {
  border-bottom: 6px none var(--alerte_vert_positif);
}

.border-bottom-6-hidden-alerte_vert_positif {
  border-bottom: 6px hidden var(--alerte_vert_positif);
}

.border-bottom-6-solid-alerte_rouge_negatif {
  border-bottom: 6px solid var(--alerte_rouge_negatif);
}

.border-bottom-6-dashed-alerte_rouge_negatif {
  border-bottom: 6px dashed var(--alerte_rouge_negatif);
}

.border-bottom-6-dotted-alerte_rouge_negatif {
  border-bottom: 6px dotted var(--alerte_rouge_negatif);
}

.border-bottom-6-double-alerte_rouge_negatif {
  border-bottom: 6px double var(--alerte_rouge_negatif);
}

.border-bottom-6-groove-alerte_rouge_negatif {
  border-bottom: 6px groove var(--alerte_rouge_negatif);
}

.border-bottom-6-ridge-alerte_rouge_negatif {
  border-bottom: 6px ridge var(--alerte_rouge_negatif);
}

.border-bottom-6-inset-alerte_rouge_negatif {
  border-bottom: 6px inset var(--alerte_rouge_negatif);
}

.border-bottom-6-outset-alerte_rouge_negatif {
  border-bottom: 6px outset var(--alerte_rouge_negatif);
}

.border-bottom-6-none-alerte_rouge_negatif {
  border-bottom: 6px none var(--alerte_rouge_negatif);
}

.border-bottom-6-hidden-alerte_rouge_negatif {
  border-bottom: 6px hidden var(--alerte_rouge_negatif);
}

.border-bottom-6-solid-alerte_bleu_information {
  border-bottom: 6px solid var(--alerte_bleu_information);
}

.border-bottom-6-dashed-alerte_bleu_information {
  border-bottom: 6px dashed var(--alerte_bleu_information);
}

.border-bottom-6-dotted-alerte_bleu_information {
  border-bottom: 6px dotted var(--alerte_bleu_information);
}

.border-bottom-6-double-alerte_bleu_information {
  border-bottom: 6px double var(--alerte_bleu_information);
}

.border-bottom-6-groove-alerte_bleu_information {
  border-bottom: 6px groove var(--alerte_bleu_information);
}

.border-bottom-6-ridge-alerte_bleu_information {
  border-bottom: 6px ridge var(--alerte_bleu_information);
}

.border-bottom-6-inset-alerte_bleu_information {
  border-bottom: 6px inset var(--alerte_bleu_information);
}

.border-bottom-6-outset-alerte_bleu_information {
  border-bottom: 6px outset var(--alerte_bleu_information);
}

.border-bottom-6-none-alerte_bleu_information {
  border-bottom: 6px none var(--alerte_bleu_information);
}

.border-bottom-6-hidden-alerte_bleu_information {
  border-bottom: 6px hidden var(--alerte_bleu_information);
}

.border-bottom-6-solid-alerte_jaune_attention {
  border-bottom: 6px solid var(--alerte_jaune_attention);
}

.border-bottom-6-dashed-alerte_jaune_attention {
  border-bottom: 6px dashed var(--alerte_jaune_attention);
}

.border-bottom-6-dotted-alerte_jaune_attention {
  border-bottom: 6px dotted var(--alerte_jaune_attention);
}

.border-bottom-6-double-alerte_jaune_attention {
  border-bottom: 6px double var(--alerte_jaune_attention);
}

.border-bottom-6-groove-alerte_jaune_attention {
  border-bottom: 6px groove var(--alerte_jaune_attention);
}

.border-bottom-6-ridge-alerte_jaune_attention {
  border-bottom: 6px ridge var(--alerte_jaune_attention);
}

.border-bottom-6-inset-alerte_jaune_attention {
  border-bottom: 6px inset var(--alerte_jaune_attention);
}

.border-bottom-6-outset-alerte_jaune_attention {
  border-bottom: 6px outset var(--alerte_jaune_attention);
}

.border-bottom-6-none-alerte_jaune_attention {
  border-bottom: 6px none var(--alerte_jaune_attention);
}

.border-bottom-6-hidden-alerte_jaune_attention {
  border-bottom: 6px hidden var(--alerte_jaune_attention);
}

.border-bottom-6-solid-blanc {
  border-bottom: 6px solid #fff;
}

.border-bottom-6-dashed-blanc {
  border-bottom: 6px dashed #fff;
}

.border-bottom-6-dotted-blanc {
  border-bottom: 6px dotted #fff;
}

.border-bottom-6-double-blanc {
  border-bottom: 6px double #fff;
}

.border-bottom-6-groove-blanc {
  border-bottom: 6px groove #fff;
}

.border-bottom-6-ridge-blanc {
  border-bottom: 6px ridge #fff;
}

.border-bottom-6-inset-blanc {
  border-bottom: 6px inset #fff;
}

.border-bottom-6-outset-blanc {
  border-bottom: 6px outset #fff;
}

.border-bottom-6-none-blanc {
  border-bottom: 6px #fff;
}

.border-bottom-6-hidden-blanc {
  border-bottom: 6px hidden #fff;
}

.border-bottom-6-solid-noir {
  border-bottom: 6px solid #222;
}

.border-bottom-6-dashed-noir {
  border-bottom: 6px dashed #222;
}

.border-bottom-6-dotted-noir {
  border-bottom: 6px dotted #222;
}

.border-bottom-6-double-noir {
  border-bottom: 6px double #222;
}

.border-bottom-6-groove-noir {
  border-bottom: 6px groove #222;
}

.border-bottom-6-ridge-noir {
  border-bottom: 6px ridge #222;
}

.border-bottom-6-inset-noir {
  border-bottom: 6px inset #222;
}

.border-bottom-6-outset-noir {
  border-bottom: 6px outset #222;
}

.border-bottom-6-none-noir {
  border-bottom: 6px #222;
}

.border-bottom-6-hidden-noir {
  border-bottom: 6px hidden #222;
}

.border-bottom-6-solid-gris_light {
  border-bottom: 6px solid var(--gris_light);
}

.border-bottom-6-dashed-gris_light {
  border-bottom: 6px dashed var(--gris_light);
}

.border-bottom-6-dotted-gris_light {
  border-bottom: 6px dotted var(--gris_light);
}

.border-bottom-6-double-gris_light {
  border-bottom: 6px double var(--gris_light);
}

.border-bottom-6-groove-gris_light {
  border-bottom: 6px groove var(--gris_light);
}

.border-bottom-6-ridge-gris_light {
  border-bottom: 6px ridge var(--gris_light);
}

.border-bottom-6-inset-gris_light {
  border-bottom: 6px inset var(--gris_light);
}

.border-bottom-6-outset-gris_light {
  border-bottom: 6px outset var(--gris_light);
}

.border-bottom-6-none-gris_light {
  border-bottom: 6px none var(--gris_light);
}

.border-bottom-6-hidden-gris_light {
  border-bottom: 6px hidden var(--gris_light);
}

.border-bottom-6-solid-gris_dark {
  border-bottom: 6px solid var(--gris_dark);
}

.border-bottom-6-dashed-gris_dark {
  border-bottom: 6px dashed var(--gris_dark);
}

.border-bottom-6-dotted-gris_dark {
  border-bottom: 6px dotted var(--gris_dark);
}

.border-bottom-6-double-gris_dark {
  border-bottom: 6px double var(--gris_dark);
}

.border-bottom-6-groove-gris_dark {
  border-bottom: 6px groove var(--gris_dark);
}

.border-bottom-6-ridge-gris_dark {
  border-bottom: 6px ridge var(--gris_dark);
}

.border-bottom-6-inset-gris_dark {
  border-bottom: 6px inset var(--gris_dark);
}

.border-bottom-6-outset-gris_dark {
  border-bottom: 6px outset var(--gris_dark);
}

.border-bottom-6-none-gris_dark {
  border-bottom: 6px none var(--gris_dark);
}

.border-bottom-6-hidden-gris_dark {
  border-bottom: 6px hidden var(--gris_dark);
}

.border-bottom-6-solid-gris_background {
  border-bottom: 6px solid var(--gris_background);
}

.border-bottom-6-dashed-gris_background {
  border-bottom: 6px dashed var(--gris_background);
}

.border-bottom-6-dotted-gris_background {
  border-bottom: 6px dotted var(--gris_background);
}

.border-bottom-6-double-gris_background {
  border-bottom: 6px double var(--gris_background);
}

.border-bottom-6-groove-gris_background {
  border-bottom: 6px groove var(--gris_background);
}

.border-bottom-6-ridge-gris_background {
  border-bottom: 6px ridge var(--gris_background);
}

.border-bottom-6-inset-gris_background {
  border-bottom: 6px inset var(--gris_background);
}

.border-bottom-6-outset-gris_background {
  border-bottom: 6px outset var(--gris_background);
}

.border-bottom-6-none-gris_background {
  border-bottom: 6px none var(--gris_background);
}

.border-bottom-6-hidden-gris_background {
  border-bottom: 6px hidden var(--gris_background);
}

.border-right-6-solid-corail_mh_brand {
  border-right: 6px solid var(--corail_mh_brand);
}

.border-right-6-dashed-corail_mh_brand {
  border-right: 6px dashed var(--corail_mh_brand);
}

.border-right-6-dotted-corail_mh_brand {
  border-right: 6px dotted var(--corail_mh_brand);
}

.border-right-6-double-corail_mh_brand {
  border-right: 6px double var(--corail_mh_brand);
}

.border-right-6-groove-corail_mh_brand {
  border-right: 6px groove var(--corail_mh_brand);
}

.border-right-6-ridge-corail_mh_brand {
  border-right: 6px ridge var(--corail_mh_brand);
}

.border-right-6-inset-corail_mh_brand {
  border-right: 6px inset var(--corail_mh_brand);
}

.border-right-6-outset-corail_mh_brand {
  border-right: 6px outset var(--corail_mh_brand);
}

.border-right-6-none-corail_mh_brand {
  border-right: 6px none var(--corail_mh_brand);
}

.border-right-6-hidden-corail_mh_brand {
  border-right: 6px hidden var(--corail_mh_brand);
}

.border-right-6-solid-corail_mh_dark {
  border-right: 6px solid var(--corail_mh_dark);
}

.border-right-6-dashed-corail_mh_dark {
  border-right: 6px dashed var(--corail_mh_dark);
}

.border-right-6-dotted-corail_mh_dark {
  border-right: 6px dotted var(--corail_mh_dark);
}

.border-right-6-double-corail_mh_dark {
  border-right: 6px double var(--corail_mh_dark);
}

.border-right-6-groove-corail_mh_dark {
  border-right: 6px groove var(--corail_mh_dark);
}

.border-right-6-ridge-corail_mh_dark {
  border-right: 6px ridge var(--corail_mh_dark);
}

.border-right-6-inset-corail_mh_dark {
  border-right: 6px inset var(--corail_mh_dark);
}

.border-right-6-outset-corail_mh_dark {
  border-right: 6px outset var(--corail_mh_dark);
}

.border-right-6-none-corail_mh_dark {
  border-right: 6px none var(--corail_mh_dark);
}

.border-right-6-hidden-corail_mh_dark {
  border-right: 6px hidden var(--corail_mh_dark);
}

.border-right-6-solid-corail_mh_light {
  border-right: 6px solid var(--corail_mh_light);
}

.border-right-6-dashed-corail_mh_light {
  border-right: 6px dashed var(--corail_mh_light);
}

.border-right-6-dotted-corail_mh_light {
  border-right: 6px dotted var(--corail_mh_light);
}

.border-right-6-double-corail_mh_light {
  border-right: 6px double var(--corail_mh_light);
}

.border-right-6-groove-corail_mh_light {
  border-right: 6px groove var(--corail_mh_light);
}

.border-right-6-ridge-corail_mh_light {
  border-right: 6px ridge var(--corail_mh_light);
}

.border-right-6-inset-corail_mh_light {
  border-right: 6px inset var(--corail_mh_light);
}

.border-right-6-outset-corail_mh_light {
  border-right: 6px outset var(--corail_mh_light);
}

.border-right-6-none-corail_mh_light {
  border-right: 6px none var(--corail_mh_light);
}

.border-right-6-hidden-corail_mh_light {
  border-right: 6px hidden var(--corail_mh_light);
}

.border-right-6-solid-gris_sable_light {
  border-right: 6px solid var(--gris_sable_light);
}

.border-right-6-dashed-gris_sable_light {
  border-right: 6px dashed var(--gris_sable_light);
}

.border-right-6-dotted-gris_sable_light {
  border-right: 6px dotted var(--gris_sable_light);
}

.border-right-6-double-gris_sable_light {
  border-right: 6px double var(--gris_sable_light);
}

.border-right-6-groove-gris_sable_light {
  border-right: 6px groove var(--gris_sable_light);
}

.border-right-6-ridge-gris_sable_light {
  border-right: 6px ridge var(--gris_sable_light);
}

.border-right-6-inset-gris_sable_light {
  border-right: 6px inset var(--gris_sable_light);
}

.border-right-6-outset-gris_sable_light {
  border-right: 6px outset var(--gris_sable_light);
}

.border-right-6-none-gris_sable_light {
  border-right: 6px none var(--gris_sable_light);
}

.border-right-6-hidden-gris_sable_light {
  border-right: 6px hidden var(--gris_sable_light);
}

.border-right-6-solid-gris_sable_dark {
  border-right: 6px solid var(--gris_sable_dark);
}

.border-right-6-dashed-gris_sable_dark {
  border-right: 6px dashed var(--gris_sable_dark);
}

.border-right-6-dotted-gris_sable_dark {
  border-right: 6px dotted var(--gris_sable_dark);
}

.border-right-6-double-gris_sable_dark {
  border-right: 6px double var(--gris_sable_dark);
}

.border-right-6-groove-gris_sable_dark {
  border-right: 6px groove var(--gris_sable_dark);
}

.border-right-6-ridge-gris_sable_dark {
  border-right: 6px ridge var(--gris_sable_dark);
}

.border-right-6-inset-gris_sable_dark {
  border-right: 6px inset var(--gris_sable_dark);
}

.border-right-6-outset-gris_sable_dark {
  border-right: 6px outset var(--gris_sable_dark);
}

.border-right-6-none-gris_sable_dark {
  border-right: 6px none var(--gris_sable_dark);
}

.border-right-6-hidden-gris_sable_dark {
  border-right: 6px hidden var(--gris_sable_dark);
}

.border-right-6-solid-jaune_ivoire_light {
  border-right: 6px solid var(--jaune_ivoire_light);
}

.border-right-6-dashed-jaune_ivoire_light {
  border-right: 6px dashed var(--jaune_ivoire_light);
}

.border-right-6-dotted-jaune_ivoire_light {
  border-right: 6px dotted var(--jaune_ivoire_light);
}

.border-right-6-double-jaune_ivoire_light {
  border-right: 6px double var(--jaune_ivoire_light);
}

.border-right-6-groove-jaune_ivoire_light {
  border-right: 6px groove var(--jaune_ivoire_light);
}

.border-right-6-ridge-jaune_ivoire_light {
  border-right: 6px ridge var(--jaune_ivoire_light);
}

.border-right-6-inset-jaune_ivoire_light {
  border-right: 6px inset var(--jaune_ivoire_light);
}

.border-right-6-outset-jaune_ivoire_light {
  border-right: 6px outset var(--jaune_ivoire_light);
}

.border-right-6-none-jaune_ivoire_light {
  border-right: 6px none var(--jaune_ivoire_light);
}

.border-right-6-hidden-jaune_ivoire_light {
  border-right: 6px hidden var(--jaune_ivoire_light);
}

.border-right-6-solid-jaune_ivoire_dark {
  border-right: 6px solid var(--jaune_ivoire_dark);
}

.border-right-6-dashed-jaune_ivoire_dark {
  border-right: 6px dashed var(--jaune_ivoire_dark);
}

.border-right-6-dotted-jaune_ivoire_dark {
  border-right: 6px dotted var(--jaune_ivoire_dark);
}

.border-right-6-double-jaune_ivoire_dark {
  border-right: 6px double var(--jaune_ivoire_dark);
}

.border-right-6-groove-jaune_ivoire_dark {
  border-right: 6px groove var(--jaune_ivoire_dark);
}

.border-right-6-ridge-jaune_ivoire_dark {
  border-right: 6px ridge var(--jaune_ivoire_dark);
}

.border-right-6-inset-jaune_ivoire_dark {
  border-right: 6px inset var(--jaune_ivoire_dark);
}

.border-right-6-outset-jaune_ivoire_dark {
  border-right: 6px outset var(--jaune_ivoire_dark);
}

.border-right-6-none-jaune_ivoire_dark {
  border-right: 6px none var(--jaune_ivoire_dark);
}

.border-right-6-hidden-jaune_ivoire_dark {
  border-right: 6px hidden var(--jaune_ivoire_dark);
}

.border-right-6-solid-jaune_light {
  border-right: 6px solid var(--jaune_light);
}

.border-right-6-dashed-jaune_light {
  border-right: 6px dashed var(--jaune_light);
}

.border-right-6-dotted-jaune_light {
  border-right: 6px dotted var(--jaune_light);
}

.border-right-6-double-jaune_light {
  border-right: 6px double var(--jaune_light);
}

.border-right-6-groove-jaune_light {
  border-right: 6px groove var(--jaune_light);
}

.border-right-6-ridge-jaune_light {
  border-right: 6px ridge var(--jaune_light);
}

.border-right-6-inset-jaune_light {
  border-right: 6px inset var(--jaune_light);
}

.border-right-6-outset-jaune_light {
  border-right: 6px outset var(--jaune_light);
}

.border-right-6-none-jaune_light {
  border-right: 6px none var(--jaune_light);
}

.border-right-6-hidden-jaune_light {
  border-right: 6px hidden var(--jaune_light);
}

.border-right-6-solid-jaune_dark {
  border-right: 6px solid var(--jaune_dark);
}

.border-right-6-dashed-jaune_dark {
  border-right: 6px dashed var(--jaune_dark);
}

.border-right-6-dotted-jaune_dark {
  border-right: 6px dotted var(--jaune_dark);
}

.border-right-6-double-jaune_dark {
  border-right: 6px double var(--jaune_dark);
}

.border-right-6-groove-jaune_dark {
  border-right: 6px groove var(--jaune_dark);
}

.border-right-6-ridge-jaune_dark {
  border-right: 6px ridge var(--jaune_dark);
}

.border-right-6-inset-jaune_dark {
  border-right: 6px inset var(--jaune_dark);
}

.border-right-6-outset-jaune_dark {
  border-right: 6px outset var(--jaune_dark);
}

.border-right-6-none-jaune_dark {
  border-right: 6px none var(--jaune_dark);
}

.border-right-6-hidden-jaune_dark {
  border-right: 6px hidden var(--jaune_dark);
}

.border-right-6-solid-orange_normal {
  border-right: 6px solid var(--orange_normal);
}

.border-right-6-dashed-orange_normal {
  border-right: 6px dashed var(--orange_normal);
}

.border-right-6-dotted-orange_normal {
  border-right: 6px dotted var(--orange_normal);
}

.border-right-6-double-orange_normal {
  border-right: 6px double var(--orange_normal);
}

.border-right-6-groove-orange_normal {
  border-right: 6px groove var(--orange_normal);
}

.border-right-6-ridge-orange_normal {
  border-right: 6px ridge var(--orange_normal);
}

.border-right-6-inset-orange_normal {
  border-right: 6px inset var(--orange_normal);
}

.border-right-6-outset-orange_normal {
  border-right: 6px outset var(--orange_normal);
}

.border-right-6-none-orange_normal {
  border-right: 6px none var(--orange_normal);
}

.border-right-6-hidden-orange_normal {
  border-right: 6px hidden var(--orange_normal);
}

.border-right-6-solid-orange_dark {
  border-right: 6px solid var(--orange_dark);
}

.border-right-6-dashed-orange_dark {
  border-right: 6px dashed var(--orange_dark);
}

.border-right-6-dotted-orange_dark {
  border-right: 6px dotted var(--orange_dark);
}

.border-right-6-double-orange_dark {
  border-right: 6px double var(--orange_dark);
}

.border-right-6-groove-orange_dark {
  border-right: 6px groove var(--orange_dark);
}

.border-right-6-ridge-orange_dark {
  border-right: 6px ridge var(--orange_dark);
}

.border-right-6-inset-orange_dark {
  border-right: 6px inset var(--orange_dark);
}

.border-right-6-outset-orange_dark {
  border-right: 6px outset var(--orange_dark);
}

.border-right-6-none-orange_dark {
  border-right: 6px none var(--orange_dark);
}

.border-right-6-hidden-orange_dark {
  border-right: 6px hidden var(--orange_dark);
}

.border-right-6-solid-vert_light {
  border-right: 6px solid var(--vert_light);
}

.border-right-6-dashed-vert_light {
  border-right: 6px dashed var(--vert_light);
}

.border-right-6-dotted-vert_light {
  border-right: 6px dotted var(--vert_light);
}

.border-right-6-double-vert_light {
  border-right: 6px double var(--vert_light);
}

.border-right-6-groove-vert_light {
  border-right: 6px groove var(--vert_light);
}

.border-right-6-ridge-vert_light {
  border-right: 6px ridge var(--vert_light);
}

.border-right-6-inset-vert_light {
  border-right: 6px inset var(--vert_light);
}

.border-right-6-outset-vert_light {
  border-right: 6px outset var(--vert_light);
}

.border-right-6-none-vert_light {
  border-right: 6px none var(--vert_light);
}

.border-right-6-hidden-vert_light {
  border-right: 6px hidden var(--vert_light);
}

.border-right-6-solid-vert_dark {
  border-right: 6px solid var(--vert_dark);
}

.border-right-6-dashed-vert_dark {
  border-right: 6px dashed var(--vert_dark);
}

.border-right-6-dotted-vert_dark {
  border-right: 6px dotted var(--vert_dark);
}

.border-right-6-double-vert_dark {
  border-right: 6px double var(--vert_dark);
}

.border-right-6-groove-vert_dark {
  border-right: 6px groove var(--vert_dark);
}

.border-right-6-ridge-vert_dark {
  border-right: 6px ridge var(--vert_dark);
}

.border-right-6-inset-vert_dark {
  border-right: 6px inset var(--vert_dark);
}

.border-right-6-outset-vert_dark {
  border-right: 6px outset var(--vert_dark);
}

.border-right-6-none-vert_dark {
  border-right: 6px none var(--vert_dark);
}

.border-right-6-hidden-vert_dark {
  border-right: 6px hidden var(--vert_dark);
}

.border-right-6-solid-bleu_turquoise_light {
  border-right: 6px solid var(--bleu_turquoise_light);
}

.border-right-6-dashed-bleu_turquoise_light {
  border-right: 6px dashed var(--bleu_turquoise_light);
}

.border-right-6-dotted-bleu_turquoise_light {
  border-right: 6px dotted var(--bleu_turquoise_light);
}

.border-right-6-double-bleu_turquoise_light {
  border-right: 6px double var(--bleu_turquoise_light);
}

.border-right-6-groove-bleu_turquoise_light {
  border-right: 6px groove var(--bleu_turquoise_light);
}

.border-right-6-ridge-bleu_turquoise_light {
  border-right: 6px ridge var(--bleu_turquoise_light);
}

.border-right-6-inset-bleu_turquoise_light {
  border-right: 6px inset var(--bleu_turquoise_light);
}

.border-right-6-outset-bleu_turquoise_light {
  border-right: 6px outset var(--bleu_turquoise_light);
}

.border-right-6-none-bleu_turquoise_light {
  border-right: 6px none var(--bleu_turquoise_light);
}

.border-right-6-hidden-bleu_turquoise_light {
  border-right: 6px hidden var(--bleu_turquoise_light);
}

.border-right-6-solid-bleu_turquoise_middle {
  border-right: 6px solid var(--bleu_turquoise_middle);
}

.border-right-6-dashed-bleu_turquoise_middle {
  border-right: 6px dashed var(--bleu_turquoise_middle);
}

.border-right-6-dotted-bleu_turquoise_middle {
  border-right: 6px dotted var(--bleu_turquoise_middle);
}

.border-right-6-double-bleu_turquoise_middle {
  border-right: 6px double var(--bleu_turquoise_middle);
}

.border-right-6-groove-bleu_turquoise_middle {
  border-right: 6px groove var(--bleu_turquoise_middle);
}

.border-right-6-ridge-bleu_turquoise_middle {
  border-right: 6px ridge var(--bleu_turquoise_middle);
}

.border-right-6-inset-bleu_turquoise_middle {
  border-right: 6px inset var(--bleu_turquoise_middle);
}

.border-right-6-outset-bleu_turquoise_middle {
  border-right: 6px outset var(--bleu_turquoise_middle);
}

.border-right-6-none-bleu_turquoise_middle {
  border-right: 6px none var(--bleu_turquoise_middle);
}

.border-right-6-hidden-bleu_turquoise_middle {
  border-right: 6px hidden var(--bleu_turquoise_middle);
}

.border-right-6-solid-bleu_turquoise_dark {
  border-right: 6px solid var(--bleu_turquoise_dark);
}

.border-right-6-dashed-bleu_turquoise_dark {
  border-right: 6px dashed var(--bleu_turquoise_dark);
}

.border-right-6-dotted-bleu_turquoise_dark {
  border-right: 6px dotted var(--bleu_turquoise_dark);
}

.border-right-6-double-bleu_turquoise_dark {
  border-right: 6px double var(--bleu_turquoise_dark);
}

.border-right-6-groove-bleu_turquoise_dark {
  border-right: 6px groove var(--bleu_turquoise_dark);
}

.border-right-6-ridge-bleu_turquoise_dark {
  border-right: 6px ridge var(--bleu_turquoise_dark);
}

.border-right-6-inset-bleu_turquoise_dark {
  border-right: 6px inset var(--bleu_turquoise_dark);
}

.border-right-6-outset-bleu_turquoise_dark {
  border-right: 6px outset var(--bleu_turquoise_dark);
}

.border-right-6-none-bleu_turquoise_dark {
  border-right: 6px none var(--bleu_turquoise_dark);
}

.border-right-6-hidden-bleu_turquoise_dark {
  border-right: 6px hidden var(--bleu_turquoise_dark);
}

.border-right-6-solid-bleu_light {
  border-right: 6px solid var(--bleu_light);
}

.border-right-6-dashed-bleu_light {
  border-right: 6px dashed var(--bleu_light);
}

.border-right-6-dotted-bleu_light {
  border-right: 6px dotted var(--bleu_light);
}

.border-right-6-double-bleu_light {
  border-right: 6px double var(--bleu_light);
}

.border-right-6-groove-bleu_light {
  border-right: 6px groove var(--bleu_light);
}

.border-right-6-ridge-bleu_light {
  border-right: 6px ridge var(--bleu_light);
}

.border-right-6-inset-bleu_light {
  border-right: 6px inset var(--bleu_light);
}

.border-right-6-outset-bleu_light {
  border-right: 6px outset var(--bleu_light);
}

.border-right-6-none-bleu_light {
  border-right: 6px none var(--bleu_light);
}

.border-right-6-hidden-bleu_light {
  border-right: 6px hidden var(--bleu_light);
}

.border-right-6-solid-bleu_dark {
  border-right: 6px solid var(--bleu_dark);
}

.border-right-6-dashed-bleu_dark {
  border-right: 6px dashed var(--bleu_dark);
}

.border-right-6-dotted-bleu_dark {
  border-right: 6px dotted var(--bleu_dark);
}

.border-right-6-double-bleu_dark {
  border-right: 6px double var(--bleu_dark);
}

.border-right-6-groove-bleu_dark {
  border-right: 6px groove var(--bleu_dark);
}

.border-right-6-ridge-bleu_dark {
  border-right: 6px ridge var(--bleu_dark);
}

.border-right-6-inset-bleu_dark {
  border-right: 6px inset var(--bleu_dark);
}

.border-right-6-outset-bleu_dark {
  border-right: 6px outset var(--bleu_dark);
}

.border-right-6-none-bleu_dark {
  border-right: 6px none var(--bleu_dark);
}

.border-right-6-hidden-bleu_dark {
  border-right: 6px hidden var(--bleu_dark);
}

.border-right-6-solid-bleu_klein_light {
  border-right: 6px solid var(--bleu_klein_light);
}

.border-right-6-dashed-bleu_klein_light {
  border-right: 6px dashed var(--bleu_klein_light);
}

.border-right-6-dotted-bleu_klein_light {
  border-right: 6px dotted var(--bleu_klein_light);
}

.border-right-6-double-bleu_klein_light {
  border-right: 6px double var(--bleu_klein_light);
}

.border-right-6-groove-bleu_klein_light {
  border-right: 6px groove var(--bleu_klein_light);
}

.border-right-6-ridge-bleu_klein_light {
  border-right: 6px ridge var(--bleu_klein_light);
}

.border-right-6-inset-bleu_klein_light {
  border-right: 6px inset var(--bleu_klein_light);
}

.border-right-6-outset-bleu_klein_light {
  border-right: 6px outset var(--bleu_klein_light);
}

.border-right-6-none-bleu_klein_light {
  border-right: 6px none var(--bleu_klein_light);
}

.border-right-6-hidden-bleu_klein_light {
  border-right: 6px hidden var(--bleu_klein_light);
}

.border-right-6-solid-bleu_klein_dark {
  border-right: 6px solid var(--bleu_klein_dark);
}

.border-right-6-dashed-bleu_klein_dark {
  border-right: 6px dashed var(--bleu_klein_dark);
}

.border-right-6-dotted-bleu_klein_dark {
  border-right: 6px dotted var(--bleu_klein_dark);
}

.border-right-6-double-bleu_klein_dark {
  border-right: 6px double var(--bleu_klein_dark);
}

.border-right-6-groove-bleu_klein_dark {
  border-right: 6px groove var(--bleu_klein_dark);
}

.border-right-6-ridge-bleu_klein_dark {
  border-right: 6px ridge var(--bleu_klein_dark);
}

.border-right-6-inset-bleu_klein_dark {
  border-right: 6px inset var(--bleu_klein_dark);
}

.border-right-6-outset-bleu_klein_dark {
  border-right: 6px outset var(--bleu_klein_dark);
}

.border-right-6-none-bleu_klein_dark {
  border-right: 6px none var(--bleu_klein_dark);
}

.border-right-6-hidden-bleu_klein_dark {
  border-right: 6px hidden var(--bleu_klein_dark);
}

.border-right-6-solid-violet_light {
  border-right: 6px solid var(--violet_light);
}

.border-right-6-dashed-violet_light {
  border-right: 6px dashed var(--violet_light);
}

.border-right-6-dotted-violet_light {
  border-right: 6px dotted var(--violet_light);
}

.border-right-6-double-violet_light {
  border-right: 6px double var(--violet_light);
}

.border-right-6-groove-violet_light {
  border-right: 6px groove var(--violet_light);
}

.border-right-6-ridge-violet_light {
  border-right: 6px ridge var(--violet_light);
}

.border-right-6-inset-violet_light {
  border-right: 6px inset var(--violet_light);
}

.border-right-6-outset-violet_light {
  border-right: 6px outset var(--violet_light);
}

.border-right-6-none-violet_light {
  border-right: 6px none var(--violet_light);
}

.border-right-6-hidden-violet_light {
  border-right: 6px hidden var(--violet_light);
}

.border-right-6-solid-violet_dark {
  border-right: 6px solid var(--violet_dark);
}

.border-right-6-dashed-violet_dark {
  border-right: 6px dashed var(--violet_dark);
}

.border-right-6-dotted-violet_dark {
  border-right: 6px dotted var(--violet_dark);
}

.border-right-6-double-violet_dark {
  border-right: 6px double var(--violet_dark);
}

.border-right-6-groove-violet_dark {
  border-right: 6px groove var(--violet_dark);
}

.border-right-6-ridge-violet_dark {
  border-right: 6px ridge var(--violet_dark);
}

.border-right-6-inset-violet_dark {
  border-right: 6px inset var(--violet_dark);
}

.border-right-6-outset-violet_dark {
  border-right: 6px outset var(--violet_dark);
}

.border-right-6-none-violet_dark {
  border-right: 6px none var(--violet_dark);
}

.border-right-6-hidden-violet_dark {
  border-right: 6px hidden var(--violet_dark);
}

.border-right-6-solid-mauve {
  border-right: 6px solid var(--mauve);
}

.border-right-6-dashed-mauve {
  border-right: 6px dashed var(--mauve);
}

.border-right-6-dotted-mauve {
  border-right: 6px dotted var(--mauve);
}

.border-right-6-double-mauve {
  border-right: 6px double var(--mauve);
}

.border-right-6-groove-mauve {
  border-right: 6px groove var(--mauve);
}

.border-right-6-ridge-mauve {
  border-right: 6px ridge var(--mauve);
}

.border-right-6-inset-mauve {
  border-right: 6px inset var(--mauve);
}

.border-right-6-outset-mauve {
  border-right: 6px outset var(--mauve);
}

.border-right-6-none-mauve {
  border-right: 6px none var(--mauve);
}

.border-right-6-hidden-mauve {
  border-right: 6px hidden var(--mauve);
}

.border-right-6-solid-rose_light {
  border-right: 6px solid var(--rose_light);
}

.border-right-6-dashed-rose_light {
  border-right: 6px dashed var(--rose_light);
}

.border-right-6-dotted-rose_light {
  border-right: 6px dotted var(--rose_light);
}

.border-right-6-double-rose_light {
  border-right: 6px double var(--rose_light);
}

.border-right-6-groove-rose_light {
  border-right: 6px groove var(--rose_light);
}

.border-right-6-ridge-rose_light {
  border-right: 6px ridge var(--rose_light);
}

.border-right-6-inset-rose_light {
  border-right: 6px inset var(--rose_light);
}

.border-right-6-outset-rose_light {
  border-right: 6px outset var(--rose_light);
}

.border-right-6-none-rose_light {
  border-right: 6px none var(--rose_light);
}

.border-right-6-hidden-rose_light {
  border-right: 6px hidden var(--rose_light);
}

.border-right-6-solid-rose_dark {
  border-right: 6px solid var(--rose_dark);
}

.border-right-6-dashed-rose_dark {
  border-right: 6px dashed var(--rose_dark);
}

.border-right-6-dotted-rose_dark {
  border-right: 6px dotted var(--rose_dark);
}

.border-right-6-double-rose_dark {
  border-right: 6px double var(--rose_dark);
}

.border-right-6-groove-rose_dark {
  border-right: 6px groove var(--rose_dark);
}

.border-right-6-ridge-rose_dark {
  border-right: 6px ridge var(--rose_dark);
}

.border-right-6-inset-rose_dark {
  border-right: 6px inset var(--rose_dark);
}

.border-right-6-outset-rose_dark {
  border-right: 6px outset var(--rose_dark);
}

.border-right-6-none-rose_dark {
  border-right: 6px none var(--rose_dark);
}

.border-right-6-hidden-rose_dark {
  border-right: 6px hidden var(--rose_dark);
}

.border-right-6-solid-rose_fushia {
  border-right: 6px solid var(--rose_fushia);
}

.border-right-6-dashed-rose_fushia {
  border-right: 6px dashed var(--rose_fushia);
}

.border-right-6-dotted-rose_fushia {
  border-right: 6px dotted var(--rose_fushia);
}

.border-right-6-double-rose_fushia {
  border-right: 6px double var(--rose_fushia);
}

.border-right-6-groove-rose_fushia {
  border-right: 6px groove var(--rose_fushia);
}

.border-right-6-ridge-rose_fushia {
  border-right: 6px ridge var(--rose_fushia);
}

.border-right-6-inset-rose_fushia {
  border-right: 6px inset var(--rose_fushia);
}

.border-right-6-outset-rose_fushia {
  border-right: 6px outset var(--rose_fushia);
}

.border-right-6-none-rose_fushia {
  border-right: 6px none var(--rose_fushia);
}

.border-right-6-hidden-rose_fushia {
  border-right: 6px hidden var(--rose_fushia);
}

.border-right-6-solid-rouge_framboise {
  border-right: 6px solid var(--rouge_framboise);
}

.border-right-6-dashed-rouge_framboise {
  border-right: 6px dashed var(--rouge_framboise);
}

.border-right-6-dotted-rouge_framboise {
  border-right: 6px dotted var(--rouge_framboise);
}

.border-right-6-double-rouge_framboise {
  border-right: 6px double var(--rouge_framboise);
}

.border-right-6-groove-rouge_framboise {
  border-right: 6px groove var(--rouge_framboise);
}

.border-right-6-ridge-rouge_framboise {
  border-right: 6px ridge var(--rouge_framboise);
}

.border-right-6-inset-rouge_framboise {
  border-right: 6px inset var(--rouge_framboise);
}

.border-right-6-outset-rouge_framboise {
  border-right: 6px outset var(--rouge_framboise);
}

.border-right-6-none-rouge_framboise {
  border-right: 6px none var(--rouge_framboise);
}

.border-right-6-hidden-rouge_framboise {
  border-right: 6px hidden var(--rouge_framboise);
}

.border-right-6-solid-rose_chair_light {
  border-right: 6px solid var(--rose_chair_light);
}

.border-right-6-dashed-rose_chair_light {
  border-right: 6px dashed var(--rose_chair_light);
}

.border-right-6-dotted-rose_chair_light {
  border-right: 6px dotted var(--rose_chair_light);
}

.border-right-6-double-rose_chair_light {
  border-right: 6px double var(--rose_chair_light);
}

.border-right-6-groove-rose_chair_light {
  border-right: 6px groove var(--rose_chair_light);
}

.border-right-6-ridge-rose_chair_light {
  border-right: 6px ridge var(--rose_chair_light);
}

.border-right-6-inset-rose_chair_light {
  border-right: 6px inset var(--rose_chair_light);
}

.border-right-6-outset-rose_chair_light {
  border-right: 6px outset var(--rose_chair_light);
}

.border-right-6-none-rose_chair_light {
  border-right: 6px none var(--rose_chair_light);
}

.border-right-6-hidden-rose_chair_light {
  border-right: 6px hidden var(--rose_chair_light);
}

.border-right-6-solid-rose_chair_dark {
  border-right: 6px solid var(--rose_chair_dark);
}

.border-right-6-dashed-rose_chair_dark {
  border-right: 6px dashed var(--rose_chair_dark);
}

.border-right-6-dotted-rose_chair_dark {
  border-right: 6px dotted var(--rose_chair_dark);
}

.border-right-6-double-rose_chair_dark {
  border-right: 6px double var(--rose_chair_dark);
}

.border-right-6-groove-rose_chair_dark {
  border-right: 6px groove var(--rose_chair_dark);
}

.border-right-6-ridge-rose_chair_dark {
  border-right: 6px ridge var(--rose_chair_dark);
}

.border-right-6-inset-rose_chair_dark {
  border-right: 6px inset var(--rose_chair_dark);
}

.border-right-6-outset-rose_chair_dark {
  border-right: 6px outset var(--rose_chair_dark);
}

.border-right-6-none-rose_chair_dark {
  border-right: 6px none var(--rose_chair_dark);
}

.border-right-6-hidden-rose_chair_dark {
  border-right: 6px hidden var(--rose_chair_dark);
}

.border-right-6-solid-vert_positif {
  border-right: 6px solid var(--vert_positif);
}

.border-right-6-dashed-vert_positif {
  border-right: 6px dashed var(--vert_positif);
}

.border-right-6-dotted-vert_positif {
  border-right: 6px dotted var(--vert_positif);
}

.border-right-6-double-vert_positif {
  border-right: 6px double var(--vert_positif);
}

.border-right-6-groove-vert_positif {
  border-right: 6px groove var(--vert_positif);
}

.border-right-6-ridge-vert_positif {
  border-right: 6px ridge var(--vert_positif);
}

.border-right-6-inset-vert_positif {
  border-right: 6px inset var(--vert_positif);
}

.border-right-6-outset-vert_positif {
  border-right: 6px outset var(--vert_positif);
}

.border-right-6-none-vert_positif {
  border-right: 6px none var(--vert_positif);
}

.border-right-6-hidden-vert_positif {
  border-right: 6px hidden var(--vert_positif);
}

.border-right-6-solid-rouge_negatif {
  border-right: 6px solid var(--rouge_negatif);
}

.border-right-6-dashed-rouge_negatif {
  border-right: 6px dashed var(--rouge_negatif);
}

.border-right-6-dotted-rouge_negatif {
  border-right: 6px dotted var(--rouge_negatif);
}

.border-right-6-double-rouge_negatif {
  border-right: 6px double var(--rouge_negatif);
}

.border-right-6-groove-rouge_negatif {
  border-right: 6px groove var(--rouge_negatif);
}

.border-right-6-ridge-rouge_negatif {
  border-right: 6px ridge var(--rouge_negatif);
}

.border-right-6-inset-rouge_negatif {
  border-right: 6px inset var(--rouge_negatif);
}

.border-right-6-outset-rouge_negatif {
  border-right: 6px outset var(--rouge_negatif);
}

.border-right-6-none-rouge_negatif {
  border-right: 6px none var(--rouge_negatif);
}

.border-right-6-hidden-rouge_negatif {
  border-right: 6px hidden var(--rouge_negatif);
}

.border-right-6-solid-bleu_texte {
  border-right: 6px solid var(--bleu_texte);
}

.border-right-6-dashed-bleu_texte {
  border-right: 6px dashed var(--bleu_texte);
}

.border-right-6-dotted-bleu_texte {
  border-right: 6px dotted var(--bleu_texte);
}

.border-right-6-double-bleu_texte {
  border-right: 6px double var(--bleu_texte);
}

.border-right-6-groove-bleu_texte {
  border-right: 6px groove var(--bleu_texte);
}

.border-right-6-ridge-bleu_texte {
  border-right: 6px ridge var(--bleu_texte);
}

.border-right-6-inset-bleu_texte {
  border-right: 6px inset var(--bleu_texte);
}

.border-right-6-outset-bleu_texte {
  border-right: 6px outset var(--bleu_texte);
}

.border-right-6-none-bleu_texte {
  border-right: 6px none var(--bleu_texte);
}

.border-right-6-hidden-bleu_texte {
  border-right: 6px hidden var(--bleu_texte);
}

.border-right-6-solid-alerte_vert_positif {
  border-right: 6px solid var(--alerte_vert_positif);
}

.border-right-6-dashed-alerte_vert_positif {
  border-right: 6px dashed var(--alerte_vert_positif);
}

.border-right-6-dotted-alerte_vert_positif {
  border-right: 6px dotted var(--alerte_vert_positif);
}

.border-right-6-double-alerte_vert_positif {
  border-right: 6px double var(--alerte_vert_positif);
}

.border-right-6-groove-alerte_vert_positif {
  border-right: 6px groove var(--alerte_vert_positif);
}

.border-right-6-ridge-alerte_vert_positif {
  border-right: 6px ridge var(--alerte_vert_positif);
}

.border-right-6-inset-alerte_vert_positif {
  border-right: 6px inset var(--alerte_vert_positif);
}

.border-right-6-outset-alerte_vert_positif {
  border-right: 6px outset var(--alerte_vert_positif);
}

.border-right-6-none-alerte_vert_positif {
  border-right: 6px none var(--alerte_vert_positif);
}

.border-right-6-hidden-alerte_vert_positif {
  border-right: 6px hidden var(--alerte_vert_positif);
}

.border-right-6-solid-alerte_rouge_negatif {
  border-right: 6px solid var(--alerte_rouge_negatif);
}

.border-right-6-dashed-alerte_rouge_negatif {
  border-right: 6px dashed var(--alerte_rouge_negatif);
}

.border-right-6-dotted-alerte_rouge_negatif {
  border-right: 6px dotted var(--alerte_rouge_negatif);
}

.border-right-6-double-alerte_rouge_negatif {
  border-right: 6px double var(--alerte_rouge_negatif);
}

.border-right-6-groove-alerte_rouge_negatif {
  border-right: 6px groove var(--alerte_rouge_negatif);
}

.border-right-6-ridge-alerte_rouge_negatif {
  border-right: 6px ridge var(--alerte_rouge_negatif);
}

.border-right-6-inset-alerte_rouge_negatif {
  border-right: 6px inset var(--alerte_rouge_negatif);
}

.border-right-6-outset-alerte_rouge_negatif {
  border-right: 6px outset var(--alerte_rouge_negatif);
}

.border-right-6-none-alerte_rouge_negatif {
  border-right: 6px none var(--alerte_rouge_negatif);
}

.border-right-6-hidden-alerte_rouge_negatif {
  border-right: 6px hidden var(--alerte_rouge_negatif);
}

.border-right-6-solid-alerte_bleu_information {
  border-right: 6px solid var(--alerte_bleu_information);
}

.border-right-6-dashed-alerte_bleu_information {
  border-right: 6px dashed var(--alerte_bleu_information);
}

.border-right-6-dotted-alerte_bleu_information {
  border-right: 6px dotted var(--alerte_bleu_information);
}

.border-right-6-double-alerte_bleu_information {
  border-right: 6px double var(--alerte_bleu_information);
}

.border-right-6-groove-alerte_bleu_information {
  border-right: 6px groove var(--alerte_bleu_information);
}

.border-right-6-ridge-alerte_bleu_information {
  border-right: 6px ridge var(--alerte_bleu_information);
}

.border-right-6-inset-alerte_bleu_information {
  border-right: 6px inset var(--alerte_bleu_information);
}

.border-right-6-outset-alerte_bleu_information {
  border-right: 6px outset var(--alerte_bleu_information);
}

.border-right-6-none-alerte_bleu_information {
  border-right: 6px none var(--alerte_bleu_information);
}

.border-right-6-hidden-alerte_bleu_information {
  border-right: 6px hidden var(--alerte_bleu_information);
}

.border-right-6-solid-alerte_jaune_attention {
  border-right: 6px solid var(--alerte_jaune_attention);
}

.border-right-6-dashed-alerte_jaune_attention {
  border-right: 6px dashed var(--alerte_jaune_attention);
}

.border-right-6-dotted-alerte_jaune_attention {
  border-right: 6px dotted var(--alerte_jaune_attention);
}

.border-right-6-double-alerte_jaune_attention {
  border-right: 6px double var(--alerte_jaune_attention);
}

.border-right-6-groove-alerte_jaune_attention {
  border-right: 6px groove var(--alerte_jaune_attention);
}

.border-right-6-ridge-alerte_jaune_attention {
  border-right: 6px ridge var(--alerte_jaune_attention);
}

.border-right-6-inset-alerte_jaune_attention {
  border-right: 6px inset var(--alerte_jaune_attention);
}

.border-right-6-outset-alerte_jaune_attention {
  border-right: 6px outset var(--alerte_jaune_attention);
}

.border-right-6-none-alerte_jaune_attention {
  border-right: 6px none var(--alerte_jaune_attention);
}

.border-right-6-hidden-alerte_jaune_attention {
  border-right: 6px hidden var(--alerte_jaune_attention);
}

.border-right-6-solid-blanc {
  border-right: 6px solid #fff;
}

.border-right-6-dashed-blanc {
  border-right: 6px dashed #fff;
}

.border-right-6-dotted-blanc {
  border-right: 6px dotted #fff;
}

.border-right-6-double-blanc {
  border-right: 6px double #fff;
}

.border-right-6-groove-blanc {
  border-right: 6px groove #fff;
}

.border-right-6-ridge-blanc {
  border-right: 6px ridge #fff;
}

.border-right-6-inset-blanc {
  border-right: 6px inset #fff;
}

.border-right-6-outset-blanc {
  border-right: 6px outset #fff;
}

.border-right-6-none-blanc {
  border-right: 6px #fff;
}

.border-right-6-hidden-blanc {
  border-right: 6px hidden #fff;
}

.border-right-6-solid-noir {
  border-right: 6px solid #222;
}

.border-right-6-dashed-noir {
  border-right: 6px dashed #222;
}

.border-right-6-dotted-noir {
  border-right: 6px dotted #222;
}

.border-right-6-double-noir {
  border-right: 6px double #222;
}

.border-right-6-groove-noir {
  border-right: 6px groove #222;
}

.border-right-6-ridge-noir {
  border-right: 6px ridge #222;
}

.border-right-6-inset-noir {
  border-right: 6px inset #222;
}

.border-right-6-outset-noir {
  border-right: 6px outset #222;
}

.border-right-6-none-noir {
  border-right: 6px #222;
}

.border-right-6-hidden-noir {
  border-right: 6px hidden #222;
}

.border-right-6-solid-gris_light {
  border-right: 6px solid var(--gris_light);
}

.border-right-6-dashed-gris_light {
  border-right: 6px dashed var(--gris_light);
}

.border-right-6-dotted-gris_light {
  border-right: 6px dotted var(--gris_light);
}

.border-right-6-double-gris_light {
  border-right: 6px double var(--gris_light);
}

.border-right-6-groove-gris_light {
  border-right: 6px groove var(--gris_light);
}

.border-right-6-ridge-gris_light {
  border-right: 6px ridge var(--gris_light);
}

.border-right-6-inset-gris_light {
  border-right: 6px inset var(--gris_light);
}

.border-right-6-outset-gris_light {
  border-right: 6px outset var(--gris_light);
}

.border-right-6-none-gris_light {
  border-right: 6px none var(--gris_light);
}

.border-right-6-hidden-gris_light {
  border-right: 6px hidden var(--gris_light);
}

.border-right-6-solid-gris_dark {
  border-right: 6px solid var(--gris_dark);
}

.border-right-6-dashed-gris_dark {
  border-right: 6px dashed var(--gris_dark);
}

.border-right-6-dotted-gris_dark {
  border-right: 6px dotted var(--gris_dark);
}

.border-right-6-double-gris_dark {
  border-right: 6px double var(--gris_dark);
}

.border-right-6-groove-gris_dark {
  border-right: 6px groove var(--gris_dark);
}

.border-right-6-ridge-gris_dark {
  border-right: 6px ridge var(--gris_dark);
}

.border-right-6-inset-gris_dark {
  border-right: 6px inset var(--gris_dark);
}

.border-right-6-outset-gris_dark {
  border-right: 6px outset var(--gris_dark);
}

.border-right-6-none-gris_dark {
  border-right: 6px none var(--gris_dark);
}

.border-right-6-hidden-gris_dark {
  border-right: 6px hidden var(--gris_dark);
}

.border-right-6-solid-gris_background {
  border-right: 6px solid var(--gris_background);
}

.border-right-6-dashed-gris_background {
  border-right: 6px dashed var(--gris_background);
}

.border-right-6-dotted-gris_background {
  border-right: 6px dotted var(--gris_background);
}

.border-right-6-double-gris_background {
  border-right: 6px double var(--gris_background);
}

.border-right-6-groove-gris_background {
  border-right: 6px groove var(--gris_background);
}

.border-right-6-ridge-gris_background {
  border-right: 6px ridge var(--gris_background);
}

.border-right-6-inset-gris_background {
  border-right: 6px inset var(--gris_background);
}

.border-right-6-outset-gris_background {
  border-right: 6px outset var(--gris_background);
}

.border-right-6-none-gris_background {
  border-right: 6px none var(--gris_background);
}

.border-right-6-hidden-gris_background {
  border-right: 6px hidden var(--gris_background);
}

.border-left-6-solid-corail_mh_brand {
  border-left: 6px solid var(--corail_mh_brand);
}

.border-left-6-dashed-corail_mh_brand {
  border-left: 6px dashed var(--corail_mh_brand);
}

.border-left-6-dotted-corail_mh_brand {
  border-left: 6px dotted var(--corail_mh_brand);
}

.border-left-6-double-corail_mh_brand {
  border-left: 6px double var(--corail_mh_brand);
}

.border-left-6-groove-corail_mh_brand {
  border-left: 6px groove var(--corail_mh_brand);
}

.border-left-6-ridge-corail_mh_brand {
  border-left: 6px ridge var(--corail_mh_brand);
}

.border-left-6-inset-corail_mh_brand {
  border-left: 6px inset var(--corail_mh_brand);
}

.border-left-6-outset-corail_mh_brand {
  border-left: 6px outset var(--corail_mh_brand);
}

.border-left-6-none-corail_mh_brand {
  border-left: 6px none var(--corail_mh_brand);
}

.border-left-6-hidden-corail_mh_brand {
  border-left: 6px hidden var(--corail_mh_brand);
}

.border-left-6-solid-corail_mh_dark {
  border-left: 6px solid var(--corail_mh_dark);
}

.border-left-6-dashed-corail_mh_dark {
  border-left: 6px dashed var(--corail_mh_dark);
}

.border-left-6-dotted-corail_mh_dark {
  border-left: 6px dotted var(--corail_mh_dark);
}

.border-left-6-double-corail_mh_dark {
  border-left: 6px double var(--corail_mh_dark);
}

.border-left-6-groove-corail_mh_dark {
  border-left: 6px groove var(--corail_mh_dark);
}

.border-left-6-ridge-corail_mh_dark {
  border-left: 6px ridge var(--corail_mh_dark);
}

.border-left-6-inset-corail_mh_dark {
  border-left: 6px inset var(--corail_mh_dark);
}

.border-left-6-outset-corail_mh_dark {
  border-left: 6px outset var(--corail_mh_dark);
}

.border-left-6-none-corail_mh_dark {
  border-left: 6px none var(--corail_mh_dark);
}

.border-left-6-hidden-corail_mh_dark {
  border-left: 6px hidden var(--corail_mh_dark);
}

.border-left-6-solid-corail_mh_light {
  border-left: 6px solid var(--corail_mh_light);
}

.border-left-6-dashed-corail_mh_light {
  border-left: 6px dashed var(--corail_mh_light);
}

.border-left-6-dotted-corail_mh_light {
  border-left: 6px dotted var(--corail_mh_light);
}

.border-left-6-double-corail_mh_light {
  border-left: 6px double var(--corail_mh_light);
}

.border-left-6-groove-corail_mh_light {
  border-left: 6px groove var(--corail_mh_light);
}

.border-left-6-ridge-corail_mh_light {
  border-left: 6px ridge var(--corail_mh_light);
}

.border-left-6-inset-corail_mh_light {
  border-left: 6px inset var(--corail_mh_light);
}

.border-left-6-outset-corail_mh_light {
  border-left: 6px outset var(--corail_mh_light);
}

.border-left-6-none-corail_mh_light {
  border-left: 6px none var(--corail_mh_light);
}

.border-left-6-hidden-corail_mh_light {
  border-left: 6px hidden var(--corail_mh_light);
}

.border-left-6-solid-gris_sable_light {
  border-left: 6px solid var(--gris_sable_light);
}

.border-left-6-dashed-gris_sable_light {
  border-left: 6px dashed var(--gris_sable_light);
}

.border-left-6-dotted-gris_sable_light {
  border-left: 6px dotted var(--gris_sable_light);
}

.border-left-6-double-gris_sable_light {
  border-left: 6px double var(--gris_sable_light);
}

.border-left-6-groove-gris_sable_light {
  border-left: 6px groove var(--gris_sable_light);
}

.border-left-6-ridge-gris_sable_light {
  border-left: 6px ridge var(--gris_sable_light);
}

.border-left-6-inset-gris_sable_light {
  border-left: 6px inset var(--gris_sable_light);
}

.border-left-6-outset-gris_sable_light {
  border-left: 6px outset var(--gris_sable_light);
}

.border-left-6-none-gris_sable_light {
  border-left: 6px none var(--gris_sable_light);
}

.border-left-6-hidden-gris_sable_light {
  border-left: 6px hidden var(--gris_sable_light);
}

.border-left-6-solid-gris_sable_dark {
  border-left: 6px solid var(--gris_sable_dark);
}

.border-left-6-dashed-gris_sable_dark {
  border-left: 6px dashed var(--gris_sable_dark);
}

.border-left-6-dotted-gris_sable_dark {
  border-left: 6px dotted var(--gris_sable_dark);
}

.border-left-6-double-gris_sable_dark {
  border-left: 6px double var(--gris_sable_dark);
}

.border-left-6-groove-gris_sable_dark {
  border-left: 6px groove var(--gris_sable_dark);
}

.border-left-6-ridge-gris_sable_dark {
  border-left: 6px ridge var(--gris_sable_dark);
}

.border-left-6-inset-gris_sable_dark {
  border-left: 6px inset var(--gris_sable_dark);
}

.border-left-6-outset-gris_sable_dark {
  border-left: 6px outset var(--gris_sable_dark);
}

.border-left-6-none-gris_sable_dark {
  border-left: 6px none var(--gris_sable_dark);
}

.border-left-6-hidden-gris_sable_dark {
  border-left: 6px hidden var(--gris_sable_dark);
}

.border-left-6-solid-jaune_ivoire_light {
  border-left: 6px solid var(--jaune_ivoire_light);
}

.border-left-6-dashed-jaune_ivoire_light {
  border-left: 6px dashed var(--jaune_ivoire_light);
}

.border-left-6-dotted-jaune_ivoire_light {
  border-left: 6px dotted var(--jaune_ivoire_light);
}

.border-left-6-double-jaune_ivoire_light {
  border-left: 6px double var(--jaune_ivoire_light);
}

.border-left-6-groove-jaune_ivoire_light {
  border-left: 6px groove var(--jaune_ivoire_light);
}

.border-left-6-ridge-jaune_ivoire_light {
  border-left: 6px ridge var(--jaune_ivoire_light);
}

.border-left-6-inset-jaune_ivoire_light {
  border-left: 6px inset var(--jaune_ivoire_light);
}

.border-left-6-outset-jaune_ivoire_light {
  border-left: 6px outset var(--jaune_ivoire_light);
}

.border-left-6-none-jaune_ivoire_light {
  border-left: 6px none var(--jaune_ivoire_light);
}

.border-left-6-hidden-jaune_ivoire_light {
  border-left: 6px hidden var(--jaune_ivoire_light);
}

.border-left-6-solid-jaune_ivoire_dark {
  border-left: 6px solid var(--jaune_ivoire_dark);
}

.border-left-6-dashed-jaune_ivoire_dark {
  border-left: 6px dashed var(--jaune_ivoire_dark);
}

.border-left-6-dotted-jaune_ivoire_dark {
  border-left: 6px dotted var(--jaune_ivoire_dark);
}

.border-left-6-double-jaune_ivoire_dark {
  border-left: 6px double var(--jaune_ivoire_dark);
}

.border-left-6-groove-jaune_ivoire_dark {
  border-left: 6px groove var(--jaune_ivoire_dark);
}

.border-left-6-ridge-jaune_ivoire_dark {
  border-left: 6px ridge var(--jaune_ivoire_dark);
}

.border-left-6-inset-jaune_ivoire_dark {
  border-left: 6px inset var(--jaune_ivoire_dark);
}

.border-left-6-outset-jaune_ivoire_dark {
  border-left: 6px outset var(--jaune_ivoire_dark);
}

.border-left-6-none-jaune_ivoire_dark {
  border-left: 6px none var(--jaune_ivoire_dark);
}

.border-left-6-hidden-jaune_ivoire_dark {
  border-left: 6px hidden var(--jaune_ivoire_dark);
}

.border-left-6-solid-jaune_light {
  border-left: 6px solid var(--jaune_light);
}

.border-left-6-dashed-jaune_light {
  border-left: 6px dashed var(--jaune_light);
}

.border-left-6-dotted-jaune_light {
  border-left: 6px dotted var(--jaune_light);
}

.border-left-6-double-jaune_light {
  border-left: 6px double var(--jaune_light);
}

.border-left-6-groove-jaune_light {
  border-left: 6px groove var(--jaune_light);
}

.border-left-6-ridge-jaune_light {
  border-left: 6px ridge var(--jaune_light);
}

.border-left-6-inset-jaune_light {
  border-left: 6px inset var(--jaune_light);
}

.border-left-6-outset-jaune_light {
  border-left: 6px outset var(--jaune_light);
}

.border-left-6-none-jaune_light {
  border-left: 6px none var(--jaune_light);
}

.border-left-6-hidden-jaune_light {
  border-left: 6px hidden var(--jaune_light);
}

.border-left-6-solid-jaune_dark {
  border-left: 6px solid var(--jaune_dark);
}

.border-left-6-dashed-jaune_dark {
  border-left: 6px dashed var(--jaune_dark);
}

.border-left-6-dotted-jaune_dark {
  border-left: 6px dotted var(--jaune_dark);
}

.border-left-6-double-jaune_dark {
  border-left: 6px double var(--jaune_dark);
}

.border-left-6-groove-jaune_dark {
  border-left: 6px groove var(--jaune_dark);
}

.border-left-6-ridge-jaune_dark {
  border-left: 6px ridge var(--jaune_dark);
}

.border-left-6-inset-jaune_dark {
  border-left: 6px inset var(--jaune_dark);
}

.border-left-6-outset-jaune_dark {
  border-left: 6px outset var(--jaune_dark);
}

.border-left-6-none-jaune_dark {
  border-left: 6px none var(--jaune_dark);
}

.border-left-6-hidden-jaune_dark {
  border-left: 6px hidden var(--jaune_dark);
}

.border-left-6-solid-orange_normal {
  border-left: 6px solid var(--orange_normal);
}

.border-left-6-dashed-orange_normal {
  border-left: 6px dashed var(--orange_normal);
}

.border-left-6-dotted-orange_normal {
  border-left: 6px dotted var(--orange_normal);
}

.border-left-6-double-orange_normal {
  border-left: 6px double var(--orange_normal);
}

.border-left-6-groove-orange_normal {
  border-left: 6px groove var(--orange_normal);
}

.border-left-6-ridge-orange_normal {
  border-left: 6px ridge var(--orange_normal);
}

.border-left-6-inset-orange_normal {
  border-left: 6px inset var(--orange_normal);
}

.border-left-6-outset-orange_normal {
  border-left: 6px outset var(--orange_normal);
}

.border-left-6-none-orange_normal {
  border-left: 6px none var(--orange_normal);
}

.border-left-6-hidden-orange_normal {
  border-left: 6px hidden var(--orange_normal);
}

.border-left-6-solid-orange_dark {
  border-left: 6px solid var(--orange_dark);
}

.border-left-6-dashed-orange_dark {
  border-left: 6px dashed var(--orange_dark);
}

.border-left-6-dotted-orange_dark {
  border-left: 6px dotted var(--orange_dark);
}

.border-left-6-double-orange_dark {
  border-left: 6px double var(--orange_dark);
}

.border-left-6-groove-orange_dark {
  border-left: 6px groove var(--orange_dark);
}

.border-left-6-ridge-orange_dark {
  border-left: 6px ridge var(--orange_dark);
}

.border-left-6-inset-orange_dark {
  border-left: 6px inset var(--orange_dark);
}

.border-left-6-outset-orange_dark {
  border-left: 6px outset var(--orange_dark);
}

.border-left-6-none-orange_dark {
  border-left: 6px none var(--orange_dark);
}

.border-left-6-hidden-orange_dark {
  border-left: 6px hidden var(--orange_dark);
}

.border-left-6-solid-vert_light {
  border-left: 6px solid var(--vert_light);
}

.border-left-6-dashed-vert_light {
  border-left: 6px dashed var(--vert_light);
}

.border-left-6-dotted-vert_light {
  border-left: 6px dotted var(--vert_light);
}

.border-left-6-double-vert_light {
  border-left: 6px double var(--vert_light);
}

.border-left-6-groove-vert_light {
  border-left: 6px groove var(--vert_light);
}

.border-left-6-ridge-vert_light {
  border-left: 6px ridge var(--vert_light);
}

.border-left-6-inset-vert_light {
  border-left: 6px inset var(--vert_light);
}

.border-left-6-outset-vert_light {
  border-left: 6px outset var(--vert_light);
}

.border-left-6-none-vert_light {
  border-left: 6px none var(--vert_light);
}

.border-left-6-hidden-vert_light {
  border-left: 6px hidden var(--vert_light);
}

.border-left-6-solid-vert_dark {
  border-left: 6px solid var(--vert_dark);
}

.border-left-6-dashed-vert_dark {
  border-left: 6px dashed var(--vert_dark);
}

.border-left-6-dotted-vert_dark {
  border-left: 6px dotted var(--vert_dark);
}

.border-left-6-double-vert_dark {
  border-left: 6px double var(--vert_dark);
}

.border-left-6-groove-vert_dark {
  border-left: 6px groove var(--vert_dark);
}

.border-left-6-ridge-vert_dark {
  border-left: 6px ridge var(--vert_dark);
}

.border-left-6-inset-vert_dark {
  border-left: 6px inset var(--vert_dark);
}

.border-left-6-outset-vert_dark {
  border-left: 6px outset var(--vert_dark);
}

.border-left-6-none-vert_dark {
  border-left: 6px none var(--vert_dark);
}

.border-left-6-hidden-vert_dark {
  border-left: 6px hidden var(--vert_dark);
}

.border-left-6-solid-bleu_turquoise_light {
  border-left: 6px solid var(--bleu_turquoise_light);
}

.border-left-6-dashed-bleu_turquoise_light {
  border-left: 6px dashed var(--bleu_turquoise_light);
}

.border-left-6-dotted-bleu_turquoise_light {
  border-left: 6px dotted var(--bleu_turquoise_light);
}

.border-left-6-double-bleu_turquoise_light {
  border-left: 6px double var(--bleu_turquoise_light);
}

.border-left-6-groove-bleu_turquoise_light {
  border-left: 6px groove var(--bleu_turquoise_light);
}

.border-left-6-ridge-bleu_turquoise_light {
  border-left: 6px ridge var(--bleu_turquoise_light);
}

.border-left-6-inset-bleu_turquoise_light {
  border-left: 6px inset var(--bleu_turquoise_light);
}

.border-left-6-outset-bleu_turquoise_light {
  border-left: 6px outset var(--bleu_turquoise_light);
}

.border-left-6-none-bleu_turquoise_light {
  border-left: 6px none var(--bleu_turquoise_light);
}

.border-left-6-hidden-bleu_turquoise_light {
  border-left: 6px hidden var(--bleu_turquoise_light);
}

.border-left-6-solid-bleu_turquoise_middle {
  border-left: 6px solid var(--bleu_turquoise_middle);
}

.border-left-6-dashed-bleu_turquoise_middle {
  border-left: 6px dashed var(--bleu_turquoise_middle);
}

.border-left-6-dotted-bleu_turquoise_middle {
  border-left: 6px dotted var(--bleu_turquoise_middle);
}

.border-left-6-double-bleu_turquoise_middle {
  border-left: 6px double var(--bleu_turquoise_middle);
}

.border-left-6-groove-bleu_turquoise_middle {
  border-left: 6px groove var(--bleu_turquoise_middle);
}

.border-left-6-ridge-bleu_turquoise_middle {
  border-left: 6px ridge var(--bleu_turquoise_middle);
}

.border-left-6-inset-bleu_turquoise_middle {
  border-left: 6px inset var(--bleu_turquoise_middle);
}

.border-left-6-outset-bleu_turquoise_middle {
  border-left: 6px outset var(--bleu_turquoise_middle);
}

.border-left-6-none-bleu_turquoise_middle {
  border-left: 6px none var(--bleu_turquoise_middle);
}

.border-left-6-hidden-bleu_turquoise_middle {
  border-left: 6px hidden var(--bleu_turquoise_middle);
}

.border-left-6-solid-bleu_turquoise_dark {
  border-left: 6px solid var(--bleu_turquoise_dark);
}

.border-left-6-dashed-bleu_turquoise_dark {
  border-left: 6px dashed var(--bleu_turquoise_dark);
}

.border-left-6-dotted-bleu_turquoise_dark {
  border-left: 6px dotted var(--bleu_turquoise_dark);
}

.border-left-6-double-bleu_turquoise_dark {
  border-left: 6px double var(--bleu_turquoise_dark);
}

.border-left-6-groove-bleu_turquoise_dark {
  border-left: 6px groove var(--bleu_turquoise_dark);
}

.border-left-6-ridge-bleu_turquoise_dark {
  border-left: 6px ridge var(--bleu_turquoise_dark);
}

.border-left-6-inset-bleu_turquoise_dark {
  border-left: 6px inset var(--bleu_turquoise_dark);
}

.border-left-6-outset-bleu_turquoise_dark {
  border-left: 6px outset var(--bleu_turquoise_dark);
}

.border-left-6-none-bleu_turquoise_dark {
  border-left: 6px none var(--bleu_turquoise_dark);
}

.border-left-6-hidden-bleu_turquoise_dark {
  border-left: 6px hidden var(--bleu_turquoise_dark);
}

.border-left-6-solid-bleu_light {
  border-left: 6px solid var(--bleu_light);
}

.border-left-6-dashed-bleu_light {
  border-left: 6px dashed var(--bleu_light);
}

.border-left-6-dotted-bleu_light {
  border-left: 6px dotted var(--bleu_light);
}

.border-left-6-double-bleu_light {
  border-left: 6px double var(--bleu_light);
}

.border-left-6-groove-bleu_light {
  border-left: 6px groove var(--bleu_light);
}

.border-left-6-ridge-bleu_light {
  border-left: 6px ridge var(--bleu_light);
}

.border-left-6-inset-bleu_light {
  border-left: 6px inset var(--bleu_light);
}

.border-left-6-outset-bleu_light {
  border-left: 6px outset var(--bleu_light);
}

.border-left-6-none-bleu_light {
  border-left: 6px none var(--bleu_light);
}

.border-left-6-hidden-bleu_light {
  border-left: 6px hidden var(--bleu_light);
}

.border-left-6-solid-bleu_dark {
  border-left: 6px solid var(--bleu_dark);
}

.border-left-6-dashed-bleu_dark {
  border-left: 6px dashed var(--bleu_dark);
}

.border-left-6-dotted-bleu_dark {
  border-left: 6px dotted var(--bleu_dark);
}

.border-left-6-double-bleu_dark {
  border-left: 6px double var(--bleu_dark);
}

.border-left-6-groove-bleu_dark {
  border-left: 6px groove var(--bleu_dark);
}

.border-left-6-ridge-bleu_dark {
  border-left: 6px ridge var(--bleu_dark);
}

.border-left-6-inset-bleu_dark {
  border-left: 6px inset var(--bleu_dark);
}

.border-left-6-outset-bleu_dark {
  border-left: 6px outset var(--bleu_dark);
}

.border-left-6-none-bleu_dark {
  border-left: 6px none var(--bleu_dark);
}

.border-left-6-hidden-bleu_dark {
  border-left: 6px hidden var(--bleu_dark);
}

.border-left-6-solid-bleu_klein_light {
  border-left: 6px solid var(--bleu_klein_light);
}

.border-left-6-dashed-bleu_klein_light {
  border-left: 6px dashed var(--bleu_klein_light);
}

.border-left-6-dotted-bleu_klein_light {
  border-left: 6px dotted var(--bleu_klein_light);
}

.border-left-6-double-bleu_klein_light {
  border-left: 6px double var(--bleu_klein_light);
}

.border-left-6-groove-bleu_klein_light {
  border-left: 6px groove var(--bleu_klein_light);
}

.border-left-6-ridge-bleu_klein_light {
  border-left: 6px ridge var(--bleu_klein_light);
}

.border-left-6-inset-bleu_klein_light {
  border-left: 6px inset var(--bleu_klein_light);
}

.border-left-6-outset-bleu_klein_light {
  border-left: 6px outset var(--bleu_klein_light);
}

.border-left-6-none-bleu_klein_light {
  border-left: 6px none var(--bleu_klein_light);
}

.border-left-6-hidden-bleu_klein_light {
  border-left: 6px hidden var(--bleu_klein_light);
}

.border-left-6-solid-bleu_klein_dark {
  border-left: 6px solid var(--bleu_klein_dark);
}

.border-left-6-dashed-bleu_klein_dark {
  border-left: 6px dashed var(--bleu_klein_dark);
}

.border-left-6-dotted-bleu_klein_dark {
  border-left: 6px dotted var(--bleu_klein_dark);
}

.border-left-6-double-bleu_klein_dark {
  border-left: 6px double var(--bleu_klein_dark);
}

.border-left-6-groove-bleu_klein_dark {
  border-left: 6px groove var(--bleu_klein_dark);
}

.border-left-6-ridge-bleu_klein_dark {
  border-left: 6px ridge var(--bleu_klein_dark);
}

.border-left-6-inset-bleu_klein_dark {
  border-left: 6px inset var(--bleu_klein_dark);
}

.border-left-6-outset-bleu_klein_dark {
  border-left: 6px outset var(--bleu_klein_dark);
}

.border-left-6-none-bleu_klein_dark {
  border-left: 6px none var(--bleu_klein_dark);
}

.border-left-6-hidden-bleu_klein_dark {
  border-left: 6px hidden var(--bleu_klein_dark);
}

.border-left-6-solid-violet_light {
  border-left: 6px solid var(--violet_light);
}

.border-left-6-dashed-violet_light {
  border-left: 6px dashed var(--violet_light);
}

.border-left-6-dotted-violet_light {
  border-left: 6px dotted var(--violet_light);
}

.border-left-6-double-violet_light {
  border-left: 6px double var(--violet_light);
}

.border-left-6-groove-violet_light {
  border-left: 6px groove var(--violet_light);
}

.border-left-6-ridge-violet_light {
  border-left: 6px ridge var(--violet_light);
}

.border-left-6-inset-violet_light {
  border-left: 6px inset var(--violet_light);
}

.border-left-6-outset-violet_light {
  border-left: 6px outset var(--violet_light);
}

.border-left-6-none-violet_light {
  border-left: 6px none var(--violet_light);
}

.border-left-6-hidden-violet_light {
  border-left: 6px hidden var(--violet_light);
}

.border-left-6-solid-violet_dark {
  border-left: 6px solid var(--violet_dark);
}

.border-left-6-dashed-violet_dark {
  border-left: 6px dashed var(--violet_dark);
}

.border-left-6-dotted-violet_dark {
  border-left: 6px dotted var(--violet_dark);
}

.border-left-6-double-violet_dark {
  border-left: 6px double var(--violet_dark);
}

.border-left-6-groove-violet_dark {
  border-left: 6px groove var(--violet_dark);
}

.border-left-6-ridge-violet_dark {
  border-left: 6px ridge var(--violet_dark);
}

.border-left-6-inset-violet_dark {
  border-left: 6px inset var(--violet_dark);
}

.border-left-6-outset-violet_dark {
  border-left: 6px outset var(--violet_dark);
}

.border-left-6-none-violet_dark {
  border-left: 6px none var(--violet_dark);
}

.border-left-6-hidden-violet_dark {
  border-left: 6px hidden var(--violet_dark);
}

.border-left-6-solid-mauve {
  border-left: 6px solid var(--mauve);
}

.border-left-6-dashed-mauve {
  border-left: 6px dashed var(--mauve);
}

.border-left-6-dotted-mauve {
  border-left: 6px dotted var(--mauve);
}

.border-left-6-double-mauve {
  border-left: 6px double var(--mauve);
}

.border-left-6-groove-mauve {
  border-left: 6px groove var(--mauve);
}

.border-left-6-ridge-mauve {
  border-left: 6px ridge var(--mauve);
}

.border-left-6-inset-mauve {
  border-left: 6px inset var(--mauve);
}

.border-left-6-outset-mauve {
  border-left: 6px outset var(--mauve);
}

.border-left-6-none-mauve {
  border-left: 6px none var(--mauve);
}

.border-left-6-hidden-mauve {
  border-left: 6px hidden var(--mauve);
}

.border-left-6-solid-rose_light {
  border-left: 6px solid var(--rose_light);
}

.border-left-6-dashed-rose_light {
  border-left: 6px dashed var(--rose_light);
}

.border-left-6-dotted-rose_light {
  border-left: 6px dotted var(--rose_light);
}

.border-left-6-double-rose_light {
  border-left: 6px double var(--rose_light);
}

.border-left-6-groove-rose_light {
  border-left: 6px groove var(--rose_light);
}

.border-left-6-ridge-rose_light {
  border-left: 6px ridge var(--rose_light);
}

.border-left-6-inset-rose_light {
  border-left: 6px inset var(--rose_light);
}

.border-left-6-outset-rose_light {
  border-left: 6px outset var(--rose_light);
}

.border-left-6-none-rose_light {
  border-left: 6px none var(--rose_light);
}

.border-left-6-hidden-rose_light {
  border-left: 6px hidden var(--rose_light);
}

.border-left-6-solid-rose_dark {
  border-left: 6px solid var(--rose_dark);
}

.border-left-6-dashed-rose_dark {
  border-left: 6px dashed var(--rose_dark);
}

.border-left-6-dotted-rose_dark {
  border-left: 6px dotted var(--rose_dark);
}

.border-left-6-double-rose_dark {
  border-left: 6px double var(--rose_dark);
}

.border-left-6-groove-rose_dark {
  border-left: 6px groove var(--rose_dark);
}

.border-left-6-ridge-rose_dark {
  border-left: 6px ridge var(--rose_dark);
}

.border-left-6-inset-rose_dark {
  border-left: 6px inset var(--rose_dark);
}

.border-left-6-outset-rose_dark {
  border-left: 6px outset var(--rose_dark);
}

.border-left-6-none-rose_dark {
  border-left: 6px none var(--rose_dark);
}

.border-left-6-hidden-rose_dark {
  border-left: 6px hidden var(--rose_dark);
}

.border-left-6-solid-rose_fushia {
  border-left: 6px solid var(--rose_fushia);
}

.border-left-6-dashed-rose_fushia {
  border-left: 6px dashed var(--rose_fushia);
}

.border-left-6-dotted-rose_fushia {
  border-left: 6px dotted var(--rose_fushia);
}

.border-left-6-double-rose_fushia {
  border-left: 6px double var(--rose_fushia);
}

.border-left-6-groove-rose_fushia {
  border-left: 6px groove var(--rose_fushia);
}

.border-left-6-ridge-rose_fushia {
  border-left: 6px ridge var(--rose_fushia);
}

.border-left-6-inset-rose_fushia {
  border-left: 6px inset var(--rose_fushia);
}

.border-left-6-outset-rose_fushia {
  border-left: 6px outset var(--rose_fushia);
}

.border-left-6-none-rose_fushia {
  border-left: 6px none var(--rose_fushia);
}

.border-left-6-hidden-rose_fushia {
  border-left: 6px hidden var(--rose_fushia);
}

.border-left-6-solid-rouge_framboise {
  border-left: 6px solid var(--rouge_framboise);
}

.border-left-6-dashed-rouge_framboise {
  border-left: 6px dashed var(--rouge_framboise);
}

.border-left-6-dotted-rouge_framboise {
  border-left: 6px dotted var(--rouge_framboise);
}

.border-left-6-double-rouge_framboise {
  border-left: 6px double var(--rouge_framboise);
}

.border-left-6-groove-rouge_framboise {
  border-left: 6px groove var(--rouge_framboise);
}

.border-left-6-ridge-rouge_framboise {
  border-left: 6px ridge var(--rouge_framboise);
}

.border-left-6-inset-rouge_framboise {
  border-left: 6px inset var(--rouge_framboise);
}

.border-left-6-outset-rouge_framboise {
  border-left: 6px outset var(--rouge_framboise);
}

.border-left-6-none-rouge_framboise {
  border-left: 6px none var(--rouge_framboise);
}

.border-left-6-hidden-rouge_framboise {
  border-left: 6px hidden var(--rouge_framboise);
}

.border-left-6-solid-rose_chair_light {
  border-left: 6px solid var(--rose_chair_light);
}

.border-left-6-dashed-rose_chair_light {
  border-left: 6px dashed var(--rose_chair_light);
}

.border-left-6-dotted-rose_chair_light {
  border-left: 6px dotted var(--rose_chair_light);
}

.border-left-6-double-rose_chair_light {
  border-left: 6px double var(--rose_chair_light);
}

.border-left-6-groove-rose_chair_light {
  border-left: 6px groove var(--rose_chair_light);
}

.border-left-6-ridge-rose_chair_light {
  border-left: 6px ridge var(--rose_chair_light);
}

.border-left-6-inset-rose_chair_light {
  border-left: 6px inset var(--rose_chair_light);
}

.border-left-6-outset-rose_chair_light {
  border-left: 6px outset var(--rose_chair_light);
}

.border-left-6-none-rose_chair_light {
  border-left: 6px none var(--rose_chair_light);
}

.border-left-6-hidden-rose_chair_light {
  border-left: 6px hidden var(--rose_chair_light);
}

.border-left-6-solid-rose_chair_dark {
  border-left: 6px solid var(--rose_chair_dark);
}

.border-left-6-dashed-rose_chair_dark {
  border-left: 6px dashed var(--rose_chair_dark);
}

.border-left-6-dotted-rose_chair_dark {
  border-left: 6px dotted var(--rose_chair_dark);
}

.border-left-6-double-rose_chair_dark {
  border-left: 6px double var(--rose_chair_dark);
}

.border-left-6-groove-rose_chair_dark {
  border-left: 6px groove var(--rose_chair_dark);
}

.border-left-6-ridge-rose_chair_dark {
  border-left: 6px ridge var(--rose_chair_dark);
}

.border-left-6-inset-rose_chair_dark {
  border-left: 6px inset var(--rose_chair_dark);
}

.border-left-6-outset-rose_chair_dark {
  border-left: 6px outset var(--rose_chair_dark);
}

.border-left-6-none-rose_chair_dark {
  border-left: 6px none var(--rose_chair_dark);
}

.border-left-6-hidden-rose_chair_dark {
  border-left: 6px hidden var(--rose_chair_dark);
}

.border-left-6-solid-vert_positif {
  border-left: 6px solid var(--vert_positif);
}

.border-left-6-dashed-vert_positif {
  border-left: 6px dashed var(--vert_positif);
}

.border-left-6-dotted-vert_positif {
  border-left: 6px dotted var(--vert_positif);
}

.border-left-6-double-vert_positif {
  border-left: 6px double var(--vert_positif);
}

.border-left-6-groove-vert_positif {
  border-left: 6px groove var(--vert_positif);
}

.border-left-6-ridge-vert_positif {
  border-left: 6px ridge var(--vert_positif);
}

.border-left-6-inset-vert_positif {
  border-left: 6px inset var(--vert_positif);
}

.border-left-6-outset-vert_positif {
  border-left: 6px outset var(--vert_positif);
}

.border-left-6-none-vert_positif {
  border-left: 6px none var(--vert_positif);
}

.border-left-6-hidden-vert_positif {
  border-left: 6px hidden var(--vert_positif);
}

.border-left-6-solid-rouge_negatif {
  border-left: 6px solid var(--rouge_negatif);
}

.border-left-6-dashed-rouge_negatif {
  border-left: 6px dashed var(--rouge_negatif);
}

.border-left-6-dotted-rouge_negatif {
  border-left: 6px dotted var(--rouge_negatif);
}

.border-left-6-double-rouge_negatif {
  border-left: 6px double var(--rouge_negatif);
}

.border-left-6-groove-rouge_negatif {
  border-left: 6px groove var(--rouge_negatif);
}

.border-left-6-ridge-rouge_negatif {
  border-left: 6px ridge var(--rouge_negatif);
}

.border-left-6-inset-rouge_negatif {
  border-left: 6px inset var(--rouge_negatif);
}

.border-left-6-outset-rouge_negatif {
  border-left: 6px outset var(--rouge_negatif);
}

.border-left-6-none-rouge_negatif {
  border-left: 6px none var(--rouge_negatif);
}

.border-left-6-hidden-rouge_negatif {
  border-left: 6px hidden var(--rouge_negatif);
}

.border-left-6-solid-bleu_texte {
  border-left: 6px solid var(--bleu_texte);
}

.border-left-6-dashed-bleu_texte {
  border-left: 6px dashed var(--bleu_texte);
}

.border-left-6-dotted-bleu_texte {
  border-left: 6px dotted var(--bleu_texte);
}

.border-left-6-double-bleu_texte {
  border-left: 6px double var(--bleu_texte);
}

.border-left-6-groove-bleu_texte {
  border-left: 6px groove var(--bleu_texte);
}

.border-left-6-ridge-bleu_texte {
  border-left: 6px ridge var(--bleu_texte);
}

.border-left-6-inset-bleu_texte {
  border-left: 6px inset var(--bleu_texte);
}

.border-left-6-outset-bleu_texte {
  border-left: 6px outset var(--bleu_texte);
}

.border-left-6-none-bleu_texte {
  border-left: 6px none var(--bleu_texte);
}

.border-left-6-hidden-bleu_texte {
  border-left: 6px hidden var(--bleu_texte);
}

.border-left-6-solid-alerte_vert_positif {
  border-left: 6px solid var(--alerte_vert_positif);
}

.border-left-6-dashed-alerte_vert_positif {
  border-left: 6px dashed var(--alerte_vert_positif);
}

.border-left-6-dotted-alerte_vert_positif {
  border-left: 6px dotted var(--alerte_vert_positif);
}

.border-left-6-double-alerte_vert_positif {
  border-left: 6px double var(--alerte_vert_positif);
}

.border-left-6-groove-alerte_vert_positif {
  border-left: 6px groove var(--alerte_vert_positif);
}

.border-left-6-ridge-alerte_vert_positif {
  border-left: 6px ridge var(--alerte_vert_positif);
}

.border-left-6-inset-alerte_vert_positif {
  border-left: 6px inset var(--alerte_vert_positif);
}

.border-left-6-outset-alerte_vert_positif {
  border-left: 6px outset var(--alerte_vert_positif);
}

.border-left-6-none-alerte_vert_positif {
  border-left: 6px none var(--alerte_vert_positif);
}

.border-left-6-hidden-alerte_vert_positif {
  border-left: 6px hidden var(--alerte_vert_positif);
}

.border-left-6-solid-alerte_rouge_negatif {
  border-left: 6px solid var(--alerte_rouge_negatif);
}

.border-left-6-dashed-alerte_rouge_negatif {
  border-left: 6px dashed var(--alerte_rouge_negatif);
}

.border-left-6-dotted-alerte_rouge_negatif {
  border-left: 6px dotted var(--alerte_rouge_negatif);
}

.border-left-6-double-alerte_rouge_negatif {
  border-left: 6px double var(--alerte_rouge_negatif);
}

.border-left-6-groove-alerte_rouge_negatif {
  border-left: 6px groove var(--alerte_rouge_negatif);
}

.border-left-6-ridge-alerte_rouge_negatif {
  border-left: 6px ridge var(--alerte_rouge_negatif);
}

.border-left-6-inset-alerte_rouge_negatif {
  border-left: 6px inset var(--alerte_rouge_negatif);
}

.border-left-6-outset-alerte_rouge_negatif {
  border-left: 6px outset var(--alerte_rouge_negatif);
}

.border-left-6-none-alerte_rouge_negatif {
  border-left: 6px none var(--alerte_rouge_negatif);
}

.border-left-6-hidden-alerte_rouge_negatif {
  border-left: 6px hidden var(--alerte_rouge_negatif);
}

.border-left-6-solid-alerte_bleu_information {
  border-left: 6px solid var(--alerte_bleu_information);
}

.border-left-6-dashed-alerte_bleu_information {
  border-left: 6px dashed var(--alerte_bleu_information);
}

.border-left-6-dotted-alerte_bleu_information {
  border-left: 6px dotted var(--alerte_bleu_information);
}

.border-left-6-double-alerte_bleu_information {
  border-left: 6px double var(--alerte_bleu_information);
}

.border-left-6-groove-alerte_bleu_information {
  border-left: 6px groove var(--alerte_bleu_information);
}

.border-left-6-ridge-alerte_bleu_information {
  border-left: 6px ridge var(--alerte_bleu_information);
}

.border-left-6-inset-alerte_bleu_information {
  border-left: 6px inset var(--alerte_bleu_information);
}

.border-left-6-outset-alerte_bleu_information {
  border-left: 6px outset var(--alerte_bleu_information);
}

.border-left-6-none-alerte_bleu_information {
  border-left: 6px none var(--alerte_bleu_information);
}

.border-left-6-hidden-alerte_bleu_information {
  border-left: 6px hidden var(--alerte_bleu_information);
}

.border-left-6-solid-alerte_jaune_attention {
  border-left: 6px solid var(--alerte_jaune_attention);
}

.border-left-6-dashed-alerte_jaune_attention {
  border-left: 6px dashed var(--alerte_jaune_attention);
}

.border-left-6-dotted-alerte_jaune_attention {
  border-left: 6px dotted var(--alerte_jaune_attention);
}

.border-left-6-double-alerte_jaune_attention {
  border-left: 6px double var(--alerte_jaune_attention);
}

.border-left-6-groove-alerte_jaune_attention {
  border-left: 6px groove var(--alerte_jaune_attention);
}

.border-left-6-ridge-alerte_jaune_attention {
  border-left: 6px ridge var(--alerte_jaune_attention);
}

.border-left-6-inset-alerte_jaune_attention {
  border-left: 6px inset var(--alerte_jaune_attention);
}

.border-left-6-outset-alerte_jaune_attention {
  border-left: 6px outset var(--alerte_jaune_attention);
}

.border-left-6-none-alerte_jaune_attention {
  border-left: 6px none var(--alerte_jaune_attention);
}

.border-left-6-hidden-alerte_jaune_attention {
  border-left: 6px hidden var(--alerte_jaune_attention);
}

.border-left-6-solid-blanc {
  border-left: 6px solid #fff;
}

.border-left-6-dashed-blanc {
  border-left: 6px dashed #fff;
}

.border-left-6-dotted-blanc {
  border-left: 6px dotted #fff;
}

.border-left-6-double-blanc {
  border-left: 6px double #fff;
}

.border-left-6-groove-blanc {
  border-left: 6px groove #fff;
}

.border-left-6-ridge-blanc {
  border-left: 6px ridge #fff;
}

.border-left-6-inset-blanc {
  border-left: 6px inset #fff;
}

.border-left-6-outset-blanc {
  border-left: 6px outset #fff;
}

.border-left-6-none-blanc {
  border-left: 6px #fff;
}

.border-left-6-hidden-blanc {
  border-left: 6px hidden #fff;
}

.border-left-6-solid-noir {
  border-left: 6px solid #222;
}

.border-left-6-dashed-noir {
  border-left: 6px dashed #222;
}

.border-left-6-dotted-noir {
  border-left: 6px dotted #222;
}

.border-left-6-double-noir {
  border-left: 6px double #222;
}

.border-left-6-groove-noir {
  border-left: 6px groove #222;
}

.border-left-6-ridge-noir {
  border-left: 6px ridge #222;
}

.border-left-6-inset-noir {
  border-left: 6px inset #222;
}

.border-left-6-outset-noir {
  border-left: 6px outset #222;
}

.border-left-6-none-noir {
  border-left: 6px #222;
}

.border-left-6-hidden-noir {
  border-left: 6px hidden #222;
}

.border-left-6-solid-gris_light {
  border-left: 6px solid var(--gris_light);
}

.border-left-6-dashed-gris_light {
  border-left: 6px dashed var(--gris_light);
}

.border-left-6-dotted-gris_light {
  border-left: 6px dotted var(--gris_light);
}

.border-left-6-double-gris_light {
  border-left: 6px double var(--gris_light);
}

.border-left-6-groove-gris_light {
  border-left: 6px groove var(--gris_light);
}

.border-left-6-ridge-gris_light {
  border-left: 6px ridge var(--gris_light);
}

.border-left-6-inset-gris_light {
  border-left: 6px inset var(--gris_light);
}

.border-left-6-outset-gris_light {
  border-left: 6px outset var(--gris_light);
}

.border-left-6-none-gris_light {
  border-left: 6px none var(--gris_light);
}

.border-left-6-hidden-gris_light {
  border-left: 6px hidden var(--gris_light);
}

.border-left-6-solid-gris_dark {
  border-left: 6px solid var(--gris_dark);
}

.border-left-6-dashed-gris_dark {
  border-left: 6px dashed var(--gris_dark);
}

.border-left-6-dotted-gris_dark {
  border-left: 6px dotted var(--gris_dark);
}

.border-left-6-double-gris_dark {
  border-left: 6px double var(--gris_dark);
}

.border-left-6-groove-gris_dark {
  border-left: 6px groove var(--gris_dark);
}

.border-left-6-ridge-gris_dark {
  border-left: 6px ridge var(--gris_dark);
}

.border-left-6-inset-gris_dark {
  border-left: 6px inset var(--gris_dark);
}

.border-left-6-outset-gris_dark {
  border-left: 6px outset var(--gris_dark);
}

.border-left-6-none-gris_dark {
  border-left: 6px none var(--gris_dark);
}

.border-left-6-hidden-gris_dark {
  border-left: 6px hidden var(--gris_dark);
}

.border-left-6-solid-gris_background {
  border-left: 6px solid var(--gris_background);
}

.border-left-6-dashed-gris_background {
  border-left: 6px dashed var(--gris_background);
}

.border-left-6-dotted-gris_background {
  border-left: 6px dotted var(--gris_background);
}

.border-left-6-double-gris_background {
  border-left: 6px double var(--gris_background);
}

.border-left-6-groove-gris_background {
  border-left: 6px groove var(--gris_background);
}

.border-left-6-ridge-gris_background {
  border-left: 6px ridge var(--gris_background);
}

.border-left-6-inset-gris_background {
  border-left: 6px inset var(--gris_background);
}

.border-left-6-outset-gris_background {
  border-left: 6px outset var(--gris_background);
}

.border-left-6-none-gris_background {
  border-left: 6px none var(--gris_background);
}

.border-left-6-hidden-gris_background {
  border-left: 6px hidden var(--gris_background);
}

.border-all-6-solid-corail_mh_brand {
  border: 6px solid var(--corail_mh_brand);
}

.border-all-6-dashed-corail_mh_brand {
  border: 6px dashed var(--corail_mh_brand);
}

.border-all-6-dotted-corail_mh_brand {
  border: 6px dotted var(--corail_mh_brand);
}

.border-all-6-double-corail_mh_brand {
  border: 6px double var(--corail_mh_brand);
}

.border-all-6-groove-corail_mh_brand {
  border: 6px groove var(--corail_mh_brand);
}

.border-all-6-ridge-corail_mh_brand {
  border: 6px ridge var(--corail_mh_brand);
}

.border-all-6-inset-corail_mh_brand {
  border: 6px inset var(--corail_mh_brand);
}

.border-all-6-outset-corail_mh_brand {
  border: 6px outset var(--corail_mh_brand);
}

.border-all-6-none-corail_mh_brand {
  border: 6px none var(--corail_mh_brand);
}

.border-all-6-hidden-corail_mh_brand {
  border: 6px hidden var(--corail_mh_brand);
}

.border-all-6-solid-corail_mh_dark {
  border: 6px solid var(--corail_mh_dark);
}

.border-all-6-dashed-corail_mh_dark {
  border: 6px dashed var(--corail_mh_dark);
}

.border-all-6-dotted-corail_mh_dark {
  border: 6px dotted var(--corail_mh_dark);
}

.border-all-6-double-corail_mh_dark {
  border: 6px double var(--corail_mh_dark);
}

.border-all-6-groove-corail_mh_dark {
  border: 6px groove var(--corail_mh_dark);
}

.border-all-6-ridge-corail_mh_dark {
  border: 6px ridge var(--corail_mh_dark);
}

.border-all-6-inset-corail_mh_dark {
  border: 6px inset var(--corail_mh_dark);
}

.border-all-6-outset-corail_mh_dark {
  border: 6px outset var(--corail_mh_dark);
}

.border-all-6-none-corail_mh_dark {
  border: 6px none var(--corail_mh_dark);
}

.border-all-6-hidden-corail_mh_dark {
  border: 6px hidden var(--corail_mh_dark);
}

.border-all-6-solid-corail_mh_light {
  border: 6px solid var(--corail_mh_light);
}

.border-all-6-dashed-corail_mh_light {
  border: 6px dashed var(--corail_mh_light);
}

.border-all-6-dotted-corail_mh_light {
  border: 6px dotted var(--corail_mh_light);
}

.border-all-6-double-corail_mh_light {
  border: 6px double var(--corail_mh_light);
}

.border-all-6-groove-corail_mh_light {
  border: 6px groove var(--corail_mh_light);
}

.border-all-6-ridge-corail_mh_light {
  border: 6px ridge var(--corail_mh_light);
}

.border-all-6-inset-corail_mh_light {
  border: 6px inset var(--corail_mh_light);
}

.border-all-6-outset-corail_mh_light {
  border: 6px outset var(--corail_mh_light);
}

.border-all-6-none-corail_mh_light {
  border: 6px none var(--corail_mh_light);
}

.border-all-6-hidden-corail_mh_light {
  border: 6px hidden var(--corail_mh_light);
}

.border-all-6-solid-gris_sable_light {
  border: 6px solid var(--gris_sable_light);
}

.border-all-6-dashed-gris_sable_light {
  border: 6px dashed var(--gris_sable_light);
}

.border-all-6-dotted-gris_sable_light {
  border: 6px dotted var(--gris_sable_light);
}

.border-all-6-double-gris_sable_light {
  border: 6px double var(--gris_sable_light);
}

.border-all-6-groove-gris_sable_light {
  border: 6px groove var(--gris_sable_light);
}

.border-all-6-ridge-gris_sable_light {
  border: 6px ridge var(--gris_sable_light);
}

.border-all-6-inset-gris_sable_light {
  border: 6px inset var(--gris_sable_light);
}

.border-all-6-outset-gris_sable_light {
  border: 6px outset var(--gris_sable_light);
}

.border-all-6-none-gris_sable_light {
  border: 6px none var(--gris_sable_light);
}

.border-all-6-hidden-gris_sable_light {
  border: 6px hidden var(--gris_sable_light);
}

.border-all-6-solid-gris_sable_dark {
  border: 6px solid var(--gris_sable_dark);
}

.border-all-6-dashed-gris_sable_dark {
  border: 6px dashed var(--gris_sable_dark);
}

.border-all-6-dotted-gris_sable_dark {
  border: 6px dotted var(--gris_sable_dark);
}

.border-all-6-double-gris_sable_dark {
  border: 6px double var(--gris_sable_dark);
}

.border-all-6-groove-gris_sable_dark {
  border: 6px groove var(--gris_sable_dark);
}

.border-all-6-ridge-gris_sable_dark {
  border: 6px ridge var(--gris_sable_dark);
}

.border-all-6-inset-gris_sable_dark {
  border: 6px inset var(--gris_sable_dark);
}

.border-all-6-outset-gris_sable_dark {
  border: 6px outset var(--gris_sable_dark);
}

.border-all-6-none-gris_sable_dark {
  border: 6px none var(--gris_sable_dark);
}

.border-all-6-hidden-gris_sable_dark {
  border: 6px hidden var(--gris_sable_dark);
}

.border-all-6-solid-jaune_ivoire_light {
  border: 6px solid var(--jaune_ivoire_light);
}

.border-all-6-dashed-jaune_ivoire_light {
  border: 6px dashed var(--jaune_ivoire_light);
}

.border-all-6-dotted-jaune_ivoire_light {
  border: 6px dotted var(--jaune_ivoire_light);
}

.border-all-6-double-jaune_ivoire_light {
  border: 6px double var(--jaune_ivoire_light);
}

.border-all-6-groove-jaune_ivoire_light {
  border: 6px groove var(--jaune_ivoire_light);
}

.border-all-6-ridge-jaune_ivoire_light {
  border: 6px ridge var(--jaune_ivoire_light);
}

.border-all-6-inset-jaune_ivoire_light {
  border: 6px inset var(--jaune_ivoire_light);
}

.border-all-6-outset-jaune_ivoire_light {
  border: 6px outset var(--jaune_ivoire_light);
}

.border-all-6-none-jaune_ivoire_light {
  border: 6px none var(--jaune_ivoire_light);
}

.border-all-6-hidden-jaune_ivoire_light {
  border: 6px hidden var(--jaune_ivoire_light);
}

.border-all-6-solid-jaune_ivoire_dark {
  border: 6px solid var(--jaune_ivoire_dark);
}

.border-all-6-dashed-jaune_ivoire_dark {
  border: 6px dashed var(--jaune_ivoire_dark);
}

.border-all-6-dotted-jaune_ivoire_dark {
  border: 6px dotted var(--jaune_ivoire_dark);
}

.border-all-6-double-jaune_ivoire_dark {
  border: 6px double var(--jaune_ivoire_dark);
}

.border-all-6-groove-jaune_ivoire_dark {
  border: 6px groove var(--jaune_ivoire_dark);
}

.border-all-6-ridge-jaune_ivoire_dark {
  border: 6px ridge var(--jaune_ivoire_dark);
}

.border-all-6-inset-jaune_ivoire_dark {
  border: 6px inset var(--jaune_ivoire_dark);
}

.border-all-6-outset-jaune_ivoire_dark {
  border: 6px outset var(--jaune_ivoire_dark);
}

.border-all-6-none-jaune_ivoire_dark {
  border: 6px none var(--jaune_ivoire_dark);
}

.border-all-6-hidden-jaune_ivoire_dark {
  border: 6px hidden var(--jaune_ivoire_dark);
}

.border-all-6-solid-jaune_light {
  border: 6px solid var(--jaune_light);
}

.border-all-6-dashed-jaune_light {
  border: 6px dashed var(--jaune_light);
}

.border-all-6-dotted-jaune_light {
  border: 6px dotted var(--jaune_light);
}

.border-all-6-double-jaune_light {
  border: 6px double var(--jaune_light);
}

.border-all-6-groove-jaune_light {
  border: 6px groove var(--jaune_light);
}

.border-all-6-ridge-jaune_light {
  border: 6px ridge var(--jaune_light);
}

.border-all-6-inset-jaune_light {
  border: 6px inset var(--jaune_light);
}

.border-all-6-outset-jaune_light {
  border: 6px outset var(--jaune_light);
}

.border-all-6-none-jaune_light {
  border: 6px none var(--jaune_light);
}

.border-all-6-hidden-jaune_light {
  border: 6px hidden var(--jaune_light);
}

.border-all-6-solid-jaune_dark {
  border: 6px solid var(--jaune_dark);
}

.border-all-6-dashed-jaune_dark {
  border: 6px dashed var(--jaune_dark);
}

.border-all-6-dotted-jaune_dark {
  border: 6px dotted var(--jaune_dark);
}

.border-all-6-double-jaune_dark {
  border: 6px double var(--jaune_dark);
}

.border-all-6-groove-jaune_dark {
  border: 6px groove var(--jaune_dark);
}

.border-all-6-ridge-jaune_dark {
  border: 6px ridge var(--jaune_dark);
}

.border-all-6-inset-jaune_dark {
  border: 6px inset var(--jaune_dark);
}

.border-all-6-outset-jaune_dark {
  border: 6px outset var(--jaune_dark);
}

.border-all-6-none-jaune_dark {
  border: 6px none var(--jaune_dark);
}

.border-all-6-hidden-jaune_dark {
  border: 6px hidden var(--jaune_dark);
}

.border-all-6-solid-orange_normal {
  border: 6px solid var(--orange_normal);
}

.border-all-6-dashed-orange_normal {
  border: 6px dashed var(--orange_normal);
}

.border-all-6-dotted-orange_normal {
  border: 6px dotted var(--orange_normal);
}

.border-all-6-double-orange_normal {
  border: 6px double var(--orange_normal);
}

.border-all-6-groove-orange_normal {
  border: 6px groove var(--orange_normal);
}

.border-all-6-ridge-orange_normal {
  border: 6px ridge var(--orange_normal);
}

.border-all-6-inset-orange_normal {
  border: 6px inset var(--orange_normal);
}

.border-all-6-outset-orange_normal {
  border: 6px outset var(--orange_normal);
}

.border-all-6-none-orange_normal {
  border: 6px none var(--orange_normal);
}

.border-all-6-hidden-orange_normal {
  border: 6px hidden var(--orange_normal);
}

.border-all-6-solid-orange_dark {
  border: 6px solid var(--orange_dark);
}

.border-all-6-dashed-orange_dark {
  border: 6px dashed var(--orange_dark);
}

.border-all-6-dotted-orange_dark {
  border: 6px dotted var(--orange_dark);
}

.border-all-6-double-orange_dark {
  border: 6px double var(--orange_dark);
}

.border-all-6-groove-orange_dark {
  border: 6px groove var(--orange_dark);
}

.border-all-6-ridge-orange_dark {
  border: 6px ridge var(--orange_dark);
}

.border-all-6-inset-orange_dark {
  border: 6px inset var(--orange_dark);
}

.border-all-6-outset-orange_dark {
  border: 6px outset var(--orange_dark);
}

.border-all-6-none-orange_dark {
  border: 6px none var(--orange_dark);
}

.border-all-6-hidden-orange_dark {
  border: 6px hidden var(--orange_dark);
}

.border-all-6-solid-vert_light {
  border: 6px solid var(--vert_light);
}

.border-all-6-dashed-vert_light {
  border: 6px dashed var(--vert_light);
}

.border-all-6-dotted-vert_light {
  border: 6px dotted var(--vert_light);
}

.border-all-6-double-vert_light {
  border: 6px double var(--vert_light);
}

.border-all-6-groove-vert_light {
  border: 6px groove var(--vert_light);
}

.border-all-6-ridge-vert_light {
  border: 6px ridge var(--vert_light);
}

.border-all-6-inset-vert_light {
  border: 6px inset var(--vert_light);
}

.border-all-6-outset-vert_light {
  border: 6px outset var(--vert_light);
}

.border-all-6-none-vert_light {
  border: 6px none var(--vert_light);
}

.border-all-6-hidden-vert_light {
  border: 6px hidden var(--vert_light);
}

.border-all-6-solid-vert_dark {
  border: 6px solid var(--vert_dark);
}

.border-all-6-dashed-vert_dark {
  border: 6px dashed var(--vert_dark);
}

.border-all-6-dotted-vert_dark {
  border: 6px dotted var(--vert_dark);
}

.border-all-6-double-vert_dark {
  border: 6px double var(--vert_dark);
}

.border-all-6-groove-vert_dark {
  border: 6px groove var(--vert_dark);
}

.border-all-6-ridge-vert_dark {
  border: 6px ridge var(--vert_dark);
}

.border-all-6-inset-vert_dark {
  border: 6px inset var(--vert_dark);
}

.border-all-6-outset-vert_dark {
  border: 6px outset var(--vert_dark);
}

.border-all-6-none-vert_dark {
  border: 6px none var(--vert_dark);
}

.border-all-6-hidden-vert_dark {
  border: 6px hidden var(--vert_dark);
}

.border-all-6-solid-bleu_turquoise_light {
  border: 6px solid var(--bleu_turquoise_light);
}

.border-all-6-dashed-bleu_turquoise_light {
  border: 6px dashed var(--bleu_turquoise_light);
}

.border-all-6-dotted-bleu_turquoise_light {
  border: 6px dotted var(--bleu_turquoise_light);
}

.border-all-6-double-bleu_turquoise_light {
  border: 6px double var(--bleu_turquoise_light);
}

.border-all-6-groove-bleu_turquoise_light {
  border: 6px groove var(--bleu_turquoise_light);
}

.border-all-6-ridge-bleu_turquoise_light {
  border: 6px ridge var(--bleu_turquoise_light);
}

.border-all-6-inset-bleu_turquoise_light {
  border: 6px inset var(--bleu_turquoise_light);
}

.border-all-6-outset-bleu_turquoise_light {
  border: 6px outset var(--bleu_turquoise_light);
}

.border-all-6-none-bleu_turquoise_light {
  border: 6px none var(--bleu_turquoise_light);
}

.border-all-6-hidden-bleu_turquoise_light {
  border: 6px hidden var(--bleu_turquoise_light);
}

.border-all-6-solid-bleu_turquoise_middle {
  border: 6px solid var(--bleu_turquoise_middle);
}

.border-all-6-dashed-bleu_turquoise_middle {
  border: 6px dashed var(--bleu_turquoise_middle);
}

.border-all-6-dotted-bleu_turquoise_middle {
  border: 6px dotted var(--bleu_turquoise_middle);
}

.border-all-6-double-bleu_turquoise_middle {
  border: 6px double var(--bleu_turquoise_middle);
}

.border-all-6-groove-bleu_turquoise_middle {
  border: 6px groove var(--bleu_turquoise_middle);
}

.border-all-6-ridge-bleu_turquoise_middle {
  border: 6px ridge var(--bleu_turquoise_middle);
}

.border-all-6-inset-bleu_turquoise_middle {
  border: 6px inset var(--bleu_turquoise_middle);
}

.border-all-6-outset-bleu_turquoise_middle {
  border: 6px outset var(--bleu_turquoise_middle);
}

.border-all-6-none-bleu_turquoise_middle {
  border: 6px none var(--bleu_turquoise_middle);
}

.border-all-6-hidden-bleu_turquoise_middle {
  border: 6px hidden var(--bleu_turquoise_middle);
}

.border-all-6-solid-bleu_turquoise_dark {
  border: 6px solid var(--bleu_turquoise_dark);
}

.border-all-6-dashed-bleu_turquoise_dark {
  border: 6px dashed var(--bleu_turquoise_dark);
}

.border-all-6-dotted-bleu_turquoise_dark {
  border: 6px dotted var(--bleu_turquoise_dark);
}

.border-all-6-double-bleu_turquoise_dark {
  border: 6px double var(--bleu_turquoise_dark);
}

.border-all-6-groove-bleu_turquoise_dark {
  border: 6px groove var(--bleu_turquoise_dark);
}

.border-all-6-ridge-bleu_turquoise_dark {
  border: 6px ridge var(--bleu_turquoise_dark);
}

.border-all-6-inset-bleu_turquoise_dark {
  border: 6px inset var(--bleu_turquoise_dark);
}

.border-all-6-outset-bleu_turquoise_dark {
  border: 6px outset var(--bleu_turquoise_dark);
}

.border-all-6-none-bleu_turquoise_dark {
  border: 6px none var(--bleu_turquoise_dark);
}

.border-all-6-hidden-bleu_turquoise_dark {
  border: 6px hidden var(--bleu_turquoise_dark);
}

.border-all-6-solid-bleu_light {
  border: 6px solid var(--bleu_light);
}

.border-all-6-dashed-bleu_light {
  border: 6px dashed var(--bleu_light);
}

.border-all-6-dotted-bleu_light {
  border: 6px dotted var(--bleu_light);
}

.border-all-6-double-bleu_light {
  border: 6px double var(--bleu_light);
}

.border-all-6-groove-bleu_light {
  border: 6px groove var(--bleu_light);
}

.border-all-6-ridge-bleu_light {
  border: 6px ridge var(--bleu_light);
}

.border-all-6-inset-bleu_light {
  border: 6px inset var(--bleu_light);
}

.border-all-6-outset-bleu_light {
  border: 6px outset var(--bleu_light);
}

.border-all-6-none-bleu_light {
  border: 6px none var(--bleu_light);
}

.border-all-6-hidden-bleu_light {
  border: 6px hidden var(--bleu_light);
}

.border-all-6-solid-bleu_dark {
  border: 6px solid var(--bleu_dark);
}

.border-all-6-dashed-bleu_dark {
  border: 6px dashed var(--bleu_dark);
}

.border-all-6-dotted-bleu_dark {
  border: 6px dotted var(--bleu_dark);
}

.border-all-6-double-bleu_dark {
  border: 6px double var(--bleu_dark);
}

.border-all-6-groove-bleu_dark {
  border: 6px groove var(--bleu_dark);
}

.border-all-6-ridge-bleu_dark {
  border: 6px ridge var(--bleu_dark);
}

.border-all-6-inset-bleu_dark {
  border: 6px inset var(--bleu_dark);
}

.border-all-6-outset-bleu_dark {
  border: 6px outset var(--bleu_dark);
}

.border-all-6-none-bleu_dark {
  border: 6px none var(--bleu_dark);
}

.border-all-6-hidden-bleu_dark {
  border: 6px hidden var(--bleu_dark);
}

.border-all-6-solid-bleu_klein_light {
  border: 6px solid var(--bleu_klein_light);
}

.border-all-6-dashed-bleu_klein_light {
  border: 6px dashed var(--bleu_klein_light);
}

.border-all-6-dotted-bleu_klein_light {
  border: 6px dotted var(--bleu_klein_light);
}

.border-all-6-double-bleu_klein_light {
  border: 6px double var(--bleu_klein_light);
}

.border-all-6-groove-bleu_klein_light {
  border: 6px groove var(--bleu_klein_light);
}

.border-all-6-ridge-bleu_klein_light {
  border: 6px ridge var(--bleu_klein_light);
}

.border-all-6-inset-bleu_klein_light {
  border: 6px inset var(--bleu_klein_light);
}

.border-all-6-outset-bleu_klein_light {
  border: 6px outset var(--bleu_klein_light);
}

.border-all-6-none-bleu_klein_light {
  border: 6px none var(--bleu_klein_light);
}

.border-all-6-hidden-bleu_klein_light {
  border: 6px hidden var(--bleu_klein_light);
}

.border-all-6-solid-bleu_klein_dark {
  border: 6px solid var(--bleu_klein_dark);
}

.border-all-6-dashed-bleu_klein_dark {
  border: 6px dashed var(--bleu_klein_dark);
}

.border-all-6-dotted-bleu_klein_dark {
  border: 6px dotted var(--bleu_klein_dark);
}

.border-all-6-double-bleu_klein_dark {
  border: 6px double var(--bleu_klein_dark);
}

.border-all-6-groove-bleu_klein_dark {
  border: 6px groove var(--bleu_klein_dark);
}

.border-all-6-ridge-bleu_klein_dark {
  border: 6px ridge var(--bleu_klein_dark);
}

.border-all-6-inset-bleu_klein_dark {
  border: 6px inset var(--bleu_klein_dark);
}

.border-all-6-outset-bleu_klein_dark {
  border: 6px outset var(--bleu_klein_dark);
}

.border-all-6-none-bleu_klein_dark {
  border: 6px none var(--bleu_klein_dark);
}

.border-all-6-hidden-bleu_klein_dark {
  border: 6px hidden var(--bleu_klein_dark);
}

.border-all-6-solid-violet_light {
  border: 6px solid var(--violet_light);
}

.border-all-6-dashed-violet_light {
  border: 6px dashed var(--violet_light);
}

.border-all-6-dotted-violet_light {
  border: 6px dotted var(--violet_light);
}

.border-all-6-double-violet_light {
  border: 6px double var(--violet_light);
}

.border-all-6-groove-violet_light {
  border: 6px groove var(--violet_light);
}

.border-all-6-ridge-violet_light {
  border: 6px ridge var(--violet_light);
}

.border-all-6-inset-violet_light {
  border: 6px inset var(--violet_light);
}

.border-all-6-outset-violet_light {
  border: 6px outset var(--violet_light);
}

.border-all-6-none-violet_light {
  border: 6px none var(--violet_light);
}

.border-all-6-hidden-violet_light {
  border: 6px hidden var(--violet_light);
}

.border-all-6-solid-violet_dark {
  border: 6px solid var(--violet_dark);
}

.border-all-6-dashed-violet_dark {
  border: 6px dashed var(--violet_dark);
}

.border-all-6-dotted-violet_dark {
  border: 6px dotted var(--violet_dark);
}

.border-all-6-double-violet_dark {
  border: 6px double var(--violet_dark);
}

.border-all-6-groove-violet_dark {
  border: 6px groove var(--violet_dark);
}

.border-all-6-ridge-violet_dark {
  border: 6px ridge var(--violet_dark);
}

.border-all-6-inset-violet_dark {
  border: 6px inset var(--violet_dark);
}

.border-all-6-outset-violet_dark {
  border: 6px outset var(--violet_dark);
}

.border-all-6-none-violet_dark {
  border: 6px none var(--violet_dark);
}

.border-all-6-hidden-violet_dark {
  border: 6px hidden var(--violet_dark);
}

.border-all-6-solid-mauve {
  border: 6px solid var(--mauve);
}

.border-all-6-dashed-mauve {
  border: 6px dashed var(--mauve);
}

.border-all-6-dotted-mauve {
  border: 6px dotted var(--mauve);
}

.border-all-6-double-mauve {
  border: 6px double var(--mauve);
}

.border-all-6-groove-mauve {
  border: 6px groove var(--mauve);
}

.border-all-6-ridge-mauve {
  border: 6px ridge var(--mauve);
}

.border-all-6-inset-mauve {
  border: 6px inset var(--mauve);
}

.border-all-6-outset-mauve {
  border: 6px outset var(--mauve);
}

.border-all-6-none-mauve {
  border: 6px none var(--mauve);
}

.border-all-6-hidden-mauve {
  border: 6px hidden var(--mauve);
}

.border-all-6-solid-rose_light {
  border: 6px solid var(--rose_light);
}

.border-all-6-dashed-rose_light {
  border: 6px dashed var(--rose_light);
}

.border-all-6-dotted-rose_light {
  border: 6px dotted var(--rose_light);
}

.border-all-6-double-rose_light {
  border: 6px double var(--rose_light);
}

.border-all-6-groove-rose_light {
  border: 6px groove var(--rose_light);
}

.border-all-6-ridge-rose_light {
  border: 6px ridge var(--rose_light);
}

.border-all-6-inset-rose_light {
  border: 6px inset var(--rose_light);
}

.border-all-6-outset-rose_light {
  border: 6px outset var(--rose_light);
}

.border-all-6-none-rose_light {
  border: 6px none var(--rose_light);
}

.border-all-6-hidden-rose_light {
  border: 6px hidden var(--rose_light);
}

.border-all-6-solid-rose_dark {
  border: 6px solid var(--rose_dark);
}

.border-all-6-dashed-rose_dark {
  border: 6px dashed var(--rose_dark);
}

.border-all-6-dotted-rose_dark {
  border: 6px dotted var(--rose_dark);
}

.border-all-6-double-rose_dark {
  border: 6px double var(--rose_dark);
}

.border-all-6-groove-rose_dark {
  border: 6px groove var(--rose_dark);
}

.border-all-6-ridge-rose_dark {
  border: 6px ridge var(--rose_dark);
}

.border-all-6-inset-rose_dark {
  border: 6px inset var(--rose_dark);
}

.border-all-6-outset-rose_dark {
  border: 6px outset var(--rose_dark);
}

.border-all-6-none-rose_dark {
  border: 6px none var(--rose_dark);
}

.border-all-6-hidden-rose_dark {
  border: 6px hidden var(--rose_dark);
}

.border-all-6-solid-rose_fushia {
  border: 6px solid var(--rose_fushia);
}

.border-all-6-dashed-rose_fushia {
  border: 6px dashed var(--rose_fushia);
}

.border-all-6-dotted-rose_fushia {
  border: 6px dotted var(--rose_fushia);
}

.border-all-6-double-rose_fushia {
  border: 6px double var(--rose_fushia);
}

.border-all-6-groove-rose_fushia {
  border: 6px groove var(--rose_fushia);
}

.border-all-6-ridge-rose_fushia {
  border: 6px ridge var(--rose_fushia);
}

.border-all-6-inset-rose_fushia {
  border: 6px inset var(--rose_fushia);
}

.border-all-6-outset-rose_fushia {
  border: 6px outset var(--rose_fushia);
}

.border-all-6-none-rose_fushia {
  border: 6px none var(--rose_fushia);
}

.border-all-6-hidden-rose_fushia {
  border: 6px hidden var(--rose_fushia);
}

.border-all-6-solid-rouge_framboise {
  border: 6px solid var(--rouge_framboise);
}

.border-all-6-dashed-rouge_framboise {
  border: 6px dashed var(--rouge_framboise);
}

.border-all-6-dotted-rouge_framboise {
  border: 6px dotted var(--rouge_framboise);
}

.border-all-6-double-rouge_framboise {
  border: 6px double var(--rouge_framboise);
}

.border-all-6-groove-rouge_framboise {
  border: 6px groove var(--rouge_framboise);
}

.border-all-6-ridge-rouge_framboise {
  border: 6px ridge var(--rouge_framboise);
}

.border-all-6-inset-rouge_framboise {
  border: 6px inset var(--rouge_framboise);
}

.border-all-6-outset-rouge_framboise {
  border: 6px outset var(--rouge_framboise);
}

.border-all-6-none-rouge_framboise {
  border: 6px none var(--rouge_framboise);
}

.border-all-6-hidden-rouge_framboise {
  border: 6px hidden var(--rouge_framboise);
}

.border-all-6-solid-rose_chair_light {
  border: 6px solid var(--rose_chair_light);
}

.border-all-6-dashed-rose_chair_light {
  border: 6px dashed var(--rose_chair_light);
}

.border-all-6-dotted-rose_chair_light {
  border: 6px dotted var(--rose_chair_light);
}

.border-all-6-double-rose_chair_light {
  border: 6px double var(--rose_chair_light);
}

.border-all-6-groove-rose_chair_light {
  border: 6px groove var(--rose_chair_light);
}

.border-all-6-ridge-rose_chair_light {
  border: 6px ridge var(--rose_chair_light);
}

.border-all-6-inset-rose_chair_light {
  border: 6px inset var(--rose_chair_light);
}

.border-all-6-outset-rose_chair_light {
  border: 6px outset var(--rose_chair_light);
}

.border-all-6-none-rose_chair_light {
  border: 6px none var(--rose_chair_light);
}

.border-all-6-hidden-rose_chair_light {
  border: 6px hidden var(--rose_chair_light);
}

.border-all-6-solid-rose_chair_dark {
  border: 6px solid var(--rose_chair_dark);
}

.border-all-6-dashed-rose_chair_dark {
  border: 6px dashed var(--rose_chair_dark);
}

.border-all-6-dotted-rose_chair_dark {
  border: 6px dotted var(--rose_chair_dark);
}

.border-all-6-double-rose_chair_dark {
  border: 6px double var(--rose_chair_dark);
}

.border-all-6-groove-rose_chair_dark {
  border: 6px groove var(--rose_chair_dark);
}

.border-all-6-ridge-rose_chair_dark {
  border: 6px ridge var(--rose_chair_dark);
}

.border-all-6-inset-rose_chair_dark {
  border: 6px inset var(--rose_chair_dark);
}

.border-all-6-outset-rose_chair_dark {
  border: 6px outset var(--rose_chair_dark);
}

.border-all-6-none-rose_chair_dark {
  border: 6px none var(--rose_chair_dark);
}

.border-all-6-hidden-rose_chair_dark {
  border: 6px hidden var(--rose_chair_dark);
}

.border-all-6-solid-vert_positif {
  border: 6px solid var(--vert_positif);
}

.border-all-6-dashed-vert_positif {
  border: 6px dashed var(--vert_positif);
}

.border-all-6-dotted-vert_positif {
  border: 6px dotted var(--vert_positif);
}

.border-all-6-double-vert_positif {
  border: 6px double var(--vert_positif);
}

.border-all-6-groove-vert_positif {
  border: 6px groove var(--vert_positif);
}

.border-all-6-ridge-vert_positif {
  border: 6px ridge var(--vert_positif);
}

.border-all-6-inset-vert_positif {
  border: 6px inset var(--vert_positif);
}

.border-all-6-outset-vert_positif {
  border: 6px outset var(--vert_positif);
}

.border-all-6-none-vert_positif {
  border: 6px none var(--vert_positif);
}

.border-all-6-hidden-vert_positif {
  border: 6px hidden var(--vert_positif);
}

.border-all-6-solid-rouge_negatif {
  border: 6px solid var(--rouge_negatif);
}

.border-all-6-dashed-rouge_negatif {
  border: 6px dashed var(--rouge_negatif);
}

.border-all-6-dotted-rouge_negatif {
  border: 6px dotted var(--rouge_negatif);
}

.border-all-6-double-rouge_negatif {
  border: 6px double var(--rouge_negatif);
}

.border-all-6-groove-rouge_negatif {
  border: 6px groove var(--rouge_negatif);
}

.border-all-6-ridge-rouge_negatif {
  border: 6px ridge var(--rouge_negatif);
}

.border-all-6-inset-rouge_negatif {
  border: 6px inset var(--rouge_negatif);
}

.border-all-6-outset-rouge_negatif {
  border: 6px outset var(--rouge_negatif);
}

.border-all-6-none-rouge_negatif {
  border: 6px none var(--rouge_negatif);
}

.border-all-6-hidden-rouge_negatif {
  border: 6px hidden var(--rouge_negatif);
}

.border-all-6-solid-bleu_texte {
  border: 6px solid var(--bleu_texte);
}

.border-all-6-dashed-bleu_texte {
  border: 6px dashed var(--bleu_texte);
}

.border-all-6-dotted-bleu_texte {
  border: 6px dotted var(--bleu_texte);
}

.border-all-6-double-bleu_texte {
  border: 6px double var(--bleu_texte);
}

.border-all-6-groove-bleu_texte {
  border: 6px groove var(--bleu_texte);
}

.border-all-6-ridge-bleu_texte {
  border: 6px ridge var(--bleu_texte);
}

.border-all-6-inset-bleu_texte {
  border: 6px inset var(--bleu_texte);
}

.border-all-6-outset-bleu_texte {
  border: 6px outset var(--bleu_texte);
}

.border-all-6-none-bleu_texte {
  border: 6px none var(--bleu_texte);
}

.border-all-6-hidden-bleu_texte {
  border: 6px hidden var(--bleu_texte);
}

.border-all-6-solid-alerte_vert_positif {
  border: 6px solid var(--alerte_vert_positif);
}

.border-all-6-dashed-alerte_vert_positif {
  border: 6px dashed var(--alerte_vert_positif);
}

.border-all-6-dotted-alerte_vert_positif {
  border: 6px dotted var(--alerte_vert_positif);
}

.border-all-6-double-alerte_vert_positif {
  border: 6px double var(--alerte_vert_positif);
}

.border-all-6-groove-alerte_vert_positif {
  border: 6px groove var(--alerte_vert_positif);
}

.border-all-6-ridge-alerte_vert_positif {
  border: 6px ridge var(--alerte_vert_positif);
}

.border-all-6-inset-alerte_vert_positif {
  border: 6px inset var(--alerte_vert_positif);
}

.border-all-6-outset-alerte_vert_positif {
  border: 6px outset var(--alerte_vert_positif);
}

.border-all-6-none-alerte_vert_positif {
  border: 6px none var(--alerte_vert_positif);
}

.border-all-6-hidden-alerte_vert_positif {
  border: 6px hidden var(--alerte_vert_positif);
}

.border-all-6-solid-alerte_rouge_negatif {
  border: 6px solid var(--alerte_rouge_negatif);
}

.border-all-6-dashed-alerte_rouge_negatif {
  border: 6px dashed var(--alerte_rouge_negatif);
}

.border-all-6-dotted-alerte_rouge_negatif {
  border: 6px dotted var(--alerte_rouge_negatif);
}

.border-all-6-double-alerte_rouge_negatif {
  border: 6px double var(--alerte_rouge_negatif);
}

.border-all-6-groove-alerte_rouge_negatif {
  border: 6px groove var(--alerte_rouge_negatif);
}

.border-all-6-ridge-alerte_rouge_negatif {
  border: 6px ridge var(--alerte_rouge_negatif);
}

.border-all-6-inset-alerte_rouge_negatif {
  border: 6px inset var(--alerte_rouge_negatif);
}

.border-all-6-outset-alerte_rouge_negatif {
  border: 6px outset var(--alerte_rouge_negatif);
}

.border-all-6-none-alerte_rouge_negatif {
  border: 6px none var(--alerte_rouge_negatif);
}

.border-all-6-hidden-alerte_rouge_negatif {
  border: 6px hidden var(--alerte_rouge_negatif);
}

.border-all-6-solid-alerte_bleu_information {
  border: 6px solid var(--alerte_bleu_information);
}

.border-all-6-dashed-alerte_bleu_information {
  border: 6px dashed var(--alerte_bleu_information);
}

.border-all-6-dotted-alerte_bleu_information {
  border: 6px dotted var(--alerte_bleu_information);
}

.border-all-6-double-alerte_bleu_information {
  border: 6px double var(--alerte_bleu_information);
}

.border-all-6-groove-alerte_bleu_information {
  border: 6px groove var(--alerte_bleu_information);
}

.border-all-6-ridge-alerte_bleu_information {
  border: 6px ridge var(--alerte_bleu_information);
}

.border-all-6-inset-alerte_bleu_information {
  border: 6px inset var(--alerte_bleu_information);
}

.border-all-6-outset-alerte_bleu_information {
  border: 6px outset var(--alerte_bleu_information);
}

.border-all-6-none-alerte_bleu_information {
  border: 6px none var(--alerte_bleu_information);
}

.border-all-6-hidden-alerte_bleu_information {
  border: 6px hidden var(--alerte_bleu_information);
}

.border-all-6-solid-alerte_jaune_attention {
  border: 6px solid var(--alerte_jaune_attention);
}

.border-all-6-dashed-alerte_jaune_attention {
  border: 6px dashed var(--alerte_jaune_attention);
}

.border-all-6-dotted-alerte_jaune_attention {
  border: 6px dotted var(--alerte_jaune_attention);
}

.border-all-6-double-alerte_jaune_attention {
  border: 6px double var(--alerte_jaune_attention);
}

.border-all-6-groove-alerte_jaune_attention {
  border: 6px groove var(--alerte_jaune_attention);
}

.border-all-6-ridge-alerte_jaune_attention {
  border: 6px ridge var(--alerte_jaune_attention);
}

.border-all-6-inset-alerte_jaune_attention {
  border: 6px inset var(--alerte_jaune_attention);
}

.border-all-6-outset-alerte_jaune_attention {
  border: 6px outset var(--alerte_jaune_attention);
}

.border-all-6-none-alerte_jaune_attention {
  border: 6px none var(--alerte_jaune_attention);
}

.border-all-6-hidden-alerte_jaune_attention {
  border: 6px hidden var(--alerte_jaune_attention);
}

.border-all-6-solid-blanc {
  border: 6px solid #fff;
}

.border-all-6-dashed-blanc {
  border: 6px dashed #fff;
}

.border-all-6-dotted-blanc {
  border: 6px dotted #fff;
}

.border-all-6-double-blanc {
  border: 6px double #fff;
}

.border-all-6-groove-blanc {
  border: 6px groove #fff;
}

.border-all-6-ridge-blanc {
  border: 6px ridge #fff;
}

.border-all-6-inset-blanc {
  border: 6px inset #fff;
}

.border-all-6-outset-blanc {
  border: 6px outset #fff;
}

.border-all-6-none-blanc {
  border: 6px #fff;
}

.border-all-6-hidden-blanc {
  border: 6px hidden #fff;
}

.border-all-6-solid-noir {
  border: 6px solid #222;
}

.border-all-6-dashed-noir {
  border: 6px dashed #222;
}

.border-all-6-dotted-noir {
  border: 6px dotted #222;
}

.border-all-6-double-noir {
  border: 6px double #222;
}

.border-all-6-groove-noir {
  border: 6px groove #222;
}

.border-all-6-ridge-noir {
  border: 6px ridge #222;
}

.border-all-6-inset-noir {
  border: 6px inset #222;
}

.border-all-6-outset-noir {
  border: 6px outset #222;
}

.border-all-6-none-noir {
  border: 6px #222;
}

.border-all-6-hidden-noir {
  border: 6px hidden #222;
}

.border-all-6-solid-gris_light {
  border: 6px solid var(--gris_light);
}

.border-all-6-dashed-gris_light {
  border: 6px dashed var(--gris_light);
}

.border-all-6-dotted-gris_light {
  border: 6px dotted var(--gris_light);
}

.border-all-6-double-gris_light {
  border: 6px double var(--gris_light);
}

.border-all-6-groove-gris_light {
  border: 6px groove var(--gris_light);
}

.border-all-6-ridge-gris_light {
  border: 6px ridge var(--gris_light);
}

.border-all-6-inset-gris_light {
  border: 6px inset var(--gris_light);
}

.border-all-6-outset-gris_light {
  border: 6px outset var(--gris_light);
}

.border-all-6-none-gris_light {
  border: 6px none var(--gris_light);
}

.border-all-6-hidden-gris_light {
  border: 6px hidden var(--gris_light);
}

.border-all-6-solid-gris_dark {
  border: 6px solid var(--gris_dark);
}

.border-all-6-dashed-gris_dark {
  border: 6px dashed var(--gris_dark);
}

.border-all-6-dotted-gris_dark {
  border: 6px dotted var(--gris_dark);
}

.border-all-6-double-gris_dark {
  border: 6px double var(--gris_dark);
}

.border-all-6-groove-gris_dark {
  border: 6px groove var(--gris_dark);
}

.border-all-6-ridge-gris_dark {
  border: 6px ridge var(--gris_dark);
}

.border-all-6-inset-gris_dark {
  border: 6px inset var(--gris_dark);
}

.border-all-6-outset-gris_dark {
  border: 6px outset var(--gris_dark);
}

.border-all-6-none-gris_dark {
  border: 6px none var(--gris_dark);
}

.border-all-6-hidden-gris_dark {
  border: 6px hidden var(--gris_dark);
}

.border-all-6-solid-gris_background {
  border: 6px solid var(--gris_background);
}

.border-all-6-dashed-gris_background {
  border: 6px dashed var(--gris_background);
}

.border-all-6-dotted-gris_background {
  border: 6px dotted var(--gris_background);
}

.border-all-6-double-gris_background {
  border: 6px double var(--gris_background);
}

.border-all-6-groove-gris_background {
  border: 6px groove var(--gris_background);
}

.border-all-6-ridge-gris_background {
  border: 6px ridge var(--gris_background);
}

.border-all-6-inset-gris_background {
  border: 6px inset var(--gris_background);
}

.border-all-6-outset-gris_background {
  border: 6px outset var(--gris_background);
}

.border-all-6-none-gris_background {
  border: 6px none var(--gris_background);
}

.border-all-6-hidden-gris_background {
  border: 6px hidden var(--gris_background);
}

.btn-rond-h56 {
  text-align: center;
  width: 56px !important;
  height: 56px !important;
  padding: 0 !important;
}

.btn-rond-h56 i {
  margin: initial !important;
  font-size: 32px !important;
}

.btn-rond-h40 {
  text-align: center;
  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
}

.btn-rond-h40 i {
  margin: initial !important;
  font-size: 16px !important;
}

.btn-primaire-h56 {
  background: var(--corail_mh_brand);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-h56:not(:disabled):not(.disabled):hover, .btn-primaire-h56:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_dark);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-h56.disabled, .btn-primaire-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-h56:focus {
  outline: none;
}

.btn-primaire-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-h40 {
  background: var(--corail_mh_brand);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-h40:not(:disabled):not(.disabled):hover, .btn-primaire-h40:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_dark);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-h40.disabled, .btn-primaire-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-h40:focus {
  outline: none;
}

.btn-primaire-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-h56 {
  color: var(--corail_mh_brand);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-h56.disabled, .btn-primaire-inverse-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-h56:focus {
  outline: none;
}

.btn-primaire-inverse-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-h40 {
  color: var(--corail_mh_brand);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-h40.disabled, .btn-primaire-inverse-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-h40:focus {
  outline: none;
}

.btn-primaire-inverse-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-h56 {
  color: var(--corail_mh_brand);
  text-align: center;
  border-color: var(--corail_mh_brand);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-h56.disabled, .btn-secondaire-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-h56:focus {
  outline: none;
}

.btn-secondaire-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-h40 {
  color: var(--corail_mh_brand);
  text-align: center;
  border-color: var(--corail_mh_brand);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-h40.disabled, .btn-secondaire-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-h40:focus {
  outline: none;
}

.btn-secondaire-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-corail_mh_brand-h56 {
  color: var(--corail_mh_brand);
  text-align: center;
  border-color: var(--corail_mh_brand);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-corail_mh_brand-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-corail_mh_brand-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-corail_mh_brand-h56.disabled, .btn-secondaire-corail_mh_brand-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-corail_mh_brand-h56:focus {
  outline: none;
}

.btn-secondaire-corail_mh_brand-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-corail_mh_brand-h40 {
  color: var(--corail_mh_brand);
  text-align: center;
  border-color: var(--corail_mh_brand);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-corail_mh_brand-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-corail_mh_brand-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-corail_mh_brand-h40.disabled, .btn-secondaire-corail_mh_brand-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-corail_mh_brand-h40:focus {
  outline: none;
}

.btn-secondaire-corail_mh_brand-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-corail_mh_dark-h56 {
  color: var(--corail_mh_dark);
  text-align: center;
  border-color: var(--corail_mh_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-corail_mh_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-corail_mh_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-corail_mh_dark-h56.disabled, .btn-secondaire-corail_mh_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-corail_mh_dark-h56:focus {
  outline: none;
}

.btn-secondaire-corail_mh_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-corail_mh_dark-h40 {
  color: var(--corail_mh_dark);
  text-align: center;
  border-color: var(--corail_mh_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-corail_mh_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-corail_mh_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-corail_mh_dark-h40.disabled, .btn-secondaire-corail_mh_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-corail_mh_dark-h40:focus {
  outline: none;
}

.btn-secondaire-corail_mh_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-corail_mh_light-h56 {
  color: var(--corail_mh_light);
  text-align: center;
  border-color: var(--corail_mh_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-corail_mh_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-corail_mh_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-corail_mh_light-h56.disabled, .btn-secondaire-corail_mh_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-corail_mh_light-h56:focus {
  outline: none;
}

.btn-secondaire-corail_mh_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-corail_mh_light-h40 {
  color: var(--corail_mh_light);
  text-align: center;
  border-color: var(--corail_mh_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-corail_mh_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-corail_mh_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-corail_mh_light-h40.disabled, .btn-secondaire-corail_mh_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-corail_mh_light-h40:focus {
  outline: none;
}

.btn-secondaire-corail_mh_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_sable_light-h56 {
  color: var(--gris_sable_light);
  text-align: center;
  border-color: var(--gris_sable_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-gris_sable_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_sable_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_sable_light-h56.disabled, .btn-secondaire-gris_sable_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_sable_light-h56:focus {
  outline: none;
}

.btn-secondaire-gris_sable_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_sable_light-h40 {
  color: var(--gris_sable_light);
  text-align: center;
  border-color: var(--gris_sable_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-gris_sable_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_sable_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_sable_light-h40.disabled, .btn-secondaire-gris_sable_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_sable_light-h40:focus {
  outline: none;
}

.btn-secondaire-gris_sable_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_sable_dark-h56 {
  color: var(--gris_sable_dark);
  text-align: center;
  border-color: var(--gris_sable_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-gris_sable_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_sable_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_sable_dark-h56.disabled, .btn-secondaire-gris_sable_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_sable_dark-h56:focus {
  outline: none;
}

.btn-secondaire-gris_sable_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_sable_dark-h40 {
  color: var(--gris_sable_dark);
  text-align: center;
  border-color: var(--gris_sable_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-gris_sable_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_sable_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_sable_dark-h40.disabled, .btn-secondaire-gris_sable_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_sable_dark-h40:focus {
  outline: none;
}

.btn-secondaire-gris_sable_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_ivoire_light-h56 {
  color: var(--jaune_ivoire_light);
  text-align: center;
  border-color: var(--jaune_ivoire_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-jaune_ivoire_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_ivoire_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_ivoire_light-h56.disabled, .btn-secondaire-jaune_ivoire_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_ivoire_light-h56:focus {
  outline: none;
}

.btn-secondaire-jaune_ivoire_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_ivoire_light-h40 {
  color: var(--jaune_ivoire_light);
  text-align: center;
  border-color: var(--jaune_ivoire_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-jaune_ivoire_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_ivoire_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_ivoire_light-h40.disabled, .btn-secondaire-jaune_ivoire_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_ivoire_light-h40:focus {
  outline: none;
}

.btn-secondaire-jaune_ivoire_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_ivoire_dark-h56 {
  color: var(--jaune_ivoire_dark);
  text-align: center;
  border-color: var(--jaune_ivoire_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-jaune_ivoire_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_ivoire_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_ivoire_dark-h56.disabled, .btn-secondaire-jaune_ivoire_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_ivoire_dark-h56:focus {
  outline: none;
}

.btn-secondaire-jaune_ivoire_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_ivoire_dark-h40 {
  color: var(--jaune_ivoire_dark);
  text-align: center;
  border-color: var(--jaune_ivoire_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-jaune_ivoire_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_ivoire_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_ivoire_dark-h40.disabled, .btn-secondaire-jaune_ivoire_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_ivoire_dark-h40:focus {
  outline: none;
}

.btn-secondaire-jaune_ivoire_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_light-h56 {
  color: var(--jaune_light);
  text-align: center;
  border-color: var(--jaune_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-jaune_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_light-h56.disabled, .btn-secondaire-jaune_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_light-h56:focus {
  outline: none;
}

.btn-secondaire-jaune_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_light-h40 {
  color: var(--jaune_light);
  text-align: center;
  border-color: var(--jaune_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-jaune_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_light-h40.disabled, .btn-secondaire-jaune_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_light-h40:focus {
  outline: none;
}

.btn-secondaire-jaune_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_dark-h56 {
  color: var(--jaune_dark);
  text-align: center;
  border-color: var(--jaune_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-jaune_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_dark-h56.disabled, .btn-secondaire-jaune_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_dark-h56:focus {
  outline: none;
}

.btn-secondaire-jaune_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-jaune_dark-h40 {
  color: var(--jaune_dark);
  text-align: center;
  border-color: var(--jaune_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-jaune_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-jaune_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-jaune_dark-h40.disabled, .btn-secondaire-jaune_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-jaune_dark-h40:focus {
  outline: none;
}

.btn-secondaire-jaune_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-orange_normal-h56 {
  color: var(--orange_normal);
  text-align: center;
  border-color: var(--orange_normal);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-orange_normal-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-orange_normal-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-orange_normal-h56.disabled, .btn-secondaire-orange_normal-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-orange_normal-h56:focus {
  outline: none;
}

.btn-secondaire-orange_normal-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-orange_normal-h40 {
  color: var(--orange_normal);
  text-align: center;
  border-color: var(--orange_normal);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-orange_normal-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-orange_normal-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-orange_normal-h40.disabled, .btn-secondaire-orange_normal-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-orange_normal-h40:focus {
  outline: none;
}

.btn-secondaire-orange_normal-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-orange_dark-h56 {
  color: var(--orange_dark);
  text-align: center;
  border-color: var(--orange_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-orange_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-orange_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-orange_dark-h56.disabled, .btn-secondaire-orange_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-orange_dark-h56:focus {
  outline: none;
}

.btn-secondaire-orange_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-orange_dark-h40 {
  color: var(--orange_dark);
  text-align: center;
  border-color: var(--orange_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-orange_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-orange_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-orange_dark-h40.disabled, .btn-secondaire-orange_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-orange_dark-h40:focus {
  outline: none;
}

.btn-secondaire-orange_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-vert_light-h56 {
  color: var(--vert_light);
  text-align: center;
  border-color: var(--vert_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-vert_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-vert_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-vert_light-h56.disabled, .btn-secondaire-vert_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-vert_light-h56:focus {
  outline: none;
}

.btn-secondaire-vert_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-vert_light-h40 {
  color: var(--vert_light);
  text-align: center;
  border-color: var(--vert_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-vert_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-vert_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-vert_light-h40.disabled, .btn-secondaire-vert_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-vert_light-h40:focus {
  outline: none;
}

.btn-secondaire-vert_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-vert_dark-h56 {
  color: var(--vert_dark);
  text-align: center;
  border-color: var(--vert_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-vert_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-vert_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-vert_dark-h56.disabled, .btn-secondaire-vert_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-vert_dark-h56:focus {
  outline: none;
}

.btn-secondaire-vert_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-vert_dark-h40 {
  color: var(--vert_dark);
  text-align: center;
  border-color: var(--vert_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-vert_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-vert_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-vert_dark-h40.disabled, .btn-secondaire-vert_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-vert_dark-h40:focus {
  outline: none;
}

.btn-secondaire-vert_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_turquoise_light-h56 {
  color: var(--bleu_turquoise_light);
  text-align: center;
  border-color: var(--bleu_turquoise_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_turquoise_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_turquoise_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_turquoise_light-h56.disabled, .btn-secondaire-bleu_turquoise_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_turquoise_light-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_turquoise_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_turquoise_light-h40 {
  color: var(--bleu_turquoise_light);
  text-align: center;
  border-color: var(--bleu_turquoise_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_turquoise_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_turquoise_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_turquoise_light-h40.disabled, .btn-secondaire-bleu_turquoise_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_turquoise_light-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_turquoise_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_turquoise_middle-h56 {
  color: var(--bleu_turquoise_middle);
  text-align: center;
  border-color: var(--bleu_turquoise_middle);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_turquoise_middle-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_turquoise_middle-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_turquoise_middle-h56.disabled, .btn-secondaire-bleu_turquoise_middle-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_turquoise_middle-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_turquoise_middle-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_turquoise_middle-h40 {
  color: var(--bleu_turquoise_middle);
  text-align: center;
  border-color: var(--bleu_turquoise_middle);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_turquoise_middle-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_turquoise_middle-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_turquoise_middle-h40.disabled, .btn-secondaire-bleu_turquoise_middle-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_turquoise_middle-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_turquoise_middle-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_turquoise_dark-h56 {
  color: var(--bleu_turquoise_dark);
  text-align: center;
  border-color: var(--bleu_turquoise_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_turquoise_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_turquoise_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_turquoise_dark-h56.disabled, .btn-secondaire-bleu_turquoise_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_turquoise_dark-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_turquoise_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_turquoise_dark-h40 {
  color: var(--bleu_turquoise_dark);
  text-align: center;
  border-color: var(--bleu_turquoise_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_turquoise_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_turquoise_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_turquoise_dark-h40.disabled, .btn-secondaire-bleu_turquoise_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_turquoise_dark-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_turquoise_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_light-h56 {
  color: var(--bleu_light);
  text-align: center;
  border-color: var(--bleu_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_light-h56.disabled, .btn-secondaire-bleu_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_light-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_light-h40 {
  color: var(--bleu_light);
  text-align: center;
  border-color: var(--bleu_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_light-h40.disabled, .btn-secondaire-bleu_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_light-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_dark-h56 {
  color: var(--bleu_dark);
  text-align: center;
  border-color: var(--bleu_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_dark-h56.disabled, .btn-secondaire-bleu_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_dark-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_dark-h40 {
  color: var(--bleu_dark);
  text-align: center;
  border-color: var(--bleu_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_dark-h40.disabled, .btn-secondaire-bleu_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_dark-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_klein_light-h56 {
  color: var(--bleu_klein_light);
  text-align: center;
  border-color: var(--bleu_klein_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_klein_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_klein_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_klein_light-h56.disabled, .btn-secondaire-bleu_klein_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_klein_light-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_klein_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_klein_light-h40 {
  color: var(--bleu_klein_light);
  text-align: center;
  border-color: var(--bleu_klein_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_klein_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_klein_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_klein_light-h40.disabled, .btn-secondaire-bleu_klein_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_klein_light-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_klein_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_klein_dark-h56 {
  color: var(--bleu_klein_dark);
  text-align: center;
  border-color: var(--bleu_klein_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_klein_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_klein_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_klein_dark-h56.disabled, .btn-secondaire-bleu_klein_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_klein_dark-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_klein_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_klein_dark-h40 {
  color: var(--bleu_klein_dark);
  text-align: center;
  border-color: var(--bleu_klein_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_klein_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_klein_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_klein_dark-h40.disabled, .btn-secondaire-bleu_klein_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_klein_dark-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_klein_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-violet_light-h56 {
  color: var(--violet_light);
  text-align: center;
  border-color: var(--violet_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-violet_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-violet_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-violet_light-h56.disabled, .btn-secondaire-violet_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-violet_light-h56:focus {
  outline: none;
}

.btn-secondaire-violet_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-violet_light-h40 {
  color: var(--violet_light);
  text-align: center;
  border-color: var(--violet_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-violet_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-violet_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-violet_light-h40.disabled, .btn-secondaire-violet_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-violet_light-h40:focus {
  outline: none;
}

.btn-secondaire-violet_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-violet_dark-h56 {
  color: var(--violet_dark);
  text-align: center;
  border-color: var(--violet_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-violet_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-violet_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-violet_dark-h56.disabled, .btn-secondaire-violet_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-violet_dark-h56:focus {
  outline: none;
}

.btn-secondaire-violet_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-violet_dark-h40 {
  color: var(--violet_dark);
  text-align: center;
  border-color: var(--violet_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-violet_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-violet_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-violet_dark-h40.disabled, .btn-secondaire-violet_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-violet_dark-h40:focus {
  outline: none;
}

.btn-secondaire-violet_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-mauve-h56 {
  color: var(--mauve);
  text-align: center;
  border-color: var(--mauve);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-mauve-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-mauve-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-mauve-h56.disabled, .btn-secondaire-mauve-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-mauve-h56:focus {
  outline: none;
}

.btn-secondaire-mauve-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-mauve-h40 {
  color: var(--mauve);
  text-align: center;
  border-color: var(--mauve);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-mauve-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-mauve-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-mauve-h40.disabled, .btn-secondaire-mauve-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-mauve-h40:focus {
  outline: none;
}

.btn-secondaire-mauve-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_light-h56 {
  color: var(--rose_light);
  text-align: center;
  border-color: var(--rose_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rose_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_light-h56.disabled, .btn-secondaire-rose_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_light-h56:focus {
  outline: none;
}

.btn-secondaire-rose_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_light-h40 {
  color: var(--rose_light);
  text-align: center;
  border-color: var(--rose_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rose_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_light-h40.disabled, .btn-secondaire-rose_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_light-h40:focus {
  outline: none;
}

.btn-secondaire-rose_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_dark-h56 {
  color: var(--rose_dark);
  text-align: center;
  border-color: var(--rose_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rose_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_dark-h56.disabled, .btn-secondaire-rose_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_dark-h56:focus {
  outline: none;
}

.btn-secondaire-rose_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_dark-h40 {
  color: var(--rose_dark);
  text-align: center;
  border-color: var(--rose_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rose_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_dark-h40.disabled, .btn-secondaire-rose_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_dark-h40:focus {
  outline: none;
}

.btn-secondaire-rose_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_fushia-h56 {
  color: var(--rose_fushia);
  text-align: center;
  border-color: var(--rose_fushia);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rose_fushia-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_fushia-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_fushia-h56.disabled, .btn-secondaire-rose_fushia-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_fushia-h56:focus {
  outline: none;
}

.btn-secondaire-rose_fushia-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_fushia-h40 {
  color: var(--rose_fushia);
  text-align: center;
  border-color: var(--rose_fushia);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rose_fushia-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_fushia-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_fushia-h40.disabled, .btn-secondaire-rose_fushia-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_fushia-h40:focus {
  outline: none;
}

.btn-secondaire-rose_fushia-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rouge_framboise-h56 {
  color: var(--rouge_framboise);
  text-align: center;
  border-color: var(--rouge_framboise);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rouge_framboise-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rouge_framboise-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rouge_framboise-h56.disabled, .btn-secondaire-rouge_framboise-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rouge_framboise-h56:focus {
  outline: none;
}

.btn-secondaire-rouge_framboise-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rouge_framboise-h40 {
  color: var(--rouge_framboise);
  text-align: center;
  border-color: var(--rouge_framboise);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rouge_framboise-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rouge_framboise-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rouge_framboise-h40.disabled, .btn-secondaire-rouge_framboise-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rouge_framboise-h40:focus {
  outline: none;
}

.btn-secondaire-rouge_framboise-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_chair_light-h56 {
  color: var(--rose_chair_light);
  text-align: center;
  border-color: var(--rose_chair_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rose_chair_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_chair_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_chair_light-h56.disabled, .btn-secondaire-rose_chair_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_chair_light-h56:focus {
  outline: none;
}

.btn-secondaire-rose_chair_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_chair_light-h40 {
  color: var(--rose_chair_light);
  text-align: center;
  border-color: var(--rose_chair_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rose_chair_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_chair_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_chair_light-h40.disabled, .btn-secondaire-rose_chair_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_chair_light-h40:focus {
  outline: none;
}

.btn-secondaire-rose_chair_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_chair_dark-h56 {
  color: var(--rose_chair_dark);
  text-align: center;
  border-color: var(--rose_chair_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rose_chair_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_chair_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_chair_dark-h56.disabled, .btn-secondaire-rose_chair_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_chair_dark-h56:focus {
  outline: none;
}

.btn-secondaire-rose_chair_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rose_chair_dark-h40 {
  color: var(--rose_chair_dark);
  text-align: center;
  border-color: var(--rose_chair_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rose_chair_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rose_chair_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rose_chair_dark-h40.disabled, .btn-secondaire-rose_chair_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rose_chair_dark-h40:focus {
  outline: none;
}

.btn-secondaire-rose_chair_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-vert_positif-h56 {
  color: var(--vert_positif);
  text-align: center;
  border-color: var(--vert_positif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-vert_positif-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-vert_positif-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-vert_positif-h56.disabled, .btn-secondaire-vert_positif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-vert_positif-h56:focus {
  outline: none;
}

.btn-secondaire-vert_positif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-vert_positif-h40 {
  color: var(--vert_positif);
  text-align: center;
  border-color: var(--vert_positif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-vert_positif-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-vert_positif-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-vert_positif-h40.disabled, .btn-secondaire-vert_positif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-vert_positif-h40:focus {
  outline: none;
}

.btn-secondaire-vert_positif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rouge_negatif-h56 {
  color: var(--rouge_negatif);
  text-align: center;
  border-color: var(--rouge_negatif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-rouge_negatif-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-rouge_negatif-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rouge_negatif-h56.disabled, .btn-secondaire-rouge_negatif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rouge_negatif-h56:focus {
  outline: none;
}

.btn-secondaire-rouge_negatif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-rouge_negatif-h40 {
  color: var(--rouge_negatif);
  text-align: center;
  border-color: var(--rouge_negatif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-rouge_negatif-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-rouge_negatif-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-rouge_negatif-h40.disabled, .btn-secondaire-rouge_negatif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-rouge_negatif-h40:focus {
  outline: none;
}

.btn-secondaire-rouge_negatif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_texte-h56 {
  color: var(--bleu_texte);
  text-align: center;
  border-color: var(--bleu_texte);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-bleu_texte-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_texte-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_texte-h56.disabled, .btn-secondaire-bleu_texte-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_texte-h56:focus {
  outline: none;
}

.btn-secondaire-bleu_texte-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-bleu_texte-h40 {
  color: var(--bleu_texte);
  text-align: center;
  border-color: var(--bleu_texte);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-bleu_texte-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-bleu_texte-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-bleu_texte-h40.disabled, .btn-secondaire-bleu_texte-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-bleu_texte-h40:focus {
  outline: none;
}

.btn-secondaire-bleu_texte-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_vert_positif-h56 {
  color: var(--alerte_vert_positif);
  text-align: center;
  border-color: var(--alerte_vert_positif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-alerte_vert_positif-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_vert_positif-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_vert_positif-h56.disabled, .btn-secondaire-alerte_vert_positif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_vert_positif-h56:focus {
  outline: none;
}

.btn-secondaire-alerte_vert_positif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_vert_positif-h40 {
  color: var(--alerte_vert_positif);
  text-align: center;
  border-color: var(--alerte_vert_positif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-alerte_vert_positif-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_vert_positif-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_vert_positif-h40.disabled, .btn-secondaire-alerte_vert_positif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_vert_positif-h40:focus {
  outline: none;
}

.btn-secondaire-alerte_vert_positif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_rouge_negatif-h56 {
  color: var(--alerte_rouge_negatif);
  text-align: center;
  border-color: var(--alerte_rouge_negatif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-alerte_rouge_negatif-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_rouge_negatif-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_rouge_negatif-h56.disabled, .btn-secondaire-alerte_rouge_negatif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_rouge_negatif-h56:focus {
  outline: none;
}

.btn-secondaire-alerte_rouge_negatif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_rouge_negatif-h40 {
  color: var(--alerte_rouge_negatif);
  text-align: center;
  border-color: var(--alerte_rouge_negatif);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-alerte_rouge_negatif-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_rouge_negatif-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_rouge_negatif-h40.disabled, .btn-secondaire-alerte_rouge_negatif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_rouge_negatif-h40:focus {
  outline: none;
}

.btn-secondaire-alerte_rouge_negatif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_bleu_information-h56 {
  color: var(--alerte_bleu_information);
  text-align: center;
  border-color: var(--alerte_bleu_information);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-alerte_bleu_information-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_bleu_information-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_bleu_information-h56.disabled, .btn-secondaire-alerte_bleu_information-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_bleu_information-h56:focus {
  outline: none;
}

.btn-secondaire-alerte_bleu_information-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_bleu_information-h40 {
  color: var(--alerte_bleu_information);
  text-align: center;
  border-color: var(--alerte_bleu_information);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-alerte_bleu_information-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_bleu_information-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_bleu_information-h40.disabled, .btn-secondaire-alerte_bleu_information-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_bleu_information-h40:focus {
  outline: none;
}

.btn-secondaire-alerte_bleu_information-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_jaune_attention-h56 {
  color: var(--alerte_jaune_attention);
  text-align: center;
  border-color: var(--alerte_jaune_attention);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-alerte_jaune_attention-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_jaune_attention-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_jaune_attention-h56.disabled, .btn-secondaire-alerte_jaune_attention-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_jaune_attention-h56:focus {
  outline: none;
}

.btn-secondaire-alerte_jaune_attention-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-alerte_jaune_attention-h40 {
  color: var(--alerte_jaune_attention);
  text-align: center;
  border-color: var(--alerte_jaune_attention);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-alerte_jaune_attention-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-alerte_jaune_attention-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-alerte_jaune_attention-h40.disabled, .btn-secondaire-alerte_jaune_attention-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-alerte_jaune_attention-h40:focus {
  outline: none;
}

.btn-secondaire-alerte_jaune_attention-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-blanc-h56 {
  color: #fff;
  text-align: center;
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-blanc-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-blanc-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-blanc-h56.disabled, .btn-secondaire-blanc-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-blanc-h56:focus {
  outline: none;
}

.btn-secondaire-blanc-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-blanc-h40 {
  color: #fff;
  text-align: center;
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-blanc-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-blanc-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-blanc-h40.disabled, .btn-secondaire-blanc-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-blanc-h40:focus {
  outline: none;
}

.btn-secondaire-blanc-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-noir-h56 {
  color: #222;
  text-align: center;
  border-color: var(--gris_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-noir-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-noir-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-noir-h56.disabled, .btn-secondaire-noir-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-noir-h56:focus {
  outline: none;
}

.btn-secondaire-noir-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-noir-h40 {
  color: #222;
  text-align: center;
  border-color: var(--gris_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-noir-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-noir-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-noir-h40.disabled, .btn-secondaire-noir-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-noir-h40:focus {
  outline: none;
}

.btn-secondaire-noir-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_light-h56 {
  color: var(--gris_light);
  text-align: center;
  border-color: var(--gris_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-gris_light-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_light-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_light-h56.disabled, .btn-secondaire-gris_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_light-h56:focus {
  outline: none;
}

.btn-secondaire-gris_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_light-h40 {
  color: var(--gris_light);
  text-align: center;
  border-color: var(--gris_light);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-gris_light-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_light-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_light-h40.disabled, .btn-secondaire-gris_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_light-h40:focus {
  outline: none;
}

.btn-secondaire-gris_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_dark-h56 {
  color: var(--gris_dark);
  text-align: center;
  border-color: var(--gris_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-gris_dark-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_dark-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_dark-h56.disabled, .btn-secondaire-gris_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_dark-h56:focus {
  outline: none;
}

.btn-secondaire-gris_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_dark-h40 {
  color: var(--gris_dark);
  text-align: center;
  border-color: var(--gris_dark);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-gris_dark-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_dark-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_dark-h40.disabled, .btn-secondaire-gris_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_dark-h40:focus {
  outline: none;
}

.btn-secondaire-gris_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_background-h56 {
  color: var(--gris_background);
  text-align: center;
  border-color: var(--gris_background);
  background-color: #0000;
  border: 1px solid;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-secondaire-gris_background-h56:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_background-h56:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_background-h56.disabled, .btn-secondaire-gris_background-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_background-h56:focus {
  outline: none;
}

.btn-secondaire-gris_background-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-secondaire-gris_background-h40 {
  color: var(--gris_background);
  text-align: center;
  border-color: var(--gris_background);
  background-color: #0000;
  border: 1px solid;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-secondaire-gris_background-h40:not(:disabled):not(.disabled):hover, .btn-secondaire-gris_background-h40:not(:disabled):not(.disabled):focus {
  background: rgba(var(--gris_light-rgb), .8);
  background-color: rgba(var(--gris_background-rgb), .8);
  transition: all .3s;
  outline: none !important;
}

.btn-secondaire-gris_background-h40.disabled, .btn-secondaire-gris_background-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-secondaire-gris_background-h40:focus {
  outline: none;
}

.btn-secondaire-gris_background-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-corail_mh_brand-h56 {
  background: var(--corail_mh_brand);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-corail_mh_brand-h56:not(:disabled):not(.disabled):hover, .btn-primaire-corail_mh_brand-h56:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_brand_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-corail_mh_brand-h56.disabled, .btn-primaire-corail_mh_brand-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-corail_mh_brand-h56:focus {
  outline: none;
}

.btn-primaire-corail_mh_brand-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-corail_mh_brand-h40 {
  background: var(--corail_mh_brand);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-corail_mh_brand-h40:not(:disabled):not(.disabled):hover, .btn-primaire-corail_mh_brand-h40:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_brand_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-corail_mh_brand-h40.disabled, .btn-primaire-corail_mh_brand-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-corail_mh_brand-h40:focus {
  outline: none;
}

.btn-primaire-corail_mh_brand-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-corail_mh_dark-h56 {
  background: var(--corail_mh_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-corail_mh_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-corail_mh_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-corail_mh_dark-h56.disabled, .btn-primaire-corail_mh_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-corail_mh_dark-h56:focus {
  outline: none;
}

.btn-primaire-corail_mh_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-corail_mh_dark-h40 {
  background: var(--corail_mh_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-corail_mh_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-corail_mh_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-corail_mh_dark-h40.disabled, .btn-primaire-corail_mh_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-corail_mh_dark-h40:focus {
  outline: none;
}

.btn-primaire-corail_mh_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-corail_mh_light-h56 {
  background: var(--corail_mh_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-corail_mh_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-corail_mh_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-corail_mh_light-h56.disabled, .btn-primaire-corail_mh_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-corail_mh_light-h56:focus {
  outline: none;
}

.btn-primaire-corail_mh_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-corail_mh_light-h40 {
  background: var(--corail_mh_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-corail_mh_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-corail_mh_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--corail_mh_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-corail_mh_light-h40.disabled, .btn-primaire-corail_mh_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-corail_mh_light-h40:focus {
  outline: none;
}

.btn-primaire-corail_mh_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_sable_light-h56 {
  background: var(--gris_sable_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-gris_sable_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-gris_sable_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_sable_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_sable_light-h56.disabled, .btn-primaire-gris_sable_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_sable_light-h56:focus {
  outline: none;
}

.btn-primaire-gris_sable_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_sable_light-h40 {
  background: var(--gris_sable_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-gris_sable_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-gris_sable_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_sable_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_sable_light-h40.disabled, .btn-primaire-gris_sable_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_sable_light-h40:focus {
  outline: none;
}

.btn-primaire-gris_sable_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_sable_dark-h56 {
  background: var(--gris_sable_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-gris_sable_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-gris_sable_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_sable_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_sable_dark-h56.disabled, .btn-primaire-gris_sable_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_sable_dark-h56:focus {
  outline: none;
}

.btn-primaire-gris_sable_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_sable_dark-h40 {
  background: var(--gris_sable_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-gris_sable_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-gris_sable_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_sable_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_sable_dark-h40.disabled, .btn-primaire-gris_sable_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_sable_dark-h40:focus {
  outline: none;
}

.btn-primaire-gris_sable_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_ivoire_light-h56 {
  background: var(--jaune_ivoire_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-jaune_ivoire_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_ivoire_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--jaune_ivoire_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_ivoire_light-h56.disabled, .btn-primaire-jaune_ivoire_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_ivoire_light-h56:focus {
  outline: none;
}

.btn-primaire-jaune_ivoire_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_ivoire_light-h40 {
  background: var(--jaune_ivoire_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-jaune_ivoire_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_ivoire_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--jaune_ivoire_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_ivoire_light-h40.disabled, .btn-primaire-jaune_ivoire_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_ivoire_light-h40:focus {
  outline: none;
}

.btn-primaire-jaune_ivoire_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_ivoire_dark-h56 {
  background: var(--jaune_ivoire_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-jaune_ivoire_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_ivoire_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--jaune_ivoire_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_ivoire_dark-h56.disabled, .btn-primaire-jaune_ivoire_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_ivoire_dark-h56:focus {
  outline: none;
}

.btn-primaire-jaune_ivoire_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_ivoire_dark-h40 {
  background: var(--jaune_ivoire_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-jaune_ivoire_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_ivoire_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--jaune_ivoire_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_ivoire_dark-h40.disabled, .btn-primaire-jaune_ivoire_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_ivoire_dark-h40:focus {
  outline: none;
}

.btn-primaire-jaune_ivoire_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_light-h56 {
  background: var(--jaune_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-jaune_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--jaune_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_light-h56.disabled, .btn-primaire-jaune_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_light-h56:focus {
  outline: none;
}

.btn-primaire-jaune_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_light-h40 {
  background: var(--jaune_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-jaune_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--jaune_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_light-h40.disabled, .btn-primaire-jaune_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_light-h40:focus {
  outline: none;
}

.btn-primaire-jaune_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_dark-h56 {
  background: var(--jaune_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-jaune_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--jaune_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_dark-h56.disabled, .btn-primaire-jaune_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_dark-h56:focus {
  outline: none;
}

.btn-primaire-jaune_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-jaune_dark-h40 {
  background: var(--jaune_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-jaune_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-jaune_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--jaune_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-jaune_dark-h40.disabled, .btn-primaire-jaune_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-jaune_dark-h40:focus {
  outline: none;
}

.btn-primaire-jaune_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-orange_normal-h56 {
  background: var(--orange_normal);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-orange_normal-h56:not(:disabled):not(.disabled):hover, .btn-primaire-orange_normal-h56:not(:disabled):not(.disabled):focus {
  background: var(--orange_normal_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-orange_normal-h56.disabled, .btn-primaire-orange_normal-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-orange_normal-h56:focus {
  outline: none;
}

.btn-primaire-orange_normal-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-orange_normal-h40 {
  background: var(--orange_normal);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-orange_normal-h40:not(:disabled):not(.disabled):hover, .btn-primaire-orange_normal-h40:not(:disabled):not(.disabled):focus {
  background: var(--orange_normal_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-orange_normal-h40.disabled, .btn-primaire-orange_normal-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-orange_normal-h40:focus {
  outline: none;
}

.btn-primaire-orange_normal-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-orange_dark-h56 {
  background: var(--orange_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-orange_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-orange_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--orange_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-orange_dark-h56.disabled, .btn-primaire-orange_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-orange_dark-h56:focus {
  outline: none;
}

.btn-primaire-orange_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-orange_dark-h40 {
  background: var(--orange_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-orange_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-orange_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--orange_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-orange_dark-h40.disabled, .btn-primaire-orange_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-orange_dark-h40:focus {
  outline: none;
}

.btn-primaire-orange_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-vert_light-h56 {
  background: var(--vert_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-vert_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-vert_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--vert_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-vert_light-h56.disabled, .btn-primaire-vert_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-vert_light-h56:focus {
  outline: none;
}

.btn-primaire-vert_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-vert_light-h40 {
  background: var(--vert_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-vert_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-vert_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--vert_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-vert_light-h40.disabled, .btn-primaire-vert_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-vert_light-h40:focus {
  outline: none;
}

.btn-primaire-vert_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-vert_dark-h56 {
  background: var(--vert_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-vert_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-vert_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--vert_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-vert_dark-h56.disabled, .btn-primaire-vert_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-vert_dark-h56:focus {
  outline: none;
}

.btn-primaire-vert_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-vert_dark-h40 {
  background: var(--vert_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-vert_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-vert_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--vert_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-vert_dark-h40.disabled, .btn-primaire-vert_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-vert_dark-h40:focus {
  outline: none;
}

.btn-primaire-vert_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_turquoise_light-h56 {
  background: var(--bleu_turquoise_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_turquoise_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_turquoise_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_turquoise_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_turquoise_light-h56.disabled, .btn-primaire-bleu_turquoise_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_turquoise_light-h56:focus {
  outline: none;
}

.btn-primaire-bleu_turquoise_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_turquoise_light-h40 {
  background: var(--bleu_turquoise_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_turquoise_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_turquoise_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_turquoise_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_turquoise_light-h40.disabled, .btn-primaire-bleu_turquoise_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_turquoise_light-h40:focus {
  outline: none;
}

.btn-primaire-bleu_turquoise_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_turquoise_middle-h56 {
  background: var(--bleu_turquoise_middle);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_turquoise_middle-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_turquoise_middle-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_turquoise_middle_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_turquoise_middle-h56.disabled, .btn-primaire-bleu_turquoise_middle-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_turquoise_middle-h56:focus {
  outline: none;
}

.btn-primaire-bleu_turquoise_middle-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_turquoise_middle-h40 {
  background: var(--bleu_turquoise_middle);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_turquoise_middle-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_turquoise_middle-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_turquoise_middle_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_turquoise_middle-h40.disabled, .btn-primaire-bleu_turquoise_middle-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_turquoise_middle-h40:focus {
  outline: none;
}

.btn-primaire-bleu_turquoise_middle-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_turquoise_dark-h56 {
  background: var(--bleu_turquoise_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_turquoise_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_turquoise_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_turquoise_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_turquoise_dark-h56.disabled, .btn-primaire-bleu_turquoise_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_turquoise_dark-h56:focus {
  outline: none;
}

.btn-primaire-bleu_turquoise_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_turquoise_dark-h40 {
  background: var(--bleu_turquoise_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_turquoise_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_turquoise_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_turquoise_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_turquoise_dark-h40.disabled, .btn-primaire-bleu_turquoise_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_turquoise_dark-h40:focus {
  outline: none;
}

.btn-primaire-bleu_turquoise_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_light-h56 {
  background: var(--bleu_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_light-h56.disabled, .btn-primaire-bleu_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_light-h56:focus {
  outline: none;
}

.btn-primaire-bleu_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_light-h40 {
  background: var(--bleu_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_light-h40.disabled, .btn-primaire-bleu_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_light-h40:focus {
  outline: none;
}

.btn-primaire-bleu_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_dark-h56 {
  background: var(--bleu_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_dark-h56.disabled, .btn-primaire-bleu_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_dark-h56:focus {
  outline: none;
}

.btn-primaire-bleu_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_dark-h40 {
  background: var(--bleu_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_dark-h40.disabled, .btn-primaire-bleu_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_dark-h40:focus {
  outline: none;
}

.btn-primaire-bleu_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_klein_light-h56 {
  background: var(--bleu_klein_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_klein_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_klein_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_klein_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_klein_light-h56.disabled, .btn-primaire-bleu_klein_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_klein_light-h56:focus {
  outline: none;
}

.btn-primaire-bleu_klein_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_klein_light-h40 {
  background: var(--bleu_klein_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_klein_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_klein_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_klein_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_klein_light-h40.disabled, .btn-primaire-bleu_klein_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_klein_light-h40:focus {
  outline: none;
}

.btn-primaire-bleu_klein_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_klein_dark-h56 {
  background: var(--bleu_klein_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_klein_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_klein_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_klein_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_klein_dark-h56.disabled, .btn-primaire-bleu_klein_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_klein_dark-h56:focus {
  outline: none;
}

.btn-primaire-bleu_klein_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_klein_dark-h40 {
  background: var(--bleu_klein_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_klein_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_klein_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_klein_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_klein_dark-h40.disabled, .btn-primaire-bleu_klein_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_klein_dark-h40:focus {
  outline: none;
}

.btn-primaire-bleu_klein_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-violet_light-h56 {
  background: var(--violet_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-violet_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-violet_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--violet_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-violet_light-h56.disabled, .btn-primaire-violet_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-violet_light-h56:focus {
  outline: none;
}

.btn-primaire-violet_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-violet_light-h40 {
  background: var(--violet_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-violet_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-violet_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--violet_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-violet_light-h40.disabled, .btn-primaire-violet_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-violet_light-h40:focus {
  outline: none;
}

.btn-primaire-violet_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-violet_dark-h56 {
  background: var(--violet_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-violet_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-violet_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--violet_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-violet_dark-h56.disabled, .btn-primaire-violet_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-violet_dark-h56:focus {
  outline: none;
}

.btn-primaire-violet_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-violet_dark-h40 {
  background: var(--violet_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-violet_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-violet_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--violet_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-violet_dark-h40.disabled, .btn-primaire-violet_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-violet_dark-h40:focus {
  outline: none;
}

.btn-primaire-violet_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-mauve-h56 {
  background: var(--mauve);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-mauve-h56:not(:disabled):not(.disabled):hover, .btn-primaire-mauve-h56:not(:disabled):not(.disabled):focus {
  background: var(--mauve_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-mauve-h56.disabled, .btn-primaire-mauve-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-mauve-h56:focus {
  outline: none;
}

.btn-primaire-mauve-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-mauve-h40 {
  background: var(--mauve);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-mauve-h40:not(:disabled):not(.disabled):hover, .btn-primaire-mauve-h40:not(:disabled):not(.disabled):focus {
  background: var(--mauve_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-mauve-h40.disabled, .btn-primaire-mauve-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-mauve-h40:focus {
  outline: none;
}

.btn-primaire-mauve-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_light-h56 {
  background: var(--rose_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rose_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rose_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--rose_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_light-h56.disabled, .btn-primaire-rose_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_light-h56:focus {
  outline: none;
}

.btn-primaire-rose_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_light-h40 {
  background: var(--rose_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rose_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rose_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--rose_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_light-h40.disabled, .btn-primaire-rose_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_light-h40:focus {
  outline: none;
}

.btn-primaire-rose_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_dark-h56 {
  background: var(--rose_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rose_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rose_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--rose_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_dark-h56.disabled, .btn-primaire-rose_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_dark-h56:focus {
  outline: none;
}

.btn-primaire-rose_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_dark-h40 {
  background: var(--rose_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rose_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rose_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--rose_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_dark-h40.disabled, .btn-primaire-rose_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_dark-h40:focus {
  outline: none;
}

.btn-primaire-rose_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_fushia-h56 {
  background: var(--rose_fushia);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rose_fushia-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rose_fushia-h56:not(:disabled):not(.disabled):focus {
  background: var(--rose_fushia_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_fushia-h56.disabled, .btn-primaire-rose_fushia-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_fushia-h56:focus {
  outline: none;
}

.btn-primaire-rose_fushia-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_fushia-h40 {
  background: var(--rose_fushia);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rose_fushia-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rose_fushia-h40:not(:disabled):not(.disabled):focus {
  background: var(--rose_fushia_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_fushia-h40.disabled, .btn-primaire-rose_fushia-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_fushia-h40:focus {
  outline: none;
}

.btn-primaire-rose_fushia-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rouge_framboise-h56 {
  background: var(--rouge_framboise);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rouge_framboise-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rouge_framboise-h56:not(:disabled):not(.disabled):focus {
  background: var(--rouge_framboise_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rouge_framboise-h56.disabled, .btn-primaire-rouge_framboise-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rouge_framboise-h56:focus {
  outline: none;
}

.btn-primaire-rouge_framboise-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rouge_framboise-h40 {
  background: var(--rouge_framboise);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rouge_framboise-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rouge_framboise-h40:not(:disabled):not(.disabled):focus {
  background: var(--rouge_framboise_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rouge_framboise-h40.disabled, .btn-primaire-rouge_framboise-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rouge_framboise-h40:focus {
  outline: none;
}

.btn-primaire-rouge_framboise-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_chair_light-h56 {
  background: var(--rose_chair_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rose_chair_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rose_chair_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--rose_chair_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_chair_light-h56.disabled, .btn-primaire-rose_chair_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_chair_light-h56:focus {
  outline: none;
}

.btn-primaire-rose_chair_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_chair_light-h40 {
  background: var(--rose_chair_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rose_chair_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rose_chair_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--rose_chair_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_chair_light-h40.disabled, .btn-primaire-rose_chair_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_chair_light-h40:focus {
  outline: none;
}

.btn-primaire-rose_chair_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_chair_dark-h56 {
  background: var(--rose_chair_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rose_chair_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rose_chair_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--rose_chair_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_chair_dark-h56.disabled, .btn-primaire-rose_chair_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_chair_dark-h56:focus {
  outline: none;
}

.btn-primaire-rose_chair_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rose_chair_dark-h40 {
  background: var(--rose_chair_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rose_chair_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rose_chair_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--rose_chair_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rose_chair_dark-h40.disabled, .btn-primaire-rose_chair_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rose_chair_dark-h40:focus {
  outline: none;
}

.btn-primaire-rose_chair_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-vert_positif-h56 {
  background: var(--vert_positif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-vert_positif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-vert_positif-h56:not(:disabled):not(.disabled):focus {
  background: var(--vert_positif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-vert_positif-h56.disabled, .btn-primaire-vert_positif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-vert_positif-h56:focus {
  outline: none;
}

.btn-primaire-vert_positif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-vert_positif-h40 {
  background: var(--vert_positif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-vert_positif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-vert_positif-h40:not(:disabled):not(.disabled):focus {
  background: var(--vert_positif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-vert_positif-h40.disabled, .btn-primaire-vert_positif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-vert_positif-h40:focus {
  outline: none;
}

.btn-primaire-vert_positif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rouge_negatif-h56 {
  background: var(--rouge_negatif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-rouge_negatif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-rouge_negatif-h56:not(:disabled):not(.disabled):focus {
  background: var(--rouge_negatif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rouge_negatif-h56.disabled, .btn-primaire-rouge_negatif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rouge_negatif-h56:focus {
  outline: none;
}

.btn-primaire-rouge_negatif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-rouge_negatif-h40 {
  background: var(--rouge_negatif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-rouge_negatif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-rouge_negatif-h40:not(:disabled):not(.disabled):focus {
  background: var(--rouge_negatif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-rouge_negatif-h40.disabled, .btn-primaire-rouge_negatif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-rouge_negatif-h40:focus {
  outline: none;
}

.btn-primaire-rouge_negatif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_texte-h56 {
  background: var(--bleu_texte);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-bleu_texte-h56:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_texte-h56:not(:disabled):not(.disabled):focus {
  background: var(--bleu_texte_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_texte-h56.disabled, .btn-primaire-bleu_texte-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_texte-h56:focus {
  outline: none;
}

.btn-primaire-bleu_texte-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-bleu_texte-h40 {
  background: var(--bleu_texte);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-bleu_texte-h40:not(:disabled):not(.disabled):hover, .btn-primaire-bleu_texte-h40:not(:disabled):not(.disabled):focus {
  background: var(--bleu_texte_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-bleu_texte-h40.disabled, .btn-primaire-bleu_texte-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-bleu_texte-h40:focus {
  outline: none;
}

.btn-primaire-bleu_texte-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_vert_positif-h56 {
  background: var(--alerte_vert_positif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-alerte_vert_positif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_vert_positif-h56:not(:disabled):not(.disabled):focus {
  background: var(--alerte_vert_positif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_vert_positif-h56.disabled, .btn-primaire-alerte_vert_positif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_vert_positif-h56:focus {
  outline: none;
}

.btn-primaire-alerte_vert_positif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_vert_positif-h40 {
  background: var(--alerte_vert_positif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-alerte_vert_positif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_vert_positif-h40:not(:disabled):not(.disabled):focus {
  background: var(--alerte_vert_positif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_vert_positif-h40.disabled, .btn-primaire-alerte_vert_positif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_vert_positif-h40:focus {
  outline: none;
}

.btn-primaire-alerte_vert_positif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_rouge_negatif-h56 {
  background: var(--alerte_rouge_negatif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-alerte_rouge_negatif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_rouge_negatif-h56:not(:disabled):not(.disabled):focus {
  background: var(--alerte_rouge_negatif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_rouge_negatif-h56.disabled, .btn-primaire-alerte_rouge_negatif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_rouge_negatif-h56:focus {
  outline: none;
}

.btn-primaire-alerte_rouge_negatif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_rouge_negatif-h40 {
  background: var(--alerte_rouge_negatif);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-alerte_rouge_negatif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_rouge_negatif-h40:not(:disabled):not(.disabled):focus {
  background: var(--alerte_rouge_negatif_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_rouge_negatif-h40.disabled, .btn-primaire-alerte_rouge_negatif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_rouge_negatif-h40:focus {
  outline: none;
}

.btn-primaire-alerte_rouge_negatif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_bleu_information-h56 {
  background: var(--alerte_bleu_information);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-alerte_bleu_information-h56:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_bleu_information-h56:not(:disabled):not(.disabled):focus {
  background: var(--alerte_bleu_information_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_bleu_information-h56.disabled, .btn-primaire-alerte_bleu_information-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_bleu_information-h56:focus {
  outline: none;
}

.btn-primaire-alerte_bleu_information-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_bleu_information-h40 {
  background: var(--alerte_bleu_information);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-alerte_bleu_information-h40:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_bleu_information-h40:not(:disabled):not(.disabled):focus {
  background: var(--alerte_bleu_information_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_bleu_information-h40.disabled, .btn-primaire-alerte_bleu_information-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_bleu_information-h40:focus {
  outline: none;
}

.btn-primaire-alerte_bleu_information-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_jaune_attention-h56 {
  background: var(--alerte_jaune_attention);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-alerte_jaune_attention-h56:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_jaune_attention-h56:not(:disabled):not(.disabled):focus {
  background: var(--alerte_jaune_attention_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_jaune_attention-h56.disabled, .btn-primaire-alerte_jaune_attention-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_jaune_attention-h56:focus {
  outline: none;
}

.btn-primaire-alerte_jaune_attention-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-alerte_jaune_attention-h40 {
  background: var(--alerte_jaune_attention);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-alerte_jaune_attention-h40:not(:disabled):not(.disabled):hover, .btn-primaire-alerte_jaune_attention-h40:not(:disabled):not(.disabled):focus {
  background: var(--alerte_jaune_attention_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-alerte_jaune_attention-h40.disabled, .btn-primaire-alerte_jaune_attention-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-alerte_jaune_attention-h40:focus {
  outline: none;
}

.btn-primaire-alerte_jaune_attention-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-blanc-h56 {
  color: #fff;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-blanc-h56:not(:disabled):not(.disabled):hover, .btn-primaire-blanc-h56:not(:disabled):not(.disabled):focus {
  background: var(--blanc_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-blanc-h56.disabled, .btn-primaire-blanc-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-blanc-h56:focus {
  outline: none;
}

.btn-primaire-blanc-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-blanc-h40 {
  color: #fff;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-blanc-h40:not(:disabled):not(.disabled):hover, .btn-primaire-blanc-h40:not(:disabled):not(.disabled):focus {
  background: var(--blanc_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-blanc-h40.disabled, .btn-primaire-blanc-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-blanc-h40:focus {
  outline: none;
}

.btn-primaire-blanc-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-noir-h56 {
  color: #fff;
  text-align: center;
  background: #222;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-noir-h56:not(:disabled):not(.disabled):hover, .btn-primaire-noir-h56:not(:disabled):not(.disabled):focus {
  background: var(--noir_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-noir-h56.disabled, .btn-primaire-noir-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-noir-h56:focus {
  outline: none;
}

.btn-primaire-noir-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-noir-h40 {
  color: #fff;
  text-align: center;
  background: #222;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-noir-h40:not(:disabled):not(.disabled):hover, .btn-primaire-noir-h40:not(:disabled):not(.disabled):focus {
  background: var(--noir_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-noir-h40.disabled, .btn-primaire-noir-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-noir-h40:focus {
  outline: none;
}

.btn-primaire-noir-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_light-h56 {
  background: var(--gris_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-gris_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-gris_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_light-h56.disabled, .btn-primaire-gris_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_light-h56:focus {
  outline: none;
}

.btn-primaire-gris_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_light-h40 {
  background: var(--gris_light);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-gris_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-gris_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_light_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_light-h40.disabled, .btn-primaire-gris_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_light-h40:focus {
  outline: none;
}

.btn-primaire-gris_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_dark-h56 {
  background: var(--gris_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-gris_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-gris_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_dark-h56.disabled, .btn-primaire-gris_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_dark-h56:focus {
  outline: none;
}

.btn-primaire-gris_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_dark-h40 {
  background: var(--gris_dark);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-gris_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-gris_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_dark_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_dark-h40.disabled, .btn-primaire-gris_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_dark-h40:focus {
  outline: none;
}

.btn-primaire-gris_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_background-h56 {
  background: var(--gris_background);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-gris_background-h56:not(:disabled):not(.disabled):hover, .btn-primaire-gris_background-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_background-h56.disabled, .btn-primaire-gris_background-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_background-h56:focus {
  outline: none;
}

.btn-primaire-gris_background-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-gris_background-h40 {
  background: var(--gris_background);
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-gris_background-h40:not(:disabled):not(.disabled):hover, .btn-primaire-gris_background-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background_a);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-gris_background-h40.disabled, .btn-primaire-gris_background-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-gris_background-h40:focus {
  outline: none;
}

.btn-primaire-gris_background-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-corail_mh_brand-h56 {
  color: var(--corail_mh_brand);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-corail_mh_brand-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-corail_mh_brand-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-corail_mh_brand-h56.disabled, .btn-primaire-inverse-corail_mh_brand-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-corail_mh_brand-h56:focus {
  outline: none;
}

.btn-primaire-inverse-corail_mh_brand-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-corail_mh_brand-h40 {
  color: var(--corail_mh_brand);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-corail_mh_brand-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-corail_mh_brand-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-corail_mh_brand-h40.disabled, .btn-primaire-inverse-corail_mh_brand-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-corail_mh_brand-h40:focus {
  outline: none;
}

.btn-primaire-inverse-corail_mh_brand-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-corail_mh_dark-h56 {
  color: var(--corail_mh_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-corail_mh_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-corail_mh_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-corail_mh_dark-h56.disabled, .btn-primaire-inverse-corail_mh_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-corail_mh_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-corail_mh_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-corail_mh_dark-h40 {
  color: var(--corail_mh_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-corail_mh_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-corail_mh_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-corail_mh_dark-h40.disabled, .btn-primaire-inverse-corail_mh_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-corail_mh_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-corail_mh_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-corail_mh_light-h56 {
  color: var(--corail_mh_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-corail_mh_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-corail_mh_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-corail_mh_light-h56.disabled, .btn-primaire-inverse-corail_mh_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-corail_mh_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-corail_mh_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-corail_mh_light-h40 {
  color: var(--corail_mh_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-corail_mh_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-corail_mh_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-corail_mh_light-h40.disabled, .btn-primaire-inverse-corail_mh_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-corail_mh_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-corail_mh_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_sable_light-h56 {
  color: var(--gris_sable_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-gris_sable_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_sable_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_sable_light-h56.disabled, .btn-primaire-inverse-gris_sable_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_sable_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-gris_sable_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_sable_light-h40 {
  color: var(--gris_sable_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-gris_sable_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_sable_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_sable_light-h40.disabled, .btn-primaire-inverse-gris_sable_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_sable_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-gris_sable_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_sable_dark-h56 {
  color: var(--gris_sable_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-gris_sable_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_sable_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_sable_dark-h56.disabled, .btn-primaire-inverse-gris_sable_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_sable_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-gris_sable_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_sable_dark-h40 {
  color: var(--gris_sable_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-gris_sable_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_sable_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_sable_dark-h40.disabled, .btn-primaire-inverse-gris_sable_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_sable_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-gris_sable_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_ivoire_light-h56 {
  color: var(--jaune_ivoire_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_ivoire_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_ivoire_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_ivoire_light-h56.disabled, .btn-primaire-inverse-jaune_ivoire_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_ivoire_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_ivoire_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_ivoire_light-h40 {
  color: var(--jaune_ivoire_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_ivoire_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_ivoire_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_ivoire_light-h40.disabled, .btn-primaire-inverse-jaune_ivoire_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_ivoire_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_ivoire_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_ivoire_dark-h56 {
  color: var(--jaune_ivoire_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_ivoire_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_ivoire_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_ivoire_dark-h56.disabled, .btn-primaire-inverse-jaune_ivoire_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_ivoire_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_ivoire_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_ivoire_dark-h40 {
  color: var(--jaune_ivoire_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_ivoire_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_ivoire_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_ivoire_dark-h40.disabled, .btn-primaire-inverse-jaune_ivoire_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_ivoire_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_ivoire_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_light-h56 {
  color: var(--jaune_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_light-h56.disabled, .btn-primaire-inverse-jaune_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_light-h40 {
  color: var(--jaune_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_light-h40.disabled, .btn-primaire-inverse-jaune_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_dark-h56 {
  color: var(--jaune_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_dark-h56.disabled, .btn-primaire-inverse-jaune_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-jaune_dark-h40 {
  color: var(--jaune_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-jaune_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-jaune_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-jaune_dark-h40.disabled, .btn-primaire-inverse-jaune_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-jaune_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-jaune_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-orange_normal-h56 {
  color: var(--orange_normal);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-orange_normal-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-orange_normal-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-orange_normal-h56.disabled, .btn-primaire-inverse-orange_normal-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-orange_normal-h56:focus {
  outline: none;
}

.btn-primaire-inverse-orange_normal-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-orange_normal-h40 {
  color: var(--orange_normal);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-orange_normal-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-orange_normal-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-orange_normal-h40.disabled, .btn-primaire-inverse-orange_normal-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-orange_normal-h40:focus {
  outline: none;
}

.btn-primaire-inverse-orange_normal-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-orange_dark-h56 {
  color: var(--orange_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-orange_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-orange_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-orange_dark-h56.disabled, .btn-primaire-inverse-orange_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-orange_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-orange_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-orange_dark-h40 {
  color: var(--orange_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-orange_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-orange_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-orange_dark-h40.disabled, .btn-primaire-inverse-orange_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-orange_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-orange_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-vert_light-h56 {
  color: var(--vert_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-vert_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-vert_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-vert_light-h56.disabled, .btn-primaire-inverse-vert_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-vert_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-vert_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-vert_light-h40 {
  color: var(--vert_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-vert_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-vert_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-vert_light-h40.disabled, .btn-primaire-inverse-vert_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-vert_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-vert_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-vert_dark-h56 {
  color: var(--vert_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-vert_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-vert_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-vert_dark-h56.disabled, .btn-primaire-inverse-vert_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-vert_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-vert_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-vert_dark-h40 {
  color: var(--vert_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-vert_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-vert_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-vert_dark-h40.disabled, .btn-primaire-inverse-vert_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-vert_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-vert_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_turquoise_light-h56 {
  color: var(--bleu_turquoise_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_turquoise_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_turquoise_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_turquoise_light-h56.disabled, .btn-primaire-inverse-bleu_turquoise_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_turquoise_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_turquoise_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_turquoise_light-h40 {
  color: var(--bleu_turquoise_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_turquoise_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_turquoise_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_turquoise_light-h40.disabled, .btn-primaire-inverse-bleu_turquoise_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_turquoise_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_turquoise_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_turquoise_middle-h56 {
  color: var(--bleu_turquoise_middle);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_turquoise_middle-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_turquoise_middle-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_turquoise_middle-h56.disabled, .btn-primaire-inverse-bleu_turquoise_middle-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_turquoise_middle-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_turquoise_middle-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_turquoise_middle-h40 {
  color: var(--bleu_turquoise_middle);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_turquoise_middle-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_turquoise_middle-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_turquoise_middle-h40.disabled, .btn-primaire-inverse-bleu_turquoise_middle-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_turquoise_middle-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_turquoise_middle-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_turquoise_dark-h56 {
  color: var(--bleu_turquoise_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_turquoise_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_turquoise_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_turquoise_dark-h56.disabled, .btn-primaire-inverse-bleu_turquoise_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_turquoise_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_turquoise_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_turquoise_dark-h40 {
  color: var(--bleu_turquoise_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_turquoise_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_turquoise_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_turquoise_dark-h40.disabled, .btn-primaire-inverse-bleu_turquoise_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_turquoise_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_turquoise_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_light-h56 {
  color: var(--bleu_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_light-h56.disabled, .btn-primaire-inverse-bleu_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_light-h40 {
  color: var(--bleu_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_light-h40.disabled, .btn-primaire-inverse-bleu_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_dark-h56 {
  color: var(--bleu_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_dark-h56.disabled, .btn-primaire-inverse-bleu_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_dark-h40 {
  color: var(--bleu_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_dark-h40.disabled, .btn-primaire-inverse-bleu_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_klein_light-h56 {
  color: var(--bleu_klein_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_klein_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_klein_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_klein_light-h56.disabled, .btn-primaire-inverse-bleu_klein_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_klein_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_klein_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_klein_light-h40 {
  color: var(--bleu_klein_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_klein_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_klein_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_klein_light-h40.disabled, .btn-primaire-inverse-bleu_klein_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_klein_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_klein_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_klein_dark-h56 {
  color: var(--bleu_klein_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_klein_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_klein_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_klein_dark-h56.disabled, .btn-primaire-inverse-bleu_klein_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_klein_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_klein_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_klein_dark-h40 {
  color: var(--bleu_klein_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_klein_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_klein_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_klein_dark-h40.disabled, .btn-primaire-inverse-bleu_klein_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_klein_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_klein_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-violet_light-h56 {
  color: var(--violet_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-violet_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-violet_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-violet_light-h56.disabled, .btn-primaire-inverse-violet_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-violet_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-violet_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-violet_light-h40 {
  color: var(--violet_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-violet_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-violet_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-violet_light-h40.disabled, .btn-primaire-inverse-violet_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-violet_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-violet_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-violet_dark-h56 {
  color: var(--violet_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-violet_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-violet_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-violet_dark-h56.disabled, .btn-primaire-inverse-violet_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-violet_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-violet_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-violet_dark-h40 {
  color: var(--violet_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-violet_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-violet_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-violet_dark-h40.disabled, .btn-primaire-inverse-violet_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-violet_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-violet_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-mauve-h56 {
  color: var(--mauve);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-mauve-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-mauve-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-mauve-h56.disabled, .btn-primaire-inverse-mauve-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-mauve-h56:focus {
  outline: none;
}

.btn-primaire-inverse-mauve-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-mauve-h40 {
  color: var(--mauve);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-mauve-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-mauve-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-mauve-h40.disabled, .btn-primaire-inverse-mauve-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-mauve-h40:focus {
  outline: none;
}

.btn-primaire-inverse-mauve-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_light-h56 {
  color: var(--rose_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rose_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_light-h56.disabled, .btn-primaire-inverse-rose_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rose_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_light-h40 {
  color: var(--rose_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rose_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_light-h40.disabled, .btn-primaire-inverse-rose_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rose_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_dark-h56 {
  color: var(--rose_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rose_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_dark-h56.disabled, .btn-primaire-inverse-rose_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rose_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_dark-h40 {
  color: var(--rose_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rose_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_dark-h40.disabled, .btn-primaire-inverse-rose_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rose_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_fushia-h56 {
  color: var(--rose_fushia);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rose_fushia-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_fushia-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_fushia-h56.disabled, .btn-primaire-inverse-rose_fushia-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_fushia-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rose_fushia-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_fushia-h40 {
  color: var(--rose_fushia);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rose_fushia-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_fushia-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_fushia-h40.disabled, .btn-primaire-inverse-rose_fushia-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_fushia-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rose_fushia-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rouge_framboise-h56 {
  color: var(--rouge_framboise);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rouge_framboise-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rouge_framboise-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rouge_framboise-h56.disabled, .btn-primaire-inverse-rouge_framboise-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rouge_framboise-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rouge_framboise-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rouge_framboise-h40 {
  color: var(--rouge_framboise);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rouge_framboise-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rouge_framboise-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rouge_framboise-h40.disabled, .btn-primaire-inverse-rouge_framboise-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rouge_framboise-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rouge_framboise-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_chair_light-h56 {
  color: var(--rose_chair_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rose_chair_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_chair_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_chair_light-h56.disabled, .btn-primaire-inverse-rose_chair_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_chair_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rose_chair_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_chair_light-h40 {
  color: var(--rose_chair_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rose_chair_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_chair_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_chair_light-h40.disabled, .btn-primaire-inverse-rose_chair_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_chair_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rose_chair_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_chair_dark-h56 {
  color: var(--rose_chair_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rose_chair_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_chair_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_chair_dark-h56.disabled, .btn-primaire-inverse-rose_chair_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_chair_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rose_chair_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rose_chair_dark-h40 {
  color: var(--rose_chair_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rose_chair_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rose_chair_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rose_chair_dark-h40.disabled, .btn-primaire-inverse-rose_chair_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rose_chair_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rose_chair_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-vert_positif-h56 {
  color: var(--vert_positif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-vert_positif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-vert_positif-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-vert_positif-h56.disabled, .btn-primaire-inverse-vert_positif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-vert_positif-h56:focus {
  outline: none;
}

.btn-primaire-inverse-vert_positif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-vert_positif-h40 {
  color: var(--vert_positif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-vert_positif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-vert_positif-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-vert_positif-h40.disabled, .btn-primaire-inverse-vert_positif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-vert_positif-h40:focus {
  outline: none;
}

.btn-primaire-inverse-vert_positif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rouge_negatif-h56 {
  color: var(--rouge_negatif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-rouge_negatif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rouge_negatif-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rouge_negatif-h56.disabled, .btn-primaire-inverse-rouge_negatif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rouge_negatif-h56:focus {
  outline: none;
}

.btn-primaire-inverse-rouge_negatif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-rouge_negatif-h40 {
  color: var(--rouge_negatif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-rouge_negatif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-rouge_negatif-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-rouge_negatif-h40.disabled, .btn-primaire-inverse-rouge_negatif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-rouge_negatif-h40:focus {
  outline: none;
}

.btn-primaire-inverse-rouge_negatif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_texte-h56 {
  color: var(--bleu_texte);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_texte-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_texte-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_texte-h56.disabled, .btn-primaire-inverse-bleu_texte-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_texte-h56:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_texte-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-bleu_texte-h40 {
  color: var(--bleu_texte);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-bleu_texte-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-bleu_texte-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-bleu_texte-h40.disabled, .btn-primaire-inverse-bleu_texte-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-bleu_texte-h40:focus {
  outline: none;
}

.btn-primaire-inverse-bleu_texte-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_vert_positif-h56 {
  color: var(--alerte_vert_positif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_vert_positif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_vert_positif-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_vert_positif-h56.disabled, .btn-primaire-inverse-alerte_vert_positif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_vert_positif-h56:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_vert_positif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_vert_positif-h40 {
  color: var(--alerte_vert_positif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_vert_positif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_vert_positif-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_vert_positif-h40.disabled, .btn-primaire-inverse-alerte_vert_positif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_vert_positif-h40:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_vert_positif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_rouge_negatif-h56 {
  color: var(--alerte_rouge_negatif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_rouge_negatif-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_rouge_negatif-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_rouge_negatif-h56.disabled, .btn-primaire-inverse-alerte_rouge_negatif-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_rouge_negatif-h56:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_rouge_negatif-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_rouge_negatif-h40 {
  color: var(--alerte_rouge_negatif);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_rouge_negatif-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_rouge_negatif-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_rouge_negatif-h40.disabled, .btn-primaire-inverse-alerte_rouge_negatif-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_rouge_negatif-h40:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_rouge_negatif-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_bleu_information-h56 {
  color: var(--alerte_bleu_information);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_bleu_information-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_bleu_information-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_bleu_information-h56.disabled, .btn-primaire-inverse-alerte_bleu_information-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_bleu_information-h56:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_bleu_information-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_bleu_information-h40 {
  color: var(--alerte_bleu_information);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_bleu_information-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_bleu_information-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_bleu_information-h40.disabled, .btn-primaire-inverse-alerte_bleu_information-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_bleu_information-h40:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_bleu_information-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_jaune_attention-h56 {
  color: var(--alerte_jaune_attention);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_jaune_attention-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_jaune_attention-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_jaune_attention-h56.disabled, .btn-primaire-inverse-alerte_jaune_attention-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_jaune_attention-h56:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_jaune_attention-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-alerte_jaune_attention-h40 {
  color: var(--alerte_jaune_attention);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-alerte_jaune_attention-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-alerte_jaune_attention-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-alerte_jaune_attention-h40.disabled, .btn-primaire-inverse-alerte_jaune_attention-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-alerte_jaune_attention-h40:focus {
  outline: none;
}

.btn-primaire-inverse-alerte_jaune_attention-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-blanc-h56 {
  color: #fff;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-blanc-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-blanc-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-blanc-h56.disabled, .btn-primaire-inverse-blanc-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-blanc-h56:focus {
  outline: none;
}

.btn-primaire-inverse-blanc-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-blanc-h40 {
  color: #fff;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-blanc-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-blanc-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-blanc-h40.disabled, .btn-primaire-inverse-blanc-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-blanc-h40:focus {
  outline: none;
}

.btn-primaire-inverse-blanc-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-noir-h56 {
  color: #222;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-noir-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-noir-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-noir-h56.disabled, .btn-primaire-inverse-noir-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-noir-h56:focus {
  outline: none;
}

.btn-primaire-inverse-noir-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-noir-h40 {
  color: #222;
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-noir-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-noir-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-noir-h40.disabled, .btn-primaire-inverse-noir-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-noir-h40:focus {
  outline: none;
}

.btn-primaire-inverse-noir-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_light-h56 {
  color: var(--gris_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-gris_light-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_light-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_light-h56.disabled, .btn-primaire-inverse-gris_light-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_light-h56:focus {
  outline: none;
}

.btn-primaire-inverse-gris_light-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_light-h40 {
  color: var(--gris_light);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-gris_light-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_light-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_light-h40.disabled, .btn-primaire-inverse-gris_light-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_light-h40:focus {
  outline: none;
}

.btn-primaire-inverse-gris_light-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_dark-h56 {
  color: var(--gris_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-gris_dark-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_dark-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_dark-h56.disabled, .btn-primaire-inverse-gris_dark-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_dark-h56:focus {
  outline: none;
}

.btn-primaire-inverse-gris_dark-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_dark-h40 {
  color: var(--gris_dark);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-gris_dark-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_dark-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_dark-h40.disabled, .btn-primaire-inverse-gris_dark-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_dark-h40:focus {
  outline: none;
}

.btn-primaire-inverse-gris_dark-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_background-h56 {
  color: var(--gris_background);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 56px;
  height: 56px;
  padding: 8px 24px;
  font-size: 22px;
  display: inline-block;
}

.btn-primaire-inverse-gris_background-h56:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_background-h56:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_background-h56.disabled, .btn-primaire-inverse-gris_background-h56:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_background-h56:focus {
  outline: none;
}

.btn-primaire-inverse-gris_background-h56 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.btn-primaire-inverse-gris_background-h40 {
  color: var(--gris_background);
  text-align: center;
  background: #fff;
  border: none;
  border-radius: 40px;
  height: 40px;
  padding: 8px 24px;
  font-size: 16px;
  display: inline-block;
}

.btn-primaire-inverse-gris_background-h40:not(:disabled):not(.disabled):hover, .btn-primaire-inverse-gris_background-h40:not(:disabled):not(.disabled):focus {
  background: var(--gris_background);
  transition: all .3s;
  outline: none !important;
}

.btn-primaire-inverse-gris_background-h40.disabled, .btn-primaire-inverse-gris_background-h40:disabled {
  opacity: .3;
  cursor: default;
}

.btn-primaire-inverse-gris_background-h40:focus {
  outline: none;
}

.btn-primaire-inverse-gris_background-h40 > i {
  vertical-align: middle;
  margin-right: .5rem;
}

input {
  font: inherit;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-ms-clear {
  display: none;
}

.form-group {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.form-group input, .form-group textarea {
  background: var(--gris_background);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 20px 15px 5px;
}

.form-group input {
  min-height: 56px;
  line-height: 1.9;
}

.form-group textarea {
  resize: none;
  min-height: 100px;
}

.form-group .control-label {
  pointer-events: none;
  z-index: 1;
  color: var(--gris_dark);
  padding-left: .125rem;
  font-size: 1rem;
  font-weight: normal;
  transition: all .28s;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
}

.form-group .bar {
  border-bottom: .0625rem solid var(--gris_dark);
  display: block;
  position: relative;
}

.form-group .bar:before {
  content: "";
  background: var(--bleu_texte);
  z-index: 2;
  width: 0;
  height: .125rem;
  transition: left .28s, width .28s;
  position: absolute;
  bottom: -.0625rem;
  left: 50%;
}

.form-group .message-erreur {
  color: var(--rouge_negatif);
  margin-top: 8px;
  margin-left: 16px;
  font-size: .75rem;
  display: none;
  position: absolute;
}

.form-group .message-aide {
  color: var(--gris_dark);
  margin-top: 8px;
  margin-left: 16px;
  font-size: .75rem;
  display: none;
  position: absolute;
}

.form-group .dsicon-close, .form-group .dsicon-check {
  width: 16px;
  height: 16px;
  margin: auto;
  font-size: 1rem;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
}

.form-group .dsicon-check {
  color: var(--vert_positif);
}

.form-group .dsicon-close {
  color: var(--rouge_negatif);
}

.form-group input {
  line-height: 1.9;
}

.form-group input, .form-group textarea {
  color: #222;
  box-shadow: none;
  border-width: 0;
  border-color: #0000;
  width: 100%;
  font-size: 1rem;
  transition: all .28s;
  display: block;
}

.form-group input:disabled, .form-group input.disabled, .form-group textarea:disabled, .form-group textarea.disabled {
  pointer-events: none;
  background-color: var(--gris_background);
  opacity: .3;
}

.form-group input:disabled ~ .control-label, .form-group input.disabled ~ .control-label, .form-group textarea:disabled ~ .control-label, .form-group textarea.disabled ~ .control-label {
  color: var(--gris_light) !important;
}

.form-group input:disabled ~ .bar, .form-group input.disabled ~ .bar, .form-group textarea:disabled ~ .bar, .form-group textarea.disabled ~ .bar {
  border-color: var(--gris_light);
}

.form-group input:disabled ~ .message-aide, .form-group input.disabled ~ .message-aide, .form-group textarea:disabled ~ .message-aide, .form-group textarea.disabled ~ .message-aide, .form-group input:disabled ~ .message-erreur, .form-group input.disabled ~ .message-erreur, .form-group textarea:disabled ~ .message-erreur, .form-group textarea.disabled ~ .message-erreur {
  display: none;
}

.form-group input.focused, .form-group input:focus, .form-group textarea.focused, .form-group textarea:focus {
  background-color: var(--gris_light);
  outline: none;
}

.form-group input.focused ~ .bar:before, .form-group input:focus ~ .bar:before, .form-group textarea.focused ~ .bar:before, .form-group textarea:focus ~ .bar:before {
  width: 100%;
  left: 0;
}

.form-group input.focused, .form-group input:focus, .form-group input:valid:not(.ignore-css-valid-state), .form-group input.has-value, .form-group textarea.focused, .form-group textarea:focus, .form-group textarea:valid:not(.ignore-css-valid-state), .form-group textarea.has-value {
  color: #222;
}

.form-group input.focused ~ .control-label, .form-group input:focus ~ .control-label, .form-group input:valid:not(.ignore-css-valid-state) ~ .control-label, .form-group input.has-value ~ .control-label, .form-group textarea.focused ~ .control-label, .form-group textarea:focus ~ .control-label, .form-group textarea:valid:not(.ignore-css-valid-state) ~ .control-label, .form-group textarea.has-value ~ .control-label {
  color: var(--bleu_texte);
  font-size: .75rem;
  top: .5rem;
  left: .75rem;
}

.form-group input:not(.erreur).focused ~ .message-aide, .form-group input:not(.erreur):focus ~ .message-aide, .form-group textarea:not(.erreur).focused ~ .message-aide, .form-group textarea:not(.erreur):focus ~ .message-aide {
  display: inherit;
}

.form-group input.valide ~ .bar:before, .form-group textarea.valide ~ .bar:before {
  background: var(--vert_positif);
  width: 100%;
  left: 0;
}

.form-group input.valide.focused ~ .control-label, .form-group input.valide:focus ~ .control-label, .form-group input.valide:valid:not(.ignore-css-valid-state) ~ .control-label, .form-group input.valide.has-value ~ .control-label, .form-group textarea.valide.focused ~ .control-label, .form-group textarea.valide:focus ~ .control-label, .form-group textarea.valide:valid:not(.ignore-css-valid-state) ~ .control-label, .form-group textarea.valide.has-value ~ .control-label {
  color: var(--vert_positif);
}

.form-group input.valide.focused ~ .dsicon-check, .form-group input.valide:focus ~ .dsicon-check, .form-group input.valide:valid:not(.ignore-css-valid-state) ~ .dsicon-check, .form-group input.valide.has-value ~ .dsicon-check, .form-group textarea.valide.focused ~ .dsicon-check, .form-group textarea.valide:focus ~ .dsicon-check, .form-group textarea.valide:valid:not(.ignore-css-valid-state) ~ .dsicon-check, .form-group textarea.valide.has-value ~ .dsicon-check {
  display: inherit;
}

.form-group input.erreur ~ .bar:before, .form-group textarea.erreur ~ .bar:before {
  background: var(--rouge_negatif);
  width: 100%;
  left: 0;
}

.form-group input.erreur ~ .control-label, .form-group textarea.erreur ~ .control-label {
  color: var(--rouge_negatif);
  font-size: .75rem;
  top: .5rem;
  left: .75rem;
}

.form-group input.erreur ~ .dsicon-close, .form-group textarea.erreur ~ .dsicon-close, .form-group input.erreur ~ .message-erreur, .form-group textarea.erreur ~ .message-erreur {
  display: inherit;
}

.mhlogo-logo-blanc {
  background-image: url("logo-blanc.2d804baa.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.mhlogo-valeur-blanc {
  background-image: url("valeur-blanc.722c56bc.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 56px;
}

.mhlogo-logo-nom-blanc {
  background-image: url("logo-nom-blanc.2e88dddd.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 212px;
  height: 56px;
}

.mhlogo-logo-nom-valeur-blanc {
  background-image: url("logo-nom-valeur-blanc.da7b6f01.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 294px;
  height: 56px;
}

.mhlogo-logo-couleur {
  background-image: url("logo-couleur.23aad555.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 64px;
  height: 64px;
}

.mhlogo-valeur-couleur {
  background-image: url("valeur-couleur.e14b6587.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 70px;
  height: 56px;
}

.mhlogo-logo-nom-couleur {
  background-image: url("logo-nom-couleur.8ea51c73.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 212px;
  height: 56px;
}

.mhlogo-logo-nom-valeur-couleur {
  background-image: url("logo-nom-valeur-couleur.2da0a4c1.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 294px;
  height: 56px;
}

.mhlogo-produit-fondation-handicap-blanc {
  background-image: url("fondation-handicap-blanc.57af8dad.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 156px;
  height: 56px;
}

.mhlogo-produit-fondation-handicap-1ligne-blanc {
  background-image: url("fondation-handicap-blanc-1ligne.46783626.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 261px;
  height: 40px;
}

.mhlogo-produit-mh-innov-blanc {
  background-image: url("mh-innov-blanc.11551a6b.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 128px;
  height: 56px;
}

.mhlogo-produit-mh-innov-1ligne-blanc {
  background-image: url("mh-innov-blanc-1ligne.f00abb97.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 266px;
  height: 24px;
}

.mhlogo-produit-fondation-handicap-couleur {
  background-image: url("fondation-handicap-couleur.656a30ce.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 156px;
  height: 56px;
}

.mhlogo-produit-fondation-handicap-1ligne-couleur {
  background-image: url("fondation-handicap-couleur-1ligne.f40f0c86.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 261px;
  height: 40px;
}

.mhlogo-produit-mh-innov-couleur {
  background-image: url("mh-innov-couleur.26256549.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 128px;
  height: 56px;
}

.mhlogo-produit-mh-innov-1ligne-couleur {
  background-image: url("mh-innov-couleur-1ligne.7c3547d8.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 266px;
  height: 24px;
}

.mhlogo-produit-le-comptoir-couleur {
  background-image: url("le-comptoir-couleur.b04a2d07.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 234px;
  height: 56px;
}

.mhlogo-produit-le-comptoir-blanc {
  background-image: url("le-comptoir-blanc.aaf1e5d9.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 234px;
  height: 56px;
}

.mhlogo-produit-diagnostic-capital-humain {
  background-image: url("diagnostic-capital-humain.0f136030.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-produit-diagnostic-capital-humain-blanc {
  background-image: url("diagnostic-capital-humain-blanc.8d0db3eb.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-produit-solutions-pme {
  background-image: url("solutions-PME.7d2bed99.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-produit-solutions-pme-blanc {
  background-image: url("solutions-PME-blanc.adf447f9.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-partenaire-ipsec-blanc {
  background-image: url("ipsec_blanc.9ef1945f.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-partenaire-ipsec-couleur {
  background-image: url("ipsec_couleur.34b7c9d3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-partenaire-mobilite-mutuelle-blanc {
  background-image: url("mobilite_mutuelle_blanc.21fed6f3.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-partenaire-mobilite-mutuelle-couleur {
  background-image: url("mobilite_mutuelle_couleur.4ef348d5.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-partenaire-radiance-blanc {
  background-image: url("radiance_blanc.45715478.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

.mhlogo-partenaire-radiance-couleur {
  background-image: url("radiance_couleur.ea47598c.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 171px;
  height: 56px;
}

@font-face {
  font-family: ds-icon;
  src: url("ds-icon.3a9981dd.eot");
  src: url("ds-icon.7b400e14.ttf") format("truetype"), url("ds-icon.852976f4.woff") format("woff"), url("ds-icon.69efcfe9.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dsicon-"], .paging-container .debut-paging, .paging-container .precedent, .paging-container .suivant, .paging-container .fin-paging, [class*=" dsicon-"] {
  speak: none;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: ds-icon, Arial, Helvetica, sans-serif !important;
}

[class^="dsicon-"][class$="-xl"], .paging-container [class$="-xl"].debut-paging, .paging-container [class$="-xl"].precedent, .paging-container [class$="-xl"].suivant, .paging-container [class$="-xl"].fin-paging, [class*=" dsicon-"][class$="-xl"] {
  font-size: 2rem;
}

.dsicon-1:before, .dsicon-1-xl:before {
  content: "";
}

.dsicon-2:before, .dsicon-2-xl:before {
  content: "";
}

.dsicon-3:before, .dsicon-3-xl:before {
  content: "";
}

.dsicon-4:before, .dsicon-4-xl:before {
  content: "";
}

.dsicon-Cercle-play:before, .dsicon-Cercle-play-xl:before {
  content: "";
}

.dsicon-afficher:before, .dsicon-afficher-xl:before {
  content: "";
}

.dsicon-aidant:before, .dsicon-aidant-xl:before {
  content: "";
}

.dsicon-altere:before, .dsicon-altere-xl:before {
  content: "";
}

.dsicon-ampoule:before, .dsicon-ampoule-xl:before {
  content: "";
}

.dsicon-assurance:before, .dsicon-assurance-xl:before {
  content: "";
}

.dsicon-avion:before, .dsicon-avion-xl:before {
  content: "";
}

.dsicon-balance-bas:before, .dsicon-balance-bas-xl:before {
  content: "";
}

.dsicon-balance-haut:before, .dsicon-balance-haut-xl:before {
  content: "";
}

.dsicon-bequille:before, .dsicon-bequille-xl:before {
  content: "";
}

.dsicon-building:before, .dsicon-building-xl:before {
  content: "";
}

.dsicon-bulle-dialogue:before, .dsicon-bulle-dialogue-xl:before {
  content: "";
}

.dsicon-bulle-dialogue-plus:before, .dsicon-bulle-dialogue-plus-xl:before {
  content: "";
}

.dsicon-burger-menu:before, .dsicon-burger-menu-xl:before {
  content: "";
}

.dsicon-bus:before, .dsicon-bus-xl:before {
  content: "";
}

.dsicon-cadenas:before, .dsicon-cadenas-xl:before {
  content: "";
}

.dsicon-calculette:before, .dsicon-calculette-xl:before {
  content: "";
}

.dsicon-calendrier:before, .dsicon-calendrier-xl:before {
  content: "";
}

.dsicon-card-mh:before, .dsicon-card-mh-xl:before {
  content: "";
}

.dsicon-cercle-check:before, .ds-alert.ds-alert--valid .ds-alert__content-info .ds-alert__icon:before, .dsicon-cercle-check-xl:before {
  content: "";
}

.dsicon-cercle-chevron-droit:before, .dsicon-cercle-chevron-droit-xl:before {
  content: "";
}

.dsicon-cercle-chevron-gauche:before, .dsicon-cercle-chevron-gauche-xl:before {
  content: "";
}

.dsicon-cercle-information:before, .dsicon-cercle-information-xl:before {
  content: "";
}

.dsicon-cercle-moins:before, .dsicon-cercle-moins-xl:before {
  content: "";
}

.dsicon-cercle-plus:before, .dsicon-cercle-plus-xl:before {
  content: "";
}

.dsicon-cerveau:before, .dsicon-cerveau-xl:before {
  content: "";
}

.dsicon-charger-fichier:before, .dsicon-charger-fichier-xl:before {
  content: "";
}

.dsicon-check:before, .dsicon-check-xl:before {
  content: "";
}

.dsicon-cheque:before, .dsicon-cheque-xl:before {
  content: "";
}

.dsicon-chevron-bas:before, .ds-select-input .ds-select__chevron-bas:before, .dsicon-chevron-bas-xl:before {
  content: "";
}

.dsicon-chevron-droit:before, .paging-container .suivant:before, .dsicon-chevron-droit-xl:before {
  content: "";
}

.dsicon-chevron-gauche:before, .paging-container .precedent:before, .dsicon-chevron-gauche-xl:before {
  content: "";
}

.dsicon-chevron-haut:before, .ds-select-input .ds-select__chevron-haut:before, .dsicon-chevron-haut-xl:before {
  content: "";
}

.dsicon-chiropracteur:before, .dsicon-chiropracteur-xl:before {
  content: "";
}

.dsicon-cible:before, .dsicon-cible-xl:before {
  content: "";
}

.dsicon-cloche:before, .dsicon-cloche-xl:before {
  content: "";
}

.dsicon-close:before, .dsicon-close-xl:before {
  content: "";
}

.dsicon-coeur:before, .dsicon-coeur-xl:before {
  content: "";
}

.dsicon-coeur-filled:before, .dsicon-coeur-filled-xl:before {
  content: "";
}

.dsicon-coeur-sante:before, .dsicon-coeur-sante-xl:before {
  content: "";
}

.dsicon-coeur-smile:before, .dsicon-coeur-smile-xl:before {
  content: "";
}

.dsicon-compte:before, .dsicon-compte-xl:before {
  content: "";
}

.dsicon-conjoint:before, .dsicon-conjoint-xl:before {
  content: "";
}

.dsicon-corbeille:before, .dsicon-corbeille-xl:before {
  content: "";
}

.dsicon-coupe:before, .dsicon-coupe-xl:before {
  content: "";
}

.dsicon-crayon:before, .dsicon-crayon-xl:before {
  content: "";
}

.dsicon-croix-medicale:before, .dsicon-croix-medicale-xl:before {
  content: "";
}

.dsicon-debut-gauche:before, .paging-container .debut-paging:before, .dsicon-debut-gauche-xl:before {
  content: "";
}

.dsicon-dent:before, .dsicon-dent-xl:before {
  content: "";
}

.dsicon-dent-abimee-noyau-instruction:before, .dsicon-dent-abimee-noyau-instruction-xl:before {
  content: "";
}

.dsicon-dent-appareil:before, .dsicon-dent-appareil-xl:before {
  content: "";
}

.dsicon-dent-chirurgie-gencive:before, .dsicon-dent-chirurgie-gencive-xl:before {
  content: "";
}

.dsicon-dent-couronne:before, .dsicon-dent-couronne-xl:before {
  content: "";
}

.dsicon-dent-detartrage:before, .dsicon-dent-detartrage-xl:before {
  content: "";
}

.dsicon-dent-devitalisation:before, .dsicon-dent-devitalisation-xl:before {
  content: "";
}

.dsicon-dent-extraction:before, .dsicon-dent-extraction-xl:before {
  content: "";
}

.dsicon-dent-implant:before, .dsicon-dent-implant-xl:before {
  content: "";
}

.dsicon-dent-manquante:before, .dsicon-dent-manquante-xl:before {
  content: "";
}

.dsicon-dent-onlay:before, .dsicon-dent-onlay-xl:before {
  content: "";
}

.dsicon-dent-parodontologie:before, .dsicon-dent-parodontologie-xl:before {
  content: "";
}

.dsicon-dent-parodontologie-non-rembourse:before, .dsicon-dent-parodontologie-non-rembourse-xl:before {
  content: "";
}

.dsicon-dent-pont:before, .dsicon-dent-pont-xl:before {
  content: "";
}

.dsicon-dent-radio:before, .dsicon-dent-radio-xl:before {
  content: "";
}

.dsicon-dent-restauration:before, .dsicon-dent-restauration-xl:before {
  content: "";
}

.dsicon-dent-soins:before, .dsicon-dent-soins-xl:before {
  content: "";
}

.dsicon-dietetique:before, .dsicon-dietetique-xl:before {
  content: "";
}

.dsicon-document:before, .dsicon-document-xl:before {
  content: "";
}

.dsicon-document-check:before, .dsicon-document-check-xl:before {
  content: "";
}

.dsicon-document-moins:before, .dsicon-document-moins-xl:before {
  content: "";
}

.dsicon-document-plus:before, .dsicon-document-plus-xl:before {
  content: "";
}

.dsicon-double-smile-mh:before, .dsicon-double-smile-mh-xl:before {
  content: "";
}

.dsicon-enfant:before, .dsicon-enfant-xl:before {
  content: "";
}

.dsicon-epingle:before, .dsicon-epingle-xl:before {
  content: "";
}

.dsicon-etoile:before, .dsicon-etoile-xl:before {
  content: "";
}

.dsicon-etoile-filled:before, .dsicon-etoile-filled-xl:before {
  content: "";
}

.dsicon-euro:before, .dsicon-euro-xl:before {
  content: "";
}

.dsicon-excel:before, .dsicon-excel-xl:before {
  content: "";
}

.dsicon-facebook:before {
  content: "";
  font-size: 1.5rem;
}

.dsicon-facebook-xl:before {
  content: "";
}

.dsicon-fiche-parametrage:before, .dsicon-fiche-parametrage-xl:before {
  content: "";
}

.dsicon-fin-droite:before, .paging-container .fin-paging:before, .dsicon-fin-droite-xl:before {
  content: "";
}

.dsicon-flag:before, .dsicon-flag-xl:before {
  content: "";
}

.dsicon-fleche-bas:before, .dsicon-fleche-bas-xl:before {
  content: "";
}

.dsicon-fleche-clic:before, .dsicon-fleche-clic-xl:before {
  content: "";
}

.dsicon-fleche-droite:before, .dsicon-fleche-droite-xl:before {
  content: "";
}

.dsicon-fleche-dropdown-texte:before, .dsicon-fleche-dropdown-texte-xl:before {
  content: "";
}

.dsicon-fleche-gauche:before, .dsicon-fleche-gauche-xl:before {
  content: "";
}

.dsicon-fleche-haut:before, .dsicon-fleche-haut-xl:before {
  content: "";
}

.dsicon-graphique:before, .dsicon-graphique-xl:before {
  content: "";
}

.dsicon-graphique-lines3x:before, .dsicon-graphique-lines3x-xl:before {
  content: "";
}

.dsicon-grille:before, .dsicon-grille-xl:before {
  content: "";
}

.dsicon-handicap:before, .dsicon-handicap-xl:before {
  content: "";
}

.dsicon-herbe:before, .dsicon-herbe-xl:before {
  content: "";
}

.dsicon-hopital:before, .dsicon-hopital-xl:before {
  content: "";
}

.dsicon-horloge:before, .dsicon-horloge-xl:before {
  content: "";
}

.dsicon-horloge-euro:before, .dsicon-horloge-euro-xl:before {
  content: "";
}

.dsicon-iiii:before, .dsicon-iiii-xl:before {
  content: "";
}

.dsicon-images:before, .dsicon-images-xl:before {
  content: "";
}

.dsicon-indisponible:before, .dsicon-indisponible-xl:before {
  content: "";
}

.dsicon-infirmiere:before, .dsicon-infirmiere-xl:before {
  content: "";
}

.dsicon-instagram:before {
  content: "";
  font-size: 1.5rem;
}

.dsicon-instagram-xl:before {
  content: "";
}

.dsicon-interrogation:before, .dsicon-interrogation-xl:before {
  content: "";
}

.dsicon-jpg:before, .dsicon-jpg-xl:before {
  content: "";
}

.dsicon-kinesitherapie:before, .dsicon-kinesitherapie-xl:before {
  content: "";
}

.dsicon-lien-externe:before, .dsicon-lien-externe-xl:before {
  content: "";
}

.dsicon-linkedin:before {
  content: "";
  font-size: 1.5rem;
}

.dsicon-linkedin-xl:before {
  content: "";
}

.dsicon-liste:before, .dsicon-liste-xl:before {
  content: "";
}

.dsicon-lit:before, .dsicon-lit-xl:before {
  content: "";
}

.dsicon-lunettes:before, .dsicon-lunettes-xl:before {
  content: "";
}

.dsicon-mail:before, .dsicon-mail-xl:before {
  content: "";
}

.dsicon-masquer:before, .dsicon-masquer-xl:before {
  content: "";
}

.dsicon-microscope:before, .dsicon-microscope-xl:before {
  content: "";
}

.dsicon-moins:before, .dsicon-moins-xl:before {
  content: "";
}

.dsicon-nourrisson:before, .dsicon-nourrisson-xl:before {
  content: "";
}

.dsicon-oeil-droit:before, .dsicon-oeil-droit-xl:before {
  content: "";
}

.dsicon-oeil-gauche:before, .dsicon-oeil-gauche-xl:before {
  content: "";
}

.dsicon-optique-audio:before, .dsicon-optique-audio-xl:before {
  content: "";
}

.dsicon-optique-hypermetrope:before, .dsicon-optique-hypermetrope-xl:before {
  content: "";
}

.dsicon-optique-hypermetrope-astigmate:before, .dsicon-optique-hypermetrope-astigmate-xl:before {
  content: "";
}

.dsicon-optique-lentilles:before, .dsicon-optique-lentilles-xl:before {
  content: "";
}

.dsicon-optique-myope-astigmate:before, .dsicon-optique-myope-astigmate-xl:before {
  content: "";
}

.dsicon-optique-operation-vue:before, .dsicon-optique-operation-vue-xl:before {
  content: "";
}

.dsicon-oreille:before, .dsicon-oreille-xl:before {
  content: "";
}

.dsicon-orthodontie:before, .dsicon-orthodontie-xl:before {
  content: "";
}

.dsicon-ostheo:before, .dsicon-ostheo-xl:before {
  content: "";
}

.dsicon-pansement:before, .dsicon-pansement-xl:before {
  content: "";
}

.dsicon-parametres:before, .dsicon-parametres-xl:before {
  content: "";
}

.dsicon-pdf:before, .dsicon-pdf-xl:before {
  content: "";
}

.dsicon-pin-geoloc:before, .dsicon-pin-geoloc-xl:before {
  content: "";
}

.dsicon-pin-geoloc-plus:before, .dsicon-pin-geoloc-plus-xl:before {
  content: "";
}

.dsicon-platre:before, .dsicon-platre-xl:before {
  content: "";
}

.dsicon-plus:before, .dsicon-plus-xl:before {
  content: "";
}

.dsicon-png:before, .dsicon-png-xl:before {
  content: "";
}

.dsicon-podologue:before, .dsicon-podologue-xl:before {
  content: "";
}

.dsicon-poing:before, .dsicon-poing-xl:before {
  content: "";
}

.dsicon-pouce:before, .dsicon-pouce-xl:before {
  content: "";
}

.dsicon-pouce-negatif:before, .dsicon-pouce-negatif-xl:before {
  content: "";
}

.dsicon-pourcentage:before, .dsicon-pourcentage-xl:before {
  content: "";
}

.dsicon-power:before, .dsicon-power-xl:before {
  content: "";
}

.dsicon-powerpoint:before, .dsicon-powerpoint-xl:before {
  content: "";
}

.dsicon-prevoyance:before, .dsicon-prevoyance-xl:before {
  content: "";
}

.dsicon-professionnel:before, .dsicon-professionnel-xl:before {
  content: "";
}

.dsicon-radioactif:before, .dsicon-radioactif-xl:before {
  content: "";
}

.dsicon-radiographie:before, .dsicon-radiographie-xl:before {
  content: "";
}

.dsicon-reglages:before, .dsicon-reglages-xl:before {
  content: "";
}

.dsicon-search:before, .dsicon-search-xl:before {
  content: "";
}

.dsicon-search-help:before, .dsicon-search-help-xl:before {
  content: "";
}

.dsicon-smile:before, .dsicon-smile-xl:before {
  content: "";
}

.dsicon-smile-circle:before, .dsicon-smile-circle-xl:before {
  content: "";
}

.dsicon-smiley:before, .dsicon-smiley-xl:before {
  content: "";
}

.dsicon-soleil:before, .dsicon-soleil-xl:before {
  content: "";
}

.dsicon-stethoscope:before, .dsicon-stethoscope-xl:before {
  content: "";
}

.dsicon-tel:before, .dsicon-tel-xl:before {
  content: "";
}

.dsicon-tombe:before, .dsicon-tombe-xl:before {
  content: "";
}

.dsicon-trombone:before, .dsicon-trombone-xl:before {
  content: "";
}

.dsicon-tv-tel:before, .dsicon-tv-tel-xl:before {
  content: "";
}

.dsicon-twitter:before {
  content: "";
  font-size: 1.5rem;
}

.dsicon-twitter-xl:before {
  content: "";
}

.dsicon-upload:before, .dsicon-upload-xl:before {
  content: "";
}

.dsicon-urgence:before, .dsicon-urgence-xl:before {
  content: "";
}

.dsicon-utilisateur-ajout:before, .dsicon-utilisateur-ajout-xl:before {
  content: "";
}

.dsicon-utilisateur-suppr:before, .dsicon-utilisateur-suppr-xl:before {
  content: "";
}

.dsicon-utilisateurs-multiples:before, .dsicon-utilisateurs-multiples-xl:before {
  content: "";
}

.dsicon-vague:before, .dsicon-vague-xl:before {
  content: "";
}

.dsicon-warning:before, .ds-alert.ds-alert--error .ds-alert__content-info .ds-alert__icon:before, .dsicon-warning-xl:before {
  content: "";
}

.dsicon-word:before, .dsicon-word-xl:before {
  content: "";
}

.dsicon-www:before, .dsicon-www-xl:before {
  content: "";
}

.dsicon-youtube:before {
  content: "";
  font-size: 1.5rem;
}

.dsicon-youtube-xl:before {
  content: "";
}

.dsicon-imprimer:before, .dsicon-imprimer-xl:before {
  content: "";
}

.dsicon-assurance-plus:before, .dsicon-assurance-plus-xl:before {
  content: "";
}

.dsicon-carte:before, .dsicon-carte-xl:before {
  content: "";
}

.dsicon-copier-coller:before, .dsicon-copier-coller-xl:before {
  content: "";
}

.dsicon-trier:before, .dsicon-trier-xl:before {
  content: "";
}

.dsicon-partage-ios:before, .dsicon-partage-ios-xl:before {
  content: "";
}

.dsicon-partage:before, .dsicon-partage-xl:before {
  content: "";
}

.dsicon-visio:before, .dsicon-visio-xl:before {
  content: "";
}

.dsicon-photo:before, .dsicon-photo-xl:before {
  content: "";
}

@font-face {
  font-family: poppins-regular;
  src: url("Poppins-Regular.f3220fcc.ttf");
}

@font-face {
  font-family: poppins-bold;
  src: url("Poppins-Bold.12951038.ttf");
}

a {
  cursor: pointer;
  text-decoration: underline;
}

a:not(.corail_mh_brand):hover, a:not(.corail_mh_brand):active, a:not(.corail_mh_brand).active-link {
  color: var(--corail_mh_brand) !important;
}

a.corail_mh_brand:hover, a.corail_mh_brand:active, a.corail_mh_brand.active-link {
  color: var(--corail_mh_dark) !important;
}

a.chevron {
  letter-spacing: normal;
  color: #222;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.38;
  text-decoration: none !important;
}

a.chevron:after {
  vertical-align: middle;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  content: "";
  margin-left: 8px;
  font-family: ds-icon, Arial, Helvetica, sans-serif;
  line-height: 1;
}

a.tabs {
  letter-spacing: normal;
  text-align: center;
  color: var(--gris_dark);
  border-bottom: 2px solid var(--gris_light);
  padding: 0 32px;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.38;
  text-decoration: none !important;
}

a.tabs:hover, a.tabs:active, a.tabs.active-link {
  color: var(--corail_mh_brand) !important;
  border-color: var(--corail_mh_brand) !important;
}

li > a {
  -webkit-text-decoration: unset;
  text-decoration: unset;
}

.opacite-1 {
  opacity: .1;
}

.opacite-2 {
  opacity: .2;
}

.opacite-3 {
  opacity: .3;
}

.opacite-4 {
  opacity: .4;
}

.opacite-5 {
  opacity: .5;
}

.opacite-6 {
  opacity: .6;
}

.opacite-7 {
  opacity: .7;
}

.opacite-8 {
  opacity: .8;
}

.opacite-9 {
  opacity: .9;
}

.opacite-10 {
  opacity: 1;
}

.radius-0 {
  border-radius: 0;
}

.radius-2, .ds-alert {
  border-radius: 2px;
}

.radius-4, .ds-card__content {
  border-radius: 4px;
}

.radius-8 {
  border-radius: 8px;
}

.radius-16 {
  border-radius: 16px;
}

.radius-100 {
  border-radius: 100%;
}

.shadow-0-0 {
  box-shadow: 0 0 #2222224d;
}

.shadow-0-1 {
  box-shadow: 0 0 1px #2222224d;
}

.shadow-0-2 {
  box-shadow: 0 0 2px #2222224d;
}

.shadow-0-3 {
  box-shadow: 0 0 3px #2222224d;
}

.shadow-0-4 {
  box-shadow: 0 0 4px #2222224d;
}

.shadow-0-5 {
  box-shadow: 0 0 5px #2222224d;
}

.shadow-0-6 {
  box-shadow: 0 0 6px #2222224d;
}

.shadow-0-7 {
  box-shadow: 0 0 7px #2222224d;
}

.shadow-0-8 {
  box-shadow: 0 0 8px #2222224d;
}

.shadow-0-9 {
  box-shadow: 0 0 9px #2222224d;
}

.shadow-0-10 {
  box-shadow: 0 0 10px #2222224d;
}

.shadow-0-11 {
  box-shadow: 0 0 11px #2222224d;
}

.shadow-0-12 {
  box-shadow: 0 0 12px #2222224d;
}

.shadow-0-13 {
  box-shadow: 0 0 13px #2222224d;
}

.shadow-0-14 {
  box-shadow: 0 0 14px #2222224d;
}

.shadow-0-15 {
  box-shadow: 0 0 15px #2222224d;
}

.shadow-0-16 {
  box-shadow: 0 0 16px #2222224d;
}

.shadow-1-0 {
  box-shadow: 0 1px #2222224d;
}

.shadow-1-1 {
  box-shadow: 0 1px 1px #2222224d;
}

.shadow-1-2 {
  box-shadow: 0 1px 2px #2222224d;
}

.shadow-1-3 {
  box-shadow: 0 1px 3px #2222224d;
}

.shadow-1-4 {
  box-shadow: 0 1px 4px #2222224d;
}

.shadow-1-5 {
  box-shadow: 0 1px 5px #2222224d;
}

.shadow-1-6 {
  box-shadow: 0 1px 6px #2222224d;
}

.shadow-1-7 {
  box-shadow: 0 1px 7px #2222224d;
}

.shadow-1-8 {
  box-shadow: 0 1px 8px #2222224d;
}

.shadow-1-9 {
  box-shadow: 0 1px 9px #2222224d;
}

.shadow-1-10 {
  box-shadow: 0 1px 10px #2222224d;
}

.shadow-1-11 {
  box-shadow: 0 1px 11px #2222224d;
}

.shadow-1-12 {
  box-shadow: 0 1px 12px #2222224d;
}

.shadow-1-13 {
  box-shadow: 0 1px 13px #2222224d;
}

.shadow-1-14 {
  box-shadow: 0 1px 14px #2222224d;
}

.shadow-1-15 {
  box-shadow: 0 1px 15px #2222224d;
}

.shadow-1-16 {
  box-shadow: 0 1px 16px #2222224d;
}

.shadow-2-0 {
  box-shadow: 0 2px #2222224d;
}

.shadow-2-1 {
  box-shadow: 0 2px 1px #2222224d;
}

.shadow-2-2 {
  box-shadow: 0 2px 2px #2222224d;
}

.shadow-2-3 {
  box-shadow: 0 2px 3px #2222224d;
}

.shadow-2-4 {
  box-shadow: 0 2px 4px #2222224d;
}

.shadow-2-5 {
  box-shadow: 0 2px 5px #2222224d;
}

.shadow-2-6 {
  box-shadow: 0 2px 6px #2222224d;
}

.shadow-2-7 {
  box-shadow: 0 2px 7px #2222224d;
}

.shadow-2-8 {
  box-shadow: 0 2px 8px #2222224d;
}

.shadow-2-9 {
  box-shadow: 0 2px 9px #2222224d;
}

.shadow-2-10 {
  box-shadow: 0 2px 10px #2222224d;
}

.shadow-2-11 {
  box-shadow: 0 2px 11px #2222224d;
}

.shadow-2-12 {
  box-shadow: 0 2px 12px #2222224d;
}

.shadow-2-13 {
  box-shadow: 0 2px 13px #2222224d;
}

.shadow-2-14 {
  box-shadow: 0 2px 14px #2222224d;
}

.shadow-2-15 {
  box-shadow: 0 2px 15px #2222224d;
}

.shadow-2-16 {
  box-shadow: 0 2px 16px #2222224d;
}

.shadow-3-0 {
  box-shadow: 0 3px #2222224d;
}

.shadow-3-1 {
  box-shadow: 0 3px 1px #2222224d;
}

.shadow-3-2 {
  box-shadow: 0 3px 2px #2222224d;
}

.shadow-3-3 {
  box-shadow: 0 3px 3px #2222224d;
}

.shadow-3-4 {
  box-shadow: 0 3px 4px #2222224d;
}

.shadow-3-5 {
  box-shadow: 0 3px 5px #2222224d;
}

.shadow-3-6, .ds-card-input:not(.ds-card--disabled) .ds-card__content:hover, .ds-card-button:not(.ds-card--disabled) .ds-card__content:hover, .ds-card-input.ds-card--focused .ds-card__content, .ds-card-button.ds-card--focused .ds-card__content {
  box-shadow: 0 3px 6px #2222224d;
}

.shadow-3-7 {
  box-shadow: 0 3px 7px #2222224d;
}

.shadow-3-8 {
  box-shadow: 0 3px 8px #2222224d;
}

.shadow-3-9 {
  box-shadow: 0 3px 9px #2222224d;
}

.shadow-3-10 {
  box-shadow: 0 3px 10px #2222224d;
}

.shadow-3-11 {
  box-shadow: 0 3px 11px #2222224d;
}

.shadow-3-12 {
  box-shadow: 0 3px 12px #2222224d;
}

.shadow-3-13 {
  box-shadow: 0 3px 13px #2222224d;
}

.shadow-3-14 {
  box-shadow: 0 3px 14px #2222224d;
}

.shadow-3-15 {
  box-shadow: 0 3px 15px #2222224d;
}

.shadow-3-16 {
  box-shadow: 0 3px 16px #2222224d;
}

.shadow-4-0 {
  box-shadow: 0 4px #2222224d;
}

.shadow-4-1 {
  box-shadow: 0 4px 1px #2222224d;
}

.shadow-4-2 {
  box-shadow: 0 4px 2px #2222224d;
}

.shadow-4-3 {
  box-shadow: 0 4px 3px #2222224d;
}

.shadow-4-4 {
  box-shadow: 0 4px 4px #2222224d;
}

.shadow-4-5 {
  box-shadow: 0 4px 5px #2222224d;
}

.shadow-4-6 {
  box-shadow: 0 4px 6px #2222224d;
}

.shadow-4-7 {
  box-shadow: 0 4px 7px #2222224d;
}

.shadow-4-8 {
  box-shadow: 0 4px 8px #2222224d;
}

.shadow-4-9 {
  box-shadow: 0 4px 9px #2222224d;
}

.shadow-4-10 {
  box-shadow: 0 4px 10px #2222224d;
}

.shadow-4-11 {
  box-shadow: 0 4px 11px #2222224d;
}

.shadow-4-12 {
  box-shadow: 0 4px 12px #2222224d;
}

.shadow-4-13 {
  box-shadow: 0 4px 13px #2222224d;
}

.shadow-4-14 {
  box-shadow: 0 4px 14px #2222224d;
}

.shadow-4-15 {
  box-shadow: 0 4px 15px #2222224d;
}

.shadow-4-16 {
  box-shadow: 0 4px 16px #2222224d;
}

.shadow-5-0 {
  box-shadow: 0 5px #2222224d;
}

.shadow-5-1 {
  box-shadow: 0 5px 1px #2222224d;
}

.shadow-5-2 {
  box-shadow: 0 5px 2px #2222224d;
}

.shadow-5-3 {
  box-shadow: 0 5px 3px #2222224d;
}

.shadow-5-4 {
  box-shadow: 0 5px 4px #2222224d;
}

.shadow-5-5 {
  box-shadow: 0 5px 5px #2222224d;
}

.shadow-5-6 {
  box-shadow: 0 5px 6px #2222224d;
}

.shadow-5-7 {
  box-shadow: 0 5px 7px #2222224d;
}

.shadow-5-8 {
  box-shadow: 0 5px 8px #2222224d;
}

.shadow-5-9 {
  box-shadow: 0 5px 9px #2222224d;
}

.shadow-5-10 {
  box-shadow: 0 5px 10px #2222224d;
}

.shadow-5-11 {
  box-shadow: 0 5px 11px #2222224d;
}

.shadow-5-12 {
  box-shadow: 0 5px 12px #2222224d;
}

.shadow-5-13 {
  box-shadow: 0 5px 13px #2222224d;
}

.shadow-5-14 {
  box-shadow: 0 5px 14px #2222224d;
}

.shadow-5-15 {
  box-shadow: 0 5px 15px #2222224d;
}

.shadow-5-16 {
  box-shadow: 0 5px 16px #2222224d;
}

.shadow-6-0 {
  box-shadow: 0 6px #2222224d;
}

.shadow-6-1 {
  box-shadow: 0 6px 1px #2222224d;
}

.shadow-6-2 {
  box-shadow: 0 6px 2px #2222224d;
}

.shadow-6-3 {
  box-shadow: 0 6px 3px #2222224d;
}

.shadow-6-4 {
  box-shadow: 0 6px 4px #2222224d;
}

.shadow-6-5 {
  box-shadow: 0 6px 5px #2222224d;
}

.shadow-6-6 {
  box-shadow: 0 6px 6px #2222224d;
}

.shadow-6-7 {
  box-shadow: 0 6px 7px #2222224d;
}

.shadow-6-8 {
  box-shadow: 0 6px 8px #2222224d;
}

.shadow-6-9 {
  box-shadow: 0 6px 9px #2222224d;
}

.shadow-6-10 {
  box-shadow: 0 6px 10px #2222224d;
}

.shadow-6-11 {
  box-shadow: 0 6px 11px #2222224d;
}

.shadow-6-12 {
  box-shadow: 0 6px 12px #2222224d;
}

.shadow-6-13 {
  box-shadow: 0 6px 13px #2222224d;
}

.shadow-6-14 {
  box-shadow: 0 6px 14px #2222224d;
}

.shadow-6-15 {
  box-shadow: 0 6px 15px #2222224d;
}

.shadow-6-16 {
  box-shadow: 0 6px 16px #2222224d;
}

.forme-brand {
  background-color: var(--bleu_turquoise_light);
  padding: 15px 20px;
  position: relative;
}

.forme-brand i.dsicon-smile-bulle-left, .forme-brand i.dsicon-smile-bulle-right, .forme-brand i.dsicon-smile-bulle-top, .forme-brand i.dsicon-smile-bulle-bottom {
  color: var(--bleu_turquoise_light);
  font-size: 3.75rem;
  display: none;
  position: absolute;
}

.forme-brand.bulle-haut {
  margin-top: 60px;
}

.forme-brand.bulle-haut i.dsicon-smile-bulle-top {
  display: inline;
  top: -60px;
  right: 20px;
}

.forme-brand.bulle-bas {
  margin-bottom: 60px;
}

.forme-brand.bulle-bas i.dsicon-smile-bulle-bottom {
  display: inline;
  right: 20px;
}

.forme-brand.bulle-droite {
  margin-right: 60px;
}

.forme-brand.bulle-droite i.dsicon-smile-bulle-right {
  display: inline;
  bottom: 10px;
  right: -66px;
}

.forme-brand.bulle-gauche {
  margin-left: 60px;
}

.forme-brand.bulle-gauche i.dsicon-smile-bulle-left {
  display: inline;
  bottom: 10px;
  left: -66px;
}

.mh-radio {
  padding-right: 24px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.mh-radio input[type="radio"] {
  opacity: 0;
  z-index: -10000;
  position: absolute;
}

.mh-radio input[type="radio"] + label {
  cursor: pointer;
  padding-left: 24px;
  font-size: .875rem;
}

.mh-radio input[type="radio"] + label:before {
  content: "";
  border: 1px solid var(--gris_dark);
  background: #fff;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.mh-radio input[type="radio"] + label:after {
  content: "";
  border-radius: 100%;
  width: 8px;
  height: 8px;
  transition: all .2s;
  position: absolute;
  top: 4px;
  left: 4px;
}

.mh-radio input[type="radio"]:checked + label:before {
  border-color: var(--corail_mh_brand);
}

.mh-radio input[type="radio"]:checked + label:after {
  background: var(--corail_mh_brand);
}

.mh-checkbox {
  padding-right: 24px;
  line-height: 16px;
  display: inline-block;
  position: relative;
}

.mh-checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: -10000;
  position: absolute;
}

.mh-checkbox input[type="checkbox"] + label {
  cursor: pointer;
  padding-left: 24px;
  font-size: .875rem;
}

.mh-checkbox input[type="checkbox"] + label:before {
  content: "";
  border: 1px solid var(--gris_dark);
  background: #fff;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.mh-checkbox input[type="checkbox"] + label:after {
  color: #fff;
  opacity: 0;
  width: 12px;
  height: 12px;
  font-family: ds-icon, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.mh-checkbox input[type="checkbox"]:checked + label:before, .mh-checkbox input[type="checkbox"]:indeterminate + label:before {
  background: var(--corail_mh_brand);
  border-color: var(--corail_mh_brand);
}

.mh-checkbox input[type="checkbox"]:checked + label:after, .mh-checkbox input[type="checkbox"]:indeterminate + label:after {
  opacity: 1;
}

.mh-checkbox input[type="checkbox"]:checked + label:after {
  content: "";
}

.mh-checkbox input[type="checkbox"]:indeterminate + label:after {
  content: "";
}

.mh-button-toggle label {
  font-weight: bold;
}

.mh-button-toggle-group {
  margin-top: 8px;
}

.mh-button-toggle-group button {
  background-color: var(--gris_light);
  color: var(--gris_dark);
  border: 1px solid #e1e1e1;
  outline: none;
  height: 32px;
  padding: 0 40px;
  font-size: 1rem;
  line-height: 32px;
}

.mh-button-toggle-group button + button {
  border-left: 0;
}

.mh-button-toggle-group button:first-child {
  border-radius: 32px 0 0 32px;
}

.mh-button-toggle-group button:last-child {
  border-radius: 0 32px 32px 0;
}

.mh-button-toggle-group button.active {
  background-color: var(--corail_mh_brand);
  border-color: var(--corail_mh_brand);
  color: #fff;
  transition: all 1s;
}

.mh-button-toggle-group button:not(.active):hover {
  color: #222;
}

.mh-button-quantity label {
  font-weight: bold;
}

.mh-button-quantity-group {
  margin-top: 8px;
  display: flex;
}

.mh-button-quantity-group button {
  border: 1px solid var(--corail_mh_brand);
  color: #fff;
  background-color: var(--corail_mh_brand);
  outline: none;
  height: 32px;
  padding: 0;
  line-height: 32px;
}

.mh-button-quantity-group button:first-child {
  border-radius: 32px 0 0 32px;
  padding-left: 15px;
  padding-right: 10px;
}

.mh-button-quantity-group button:last-child {
  border-radius: 0 32px 32px 0;
  padding-left: 10px;
  padding-right: 15px;
}

.mh-button-quantity-group button:hover {
  background-color: var(--corail_mh_dark);
  border: 1px solid var(--corail_mh_dark);
}

.mh-button-quantity-group input {
  text-align: center;
  color: #222;
  -moz-appearance: textfield;
  border: 1px solid #e1e1e1;
  border-left: 0;
  border-right: 0;
  width: 50px;
  height: 32px;
  line-height: 32px;
}

.mh-button-quantity-group input.zero {
  color: #757575;
}

.mh-button-quantity-group input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mh-button-quantity-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mh-slide-toggle {
  width: 52px;
  height: 32px;
  display: inline-block;
  position: relative;
}

.mh-slide-toggle input {
  opacity: 0;
  z-index: -1000;
  position: absolute;
}

.mh-slide-toggle input:checked + span {
  background-color: var(--corail_mh_brand);
}

.mh-slide-toggle input:checked + span i {
  color: var(--corail_mh_brand);
  font-size: 1.4rem;
  top: 11px;
  left: 25px;
  transform: rotate(0);
}

.mh-slide-toggle input:checked + span:before {
  transform: translateX(20px);
  box-shadow: 0 1px 0 -1px #222;
}

.mh-slide-toggle span {
  cursor: pointer;
  background-color: var(--gris_dark);
  border-radius: 32px;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.mh-slide-toggle span i {
  transform-origin: center;
  color: var(--gris_dark);
  width: 22px;
  height: 22px;
  font-size: 1.4rem;
  transition: all .4s;
  display: block;
  position: relative;
  top: 0;
  left: 5px;
  transform: rotate(180deg);
}

.mh-slide-toggle span:before {
  content: "";
  background-color: #fff;
  border-radius: 32px;
  width: 28px;
  height: 28px;
  transition: all .4s;
  position: absolute;
  bottom: 2px;
  left: 2px;
  box-shadow: 0 1px 4px -1px #222;
}

.form-group select {
  box-shadow: none;
  appearance: none;
  background: var(--gris_background);
  border: none;
  width: 100%;
  height: 55px;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 1.6;
  position: relative;
}

.form-group select ~ .message-aide-select {
  color: var(--gris_dark);
  visibility: visible;
  margin-top: 4px;
  margin-left: 8px;
  font-size: .75rem;
  position: absolute;
}

.form-group select ~ .message-erreur-select {
  visibility: hidden;
  position: absolute;
}

.form-group select.erreur ~ .bar {
  border-bottom: 2px solid #eb0000;
  display: block;
  position: relative;
}

.form-group select.erreur ~ .message-erreur-select {
  color: var(--rouge_negatif);
  visibility: visible;
  margin-top: 4px;
  margin-left: 8px;
  font-size: .75rem;
  position: absolute;
}

.form-group select.erreur ~ .message-aide-select {
  visibility: hidden;
}

.form-group select.valide ~ .bar {
  border-bottom: 2px solid #238500;
  display: block;
  position: relative;
}

.form-group select.valide ~ .message-erreur-select, .form-group select.valide ~ .message-aide-select {
  visibility: hidden;
}

.form-group select:focus {
  outline: none;
}

.form-group select:focus ~ i.dsicon-chevron-bas, .form-group .ds-select-input select:focus ~ i.ds-select__chevron-bas, .ds-select-input .form-group select:focus ~ i.ds-select__chevron-bas {
  display: none;
}

.form-group select:focus ~ i.dsicon-chevron-haut, .form-group .ds-select-input select:focus ~ i.ds-select__chevron-haut, .ds-select-input .form-group select:focus ~ i.ds-select__chevron-haut {
  display: inline;
}

.form-group select:focus ~ .bar:before {
  width: 100%;
  left: 0;
}

.form-group i.dsicon-chevron-bas, .form-group .ds-select-input i.ds-select__chevron-bas, .ds-select-input .form-group i.ds-select__chevron-bas, .form-group i.dsicon-chevron-haut, .form-group .ds-select-input i.ds-select__chevron-haut, .ds-select-input .form-group i.ds-select__chevron-haut {
  position: absolute;
  top: 20px;
  bottom: 0;
  right: 15px;
}

.form-group i.dsicon-chevron-bas, .form-group .ds-select-input i.ds-select__chevron-bas, .ds-select-input .form-group i.ds-select__chevron-bas {
  display: inline;
}

.form-group i.dsicon-chevron-haut, .form-group .ds-select-input i.ds-select__chevron-haut, .ds-select-input .form-group i.ds-select__chevron-haut {
  display: none;
}

.dropdown-link {
  border-bottom: 1px dashed #222;
}

.dropdown-link a {
  text-decoration: none;
}

.dropdown-link .dsicon-fleche-dropdown-texte {
  margin-left: 10px;
}

.paging-container .debut-paging, .paging-container .precedent, .paging-container .suivant, .paging-container .fin-paging {
  border: 1px solid var(--gris_dark);
  opacity: .8;
  background: #fff;
  border-radius: 50%;
  font-size: .875rem;
}

.paging-container .debut-paging:not(:disabled):not(.disabled):hover, .paging-container .precedent:not(:disabled):not(.disabled):hover, .paging-container .suivant:not(:disabled):not(.disabled):hover, .paging-container .fin-paging:not(:disabled):not(.disabled):hover {
  background: var(--gris_background);
}

.paging-container .debut-paging {
  margin-right: 5px;
  padding: 12px;
}

.paging-container .precedent {
  margin-right: 8px;
  padding: 12px;
}

.paging-container .compte {
  color: #222;
  margin-right: 8px;
  font-size: .875rem;
  line-height: 1.36;
}

.paging-container .compte .compte-total {
  color: var(--gris_dark);
}

.paging-container .suivant {
  margin-right: 8px;
  padding: 12px;
}

.paging-container .fin-paging {
  padding: 12px;
}

.paging-container .disabled, .paging-container [disabled] {
  pointer-events: none;
  opacity: .3;
  color: #222 !important;
  border-color: var(--gris_dark) !important;
}

.ds-loader {
  color: var(--corail_mh_brand);
  transform-origin: center;
  width: 1em;
  height: 1em;
  animation: 2s 2s infinite loader-icon-spin;
  display: inline-block;
  position: relative;
  transform: rotate(0);
}

.ds-loader:before {
  animation: 12s .333333s infinite backwards loader-icon-color;
  position: absolute;
  bottom: -.5em;
}

@keyframes loader-icon-spin {
  0% {
    transform: rotate(0);
  }

  33%, 100% {
    transform: rotate(-360deg);
  }
}

@keyframes loader-icon-color {
  0%, 13.3333% {
    color: var(--corail_mh_brand);
  }

  16.6667%, 30% {
    color: var(--jaune_dark);
  }

  33.3333%, 46.6667% {
    color: var(--corail_mh_brand);
  }

  50%, 63.3333% {
    color: var(--bleu_dark);
  }

  66.6667%, 80% {
    color: var(--corail_mh_brand);
  }

  83.3333%, 96.6667% {
    color: var(--rose_light);
  }
}

.ds-table {
  letter-spacing: normal;
  width: 100%;
  font-size: .875rem;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.36;
}

.ds-table tr {
  transition: background-color .2s;
}

.ds-table tr td, .ds-table tr th {
  vertical-align: middle;
  height: 4.5rem;
  padding: .25rem .75rem;
}

.ds-table tr td:first-child, .ds-table tr th:first-child {
  padding-left: 1.5rem;
}

.ds-table tr td:last-child, .ds-table tr th:last-child {
  padding-right: 1.5rem;
}

.ds-table__mobile-label {
  display: none;
}

.ds-table thead tr, .ds-table tfoot tr {
  border-top: 1px solid var(--corail_mh_brand);
  border-bottom: 1px solid var(--corail_mh_brand);
  color: var(--corail_mh_brand);
}

.ds-table thead tr td, .ds-table thead tr th, .ds-table tfoot tr td, .ds-table tfoot tr th {
  height: 3rem;
  padding: .5rem .75rem;
}

@media (width <= 1024px) {
  .ds-table--mobile-layout thead, .ds-table--mobile-layout tfoot {
    display: none;
  }

  .ds-table--mobile-layout tr td, .ds-table--mobile-layout tr th {
    height: auto;
    padding: .5rem;
    display: flex;
  }

  .ds-table--mobile-layout tr td:first-child, .ds-table--mobile-layout tr th:first-child {
    padding-top: 1rem;
    padding-left: .5rem;
  }

  .ds-table--mobile-layout tr td:last-child, .ds-table--mobile-layout tr th:last-child {
    padding-bottom: 1rem;
    padding-right: .5rem;
  }

  .ds-table--mobile-layout__mobile-label {
    text-align: right;
    color: var(--gris_dark);
    flex: 0 0 115px;
    margin-right: 1rem;
    display: block;
  }
}

.ds-table--alternate-bg tbody tr:nth-child(odd) {
  background: var(--gris_background);
}

.ds-table__row-selected {
  background: rgba(var(--bleu_texte-rgb), .2);
  border-top: 1px solid var(--bleu_texte);
  border-bottom: 1px solid var(--bleu_texte);
}

.ds-table__row-hoverable:hover {
  background: rgba(var(--bleu_texte-rgb), .2) !important;
}

.ds-alert {
  flex-flow: row;
  justify-content: space-between;
  padding: 1.5rem;
  display: flex;
}

.ds-alert.ds-alert--hidden {
  display: none;
}

.ds-alert .ds-alert__title {
  font-weight: bolder;
}

.ds-alert .ds-alert__content {
  width: 100%;
  display: flex;
}

.ds-alert .ds-alert__content-info {
  align-self: center;
}

.ds-alert .ds-alert__content-text {
  margin-left: 1.5rem;
  margin-right: 2.3125rem;
  font-size: .875rem;
}

.header-title {
  border-radius: 0 0 50% 50% / 0 0 30px 30px;
  padding: 32px 32px 71px;
}

.ds-stepper {
  display: block;
}

.ds-stepper a {
  text-decoration: none;
}

.ds-stepper .ds-stepper__step {
  box-sizing: content-box;
  background: none;
  border: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.ds-stepper .ds-stepper__step.ds-stepper__step--active {
  background: var(--bleu_turquoise_light);
  border-radius: 1.75rem;
  animation-name: current-step;
  animation-duration: .5s;
}

.ds-stepper .ds-stepper__step.ds-stepper__step--active .ds-stepper__step-icon {
  color: var(--bleu_dark);
}

.ds-stepper .ds-stepper__step.ds-stepper__step--active .ds-stepper__step-label {
  color: var(--bleu_dark);
  font-weight: bold;
}

.ds-stepper .ds-stepper__step.ds-stepper__step--active .ds-stepper__step-progression {
  white-space: nowrap;
  color: var(--bleu_dark);
  margin-left: 1rem;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  position: absolute;
}

.ds-stepper .ds-stepper__step.ds-stepper__step--clickable {
  cursor: pointer;
}

.ds-stepper .ds-stepper__step-icon {
  color: #222;
  background: none;
  border-radius: 1.75rem;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .05rem;
  position: relative;
}

.ds-stepper .ds-stepper__step-icon i[class*="dsicon-"] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ds-stepper .ds-stepper__step-icon.ds-stepper__step-icon--validated-step {
  color: #fff;
  background: var(--vert_positif);
  animation-name: validate-step;
  animation-duration: .5s;
}

.ds-stepper .ds-stepper__step-icon:not(.ds-stepper__step-icon--active):not(.ds-stepper__step-icon--validated-step) {
  color: var(--gris_dark);
  background: var(--gris_light);
}

.ds-stepper .ds-stepper__step-fieldset-list {
  width: 100%;
  overflow: hidden;
}

.ds-stepper .ds-stepper__step-fieldset-list.ds-stepper__step-fieldset-list--open {
  padding: 0 0 16px 40px;
}

.ds-stepper .ds-stepper__step-fieldset-list a {
  text-decoration: none;
}

.ds-stepper .ds-stepper__step-fieldset-name {
  padding: 8px 0;
  font-weight: bold;
}

.ds-stepper .ds-stepper__step-field {
  background: var(--gris_background);
  border-radius: 4px;
  justify-content: space-between;
  min-height: 25px;
  margin-top: 4px;
  padding: 4px 8px;
  display: flex;
}

.ds-stepper .ds-stepper__step-field .ds-stepper__step-field-icon {
  color: var(--gris_dark);
  padding: 4px 8px 0;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__steps {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step-wrapper {
  display: flex;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__vertical-line-separator {
  border-left: .5rem solid var(--gris_light);
  height: .25rem;
  margin: 1rem .1rem;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__vertical-line-separator.ds-stepper__vertical-line-separator--validated {
  border-left: .5rem solid var(--vert_positif);
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step-fieldset-list.ds-stepper__step-fieldset-list--open {
  padding-top: 16px;
  padding-left: 0;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step {
  height: 2.5rem;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step.ds-stepper__step--active {
  padding-right: 1rem;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step.ds-stepper__step--active .ds-stepper__step-progression {
  position: relative;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step.ds-stepper__step--active .ds-stepper__step-label, .ds-stepper.ds-stepper--horizontal .ds-stepper__step-label {
  display: none;
}

.ds-stepper.ds-stepper--horizontal .ds-stepper__step-icon {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: .05rem;
}

.ds-stepper.ds-stepper--vertical {
  height: auto;
  display: block;
}

.ds-stepper.ds-stepper--vertical:before {
  width: 4px;
  height: auto;
  inset: 0 auto 0 58px;
}

.ds-stepper.ds-stepper--vertical .ds-stepper__vertical-line-separator {
  border-left: .25rem solid var(--gris_light);
  min-height: 1.5rem;
  margin: .25rem 0 .25rem 3.625rem;
}

.ds-stepper.ds-stepper--vertical .ds-stepper__step {
  height: 3.5rem;
}

.ds-stepper.ds-stepper--vertical .ds-stepper__step-label {
  margin-left: 1rem;
  display: inline;
}

.ds-stepper.ds-stepper--vertical .ds-stepper__step-icon {
  width: 3.5rem;
  height: 3.5rem;
  margin-left: 2rem;
}

@keyframes validate-step {
  from {
    background: var(--bleu_turquoise_light);
    width: 93%;
    margin-left: 1rem;
    position: absolute;
    left: 0;
  }

  to {
    background: var(--vert_positif);
    width: 3.5rem;
    margin-left: 2rem;
    position: relative;
  }
}

@keyframes current-step {
  from {
    background: var(--gris_light);
    width: 3.5rem;
    position: relative;
  }

  to {
    background: var(--bleu_turquoise_light);
    width: 93%;
    left: 0;
  }
}

.ds-typeform-container {
  display: block;
  position: relative;
}

.ds-typeform-container .ds-typeform-question-container {
  width: 100%;
  display: block;
  position: relative;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-back {
  margin-bottom: 2rem;
  display: block;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-back.hidden {
  visibility: hidden;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-question > * {
  width: 100%;
  display: block;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-title {
  text-align: start;
  flex-flow: column;
  margin-bottom: 3rem;
  font-size: 1.375rem;
  display: flex;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-title .ds-typeform-question-subtitle {
  margin-top: 1rem;
  font-size: 1rem;
  line-height: 1.38;
  display: block;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-field-container > * ~ * {
  margin-top: 3rem;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-field-container .popover {
  margin-top: 0;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-submit {
  padding: 2rem 0;
  display: block;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-submit .alternative-submit {
  margin-left: 1.5rem;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-submit .alternative-submit .keyboard-key {
  font-weight: bold;
}

.ds-typeform-container .ds-typeform-question-container .ds-typeform-submit.disabled .alternative-submit {
  opacity: .3;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-back {
  display: none;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-title {
  text-align: center;
  margin-bottom: 2rem;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-title .ds-typeform-question-subtitle {
  font-size: 14px;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-field-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-question .ds-typeform-question-field-container > * ~ * {
  margin-top: 2rem;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-submit button {
  width: 100%;
}

.mobile .ds-typeform-container .ds-typeform-question-container .ds-typeform-submit .alternative-submit {
  display: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow: hidden auto;
}

.modal {
  z-index: 1055;
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  pointer-events: none;
  width: auto;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-centered:before {
  content: "";
  height: min-content;
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  pointer-events: auto;
  border: 1px solid var(--bs-border-color-translucent);
  background-color: #fff;
  background-clip: padding-box;
  border-radius: .5rem;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1050;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header, .ds-modal__header {
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close, .modal-header .ds-modal__close, .ds-modal__header .close, .ds-modal__header .ds-modal__close {
  appearance: none;
  background: none;
  border: none;
  outline: none;
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-header .modal-title, .modal-header .ds-modal__title, .ds-modal__header .modal-title, .ds-modal__header .ds-modal__title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body, .ds-modal__body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer, .ds-modal__footer {
  border-bottom-right-radius: calc(.5rem - 1px);
  border-bottom-left-radius: calc(.5rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > *, .ds-modal__footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (width >= 20rem) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (width >= 80rem) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (width >= 90rem) {
  .modal-xl {
    max-width: 1140px;
  }
}

.popover {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 8px;
  max-width: 276px;
  font-family: poppins-regular;
  font-size: .875rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px #2222224d;
}

.popover .popover-body {
  border-radius: 8px;
  padding: 24px;
}

.popover .popover-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: -1px;
  padding: 24px 24px 0;
}

.popover .popover-header + .popover-body {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 4px 24px 24px !important;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"], .bs-popover-auto[data-popper-placement^="top"] {
  margin-bottom: .5rem;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[x-placement^="top"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-width: .5rem .5rem 0;
  border-top-color: var(--bs-border-color-translucent);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[x-placement^="top"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: .5rem .5rem 0;
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-end, .bs-popover-auto[x-placement^="right"], .bs-popover-auto[data-popper-placement^="right"] {
  margin-left: .5rem;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  margin: .5rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[x-placement^="right"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: var(--bs-border-color-translucent);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[x-placement^="right"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"], .bs-popover-auto[data-popper-placement^="bottom"] {
  margin-top: .5rem;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-width: 0 .5rem .5rem;
  border-bottom-color: var(--bs-border-color-translucent);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-start, .bs-popover-auto[x-placement^="left"], .bs-popover-auto[data-popper-placement^="left"] {
  margin-right: .5rem;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  width: .5rem;
  height: 1rem;
  margin: .5rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[x-placement^="left"] > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: var(--bs-border-color-translucent);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[x-placement^="left"] > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff;
  right: 1px;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow, .bs-popover-end > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow, .bs-popover-start-bottom > .popover-arrow, .bs-popover-start-top > .popover-arrow, .bs-popover-end-bottom > .popover-arrow, .bs-popover-end-top > .popover-arrow {
  top: -7px !important;
}

.popover-body {
  color: #212529;
  padding: 1rem;
}

.popover-error.bs-popover-top > .popover-arrow:after, .popover-error.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .popover-error.bs-popover-auto[x-placement^="top"] > .popover-arrow:after {
  border-top-color: #f9b2b2;
}

.popover-error.bs-popover-end > .popover-arrow:after, .popover-error.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .popover-error.bs-popover-auto[x-placement^="right"] > .popover-arrow:after {
  border-right-color: #f9b2b2;
}

.popover-error.bs-popover-bottom > .popover-arrow:after, .popover-error.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .popover-error.bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after {
  border-bottom-color: #f9b2b2;
}

.popover-error.bs-popover-start > .popover-arrow:after, .popover-error.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .popover-error.bs-popover-auto[x-placement^="left"] > .popover-arrow:after {
  border-left-color: #f9b2b2;
}

.popover-error .popover-body, .popover-error .popover-header {
  background-color: #f9b2b2;
}

.popover-info.bs-popover-top > .popover-arrow:after, .popover-info.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .popover-info.bs-popover-auto[x-placement^="top"] > .popover-arrow:after {
  border-top-color: #c1d5ee;
}

.popover-info.bs-popover-end > .popover-arrow:after, .popover-info.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .popover-info.bs-popover-auto[x-placement^="right"] > .popover-arrow:after {
  border-right-color: #c1d5ee;
}

.popover-info.bs-popover-bottom > .popover-arrow:after, .popover-info.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .popover-info.bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after {
  border-bottom-color: #c1d5ee;
}

.popover-info.bs-popover-start > .popover-arrow:after, .popover-info.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .popover-info.bs-popover-auto[x-placement^="left"] > .popover-arrow:after {
  border-left-color: #c1d5ee;
}

.popover-info .popover-body, .popover-info .popover-header {
  background-color: #c1d5ee;
}

.popover-neutral.bs-popover-top > .popover-arrow:after, .popover-neutral.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .popover-neutral.bs-popover-auto[x-placement^="top"] > .popover-arrow:after {
  border-top-color: #fff;
}

.popover-neutral.bs-popover-end > .popover-arrow:after, .popover-neutral.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .popover-neutral.bs-popover-auto[x-placement^="right"] > .popover-arrow:after {
  border-right-color: #fff;
}

.popover-neutral.bs-popover-bottom > .popover-arrow:after, .popover-neutral.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .popover-neutral.bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after {
  border-bottom-color: #fff;
}

.popover-neutral.bs-popover-start > .popover-arrow:after, .popover-neutral.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .popover-neutral.bs-popover-auto[x-placement^="left"] > .popover-arrow:after {
  border-left-color: #fff;
}

.popover-neutral .popover-body, .popover-neutral .popover-header {
  background-color: #fff;
}

.popover-valid.bs-popover-top > .popover-arrow:after, .popover-valid.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .popover-valid.bs-popover-auto[x-placement^="top"] > .popover-arrow:after {
  border-top-color: #bddab2;
}

.popover-valid.bs-popover-end > .popover-arrow:after, .popover-valid.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .popover-valid.bs-popover-auto[x-placement^="right"] > .popover-arrow:after {
  border-right-color: #bddab2;
}

.popover-valid.bs-popover-bottom > .popover-arrow:after, .popover-valid.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .popover-valid.bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after {
  border-bottom-color: #bddab2;
}

.popover-valid.bs-popover-start > .popover-arrow:after, .popover-valid.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .popover-valid.bs-popover-auto[x-placement^="left"] > .popover-arrow:after {
  border-left-color: #bddab2;
}

.popover-valid .popover-body, .popover-valid .popover-header {
  background-color: #bddab2;
}

.popover-warn.bs-popover-top > .popover-arrow:after, .popover-warn.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after, .popover-warn.bs-popover-auto[x-placement^="top"] > .popover-arrow:after {
  border-top-color: #f9f5ad;
}

.popover-warn.bs-popover-end > .popover-arrow:after, .popover-warn.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after, .popover-warn.bs-popover-auto[x-placement^="right"] > .popover-arrow:after {
  border-right-color: #f9f5ad;
}

.popover-warn.bs-popover-bottom > .popover-arrow:after, .popover-warn.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after, .popover-warn.bs-popover-auto[x-placement^="bottom"] > .popover-arrow:after {
  border-bottom-color: #f9f5ad;
}

.popover-warn.bs-popover-start > .popover-arrow:after, .popover-warn.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after, .popover-warn.bs-popover-auto[x-placement^="left"] > .popover-arrow:after {
  border-left-color: #f9f5ad;
}

.popover-warn .popover-body, .popover-warn .popover-header {
  background-color: #f9f5ad;
}

.popover-large {
  width: 400px;
  max-width: 400px;
}

.popover-content {
  display: flex;
}

.ds-card-input:not(.ds-card--disabled) .ds-card__content:hover, .ds-card-button:not(.ds-card--disabled) .ds-card__content:hover, .ds-card-input.ds-card--focused .ds-card__content, .ds-card-button.ds-card--focused .ds-card__content {
  cursor: pointer;
}

.ds-card-input:not(.ds-card--disabled) .ds-card__content:hover .ds-card__content-icon, .ds-card-button:not(.ds-card--disabled) .ds-card__content:hover .ds-card__content-icon, .ds-card-input.ds-card--focused .ds-card__content .ds-card__content-icon, .ds-card-button.ds-card--focused .ds-card__content .ds-card__content-icon, .ds-card-input:not(.ds-card--disabled) .ds-card__content:hover .ds-card__content-label, .ds-card-button:not(.ds-card--disabled) .ds-card__content:hover .ds-card__content-label, .ds-card-input.ds-card--focused .ds-card__content .ds-card__content-label, .ds-card-button.ds-card--focused .ds-card__content .ds-card__content-label {
  color: var(--bleu_dark) !important;
}

.ds-card-input.ds-card--checked .ds-card__content, .ds-card-button.ds-card--checked .ds-card__content {
  border: none;
}

.ds-card-input.ds-card--checked .ds-card__content .ds-card__content-icon, .ds-card-button.ds-card--checked .ds-card__content .ds-card__content-icon, .ds-card-input.ds-card--checked .ds-card__content .ds-card__content-label, .ds-card-button.ds-card--checked .ds-card__content .ds-card__content-label {
  color: var(--bleu_dark) !important;
}

.ds-card__content-sublabel {
  text-align: center;
}

.ds-card__selected-icon {
  color: #fff;
  opacity: 0;
  margin: 0;
  transition: opacity .2s, transform .2s;
  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: scale(0);
}

.ds-card__content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  transition: box-shadow .2s, background-color .2s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.ds-card__content-icons {
  align-items: center;
  display: flex;
}

.ds-card__content-icon {
  align-items: center;
  height: 1.5rem;
  margin-bottom: 1rem;
  transition: color .2s;
  display: flex;
}

.ds-card__content-icon + .ds-card__content-icon {
  padding-left: .5rem;
}

.ds-card__numero-icon {
  color: #fff;
  margin: 0;
  font-size: 175%;
  transition: opacity .2s, transform .2s;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.ds-card__info-icon {
  cursor: pointer;
  transition: color .2s;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.ds-card__content-label {
  text-align: center;
  font-size: 1rem;
  line-height: 1.38;
  transition: color .2s;
}

.ds-card__content-sublabel {
  text-align: center;
  margin-top: .5rem;
  font-size: .875rem;
  line-height: 1.1875rem;
  transition: color .2s;
}

.ds-card-input, .ds-card-button {
  -webkit-user-select: none;
  user-select: none;
  width: 12.5rem;
  height: 10.375rem;
  display: flex;
  position: relative;
}

.ds-card-input.ds-card--checked .ds-card__selected-icon, .ds-card-button.ds-card--checked .ds-card__selected-icon {
  opacity: 1;
  display: block;
  transform: scale(1);
}

.ds-card-input input {
  appearance: none;
  background: none !important;
}

.ds-card-input input[type="radio"] ~ .ds-card__content--selected:hover {
  cursor: initial;
  box-shadow: none;
}

.ds-card-button {
  appearance: none;
  background: none !important;
  border: none !important;
}

@media (width >= 90rem) {
  .ds-card-input, .ds-card-button {
    width: 12.5rem;
  }
}

.mobile .ds-card-input, .mobile .ds-card-button {
  width: 100%;
}

strong {
  font-weight: bolder;
}

.ds-card__list-container {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -34px;
  display: flex;
}

.ds-card__list-container .ds-card-input, .ds-card__list-container .ds-card-button {
  margin-bottom: 34px;
  margin-right: 34px;
}

.ds-card__list-container.ds-card__list-container--large .ds-card-input, .ds-card__list-container.ds-card__list-container--large .ds-card-button {
  width: 17.8rem;
}

.ds-card__list-container.ds-card__list-container--large .ds-card-input .ds-card__content, .ds-card__list-container.ds-card__list-container--large .ds-card-button .ds-card__content {
  justify-content: initial;
  padding-top: 2rem;
}

.ds-card__list-container.ds-card__list-container--large .ds-card-input .ds-card__content-centered, .ds-card__list-container.ds-card__list-container--large .ds-card-button .ds-card__content-centered {
  justify-content: center;
}

@media (width <= 652px) {
  .desktop .ds-card__list-container {
    justify-content: center;
  }
}

.mobile .ds-card__list-container {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-right: 0;
}

.mobile .ds-card__list-container.ds-card__list-container--large .ds-card-input, .mobile .ds-card__list-container.ds-card__list-container--large .ds-card-button {
  width: 100%;
}

.mobile .ds-card__list-container .ds-card-input, .mobile .ds-card__list-container .ds-card-button {
  margin-bottom: 18px;
  margin-right: 0;
}

.ds-input-group .ds-input.ds-input--focused ~ .ds-input__control-label, .ds-input-group .ds-input:not(.ds-input--undefined) ~ .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--focused ~ .ds-input__control-label, .ds-input-group .ds-input-control:not(.ds-input--undefined) ~ .ds-input__control-label {
  color: var(--bleu_texte);
  font-size: .75rem;
  top: 8px;
  left: 16px;
}

.ds-input-group .ds-input ~ .ds-input__control-label, .ds-input-group .ds-input-control ~ .ds-input__control-label {
  color: var(--gris_dark);
  font-size: 1rem;
  top: 16px;
  left: 16px;
}

.ds-input-group {
  min-height: 82px;
  margin-bottom: 8px;
  display: block;
  position: relative;
}

.ds-input-group .ds-input__control-label {
  pointer-events: none;
  z-index: 1;
  padding-left: .125rem;
  font-weight: normal;
  transition: all .28s;
  position: absolute;
}

.ds-input-group .ds-input__bar {
  border-bottom: .0625rem solid var(--gris_dark);
  display: block;
  position: relative;
}

.ds-input-group .ds-input__bar:before {
  content: "";
  background: var(--bleu_texte);
  z-index: 2;
  width: 0;
  height: .125rem;
  transition: left .28s, width .28s;
  position: absolute;
  bottom: -.0625rem;
  left: 50%;
}

.ds-input-group .message-aide, .ds-input-group .message-erreur {
  min-height: 1.0625rem;
  margin-top: 8px;
  margin-left: 16px;
  font-size: .75rem;
  line-height: 1.0625rem;
  display: none;
}

.ds-input-group .message-erreur {
  color: var(--rouge_negatif);
}

.ds-input-group .message-aide {
  color: var(--gris_dark);
}

.ds-input-group .ds-input__icon {
  width: 16px;
  height: 16px;
  margin: auto;
  font-size: 1rem;
  position: absolute;
  top: 0;
  bottom: 24px;
  right: 16px;
}

.ds-input-group .ds-input__edition, .ds-input-group .ds-input__validation {
  display: none;
}

.ds-input-group .ds-input__validation.dsicon-check {
  color: var(--vert_positif);
}

.ds-input-group .ds-input__validation.dsicon-close {
  color: var(--rouge_negatif);
}

.ds-input-group input.ds-input {
  padding: 20px 15px 5px;
}

.ds-input-group .ds-input, .ds-input-group .ds-input-control {
  background: var(--gris_background);
  color: #222;
  box-shadow: none;
  border-width: 0;
  border-color: #0000;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 56px;
  font-size: 1rem;
  line-height: 1.9;
  transition: all .28s;
  display: block;
}

.ds-input-group .ds-input.ds-input--disabled, .ds-input-group .ds-input-control.ds-input--disabled {
  pointer-events: none;
  background-color: var(--gris_background);
}

.ds-input-group .ds-input.ds-input--disabled ~ .ds-input__control-label, .ds-input-group .ds-input.ds-input--disabled .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--disabled ~ .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--disabled .ds-input__control-label {
  color: var(--gris_light);
}

.ds-input-group .ds-input.ds-input--disabled ~ .ds-input__bar, .ds-input-group .ds-input.ds-input--disabled .ds-input__bar, .ds-input-group .ds-input-control.ds-input--disabled ~ .ds-input__bar, .ds-input-group .ds-input-control.ds-input--disabled .ds-input__bar {
  border-color: var(--gris_light);
}

.ds-input-group .ds-input.ds-input--disabled ~ .message-aide, .ds-input-group .ds-input-control.ds-input--disabled ~ .message-aide, .ds-input-group .ds-input.ds-input--disabled ~ .message-erreur, .ds-input-group .ds-input-control.ds-input--disabled ~ .message-erreur {
  display: none;
}

.ds-input-group .ds-input.ds-input--focused, .ds-input-group .ds-input-control.ds-input--focused {
  background-color: var(--gris_light);
  color: #222;
}

.ds-input-group .ds-input.ds-input--focused ~ .ds-input__bar:before, .ds-input-group .ds-input.ds-input--focused .ds-input__bar:before, .ds-input-group .ds-input-control.ds-input--focused ~ .ds-input__bar:before, .ds-input-group .ds-input-control.ds-input--focused .ds-input__bar:before {
  width: 100%;
  left: 0;
}

.ds-input-group .ds-input:not(.ds-input--error):not(.ds-input--valid).ds-input--focused ~ .message-aide, .ds-input-group .ds-input-control:not(.ds-input--error):not(.ds-input--valid).ds-input--focused ~ .message-aide {
  display: block;
}

.ds-input-group .ds-input.ds-input--valid ~ .ds-input__bar:before, .ds-input-group .ds-input.ds-input--valid .ds-input__bar:before, .ds-input-group .ds-input-control.ds-input--valid ~ .ds-input__bar:before, .ds-input-group .ds-input-control.ds-input--valid .ds-input__bar:before {
  background: var(--vert_positif);
}

.ds-input-group .ds-input.ds-input--valid ~ .ds-input__control-label, .ds-input-group .ds-input.ds-input--valid .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--valid ~ .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--valid .ds-input__control-label {
  color: var(--vert_positif);
}

.ds-input-group .ds-input.ds-input--valid ~ .ds-input__validation.dsicon-check, .ds-input-group .ds-input-control.ds-input--valid ~ .ds-input__validation.dsicon-check {
  display: block;
}

.ds-input-group .ds-input.ds-input--error ~ .ds-input__bar:before, .ds-input-group .ds-input.ds-input--error .ds-input__bar:before, .ds-input-group .ds-input-control.ds-input--error ~ .ds-input__bar:before, .ds-input-group .ds-input-control.ds-input--error .ds-input__bar:before {
  background: var(--rouge_negatif);
}

.ds-input-group .ds-input.ds-input--error ~ .ds-input__control-label, .ds-input-group .ds-input.ds-input--error .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--error ~ .ds-input__control-label, .ds-input-group .ds-input-control.ds-input--error .ds-input__control-label {
  color: var(--rouge_negatif);
}

.ds-input-group .ds-input.ds-input--error ~ .ds-input__validation.dsicon-close, .ds-input-group .ds-input-control.ds-input--error ~ .ds-input__validation.dsicon-close, .ds-input-group .ds-input.ds-input--error ~ .message-erreur, .ds-input-group .ds-input-control.ds-input--error ~ .message-erreur {
  display: block;
}

.ds-input-group-typeform .ds-input.ds-input--focused ~ .ds-input__control-label, .ds-input-group-typeform .ds-input:not(.ds-input--undefined) ~ .ds-input__control-label, .ds-input-group-typeform .ds-input-control.ds-input--focused ~ .ds-input__control-label, .ds-input-group-typeform .ds-input-control:not(.ds-input--undefined) ~ .ds-input__control-label {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.ds-input-group-typeform .ds-input ~ .ds-input__control-label, .ds-input-group-typeform .ds-input-control ~ .ds-input__control-label {
  color: var(--gris_dark);
  font-size: 1rem;
  top: 8px;
  left: 8px;
}

.ds-input-group-typeform {
  min-height: 56px;
  margin-bottom: 8px;
  display: block;
  position: relative;
}

.ds-input-group-typeform .ds-input__control-label {
  pointer-events: none;
  z-index: 1;
  padding-left: .125rem;
  font-weight: normal;
  position: absolute;
}

.ds-input-group-typeform .ds-input__bar {
  border-bottom: .0625rem solid var(--gris_dark);
  display: block;
  position: relative;
}

.ds-input-group-typeform .ds-input__bar:before {
  content: "";
  background: var(--bleu_texte);
  z-index: 2;
  width: 0;
  height: .0625rem;
  transition: left .28s, width .28s;
  position: absolute;
  bottom: -.0625rem;
  left: 50%;
}

.ds-input-group-typeform .message-aide, .ds-input-group-typeform .message-erreur {
  min-height: 1.0625rem;
  margin-top: 8px;
  margin-left: 8px;
  font-size: .75rem;
  line-height: 1.0625rem;
  display: none;
}

.ds-input-group-typeform .message-erreur {
  color: var(--rouge_negatif);
}

.ds-input-group-typeform .message-aide {
  color: var(--gris_dark);
}

.ds-input-group-typeform .ds-input__icon {
  width: 16px;
  height: 16px;
  margin: auto;
  font-size: 1rem;
  position: absolute;
  top: 0;
  bottom: 24px;
  right: 8px;
}

.ds-input-group-typeform .ds-input__validation, .ds-input-group-typeform .ds-input__edition {
  display: none;
}

.ds-input-group-typeform input.ds-input {
  padding: 0 8px 4px;
}

.ds-input-group-typeform .ds-input, .ds-input-group-typeform .ds-input-control {
  color: #222;
  box-shadow: none;
  background-color: #0000;
  border-width: 0;
  border-color: #0000;
  width: 100%;
  height: 32px;
  font-size: 1.125rem;
  line-height: 1.5625rem;
  transition: all .28s;
  display: block;
}

.ds-input-group-typeform .ds-input.ds-input--pristine:not(.ds-input--undefined) ~ .ds-input__edition, .ds-input-group-typeform .ds-input-control.ds-input--pristine:not(.ds-input--undefined) ~ .ds-input__edition {
  display: block;
}

.ds-input-group-typeform .ds-input.ds-input--disabled, .ds-input-group-typeform .ds-input-control.ds-input--disabled {
  pointer-events: none;
}

.ds-input-group-typeform .ds-input.ds-input--disabled ~ .ds-input__control-label, .ds-input-group-typeform .ds-input.ds-input--disabled .ds-input__control-label, .ds-input-group-typeform .ds-input-control.ds-input--disabled ~ .ds-input__control-label, .ds-input-group-typeform .ds-input-control.ds-input--disabled .ds-input__control-label {
  color: var(--gris_light);
}

.ds-input-group-typeform .ds-input.ds-input--disabled ~ .ds-input__bar, .ds-input-group-typeform .ds-input.ds-input--disabled .ds-input__bar, .ds-input-group-typeform .ds-input-control.ds-input--disabled ~ .ds-input__bar, .ds-input-group-typeform .ds-input-control.ds-input--disabled .ds-input__bar {
  border-color: var(--gris_light);
}

.ds-input-group-typeform .ds-input.ds-input--disabled ~ .message-aide, .ds-input-group-typeform .ds-input-control.ds-input--disabled ~ .message-aide, .ds-input-group-typeform .ds-input.ds-input--disabled ~ .message-erreur, .ds-input-group-typeform .ds-input-control.ds-input--disabled ~ .message-erreur {
  display: none;
}

.ds-input-group-typeform .ds-input.ds-input--focused, .ds-input-group-typeform .ds-input-control.ds-input--focused {
  color: #222;
}

.ds-input-group-typeform .ds-input.ds-input--focused ~ .ds-input__bar:before, .ds-input-group-typeform .ds-input.ds-input--focused .ds-input__bar:before, .ds-input-group-typeform .ds-input-control.ds-input--focused ~ .ds-input__bar:before, .ds-input-group-typeform .ds-input-control.ds-input--focused .ds-input__bar:before {
  width: 100%;
  left: 0;
}

.ds-input-group-typeform .ds-input:not(.ds-input--error):not(.ds-input--valid).ds-input--focused ~ .message-aide, .ds-input-group-typeform .ds-input-control:not(.ds-input--error):not(.ds-input--valid).ds-input--focused ~ .message-aide {
  display: block;
}

.ds-input-group-typeform .ds-input.ds-input--valid ~ .ds-input__bar:before, .ds-input-group-typeform .ds-input.ds-input--valid .ds-input__bar:before, .ds-input-group-typeform .ds-input-control.ds-input--valid ~ .ds-input__bar:before, .ds-input-group-typeform .ds-input-control.ds-input--valid .ds-input__bar:before {
  background: var(--vert_positif);
}

.ds-input-group-typeform .ds-input.ds-input--error ~ .ds-input__bar:before, .ds-input-group-typeform .ds-input.ds-input--error .ds-input__bar:before, .ds-input-group-typeform .ds-input-control.ds-input--error ~ .ds-input__bar:before, .ds-input-group-typeform .ds-input-control.ds-input--error .ds-input__bar:before {
  background: var(--rouge_negatif);
}

.ds-input-group-typeform .ds-input.ds-input--error ~ .message-erreur, .ds-input-group-typeform .ds-input-control.ds-input--error ~ .message-erreur {
  display: block;
}

.ds-input-form {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.ds-input-form i {
  position: absolute;
  top: 4px;
  right: 8px;
}

.ds-input-form i.dsicon-crayon {
  color: #222;
}

.ds-input-form i.dsicon-crayon:hover {
  cursor: pointer;
}

.ds-input-form--field {
  border: none;
  border-bottom: 1px var(--gris_dark) solid;
  color: var(--gris_dark);
  appearance: none;
  outline: none;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 18px;
}

.ds-input-form--field:disabled {
  background-color: #fff;
}

.ds-input-form--field:focus, .ds-input-form--field:focus-visible, .ds-input-form--field:focus-within {
  border-bottom: 1px var(--bleu_texte) solid;
}

.ds-input-form--field:focus ~ .message-aide, .ds-input-form--field:focus-visible ~ .message-aide, .ds-input-form--field:focus-within ~ .message-aide {
  display: inherit;
}

.ds-input-form--field:focus ~ .message-erreur, .ds-input-form--field:focus-visible ~ .message-erreur, .ds-input-form--field:focus-within ~ .message-erreur {
  display: none;
}

.ds-input-form .message-aide, .ds-input-form .message-erreur {
  color: var(--gris_dark);
  margin-left: 8px;
  font-size: 12px;
  display: none;
}

.ds-input-form__editMode .ds-input-form--field {
  border-bottom: 1px var(--gris_light) solid;
}

.ds-input-form__disabled .ds-input-form--field, .ds-input-form__disabled .text-selected {
  opacity: .3;
}

.ds-input-form__valide .ds-input-form--field {
  border-bottom: 1px var(--vert_positif) solid !important;
}

.ds-input-form__valide .ds-input-form--field:focus, .ds-input-form__valide .ds-input-form--field:focus-visible, .ds-input-form__valide .ds-input-form--field:focus-within {
  border-bottom: 1px var(--bleu_texte) solid !important;
}

.ds-input-form__valide .ds-input-form--field:focus ~ .message-aide, .ds-input-form__valide .ds-input-form--field:focus-visible ~ .message-aide, .ds-input-form__valide .ds-input-form--field:focus-within ~ .message-aide {
  display: inherit;
}

.ds-input-form__valide .ds-input-form--field:focus ~ .message-erreur, .ds-input-form__valide .ds-input-form--field:focus-visible ~ .message-erreur, .ds-input-form__valide .ds-input-form--field:focus-within ~ .message-erreur {
  display: none !important;
}

.ds-input-form__valide .ds-input-form--field:focus ~ .dsicon-check, .ds-input-form__valide .ds-input-form--field:focus-visible ~ .dsicon-check, .ds-input-form__valide .ds-input-form--field:focus-within ~ .dsicon-check {
  visibility: hidden;
}

.ds-input-form__valide i {
  color: var(--vert_positif);
}

.ds-input-form__erreur .ds-input-form--field {
  border-bottom: 1px var(--rouge_negatif) solid !important;
}

.ds-input-form__erreur .ds-input-form--field:focus, .ds-input-form__erreur .ds-input-form--field:focus-visible, .ds-input-form__erreur .ds-input-form--field:focus-within {
  border-bottom: 1px var(--bleu_texte) solid;
}

.ds-input-form__erreur .ds-input-form--field:focus ~ .message-aide, .ds-input-form__erreur .ds-input-form--field:focus-visible ~ .message-aide, .ds-input-form__erreur .ds-input-form--field:focus-within ~ .message-aide {
  display: none;
}

.ds-input-form__erreur .ds-input-form--field:focus ~ .message-erreur, .ds-input-form__erreur .ds-input-form--field:focus-visible ~ .message-erreur, .ds-input-form__erreur .ds-input-form--field:focus-within ~ .message-erreur, .ds-input-form__erreur .message-erreur {
  display: inherit;
}

.ds-input-form__erreur i {
  color: var(--rouge_negatif);
}

.ds-input-datalist__suggestions {
  background-color: #fff;
  max-height: 50vh;
  margin-top: 1px;
  overflow: auto;
}

.ds-input-datalist__suggestion {
  cursor: pointer;
  outline: none;
  padding: 1rem;
}

.ds-input-datalist__suggestion:hover, .ds-input-datalist__suggestion--focused {
  background-color: #0000000d;
}

.ds-input-datalist__suggestion--selected, .ds-input-datalist__suggestion i {
  color: var(--vert_positif);
  font-style: normal;
}

.ds-input-datalist__suggestion-prefix {
  margin-right: .5em;
}

.ds-textarea-form {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.ds-textarea-form--field {
  border: 1px var(--gris_dark) solid;
  outline: none;
  width: 100%;
  padding: 16px;
  font-size: 18px;
}

.ds-textarea-form--field:disabled {
  background-color: #fff;
}

.ds-textarea-form--field:focus, .ds-textarea-form--field:focus-visible, .ds-textarea-form--field:focus-within {
  border: 1px var(--bleu_texte) solid;
}

.ds-textarea-form--field:focus ~ .message-aide, .ds-textarea-form--field:focus-visible ~ .message-aide, .ds-textarea-form--field:focus-within ~ .message-aide {
  display: inherit;
}

.ds-textarea-form--field:focus ~ .message-erreur, .ds-textarea-form--field:focus-visible ~ .message-erreur, .ds-textarea-form--field:focus-within ~ .message-erreur {
  display: none;
}

.ds-textarea-form .message-aide, .ds-textarea-form .message-erreur {
  color: var(--gris_dark);
  margin-left: 8px;
  font-size: 12px;
  display: none;
}

.ds-textarea-form__disabled textarea {
  opacity: .3;
}

.ds-textarea-form__valide textarea {
  border: 1px var(--vert_positif) solid !important;
}

.ds-textarea-form__valide textarea:focus, .ds-textarea-form__valide textarea:focus-visible, .ds-textarea-form__valide textarea:focus-within {
  border-bottom: 1px var(--bleu_texte) solid;
}

.ds-textarea-form__valide textarea:focus ~ .message-aide, .ds-textarea-form__valide textarea:focus-visible ~ .message-aide, .ds-textarea-form__valide textarea:focus-within ~ .message-aide {
  display: inherit;
}

.ds-textarea-form__valide textarea:focus ~ .message-erreur, .ds-textarea-form__valide textarea:focus-visible ~ .message-erreur, .ds-textarea-form__valide textarea:focus-within ~ .message-erreur {
  display: none;
}

.ds-textarea-form__erreur textarea {
  border: 1px var(--rouge_negatif) solid !important;
}

.ds-textarea-form__erreur textarea:focus, .ds-textarea-form__erreur textarea:focus-visible, .ds-textarea-form__erreur textarea:focus-within {
  border: 1px var(--bleu_texte) solid;
}

.ds-textarea-form__erreur textarea:focus ~ .message-aide, .ds-textarea-form__erreur textarea:focus-visible ~ .message-aide, .ds-textarea-form__erreur textarea:focus-within ~ .message-aide {
  display: none;
}

.ds-textarea-form__erreur textarea:focus ~ .message-erreur, .ds-textarea-form__erreur textarea:focus-visible ~ .message-erreur, .ds-textarea-form__erreur textarea:focus-within ~ .message-erreur, .ds-textarea-form__erreur .message-erreur {
  display: inherit;
}

.ds-select-input {
  display: block;
}

.ds-select-input button {
  box-shadow: none;
  appearance: none;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}

.ds-select-input button:disabled {
  cursor: initial;
}

.ds-select-input .ds-select__chevron-bas, .ds-select-input .ds-select__chevron-haut {
  position: absolute;
  top: 20px;
  bottom: 0;
  right: 11px;
}

.ds-select-input .ds-select__chevron-bas {
  display: inline;
}

.ds-select-input .ds-select__chevron-haut, .ds-select-input.ds-select--open i.ds-select__chevron-bas {
  display: none;
}

.ds-select-input.ds-select--open i.ds-select__chevron-haut {
  display: inline;
}

.ds-select-input .ds-select__options {
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  padding: 8px 0 16px;
  display: block;
  position: absolute;
  top: 58px;
  overflow: auto;
  box-shadow: 0 3px 6px #2222224d;
}

.ds-select-input .ds-select__options.ds-select__options--hidden {
  display: none;
}

.ds-select-input .ds-select__options .ds-select__option {
  color: var(--gris_dark);
  min-height: 57px;
  padding: 16px;
}

.ds-select-input .ds-select__options .ds-select__option.ds-select__option--active {
  background-color: var(--gris_background);
  color: var(--vert_positif);
}

.ds-select-input .ds-select__options .ds-select__option.ds-select__option--active .ds-select__option-active-indicator {
  float: right;
}

.ds-select-input .ds-select__options .ds-select__option.ds-select__option--active .ds-select__option-active-indicator .dsicon-check {
  display: inline;
  top: auto;
  bottom: auto;
}

.ds-select-input .ds-select__options .ds-select__option:hover {
  background-color: var(--gris_background);
  cursor: pointer;
}

.ds-select-input.ds-input--disabled button {
  color: var(--gris_light);
  cursor: initial;
}

.ds-select-input.ds-input--disabled i.ds-select__chevron-bas, .ds-select-input.ds-input--disabled i.ds-select__chevron-haut {
  display: none;
}

.ds-input-group .ds-select-input button {
  padding: 20px 15px 5px;
}

.ds-input-group-typeform .ds-select-input button {
  height: 32px;
  padding: 0 24px 4px 8px;
  font-size: 1.125rem;
  line-height: 1.5625rem;
}

.ds-input-group-typeform .ds-select-input .ds-select__chevron-haut, .ds-input-group-typeform .ds-select-input .ds-select__chevron-bas {
  top: 7px;
}

.ds-date3-input {
  display: block;
}

.ds-date3-input span {
  margin: 0 8px;
  display: inline-block;
}

.ds-date3-input .ds-input__fake-group {
  width: 2.25rem;
  display: inline-block;
}

.ds-date3-input .ds-input__fake-group input {
  text-align: center;
  padding: 0 0 4px;
}

.ds-date3-input .ds-input__fake-group:last-of-type {
  width: 4rem;
}

.ds-date3-input input:focus::placeholder {
  color: #0000;
}

.ds-quantity__label {
  margin-bottom: 8px;
  display: inline-block;
}

.ds-quantity-input {
  display: flex;
}

.ds-quantity-input .ds-quantity__button {
  border: 1px solid var(--corail_mh_brand);
  color: #fff;
  background-color: var(--corail_mh_brand);
  cursor: pointer;
  outline: none;
  height: 32px;
  padding: 0;
  line-height: 32px;
}

.ds-quantity-input .ds-quantity__button.ds-quantity__button--disabled {
  background-color: rgba(var(--corail_mh_brand), .3);
  border-color: #0000;
}

.ds-quantity-input .ds-quantity__moins {
  border-radius: 32px 0 0 32px;
  padding-left: 15px;
  padding-right: 10px;
}

.ds-quantity-input .ds-quantity__plus {
  border-radius: 0 32px 32px 0;
  padding-left: 10px;
  padding-right: 15px;
}

.ds-quantity-input .ds-quantity__input {
  text-align: center;
  color: #222;
  -moz-appearance: textfield;
  border: 1px solid #e1e1e1;
  border-left: 0;
  border-right: 0;
  width: 50px;
  height: 32px;
  line-height: 32px;
}

.ds-quantity-input .ds-quantity__input.ds-quantity--zero {
  color: #757575;
}

.ds-quantity-input .ds-quantity__input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ds-quantity-input .ds-quantity__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ds-file-input {
  display: block;
}

.ds-spinner--fullscreen.ds-spinner--shown {
  z-index: 9999;
  background: #fff9;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.ds-spinner--fullscreen.ds-spinner--shown .ds-spinner-container {
  box-sizing: content-box;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ds-spinner--fullscreen.ds-spinner--shown .ds-loader {
  font-size: 4rem;
}

.ds-spinner--fit-content.ds-spinner--shown {
  z-index: 99999;
  background: #fffc;
  position: absolute;
  inset: 0;
}

.ds-spinner--fit-content.ds-spinner--shown .ds-spinner-container {
  box-sizing: content-box;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ds-spinner--fit-content.ds-spinner--shown .ds-loader {
  font-size: 3.5rem;
}

.ds-card-selected, .ds-link-card:focus, a.ds-card.ds-card--is-selected, .ds-card.ds-card--is-selected {
  background: var(--bleu_turquoise_light);
  color: var(--bleu_dark) !important;
  border: 0 !important;
}

.ds-card-selected .check, .ds-link-card:focus .check, .ds-card.ds-card--is-selected .check {
  opacity: 1;
  transform: scale(1);
}

.ds-card-selected .info, .ds-link-card:focus .info, .ds-card.ds-card--is-selected .info {
  color: #fff !important;
}

.ds-card-selected .label, .ds-link-card:focus .label, .ds-card.ds-card--is-selected .label {
  font-weight: bold;
}

a.ds-card, .ds-card {
  color: var(--gris_dark);
  background: none;
  width: 134px;
  max-width: 100%;
  height: 134px;
  text-decoration: none;
  transition: box-shadow .2s, color .2s, background-color .2s, border-color .2s;
  position: relative;
}

a.ds-card:not(.grid-mode), .ds-card:not(.grid-mode) {
  margin-bottom: 24px;
  margin-right: 24px;
}

@media (width >= 64rem) {
  a.ds-card, .ds-card {
    height: 166px;
  }

  a.ds-card:not(.grid-mode), .ds-card:not(.grid-mode) {
    width: 214px;
    margin-bottom: 32px !important;
    margin-right: 32px !important;
  }
}

@media (width >= 80rem) {
  a.ds-card:not(.grid-mode), .ds-card:not(.grid-mode) {
    width: 174px;
  }
}

@media (width >= 90rem) {
  a.ds-card:not(.grid-mode), .ds-card:not(.grid-mode) {
    width: 200px;
  }
}

a.ds-card .info, .ds-card .info {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

a.ds-card .icone, .ds-card .icone {
  font-size: 2rem;
  text-decoration: none !important;
}

a.ds-card .label, .ds-card .label {
  letter-spacing: normal;
  text-transform: none;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  white-space: normal !important;
  word-wrap: break-word !important;
}

a.ds-card:hover, .ds-card:hover {
  box-shadow: 0 3px 6px #2222224d !important;
}

a.ds-card:hover, a.ds-card:focus, .ds-card:hover, .ds-card:focus {
  outline: none;
  color: var(--bleu_dark) !important;
}

a.ds-card:active, a.ds-card:focus:not(:focus-visible), .ds-card:active, .ds-card:focus:not(:focus-visible) {
  box-shadow: none;
}

a.ds-card .check, .ds-card .check {
  opacity: 0;
  color: #fff;
  transition: opacity .2s .2s, transform .2s .2s;
  position: absolute;
  top: 1rem;
  right: 1rem;
  transform: scale(0);
}

a.ds-card.grid-mode, .ds-card.grid-mode {
  width: 100%;
  margin-left: 0 !important;
}

a.ds-card:hover, .ds-card:hover {
  text-decoration: none !important;
}

footer {
  max-width: 90rem;
}

footer a {
  text-decoration: none;
}

.ds-footer_light {
  margin: 0 32px;
  padding: 25px 0;
}

@media (width <= 64rem) {
  .ds-footer_light {
    flex-direction: column;
    margin: 0 16px;
  }
}

.ds-footer_light a, .ds-footer_light span {
  color: var(--gris_dark);
  font-size: .75rem;
}

.ds-footer_light .menu a {
  text-decoration: none;
}

@media (width >= 64rem) {
  .ds-footer_light .menu a {
    margin-right: 25px;
  }
}

@media (width <= 64rem) {
  .ds-footer_light .menu a {
    margin-bottom: 8px;
  }

  .ds-footer_light .menu {
    flex-direction: column;
    margin-bottom: 32px;
  }
}

.ds-footer_dark {
  background: #636363;
  margin: 0;
  padding: 71px 32px;
}

@media (width <= 64rem) {
  .ds-footer_dark {
    flex-direction: column-reverse;
    padding: 32px 16px;
  }
}

.ds-footer_dark .menu {
  padding-top: 3px;
}

.ds-footer_dark .menu a {
  width: 33%;
  margin-bottom: 16px;
  display: inline-block;
}

@media (width <= 64rem) {
  .ds-footer_dark .menu a {
    width: 100%;
  }
}

.ds-footer_dark a, .ds-footer_dark span {
  font-size: 1rem;
  color: #fff !important;
}

.ds-footer_dark .content-side .social-network a {
  color: #fff;
}

.ds-footer_dark .content-side .social-network a:not(:last-child) {
  margin-right: 16px;
}

.ds-footer_dark .content-side .form-group {
  margin-top: 25px;
}

@media (width <= 64rem) {
  .ds-footer_dark .content-side .form-group {
    margin-bottom: 25px;
  }
}

.ds-footer_dark .content-side .form-group:hover button {
  background: var(--gris_light);
}

.ds-footer_dark .content-side .form-group input:focus ~ button {
  color: #fff;
  background: var(--corail_mh_brand) !important;
}

.ds-footer_dark .content-side .form-group button {
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  padding-top: 6px;
  position: absolute;
  top: 9px;
  right: 9px;
}

.ds-footer_dark .content-side .form-group .message-aide {
  color: #fff;
  display: block;
  position: relative;
}

.ds-dropdown {
  margin-top: 0;
  margin-bottom: 0;
}

.ds-dropdown .control-label {
  font-size: inherit;
}

.ds-dropdown .dropdown {
  box-shadow: none;
  appearance: none;
  background: var(--gris_background);
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  height: 55px;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 1.6;
  position: relative;
  padding: 0 !important;
}

.ds-dropdown .dropdown.opened {
  background: var(--gris_light);
}

.ds-dropdown .dropdown.opened ~ .control-label, .ds-dropdown .dropdown.opened ~ .dsicon-chevron-haut, .ds-dropdown .ds-select-input .dropdown.opened ~ .ds-select__chevron-haut, .ds-select-input .ds-dropdown .dropdown.opened ~ .ds-select__chevron-haut {
  color: var(--bleu_texte);
}

.ds-dropdown .dropdown .options {
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  max-height: 310px;
  padding: 8px 0;
  display: none;
  position: absolute;
  top: 50px;
  overflow: auto;
}

.ds-dropdown .dropdown .options .option {
  padding: 16px;
  position: relative;
}

.ds-dropdown .dropdown .options .option p {
  color: var(--gris_dark);
}

.ds-dropdown .dropdown .options .option:hover {
  background-color: var(--gris_background);
  cursor: pointer;
}

.ds-dropdown .dropdown .options .option:hover p {
  color: #222;
}

.ds-dropdown .dropdown .options .option.active p {
  color: var(--vert_positif);
}

.ds-dropdown .dropdown .options .option.active .dsicon-check {
  display: inline;
}

.ds-dropdown .dropdown .options .option .dsicon-check {
  display: none;
}

.ds-dropdown .dropdown .options .option:not(:last-child) .sub-libelle ~ .line {
  display: inherit;
}

.ds-dropdown .dropdown .options .option .line {
  background-color: var(--gris_light);
  height: 1px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}

.ds-dropdown .dropdown ~ .message-aide {
  display: inherit;
  color: var(--gris_dark);
  display: inherit;
  margin-top: 4px;
  margin-left: 8px;
  font-size: .75rem;
  position: absolute;
}

.ds-dropdown .dropdown ~ .message-erreur {
  color: var(--rouge_negatif);
  margin-left: 8px;
  font-size: .75rem;
  display: none;
  position: absolute;
}

.ds-dropdown .dropdown.erreur ~ .bar {
  border-bottom: 2px solid var(--rouge_negatif);
  display: block;
  position: relative;
}

.ds-dropdown .dropdown.erreur ~ .message-erreur {
  color: var(--rouge_negatif);
  display: inherit;
  margin-top: 4px;
  margin-left: 8px;
  font-size: .75rem;
  position: absolute;
}

.ds-dropdown .dropdown.erreur ~ .message-aide {
  display: none;
}

.ds-dropdown .dropdown.valide ~ .bar {
  border-bottom: 2px solid var(--vert_positif);
  display: block;
  position: relative;
}

.ds-dropdown .dropdown.valide ~ .message-erreur, .ds-dropdown .dropdown.valide ~ .message-aide {
  display: none;
}

.ds-dropdown .dropdown:focus {
  outline: none;
}

.ds-dropdown .dropdown:focus ~ i.dsicon-chevron-bas, .ds-dropdown .ds-select-input .dropdown:focus ~ i.ds-select__chevron-bas, .ds-select-input .ds-dropdown .dropdown:focus ~ i.ds-select__chevron-bas {
  display: none;
}

.ds-dropdown .dropdown:focus ~ i.dsicon-chevron-haut, .ds-dropdown .ds-select-input .dropdown:focus ~ i.ds-select__chevron-haut, .ds-select-input .ds-dropdown .dropdown:focus ~ i.ds-select__chevron-haut {
  display: inline;
}

.ds-dropdown .dropdown:focus ~ .bar:before {
  width: 100%;
  left: 0;
}

.ds-dropdown .dropdown-text {
  font-size: inherit;
  box-shadow: none;
  appearance: none;
  border-bottom: 1px dashed #222;
  width: auto;
  margin-left: 5px;
  padding: 0;
  position: relative;
}

.ds-dropdown .dropdown-text .dsicon-fleche-dropdown-texte {
  margin-left: 4px;
}

.ds-dropdown .dropdown-text.erreur {
  border-bottom: 1px dashed var(--rouge_negatif);
}

.ds-dropdown .dropdown-text.erreur label, .ds-dropdown .dropdown-text.erreur .dsicon-fleche-dropdown-texte {
  color: var(--rouge_negatif);
}

.ds-dropdown .dropdown-text.disabled {
  border-bottom: 1px dashed var(--gris_dark);
}

.ds-dropdown .dropdown-text.disabled label, .ds-dropdown .dropdown-text.disabled .dsicon-fleche-dropdown-texte {
  color: var(--gris_dark);
}

.ds-dropdown .dropdown-text .options {
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  width: 300px;
  max-height: 310px;
  padding: 8px 0;
  display: none;
  position: absolute;
  overflow: auto;
}

.ds-dropdown .dropdown-text .options.top-left, .ds-dropdown .dropdown-text .options.top-right {
  bottom: -10px;
}

.ds-dropdown .dropdown-text .options.top-left {
  right: 0;
}

.ds-dropdown .dropdown-text .options.top-right {
  left: 0;
}

.ds-dropdown .dropdown-text .options.bottom-left, .ds-dropdown .dropdown-text .options.bottom-right {
  top: 0;
}

.ds-dropdown .dropdown-text .options.bottom-left {
  right: 0;
}

.ds-dropdown .dropdown-text .options.bottom-right {
  left: 0;
}

.ds-dropdown .dropdown-text .options .option {
  padding: 16px;
  position: relative;
}

.ds-dropdown .dropdown-text .options .option:hover {
  background-color: var(--gris_background);
  cursor: pointer;
}

.ds-dropdown .dropdown-text .options .option:hover p {
  color: #222;
}

.ds-dropdown .dropdown-text .options .option.active p {
  color: var(--vert_positif);
}

.ds-dropdown .dropdown-text .options .option.active .dsicon-check {
  display: inline;
}

.ds-dropdown .dropdown-text .options .option p {
  color: var(--gris_dark);
}

.ds-dropdown .dropdown-text .options .option .dsicon-check {
  display: none;
}

.ds-dropdown .dropdown-text .options .option .sub-libelle ~ .line {
  display: inherit;
}

.ds-dropdown .message-erreur {
  color: var(--rouge_negatif);
  margin-left: 8px;
  font-size: .75rem;
}

.ds-dropdown .sub-libelle {
  color: var(--gris_dark);
}

.ds-dropdown .control-label {
  color: #222;
  width: calc(100% - 3.5rem);
  padding-left: 0 !important;
  left: 1rem !important;
}

.ds-dropdown .control-label.ds-placeholder {
  color: var(--gris_dark);
}

.ds-dropdown .control-value {
  position: absolute;
  bottom: .625rem;
  left: 1rem;
  right: 2.5rem;
}

.ds-dropdown .control-value ~ .control-label {
  color: var(--bleu_texte);
  font-size: .75rem;
  top: .5rem;
  left: .75rem;
}

.ds-dropdown .disabled {
  pointer-events: none;
  opacity: .3;
  background-color: #f6f6f64d !important;
}

.ds-dropdown__menu {
  background-color: #fff;
  min-width: 200px;
  max-height: 50vh;
  margin-top: 1px;
  overflow: auto;
}

.ds-dropdown__item {
  cursor: pointer;
  outline: none;
}

.ds-dropdown__item:hover {
  background-color: #0000000d;
}

.ds-dropdown__item--selected {
  color: var(--vert_positif);
  font-style: normal;
}

.ds-dropdown__item-content {
  padding: 1rem;
}

.ds-dropdown__item-separator {
  border-bottom: 1px solid var(--gris_light);
  margin: 0 1rem;
}

.ds-dropdown .ds-placeholder {
  color: var(--gris_dark);
}

.ds-dropdown .ds-placeholder.valide {
  color: var(--vert_positif);
}

.ds-dropdown .ds-placeholder.opened {
  color: var(--bleu_texte);
}

.ds-dropdown .ds-input-form__disabled .ds-placeholder, .ds-dropdown .ds-input-form__disabled .dsicon-fleche-dropdown-texte {
  opacity: .3;
}

.ds-dropdown-form {
  width: 300px;
  height: 56px;
}

.ds-dropdown-form .placeholder {
  color: var(--gris_dark);
}

.ds-dropdown-form .text-selected {
  color: #222;
}

.ds-dropdown-form .text {
  padding: 2px 0 2px 8px;
}

.ds-dropdown-form .options {
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  padding: 8px 0 16px;
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  overflow: auto;
  box-shadow: 0 3px 6px #2222224d;
}

.ds-dropdown-form .option {
  padding: 16px;
}

.ds-dropdown-form .option p {
  color: var(--gris_dark);
}

.ds-dropdown-form .option:hover {
  background-color: var(--gris_background);
  cursor: pointer;
}

.ds-dropdown-form .option:hover p {
  color: #222;
}

.ds-dropdown-form .option .selected {
  position: relative;
}

.ds-dropdown-form .option .selected i {
  color: var(--vert_positif);
  top: 50%;
  transform: translateY(-50%);
}

.ds-dropdown-form .option .selected p {
  color: var(--vert_positif);
}

.ds-dropdown-form i.dsicon-fleche-dropdown-texte {
  color: #222;
  top: 4px;
  right: 4px;
}

.ds-dropdown-form i.dsicon-fleche-dropdown-texte.rotate {
  transform: rotate(180deg);
}

.ds-dropdown-form .ds-placeholder {
  color: var(--gris_dark);
}

.ds-dropdown-form .ds-input-form__disabled .ds-placeholder, .ds-dropdown-form .ds-input-form__disabled .dsicon-fleche-dropdown-texte {
  opacity: .3;
}

.position-goutte, .ds-goutte-right, .ds-goutte-right-top, .ds-goutte-right-bottom, .ds-goutte-left, .ds-goutte-left-top, .ds-goutte-left-bottom, .ds-goutte-top, .ds-goutte-top-right, .ds-goutte-top-left, .ds-goutte-bottom, .ds-goutte-bottom-right, .ds-goutte-bottom-left {
  display: block;
  position: absolute;
}

.ds-goutte-bottom, .ds-goutte-bottom-right, .ds-goutte-bottom-left {
  bottom: -8px;
}

.ds-goutte-bottom:after, .ds-goutte-bottom-right:after, .ds-goutte-bottom-left:after {
  content: " ";
  display: block;
  -webkit-mask: url("goutte-bottom.6ed93747.svg") 50% / cover no-repeat;
  mask: url("goutte-bottom.6ed93747.svg") 50% / cover no-repeat;
}

.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-bottom-left.ds-goutte-inside {
  bottom: 0;
}

.ds-goutte-top, .ds-goutte-top-right, .ds-goutte-top-left {
  top: -8px;
}

.ds-goutte-top:after, .ds-goutte-top-right:after, .ds-goutte-top-left:after {
  content: " ";
  display: block;
  -webkit-mask: url("goutte-top.9c30f9a3.svg") 50% / cover no-repeat;
  mask: url("goutte-top.9c30f9a3.svg") 50% / cover no-repeat;
}

.ds-goutte-top.ds-goutte-inside, .ds-goutte-top-right.ds-goutte-inside, .ds-goutte-top-left.ds-goutte-inside {
  top: 0;
  transform: translateX(-50%)rotate(180deg);
}

.ds-goutte-left, .ds-goutte-left-top, .ds-goutte-left-bottom {
  left: -8px;
}

.ds-goutte-left:after, .ds-goutte-left-top:after, .ds-goutte-left-bottom:after {
  content: " ";
  display: block;
  -webkit-mask: url("goutte-left.6d688286.svg") 50% / cover no-repeat;
  mask: url("goutte-left.6d688286.svg") 50% / cover no-repeat;
}

.ds-goutte-left.ds-goutte-inside, .ds-goutte-left-top.ds-goutte-inside, .ds-goutte-left-bottom.ds-goutte-inside {
  left: 0;
}

.ds-goutte-right, .ds-goutte-right-top, .ds-goutte-right-bottom {
  right: -8px;
}

.ds-goutte-right:after, .ds-goutte-right-top:after, .ds-goutte-right-bottom:after {
  content: " ";
  display: block;
  -webkit-mask: url("goutte-right.0ebc80b6.svg") 50% / cover no-repeat;
  mask: url("goutte-right.0ebc80b6.svg") 50% / cover no-repeat;
}

.ds-goutte-right.ds-goutte-inside, .ds-goutte-right-top.ds-goutte-inside, .ds-goutte-right-bottom.ds-goutte-inside {
  right: 0;
}

.ds-goutte-bottom, .ds-goutte-top {
  width: 24px;
  height: 8px;
  left: 50%;
  transform: translateX(-50%);
}

.ds-goutte-bottom:after, .ds-goutte-top:after {
  width: 24px;
  height: 8px;
}

.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-top.ds-goutte-inside {
  transform: translateX(-50%)rotate(180deg);
}

.ds-goutte-bottom-right, .ds-goutte-top-right {
  width: 24px;
  height: 8px;
  right: 10%;
}

.ds-goutte-bottom-right:after, .ds-goutte-top-right:after {
  width: 24px;
  height: 8px;
}

.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-top-right.ds-goutte-inside {
  transform: rotate(180deg);
}

.ds-goutte-bottom-left, .ds-goutte-top-left {
  width: 24px;
  height: 8px;
  left: 10%;
}

.ds-goutte-bottom-left:after, .ds-goutte-top-left:after {
  width: 24px;
  height: 8px;
}

.ds-goutte-bottom-left.ds-goutte-inside, .ds-goutte-top-left.ds-goutte-inside {
  transform: rotate(180deg);
}

.ds-goutte-left, .ds-goutte-right {
  width: 8px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.ds-goutte-left:after, .ds-goutte-right:after {
  width: 8px;
  height: 24px;
}

.ds-goutte-left.ds-goutte-inside, .ds-goutte-right.ds-goutte-inside {
  transform: translateY(-50%)rotate(180deg);
}

.ds-goutte-left-top, .ds-goutte-right-top {
  width: 8px;
  height: 24px;
  top: 10%;
}

.ds-goutte-left-top:after, .ds-goutte-right-top:after {
  width: 8px;
  height: 24px;
}

.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-right-top.ds-goutte-inside {
  transform: rotate(180deg);
}

.ds-goutte-left-bottom, .ds-goutte-right-bottom {
  width: 8px;
  height: 24px;
  bottom: 10%;
}

.ds-goutte-left-bottom:after, .ds-goutte-right-bottom:after {
  width: 8px;
  height: 24px;
}

.ds-goutte-left-bottom.ds-goutte-inside, .ds-goutte-right-bottom.ds-goutte-inside {
  transform: rotate(180deg);
}

.ds-goutte-scale-2.ds-goutte-bottom, .ds-goutte-scale-2.ds-goutte-top {
  transform: translateX(-50%)scale(2) !important;
}

.ds-goutte-scale-2.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-top.ds-goutte-inside {
  transform: translateX(-50%)rotate(180deg)scale(2) !important;
}

.ds-goutte-scale-2.ds-goutte-left, .ds-goutte-scale-2.ds-goutte-right {
  transform: translateY(-50%)scale(2) !important;
}

.ds-goutte-scale-2.ds-goutte-left.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-right.ds-goutte-inside {
  transform: translateY(-50%)rotate(180deg)scale(2) !important;
}

.ds-goutte-scale-2.ds-goutte-bottom-right, .ds-goutte-scale-2.ds-goutte-bottom-left, .ds-goutte-scale-2.ds-goutte-top-right, .ds-goutte-scale-2.ds-goutte-top-left, .ds-goutte-scale-2.ds-goutte-left-top, .ds-goutte-scale-2.ds-goutte-left-bottom, .ds-goutte-scale-2.ds-goutte-right-top, .ds-goutte-scale-2.ds-goutte-right-bottom {
  transform: scale(2) !important;
}

.ds-goutte-scale-2.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-bottom-left.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-top-right.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-top-left.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-left-bottom.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-right-top.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-right-bottom.ds-goutte-inside {
  transform: rotate(180deg)scale(2) !important;
}

.ds-goutte-scale-2.ds-goutte-bottom, .ds-goutte-scale-2.ds-goutte-bottom-right, .ds-goutte-scale-2.ds-goutte-bottom-left {
  bottom: -12px !important;
}

.ds-goutte-scale-2.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-bottom-left.ds-goutte-inside {
  bottom: 4px !important;
}

.ds-goutte-scale-2.ds-goutte-top, .ds-goutte-scale-2.ds-goutte-top-right, .ds-goutte-scale-2.ds-goutte-top-left {
  top: -12px !important;
}

.ds-goutte-scale-2.ds-goutte-top.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-top-right.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-top-left.ds-goutte-inside {
  top: 4px !important;
}

.ds-goutte-scale-2.ds-goutte-left, .ds-goutte-scale-2.ds-goutte-left-top, .ds-goutte-scale-2.ds-goutte-left-bottom {
  left: -12px !important;
}

.ds-goutte-scale-2.ds-goutte-left.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-left-bottom.ds-goutte-inside {
  left: 4px !important;
}

.ds-goutte-scale-2.ds-goutte-right, .ds-goutte-scale-2.ds-goutte-right-top, .ds-goutte-scale-2.ds-goutte-right-bottom {
  right: -12px !important;
}

.ds-goutte-scale-2.ds-goutte-right.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-right-top.ds-goutte-inside, .ds-goutte-scale-2.ds-goutte-right-bottom.ds-goutte-inside {
  right: 4px !important;
}

.ds-goutte-scale-3.ds-goutte-bottom, .ds-goutte-scale-3.ds-goutte-top {
  transform: translateX(-50%)scale(3) !important;
}

.ds-goutte-scale-3.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-top.ds-goutte-inside {
  transform: translateX(-50%)rotate(180deg)scale(3) !important;
}

.ds-goutte-scale-3.ds-goutte-left, .ds-goutte-scale-3.ds-goutte-right {
  transform: translateY(-50%)scale(3) !important;
}

.ds-goutte-scale-3.ds-goutte-left.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-right.ds-goutte-inside {
  transform: translateY(-50%)rotate(180deg)scale(3) !important;
}

.ds-goutte-scale-3.ds-goutte-bottom-right, .ds-goutte-scale-3.ds-goutte-bottom-left, .ds-goutte-scale-3.ds-goutte-top-right, .ds-goutte-scale-3.ds-goutte-top-left, .ds-goutte-scale-3.ds-goutte-left-top, .ds-goutte-scale-3.ds-goutte-left-bottom, .ds-goutte-scale-3.ds-goutte-right-top, .ds-goutte-scale-3.ds-goutte-right-bottom {
  transform: scale(3) !important;
}

.ds-goutte-scale-3.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-bottom-left.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-top-right.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-top-left.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-left-bottom.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-right-top.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-right-bottom.ds-goutte-inside {
  transform: rotate(180deg)scale(3) !important;
}

.ds-goutte-scale-3.ds-goutte-bottom, .ds-goutte-scale-3.ds-goutte-bottom-right, .ds-goutte-scale-3.ds-goutte-bottom-left {
  bottom: -16px !important;
}

.ds-goutte-scale-3.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-bottom-left.ds-goutte-inside {
  bottom: 8px !important;
}

.ds-goutte-scale-3.ds-goutte-top, .ds-goutte-scale-3.ds-goutte-top-right, .ds-goutte-scale-3.ds-goutte-top-left {
  top: -16px !important;
}

.ds-goutte-scale-3.ds-goutte-top.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-top-right.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-top-left.ds-goutte-inside {
  top: 8px !important;
}

.ds-goutte-scale-3.ds-goutte-left, .ds-goutte-scale-3.ds-goutte-left-top, .ds-goutte-scale-3.ds-goutte-left-bottom {
  left: -16px !important;
}

.ds-goutte-scale-3.ds-goutte-left.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-left-bottom.ds-goutte-inside {
  left: 8px !important;
}

.ds-goutte-scale-3.ds-goutte-right, .ds-goutte-scale-3.ds-goutte-right-top, .ds-goutte-scale-3.ds-goutte-right-bottom {
  right: -16px !important;
}

.ds-goutte-scale-3.ds-goutte-right.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-right-top.ds-goutte-inside, .ds-goutte-scale-3.ds-goutte-right-bottom.ds-goutte-inside {
  right: 8px !important;
}

.ds-goutte-scale-4.ds-goutte-bottom, .ds-goutte-scale-4.ds-goutte-top {
  transform: translateX(-50%)scale(4) !important;
}

.ds-goutte-scale-4.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-top.ds-goutte-inside {
  transform: translateX(-50%)rotate(180deg)scale(4) !important;
}

.ds-goutte-scale-4.ds-goutte-left, .ds-goutte-scale-4.ds-goutte-right {
  transform: translateY(-50%)scale(4) !important;
}

.ds-goutte-scale-4.ds-goutte-left.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-right.ds-goutte-inside {
  transform: translateY(-50%)rotate(180deg)scale(4) !important;
}

.ds-goutte-scale-4.ds-goutte-bottom-right, .ds-goutte-scale-4.ds-goutte-bottom-left, .ds-goutte-scale-4.ds-goutte-top-right, .ds-goutte-scale-4.ds-goutte-top-left, .ds-goutte-scale-4.ds-goutte-left-top, .ds-goutte-scale-4.ds-goutte-left-bottom, .ds-goutte-scale-4.ds-goutte-right-top, .ds-goutte-scale-4.ds-goutte-right-bottom {
  transform: scale(4) !important;
}

.ds-goutte-scale-4.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-bottom-left.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-top-right.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-top-left.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-left-bottom.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-right-top.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-right-bottom.ds-goutte-inside {
  transform: rotate(180deg)scale(4) !important;
}

.ds-goutte-scale-4.ds-goutte-bottom, .ds-goutte-scale-4.ds-goutte-bottom-right, .ds-goutte-scale-4.ds-goutte-bottom-left {
  bottom: -20px !important;
}

.ds-goutte-scale-4.ds-goutte-bottom.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-bottom-right.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-bottom-left.ds-goutte-inside {
  bottom: 12px !important;
}

.ds-goutte-scale-4.ds-goutte-top, .ds-goutte-scale-4.ds-goutte-top-right, .ds-goutte-scale-4.ds-goutte-top-left {
  top: -20px !important;
}

.ds-goutte-scale-4.ds-goutte-top.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-top-right.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-top-left.ds-goutte-inside {
  top: 12px !important;
}

.ds-goutte-scale-4.ds-goutte-left, .ds-goutte-scale-4.ds-goutte-left-top, .ds-goutte-scale-4.ds-goutte-left-bottom {
  left: -20px !important;
}

.ds-goutte-scale-4.ds-goutte-left.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-left-top.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-left-bottom.ds-goutte-inside {
  left: 12px !important;
}

.ds-goutte-scale-4.ds-goutte-right, .ds-goutte-scale-4.ds-goutte-right-top, .ds-goutte-scale-4.ds-goutte-right-bottom {
  right: -20px !important;
}

.ds-goutte-scale-4.ds-goutte-right.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-right-top.ds-goutte-inside, .ds-goutte-scale-4.ds-goutte-right-bottom.ds-goutte-inside {
  right: 12px !important;
}

.ds-recherche {
  margin-top: 0;
  margin-bottom: 0;
}

.ds-recherche input {
  padding: 5px 49px 5px 16px;
}

.ds-recherche input:hover, .ds-recherche input:focus {
  cursor: pointer;
}

.ds-recherche input:focus ~ .bar {
  border-bottom: 1px solid var(--corail_mh_brand);
  display: block;
  position: relative;
}

.ds-recherche input:focus ~ .bar:before {
  background: var(--corail_mh_brand);
  transition: left .28s, width .28s;
}

.ds-recherche input:focus ~ i.dsicon-search {
  color: #fff;
  background-color: var(--corail_mh_brand);
}

.ds-recherche input:focus ~ i.dsicon-search:hover {
  background-color: var(--corail_mh_dark);
}

.ds-recherche i.dsicon-search {
  color: #222;
  background-color: var(--gris_background);
  cursor: pointer;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: auto;
  padding-top: 12px;
  padding-left: 12px;
  font-size: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;
}

.ds-recherche i.dsicon-search:hover {
  background-color: var(--gris_light);
}

.ds-recherche .control-label {
  font-size: inherit;
}

.ds-recherche .options {
  z-index: 99;
  background: #fff;
  border-radius: 5px;
  width: 100%;
  max-height: 310px;
  padding: 8px 0;
  display: none;
  position: absolute;
  top: 57px;
  overflow: auto;
}

.ds-recherche .options .option {
  padding: 16px;
  position: relative;
}

.ds-recherche .options .option p {
  color: var(--gris_dark);
}

.ds-recherche .options .option:hover {
  background-color: var(--gris_background);
  cursor: pointer;
}

.ds-recherche .options .option:hover p {
  color: #222;
}

.ds-recherche .options .option.active p {
  color: var(--vert_positif);
}

.ds-recherche .options .option.active .dsicon-check {
  display: inline;
}

.ds-recherche .options .option .dsicon-check {
  display: none;
}

.ds-recherche .options .option:not(:last-child) .sub-libelle ~ .line {
  display: inherit;
}

.ds-recherche .options .option .line {
  background-color: var(--gris_light);
  height: 1px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}

.ds-recherche ~ .message-aide {
  display: inherit;
  color: var(--gris_dark);
  display: inherit;
  margin-top: 4px;
  margin-left: 8px;
  font-size: .75rem;
  position: absolute;
}

.ds-recherche ~ .message-erreur {
  color: var(--rouge_negatif);
  margin-left: 8px;
  font-size: .75rem;
  display: none;
  position: absolute;
}

.ds-recherche .message-erreur {
  color: var(--rouge_negatif);
  margin-left: 8px;
  font-size: .75rem;
}

.ds-recherche .sub-libelle {
  color: var(--gris_dark);
}

.ds-recherche .control-label {
  color: #222;
  width: calc(100% - 3.5rem);
  padding-left: 0 !important;
  left: 1rem !important;
}

.ds-recherche .control-label.placeholder {
  color: var(--gris_dark);
}

.ds-recherche .disabled {
  pointer-events: none;
  opacity: .3;
  background-color: #f6f6f64d !important;
}

.ds-side-panel-left-200 {
  z-index: 1000;
  background: var(--blanc);
  width: 200px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -200px;
}

.ds-side-panel-left-200.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-200 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-200 {
  z-index: 1000;
  background: var(--blanc);
  width: 200px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -200px;
}

.ds-side-panel-right-200.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-200 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-200 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 200px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -200px;
  left: 0;
}

.ds-side-panel-bottom-200.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-200 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-200 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 200px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -200px;
  left: 0;
}

.ds-side-panel-top-200.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-200 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-240 {
  z-index: 1000;
  background: var(--blanc);
  width: 240px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -240px;
}

.ds-side-panel-left-240.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-240 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-240 {
  z-index: 1000;
  background: var(--blanc);
  width: 240px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -240px;
}

.ds-side-panel-right-240.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-240 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-240 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 240px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -240px;
  left: 0;
}

.ds-side-panel-bottom-240.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-240 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-240 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 240px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -240px;
  left: 0;
}

.ds-side-panel-top-240.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-240 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-280 {
  z-index: 1000;
  background: var(--blanc);
  width: 280px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -280px;
}

.ds-side-panel-left-280.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-280 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-280 {
  z-index: 1000;
  background: var(--blanc);
  width: 280px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -280px;
}

.ds-side-panel-right-280.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-280 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-280 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 280px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -280px;
  left: 0;
}

.ds-side-panel-bottom-280.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-280 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-280 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 280px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -280px;
  left: 0;
}

.ds-side-panel-top-280.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-280 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-320 {
  z-index: 1000;
  background: var(--blanc);
  width: 320px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -320px;
}

.ds-side-panel-left-320.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-320 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-320 {
  z-index: 1000;
  background: var(--blanc);
  width: 320px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -320px;
}

.ds-side-panel-right-320.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-320 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-320 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 320px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -320px;
  left: 0;
}

.ds-side-panel-bottom-320.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-320 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-320 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 320px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -320px;
  left: 0;
}

.ds-side-panel-top-320.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-320 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-360 {
  z-index: 1000;
  background: var(--blanc);
  width: 360px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -360px;
}

.ds-side-panel-left-360.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-360 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-360 {
  z-index: 1000;
  background: var(--blanc);
  width: 360px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -360px;
}

.ds-side-panel-right-360.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-360 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-360 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 360px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -360px;
  left: 0;
}

.ds-side-panel-bottom-360.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-360 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-360 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 360px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -360px;
  left: 0;
}

.ds-side-panel-top-360.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-360 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-400 {
  z-index: 1000;
  background: var(--blanc);
  width: 400px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -400px;
}

.ds-side-panel-left-400.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-400 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-400 {
  z-index: 1000;
  background: var(--blanc);
  width: 400px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -400px;
}

.ds-side-panel-right-400.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-400 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-400 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 400px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -400px;
  left: 0;
}

.ds-side-panel-bottom-400.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-400 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-400 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 400px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -400px;
  left: 0;
}

.ds-side-panel-top-400.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-400 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-520 {
  z-index: 1000;
  background: var(--blanc);
  width: 520px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -520px;
}

.ds-side-panel-left-520.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-520 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-520 {
  z-index: 1000;
  background: var(--blanc);
  width: 520px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -520px;
}

.ds-side-panel-right-520.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-520 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-520 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 520px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -520px;
  left: 0;
}

.ds-side-panel-bottom-520.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-520 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-520 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 520px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -520px;
  left: 0;
}

.ds-side-panel-top-520.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-520 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-600 {
  z-index: 1000;
  background: var(--blanc);
  width: 600px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -600px;
}

.ds-side-panel-left-600.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-600 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-600 {
  z-index: 1000;
  background: var(--blanc);
  width: 600px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -600px;
}

.ds-side-panel-right-600.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-600 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-600 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 600px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -600px;
  left: 0;
}

.ds-side-panel-bottom-600.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-600 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-600 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 600px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -600px;
  left: 0;
}

.ds-side-panel-top-600.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-600 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-704 {
  z-index: 1000;
  background: var(--blanc);
  width: 704px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -704px;
}

.ds-side-panel-left-704.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-704 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-704 {
  z-index: 1000;
  background: var(--blanc);
  width: 704px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -704px;
}

.ds-side-panel-right-704.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-704 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-704 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 704px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -704px;
  left: 0;
}

.ds-side-panel-bottom-704.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-704 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-704 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 704px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -704px;
  left: 0;
}

.ds-side-panel-top-704.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-704 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-left-800 {
  z-index: 1000;
  background: var(--blanc);
  width: 800px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  left: -800px;
}

.ds-side-panel-left-800.ds-side-panel-opened {
  left: 0;
}

.ds-side-panel-left-800 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-right-800 {
  z-index: 1000;
  background: var(--blanc);
  width: 800px;
  height: 100vh;
  transition: left .3s ease-in-out, right .3s ease-in-out;
  position: fixed;
  top: 0;
  right: -800px;
}

.ds-side-panel-right-800.ds-side-panel-opened {
  right: 0;
}

.ds-side-panel-right-800 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-bottom-800 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 800px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  bottom: -800px;
  left: 0;
}

.ds-side-panel-bottom-800.ds-side-panel-opened {
  bottom: 0;
}

.ds-side-panel-bottom-800 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.ds-side-panel-top-800 {
  z-index: 1000;
  background: var(--blanc);
  width: 100%;
  height: 800px;
  transition: top .3s ease-in-out, bottom .3s ease-in-out;
  position: fixed;
  top: -800px;
  left: 0;
}

.ds-side-panel-top-800.ds-side-panel-opened {
  top: 0;
}

.ds-side-panel-top-800 > .dsicon-close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}

.nav-container-mask {
  z-index: 900;
  opacity: 1;
  background: #22222280;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

@media (width <= 64rem) {
  .nav-container-mask {
    display: none !important;
  }
}

.nav-container-mask.closed {
  opacity: 0;
  z-index: -1;
}

.tabs3 {
  padding-bottom: 1rem;
  overflow: auto hidden;
}

@media (width >= 64rem) {
  .tabs3 {
    flex-wrap: wrap;
  }
}

.tabs3 .tab3 {
  margin: 0 .25rem;
}

@media (width >= 64rem) {
  .tabs3 .tab3 {
    margin: 0 .5rem;
  }
}

.tabs3 .tab3 button {
  text-align: center;
  white-space: nowrap;
  color: var(--gris_dark);
  border: none;
  border-radius: 2rem;
  height: 2rem;
  padding: .375rem 1rem;
  font-size: .875rem;
  display: inline-block;
}

@media (width >= 64rem) {
  .tabs3 .tab3 button {
    padding: .375rem 1.5rem;
    font-size: 1rem;
  }
}

.tabs3 .tab3 button:not(.selected) {
  background-color: #fff;
  box-shadow: 0 3px 6px #2222224d;
}

.tabs3 .tab3 button:not(.selected):not(:disabled):not(.disabled):hover {
  color: var(--corail_mh_dark);
  transition: all .3s;
  outline: none !important;
}

.tabs3 .tab3 button.selected {
  background: var(--corail_mh_brand);
  color: #fff;
}

.tabs3 .tab3 button.disabled {
  opacity: .3;
  cursor: default;
}

.tabs3 .tab3 button:focus {
  outline: none;
}

.tabs3 .tab3 button > i {
  vertical-align: middle;
  margin-right: .5rem;
}

.tabs3 .tab3 svg {
  fill: var(--corail_mh_brand);
  width: 1.5rem;
  height: .5rem;
  margin-top: -1rem;
}

.tabs3 .tab3 .bottom-wave {
  height: .5rem;
}

.modal-content {
  border: none;
  border-radius: 8px;
  box-shadow: 0 3px 6px #2223;
}

.modal-header {
  border-bottom: none;
  padding: 1.5rem 1.5rem 1rem;
}

.modal-body {
  padding: 0 1.5rem 1rem;
}

.tooltip-inner {
  color: #222;
  background: #fff;
  width: 200px;
  padding: 1rem;
  font-size: 12px;
  line-height: 19px;
  box-shadow: 0 3px 6px #2223;
}

.tooltip .arrow:before {
  border-top-color: #fff;
}

.tooltip.show {
  opacity: 1;
}

.ds-tooltip {
  pointer-events: none;
  z-index: 1080;
  border-radius: .125em;
  max-width: 18.2em;
  padding: 1.7em;
  display: none;
  position: absolute;
}

.ds-tooltip .ds-tooltip-text {
  color: #222;
  white-space: pre-line;
}

.ds-tooltip-arrow {
  width: 0;
  height: 0;
  position: absolute;
}

.ds-tooltip-right .ds-tooltip-arrow {
  margin-top: -5px;
  top: 50%;
  left: 5px;
  transform: rotate(90deg);
}

.ds-tooltip-left .ds-tooltip-arrow {
  margin-top: 5px;
  top: 50%;
  right: 5px;
  transform: rotate(-90deg);
}

.ds-tooltip-top .ds-tooltip-arrow {
  margin-left: -5px;
  bottom: 5px;
  left: 50%;
}

.ds-tooltip-bottom .ds-tooltip-arrow {
  margin-left: 5px;
  top: 5px;
  left: 50%;
  transform: rotate(180deg);
}

.ds-message {
  opacity: 1;
  min-height: 70px;
  margin-bottom: 15px;
  padding: 24px;
  position: relative;
}

.ds-message .dsicon-close {
  cursor: pointer;
}

.ds-main {
  max-width: 90rem !important;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (width >= 20rem) {
  .container {
    max-width: 18rem;
  }
}

@media (width >= 64rem) {
  .container {
    max-width: 62rem;
  }
}

@media (width >= 80rem) {
  .container {
    max-width: 78rem;
  }
}

@media (width >= 90rem) {
  .container {
    max-width: 90rem;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

@media (width >= 20rem) {
  .container, .container-sm {
    max-width: 18rem;
  }
}

@media (width >= 64rem) {
  .container, .container-sm, .container-md {
    max-width: 62rem;
  }
}

@media (width >= 80rem) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 78rem;
  }
}

@media (width >= 90rem) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 90rem;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-1 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-2 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-3 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-3 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-4 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-4 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-5 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-5 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-6 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-6 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-7 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-7 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-8 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-9 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-9 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-10 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-10 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-11 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-11 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-12 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col, .col-auto {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col, .col-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100% / 1;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 100% / 2;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 100% / 3;
  max-width: 33.3333%;
}

.row-cols-4 > * {
  flex: 0 0 100% / 4;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 100% / 5;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 100% / 6;
  max-width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.col-sm-1 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-1 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-2 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-3 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-3 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-4 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-4 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-5 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-5 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-6 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-6 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-7 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-7 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-8 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-9 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-9 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-10 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-10 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-11 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-11 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm-12 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-sm, .col-sm-auto {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-sm, .col-sm-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (width >= 20rem) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100% / 1;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 100% / 2;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 100% / 3;
    max-width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 100% / 4;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 100% / 5;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 100% / 6;
    max-width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

.col-md-1 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-1 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-2 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-3 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-3 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-4 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-4 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-5 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-5 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-6 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-6 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-7 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-7 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-8 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-9 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-9 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-10 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-10 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-11 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-11 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md-12 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-md, .col-md-auto {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-md, .col-md-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (width >= 64rem) {
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100% / 1;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 100% / 2;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 100% / 3;
    max-width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 100% / 4;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 100% / 5;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 100% / 6;
    max-width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-md-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-md-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

.col-lg-1 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-1 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-2 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-3 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-3 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-4 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-4 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-5 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-5 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-6 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-6 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-7 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-7 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-8 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-9 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-9 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-10 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-10 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-11 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-11 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg-12 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-lg, .col-lg-auto {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-lg, .col-lg-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (width >= 80rem) {
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100% / 1;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 100% / 2;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 100% / 3;
    max-width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 100% / 4;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 100% / 5;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 100% / 6;
    max-width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

.col-xl-1 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-1 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-2 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-3 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-3 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-4 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-4 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-5 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-5 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-6 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-6 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-7 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-7 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-8 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-8 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-9 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-9 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-10 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-10 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-11 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-11 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl-12 {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

@media screen and (width <= 20rem) {
  .col-xl, .col-xl-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (width >= 90rem) {
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100% / 1;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 100% / 2;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 100% / 3;
    max-width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 100% / 4;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 100% / 5;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 100% / 6;
    max-width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

html, body, #wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

img {
  max-width: 100%;
}

:focus {
  outline: 1px dashed var(--corail_mh_brand);
  outline-offset: 0;
}

:focus:not(:focus-visible), :focus[tabindex="-1"] {
  outline: none;
}

.unstyled-button, .link-button {
  background: none;
  border: none;
  padding: 0;
}

.unstyled-button:focus, .link-button:focus {
  outline: 1px dashed var(--corail_mh_brand);
  outline-offset: 0;
}

.unstyled-button:focus:not(:focus-visible), .link-button:focus:not(:focus-visible) {
  outline: none;
}

.link-button {
  text-decoration: underline;
}

.link-button:hover {
  text-decoration: none;
}

@media (width >= 64rem) {
  .w-md-75 {
    width: 75%;
  }
}

.ds-message {
  opacity: 1;
  min-height: 70px;
  margin-bottom: 15px;
  padding: 24px;
  position: relative;
  box-shadow: 0 3px 6px #2222224d;
}

.ds-message.message-info {
  background-color: var(--alerte_bleu_information);
}

.ds-message.message-info_secondaire {
  background-color: var(--gris_background);
}

.ds-message.message-alerte {
  background-color: var(--alerte_jaune_attention);
}

.ds-message.message-erreur {
  background-color: var(--alerte_rouge_negatif);
}

.ds-message.message-succes {
  background-color: var(--alerte_vert_positif);
}

.ds-message .dsicon-cercle-check, .ds-message .ds-alert.ds-alert--valid .ds-alert__content-info .ds-alert__icon, .ds-alert.ds-alert--valid .ds-alert__content-info .ds-message .ds-alert__icon {
  font-size: 1.5rem;
}

.ds-message p, .ds-message i {
  color: #222;
}

.ds-message .dsicon-close {
  cursor: pointer;
  margin: 0 0 0 50px;
  display: block;
  position: absolute;
  top: 24px;
  right: 24px;
}

.ds-message .dsicon-cercle-information, .ds-message .dsicon-interrogation, .ds-message .dsicon-cercle-check, .ds-message .ds-alert.ds-alert--valid .ds-alert__content-info .ds-alert__icon, .ds-alert.ds-alert--valid .ds-alert__content-info .ds-message .ds-alert__icon, .ds-message .dsicon-warning, .ds-message .ds-alert.ds-alert--error .ds-alert__content-info .ds-alert__icon, .ds-alert.ds-alert--error .ds-alert__content-info .ds-message .ds-alert__icon {
  margin-right: 24px;
}

.steps {
  position: relative;
}

.steps ul {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.steps ul .step-item {
  outline: none;
  width: 100%;
  display: flex;
  position: relative;
}

.steps ul .step-item:before {
  content: "";
  background-color: var(--gris_light);
  width: 4px;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 18px;
}

.steps ul .step-item .step-subTitle {
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
}

.steps ul .step-item .step-content {
  color: #1a283e;
  text-align: center;
  object-fit: contain;
  z-index: 2;
  background-color: #5ad5d9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.125rem;
  font-weight: bolder;
  position: relative;
  box-shadow: 0 0 2px 2px #fff;
}

.steps ul .step-item .step-content .step-image {
  object-fit: contain;
  width: 40px;
  height: 40px;
}

.steps ul .step-item .step-content.current {
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
}

.steps ul .step-item .step-content.current .step-content {
  color: #000;
  background-color: #26a3a7;
  font-family: poppins-bold, Arial, Helvetica, sans-serif;
}

.steps ul .step-item .step-content.disabled {
  color: #6588bf;
  background-color: #adeaec;
}

.steps ul .step-item .step-content.disabled .step-image {
  opacity: .6;
}

.steps ul .step-item .step-number {
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
  line-height: 2;
}

.steps ul .step-item .subTitle {
  position: absolute;
}

.steps ul .step-item:last-child:before {
  display: none;
}

.hp-main {
  width: calc(100% - 2rem);
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 64rem) {
  .hp-main {
    width: calc(100% - 29rem);
  }
}

@media (width >= 80rem) {
  .hp-main {
    max-width: 1008px;
  }
}

@media (width >= 90rem) {
  .hp-main {
    max-width: 1141px;
  }
}

.btn-primaire-inverse-h40, .btn-primaire-inverse-h40:hover, .btn-primaire-inverse-h40:focus, .btn-primaire-h40, .btn-primaire-h40:hover, .btn-primaire-h40:focus, .btn-secondaire-h40, .btn-secondaire-h40:hover, .btn-secondaire-h40:focus, .btn-secondaire-noir-h40, .btn-secondaire-noir-h40:hover, .btn-secondaire-noir-h40:focus {
  text-decoration: none;
}

.btn-primaire-inverse-h40:focus:focus, .btn-primaire-h40:focus:focus, .btn-secondaire-h40:focus:focus, .btn-secondaire-noir-h40:focus:focus {
  outline: 1px dashed var(--corail_mh_brand);
  outline-offset: .25rem;
}

.btn-primaire-inverse-h40:focus:focus:not(:focus-visible), .btn-primaire-h40:focus:focus:not(:focus-visible), .btn-secondaire-h40:focus:focus:not(:focus-visible), .btn-secondaire-noir-h40:focus:focus:not(:focus-visible) {
  outline: none;
}

a.btn-primaire-h40:hover, a.btn-primaire-h40:focus, a.btn-primaire-h56:hover, a.btn-primaire-h56:focus {
  color: #fff !important;
}

@media (width >= 64rem) {
  .w-md-auto {
    width: auto !important;
  }
}

.env-switch__wrapper {
  position: relative;
}

.env-switch__button {
  border-bottom: 1px dashed #222;
}

.env-switch__button:hover, .env-switch__button:focus {
  border-color: var(--corail_mh_brand);
  color: var(--corail_mh_brand);
}

.env-switch__list {
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
}

@media (width >= 64rem) {
  .env-switch__list {
    left: auto;
    right: 0;
  }
}

.footer_style {
  background-color: #0000 !important;
}

#main-content {
  background-image: linear-gradient(19deg, #fff 44%, #ffe5d9 93%);
}

.subtitle {
  max-width: 480px;
}

@media (width <= 63.98rem) {
  .subtitle {
    max-width: 350px;
    margin: auto;
  }
}

.epargne-slider {
  position: relative;
}

.epargne-slider .tns-ovh {
  padding: .25rem 0;
}

.epargne-slider > .controls {
  display: flex;
}

.epargne-slider > .controls > .prev, .epargne-slider > .controls > .next {
  z-index: 2;
  align-items: center;
  transition: color .2s, transform .2s, opacity .2s .2s;
  display: flex;
}

.epargne-slider > .controls > .prev:hover, .epargne-slider > .controls > .prev:focus, .epargne-slider > .controls > .next:hover, .epargne-slider > .controls > .next:focus {
  color: var(--corail_mh_brand);
  transform: scale(1.1);
}

.epargne-slider > .controls > .prev {
  justify-content: flex-end;
  margin: 32px 48px 32px auto;
  left: -3rem;
}

.epargne-slider > .controls > .next {
  margin: 32px auto 32px 48px;
  right: -3rem;
}

.epargne-slider .slide {
  padding: 0 1rem;
}

.epargne-slider .slider-card {
  background-color: #fff;
  overflow: hidden;
}

.epargne-slider .slider-card .thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 63%;
  margin: 0 auto;
  padding-top: 50.85%;
}

.epargne-slider .slider-card:focus, .epargne-slider .slider-card:hover, .epargne-slider .slider-card:focus-within {
  box-shadow: 0 2px 4px #2222224d !important;
}

.footer-img {
  margin: 0 72px 0 92px;
  line-height: 0;
}

@media (width <= 63.98rem) {
  .footer-img {
    margin: 0;
  }
}

.slide-indicators {
  margin: -47px 0 0;
  position: relative;
  bottom: 1px;
}

.slide-indicators span {
  text-indent: 0;
  background: var(--gris_dark);
  opacity: 1;
  transition: var(--gris_dark) .4s;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.slide-indicators span.active {
  background: var(--corail_mh_brand);
  transition: var(--gris_dark) .2s;
  border-radius: 4px;
  width: 30px;
  height: 8px;
}

@media (width >= 64rem) {
  .espace-action {
    display: flex;
    text-align: left !important;
  }
}

.espace-action > .thumb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 214px;
}

@media (width >= 64rem) {
  .espace-action > .thumb {
    flex: 0 0 25%;
    align-self: stretch;
    height: auto;
    margin: 0 4rem 0 0;
  }
}

.espace-action > .content {
  flex: 0 0 50%;
  padding: 5rem 0;
}

@media (width >= 64rem) {
  .espace-action:nth-of-type(2n) {
    justify-content: flex-end;
    text-align: right !important;
  }

  .espace-action:nth-of-type(2n) > .thumb {
    order: 2;
    margin: 0 0 0 4rem;
  }
}

.ppa-footer {
  margin: 2rem 1.5rem;
  display: block;
}

.ppa-footer__container {
  border-top: 1px solid var(--gris_light);
  padding: 1.5rem 0;
}

.ppa-footer__links {
  margin-bottom: 2rem;
}

.ppa-footer__links__item {
  line-height: 17px;
}

.ppa-footer__links__item__link {
  cursor: pointer;
  font-size: 12px;
  line-height: 17px;
  color: var(--gris_dark) !important;
  text-decoration: underline !important;
}

.ppa-footer__links__item__link:hover {
  color: var(--corail_mh_brand);
}

.ppa-footer__links__item + .ppa-footer__links__item {
  margin-top: .5rem;
}

.ppa-footer__trademark {
  color: var(--gris_dark);
  font-size: 12px;
  line-height: 17px;
}

.ppa-footer__trademark__website {
  cursor: pointer;
  font-size: 12px;
  line-height: 17px;
  color: var(--gris_dark) !important;
  text-decoration: underline !important;
}

.ppa-footer__trademark__website:hover {
  color: var(--corail_mh_brand);
}

.ppa-footer__accessibility {
  margin-top: 2rem;
}

.ppa-footer__accessibility__service {
  cursor: pointer;
  align-items: center;
  font-size: 12px;
  line-height: 17px;
  text-decoration: none;
  display: flex;
  color: var(--gris_dark) !important;
  text-decoration: underline !important;
}

.ppa-footer__accessibility__service:hover {
  color: var(--corail_mh_brand);
}

.ppa-footer__accessibility__service__logo {
  flex: 0 0 25%;
  max-width: 120px;
  margin-right: 1rem;
}

.ppa-footer__accessibility__service__logo img {
  width: auto;
  max-width: 100%;
}

.ppa-footer__accessibility__service + .ppa-footer__accessibility__service {
  margin-top: 1rem;
}

@media (width >= 64rem) {
  .ppa-footer {
    margin-bottom: 0;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .ppa-footer__container {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    max-width: 88rem !important;
  }

  .ppa-footer__links {
    display: flex;
  }

  .ppa-footer__links__item + .ppa-footer__links__item {
    margin-top: 0;
    margin-left: 2rem;
  }

  .ppa-footer__accessibility {
    flex: 1 0 100%;
    justify-content: space-between;
    display: flex;
  }

  .ppa-footer__accessibility__service {
    flex: 0 0 calc(50% - 4rem);
    max-width: 460px;
  }

  .ppa-footer__accessibility__service__logo {
    flex-basis: 120px;
    margin-right: 2rem;
  }

  .ppa-footer__accessibility__service + .ppa-footer__accessibility__service {
    margin-top: 0;
  }

  .footer_style {
    background-color: var(--gris_background);
    display: flow-root;
  }
}

.border-round {
  border-radius: 0 0 100% 100% / 0 0 72px 72px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
}

@media (width >= 64rem) {
  .border-round {
    border-radius: 0 0 100% 100% / 0 0 92px 92px;
  }
}

@media (width >= 80rem) {
  .border-round {
    border-radius: 0 0 100% 100% / 0 0 102px 102px;
  }
}

.intro-return {
  padding-bottom: 37vw;
  position: relative;
}

@media (width >= 64rem) {
  .intro-return {
    padding-bottom: 0;
  }
}

.intro-return .thumbnail {
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  height: 37vw;
  position: absolute;
  bottom: 0;
  left: 2rem;
  right: 2rem;
}

@media (width >= 64rem) {
  .intro-return .thumbnail {
    background-position: 100%;
    background-size: contain;
    flex: 0 0 calc(50% - 1.5rem);
    height: auto;
    margin-right: 3rem;
    position: static;
  }
}

.intro-return .anchor {
  text-decoration: underline;
}

.intro-return .anchor:hover, .intro-return .anchor:focus {
  color: var(--corail_mh_brand) !important;
}

.intro-return.no-mobile-thumb {
  padding-bottom: 0;
}

.intro-return-bg {
  background-image: linear-gradient(242deg, #b5fdff, #5ad5d9 100%);
}

.desktop-banner-img {
  width: 543px;
  height: 585px;
  margin-right: 8%;
}

@media (width >= 90rem) {
  .desktop-banner-img {
    width: 24%;
    height: 596px;
    margin-right: 24%;
  }
}

.desktop-banner-img img {
  width: 543px;
  max-width: none;
  height: 585px;
}

@media (width >= 90rem) {
  .desktop-banner-img img {
    height: 596px;
  }
}

.mobile-banner-img {
  object-fit: contain;
  width: 320px;
  height: 292px;
  margin: auto;
}

a, button {
  cursor: pointer;
}

.new-account {
  text-decoration: underline;
}

.banner-chevron {
  margin: -32px 0 10px;
}

.app-logo {
  width: 91px;
  height: 24px;
}

@media (width >= 64rem) {
  .app-logo {
    width: 152px;
    height: 39px;
  }
}

#skiplink {
  z-index: 999;
  background: var(--corail_mh_brand);
  color: #fff;
  white-space: nowrap;
  border-radius: 0 0 4px 4px;
  padding: .5rem 1rem;
  transition: top .2s;
  display: inline-block;
  position: fixed;
  top: -3rem;
  left: 50%;
  transform: translateX(-50%);
}

#skiplink:focus {
  text-decoration: none;
  top: 0;
}

.header {
  z-index: 100;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (width <= 63.98rem) {
  .header {
    width: 100vw;
  }
}

body {
  padding-top: 56px;
}

@media (width >= 64rem) {
  body {
    padding-top: 87px;
  }
}

.menu-navbar {
  justify-content: flex-end;
  height: 100%;
  display: flex;
}

.menu-navbar .open-menu-link {
  text-decoration: none;
}

.menu-navbar__mobile-toggle {
  align-items: center;
  display: flex;
}

.menu-navbar__mobile-toggle__label {
  text-align: right;
  margin-right: .5rem;
  font-size: 12px;
  line-height: 17px;
  transition: transform .2s;
  transform: translateY(-100%);
}

.menu-navbar__mobile-toggle__label__on {
  opacity: 0;
  transition: opacity .2s;
}

.menu-navbar__mobile-toggle__label__off {
  opacity: 1;
  width: 100%;
  transition: opacity .2s;
  position: absolute;
}

.menu-navbar__mobile-toggle__label.is-on {
  transform: translateY(0);
}

.menu-navbar__mobile-toggle__label.is-on .menu-navbar__mobile-toggle__label__on {
  opacity: 1;
  transition: opacity .2s;
}

.menu-navbar__mobile-toggle__label.is-on .menu-navbar__mobile-toggle__label__off {
  opacity: 0;
  transition: opacity .2s;
}

.menu-navbar__mobile-toggle__burger {
  position: relative;
}

.menu-navbar__mobile-toggle__burger, .menu-navbar__mobile-toggle__burger:before, .menu-navbar__mobile-toggle__burger:after {
  background: #222;
  border-radius: 2px;
  width: 16px;
  height: 2px;
  transition: transform .2s, background-color .2s;
}

.menu-navbar__mobile-toggle__burger:before, .menu-navbar__mobile-toggle__burger:after {
  content: "";
  display: block;
  position: absolute;
}

.menu-navbar__mobile-toggle__burger:before {
  transform: translateY(-5px);
}

.menu-navbar__mobile-toggle__burger:after {
  transform: translateY(5px);
}

.menu-navbar__mobile-toggle__burger.is-on {
  background: none;
}

.menu-navbar__mobile-toggle__burger.is-on:before {
  transform: rotate(-45deg);
}

.menu-navbar__mobile-toggle__burger.is-on:after {
  transform: rotate(45deg);
}

.menu-navbar__container {
  opacity: 0;
  transform-origin: top;
  background: #fff;
  transition: opacity .2s, transform .2s ease-out;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  transform: scaleY(0);
  box-shadow: 0 2px 1px #2222224d;
}

.menu-navbar__container.is-visible {
  opacity: 1;
  transform: scaleY(1);
}

.menu-navbar__top-level__link {
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  text-decoration: none;
  transition: color .2s;
  position: relative;
}

.menu-navbar__top-level__link > .dsicon-chevron-bas, .ds-select-input .menu-navbar__top-level__link > .ds-select__chevron-bas {
  opacity: 0;
  font-size: .8rem;
  transition: opacity .2s;
  display: none;
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 64rem) {
  .menu-navbar__top-level__link > .dsicon-chevron-bas, .ds-select-input .menu-navbar__top-level__link > .ds-select__chevron-bas {
    display: block;
  }
}

.menu-navbar__top-level__link:hover {
  text-decoration: none;
}

.menu-navbar__top-level__link--corail, .menu-navbar__top-level__link.is-active {
  color: var(--corail_mh_brand);
}

.menu-navbar__top-level__link.is-active > .dsicon-chevron-bas, .ds-select-input .menu-navbar__top-level__link.is-active > .ds-select__chevron-bas {
  opacity: 1;
}

.menu-navbar__top-level__icon {
  text-align: left;
  width: 1.5rem;
}

.menu-navbar__top-level__chevron {
  margin-left: auto;
  transition: transform .2s;
}

.menu-navbar__top-level__chevron.is-rotated {
  transform: rotate(180deg);
}

.menu-navbar__top-level.is-corail .menu-navbar__top-level__link {
  color: var(--corail_mh_brand);
}

@media (width >= 64rem) {
  .menu-navbar__mobile-toggle {
    display: none;
  }

  .menu-navbar__container {
    box-shadow: none;
    opacity: 1;
    background: none;
    width: 100%;
    transition: none;
    display: flex;
    position: static;
    transform: none !important;
  }

  .menu-navbar__top-level {
    border-top: none;
    margin: 0 1.5rem 0 0;
  }

  .menu-navbar__top-level__link {
    padding: .5rem 0;
    position: relative;
  }

  .menu-navbar__top-level__link:hover, .menu-navbar__top-level__link:focus {
    color: var(--corail_mh_brand);
  }

  .menu-navbar__top-level__chevron {
    display: none;
  }

  .menu-navbar__top-level.is-corail {
    margin-left: auto;
  }

  .menu-navbar__top-level.is-corail .menu-navbar__top-level__link:hover, .menu-navbar__top-level.is-corail .menu-navbar__top-level__link:focus {
    color: var(--corail_mh_dark);
  }

  .menu-navbar__top-level.is-corail ~ .menu-navbar__top-level.is-corail {
    margin-left: 0;
  }

  .menu-navbar__top-level:last-child {
    margin-right: 0;
  }
}

.popover__wrapper {
  display: inline-block;
  position: relative;
}

.popover__title {
  color: #e44444;
  text-align: center;
  font-size: 16px;
  line-height: 36px;
  text-decoration: none;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  border-radius: 8px;
  width: auto;
  padding: 1.5rem;
  position: absolute;
  left: -56px;
  transform: translate(0, 10px);
  box-shadow: 0 0 17px #00000042;
}

.popover__content:before {
  z-index: -1;
  content: "";
  border: 10px solid #0000;
  border-top-width: 0;
  border-bottom-color: #fff;
  transition-property: transform;
  transition-duration: .3s;
  position: absolute;
  top: -8px;
  left: calc(78% - 10px);
}

.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: all .5s cubic-bezier(.75, -.02, .2, .97);
  transform: translate(0, -6px);
}

.menu a {
  font-size: 14px;
}

.mobile__menu {
  display: none;
}

.list__menu {
  width: 100%;
  margin-top: 50px;
  position: absolute;
  top: 0;
  left: 0;
}

.list__menu > li {
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  width: 100%;
  margin: 0;
  padding: .5em 0;
  display: flex;
  left: -56px;
}

.list__menu > li > a {
  padding: 7px;
}

.list__menu > li:first-child {
  border-top: 1px solid #e1e1e1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.list__menu > li:last-child {
  border-bottom: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: 0 3px 2px -2px #000;
}

.chevron-mobile {
  margin-top: 11px;
  margin-left: auto;
  margin-right: 16px;
  font-size: 12px;
}

.bleu_turquoise_gradient_bg {
  background: #b5fdff;
  background: -webkit-linear-gradient(to bottom, #b5fdff 0%, #5ad5d9 100%);
  background: -moz-linear-gradient(to bottom, #b5fdff 0%, #5ad5d9 100%);
  background: linear-gradient(#b5fdff 0%, #5ad5d9 100%);
}

@media (width >= 64rem) {
  .bleu_turquoise_gradient_bg {
    background: linear-gradient(to right, #5ad5d9 0%, #b5fdff 100%);
  }
}

.agirc-arrco-container {
  width: 1008px;
}

.agirc-arrco-container .agirc-arrco-compte-container {
  border-radius: 16px;
  width: 904px;
  height: 353px;
  margin: 0 0 0 -100px;
  z-index: 0 !important;
}

.agirc-arrco-container .agirc-arrco-image-wrapper img {
  justify-content: center;
  align-items: center;
  max-width: 270px;
  display: flex;
  z-index: 10 !important;
}

@media (width >= 64rem) {
  .agirc-arrco-container .agirc-arrco-image-wrapper img {
    max-width: 380px;
  }
}

.retaitre-smile-container {
  color: #1a283e;
  background-image: url("brand-mh-smile.2ea93384.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (width <= 79.98rem) {
  .retaitre-smile-container {
    border-radius: 16px;
  }
}

.hp-your-side {
  width: auto;
}

@media (width <= 79.98rem) {
  .hp-your-side {
    width: calc(100% - 2rem);
    margin-left: auto;
    margin-right: auto;
  }
}

.retraite-finder > .field {
  position: relative;
}

.retraite-finder > .field > .submit {
  opacity: 1;
  width: 57px;
  height: 57px;
  position: absolute;
  top: 0;
  right: 0;
}

.retraite-finder > .field > .submit[disabled] {
  opacity: .5;
}

.retraite-finder > .information {
  position: relative;
}

.retraite-finder > .information > .tooltip {
  pointer-events: none;
  width: calc(100vw - 2rem);
  max-width: 350px;
  transition: opacity .2s;
  position: absolute;
  bottom: calc(100% + 4px);
  right: 50%;
  transform: translateX(50%);
}

@media (width >= 64rem) {
  .retraite-finder > .information > .tooltip {
    right: 0;
    transform: translateX(0);
  }
}

.retraite-finder > .information > .tooltip > .tooltip-inner {
  max-width: none;
}

.retraite-finder > .information > .tooltip > .arrow {
  right: calc(50% - .5rem);
}

@media (width >= 64rem) {
  .retraite-finder > .information > .tooltip > .arrow {
    right: 1.5rem;
  }
}

.retraite-finder > .information > .tooltip.show {
  pointer-events: all;
}

.retraite-finder > .formulaire {
  flex-wrap: wrap;
}

@media (width >= 64rem) {
  .retraite-finder > .formulaire {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
}

.retraite-finder > .formulaire > .field {
  flex: 1 0 60%;
}

.retraite-finder > .formulaire > .cta {
  flex: 1 0 100%;
}

.retraite-finder > .formulaire > .information {
  position: relative;
}

@media (width >= 64rem) {
  .retraite-finder > .formulaire > .information {
    position: absolute;
    top: 21px;
    right: -32px;
  }
}

.retraite-finder > .formulaire > .information > .tooltip {
  width: calc(100vw - 2rem);
  max-width: 350px;
  transition: opacity .2s;
  position: absolute;
  bottom: calc(100% + 24px);
  right: -8px;
}

.retraite-finder > .formulaire > .information > .tooltip > .tooltip-inner {
  max-width: none;
}

.retraite-finder > .formulaire > .information > .tooltip > .arrow {
  right: 8px;
}

.retraite-finder > .formulaire .control-label {
  white-space: nowrap;
}

.scroll-hide {
  overflow: hidden;
}

.scroll-hide--bottom > * {
  margin-bottom: -30px;
  padding-bottom: 30px;
}

.title-border {
  outline-offset: -.25rem;
  outline: .25rem solid;
  padding: 0 .5rem;
  display: inline-block;
}

@media (width >= 64rem) {
  .trouver-offre > .content {
    padding-top: 125px !important;
    padding-bottom: 125px !important;
  }
}

.trouver-offre > .thumb {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 280px;
}

@media (width >= 64rem) {
  .trouver-offre > .thumb {
    flex: 0 0 48.5%;
    height: auto;
  }
}

@media (width <= 63.98rem) {
  .trouver-offre {
    border-radius: 16px;
  }
}

@media (width >= 64rem) {
  .trouver-offre-pcpme {
    flex: 1 1 0;
  }
}

.trouver-offre-pcpme > .thumb {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 280px;
}

@media (width >= 64rem) {
  .right-side {
    width: 30%;
    margin-top: 129px;
    margin-left: 9%;
  }

  .left-side {
    width: 31%;
    margin-left: 15%;
  }
}

.app-banner {
  background-position-x: right;
  background-repeat: no-repeat;
  height: 406px;
  width: 100% !important;
}

@media (width >= 20rem) {
  .app-banner {
    width: 286px;
    height: 405px;
    margin: 0 0 84px;
  }
}

.phone {
  object-fit: contain;
  width: 321px;
  height: 472px;
  margin: 19px 76px;
}

@media (width >= 80rem) {
  .phone {
    margin: 19px 176px;
  }
}

.phone-mobile {
  width: auto;
  height: 200px;
  margin: -99px 83.4px -100px 29%;
}

.title {
  color: #fff;
}

@media (width >= 64rem) {
  .title {
    width: 435px;
    height: 76px;
    margin: 0 92px 8px 0;
  }
}

.sub-title {
  color: #fff;
}

@media (width >= 64rem) {
  .sub-title {
    width: 527px;
    height: 60px;
    margin: 8px 0 40px;
  }
}

.badge {
  max-width: 90%;
}

.row > div.decouvrez-application {
  flex-shrink: unset;
  width: auto;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.typeform-block {
  visibility: hidden;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transform-origin: top;
  background: #fff;
  grid-template-rows: 56px 54px 1fr;
  grid-template-columns: 91px auto auto;
  max-height: 100vh;
  padding: 0 1rem;
  transition: opacity .2s, transform 0s .2s;
  display: grid;
  position: fixed;
  inset: 0;
  overflow: hidden auto;
  transform: translateY(-100%);
}

@media (width <= 63.98rem) {
  .typeform-block {
    width: 100vw;
  }
}

@media (width >= 64rem) {
  .typeform-block {
    grid-template-rows: 5rem 0 auto;
    grid-template-columns: auto auto auto;
    padding: 0 3rem 2rem;
    bottom: auto;
    overflow: hidden;
    box-shadow: 0 0 0 120vh #2223, 0 3px 6px #2222224d;
  }
}

@media (width >= 90rem) {
  .typeform-block {
    padding-left: calc(50vw - 704px);
    padding-right: calc(50vw - 704px);
  }
}

.typeform-block > .logo {
  grid-area: 1 / 1;
}

@media (width >= 64rem) {
  .typeform-block > .logo {
    display: none;
  }
}

.typeform-block > .logo > a {
  background-position: center;
  width: 100%;
  display: block;
}

.typeform-block > .hide {
  z-index: 3;
  grid-area: 1 / 3;
  justify-self: end;
  position: relative;
}

.typeform-block > .hide:hover, .typeform-block > .hide:focus {
  color: var(--corail_mh_brand);
}

.typeform-block > .previous {
  z-index: 3;
  border-top: 1px solid var(--gris_light);
  border-bottom: 1px solid var(--gris_light);
  grid-area: 2 / 1 / 3 / 4;
  align-items: center;
  display: flex;
  position: relative;
}

@media (width >= 64rem) {
  .typeform-block > .previous {
    border: none;
    grid-area: 1 / 1;
  }
}

.typeform-block > .previous > button:hover, .typeform-block > .previous > button:focus {
  color: var(--corail_mh_brand);
}

.typeform-block > .previous > button[disabled] {
  opacity: .5;
  pointer-events: none;
}

.typeform-block > .question {
  visibility: hidden;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  grid-area: 3 / 1 / 4 / 4;
  padding-top: 1rem;
  transition-property: transform;
  transition-duration: 0s;
  transition-timing-function: ease-out;
  position: relative;
  transform: translateX(0);
}

@media (width >= 64rem) {
  .typeform-block > .question {
    display: flex;
    transform: translateY(0);
  }
}

.typeform-block > .question > .help {
  display: block;
  position: relative;
}

@media (width >= 64rem) {
  .typeform-block > .question > .help {
    flex: 0 0 calc(100% - 672px);
  }
}

@media (width >= 80rem) {
  .typeform-block > .question > .help {
    flex-basis: 434px;
  }
}

.typeform-block > .question > .help, .typeform-block > .question > .help > div, .typeform-block > .question > .help > a {
  display: block;
  position: relative;
}

.typeform-block > .question > .help > i, .typeform-block > .question > .help > div > i, .typeform-block > .question > .help > a > i {
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.typeform-block > .question.is-current {
  visibility: inherit;
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}

@media (width >= 64rem) {
  .typeform-block > .question.is-current {
    transform: translateY(0);
  }
}

.typeform-block > .question.is-above {
  transform: translateX(-140%);
}

@media (width >= 64rem) {
  .typeform-block > .question.is-above {
    transform: translateY(-140%);
  }
}

.typeform-block > .question.is-below {
  transform: translateX(140%);
}

@media (width >= 64rem) {
  .typeform-block > .question.is-below {
    transform: translateY(140%);
  }
}

.typeform-block > .question.is-moving {
  opacity: 1;
  transition-duration: .2s;
}

.typeform-block.is-visible {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  transition: opacity .2s, transform .4s ease-out;
  transform: translateY(0);
}

.typeform-block > .mask {
  z-index: 2;
  display: none;
  position: relative;
}

@media (width >= 64rem) {
  .typeform-block > .mask {
    background: linear-gradient(#fff 50%, #fff0 100%);
    grid-area: 1 / 1 / 2 / 4;
    display: block;
  }

  .typeform-form {
    flex: auto;
  }
}

.typeform-form > .choices > .choice {
  border: 1px solid var(--gris_light);
  color: var(--gris_dark);
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s, color .2s, background-color .2s;
}

@media (width >= 64rem) {
  .typeform-form > .choices > .choice {
    flex: 0 0 192px;
    justify-content: center;
    align-items: center;
    min-height: 166px;
    display: flex;
  }
}

@media (width >= 80rem) {
  .typeform-form > .choices > .choice {
    flex-basis: 214px;
  }
}

.typeform-form > .choices > .choice + .choice {
  margin-top: 1rem;
}

@media (width >= 64rem) {
  .typeform-form > .choices > .choice + .choice {
    margin: 0 0 0 2rem;
  }
}

.typeform-form > .choices > .choice:hover, .typeform-form > .choices > .choice:focus {
  color: #222;
  outline: none;
  box-shadow: 0 3px 6px #2222224d;
}

.typeform-form > .choices > .choice:active, .typeform-form > .choices > .choice:focus:not(:focus-visible) {
  box-shadow: none;
  background: #5ad5d9;
  border-color: #5ad5d9;
  color: #1a283e !important;
}

.typeform-form .choices-ppa-toutm {
  cursor: pointer;
}

.typeform-form .choices-ppa-toutm .choice {
  flex-basis: auto;
  width: 288px;
  position: relative;
}

.typeform-form .choices-ppa-toutm .choice .choice-hover {
  background-color: #ffffffb3;
  position: absolute;
  inset: 0;
}

.typeform-form .choices-ppa-toutm .choice img {
  width: 288px;
}

@media (width >= 64rem) {
  .typeform-form .choices-ppa-toutm .choice, .typeform-form .choices-ppa-toutm .choice img {
    width: 255px;
  }

  .typeform-form .choices-ppa-toutm .choice.highlighted-choice, .typeform-form .choices-ppa-toutm .choice.highlighted-choice img {
    width: 319px;
  }
}

#typeform-siren-pcpme, #typeform-numero-adherent {
  position: relative;
  overflow: hidden;
}

#typeform-siren-pcpme .ds-loader, #typeform-numero-adherent .ds-loader {
  z-index: 90;
  opacity: 0;
  transition: opacity .2s;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 0 50vh #ffffff80;
}

#typeform-siren-pcpme .ds-loader.is-visible, #typeform-numero-adherent .ds-loader.is-visible {
  opacity: 1;
}

.typeform-tp-container {
  justify-content: center;
  align-items: center;
  display: grid;
}

.typeform-tp-container > * {
  opacity: 0;
  grid-area: 1 / 1;
  width: 100%;
  max-width: 288px;
  transition: opacity .2s, transform .2s;
}

@media (width >= 64rem) {
  .typeform-tp-container > * {
    max-width: 400px;
  }
}

.typeform-tp-container > :first-child {
  transform: translateX(-20%)scale(.5);
}

.typeform-tp-container > :last-child {
  transform: translateX(20%)scale(.5);
}

.typeform-tp-container > .is-visible {
  opacity: 1;
  transition: opacity .2s .2s, transform .2s .2s;
  transform: translateX(0);
}

.faq-smile-container {
  text-align: center;
  color: #1a283e;
  background-image: url("faq-smile.67a3c488.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  flex-direction: column;
  place-content: center;
  align-items: center;
  height: 120px;
  max-height: 140px;
  display: flex;
}

.faq-smile-container, .faq-smile-container > div, .faq-smile-container > a {
  display: flex !important;
}

/*# sourceMappingURL=index.c0b8228b.css.map */
