.border-round {
    $w: 30px;
    margin-left: -$w;
    margin-right: -$w;
    padding-left: $w;
    padding-right: $w;
    overflow: hidden;
    border-radius: 0 0 100% 100% / 0 0 72px 72px;

    @include media-breakpoint-up('md') {
        border-radius: 0 0 100% 100% / 0 0 92px 92px;
    }

    @include media-breakpoint-up('lg') {
        border-radius: 0 0 100% 100% / 0 0 102px 102px;
    }
}
