input {
  font: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* ie hide cross */
input::-ms-clear {
  display: none;
}


.form-group {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1.5rem;

  input,
  textarea {
    background: $gris_background;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 20px 15px 5px 15px;
  }

  input {
    min-height: 56px;
    line-height: 1.9;
  }

  textarea {
    min-height: 100px;
    resize: none;
  }

  .control-label {
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    color: $gris_dark;
    font-size: 1rem;
    font-weight: normal;
    transition: all 0.28s ease;
  }

  .bar {
    position: relative;
    border-bottom: 0.0625rem solid $gris_dark;
    display: block;

    &::before {
      content: "";
      height: 0.125rem;
      width: 0;
      left: 50%;
      bottom: -0.0625rem;
      position: absolute;
      background: $bleu_texte;
      transition: left 0.28s ease, width 0.28s ease;
      z-index: 2;
    }
  }

  .message-erreur {
    display: none;
    color: $rouge_negatif;
    font-size: 0.75rem;
    margin-left: 16px;
    margin-top: 8px;
    position: absolute;
  }

  .message-aide {
    display: none;
    color: $gris_dark;
    font-size: 0.75rem;
    margin-left: 16px;
    margin-top: 8px;
    position: absolute;
  }

  .dsicon-close,
  .dsicon-check {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 16px;
    width: 16px;
    font-size: 1rem;
    right: 20px;
  }

  .dsicon-check {
    color: $vert_positif;
  }

  .dsicon-close {
    color: $rouge_negatif;
  }

  input {
    line-height: 1.9;
  }

  input,
  textarea {
    display: block;
    // background: none;
    // padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 1rem;
    border-width: 0;
    border-color: transparent;
    width: 100%;
    color: $noir;
    transition: all 0.28s ease;
    box-shadow: none;

    &:disabled,
    &.disabled {
      pointer-events: none;
      background-color: $gris_background;
      opacity: 0.3;

      ~ .control-label {
        color: $gris_light !important;
      }

      ~ .bar {
        border-color: $gris_light;
      }

      ~ .message-aide {
        display: none;
      }

      ~ .message-erreur {
        display: none;
      }
    }

    &.focused,
    &:focus {
      background-color: $gris_light;
      outline: none;

      ~ .bar::before {
        width: 100%;
        left: 0;
      }
    }

    // Note importante
    // ---------------
    // La règle `&:valid` suppose à tort que le `.control-label` doit être en position haute lorsque le champ est valide.
    // La vrai règle est plutôt : il doit être en position haute si le champ contient une valeur.
    //
    // Cependant, lorsque le composant est utilisé en pur HTML, cette règle permet de le faire fonctionner à peu près correctement...
    // Par contre, s'il est utilisé en JavaScript, on peut très facilement détecter la présence d'une valeur et ajouter en conséquence la classe `.has-value`.
    // C'est pourquoi, `&:valid` peut être désactivée si `.ignore-css-valid-state` est présent.

    &.focused,
    &:focus,
    &:valid:not(.ignore-css-valid-state),
    &.has-value {
      color: $noir;

      ~ .control-label {
        font-size: 0.75rem;
        color: $bleu_texte;
        top: 0.5rem;
        left: 0.75rem;
      }
    }

    &:not(.erreur) {
      &.focused,
      &:focus {
        ~ .message-aide {
          display: inherit;
        }
      }
    }

    &.valide {
      ~ .bar::before {
        width: 100%;
        left: 0;
        background: $vert_positif;
      }

      &.focused,
      &:focus,
      &:valid:not(.ignore-css-valid-state),
      &.has-value {
        ~ .control-label {
          color: $vert_positif;
        }

        ~ .dsicon-check {
          display: inherit;
        }

      }
    }

    &.erreur {
      ~ .bar::before {
        width: 100%;
        left: 0;
        background: $rouge_negatif;
      }

      ~ .dsicon-close {
        display: inherit;
      }

      ~ .control-label {
        color: $rouge_negatif;
        font-size: 0.75rem;
        top: 0.5rem;
        left: 0.75rem;
      }

      ~ .dsicon-close {
        display: inherit;
      }

      ~ .message-erreur {
        display: inherit;
      }
    }
  }
}
