$class: ".ds-input";

%floating-label-typeform {
  ~ #{$class}__control-label {
    @include sr-only();
  }
}

%label-as-placeholder-typeform {
  ~ #{$class}__control-label {
    font-size: 1rem;
    color: $gris_dark;
    top: 8px;
    left: 8px;
  }
}

#{$class}-group-typeform {
  display: block;
  position: relative;
  margin-bottom: 8px;
  min-height: 56px;

  #{$class}__control-label {
    position: absolute;
    pointer-events: none;
    padding-left: 0.125rem;
    z-index: 1;
    font-weight: normal;
  }

  #{$class}__bar {
    position: relative;
    border-bottom: 0.0625rem solid $gris_dark;
    display: block;

    &::before {
      content: "";
      height: 0.0625rem;
      width: 0;
      left: 50%;
      bottom: -0.0625rem;
      position: absolute;
      background: $bleu_texte;
      transition: left 0.28s ease, width 0.28s ease;
      z-index: 2;
    }
  }

  .message-aide,
  .message-erreur {
    display: none;
    font-size: 0.75rem;
    line-height: 1.0625rem;
    min-height: 1.0625rem;
    margin-left: 8px;
    margin-top: 8px;
  }

  .message-erreur {
    color: $rouge_negatif;
  }

  .message-aide {
    color: $gris_dark;
  }

  #{$class}__icon {
    position: absolute;
    height: 16px;
    width: 16px;
    font-size: 1rem;
    margin: auto;
    top: 0;
    bottom: 24px;
    right: 8px;
  }

  #{$class}__validation {
    display: none;
  }

  #{$class}__edition {
    display: none;
  }

  input#{$class} {
    padding: 0 8px 4px 8px;
    //&:focus {
    //  outline: none;
    //}
  }

  #{$class},
  #{$class}-control {
    display: block;
    background-color: transparent;
    height: 32px;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    border-width: 0;
    border-color: transparent;
    width: 100%;
    color: $noir;
    transition: all 0.28s ease;
    box-shadow: none;

    // Default to label as placeholder
    @extend %label-as-placeholder-typeform;

    // Make label float on focus or if value
    &#{$class}--focused,
    &:not(#{$class}--undefined) {
      @extend %floating-label-typeform;
    }

    &#{$class}--pristine:not(#{$class}--undefined) {
      ~ #{$class}__edition {
        display: block;
      }
    }

    &#{$class}--disabled {
      pointer-events: none;

      ~ #{$class}__control-label,
      #{$class}__control-label {
        color: $gris_light;
      }

      ~ #{$class}__bar,
      #{$class}__bar {
        border-color: $gris_light;
      }

      ~ .message-aide {
        display: none;
      }

      ~ .message-erreur {
        display: none;
      }
    }

    &#{$class}--focused {
      //outline: none;
      color: $noir;

      ~ #{$class}__bar::before,
      #{$class}__bar::before {
        width: 100%;
        left: 0;
      }
    }

    &:not(#{$class}--error):not(#{$class}--valid) {
      &#{$class}--focused {
        ~ .message-aide {
          display: block;
        }
      }
    }

    &#{$class}--valid {
      ~ #{$class}__bar::before,
      #{$class}__bar::before {
        background: $vert_positif;
      }
    }

    &#{$class}--error {
      ~ #{$class}__bar::before,
      #{$class}__bar::before {
        background: $rouge_negatif;
      }

      ~ .message-erreur {
        display: block;
      }
    }
  }
}
