$border-radius: 1.75rem;
$step-size-md: 3.5rem;
$step-size-xs: 2.5rem;

$class: ".ds-stepper";
$stepclass: "#{$class}__step";
$stepiconclass: "#{$stepclass}-icon";
$verticalline: "#{$class}__vertical-line-separator";

$left-margin-vertical: 2rem;

#{$class} {

  display: block;

  a {
    text-decoration: none;
  }

  #{$stepclass} {

    border: none;
    background: none;
    box-sizing: content-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &#{$stepclass}--active {
      background: $bleu_turquoise_light;
      border-radius: $border-radius;
      animation-name: current-step;
      animation-duration: 0.5s;

      #{$stepclass}-icon {
        color: $bleu_dark;
      }

      #{$stepclass}-label {
        font-weight: bold;
        color: $bleu_dark;
      }

      #{$stepclass}-progression {
        font-weight: bold;
        white-space: nowrap;
        font-size: 14px;
        line-height: 19px;
        position: absolute;
        margin-left: 1rem;
        color: $bleu_dark;
      }
    }

    &#{$stepclass}--clickable {
      cursor: pointer;
    }

  }

  #{$stepiconclass} {
    border-radius: $border-radius;
    margin-left: 0.05rem;
    height: $step-size-xs;
    width: $step-size-xs;
    flex-shrink: 0;
    position: relative;
    color: $noir;
    background: none;

    i[class*="dsicon-"] {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &#{$stepiconclass}--validated-step {
      color: $blanc;
      background: $vert_positif;
      animation-name: validate-step;
      animation-duration: 0.5s;
    }

    &:not(#{$stepiconclass}--active):not(#{$stepiconclass}--validated-step) {
      color: $gris_dark;
      background: $gris_light;
    }
  }

  #{$stepclass}-fieldset-list {
    width: 100%;
    overflow: hidden;

    &#{$stepclass}-fieldset-list--open {
      padding: 0 0 16px 40px;
    }

    a {
      text-decoration: none;
    }
  }

  #{$stepclass}-fieldset-name {
    padding: 8px 0;
    font-weight: bold;
  }

  #{$stepclass}-field {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    padding: 4px 8px;
    min-height: 25px;
    border-radius: 4px;
    background: $gris_background;

    #{$stepclass}-field-icon {
      padding: 4px 8px 0px;
      color: $gris_dark;
    }
  }

  &#{$class}--horizontal {

    #{$class}__steps {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    #{$class}__step-wrapper {
      display: flex;
    }

    #{$verticalline} {
      border-left: 0.5rem solid $gris_light;
      height: 0.25rem;
      margin: 1rem 0.1rem 1rem 0.1rem;

      &#{$verticalline}--validated {
        border-left: 0.5rem solid $vert_positif;
      }
    }

    #{$stepclass}-fieldset-list {
      &#{$stepclass}-fieldset-list--open {
        padding-top: 16px;
        padding-left: 0;
      }
    }

    #{$stepclass} {
      height: $step-size-xs;

      &#{$stepclass}--active {
        padding-right: 1rem;

        #{$stepclass}-progression {
          position: relative;
        }

        #{$stepclass}-label {
          display: none;
        }
      }
    }

    #{$stepclass}-label {
      display: none;
    }

    #{$stepiconclass} {
      margin-left: 0.05rem;
      height: $step-size-xs;
      width: $step-size-xs;
    }
  }

  &#{$class}--vertical {
    display: block;
    height: auto;

    &:before {
      // Barre verticale derrière les étapes
      top: 0;
      left: 58px;
      right: auto;
      bottom: 0;
      height: auto;
      width: 4px;
    }

    #{$verticalline} {
      border-left: 0.25rem solid $gris_light;
      min-height: 1.5rem;
      margin: 0.25rem 0 0.25rem 3.625rem;
    }

    #{$stepclass} {
      height: $step-size-md;
    }

    #{$stepclass}-label {
      display: inline;
      margin-left: 1rem;
    }

    #{$stepiconclass} {
      margin-left: $left-margin-vertical;
      height: $step-size-md;
      width: $step-size-md;
    }
  }
}

@keyframes validate-step {
  from {
    margin-left: 1rem;
    width: 93%;
    position: absolute;
    left: 0;
    background: $bleu_turquoise_light;
  }
  to {
    margin-left: 2rem;
    width: 3.5rem;
    position: relative;
    background: $vert_positif;
  }
}

@keyframes current-step {
  from {
    width: 3.5rem;
    position: relative;
    background: $gris_light;
  }
  to {
    width: 93%;
    left: 0rem;
    background: $bleu_turquoise_light;
  }
}


