$icons: (
  "dsicon-1": "\e904",
  "dsicon-2": "\e905",
  "dsicon-3": "\e906",
  "dsicon-4": "\e907",
  "dsicon-Cercle-play": "\e908",
  "dsicon-afficher": "\e909",
  "dsicon-aidant": "\e90a",
  "dsicon-altere": "\e90b",
  "dsicon-ampoule": "\e90c",
  "dsicon-assurance": "\e90d",
  "dsicon-avion": "\e90e",
  "dsicon-balance-bas": "\e90f",
  "dsicon-balance-haut": "\e910",
  "dsicon-bequille": "\e911",
  "dsicon-building": "\e912",
  "dsicon-bulle-dialogue": "\e913",
  "dsicon-bulle-dialogue-plus": "\e914",
  "dsicon-burger-menu": "\e915",
  "dsicon-bus": "\e916",
  "dsicon-cadenas": "\e917",
  "dsicon-calculette": "\e918",
  "dsicon-calendrier": "\e919",
  "dsicon-card-mh": "\e91a",
  "dsicon-cercle-check": "\e91b",
  "dsicon-cercle-chevron-droit": "\e91c",
  "dsicon-cercle-chevron-gauche": "\e91d",
  "dsicon-cercle-information": "\e91e",
  "dsicon-cercle-moins": "\e91f",
  "dsicon-cercle-plus": "\e920",
  "dsicon-cerveau": "\e921",
  "dsicon-charger-fichier": "\e922",
  "dsicon-check": "\e923",
  "dsicon-cheque": "\e924",
  "dsicon-chevron-bas": "\e925",
  "dsicon-chevron-droit": "\e926",
  "dsicon-chevron-gauche": "\e927",
  "dsicon-chevron-haut": "\e928",
  "dsicon-chiropracteur": "\e929",
  "dsicon-cible": "\e92a",
  "dsicon-cloche": "\e92b",
  "dsicon-close": "\e92c",
  "dsicon-coeur": "\e92d",
  "dsicon-coeur-filled": "\e92e",
  "dsicon-coeur-sante": "\e92f",
  "dsicon-coeur-smile": "\e930",
  "dsicon-compte": "\e931",
  "dsicon-conjoint": "\e932",
  "dsicon-corbeille": "\e933",
  "dsicon-coupe": "\e934",
  "dsicon-crayon": "\e935",
  "dsicon-croix-medicale": "\e936",
  "dsicon-debut-gauche": "\e937",
  "dsicon-dent": "\e938",
  "dsicon-dent-abimee-noyau-instruction": "\e939",
  "dsicon-dent-appareil": "\e93a",
  "dsicon-dent-chirurgie-gencive": "\e93b",
  "dsicon-dent-couronne": "\e93c",
  "dsicon-dent-detartrage": "\e93d",
  "dsicon-dent-devitalisation": "\e93e",
  "dsicon-dent-extraction": "\e93f",
  "dsicon-dent-implant": "\e940",
  "dsicon-dent-manquante": "\e941",
  "dsicon-dent-onlay": "\e942",
  "dsicon-dent-parodontologie": "\e943",
  "dsicon-dent-parodontologie-non-rembourse": "\e944",
  "dsicon-dent-pont": "\e945",
  "dsicon-dent-radio": "\e946",
  "dsicon-dent-restauration": "\e947",
  "dsicon-dent-soins": "\e948",
  "dsicon-dietetique": "\e949",
  "dsicon-document": "\e94a",
  "dsicon-document-check": "\e94b",
  "dsicon-document-moins": "\e94c",
  "dsicon-document-plus": "\e94d",
  "dsicon-double-smile-mh": "\e94e",
  "dsicon-enfant": "\e94f",
  "dsicon-epingle": "\e950",
  "dsicon-etoile": "\e951",
  "dsicon-etoile-filled": "\e952",
  "dsicon-euro": "\e953",
  "dsicon-excel": "\e954",
  "dsicon-facebook": "\e955",
  "dsicon-fiche-parametrage": "\e956",
  "dsicon-fin-droite": "\e957",
  "dsicon-flag": "\e958",
  "dsicon-fleche-bas": "\e959",
  "dsicon-fleche-clic": "\e95a",
  "dsicon-fleche-droite": "\e95b",
  "dsicon-fleche-dropdown-texte": "\e95c",
  "dsicon-fleche-gauche": "\e95d",
  "dsicon-fleche-haut": "\e95e",
  "dsicon-graphique": "\e95f",
  "dsicon-graphique-lines3x": "\e960",
  "dsicon-grille": "\e961",
  "dsicon-handicap": "\e962",
  "dsicon-herbe": "\e963",
  "dsicon-hopital": "\e964",
  "dsicon-horloge": "\e965",
  "dsicon-horloge-euro": "\e966",
  "dsicon-iiii": "\e967",
  "dsicon-images": "\e968",
  "dsicon-indisponible": "\e969",
  "dsicon-infirmiere": "\e96a",
  "dsicon-instagram": "\e96b",
  "dsicon-interrogation": "\e96c",
  "dsicon-jpg": "\e96d",
  "dsicon-kinesitherapie": "\e96e",
  "dsicon-lien-externe": "\e96f",
  "dsicon-linkedin": "\e970",
  "dsicon-liste": "\e971",
  "dsicon-lit": "\e972",
  "dsicon-lunettes": "\e973",
  "dsicon-mail": "\e974",
  "dsicon-masquer": "\e975",
  "dsicon-microscope": "\e976",
  "dsicon-moins": "\e977",
  "dsicon-nourrisson": "\e978",
  "dsicon-oeil-droit": "\e979",
  "dsicon-oeil-gauche": "\e97a",
  "dsicon-optique-audio": "\e97b",
  "dsicon-optique-hypermetrope": "\e97c",
  "dsicon-optique-hypermetrope-astigmate": "\e97d",
  "dsicon-optique-lentilles": "\e97e",
  "dsicon-optique-myope-astigmate": "\e97f",
  "dsicon-optique-operation-vue": "\e980",
  "dsicon-oreille": "\e981",
  "dsicon-orthodontie": "\e982",
  "dsicon-ostheo": "\e983",
  "dsicon-pansement": "\e984",
  "dsicon-parametres": "\e985",
  "dsicon-pdf": "\e986",
  "dsicon-pin-geoloc": "\e987",
  "dsicon-pin-geoloc-plus": "\e988",
  "dsicon-platre": "\e989",
  "dsicon-plus": "\e98a",
  "dsicon-png": "\e98b",
  "dsicon-podologue": "\e98c",
  "dsicon-poing": "\e98d",
  "dsicon-pouce": "\e98e",
  "dsicon-pouce-negatif": "\e98f",
  "dsicon-pourcentage": "\e990",
  "dsicon-power": "\e991",
  "dsicon-powerpoint": "\e992",
  "dsicon-prevoyance": "\e993",
  "dsicon-professionnel": "\e994",
  "dsicon-radioactif": "\e995",
  "dsicon-radiographie": "\e996",
  "dsicon-reglages": "\e997",
  "dsicon-search": "\e998",
  "dsicon-search-help": "\e999",
  "dsicon-smile": "\e99a",
  "dsicon-smile-circle": "\e99b",
  "dsicon-smiley": "\e99c",
  "dsicon-soleil": "\e99d",
  "dsicon-stethoscope": "\e99e",
  "dsicon-tel": "\e99f",
  "dsicon-tombe": "\e9a0",
  "dsicon-trombone": "\e9a1",
  "dsicon-tv-tel": "\e9a2",
  "dsicon-twitter": "\e9a3",
  "dsicon-upload": "\e9a4",
  "dsicon-urgence": "\e9a5",
  "dsicon-utilisateur-ajout": "\e9a6",
  "dsicon-utilisateur-suppr": "\e9a7",
  "dsicon-utilisateurs-multiples": "\e9a8",
  "dsicon-vague": "\e9a9",
  "dsicon-warning": "\e9aa",
  "dsicon-word": "\e9ab",
  "dsicon-www": "\e9ac",
  "dsicon-youtube": "\e9ad",
  "dsicon-imprimer": "\e9ae",
  "dsicon-assurance-plus": "\e9af",
  "dsicon-carte": "\e9b0",
  "dsicon-copier-coller": "\e9b1",
  "dsicon-trier": "\e9b2",
  "dsicon-partage-ios": "\e900",
  "dsicon-partage": "\e901",
  "dsicon-visio": "\e902",
  "dsicon-photo": "\e903"
);