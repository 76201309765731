$class: ".ds-alert";

#{$class} {
  @extend .radius-2;
  display: flex;
  padding: 1.5rem;
  flex-flow: nowrap;
  justify-content: space-between;

  &#{$class}--hidden {
    display: none;
  }

  #{$class}__title {
    font-weight: bolder;
  }

  #{$class}__content {
    display: flex;
    width: 100%;
  }

  #{$class}__content-info {
    align-self: center;
  }

  #{$class}__content-text {
    font-size: $font-size-sub;
    margin-left: 1.5rem;
    margin-right: 2.3125rem;
  }

  // Styles

  &#{$class}--neutral {
    @extend .alerte_gris_neutre_bg;
  }

  &#{$class}--warn {
    @extend .alerte_jaune_attention_bg;
  }

  &#{$class}--info {
    @extend .alerte_bleu_information_bg;
  }

  &#{$class}--valid {
    @extend .alerte_vert_positif_bg;

    #{$class}__content-info {
      #{$class}__icon {
        @extend .dsicon-cercle-check;
      }
    }
  }

  &#{$class}--error {
    @extend .alerte_rouge_negatif_bg;

    #{$class}__content-info {
      #{$class}__icon {
        @extend .dsicon-warning;
      }
    }
  }
}






