.espace-action {
    @include media-breakpoint-up('md') {
        display: flex;
        text-align: left !important;
    }

    >.thumb {
        height: 214px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        @include media-breakpoint-up('md') {
            flex: 0 0 25%;
            align-self: stretch;
            margin: 0 4rem 0 0;
            height: auto;
        }
    }

    >.content {
        flex: 0 0 50%;
        padding: 5rem 0;
    }

    @include media-breakpoint-up('md') {
        &:nth-of-type(even) {
            justify-content: flex-end;
            text-align: right !important;

            >.thumb {
                order: 2;
                margin: 0 0 0 4rem;
            }
        }
    }
}