$arrow-offset: 5px;
$zindex-tooltip: 1070 !default;

.tooltip {
  &-inner {
      width: 200px;
      padding: 1rem;
      background: $blanc;
      box-shadow: 0 3px 6px 0 rgba(34, 34, 34, 0.2);
      color: $noir;
      font-size: 12px;
      line-height: 19px;
  }

  .arrow {
      &:before {
          border-top-color: $blanc;
      }
  }

  &.show {
      opacity: 1;
  }
}

.ds-tooltip {
  position: absolute;
  display: none;
  padding: 1.7em;
  max-width: 18.2em;
  pointer-events: none;
  border-radius: 0.125em;
  z-index: $zindex-tooltip;
}

.ds-tooltip .ds-tooltip-text {
  color: $noir;
  white-space: pre-line;
}

.ds-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
}

.ds-tooltip-right .ds-tooltip-arrow {
  top: 50%;
  left: 5px;
  margin-top: -$arrow-offset;
  transform: rotate(90deg);
}

.ds-tooltip-left .ds-tooltip-arrow {
  top: 50%;
  right: 5px;
  margin-top: $arrow-offset;
  transform: rotate(-90deg);
}

.ds-tooltip-top .ds-tooltip-arrow {
  bottom: 5px;
  left: 50%;
  margin-left: -$arrow-offset;
}

.ds-tooltip-bottom .ds-tooltip-arrow {
  top: 5px;
  left: 50%;
  margin-left: $arrow-offset;
  transform: rotate(180deg);
}
