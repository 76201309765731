@use "sass:meta";

@each $size, $font-size, $icone-size in $sizes-btn {
  .btn-rond-h#{$size} {
    width: $size * 1px !important;
    height: $size * 1px !important;
    padding: 0 !important;
    text-align: center;

    i {
      font-size: $icone-size !important;
      margin: initial !important;
    }
  }
}

@each $size, $font-size, $icone-size in $sizes-btn {
  .btn-primaire-h#{$size} {
    @include button-bg($corail_mh_dark, $blanc, $corail_mh_brand, $font-size, $size);
    @include button-padding($size, $font-size);
    @include disabled-button();
    @include disabled-outline();
    @include button-icone-position();
  }
}

@each $size, $font-size, $icone-size in $sizes-btn {
  .btn-primaire-inverse-h#{$size} {
    @include button-bg($gris_background, $corail_mh_brand, $blanc, $font-size, $size);
    @include button-padding($size, $font-size);
    @include disabled-button();
    @include disabled-outline();
    @include button-icone-position();
  }
}

@each $size, $font-size, $icone-size in $sizes-btn {
  .btn-secondaire-h#{$size} {
    @include button-bg-secondaire(var(--gris_light-rgb), $corail_mh_brand, $font-size, $size);
    @include button-padding($size, $font-size);
    @include disabled-button();
    @include disabled-outline();
    @include button-icone-position()
  }
}

@each $name, $couleur in $couleurs {
  @each $size, $font-size, $icone-size in $sizes-btn {
    .btn-secondaire-#{$name}-h#{$size} {
      @include button-bg-secondaire(var(--gris_light-rgb), $couleur, $font-size, $size);
      @include button-padding($size, $font-size);
      @include disabled-button();
      @include disabled-outline();
      @include button-icone-position()
    }
  }
}

@each $name, $couleur in $couleurs {
  @each $size, $font-size, $icone-size in $sizes-btn {
    .btn-primaire-#{$name}-h#{$size} {
      @if (meta.variable-exists(#{$name}_hover)) {
        @include button-bg(var(--#{$name}_hover), $blanc, $couleur, $font-size, $size);
      } @else {
        @include button-bg(var(--#{$name}_a), $blanc, $couleur, $font-size, $size);
      }
      
      @include button-padding($size, $font-size);
      @include disabled-button();
      @include disabled-outline();
      @include button-icone-position()
    }
  }
}

@each $name, $couleur in $couleurs {
  @each $size, $font-size, $icone-size in $sizes-btn {
    .btn-primaire-inverse-#{$name}-h#{$size} {
      @include button-bg($gris_background, $couleur, $blanc, $font-size, $size);
      @include button-padding($size, $font-size);
      @include disabled-button();
      @include disabled-outline();
      @include button-icone-position()
    }
  }
}
