$ds-main: map-get($container-max-widths, "xl");

@mixin linkStyle {
    font-size: 12px;
    line-height: 17px;
    color: $gris_dark !important;
    text-decoration: underline !important;
    cursor: pointer;

    &:hover {
        color: $corail_mh_brand;
    }
}

.ppa-footer {
    display: block;
    margin: 2rem 1.5rem;

    &__container {
        padding: 1.5rem 0;
        border-top: 1px solid $gris_light;
    }

    &__links {
        margin-bottom: 2rem;

        &__item {
            line-height: 17px;

            &__link {
                @include linkStyle;
            }

            & + & {
                margin-top: 0.5rem;
            }
        }
    }

    &__trademark {
        font-size: 12px;
        line-height: 17px;
        color: $gris_dark;
        
        &__website {
            @include linkStyle;
        }
    }

    &__accessibility {
        margin-top: 2rem;

        &__service {
            @include linkStyle();

            display: flex;
            align-items: center;
            text-decoration: none;

            &__logo {
                flex: 0 0 25%;
                max-width: 120px;
                margin-right:1rem;

                img {
                    width: auto;
                    max-width: 100%;
                }
            }

            & + & {
                margin-top: 1rem;
            }
        }
    }

    @include media-breakpoint-up('md') {
        margin-left: 3rem;
        margin-right: 3rem;
        margin-bottom: 0;

        &__container {
            max-width: calc(#{$ds-main} - 2rem) !important;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &__links {
            display: flex;

            &__item {
                & + & {
                    margin-top: 0;
                    margin-left: 2rem;
                }
            }
        }

        &__accessibility {
            flex: 1 0 100%;
            display: flex;
            justify-content: space-between;

            &__service {
                flex: 0 0 calc(50% - 4rem);
                max-width: 460px;

                &__logo {
                    flex-basis: 120px;
                    margin-right: 2rem;
                }

                & + & {
                    margin-top: 0;
                }
            }
        }
    }
}

.footer_style {
    @include media-breakpoint-up('md') {
        background-color: $gris_background;
        display: flow-root;;
    }
}