$class: ".ds-quantity";

#{$class}__label {
  display: inline-block;
  margin-bottom: 8px;
}

#{$class}-input {
  display: flex;

  #{$class}__button {
    height: 32px;
    line-height: 32px;
    border: 1px solid $corail_mh_brand;
    padding: 0;
    color: $blanc;
    outline: none;
    background-color: $corail_mh_brand;
    cursor: pointer;

    //&:focus {
    //  outline: none;
    //}

    &#{$class}__button--disabled {
      background-color: rgba($corail_mh_brand, 0.3);
      border-color: transparent;
    }
  }

  #{$class}__moins {
    @include radius(left, 32px);
    padding-left: 15px;
    padding-right: 10px;
  }

  #{$class}__plus {
    @include radius(right, 32px);
    padding-right: 15px;
    padding-left: 10px;
  }

  #{$class}__input {
    width: 50px;
    border: 1px solid #e1e1e1;
    border-left: 0;
    border-right: 0;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #222;
    -moz-appearance: textfield;

    &#{$class}--zero {
      color: #757575;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
