@use 'sass:math';

.mh-radio {
  position: relative;
  line-height: 16px;
  display: inline-block;
  padding-right: 24px;

  input {
    &[type="radio"] {
      opacity: 0;
      position: absolute;
      z-index: -10000;

      & + label {
        cursor: pointer;
        padding-left: 24px;
        font-size: 0.875rem;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: $size-radio-container;
          height: $size-radio-container;
          border: 1px solid $gris_dark;
          border-radius: 100%;
          background: $blanc;
        }

        &::after {
          content: "";
          width: $size-radio;
          height: $size-radio;
          position: absolute;
          top: math.div($size-radio, 2);
          left: math.div($size-radio, 2);
          border-radius: 100%;
          transition: all 0.2s ease;
        }
      }
      &:checked {
        & + label {
          &::before {
            border-color: $corail_mh_brand;
          }
          &::after {
            background: $corail_mh_brand;
          }
        }
      }
    }
  }
}

.mh-checkbox {
  position: relative;
  line-height: 16px;
  display: inline-block;
  padding-right: 24px;

  input {
    &[type="checkbox"] {
      opacity: 0;
      position: absolute;
      z-index: -10000;

      & + label {
        cursor: pointer;

        padding-left: 24px;
        font-size: 0.875rem;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: $size-checkbox-container;
          height: $size-checkbox-container;
          border: 1px solid $gris_dark;
          border-radius: 100%;
          background: $blanc;
          @include radius(all, 3px);
        }

        &::after {
          width: $size-checkbox;
          height: $size-checkbox;
          position: absolute;
          top: 2px;
          left: 2px;
          line-height: $size-checkbox;
          color: white;
          font-size: $size-checkbox;
          font-family: "ds-icon", Arial, Helvetica, sans-serif;
          opacity: 0;
        }
      }
      &:checked, &:indeterminate {
        & + label {
          &::before {
            background: $corail_mh_brand;
            border-color: $corail_mh_brand;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      &:checked + label::after {
        content: map-get($icons, "dsicon-check");
      }
      &:indeterminate + label::after {
        content: map-get($icons, "dsicon-moins");
      }
    }
  }
}

.mh-button-toggle {
  label {
    font-weight: bold;
  }
  &-group {
    margin-top: 8px;
    button {
      background-color: $gris_light;
      height: 32px;
      line-height: 32px;
      font-size: 1rem;
      border: 1px solid #e1e1e1;
      padding: 0 40px;
      color: $gris_dark;
      outline: none;

      & + button {
        border-left: 0;
      }

      &:first-child {
        @include radius(left, 32px);
      }

      &:last-child {
        @include radius(right, 32px);
      }

      &.active {
        transition: all 1s ease;
        background-color: $corail_mh_brand;
        border-color: $corail_mh_brand;
        color: $blanc;
      }
      &:not(.active):hover{
        color: $noir;
      }
    }
  }
}

.mh-button-quantity {
  label {
    font-weight: bold;
  }
  &-group {
    margin-top: 8px;
    display: flex;
    button {
      height: 32px;
      line-height: 32px;
      border: 1px solid $corail_mh_brand;
      padding: 0;
      color: $blanc;
      outline: none;
      background-color: $corail_mh_brand;

      &:first-child {
        @include radius(left, 32px);
        padding-left: 15px;
        padding-right: 10px;
      }

      &:last-child {
        @include radius(right, 32px);
        padding-right: 15px;
        padding-left: 10px;
      }
      &:hover{
        background-color: $corail_mh_dark;
        border: 1px  solid $corail_mh_dark;
      }
  }
    input {
      border: 1px solid #e1e1e1;
      border-left: 0;
      border-right: 0;
      height: 32px;
      line-height: 32px;
      width: 50px;
      text-align: center;
      color: #222;
      -moz-appearance: textfield;

      &.zero {
        color: #757575;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.mh-slide-toggle {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1000;
    &:checked {
      & + span {
        background-color: $corail_mh_brand;
        i {
          top: 11px;
          color: $corail_mh_brand;
          left: 25px;
          font-size: 1.4rem;
          transform: rotate(0);
        }
        &::before {
          transform: translateX(20px);
          box-shadow: 0px 1px 0 -1px $noir;
        }
      }
    }
  }

  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gris_dark);
    transition: 0.4s;
    border-radius: 32px;

    i {
      display: block;
      position: relative;
      transition: 400ms;
      transform: rotate(180deg);
      transform-origin: center;
      top: 0px;
      left: 5px;
      font-size: 1.4rem;
      width: 22px;
      height: 22px;
      color: $gris_dark;
    }

    &::before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      transition: 0.4s;
      border-radius: 32px;
      box-shadow: 0px 1px 4px -1px $noir;
    }
  }
}
