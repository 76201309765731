.retraite-finder {
    >.field {
        position: relative;

        >.submit {
            position: absolute;
            top: 0;
            right: 0;
            height: 57px;
            width: 57px;
            opacity: 1;
    
            &[disabled] {
                opacity: 0.5;
            }
        }
    }

    >.information {
        position: relative;

        >.tooltip {
            position: absolute;
            width: calc(100vw - 2rem);
            max-width: 350px;
            right: 50%;
            bottom: calc(100% + 4px);
            pointer-events: none;
            transition: opacity 0.2s;
            transform: translateX(50%);

            @include media-breakpoint-up('md') {
                right: 0;
                transform: translateX(0);
            }

            >.tooltip-inner {
                max-width: none;
            }

            >.arrow {
                right: calc(50% - 0.5rem);

                @include media-breakpoint-up('md') {
                    right: 1.5rem;
                }
            }

            &.show {
                pointer-events: all;
            }
        }
    }
}

.retraite-finder {
    
    >.formulaire {
        flex-wrap: wrap;

        @include media-breakpoint-up('md') {
            position: relative;
            width: 300px;
            margin-left: auto;
            margin-right: auto;
        }

        >.field {
            flex: 1 0 60%;
        }
        
        >.cta {
            flex: 1 0 100%;
        }

        >.information {
            position: relative;

            @include media-breakpoint-up('md') {
                position: absolute;
                right: -32px;
                top: 21px;
            }

            >.tooltip {
                position: absolute;
                width: calc(100vw - 2rem);
                max-width: 350px;
                right: -8px;
                bottom: calc(100% + 24px);
                transition: opacity 0.2s;

                >.tooltip-inner {
                    max-width: none;
                }

                >.arrow {
                    right: 8px;
                }
            }
        }

        .control-label {
            white-space: nowrap;
        }
    }
}