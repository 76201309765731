.alerte_blanc_neutre_bg {
  background-color: $alerte_blanc_neutre_bg;
}

.alerte_gris_neutre_bg {
  background-color: $alerte_gris_neutre_bg;
}

.alerte_bleu_information_bg {
  background-color: $alerte_bleu_information_bg;
}

.alerte_vert_positif_bg {
  background-color: $alerte_vert_positif_bg;
}

.alerte_jaune_attention_bg {
  background-color: $alerte_jaune_attention_bg;
}

.alerte_rouge_negatif_bg {
  background-color: $alerte_rouge_negatif_bg;
}
