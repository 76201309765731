
.ds-input-form {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1.5rem;  


  i {
    position: absolute;
    right: 8px;
    top: 4px;
  }

  i.dsicon-crayon {
    color: $noir;

    &:hover {
      cursor: pointer;
    }
  }

  &--field {
    border: none;
    outline: none;
    border-bottom: 1px $gris_dark solid;
    color: $gris_dark;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100%;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    
    &:disabled {
      background-color: $blanc;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border-bottom: 1px $bleu_texte solid;

      ~ .message-aide {
        display: inherit;
      }

      ~ .message-erreur {
        display: none;
      }
    }
  }

  .message-aide,
  .message-erreur {
    display: none;
    margin-left: 8px;
    font-size: 12px;
    color: $gris_dark;
  }

  &__editMode {
    .ds-input-form--field {
      border-bottom: 1px $gris_light solid;
    }
  }

  &__disabled {
    .ds-input-form--field,
    .text-selected {
      opacity: 0.3;
    }
  }

  &__valide {
    .ds-input-form--field {
      border-bottom: 1px $vert_positif solid !important;

      &:focus,
      &:focus-visible,
      &:focus-within {
        border-bottom: 1px $bleu_texte solid !important;

        ~ .message-aide {
          display: inherit;
        }

        ~ .message-erreur {
          display: none !important;
        }
        ~ .dsicon-check{
          visibility: hidden;
        }
      }
    }

    i {
      color: $vert_positif;
    }
  }

  &__erreur {
    .ds-input-form--field {
      border-bottom: 1px $rouge_negatif solid !important;

      &:focus,
      &:focus-visible,
      &:focus-within {
        border-bottom: 1px $bleu_texte solid;

        ~ .message-aide {
          display: none;
        }

        ~ .message-erreur {
          display: inherit;
        }
      }
    }

    .message-erreur {
      display: inherit;
    }

    i {
      color: $rouge_negatif;
    }
  }
}

.ds-input-datalist {
  &__suggestions {
    margin-top: 1px;
    max-height: 50vh;
    overflow: auto;
    background-color: $blanc;
  }
  
  &__suggestion {
    padding: 1rem;
    outline: none;
    cursor: pointer;
  
    &:hover,
    &--focused {
      background-color: rgba(0, 0, 0, 0.05);
    }
   
    // Not: `<i>` is used to highlight the part of the selection that matches the input value.
    &--selected,
    i {
      font-style: normal;
      color: $vert_positif;
    }
  }
  
  &__suggestion-prefix {
    margin-right: 0.5em;
  }
}
