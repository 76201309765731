// Button background
@mixin button-bg($bg_dark, $color, $color_bg, $font-size, $size) {
  background: $color_bg;
  color: $color;
  text-align: center;
  font-size: $font-size;
  border-radius: #{$size}px;
  height: #{$size}px;
  border: none;

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    background: $bg_dark;
    transition: all 0.3s ease;
    outline: none !important;
  }
}

@mixin button-bg-secondaire($bg_dark-rgb, $color, $font-size, $size) {
  background-color: transparent;
  color: $color;
  text-align: center;
  font-size: $font-size;
  border-radius: #{$size}px;
  @if $color == $noir {
    border-color: $gris_dark;
  } @else {
    border-color: $color;
  }
  border: 1px solid;
  height: #{$size}px;
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus {
    background: rgba($bg_dark-rgb, 0.8);
    transition: all 0.3s ease;
    outline: none !important;
    background-color: rgba(var(--gris_background-rgb), 0.8);
  }
}

@mixin disabled-button() {
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        cursor: default;
    }
}

@mixin button-padding($hight, $font) {
    padding: 8px 24px;
}

@mixin disabled-outline() {
    &:focus {
        outline: none;
    }
}

@mixin button-icone-position() {
    display: inline-block;
    & > i {
        margin-right: 0.5rem;
        vertical-align: middle;
    }
}
